import React, {Component} from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {connect} from 'react-redux';
// import {TweenMax} from "gsap/TweenMax";
import gsap from "gsap";
import {ROUTES, navigateToLocation, navigateBack} from '../../app.router';
import {
	getConnectionStrength,
	getCurrentGameDetails,
	getIsChallengeOver,
	getIsSaving,
	getIsSavingSuccessful,
	getLastSessionObject,
	getSetScoreSetup,
	getSetScoreValues
} from '../game-details/game-details.selector';
import {
	PauseGameAction,
	UnpauseGameAction,
	SetGameQuit,
	SaveRequestedAction,
	SaveTimeoutAction,
	ShowHowToPlay,
	FetchCurrentPosition,
	ToggleOskVisibility,
	SetChallengeOver,
} from '../game-details/game-details.actions';
import {
	getRunningSessionId,
	getCurrentRegionCode,
	getWebviewState,
	getIsInterimScoreEnabled, getRunningSessionObject, getUserIsSubscribed, getLoggedUser,
	getRunningGameChallengeStyle
} from '../../app.selectors';
import { SetWebviewState } from '../../app.actions';
import {
	buttonPressed, buttonReleased,
	endGame,
	messageNativeClientOnOSKKeyEvent,
	messageVirtualKeyboard
} from '../../assets/lib/game-wrapper';
import {addPopup, closePopup} from '../popup/popup.component';
import QuitGamePopup from '../popup/quit-game-popup/quit-game-popup.component';
import {Button} from '../common/button/common-button.component';
import inputManager from '../../assets/lib/inputmanager';
import {saveState, updateTournamentPosition, quitApp} from '../../assets/lib/game-wrapper';
import SaveButton from '../common/button/save-button.component';
import GenericPopup, { TYPE_HAS_TWO_BUTTONS, TYPE_HAS_NO_BUTTONS} from '../popup/generic-popup/generic-popup.component';
import {getChallengeId, getGiants} from '../challenge/challenge.selectors';
import {getSelectedGiantSlayerChallenge} from '../game-details/game-details.selector';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import { getTournamentShortCode, isXboxUser, versionGreaterThanOrEqual } from '../../assets/lib/utils';
import {ControlMenu} from "./control-menu/control-menu.component";
import { HowToPlay } from '../how-to-play';
import {get, includes} from 'lodash';
import ChallengeAnimation from '../animations/challenge-animation/challenge-animation.component';
import InGameHeader from '../ingame-header/in-game-header.component';
import { pauseGame, beginGame } from '../../assets/lib/game-wrapper';

// Assets
import deviceInfo, { STORE_TYPE_EPIC } from '../../assets/lib/deviceInfo';

import './in-game-hud.component.less';
import InGameOSK from "../ingame-osk/ingame-osk.component";
import { readOskData, writeOskData } from "../../assets/lib/local-storage";
import { CG_DATA_DISPLAY_TYPES } from '../../constants';
import { getChallengeById } from '../../entities/entities.selectors';
import inGameHudStore from './in-game-hud.store';
import {getLeaderBoardObject} from '../tournaments/tournaments.selector';
import { getPopupVisibility } from '../popup/popup.selectors';
import DeviceInfo from '../../assets/lib/deviceInfo';

const continueBtnChildId = "inGameHudContinueButton";

class InGameHudComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			saveContainerVisible: false,
			inControlMenu: false,
			challengeAnimation: '',
			scores: [],
			currentRank: -1
		};
		this.saveTimer = null;
		this.currentPositionTimer = null;

		this.onContinueClicked = this.onContinueClicked.bind(this);
		this.onHowToPlayClicked = this.onHowToPlayClicked.bind(this);
		this.onQuitClicked = this.onQuitClicked.bind(this);
		this.onExitClicked = this.onExitClicked.bind(this);
		this.onPopupOkClicked = this.onPopupOkClicked.bind(this);
	}

	componentDidMount() {
		// reset challenge state on component mounted to make sure componentDidUpdate stuff works
		this.resetNativeClientChallengeOverState();

		document.getElementById('root').classList.add('background_hidden');
		inputManager.setBackFunction(() => {
			this.onContinueClicked();
		});

		this.startCurrentPositionTimer();

		if (this.props.challengeId) {
			this.setState({ challengeAnimation: 'start' });
			pauseGame();
			this.props.dispatch(PauseGameAction());
		}

		if (this.props.isWebViewVisible) {
			this.showMenu(continueBtnChildId);
		}

		if (this.isRunningOnNativeClientV2()) {
			const oskData = readOskData();
			if (oskData) {
				this.setState({oskData});
			}
			document.getElementById('app-main-container').classList.add('no-gradient');
		}
	}

	componentWillUnmount() {
		this.componentUnmounted = true;

		this.closeSavingPopup();
		document.getElementById('root').classList.remove('background_hidden');
		inputManager.setBackFunction(null);

		this.resetNativeClientChallengeOverState();

		if (this.successfulTimer) {
			clearTimeout(this.successfulTimer);
			this.successfulTimer = null;
			closePopup();
		}

		this.clearCurrentPositionTimer();

		if (this.isRunningOnNativeClientV2()) {
			writeOskData(null);
			this.toggleOSKVisibilityOnNativeClientV2(false);
			document.getElementById('app-main-container').classList.remove('no-gradient');
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isSaving && !this.props.isSaving) {
			this.closeSavingPopup();
			if (this.props.isSavingSuccessful) {
				this.showSavedSuccessfulPopup();
			}
			this.saveMessageSent = false;
		}

		if (!prevProps.isWebViewVisible && this.props.isWebViewVisible) {
			this.showMenu(continueBtnChildId);
		} else if(prevProps.isWebViewVisible && !this.props.isWebViewVisible) {
			this.hideMenu();
		}

		if (prevProps.showHowToPlay && !this.props.showHowToPlay) {
			this.showMenu('howToPlayButton');
			inputManager.setBackFunction(() => {
				this.onQuitClicked()
			});
		}

		if (!prevProps.isChallengeOver && this.props.isChallengeOver) {
			this.setState({ challengeAnimation: 'end' });
		}
	}

	isArrayEqual = (array1, array2) => {
		return _(array1).xorWith(array2, _.isEqual).isEmpty();
	}

	resetNativeClientChallengeOverState = () => {
		this.props.dispatch(SetChallengeOver(false));
		this.props.dispatch(SetWebviewState({visible: false}));
	}

	handleOSKKeyEvent = (code, pressed) => {
		messageNativeClientOnOSKKeyEvent(code, !!pressed);
	};

	toggleOSKVisibilityOnNativeClientV2 = (isVisible) => {
		this.props.dispatch(ToggleOskVisibility(isVisible));
		messageVirtualKeyboard(isVisible);
	};

	isRunningOnNativeClientV2 = () => {
		return deviceInfo.isRunningOnNativeClientV2();
	};

	showMenu(selectedButton) {
		// document.querySelector(".common-button").style = { y : 0, scale:1, opacity: 1, delay: 0.3};
		!this.componentUnmounted && inputManager.setCurrentChildById(selectedButton);
	}

	hideMenu() {
		// document.querySelector(".common-button").style = { opacity: 0 };
	}

	clearCurrentPositionTimer = () => {
		if (this.currentPositionTimer) {
			clearTimeout(this.currentPositionTimer);
			this.currentPositionTimer = null;
		}
	};

	startCurrentPositionTimer = () => {
		if(!this.props.isInterimScoreEnabled) return;

		if (this.props.lastSessionObject && this.props.lastSessionObject.tournamentId && !this.componentUnmounted) {
			this.props.dispatch(FetchCurrentPosition(
				getTournamentShortCode(this.props.lastSessionObject.tournamentId,this.props.lastSessionObject.challengeId),
				this.props.lastSessionObject.challengeId,
				this.props.lastSessionObject.tournamentId,
				this.onCurrentPositionFetched
			));
		}
	};

	onCurrentPositionFetched = (response) => {
		this.setState({ currentRank: response });
		this.currentPositionTimer = setTimeout(this.startCurrentPositionTimer, 5000);
	};

	onContinueClicked() {
		inputManager.setBackSound(false);
		this.props.dispatch(UnpauseGameAction());

		// this solves the issue when user quickly presses A + B buttons - as a result the 'Are you sure to quit the game' popup remains to be opened
		setTimeout(() => {
			if (this.props.isPopupVisible) {
				closePopup();
			}
		}, 200);
	}

	onHowToPlayClicked() {
		inputManager.setBackFunction(null);
		this.props.dispatch(PauseGameAction());
		this.props.dispatch(ShowHowToPlay());
	}

	handleQuitGamePopupBack() {}

	onQuitClicked() {
		const message = <span>Are you sure you want to leave <br/> this game session?</span>
		addPopup(
			<QuitGamePopup
				setBackFunction={this.handleQuitGamePopupBack}
				message={message}
				onOkClicked={this.onPopupOkClicked}
				onCancelClicked={this.onPopupCancelClicked}
			/>
		);
	}

	onExitClicked() {
		quitApp();
	}

	onPopupOkClicked() {
		const {challengeId} = this.props;

		if (challengeId) {
			navigateToLocation(ROUTES.RESULTS_WAITING);
		} else {
			navigateBack();
		}
		this.props.dispatch(SetGameQuit(this.props.sessionId));
	}

	onPopupCancelClicked = () => {
		inputManager.setBackFunction(() => {
			this.onQuitClicked();
		});
	}

	onButtonFocus = (focus) => {
		if (focus) {
			this.setState({saveContainerVisible: false });
		}
	};

	onSaveButtonFocus = (focus) => {
		if (focus) {
			this.setState({saveContainerVisible: true});
		}
	};

	onSaveButtonClicked = () => {
		this.setState({saveContainerVisible: !this.state.saveContainerVisible});
	};

	onSaveButtonItemClicked = (saveStateData, slot) => {
		if (this.props.isSaving) return;
		if (this.props.isPopupVisible) {
			closePopup();
		}

		if (saveStateData) {
			addPopup(<GenericPopup
				buttonType={TYPE_HAS_TWO_BUTTONS}
				okButtonLabel="Yes"
				cancelButtonLabel="No"
				title={`Overwrite Save Slot ${slot}`}
				onOkClicked={() => {
					this.startSaveStateProcess(slot);
				}}
				onCancelClicked={() => true}
			/>);
		} else {
			this.startSaveStateProcess(slot);
		}
	};

	startSaveStateProcess = (slot) => {
    	const { region } = this.props;

		if (this.saveMessageSent) {
			return;
		}

		saveState(slot, region);

		this.saveMessageSent = true;
		this.props.dispatch(SaveRequestedAction());

		addPopup(<GenericPopup
			title={`Saving in Slot ${slot}`}
			message="Please wait..."
			buttonType={TYPE_HAS_NO_BUTTONS}
		/>);

		this.saveTimer = setTimeout(() => {
			addPopup(<GenericPopup
				okButtonLabel="Got it!"
				title="Oops! Error saving"
				message="Please try saving again."
			/>);
			this.props.dispatch(SaveTimeoutAction());
		}, 30000);
	};

	closeSavingPopup = () => {
		if (this.saveTimer) {
			if (this.props.isSaving) this.props.dispatch(SaveTimeoutAction());
			clearTimeout(this.saveTimer);
			closePopup();
			this.saveTimer = null;
		}
	};

	onChallengeStartAnimFinish = () => {
		this.setState({ challengeAnimation: '' });
		inputManager.setBackSound(false);
		this.props.dispatch(UnpauseGameAction());
  }

	onChallengeEndAnimFinish = () => {
    this.props.dispatch(SetGameQuit(this.props.sessionId));
    navigateToLocation(ROUTES.RESULTS_WAITING);
		endGame();
  }

	showSavedSuccessfulPopup = () => {
		addPopup(<GenericPopup
			title="Successful Save!"
			buttonType={TYPE_HAS_NO_BUTTONS}
		/>);

		this.successfulTimer = setTimeout(() => {
			this.successfulTimer = null;
			closePopup();
		}, 1000);
	};

	onHeaderMenuClick = () => {
		pauseGame();
		this.props.dispatch(PauseGameAction());
	}

	shouldNotifyNativeOnStartButtonPressedReleased = () => {
		if (!DeviceInfo.isRunningOnNativeClientV2()) return false;

		return versionGreaterThanOrEqual(DeviceInfo.version, '3.2.0');
	};

	onHeaderStartPressed = () => {
		if (!this.shouldNotifyNativeOnStartButtonPressedReleased()) return;
		buttonPressed('Start');
	}

	onHeaderStartReleased = () => {
		if (!this.shouldNotifyNativeOnStartButtonPressedReleased()) return;
		buttonReleased('Start');
	}

	onHeaderStartClick = () => {
		if (!this.shouldNotifyNativeOnStartButtonPressedReleased()) {
			beginGame();
		}
		inputManager.setBackSound(false);
    	this.props.dispatch(UnpauseGameAction());
  	}

	onHeaderToggleOSK = () => {
		this.toggleOSKVisibilityOnNativeClientV2(!this.props.isOskVisible);
	}

	renderSaveButtons = () => {
		if (!this.props.currentGameDetails) return null;
		const {savedStates} = this.props.currentGameDetails;
		const buttonsArray = [];

		let nextEmptySlot = 1;

		for (let slot = 1; slot < 4; slot++) {
			const data = savedStates ? savedStates.find(savedStateData => savedStateData.slot === slot) : null;
			const showPremiumBenefits = !this.props.isSubscribed;
			if (data) nextEmptySlot = slot + 1;
			buttonsArray.push(
				<SaveButton
					data={data}
					key={slot}
					slot={slot}
					onClick={this.onSaveButtonItemClicked}
					childId={"saveButton" + slot}
					disabled={(!data && slot > nextEmptySlot) || this.props.isSaving || this.props.isPopupVisible}
					premiumBenefitsText={showPremiumBenefits ? "Only on Premium" : null}
					notSelectable={!this.state.saveContainerVisible || slot > nextEmptySlot || showPremiumBenefits && deviceInfo.storeType === STORE_TYPE_EPIC}
				/>
			);
		}

		return buttonsArray;
	};


	render() {
		const {
			inControlMenu,
			saveContainerVisible
		} = this.state

		if (this.props.challengeId && (this.state.challengeAnimation === 'start' || this.state.challengeAnimation === 'end')) {
			return (
				<ChallengeAnimation
					challengeAnim={this.state.challengeAnimation}
					onChallengeStartAnimFinish={this.onChallengeStartAnimFinish}
					onChallengeEndAnimFinish={this.onChallengeEndAnimFinish}
				/>
			);
		}

		if (!this.props.isWebViewVisible) {
			const oskData = this.state.oskData;
			const oskEnabled = oskData && oskData.vk_enabled && !!oskData.vk_enabled.find(entry => entry > 0);
			const giantSlayerRole = this.props.lastSessionObject ? this.props.lastSessionObject.role : "";
			const isBetterRank = this.state.currentRank >= this.props.prevRank;

			return (
				<div>
					<InGameHeader
						connectionStrength={this.props.connectionStrength}
						allowOSK={oskEnabled}
						onMenuClicked={ () => this.onHeaderMenuClick() }
						onStartClicked={ this.onHeaderStartClick }
						onStartPressed={ this.onHeaderStartPressed }
						onStartReleased={ this.onHeaderStartReleased }
						onOSKClicked={ this.onHeaderToggleOSK }
						active={ !deviceInfo.isXboxPlatform() && !deviceInfo.isPlayStationPlatform() } // Don't show Menu buttons if Xbox
						isChallenge={ !!this.props.challengeId }
						scores={ inGameHudStore.scores }
						userData={ this.props.user }
						isTournament={ this.props.challengeStyle === 'tournament' }
						rank={ this.state.currentRank }
						isBetterRank={ isBetterRank }
						giantSlayerRole={ giantSlayerRole }
						giantSlayerChallenge={ this.props.giantSlayerChallenge }
						giantUserData={ this.props.giantUserData }
						challengeObject={ this.props.challengeObject }
					/>
					{this.props.isOskVisible && <InGameOSK
						inputSetupRaw={oskData}
						onOSKKeyUp={(code) => {
							this.handleOSKKeyEvent(code, 0);
						}}
						onOSKKeyDown={(code) => {
							this.handleOSKKeyEvent(code, 1);
						}}
					/>}
				</div>
			);
		}

		const saveEnabled = this.props.currentGameDetails
			&& this.props.currentGameDetails.tags
			&& this.props.currentGameDetails.tags.includes("savestates")
			&& featureInfo.isSupported(FEATURES.SAVE_STATES)
			&& !this.props.challengeId;

		if (this.props.showHowToPlay) {
			return (<HowToPlay />);
		}

		if (inControlMenu) {
			return (
				<section className="control-menu-component">
					<ControlMenu isXboxPlayer={this.props.isXboxPlayer || deviceInfo.isPlayStationPlatform()} handleConfirm={() => {
						this.setState({inControlMenu: false}, () => {
							inputManager.setCurrentChildById('editControlsButton');
							inputManager.setBackFunction(() => {
								this.onQuitClicked()
							});
						})}
					}/>
				</section>
			)
		}

		inputManager.setBackSound(true);

		return (
			<section className="in-game-menu-component">
				<Button
					className="primary"
					onClick={this.onContinueClicked}
					childId={continueBtnChildId}
					onFocus={this.onButtonFocus}
				>
					Continue
				</Button>
				{
					featureInfo.isSupported(FEATURES.CONTROL_OPTIONS) && <Button
						className="primary"
						onClick={() => {
							this.setState({inControlMenu: true})
						}}
						childId={"editControlsButton"}
						onFocus={this.onButtonFocus}
					>
						Settings
					</Button>
				}
			
				<div className={`save-buttons-container ${saveContainerVisible ? 'focused' : ''}`}>
					<Button
						className="primary common-save-button"
						childId={"saveButton"}
						onFocus={this.onSaveButtonFocus}
						onClick={this.onSaveButtonClicked}
						withEventObject
						notSelectable={!saveEnabled}
					>
						Save
					</Button>
					{this.renderSaveButtons()}
				</div>
				<Button
					className="primary"
					onClick={this.onHowToPlayClicked}
					childId={"howToPlayButton"}
					onFocus={this.onButtonFocus}
				>
					Controls
				</Button>
				<Button
					className="primary"
					onClick={this.onQuitClicked}
					childId={"quitButton"}
					onFocus={this.onButtonFocus}
				>
					Quit Game
				</Button>
			</section>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const isPopupVisible = !getPopupVisibility(state);
	const currentGameDetails = getCurrentGameDetails(state);
	const isSaving = getIsSaving(state);
	const isSavingSuccessful = getIsSavingSuccessful(state);
	const challengeId = getChallengeId(state);
	const currentGamesTags = get(currentGameDetails, 'tags');
	const hasLeaderBoard = currentGamesTags && Array.isArray(currentGamesTags) ? includes(currentGamesTags, 'leaderboard') : false;
	const isWebViewVisible = getWebviewState(state).visible;
	const lastSessionObject = getLastSessionObject(state);
	const isInterimScoreEnabled = getIsInterimScoreEnabled(state);
	const sessionObject = getRunningSessionObject(state);
	const isMultiplayerChallenge = sessionObject ? sessionObject.challenge_instance_id : false;
	const showHowToPlay = state.gameDetails ? state.gameDetails.showHowToPlay : false;
	const isSubscribed = getUserIsSubscribed(state);
  	const user = getLoggedUser(state);
	const challengeStyle = getRunningGameChallengeStyle(state);
	const isChallengeOver = getIsChallengeOver(state);
	const scoreSetup = getSetScoreSetup(state);
	const scoreValues = getSetScoreValues(state);
	const leaderBoardObject = getLeaderBoardObject(state);
	const connectionStrength = getConnectionStrength(state);

	const giantSlayerChallenge = getSelectedGiantSlayerChallenge(state);
  const giants = getGiants(state);

  let giantUserData = null;
	let challengeObject = null;

	if (giantSlayerChallenge) {
		giantUserData = giants.find(giant => giant._id === giantSlayerChallenge.giantId);
    challengeObject = getChallengeById(state, giantSlayerChallenge.challengeId);
	}

	const prevRank = leaderBoardObject ? leaderBoardObject.rank : -1;

	return {
		connectionStrength,
		isPopupVisible,
		isXboxPlayer: isXboxUser(state),
		isOskVisible: state.gameDetails.isOskVisible,
		currentGameDetails,
		hasLeaderBoard,
		challengeId,
		challengeStyle,
		isSaving,
		isWebViewVisible,
		isInterimScoreEnabled,
		lastSessionObject,
		isSavingSuccessful,
		gameId: currentGameDetails ? currentGameDetails._id : null,
		sessionId: getRunningSessionId(state),
		region: getCurrentRegionCode(state),
		enableRetryButton: !isMultiplayerChallenge,
		showHowToPlay,
		isSubscribed,
		webrtc: ownProps.webrtc,
		user,
		isChallengeOver,
		scoreSetup,
		scoreValues,
		prevRank,
		giantSlayerChallenge,
		giantUserData,
		challengeObject,
	};
};

export default connect(mapStateToProps)(observer(InGameHudComponent));
