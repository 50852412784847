
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const ShowNotificationAction = (title, summary, className, userData, notificationType) => {
	return (dispatch) => {
		dispatch({ type: SHOW_NOTIFICATION, title, summary, className, userData, notificationType });
	};
};

export const LATEST_NOTIFICATION_ACTION  = 'SHOW_LATEST_NOTIFICATION';
export const LatestNotificationAction = (show) => {
	return (dispatch) => {
		dispatch({ type: LATEST_NOTIFICATION_ACTION, show });
	};
};

export const ADD_NOTIFICATION_TO_QUEUE_ACTION  = 'ADD_NOTIFICATION_TO_QUEUE_ACTION';
export const AddNotificationToQueueAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: ADD_NOTIFICATION_TO_QUEUE_ACTION, payload });
	};
};

export const REMOVE_LAST_NOTIFICATION_FROM_QUEUE_ACTION  = 'REMOVE_LAST_NOTIFICATION_FROM_QUEUE_ACTION';
export const RemoveLastNotificationFromQueueAction = () => {
	return (dispatch) => {
		dispatch({ type: REMOVE_LAST_NOTIFICATION_FROM_QUEUE_ACTION });
	};
};
