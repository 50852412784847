import React, { Component } from 'react';
import FontSizeByLength from '../../common/font-size-by-length/font-size-by-length.component';
import { Button } from '../../common/button/common-button.component';
import { ButtonWithGem } from '../../common/button/common-button-with-gem.component';
import { Avatar } from '../../common/avatar/avatar.component';
import gemImage from '../../../assets/images/global/gems.png';
import './mpc-status.component.less';
import GemAnimation from '../../animations/gem.animation';
import CountAnimation from '../../animations/count.animation';
import inputManager from '../../../assets/lib/inputmanager';
import SVGAnimation from '../../../assets/lib/react-bodymovin.lib';
import avatarSlayers from '../../../assets/images/generic-icons/avatar-slayers.png';
import vsJson from '../../../assets/animations/vs.json';
import { connect } from 'react-redux';
import {getGiants, getSelectedChallengeHistory} from '../challenge.selectors';
import {getIsPostAuthFetched, getLoggedUser, getSubscriptionsPopupDisplayCount, getUserIsSubscribed} from '../../../app.selectors';
import {SetNudgeAction} from '../../my-profile/my-profile.actions';
import {ShowNotificationAction} from '../../notifications/notifications.actions';
import {navigateBack, navigateToLocation, ROUTES} from '../../../app.router';
import {AcceptChallengeRequestAction, SendChallengeRequestAction} from '../challenge.actions';
import {FetchGameDetailed} from '../../../entities/games/games.actions';
import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import { isLowSpecDevice, paramsToObject } from '../../../app.helpers';
import {
    isFreemiumAppleIosUser,
    isFreemiumV4UserNotSubscribed,
    isFreeTierV2FeatureFlagEnabled,
    SUBSCRIPTIONS_POPUP_DISPLAY_COUNT_MAX,
    withBadWordMask
} from '../../../assets/lib/utils';
import {addPopup} from '../../popup/popup.component';
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import {incrementSubscriptionsPopupCount} from '../../../app.actions';
import multiplayerVersusGif from '../../../assets/images/multiplayer-versus.gif';
import { APPLE_IOS_SKU, GAME_TYPE, PREMIUM_POPUP_HEADER_TEXT_1, PREMIUM_POPUP_HEADER_TEXT_2 } from '../../../constants';
import deviceInfo, { STORE_TYPE_APPLE_IOS } from '../../../assets/lib/deviceInfo';
import { startNativePayment } from '../../../assets/lib/game-wrapper';

const vsAnimation = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: vsJson,
    rendererSettings: { className: 'lottie' }
};
const PAGE_ID = 'mp_challenge_status_1';

class MPCStatusComponent extends Component {
    constructor() {
        super();
        this.state = {status:{}};

        this.state = {
            myGemElement:null,
            opponentGemElement:null,
            componentElement:null,
            animationReady:false,
        };
    }
    
    componentDidMount() {
        const {componentType, challengeState, giantSlayerDetails} = this.props.challengeHistoryObject;

        if (giantSlayerDetails) {
            inputManager.setCurrentChildById("exitButton");
        } else if (componentType === 'resultState'){
            inputManager.setCurrentChildById("continueButton");
        } else if (componentType === 'newChallengeState'){
            inputManager.setCurrentChildById("playButton");
        } else {
            switch (challengeState) {
                case 'win':
                case 'loss':
                case 'draw':
                    inputManager.setCurrentChildById("replayButton");
                    break;
                case 'waiting':
                    inputManager.setCurrentChildById("nudgeButton");
                    break;
                case 'issued':
                    inputManager.setCurrentChildById("acceptButton");
                    break;
                default :
            }
        }

        this.props.dispatch(FetchGameDetailed(this.props.challengeHistoryObject.gameId));

        this.showSubscriptionsComparisonPremiumPopup();
    }

    showSubscriptionsComparisonPremiumPopup() {
        const { isFreeTierV2FeatureEnabled, isPostAuthFetched, isUserSubscribed, previousRoute } = this.props;

        if (isFreeTierV2FeatureEnabled
            && isPostAuthFetched
            && !isUserSubscribed
            && previousRoute === ROUTES.RESULTS_WAITING.path
            // show subscriptions popup only 2 times during app session (for challenge (solo, duel, giant) results pages only)
            && (SUBSCRIPTIONS_POPUP_DISPLAY_COUNT_MAX > this.props.subscriptionsPopupDisplayCount)
            && (deviceInfo.storeType !== STORE_TYPE_APPLE_IOS)
        ) {
            this.props.dispatch(incrementSubscriptionsPopupCount());
            addPopup(<SubscriptionComparisonsPopup leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_2} price={'£1'} pageId={PAGE_ID}/>);
        }
    }

    switchState = () => {
        const {userGems, challengeHistoryObject} = this.props;
        const { challengeState, componentType, wager, replay, giantSlayerDetails, blocked } = challengeHistoryObject;
        const gemCount = wager;
        const replayCost = replay || wager;        

        if (!featureInfo.isSupported(FEATURES.GAMEPLAY)) 
            return null;

        if (giantSlayerDetails) {
            return <Button className="primary" onClick={this.onExitClicked} childId="exitButton">Exit</Button>;
        }

        if(componentType === 'resultState') {
            return <Button className="primary" onClick={this.onContinueClicked} childId="continueButton">Continue</Button>;
        } else if (componentType === 'newChallengeState') {
            return <Button className="primary" onClick={this.onPlayClicked} childId="playButton">Play</Button>;
        }

        switch (challengeState) {
        case 'win':
        case 'loss':
        case 'draw':
            return <ButtonWithGem className="primary" onClick={this.onPlayClicked} cost={replayCost} disabled={replayCost>userGems} notSelectable={replayCost>userGems} childId="replayButton">Replay</ButtonWithGem>;
        case 'waiting':
            return !blocked  && <Button className="primary" onClick={this.onNudgeClicked} childId="nudgeButton">Nudge</Button>;
        case 'issued':
            return <ButtonWithGem className="primary" onClick={this.onAcceptClicked} cost={gemCount} disabled={gemCount>userGems} notSelectable={gemCount>userGems}  childId="acceptButton">Accept Challenge</ButtonWithGem>;
        default :
        }
    };

    onPlayClicked = () => {
        const {gameId, challengeId, opponent} = this.props.challengeHistoryObject;
        this.props.dispatch(SendChallengeRequestAction(gameId,challengeId,opponent._id));
        navigateToLocation(ROUTES.HOW_TO_PLAY, {id: gameId, challengeId, gameType: GAME_TYPE.DUEL_CHALLENGE});
    };

    onNudgeClicked = () => {
        const {opponent} = this.props.challengeHistoryObject;
        const summary = `You nudged ${withBadWordMask(opponent.displayName)}`;
        this.props.dispatch(ShowNotificationAction('Nudge!', summary ));
        SetNudgeAction(opponent._id);
        navigateBack();
    };

    onAcceptClicked = () => {
        const {gameId, challengeId, challengeInstanceId} = this.props.challengeHistoryObject;

        if (this.props.isFreemiumAppleIos) {
            startNativePayment(APPLE_IOS_SKU);
            return;
        } else if (this.props.isFreemiumV4User) {
            addPopup(<SubscriptionComparisonsPopup
                customClassName={'subscription-comparisons-popup-1300'}
                leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_1} pageId={PAGE_ID}
            />);
            return;
        }

        this.props.dispatch(AcceptChallengeRequestAction(challengeInstanceId, gameId, challengeId));
        const query = paramsToObject(this.props.location.search);
        navigateToLocation(ROUTES.HOW_TO_PLAY, {
            id: gameId,
            challengeId,
            messageId: query ? query.messageId : undefined,
            gameType: GAME_TYPE.DUEL_CHALLENGE,
        });
    };

    onContinueClicked = () => {
        const {gameId, challengeId} = this.props.challengeHistoryObject;
        navigateToLocation(ROUTES.GAME_CHALLENGES,{id:gameId, focusElement: challengeId} );
    };

    onExitClicked = () => {
        navigateBack();
    };

    showObject = (bool) => {
        if(bool) {
            return bool;
        } else {
            return '';
        }
    };

    showDescription = (player) => {
        const {scoreLabel} = this.props.challengeHistoryObject;

        if(player && player.score !== 'fail') {
            if(player.score) {
                return scoreLabel;
            } else {
                return "Waiting for result";
            }
        } else {
            return null;
        }
    };

    setMyGemPosition = element => {
        if(!element)return;

        this.setState( prevState => ({
            ...prevState,
            myGemElement: element,
        }));
    };

    setOpponentGemPosition = element => {
        if(!element)return;

        this.setState( prevState => ({
            ...prevState,
            opponentGemElement: element,
        }));
    };

    setComponentBounds = element => {
        if(!element)return;

        this.setState( prevState => ({
            ...prevState,
            componentElement: element,
        }));
    };

    setAnimationReady = () => {
        this.setState( prevState => ({
            ...prevState,
            animationReady: true,
        }));
    };

    getAnimationComponent = (gemCount,isAnimated) => {
        const { challengeState } = this.props.challengeHistoryObject;
        if(!(challengeState==='loss' || challengeState==='win')) return null;
        let startPoint;
        let toLeft;

        if(isAnimated && this.state.animationReady && this.state.myGemElement && this.state.opponentGemElement && this.state.componentElement) {
            const myGemRect = this.state.myGemElement.getBoundingClientRect();
            const opponentGemRect =  this.state.opponentGemElement.getBoundingClientRect();
            const componentRect = this.state.componentElement.getBoundingClientRect();

            if(challengeState === 'win') {
                startPoint = {
                    x:opponentGemRect.x-componentRect.x,
                    y:opponentGemRect.y-componentRect.y,
                };
                toLeft = true;
            }else {
                startPoint = {
                    x:myGemRect.x-componentRect.x,
                    y:myGemRect.y-componentRect.y,
                };
                toLeft = false;
            }
        }else{
            return null;
        }

        return <GemAnimation startPoint={startPoint} gemCount={gemCount} toLeft={toLeft}/>;
    };

    getCountAnimation = (isAnimated, gemCount, result, isOpponent=false) => {
        let calculatedGemCount = gemCount;
        if(isOpponent){
            if(result==='win')calculatedGemCount=0;
            else if (result==='loss')calculatedGemCount=gemCount*2;
        }else{
            if(result==='win')calculatedGemCount=gemCount*2;
            else if (result==='loss')calculatedGemCount=0;
        }

        if(!isAnimated) {
            return <span className="gem-text">{calculatedGemCount}</span>;
        }

        if(this.state.animationReady) {
            return <CountAnimation textClass="gem-text" startValue={gemCount} endValue={calculatedGemCount} speedInMillisecond={150} />;
        }

        return <span className="gem-text">{gemCount}</span>;
    };

    getDisplayName = name => {
        return (
            <span className="name">
                <FontSizeByLength changePerChar={10} startAtChar={12} stopAtChar={16}>
                    {withBadWordMask(name)}
                </FontSizeByLength>
            </span>
        );
    };

    render() {
        const { giantUserData, isFreeTierV2FeatureEnabled, isUserSubscribed } = this.props;
        const {me, opponent, challengeState, componentType, wager, challengeTitle, challengeDescription, medal, challengeId, giantSlayerDetails, scoreLabel} = this.props.challengeHistoryObject;
        const isAnimated  = componentType === 'resultState' && (challengeState==='loss' || challengeState==='win');
        const gemCount = wager;
        const hideGems = isFreeTierV2FeatureEnabled && !isUserSubscribed;

        let slayersWinning = false;
        let mySlayerScore = 0;
        let gsPlayerWinning = false;
        let gsPlayerOutcomeText = '';
        let gsOpponentOutcomeText = '';
        let gsPlayerPrize = 0;
        let gsOpponentPrize = 0;
        let currentSlayerWinFraction = 0;
        if (giantSlayerDetails) {
            if (giantSlayerDetails.currentSlayerWinFraction) {
                currentSlayerWinFraction = giantSlayerDetails.currentSlayerWinFraction;
            }
            slayersWinning = (currentSlayerWinFraction > giantSlayerDetails.targetSlayerWinFraction);
            gsPlayerWinning = (giantSlayerDetails.role === 'giant') ? !slayersWinning : slayersWinning;
            gsPlayerOutcomeText = (giantSlayerDetails.state === 'live')
                ? gsPlayerWinning ? 'Winning' : 'Losing'
                : gsPlayerWinning ? 'Won' : 'Lost';
            gsOpponentOutcomeText = (giantSlayerDetails.state === 'live')
                ? gsPlayerWinning ? 'Losing' : 'Winning'
                : gsPlayerWinning ? 'Lost' : 'Won';
            gsPlayerPrize = 20; // TODO: retrieve correct data
            gsOpponentPrize = 0; // TODO: retrieve correct data
            if (giantSlayerDetails.role === 'slayer') {
                mySlayerScore = me.score;
            }
        }

        let percentage = 0;
        let numberSlayers = 0;
        let plural = '';
        if (giantSlayerDetails) {
            percentage = Math.round(giantSlayerDetails.targetSlayerWinFraction * 100);
            numberSlayers = giantSlayerDetails.numberSlayers;
            plural = giantSlayerDetails.numberSlayers !== 1 ? 's' : '';
        }

        return (
            <section className="mpc-status-component" ref={this.setComponentBounds}>
                <div className="content-with-bg-full-screen">
                    <div className="challenge-opponents">
                        {giantSlayerDetails &&
                            <>
                                <h1 className="giant-slayer-title">Giant Slayer Results</h1>
                                <p>{`Over ${percentage}% of slayers must beat the giant to win.`}</p>
                                <h2>{challengeTitle}</h2>
                                <p>{challengeDescription}</p>
                            </>
                        }
                        {!giantSlayerDetails &&
                            <>
                                <h1>{challengeTitle}</h1>
                                <p>{challengeDescription}</p>
                            </>
                        }
                        <div className="panels">
                            <div className="content-left" onAnimationEnd={this.setAnimationReady}>
                                <div className={`panel loggedUser gradient-top-border ${giantSlayerDetails ? (gsPlayerWinning ? 'gs-won' : 'gs-lost') : ''}`}>
                                    <div className="panel-inner">
                                        <div className="top-container">
                                            {giantSlayerDetails && giantSlayerDetails.role === 'giant' &&
                                                <>
                                                    <Avatar userData={giantUserData} />
                                                    {this.getDisplayName(giantUserData.displayName)}
                                                </>
                                            }
                                            {giantSlayerDetails && giantSlayerDetails.role === 'slayer' &&
                                                <>
                                                    <img className="avatar-container" src={avatarSlayers} alt="Slayers" />
                                                    <span className="name">{`${numberSlayers} slayer${plural}`}</span>
                                                </>
                                            }
                                            {!giantSlayerDetails &&
                                                <>
                                                    <Avatar userData={me} />
                                                    {medal?<img className="left-medal" src={this.showObject(medal.img_src)} alt=""/>:''}
                                                    {this.getDisplayName(me.displayName)}
                                                </>
                                            }
                                        </div>
                                        <div className="gem-panel">
                                            {!hideGems && giantSlayerDetails && giantSlayerDetails.state === 'completed' &&
                                                <>
                                                    <img className="gems-img" src={gemImage} alt="" ref={this.setMyGemPosition}/>
                                                    <span className="gem-text">{gsPlayerPrize}</span>
                                                </>
                                            }
                                            {!hideGems && !giantSlayerDetails &&
                                                <>
                                                    <img className="gems-img" src={gemImage} alt="" ref={this.setMyGemPosition}/>
                                                    {this.getCountAnimation(isAnimated,gemCount,challengeState)}
                                                </>
                                            }
                                        </div>
                                        {giantSlayerDetails &&
                                            <div className={`outcome-text`}>
                                                {gsPlayerOutcomeText}
                                            </div>
                                        }
                                    </div>
                                    {giantSlayerDetails && giantSlayerDetails.role === 'giant' &&
                                        <div className={`panel-result ${slayersWinning ? 'lost' : 'won'}`}>
                                            <div className="result-text-left">
                                                {`Score to beat: ${giantSlayerDetails.giantScore} ${scoreLabel}`}
                                            </div>
                                        </div>
                                    }
                                    {giantSlayerDetails && giantSlayerDetails.role === 'slayer' &&
                                        <div className={`panel-result ${slayersWinning ? 'won' : 'lost'}`}>
                                            <div className="result-text-left">
                                                {`${Math.round(currentSlayerWinFraction * 100)}% of slayers beat the giant`}
                                            </div>
                                            <div className="result-text-right">
                                                {`Your score: ${me.score} ${scoreLabel}`}
                                            </div>
                                        </div>
                                    }
                                    {!giantSlayerDetails &&
                                        <div className={`panel-result ${challengeState==='win' ? 'won' : challengeState==='loss' ? 'lost' : 'waiting'} ${componentType==='newChallengeState' || challengeState==='issued'  ? 'hide' : ''}`}>
                                            <div className="result-text">
                                                {this.showObject(me.score)}{' '}{this.showDescription(me)}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="content-vs">
                                {this.state.animationReady && !isLowSpecDevice() ? <SVGAnimation options={vsAnimation} /> : null}
                                {isLowSpecDevice() && <img src={multiplayerVersusGif} alt="VS" className="content-vs__gif"/>}
                            </div>
                            <div className="content-right">
                                <div className={`panel opponent gradient-top-border ${giantSlayerDetails ? (gsPlayerWinning ? 'gs-lost' : 'gs-won') : ''}`}>
                                    <div className="panel-inner">
                                        <div className="gem-panel">
                                            {!hideGems && giantSlayerDetails && giantSlayerDetails.state === 'completed' &&
                                                <>
                                                    <img className="gems-img" src={gemImage} alt="" ref={this.setOpponentGemPosition}/>
                                                    <span className="gem-text">{gsOpponentPrize}</span>
                                                </>
                                            }
                                            {!hideGems && !giantSlayerDetails &&
                                                <>
                                                    <img className="gems-img" src={gemImage} alt="" ref={this.setOpponentGemPosition}/>
                                                    {this.getCountAnimation(isAnimated,gemCount,challengeState,true)}
                                                </>
                                            }
                                        </div>
                                        <div className="top-container top-container--right">
                                            {giantSlayerDetails && giantSlayerDetails.role === 'slayer' &&
                                                <>
                                                    <Avatar userData={giantUserData} />
                                                    {this.getDisplayName(giantUserData.displayName)}
                                                </>
                                            }
                                            {giantSlayerDetails && giantSlayerDetails.role === 'giant' &&
                                                <>
                                                    <img className="avatar-container" src={avatarSlayers} alt="Slayers" />
                                                    <span className="name">{`${numberSlayers} slayer${plural}`}</span>
                                                </>
                                            }
                                            {!giantSlayerDetails &&
                                                <>
                                                    <Avatar userData={opponent} />
                                                    {this.getDisplayName(opponent.displayName)}
                                                </>
                                            }
                                        </div>
                                        {giantSlayerDetails &&
                                            <div className={`outcome-text`}>
                                                {gsOpponentOutcomeText}
                                            </div>
                                        }
                                    </div>
                                    {giantSlayerDetails && giantSlayerDetails.role === 'slayer' &&
                                        <div className={`panel-result ${slayersWinning ? 'lost' : 'won'}`}>
                                            <div className="result-text-right">
                                                {`Score to beat: ${giantSlayerDetails.giantScore} ${scoreLabel}`}
                                            </div>
                                        </div>
                                    }
                                    {giantSlayerDetails && giantSlayerDetails.role === 'giant' &&
                                        <div className={`panel-result ${slayersWinning ? 'won' : 'lost'}`}>
                                            <div className="result-text-right">
                                                {`${Math.round(currentSlayerWinFraction * 100)}% of slayers beat the giant`}
                                            </div>
                                        </div>
                                    }
                                    {!giantSlayerDetails &&
                                        <div className={`panel-result ${challengeState==='win' ? 'lost' : challengeState==='loss' ? 'won' : 'waiting'} ${componentType==='newChallengeState' || challengeState==='issued' ? 'hide' : ''}`}>
                                            <div className="result-text">
                                                {this.showObject(opponent.score)}{' '}{this.showDescription(opponent)}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {this.switchState()}
                    </div>
                </div>
                {this.getAnimationComponent(gemCount,isAnimated)}
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    const userGems = getLoggedUser(state).currencies.GEMS;
    const challengeHistoryObject = getSelectedChallengeHistory(state);

    const giants = getGiants(state);
    let giantUserData = null;
    if (challengeHistoryObject?.giantSlayerDetails) {
        giantUserData = giants.find(giant => giant._id === challengeHistoryObject.giantSlayerDetails.giantId);
    }

    return {
        isFreemiumAppleIos: isFreemiumAppleIosUser(state),
        isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
        subscriptionsPopupDisplayCount: getSubscriptionsPopupDisplayCount(state),
        previousRoute: state.routing.previousRoute && state.routing.previousRoute.path,
        isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
        isPostAuthFetched: getIsPostAuthFetched(state),
        isUserSubscribed: getUserIsSubscribed(state),
        userGems,
        challengeHistoryObject,
        giantUserData
    };
};

export const MPCStatus = connect(mapStateToProps)(MPCStatusComponent);
