import {antstreamAPIService} from '../../app.reducer';
import {UpdateChallengesData} from '../../entities/challenges/challenges.actions';
import {normalizeChallengeHistoryObjects} from '../../entities/entities.normaliser';
import { GetGiantSlayerChallengesAction } from '../challenge/challenge.actions';
import {readRestUserData} from "../../assets/lib/local-storage";
import {addPopup} from "../popup/popup.component";
import GenericPopup from "../popup/generic-popup/generic-popup.component";
import React from "react";
import { handleNoAuthTokenIssue } from '../../assets/lib/utils';

export const SetNudgeAction = (payload) => {
	const { authToken } = readRestUserData() || {};
	if (!authToken) handleNoAuthTokenIssue('SetNudgeAction');
		const params = {
			headers: { Authorization: 'Bearer ' + authToken },
			body: {
				recepientId: payload
			}
		};
		antstreamAPIService.player.nudgeCreate(params)
			.then(({ data }) => {
				if (!data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);
			})
			.catch(catchErr => {
				addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
				console.error('Failed to nudge user: ' + catchErr.message);
			});

};

export const UPDATE_MY_MENU_SELECTION = 'Update My Menu Selecton';
export const UpdateMyMenuSelection = (payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_MY_MENU_SELECTION, payload});
	};
};

export const FETCH_USER_CHALLENGES = 'FETCH_USER_CHALLENGES';
export const FetchUserChallenges = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_USER_CHALLENGES });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('FETCH_USER_CHALLENGES');
			const params = {headers: { Authorization: 'Bearer ' + authToken }};
			antstreamAPIService.challenge.getUserHistoryList(params)
				.then(({ data }) => {
					if (!data) throw new Error('Something went wrong');
					if (data.error) throw new Error(data.error);

					dispatch(FetchUserChallengesSuccess(data.data.result.challenges));
				})
				.catch(catchErr => {
					addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
					console.error('Failed to get user history: ' + catchErr.message);
				});

	};
};

export const FETCH_USER_CHALLENGES_SUCCESS = 'FETCH_USER_CHALLENGES_SUCCESS';
export const FetchUserChallengesSuccess = (response) => {
	return (dispatch, getState) => {
		const {challengeObjects, giantSlayerChallengeObjects} = response;
		dispatch(UpdateChallengesData(challengeObjects));
		dispatch(GetGiantSlayerChallengesAction());

		let {issuedChallenges, challengesHistory} = response;
		issuedChallenges = normalizeChallengeHistoryObjects(getState(), issuedChallenges,false);
		challengesHistory = normalizeChallengeHistoryObjects(getState(), challengesHistory,true);
		const payload = {issuedChallenges,challengesHistory};
		dispatch({ type: FETCH_USER_CHALLENGES_SUCCESS, payload});
	};
};
