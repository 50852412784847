import React, { Component } from 'react';
import {map} from "lodash";
import {messageNativeXboxShowGamerCard} from "../../../assets/lib/game-wrapper";
import GenericPopup from "../../popup/generic-popup/generic-popup.component";
import {addPopup} from "../../popup/popup.component";
import {antstreamService} from "../../../app.reducer";

class XboxGamerCardButtonComponent extends Component {
    constructor(props) {
        super(props);
        this.unsubscribeKeyPressedMessage$ = null;
    }

    componentDidMount() {
        window.requestAnimationFrame(this.listenForGamepads);

        // subscribe to gamepad events from native client
        this.unsubscribeKeyPressedMessage$ = antstreamService
            .getGamepadKeyPressedMessage$()
            .subscribe(msg => {
                if (msg !== 'xButton') return;
                this.handleGamepadButtonXPressed();
            });
    }

    componentWillUnmount() {
        if (this.unsubscribeKeyPressedMessage$) {
            this.unsubscribeKeyPressedMessage$.unsubscribe();
        }
    }

    handleGamepadButtonXPressed = () => {
        if (this.props.xboxUserId) {
            messageNativeXboxShowGamerCard(this.props.xboxUserId);
        }
    };

    listenForGamepads = () => {
        // we are using lodash's map
        // because getGamepads returns an object in Chrome
        // and an array in other browsers
        map(navigator.getGamepads(), (gamepad) => {
            if (!gamepad) return;

            const buttons = gamepad.buttons;
            const xButtonPressed = buttons[2] && buttons[2].pressed;
            if (xButtonPressed) {
                this.handleGamepadButtonXPressed();
            }
        })

        // we keep the listener
        window.requestAnimationFrame(this.listenForGamepads);
    };

    render() {
        return (
            <div onClick={this.handleGamepadButtonXPressed} className="xbox-gamer-card">
                <span className="xbox-gamer-card__icon">X</span>
                <span className="xbox-gamer-card__text">Xbox Gamer Card</span>
            </div>
        );
    }
}

export const XboxGamerCardButton = XboxGamerCardButtonComponent;
