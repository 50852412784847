// use to avoid compatibility issues
// between browsers for WebRTC
// https://github.com/webrtchacks/adapter
import Controls from "./Controls";
import Perf from "./perf";
import deviceInfo, {
  isDeviceChrome,
  isDeviceSafari,
  STORE_TYPE_SAMSUNG_TV,
} from "./deviceInfo";

import { AntRTCTelemetry } from "./webrtcTelemetry";
import AntRTCNetwork from "./AntRTCNetwork";
import { PacketTypeString, ControlPadMapping } from "./AntRTCNetworkConstants";

const oskHeight = 230; // height of osk (on screen keyboard) when visible

export default class LegacyAntRTC {
  constructor(
    webRTCServer,
    sessionID,
    remoteVideo,
    videoOutputTarget,
    videoPostprocessingCanvas,
    superSamplingCanvas,
    controlsCanvas,
    perfCanvas,
    challengeStyle,
    onVideoSetup,
    onSaveResponse,
    onErrorCb,
    onInGameEvent,
    mute,
    fixedJoysticks,
    isUIPaused,
    callUIPause,
    gameId,
    serverIP
  ) {
    this.webRTCServer = webRTCServer;
    this.gameId = gameId;
    this.serverIP = serverIP;
    this.sessionID = sessionID;
    this.onErrorCb = onErrorCb;
    this.onInGameEvent = onInGameEvent;
    this.dataChannel = null;
    this.onVideoSetup = onVideoSetup;
    this.onSaveResponse = onSaveResponse;
    this.messageSendTime = performance.now();
    this.isVideoSetup = true;
    this.streamSet = false;
    this.unmuteEnabled = false;
    this.mute = mute;
    this.oskVisible = false;
    this.network = null;
    this.fixedJoysticks = fixedJoysticks;
    this.frameCount = 0;
    this.isUIPaused = isUIPaused;
    this.callUIPause = callUIPause;

    this.controls = null;
    this.controlsCanvas = controlsCanvas;
    this.postprocessLatencyDebugging = false;
    this.postprocessing = videoPostprocessingCanvas !== null;
    this.videoOutputTarget = videoOutputTarget;
    this.videoPostprocessingCanvas = videoPostprocessingCanvas;
    this.videoSuperSamplingCanvas = superSamplingCanvas;
    this.videoScalingMode = 0;
    this.perfCanvas = perfCanvas;

    this.isInCall = false;

    this.controlPadStates = [0, 0, 0, 0];
    this.controlPadStatesPrev = [0, 0, 0, 0];
    this.playerPads = {};
    this.playerPadsCount = 0;
    this.multiplayerClient = false;
    this.sessionStart = Date.now();
    this.networkIntervalId = null;
    this.initialUnpause = false;
    this.isGamepadStartPressed = false;
    this.isGamepadStartPressedPrev = false;
    this.isGamepadStartPressedCounter = 0;

    this.dest = "local:";
    this.rvstream = null;
    this.remoteVideo = remoteVideo;
    this.preview = null;
    this.calling = false;
    this.mic = false;
    this.cam = false;
    this.mediaConnected = false;
    this.timeoutRunning = false;
    this.iceTimeout = 3000;
    this.peerConnection = null;
    this.httpRequest = null;
    this.localStream = null;
    this.constraints = {
      voiceActivityDetection: false,
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    };

    this.controllers = [];

    this.onVendorButtonPressed = null;
    this.onEscapeKeyPressed = null;
    this.onTabKeyPressed = null;
    this.onEvent = null;
    this.isPaused = false;
    this.controlsString = "";
    this.gameType = 0;
    this.disableInputs = false;
    this.perfInterval = null;

    let params = new URLSearchParams(window.location.search);
    if (params.get("input_map") !== null) {
      this.controlsString = decodeURIComponent(
        decodeURIComponent(params.get("input_map"))
      );
    } else {
      throw new Error("Controls string not found.");
    }

    this.gameProfile = JSON.parse(
      decodeURIComponent(decodeURIComponent(params.get("game_profile")))
    );

    // 0: Ordinary Free Play
    // 1: Challenge
    // 2: Tournament
    // 3: Connection Test
    if (challengeStyle) {
      if (challengeStyle === "intro" || challengeStyle === "challenge") {
        this.gameType = 1;
      } else if (challengeStyle === "tournament") {
        this.gameType = 2;
      } else if (challengeStyle === "test") {
        this.gameType = 3;
      }
    }
    document.addEventListener("touchend", this.initialUnmute);
    document.addEventListener("click", this.initialUnmute);
    document.addEventListener("keydown", this.initialUnmute);
  }

  sendSave(data) {
    if(this.network) {
        this.network.sendSave(data);
    }
    else {
        console.err("Can't send Save. No network object present");
    }
  }
  
  onPerfInterval = () => {
    // console.log("[AntRTC_Legacy] onPerfInterval");
    if (this.perf) {
      this.perf.updateStats();
    }
  };

  initialUnmute = (e) => {
    if (!this.unmuteEnabled) {
      return;
    }
    document.removeEventListener("touchend", this.initialUnmute);
    document.removeEventListener("click", this.initialUnmute);
    document.removeEventListener("keydown", this.initialUnmute);
    this.mute(false);
  };

  call = () => {
    console.info("WebRTC call");
    this.calling = true;
    this.timeoutRunning = false;

    this.peerConnection = new RTCPeerConnection();

    if(isDeviceSafari) {
        // Add a transceiver in Safari case to indicate you want to receive video.
        this.peerConnection.addTransceiver('video', { direction: 'recvonly' });
        this.peerConnection.addTransceiver('audio', { direction: 'recvonly' });
    }

    if (deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV) {
      const transceiver = this.peerConnection.addTransceiver("video");
      const availReceiveCodecs = RTCRtpReceiver.getCapabilities("video").codecs;
      let H264_codecs = [];
      // iterate over supported codecs and pull out the codecs we want
      for (let i = 0; i < availReceiveCodecs.length; i++) {
        if (availReceiveCodecs[i].mimeType != "video/VP8") {
          H264_codecs.push(availReceiveCodecs[i]);
        }
      }
      // currently not all browsers support setCodecPreferences
      if (transceiver.setCodecPreferences != undefined) {
        try {
          transceiver.setCodecPreferences(availReceiveCodecs);
        } catch (ex) {
          this.onError("[LEGACY WEBRTC] failed to set codec: " + ex.toString());
        }
      }
    }

    this.peerConnection.onicecandidate = this.onIceCandidate;
    this.peerConnection.oniceconnectionstatechange =
      this.onIceConnectionStateChanged;
    this.peerConnection.onicegatheringstatechange =
      this.onIceGatheringStateChange;
    this.peerConnection.ontrack = this.onRemoteTrackAdded;

    if (this.perfCanvas) {
      this.perf = new Perf(
        this.sessionID,
        this.perfCanvas,
        this.peerConnection,
        true,
        null
      );
      this.perf.init();
    }

    this.tele = new AntRTCTelemetry(
      this.sessionID,
      this.peerConnection,
      this.remoteVideo,
      null,
      'v1',
      10000,
      this.gameId,
      this.serverIP
    );
    this.tele.start();

    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => {
        this.peerConnection.addTrack(track, this.localStream);
      });
      console.debug(
        "[LEGACY WEBRTC] BW creating offer:",
        JSON.stringify(this.constraints)
      );
    }

    // data channel should be created before createOffer
    this.createDataChannel();

    if (this.perfCanvas) {
      this.perfInterval = setInterval(this.onPerfInterval.bind(this), 2000);
    }

    return this.peerConnection
      .createOffer(this.constraints)
      .then(this.onCreateOfferSuccess)
      .catch((error) => {
        this.onError(
          "[LEGACY WEBRTC] failed to create offer",
          error.toString()
        );
      });
  };

  disableInput = (disable) => {
    this.disableInputs = disable;
  };

  endCall = () => {
    console.log("[LEGACY WEBRTC] endCall");
    if (this.network) {
      this.network.sendDisconnect(this.dataChannel);
    }

    if (this.perfInterval) {
      clearInterval(this.perfInterval);
      this.perfInterval = null;
    }

    this.onErrorCb = null;
    this.onEscapeKeyPressed = null;
    this.onTabKeyPressed = null;
    if (this.peerConnection) {
      this.peerConnection.close();
    }
    this.isInCall = false;
    this.removeKeyListeners();

    if (this.controls) {
      this.controls.remove();
    }

    if (this.perf) {
      this.perf.remove();
    }

    if (this.tele) {
      this.tele.stop();
    }
    if (this.networkIntervalId) {
      clearInterval(this.networkIntervalId);
    }
  };

  clampPadTriggerValue = (value) => {
    value = Math.max(0.0, Math.min(value, 1.0));
    return (value * 255) | 0;
  };

  clampPadAxisValue = (value, flip) => {
    value = Math.max(-1.0, Math.min(value, 1.0));
    value *= flip ? -1 : 1;
    value *= value < 0 ? 32768 : 32767;
    return value | 0;
  };

  pollControlPads = () => {
    this.isGamepadStartPressedPrev = this.isGamepadStartPressed;
    this.isGamepadStartPressed = false;

    for (var i = 0; i <= 15; i++) {
      this.controlPadStates[padId] = 0;
    }

    // we don't listen to events
    // if the game is paused
    if (this.isPaused) {
      return;
    }

    if (this.oskVisible) {
      return;
    }

    // if (!this.isDataChannelOpen) {
    //   return;
    // }

    var padId = 0;

    this.controllers.forEach((gamepad) => {
      this.controlPadStatesPrev[padId] = this.controlPadStates[padId];
      this.controlPadStates[padId] = 0;

      const b = gamepad.buttons;
      const a = gamepad.axes;

      for (var i = 0; i <= 15; i++) {
        if (b[i].pressed) {
          this.controlPadStates[padId] |= ControlPadMapping[i];
        }
      }

      this.isGamepadStartPressed |= b[9].pressed;

      var leftTrigger = 0;
      var rightTrigger = 0;
      var leftThumbX = 0;
      var leftThumbY = 0;
      var rightThumbX = 0;
      var rightThumbY = 0;

      if (b[6].pressed) leftTrigger += 1;
      if (b[7].pressed) rightTrigger += 1;

      if (a[0]) leftThumbX += a[0];
      if (a[1]) leftThumbY += a[1];
      if (a[2]) rightThumbX += a[2];
      if (a[3]) rightThumbY += a[3];

      // Assign player number to PadID if a button has been pressed.
      if (
        (this.controlPadStates[padId] !== 0 ||
          leftThumbX !== 0 ||
          leftThumbY !== 0 ||
          rightThumbX !== 0 ||
          rightThumbY !== 0) &&
        this.playerPads[padId] === undefined
      ) {
        if (this.multiplayerClient) {
          this.playerPads[padId] = 1;
        } else {
          this.playerPads[padId] = this.playerPadsCount++;
        }
      }

      if (this.playerPads[padId] !== undefined) {
        let playerId = this.playerPads[padId];

        /**
         * fixes an issue when a browser detects some fake gamepads and orders them with higher priority then true gamepad
         * these fake unknown gamepads have Gamepad.mapping === ''
         * In this case we should:
         *    ignore fake/unknown gamepads
         *    increase Gamepad.index of true gamepads to 0 or 1 in order to send them as player1 or player2 to Game Server
         * */
        if (this.controllers.length > 1) {
          if (!gamepad.mapping) {
            // if unknown gamepad
            padId++;
            return;
          }
          this.controllers.forEach((currentGamepad) => {
            // if fake/unknown gamepad and it has higher index than the real gamepad
            if (
              !currentGamepad.mapping &&
              currentGamepad.index < gamepad.index
            ) {
              playerId = playerId - 1;
            }
          });
        }

        const clamPadTriggerValue = {
          left: this.clampPadTriggerValue(leftTrigger),
          right: this.clampPadTriggerValue(rightTrigger),
        };

        const clampPadAxisValue = {
          left: {
            x: this.clampPadAxisValue(leftThumbX),
            y: this.clampPadAxisValue(leftThumbY, true),
          },
          right: {
            x: this.clampPadAxisValue(rightThumbX),
            y: this.clampPadAxisValue(rightThumbY, true),
          },
        };

        this.network.sendContollerInputs(
          playerId,
          this.controlPadStates[padId],
          clamPadTriggerValue,
          clampPadAxisValue
        );
      }

      padId++;
    });

    if (!this.isPaused) {
      if (!this.isGamepadStartPressedPrev && this.isGamepadStartPressed) {
        this.isGamepadStartPressedCounter = 0;
      }
      if (this.isGamepadStartPressed) {
        this.isGamepadStartPressedCounter++;
      }
      const startHeldMaxFrameCount = 60;
      if (
        this.isGamepadStartPressed &&
        this.isGamepadStartPressedCounter > startHeldMaxFrameCount
      ) {
        this.callUIPause();
      }
    }
  };

  onError = (message, errorObject) => {
    console.error(message, errorObject);

    if (this.onErrorCb) {
      this.onErrorCb(message, errorObject);
    }
  };

  getIsPaused = () => {
    return this.isPaused;
  }

  togglePause = () => {
    if(this.isPaused) {
        this.unpause();
    }
    else {
        this.pause();
    }
  }
  
  pause = () => {
    
    if (this.network) {
        this.isPaused = true;
        this.network.sendPause(this.isPaused);    
    }
  };

  unpause = () => {
    
    if (this.network) {
        this.isPaused = false;
        this.network.sendPause(this.isPaused);       
    }
  };
  setOSKVisible = (value) => {
    this.oskVisible = value;
  };

  startGame = () => {
    this.network.sendSpecialButtonInput(0, 0x00, 1);
    // we need to wait a few frames before sending the key up
    setTimeout(() => {
      this.network.sendSpecialButtonInput(0, 0x00, 0);
    }, 100);
  };

  onIceCandidate = (evt) => {
    if (evt.candidate) {
      if (!this.timeoutRunning) {
        this.timeoutRunning = true;

        setTimeout(() => {
          console.debug(
            "[LEGACY WEBRTC] dialVSP from timeout " + this.iceTimeout
          );
          this.dialVSP();
        }, this.iceTimeout);
      }

      return;
    }

    this.dialVSP();
  };

  onIceConnectionStateChanged = (evt) => {
    if (this.peerConnection && this.peerConnection.iceConnectionState) {
      console.info(
        "[LEGACY WEBRTC] ICE connection state:",
        this.peerConnection.iceConnectionState
      );

      if (
        this.peerConnection.iceConnectionState === "completed" ||
        this.peerConnection.iceConnectionState === "connected"
      ) {
        this.mediaConnected = true;
        this.isInCall = true;
      } else if (this.peerConnection.iceConnectionState === "disconnected") {
        this.onErrorCb(
          "[LEGACY WEBRTC] Game stream has disconnected",
          this.peerConnection.iceConnectionState
        );
      }
    }
  };

  onIceGatheringStateChange = () => {
    if (this.peerConnection && this.peerConnection.iceGatheringState) {
      console.info(
        "[LEGACY WEBRTC] ICE gathering state: " +
          this.peerConnection.iceGatheringState
      );
    }
  };

  onCreateOfferSuccess = (desc) => {
    this.peerConnection.setLocalDescription(desc);
    this.addKeyListeners();
    this.isInCall = true;
    this.update();
  };

  addKeyListeners = () => {
    window.onbeforeunload = () => {
      this.network.sendDisconnect();
    };
    document.addEventListener("keyup", this.keyUpListener);
    document.addEventListener("keydown", this.keyDownListener);
  };

  removeKeyListeners = () => {
    document.removeEventListener("keyup", this.keyUpListener);
    document.removeEventListener("keydown", this.keyDownListener);
  };

  keyUpListener = (event) => {
    if (
      event.code == "F8" &&
      this.postprocessing &&
      this.postprocessLatencyDebugging
    ) {
      this.videoScalingMode = (this.videoScalingMode + 1) % 3;
      if (this.videoScalingMode == 0) console.log("SCALING: SUPER SAMPLING");
      if (this.videoScalingMode == 1) console.log("SCALING: PIXEL SCALING");
      if (this.videoScalingMode == 2) console.log("SCALING: DEFAULT WebRTC");
    }
    event.preventDefault();
    this.network.sendRawKeyInput(event.code, 0);
  };

  keyDownListener = (event) => {
    event.preventDefault();

    switch (event.code) {
      case "Escape":
        this.onEscapeKeyPressed();
        break;
      case "Tab":
        this.onTabKeyPressed();
        break;
      case "F2":
        this.multiplayerClient = true;
        break;
      default:
        if (!this.disableInputs) {
          this.network.sendRawKeyInput(event.code, 1);
        }
    }
  };

  addGamepadConnectionListeners = () => {
    window.addEventListener("gamepadconnected", this.gamepadConnectedListener);
    window.addEventListener(
      "gamepaddisconnected",
      this.gamepadDisconnectedListener
    );
  };

  removeGamepadConnectionListeners = () => {
    window.removeEventListener(
      "gamepadconnected",
      this.gamepadConnectedListener
    );
    window.removeEventListener(
      "gamepaddisconnected",
      this.gamepadDisconnectedListener
    );
  };

  gamepadConnectedListener = (e) => {
    this.controllers[e.gamepad.index] = e.gamepad;
  };

  gamepadDisconnectedListener = (e) => {
    delete this.controllers[e.gamepad.index];
  };

  scangamepads = () => {
    let gamepads = [];

    if (navigator.getGamepads) {
      gamepads = navigator.getGamepads();
    }

    if (navigator.webkitGetGamepads) {
      gamepads = navigator.webkitGetGamepads();
    }

    for (let i = 0; i < gamepads.length; i++) {
      if (gamepads[i]) {
        this.controllers[i] = gamepads[i];
      }
    }
  };

  update = () => {
    if (!this.isInCall) {
      return;
    }

    if (!this.disableInputs) {
      this.scangamepads();
      this.pollControlPads(true);
    }

    this.resizeVideo();
    //this.renderVideo();

    window.requestAnimationFrame(this.update);
  };

  onRemoteTrackAdded = (evt) => {
    if (evt.streams === undefined || evt.streams.length === 0) {
      console.warn("WebRTC onRemoteTrackAdded: no streams");
      return;
    }

    const stream = evt.streams[0];
    this.remoteVideo.srcObject = stream;
    this.streamSet = true;
    this.rvstream = stream;
  };

  dialVSP = () => {
    if (this.calling) {
      this.calling = false;
      let dialURL = `https://${this.webRTCServer}/sdp?destination=${this.dest}&session=${this.sessionID}`;
      console.info("[LEGACY WEBRTC] dialVSP: " + dialURL);

      this.httpRequest = new XMLHttpRequest();
      this.httpRequest.open("POST", dialURL);
      this.httpRequest.setRequestHeader("Content-Type", "application/sdp");
      this.httpRequest.onreadystatechange = this.VSPResponse;

      if (this.peerConnection && this.peerConnection.localDescription) {
        this.httpRequest.send(this.peerConnection.localDescription.sdp);
      } else {
        console.log("[LEGACY WEBRTC] Peer Connection already Closed!");
      }
    }
  };

  VSPResponse = () => {
    if (this.httpRequest.readyState !== this.httpRequest.DONE) return;

    if (this.httpRequest.status !== 200) {
      this.onError(
        `[LEGACY WEBRTC] answer failed: status = ${this.httpRequest.status}`,
        this.httpRequest
      );
      return;
    }

    let sdp = this.httpRequest.responseText;
    // let sdp = this.httpRequest.responseText.replace("42001f", "42e01f");
    // sdp = sdp.replace("maxaveragebitrate=510000", "maxaveragebitrate=48000");

    // console.info("[LEGACY WEBRTC] answer SDP received:" + sdp.length + "bytes");
    // console.info("[LEGACY WEBRTC] answer SDP:\n" + sdp);

    this.peerConnection
      .setRemoteDescription({ type: "answer", sdp: sdp })
      .then(() => {
        console.log("[LEGACY WEBRTC] answer SDP accepted, media starting");
      })
      .catch((error) => {
        this.onError("[LEGACY WEBRTC] Offer SDP unacceptable", error);
      });
  };

  resizeVideo = () => {
    const cw = window.innerWidth;
    const ch = this.oskVisible
      ? window.innerHeight - oskHeight
      : window.innerHeight;
    const ct = 0;
    const cl = 0;

    if (this.videoSize != null) {
      //this.remoteVideo.removeAttr('hidden');
      var ow = this.remoteVideo.videoWidth;
      var oh = this.remoteVideo.videoHeight;

      var aspect = this.videoSize.aspect;
      if (aspect < 0) aspect = ow / oh;

      var vw, vh;
      if (ch * aspect < cw) {
        vw = Math.round(ch * aspect);
        vh = ch;
      } else {
        vw = cw;
        vh = Math.round(cw / aspect);
      }

      // If postprocessing is enabled ensure canvas is resized.
      if (this.postprocessing) {
        if (this.videoPostprocessingCanvas.width != vw) {
          this.videoPostprocessingCanvas.width = vw;
        }
        if (this.videoPostprocessingCanvas.height != vh) {
          this.videoPostprocessingCanvas.height = vh;
        }
      }

      if (this.videoOutputTarget.style.width != vw + "px") {
        this.videoOutputTarget.style.width = vw + "px";
      }
      if (this.videoOutputTarget.style.height != vh + "px") {
        this.videoOutputTarget.style.height = vh + "px";
      }

      this.videoOutputTarget.style.marginTop = +(ch / 2 - vh / 2 - ct) + "px";
      this.videoOutputTarget.style.marginLeft = +(cw / 2 - vw / 2 - cl) + "px";
    } else {
      this.videoOutputTarget.style.width = "0px";
      this.videoOutputTarget.style.height = "0px";
    }

    if (this.postprocessing && !this.postprocessLatencyDebugging) {
      this.remoteVideo.style.height = "0px";
      this.remoteVideo.style.width = "0px";
    }
  };

  renderVideo = () => {
    if (!this.postprocessing) {
      return;
    }

    if (this.remoteVideo.videoWidth != 0) {
      const vh = this.remoteVideo.videoHeight;

      if (this.postprocessLatencyDebugging) {
        this.remoteVideo.style.height = this.remoteVideo.videoHeight + "px";
        this.remoteVideo.style.width = this.remoteVideo.videoWidth + "px";
      }

      // Ensure super-sampling canvas is not too large
      const maxSuperSamplingHeight = 2160;
      var superSampling = 8;
      while (vh * superSampling > maxSuperSamplingHeight) {
        superSampling /= 2;
      }

      const ctxFront = this.videoPostprocessingCanvas.getContext("2d");

      if (this.videoScalingMode == 0) {
        const ssc = this.videoSuperSamplingCanvas;
        if (ssc.width != this.remoteVideo.videoWidth * superSampling)
          ssc.width = this.remoteVideo.videoWidth * superSampling;
        if (ssc.height != this.remoteVideo.videoHeight * superSampling)
          ssc.height = this.remoteVideo.videoHeight * superSampling;

        const ctxSuperSampling = this.videoSuperSamplingCanvas.getContext("2d");

        ctxSuperSampling.imageSmoothingEnabled = false;
        ctxSuperSampling.drawImage(
          this.remoteVideo,
          0,
          0,
          ssc.width,
          ssc.height
        );

        ctxFront.imageSmoothingEnabled = true;
        ctxFront.drawImage(
          this.videoSuperSamplingCanvas,
          0,
          0,
          this.videoPostprocessingCanvas.width,
          this.videoPostprocessingCanvas.height
        );
      } else if (this.videoScalingMode == 1) {
        ctxFront.imageSmoothingEnabled = false;
        ctxFront.drawImage(
          this.remoteVideo,
          0,
          0,
          this.videoPostprocessingCanvas.width,
          this.videoPostprocessingCanvas.height
        );
      } else if (this.videoScalingMode == 2) {
        ctxFront.imageSmoothingEnabled = true;
        ctxFront.drawImage(
          this.remoteVideo,
          0,
          0,
          this.videoPostprocessingCanvas.width,
          this.videoPostprocessingCanvas.height
        );
      }
    }

    this.remoteVideo.requestVideoFrameCallback(this.renderVideo);
  };

  setFixedJoysticks = (flag) => {
    this.fixedJoysticks = flag;

    if (this.controls) {
      this.controls.setFixedJoysticks(flag);
    }
  };

  tryUnmute = () => {
    this.unmuteEnabled = true;
    if (isDeviceChrome() || deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV) {
      this.mute(false);
    }
  };

  createDataChannel = () => {
    this.dataChannel = this.peerConnection.createDataChannel("AntDataChannel");
    this.network = new AntRTCNetwork(
      this.dataChannel,
      null,
      this.sessionStart,
      this.controlsString
    );

    this.dataChannel.onopen = () => {
      console.info("[LEGACY WEBRTC] AntDataChannel is open now");

      this.messageSendTime = performance.now();
      this.network.sendControlsString();
    };

    this.dataChannel.onerror = (error) => {
      this.onError("[LEGACY WEBRTC] Data channel error", error);
    };

    this.dataChannel.onmessage = (e) => {
      var obj = null;
      try {
        obj = JSON.parse(e.data);
      } catch (error) {
        this.onError(
          "[LEGACY WEBRTC] Could not parse JSON event from server",
          error
        );
      }

      if (!obj) {
        return;
      }

      if (obj.type === PacketTypeString.CG_PACKET_VIDEO_SETUP) {
        this.network.sendPacketID();
        this.isVideoSetup = true;
        this.videoSize = obj;
        this.resizeVideo();
        this.renderVideo();
      } else if (obj.type === PacketTypeString.CG_PACKET_PING_REPLY) {
        if (this.network && this.perf) {
          this.perf.reportPing(
            this.network.getPingDelay(window.performance.now())
          );
        }
      } else if (obj.type === PacketTypeString.CG_PACKET_PAUSE_CONFIRM) {
        this.isPaused = obj.paused > 0 ? true : false;
        if (!this.initialUnpause && !this.isUIPaused()) {
          if (this.gameType == 0 || this.gameType == 3) {
            if (this.isPaused) {
              this.initialUnpause = true;
              this.unpause();
            }
          }
        }
      } else if (obj.type === PacketTypeString.CG_PACKET_DATA_DISPLAY_SETUP) {
        // TODO. Containa HUD setup values
        this.onInGameEvent(obj);
      } else if (obj.type === PacketTypeString.CG_PACKET_SAVE_RESPONSE) {
        this.onSaveResponse(obj);
      } else if (obj.type === PacketTypeString.CG_PACKET_INPUT_SETUP_RAW) {
        this.onVideoSetup();
        this.tryUnmute();
        this.network.sendLocalPlayerSlot(0, 0);
        this.onInGameEvent(obj);
        this.controls = new Controls(
          this.controlsCanvas,
          this.network,
          this.gameProfile,
          obj,
          this.fixedJoysticks,
          this.videoOutputTarget
        );
        this.controls.init();
      }
      // Got an event from Lua.
      else {
        this.onInGameEvent(obj);
      }
    };
  };
}
