import * as actions from './notifications.actions';

const initialState = {
	notificationsQueue: [],
	title: null,
	summary: null,
	className: null,
	userData: null,
	notificationType: null,
	showLatest: null
};

export const notificationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SHOW_NOTIFICATION:
			return {
				...state,
				title: action.title,
				summary: action.summary,
				className: action.className,
				userData: action.userData,
				notificationType: action.notificationType
			};
		case actions.LATEST_NOTIFICATION_ACTION:
			return {
				...state,
				showLatest: action.show
			};
		case actions.ADD_NOTIFICATION_TO_QUEUE_ACTION: {
			return {
				...state,
				notificationsQueue: state.notificationsQueue.concat([action.payload])
			};
		}
		case actions.REMOVE_LAST_NOTIFICATION_FROM_QUEUE_ACTION: {
			return {
				...state,
				notificationsQueue: state.notificationsQueue.slice(0, -1)
			};
		}
		default:
			return state;
	}
};
