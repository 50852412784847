import React from 'react';
import './free-tier-banner.component.less';

import FreeTierBannerContent from './free-tier-banner-content/free-tier-banner-content.component'

export const FreeTierBanner = (props) => { // TODO we can refactor this to give hero-banner component a background image prop instead 
    return (
        <div className='free-tier-banner'>
           <FreeTierBannerContent {...props}/>
        </div> 
    )
}

export default FreeTierBanner
