export const determineTournamentPlayCost = (tournamentData, defaultGemPlayCost) => {
    if (typeof tournamentData?.sponsoredData?.playCost !== 'number') return defaultGemPlayCost;
    return tournamentData?.sponsoredData?.playCost;
};

/**
 * builds unique label for a tournament
 * example "ZOTAC Gaming Tournament 05-2023 644142ad161e168435121bd6"
 **/
export const buildSponsoredTournamentLabel = (tournamentData) => {
    const d = new Date();
    const month = d.getUTCMonth() + 1;
    const leadingZero = month < 10 ? '0' : '';

    return `${tournamentData?.title} ${leadingZero}${month}-${d.getUTCFullYear()} ${tournamentData?._id}`;
};
