import React, {Component} from "react";
import Countdown from "./countdown.component";
import PodiumComponent from "../../leaderboard/podium.component";
import {
	getCurrentGameLeaderboardFirstUser
} from "../../game-details/game-details.selector";
import {connect} from "react-redux";
import {FetchGameLeaderboardAction} from "../../game-details/game-details.actions";
import inputmanager, {PARENT_ENTRANCE} from "../../../assets/lib/inputmanager";
import {getCurrentTournamentDetailId} from "../tournaments.selector";
import {getTournamentShortCode, isXboxCrossplayEnabled, isXboxUser} from "../../../assets/lib/utils";
import {getLoggedUserAccess} from "../../../app.selectors";
import { LIST_WORLDWIDE, LIST_XBOX, ListElementsDetails, extraFilterButtons, defaultButtons, getEnabledFilterButtons } from "../../leaderboard/leaderboard.component";
import FilterButton from '../../leaderboard/filterButton/filterButton.component';
import joystickPurple from "../../../assets/images/global/joystick-purple.png";
import joystickGrey from "../../../assets/images/global/joystick-grey.png";
import { addPopup } from "../../popup/popup.component";
import XboxCrossplayOnlyPopup from "../../popup/xbox-crossplay-only-popup/xbox-crossplay-only-popup.component";

class TournamentsInfoSpectatorStateComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedList: this.props.isXboxCrossplayTurnedOff ? LIST_XBOX : LIST_WORLDWIDE,
		};
	}

	componentDidMount() {
		this.setList(this.props.isXboxCrossplayTurnedOff ? LIST_XBOX : LIST_WORLDWIDE);
	}

	setList = (listType) => {
		if (this.props.isXboxCrossplayTurnedOff && listType === LIST_WORLDWIDE) {
			addPopup(<XboxCrossplayOnlyPopup />);
			return;
		}

		this.fetchList(listType);
		this.setState({
			selectedList: listType,
		});
	};

	fetchList = (listType, offset) => {
		const { challengeData, tournamentData, dispatch } = this.props;

		if (!tournamentData) return null;

		const accessType = tournamentData.leaderboardFilterAccessType || null;
		const tournamentId = getTournamentShortCode(tournamentData._id, challengeData._id);

		dispatch(FetchGameLeaderboardAction(listType, tournamentId, challengeData._id, tournamentData._id, offset, accessType, this.onListResponse));
	};

	onListResponse = (offset) => {
		inputmanager.refreshBoundingRects('podium-container');
	};

	onPodiumContainerRef = (element) => {
		inputmanager.onParentRef(element, "podium-container", false, PARENT_ENTRANCE.ONLY_HORIZONTAL);
	};

	render() {
		const {tournamentData, userAccess} = this.props;

		const { podiumImageSource } = ListElementsDetails[this.state.selectedList]
		const filterButtons = tournamentData ? [...defaultButtons, ...getEnabledFilterButtons(extraFilterButtons, tournamentData, userAccess)] : defaultButtons 

		if (this.props.isXboxPlayer) {
			filterButtons.push({
				list: LIST_XBOX,
				text: 'xbox',
				enabledImage: joystickPurple,
				disabledImage: joystickGrey,
				className: 'xbox'
			});
		}

		const isSponsoredWithZero = tournamentData?.sponsored && !tournamentData.prizePot;

		return (
			<div className="tournaments-info-component finished-spectator-state">
				<div className="content-left">
					<div className="top-container">
						<h2>{this.props.challengeData && this.props.challengeData.title}</h2>
						<div className="switch-button-container">
							{
								filterButtons && filterButtons.map((filterButton) => {
									const {list, enabledImage, disabledImage, text, className } = filterButton;
									const isActive = this.state.selectedList === filterButton.list
									return (
										<FilterButton
											key={`${text}-button`}
											onClick={() => this.setList(list)}
											isActive={isActive}
											image={isActive ? enabledImage : disabledImage}
											parentId="filterButtonsContainer"
											text={text}
											imageAlt={`${text}-icon`}
											childId={`${text}-button`}
											className={`switch-button ${className}`}
											{...filterButton}
									/>)
								})
							}
						</div>
					</div>
					<div className={`bottom-container ${this.state.selectedList === LIST_WORLDWIDE ? 'global' : ''}`}>
						<div className="trophy-container">
							{!isSponsoredWithZero && <div className="trophy-icon">
								<div className="trophy-icon-score">
									<div className="label">
										Prize Pot
									</div>
									{new Intl.NumberFormat('en-GB').format(this.props.tournamentData.prizePot)}
								</div>
							</div>}
						</div>
						<PodiumComponent
							onPodiumContainerRef={this.onPodiumContainerRef}
							firstUser={this.props.firstUser}
							podiumImageSource={podiumImageSource}
						/>
					</div>
				</div>
				<div className="content-right">
					<div className="image-container">
						<Countdown startTimeStamp={this.props.tournamentData.startDate}
								   endTimeStamp={this.props.tournamentData.endDate}/>
						<img className="tournament-image" src={this.props.tournamentData.detailImage} alt=""/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const tournamentId = getCurrentTournamentDetailId(state);
	const firstUser = getCurrentGameLeaderboardFirstUser(state);
	const userAccess = getLoggedUserAccess(state);
	const isXboxPlayer = isXboxUser(state);
	const isXboxCrossplayTurnedOff = isXboxPlayer && !isXboxCrossplayEnabled(state);

	return {
		isXboxPlayer,
		isXboxCrossplayTurnedOff, // cross play disabled
		userAccess,
		tournamentId,
		firstUser
	};
};

export const TournamentsInfoSpectatorState = connect(mapStateToProps)(TournamentsInfoSpectatorStateComponent);
