import React from 'react';
import PropTypes from 'prop-types';
import './payment-box.component.less';

const CURRENCIES = {
    "thb": "฿",
    "gbp": "£",
    "eur": "€",
    "usd": "$"
};

/**
 * Exchanges a currency string for its symbol
 * @param {string} currency - the currency to exchange
 * @returns {string} the symbol of the currency
 */
export const getCurrency = currency => {
    if (!currency) {
        return '';
    }

    return CURRENCIES[currency.toLowerCase()] || currency;
};

const PaymentBox = ({title, currency = "gdp", amount, subTitle, extraTitle, handleClick}) => {
    const currencyPrefix = getCurrency(currency);
    return (
    <div className="payment-box" onClick={() => handleClick && handleClick()} >
        {title && <div className="title">{title}</div>}
        {currencyPrefix && amount && <div className="currency">
            <span className="icon">{currencyPrefix}</span>
            <span className="amount">{amount}</span>
        </div>}
        {subTitle && <div className="sub-title">{subTitle}</div>}
        {extraTitle && <div className="extraTitle">{extraTitle}</div>}
    </div>
)};

PaymentBox.propTypes = {
    title: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
	currency: PropTypes.string.isRequired,
	amount: PropTypes.number.isRequired,
	secondTitle: PropTypes.string,
	subTitle: PropTypes.string,
};


export default PaymentBox;