import React from 'react';

import './pulsar.component.less';


const PulsarComponent = ({borderWithRadius, borderRound}) => {
	return (
		<div className="pulsar-component">
			<div className={`back-border ${borderWithRadius?'radius':''} ${borderRound? 'round' : ''}`}/>
			<div className={`front-border ${borderWithRadius?'radius':''} ${borderRound? 'round' : ''}`}/>
		</div>
	);
};

export { PulsarComponent };
