import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLoggedUser, isFreeTierBannerNeeded } from '../../app.selectors';
import { TabMenu } from '../common/tab-menu/tab-menu.component';
import { ROUTES, navigateToLocation } from '../../app.router';
import './settings.component.less';
import inputManager from '../../assets/lib/inputmanager';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import { FreeTierBanner } from '../common/hero-carousel/free-tier-banner/free-tier-banner.component';
import HeroBanner from '../common/hero-carousel/hero-banner/hero-banner.component';
import { externalFocusableComponent as externalFocusableFreeTierComponent } from '../free-tier-information/free-tier-information.component';
import { isXboxUser, requireAuth } from '../../assets/lib/utils';
import { Route } from 'react-router-dom';
import { SettingsAccount } from './account/settings-account.component';
import { SettingsSupport } from './support/settings-support.component';
import { SettingsHelpfulInfo } from './helpful-info/settings-helpful-info.component';
import SettingsPrivacyPolicyComponent from './privacy-policy/settings-privacy-policy.component';
import SettingsHealthSafetyComponent from './health-safety/settings-health-safety.component';
import SettingsTermsOfUseComponent from './terms-of-use/settings-terms-of-use.component';
import { SettingsDebug } from './debug/settings-debug.component';
import deviceInfo from '../../assets/lib/deviceInfo';

class SettingsComponent extends Component {
	constructor() {
		super();
		this.state = {
			isBannerSelected: false
		};
		this.menuSelect = this.onMenuSelect.bind(this);
		this.bannerRef = null;
	}

	componentDidMount(){
		inputManager.setBackFunction(()=>{navigateToLocation(ROUTES.HOMEPAGE)});
	}

	componentWillUnmount(){
		inputManager.setBackFunction(null);
	}

	onMenuSelect(menuItem) {
		const id = this.props.loggedUserId;
		navigateToLocation(menuItem.route, {id});
	}

	onBannerComponentRef = (element) => {
		this.bannerRef = element;
		inputManager.onChildRef(element,"freeTierBanner", this.onFreeTierFocus);
	};

	onFreeTierFocus = (focus) => {
		this.setState({isBannerSelected:focus});
		if(this.bannerRef){
			this.bannerRef.focus();
		}
	};

	onFreeTierBannerClicked = () => {
		inputManager.setCurrentChildById("freeTierBanner");
		navigateToLocation(ROUTES.FREE_TIER_INFORMATION,{ focusElement: externalFocusableFreeTierComponent.BACK_BUTTON})
	};

	render() {
		const { isFreeTierBannerNeededValue, isXboxPlayer } = this.props;
		const { isBannerSelected } = this.state;

		const menu =[
			{title: "Account", route: ROUTES.SETTINGS_ACCOUNT, index:0},
			{title: "Support", route: ROUTES.SETTINGS_SUPPORT, index:1},
		];
		if (isXboxPlayer || deviceInfo.isPlayStationPlatform()) {
			menu.push({title: "Privacy Policy", route: ROUTES.SETTINGS_PRIVACY_POLICY, index:4});
			menu.push({title: "Terms Of Use", route: ROUTES.SETTINGS_TERMS_OF_USE, index:5});
			menu.push({title: "Health & Safety", route: ROUTES.SETTINGS_HEALTH_SAFETY, index:6});
		} else {
			menu.push({title: "Helpful Info", route: ROUTES.SETTINGS_HELPFUL_INFO, index:2});
		}
		if (featureInfo.isSupported(FEATURES.DEBUG_SETTINGS)) {
			menu.push({title: 'Debug', route: ROUTES.SETTINGS_DEBUG, index:3})
		}

		return (
			<section 
				className="settings-component"
			>
				 {isFreeTierBannerNeededValue && 
					 <div 
						className="settings-component-free-tier-banner-wrapper"
						childid="freeTierBanner"
						ref={this.onBannerComponentRef}
						onClick={this.onFreeTierBannerClicked}
						tabIndex={0}
					>
						<HeroBanner
							isSelected={isBannerSelected}
							>
							<FreeTierBanner/>
						</HeroBanner> 
					</div>
				 }
				<div className="settings-content-wrapper">
					<h1>Settings</h1>
					<TabMenu items={menu} onSelect={this.menuSelect} >
						<div className="settings-content-container">
							<>
								<Route path={ROUTES.SETTINGS_ACCOUNT.path} component={SettingsAccount} onEnter={requireAuth} />
								<Route path={ROUTES.SETTINGS_SUPPORT.path} component={SettingsSupport} />
								<Route path={ROUTES.SETTINGS_HELPFUL_INFO.path} component={SettingsHelpfulInfo} />
								<Route path={ROUTES.SETTINGS_PRIVACY_POLICY.path} component={SettingsPrivacyPolicyComponent} />
								<Route path={ROUTES.SETTINGS_HEALTH_SAFETY.path} component={SettingsHealthSafetyComponent} />
								<Route path={ROUTES.SETTINGS_TERMS_OF_USE.path} component={SettingsTermsOfUseComponent} />
								<Route path={ROUTES.SETTINGS_DEBUG.path} component={SettingsDebug} />
							</>
						</div>
					</TabMenu>
				</div>
		 	</section>
		);
	}
}

const mapStateToProps = (state) => {
	const loggedUser = getLoggedUser(state)
	const isXboxPlayer = isXboxUser(state);
	return {
		isXboxPlayer,
		loggedUserId: loggedUser && loggedUser._id,
		getUser: loggedUser,
		isFreeTierBannerNeededValue: isFreeTierBannerNeeded(state)
	};
};

export const Settings = connect(mapStateToProps)(SettingsComponent);