import * as ACTIONS from './settings.actions';
import {LOGOUT_USER_ACTION} from '../login/login.actions';

const initialState = {
	connectionTesterPopupSeen: undefined,
	bandwidthTestResults: null,
	bandwidthTestRequested: false,
	connectionTestResults: null,
	testSessionRequested: false,
	testSessionCreated: false,
	access: null,
	controlEditing: false,
	showLagGraph: false,
	headlessNative: false,
    showControllerDebug: false,
	qualitySetting: null,
	consoleOpen: false,
	fpsPanelOpen: false,
	localGameServer:null,
	uiURL:null,
	betaUser:null,
	noVblank: true,
    useSmoothing: false,
    useRumble: false,
	regions:null,
	currentRegionObject:null,
	connectionTesterSupported:null,
	language: 'en',
	controlPreferencesDpad: "flexible",
	controlPreferencesRumble: true,
	controlPreferencesTransparency: 10,
	controlPreferencesVolume: 100,
};

export const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACTIONS.FETCH_DEBUG_SETTINGS_ACTION_SUCCESS:
	case ACTIONS.SET_DEBUG_SETTINGS_ACTION_SUCCESS:
		return { 
			...state,
			...action.payload
		};
	case ACTIONS.GET_USER_REGION_ACTION:
		return {
			...state,
			regions: action.regions,
			currentRegionObject: action.currentRegionObject,
		};
	case ACTIONS.SET_USER_REGION_ACTION:
		return {
			...state,
			regions:{
				...state.regions,
				myRegion: action.payload
			}
		};
	case ACTIONS.ON_CONNECTION_TEST_RESULTS:
		return {
			...state,
			connectionTestResults:action.payload,
			testSessionRequested: false,
			testSessionCreated: false,
		};
	case ACTIONS.ON_TEST_SESSION_REQUESTED:
		return {
			...state,
			connectionTestResults:null,
			testSessionRequested:true,
			testSessionCreated: false
		};
	case ACTIONS.ON_TEST_SESSION_CREATED:
		return {
			...state,
			testSessionRequested: false,
			testSessionCreated: true
		};
	case ACTIONS.CLEAR_TEST_SESSION_DATA:
		return {
			...state,
			connectionTestResults: null,
			testSessionRequested: false,
			testSessionCreated: false
		};
	case ACTIONS.REQUEST_BANDWIDTH_TEST:
		return {
			...state,
			bandwidthTestRequested: true,
			bandwidthTestResults:null,
		};
	case ACTIONS.ON_BANDWIDTH_TEST_RESULTS:
		return {
			...state,
			bandwidthTestRequested: false,
			bandwidthTestResults: action.payload
		};
	case ACTIONS.CLEAR_BANDWIDTH_TEST_DATA:
		return {
			...state,
			bandwidthTestRequested: false,
			bandwidthTestResults: null,
		};
	case ACTIONS.SET_CONNECTION_TESTER_SUPPORTED:
		return {
			...state,
			connectionTesterSupported: action.connectionTesterSupported,
		};
	case LOGOUT_USER_ACTION:
		return {};
	case ACTIONS.UPDATE_USER_LANGUAGE_ACTION:
		return {
			...state,
			language: action.language
		};
	case ACTIONS.SET_CONTROL_PREFERENCES_DPAD_ACTION:
		return {
			...state,
			controlPreferencesDpad: action.payload
		};
	case ACTIONS.SET_CONTROL_PREFERENCES_RUMBLE_ACTION:
		return {
			...state,
			controlPreferencesRumble: action.payload
		};
	case ACTIONS.SET_CONTROL_PREFERENCES_TRANSPARENCY_ACTION:
		return {
			...state,
			controlPreferencesTransparency: action.payload
		};
	case ACTIONS.SET_CONTROL_PREFERENCES_VOLUME_ACTION:
		return {
			...state,
			controlPreferencesVolume: action.payload
		};
	default:
		return state;
	}
};
