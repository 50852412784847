import React from 'react';

import { Button } from './../button/common-button.component';
import './drop-down-selection-button.less';
import dropDownArrowIcon from '../../../assets/images/global/dropdown-arrow.svg';

const DropDownSelectionButton = (props) => {
	return (
		<Button
			{...props}
			className={`${props.className} drop-down-selection-button`}
		>
			<div className="drop-down-content">
				{props.children}
			</div>
			{!props.disabled && !props.noArrowIcon &&
				<div className="arrow-container">
					<img className="arrow-image" src={dropDownArrowIcon} alt="dropdownicon"/>
				</div>
			}
		</Button>
	);
};

export { DropDownSelectionButton };
