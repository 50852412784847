import React from 'react';

import { Button } from './common-button.component';
import './common-button-favourite.component.less';
import starImageSelected from '../../../assets/images/global/star-selected.png';
import starImage from '../../../assets/images/global/star.png';

const ButtonFavourite = (props) => {
	return (
		<Button
			{...props}
			className={`${props.className?props.className:''} ${props.selected ? 'primary' : 'secondary'} favourite`}
		>
			<div className="icon-container">
				<img className="favourite-icon" src={props.selected ? starImageSelected : starImage} alt="star-icon"/>
			</div>
		</Button>

	);
};

export { ButtonFavourite };
