import React from 'react';
import ReactHelmet from 'react-helmet'

const Helmet = ({title='Antstream', description="Home of retro gaming", image, imageAlt, url=window.location.href}) => {
    return (
        <ReactHelmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:description" content={description}/>
            <meta name="og:type" content="website" />
            {image && <meta property="og:image" content={image}/>}
            {image && <meta name="twitter:card" content={image}/>}
            <meta property="og:site_name" content="Antstream"/>
            {url && <meta property="og:url" content={url}/>}
            {imageAlt && <meta name="twitter:image:alt" content={imageAlt}/>}
        </ReactHelmet>
    )
}
export default Helmet;