import React, {Component} from 'react';
import { connect } from 'react-redux';

import './notifications.component.less';
import { Notification } from './notification/notification.component';
import {
	getNotificationClassName,
	getNotificationSummary,
	getNotificationTitle,
	getNotificationUserData,
	getNotificationNotificationType,
	getShowLatestNotification,
	getNotificationsQueue
} from './notifications.selectors';
import AudioManager, {TYPE_NOTIFICATION} from '../../app.audio';
import {getPopupVisibility} from "../popup/popup.selectors";
import {
	AddNotificationToQueueAction,
	LatestNotificationAction,
	RemoveLastNotificationFromQueueAction,
	ShowNotificationAction
} from "./notifications.actions";
import inputmanager from '../../assets/lib/inputmanager';
import deviceInfo from '../../assets/lib/deviceInfo';
import { GAME_STATE } from '../../constants';

const DURATION = 8000;

class Notifications extends Component {
	constructor(props) {
		super(props);

		this.state = {
			componentVisible: false,
			selected: false
		};
	}

	showNotification = () => {
		this.setState({componentVisible: true});
		AudioManager.playAudio(TYPE_NOTIFICATION);

		setTimeout(
			this.closeNotification,
			DURATION
		);
	}

	closeNotification = () => {
		if (this.state.componentVisible) {
			this.props.dispatch(ShowNotificationAction(null, null, null, null, null));
		}
		this.hideNotification();

		this.checkNotificationsInQueue();
	}

	hideNotification = () => {
		this.setState({ componentVisible: false, selected: false });

		if (!inputmanager.currentChild) { // focus only if there is no focus on another one item
			inputmanager.setCurrentChildById('profileButton', 'navBar');
		}
	}

	checkNotificationsInQueue = () => {
		if (!this.props.notificationsQueue.length) return;

		const lastQueued = this.props.notificationsQueue.slice(-1)[0];
		if (!lastQueued) return;

		this.props.dispatch(ShowNotificationAction(
			lastQueued.title,
			lastQueued.summary,
			lastQueued.className,
			lastQueued.userData,
			lastQueued.notificationType
		));

		this.props.dispatch(RemoveLastNotificationFromQueueAction());
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {title, summary, showLatest, isPopupHidden, dispatch, isInternalPopupsMode} = this.props;
		const {componentVisible} = this.state;

		if (!title && !summary) {
			if (showLatest) {
				dispatch(LatestNotificationAction(false));
			}

			if (prevProps.isInternalPopupsMode && !isInternalPopupsMode && this.props.notificationsQueue?.length) {
				this.checkNotificationsInQueue();
			}
			return;
		}

		// if playing game at this moment - add notification to the queue in order to show it only after game is finished
		if (isInternalPopupsMode) {
			dispatch(AddNotificationToQueueAction({
				title,
				summary,
				className: this.props.className,
				userData: this.props.userData,
				notificationType: this.props.notificationType
			}));
			dispatch(ShowNotificationAction(null, null, null, null, null));
			return;
		}

		if (!isPopupHidden && componentVisible) {
			//Hide notification component if there is an existing popup
			this.hideNotification();
		} else if (showLatest && !componentVisible) {
			//Show latest notification
			this.showNotification();
			dispatch(LatestNotificationAction(false));
		} else if (prevProps.title !== title || prevProps.summary !== summary) {
			//Show notification
			this.showNotification();
		}
	}

	onCustomFocusCloseButton = (focus) => {
		this.setState({ selected: focus });
	};

	onCloseButtonRef = (element) => {
		inputmanager.onChildRef(element, 'notification-close', this.onCustomFocusCloseButton, 'navBar');
	};

	render() {
		const {title, summary, className, userData, notificationType} = this.props;
		const {componentVisible} = this.state;

		const notificationItem = {
			title,
			summary,
			className,
			userData,
			notificationType
		}

		const visible = componentVisible ? 'visible' : '';
		const animation = (deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform())
			? '' : 'animation';

		return (
			<div className={`notifications ${animation} ${visible}`}>
				<div className="content">
					<Notification item={notificationItem} />
					{componentVisible &&
						<div
							ref={this.onCloseButtonRef}
							className={`close-button ${ this.state.selected ? 'selected' : '' }`}
							onClick={this.closeNotification}
						></div>
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const isPopupHidden = getPopupVisibility(state);
	const showLatest = getShowLatestNotification(state);
	const title = getNotificationTitle(state);
	const summary = getNotificationSummary(state);
	const className = getNotificationClassName(state);
	const userData = getNotificationUserData(state);
	const notificationType = getNotificationNotificationType(state);
	const notificationsQueue = getNotificationsQueue(state);

	const isInternalPopupsMode = state.gameDetails.gameState === GAME_STATE.LOADING
		|| state.gameDetails.gameState === GAME_STATE.READY
		|| state.gameDetails.gameState === GAME_STATE.ERROR
		|| state.gameDetails.gameState === GAME_STATE.PAUSED
		|| state.gameDetails.gameState === GAME_STATE.PLAYING;

	return {
		notificationsQueue,
		isInternalPopupsMode,
		title,
		summary,
		className,
		userData,
		notificationType,
		showLatest,
		isPopupHidden
	};
};

Notifications = connect(mapStateToProps)(Notifications);
export {Notifications};
