import { fromJS } from 'immutable';
import { FETCH_MESSAGES_SUCCESS } from './messages.actions';
import {messageNormalizer} from '../entities.normaliser';

const initialState = fromJS({});

export const messagesReducer = (state = initialState, action) => {
	switch (action.type) {
	case FETCH_MESSAGES_SUCCESS:
		const normalizedData = messageNormalizer(action.messages);
		const byId = { ...normalizedData.entities.messages };
		const allIds = [ ...normalizedData.result ];
		return { byId, allIds };
		
	default:
		return state;
	}
};
