import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../app.router';
import SelectAvatarComponent from '../select-avatar/select-avatar.component';
import { getAvatars } from '../login/login.selectors';
import { SetUserAvatarAction } from '../login/login.actions';

import './change-avatar.component.less';
import {getLoggedUser} from '../../app.selectors';
import {ShowNotificationAction} from "../notifications/notifications.actions";


export const ChangeAvatar = () => {
	const avatarList = useSelector((state) => getAvatars(state));
	if(!avatarList) return null;
	const avatar = useSelector((state) => getLoggedUser(state).profile_image_id);
	const dispatch = useDispatch();

	const saveAvatar = (selectedId) => {
		if(avatar !== selectedId) {
			dispatch(ShowNotificationAction("Settings Saved"));
			dispatch(SetUserAvatarAction(selectedId));
		}
		navigateToLocation(ROUTES.SETTINGS_ACCOUNT);
	};
	return (
		<section className="change-avatar-component">
			<SelectAvatarComponent
				avatarList={avatarList}
				initalAvatar={avatar}
				handleSelect={saveAvatar}
			/>
		</section>
	);
}
export default ChangeAvatar;