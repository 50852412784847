import React, {Component} from 'react'
import './xbox-subscription-popup.component.less';
import {getMicrosoftSubscriptionProducts, getPaymentData} from '../../../app.selectors';
import { connect } from 'react-redux';
import {getDateStringWithEpochWithNth} from '../../../assets/lib/utils';
import { Button } from "../../common/button/common-button.component";
import * as Popup from "../popup.component";
import inputManager from "../../../assets/lib/inputmanager";
import { FetchMicrosoftProductsAction, XboxSubscriptionActionPending } from "../../login/login.actions";
import { Loader } from "../../common/loader/loader.component";
import { startNativePayment } from "../../../assets/lib/game-wrapper";

const navLevel = 2;
export const popupType = {
    SUBSCRIPTION_RENEWAL: 'subscription-renewal',
    SUBSCRIPTION_EXPIRED: 'subscription-expired',
    PURCHASE_COMPLETE: 'purchase-complete'
};

/**
 * this is hardcoded xbox products IDs
 * we use them to apply the styles (image, color) to a product
 * */
const PRODUCTS_IDS = {
    oneYear: '9PHM2C95KK1V',
    threeYear: '9PKD3X5QCKJD',
};

const CURRENCIES = {
    "GBP": "£",
    "EUR": "€",
    "USD": "$"
};
const CANCEL_BTN_CHILD_ID = 'xbox-subscription-popup-cancel-button';

class XboxSubscriptionPopup extends Component {
    constructor() {
        super();
        this.state = {
            showConfirmationText: false,
            selectedProductId: null
        };
    }

    componentDidMount() {
        const { type, products } = this.props;

        if (this.shouldCloseImmediately()) {
            setTimeout(() => Popup.closePopup(), 500);
            return;
        }

        if (!Array.isArray(products) || !products.length) {
            this.props.dispatch(FetchMicrosoftProductsAction());
        }

        if (type === popupType.SUBSCRIPTION_RENEWAL || type === popupType.SUBSCRIPTION_EXPIRED) {
            if (products && products.length) {
                this.focusOn3YearProduct();
            }
        }

        if (this.isPopupClosable()) {
            // close popup using 'B' button (or Esc)
            inputManager.setBackFunction(() => Popup.closePopup()); 
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.products
            && this.props.products.length
            && (!prevProps.products || !prevProps.products.length)
        ) {
            this.focusOn3YearProduct();
        }

        if (this.shouldCloseImmediately()) {
            Popup.closePopup();
            return;
        }
    }

    componentWillUnmount() {
        if (this.isPopupClosable()) {
            inputManager.setBackFunction(null);
        }
    }

    shouldCloseImmediately = () => {
        // if subscription becomes true AFTER this popup was added to the queue - close this popup
        return this.props.type === popupType.SUBSCRIPTION_EXPIRED
            && this.props.isFromPostLogin
            && this.props.subscription === true
        ;
    }

    focusOn3YearProduct = () => {
        if (this.isLifetimeSubscription()) {
            // if lifetime subscription all products should be disabled
            inputManager.setCurrentChildById(CANCEL_BTN_CHILD_ID);
            return;
        }

        const { products } = this.props;
        if (!Array.isArray(products) || !products.length) return;

        const year3subscription = products.find(el => el.id === PRODUCTS_IDS.threeYear);
        if (year3subscription) {
            inputManager.setCurrentChildById(year3subscription.id);
        } else {
            inputManager.setCurrentChildById(products[0].id);
        }
    }

    renderTitle(type) {
        let text = null;
        if (type === popupType.SUBSCRIPTION_RENEWAL) {
            text = 'Subscription Renewal';
        } else if (type === popupType.SUBSCRIPTION_EXPIRED) {
            text = 'Subscription Expired';
        } else if (type === popupType.PURCHASE_COMPLETE) {
            text = 'Purchase Complete';
        }

        if (!text) return null;

        return <div className="title-text">{text}</div>;
    }

    isLifetimeSubscription = () => {
        const { paymentDue, subscription } = this.props;
        if (!subscription || !paymentDue) return false;

        const lifetimeEdge = 50; // 50 years

        const now = new Date();
        now.setFullYear(now.getFullYear() + lifetimeEdge);
        const lifetimeInSeconds = Math.floor(now.getTime() / 1000);

        return lifetimeInSeconds < paymentDue;
    }

    renderSubtitle = (type) => {
        let text = null;

        if (type === popupType.SUBSCRIPTION_RENEWAL) {
            if (this.isLifetimeSubscription()) {
                text = 'You have the lifetime edition';
            } else {
                // TODO: uncomment this after expiry date issue is resolved
                // text = 'Your current subscription ends ' + getDateStringWithEpochWithNth(this.props.paymentDue);
            }
        } else if (type === popupType.SUBSCRIPTION_EXPIRED) {
            text = 'Your subscription expired ' + getDateStringWithEpochWithNth(this.props.paymentDue);
        } else if (type === popupType.PURCHASE_COMPLETE) {
            text = null;
        }

        if (!text) return null;

        return <div className='subtitle-text'>{text}</div>;
    }

    shouldDisplayConfirmationText() {
        if (!this.props.subscription) return false;

        // paymentDue greater than Now
        return this.props.paymentDue > Math.floor(Date.now() / 1000); 
    }

    startSubscriptionPayment(productId) {
        if (this.state.showConfirmationText) {
            this.setState({showConfirmationText: false, selectedProductId: null});
        }

        const selectedProductId = productId || this.state.selectedProductId;
        this.props.dispatch(XboxSubscriptionActionPending()); // run spinner
        startNativePayment(null, selectedProductId); // send msg to native client
    }

    handleProductClick(productId) {
        // if we already have subscription - show confirmation text
        if (this.shouldDisplayConfirmationText()) {
            this.setState({
                showConfirmationText: true,
                selectedProductId: productId
            }, () => {
                // new buttons appeared - refresh the rects to fix the issue with
                inputManager.refreshBoundingRects();
                // focus on Cancel button
                inputManager.setCurrentChildById(CANCEL_BTN_CHILD_ID);
            });
        } else {
            this.startSubscriptionPayment(productId);
        }
    }

    getTheProductCost(product) {
        if (!product) return '';
        if (product.formattedPrice) return product.formattedPrice;

        if (product.price === null
            || product.price === undefined
            || product.price === '') return '';

        const dollars = Math.floor(product.price);
        const cents = Math.round(product.price * 100) % 100;

        const currencySymbol = CURRENCIES[product.currency] || '';

        return currencySymbol + dollars.toString() + '.' + cents.toString();
    }

    getTimeLeftString(epochTime) {
        const now = new Date().getTime();
        const epochDate = new Date(0);
        epochDate.setUTCSeconds(epochTime);
        const futureDate = epochDate.getTime();

        const timeleft = futureDate - now;

        const msPerSecond = 1000;
        const msPerMinute = msPerSecond * 60;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;

        const days = Math.floor(timeleft / msPerDay);
        // const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / msPerHour);
        // const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / msPerMinute);
        // const seconds = Math.floor((timeleft % (1000 * 60)) / msPerSecond);

        return days + ' days ';
    }

    renderMainContent(type, planId) {
        if (this.props.isLoading) {
            return (
                <div className="loading-content">
                    <Loader loading />
                    <p className="loading-content-text">Loading...</p>
                </div>
            );
        }

        if (this.state.showConfirmationText) {
            return (
                <div className="confirmation-text-content">
                    <p className="confirmation-text-content__item">
                        Warning! You still have {this.getTimeLeftString(this.props.paymentDue)} left of your subscription.
                    </p>
                    <p className="confirmation-text-content__item">
                        Purchasing this will start your new subscription today and you will lose the remaining time on your current subscription.
                    </p>
                </div>
            );
        }

        if (type === popupType.SUBSCRIPTION_RENEWAL || type === popupType.SUBSCRIPTION_EXPIRED) {
            if (!Array.isArray(this.props.products) || !this.props.products.length) {
                return (
                    <div className='subscription-renewal-content'>
                        No products available
                    </div>
                );
            }

            const productsDisabled = this.isLifetimeSubscription();
            return (
                <div className='subscription-renewal-content'>
                    {this.props.products.map((p) =>
                        <Button
                            disabled={productsDisabled}
                            stopBounceAnimation
                            onClick={() => this.handleProductClick(p.id)}
                            childId={p.id}
                            key={p.title}
                            className={`plan-button ${this.getProductClass(p.id)}`}
                            layer={navLevel}>
                            <div className="button-title-container">
                                <div className="button-title">{p.title}</div>
                            </div>
                            <div>
                                <div className='button-illus'></div>
                                <div className="button-cost">
                                    <div className={`button-cost-inner`}>
                                        {this.getTheProductCost(p)}
                                    </div>
                                </div>
                            </div>
                        </Button>
                    )}
                </div>
            );
        } else if (type === popupType.PURCHASE_COMPLETE) {
            const product = this.props.products.find(p => p.id === planId);
            return (
                <div className='subscription-purchase-complete'>
                    <div className="left-col">
                        <div className={`subscription-image ${this.getImageClass(planId)}`}/>
                    </div>
                    <div className="right-col">
                        <p className="right-col__paragraph">
                            Congratulations on your new {product ? product.title : 'subscription'}
                        </p>
                        <p className="right-col__paragraph">
                            {/*TODO: uncomment this after expiry date issue is resolved*/}
                            {/*Your current subscription ends on {getDateStringWithEpochWithNth(this.props.paymentDue)}*/}
                        </p>
                    </div>
                </div>
            );
        }

        return 'Unknown content';
    }

    getImageClass(productId) {
        const product = this.props.products.find(p => p.id === productId);
        if (!product) return '';

        if (product.id === PRODUCTS_IDS.oneYear) {
            return 'subscription-image--1year';
        } else if (product.id === PRODUCTS_IDS.threeYear) {
            return 'subscription-image--3year';
        }

        return '';
    }

    getProductClass(productId) {
        if (productId === PRODUCTS_IDS.oneYear) {
            return 'plan-button--1year';
        } else if (productId === PRODUCTS_IDS.threeYear) {
            return 'plan-button--3year';
        }

        return '';
    }

    isPopupClosable() {
        return this.state.showConfirmationText
            || this.props.type === popupType.PURCHASE_COMPLETE
            || this.props.type === popupType.SUBSCRIPTION_RENEWAL
            || (this.props.type === popupType.SUBSCRIPTION_EXPIRED
                && (window.config.REACT_APP_ENV === 'dev'))
        ;
    }

    renderControls() {
        if (this.state.showConfirmationText) {
            return (
                <>
                    <Button
                        className="secondary cancel-button"
                        onClick={Popup.closePopup}
                        childId={CANCEL_BTN_CHILD_ID}
                        layer={navLevel}
                    >
                        Close
                    </Button>
                    <Button
                        className="primary proceed-button"
                        onClick={() => this.startSubscriptionPayment()}
                        childId="xbox-subscription-popup-buy-button"
                        layer={navLevel}
                    >
                        Buy
                    </Button>
                </>
            );
        }

        if (this.props.type === popupType.PURCHASE_COMPLETE || this.props.type === popupType.SUBSCRIPTION_RENEWAL) {
            return (
                <Button
                    className="primary cancel-button"
                    onClick={() => Popup.closePopup()}
                    childId="xbox-subscription-popup-cancel-button"
                    layer={navLevel}
                >
                    Close
                </Button>
            );
        }

        if (this.props.type === popupType.SUBSCRIPTION_EXPIRED) {
            if (window.config.REACT_APP_ENV === 'dev') {
                return (
                    <Button
                        className="primary cancel-button"
                        onClick={() => Popup.closePopup()}
                        childId="xbox-subscription-popup-cancel-button"
                        layer={navLevel}
                    >
                        Close (DEV)
                    </Button>
                );
            }
        }

        return null;
    }

    renderAlreadyHaveSubscription() {
        if (this.props.subscription === true) return null;

        return (
            <div className='already-have-subscription'>
                <span className='already-have-subscription__1'>Already have a subscription?</span>
                <span className='already-have-subscription__2'>&nbsp;Ensure that the Xbox profile associated with the subscription has logged into Antstream Arcade before using other Xbox profiles.</span>
            </div>
        );
    }
    
    
    render() {
        return (
            <div className="xbox-subscription-popup">
                {this.renderTitle(this.props.type)}
                {this.renderSubtitle(this.props.type)}
                <div className="xbox-subscription-popup__content">
                    {this.renderMainContent(this.props.type, this.props.planId)}
                </div>
                {this.renderAlreadyHaveSubscription()}
                <div className="xbox-subscription-popup__buttons">
                    {this.renderControls()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const paymentData = getPaymentData(state) || {};
    return {
        isLoading: state.app.isXboxSubscriptionPopupLoading,
        paymentDue: paymentData.paymentDue,
        subscription: paymentData.subscription,
        products: getMicrosoftSubscriptionProducts(state)
    };
};

export default connect(mapStateToProps)(XboxSubscriptionPopup);
