import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
    maxLengthValidator,
    minLengthValidator,
    requiredValidator,
    emojiValidator,
    specCharactersValidator,
    trimValidator
} from '../../common/input/input.validator';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { getRegions } from '../../../components/settings/settings.selector';
import { getLoggedUser, getUserIsSubscribed, getPaymentData, getRoutingSearch } from '../../../app.selectors';
import { SetDisplayNameAction } from '../settings.actions';
import { FetchPaymentDataAction, handleLogoutFlow } from '../../login/login.actions';
import { Input } from '../../common/input/input.component';
import { Button } from '../../common/button/common-button.component';
import { openUrlOnDefaultBrowser } from '../../../assets/lib/game-wrapper';
import deviceInfo, {
    PLATFORM_TYPE_IPHONE,
    STORE_TYPE_APPLE_IOS,
    STORE_TYPE_EPIC,
    STORE_TYPE_SAMSUNG_TV
} from '../../../assets/lib/deviceInfo';
import { SetTutorialId } from '../../tutorial/tutorial.actions.js';
import inputManager from '../../../assets/lib/inputmanager';
import '../settings.component.less';
import { readRestUserData } from '../../../assets/lib/local-storage';
import { RegionsDropdown } from '../../regions-dropdown/regions-dropdown.component';
import {
    getDateStringWithEpoch,
    getDateStringWithEpochWithNth, isFreeTrialSubscription,
    isLifeTimeSubscription,
    isXboxCrossplayEnabled,
    isXboxUser
} from '../../../assets/lib/utils';
import { paymentUrl } from '../../../assets/lib/Antstream';
import { startPaymentProcess } from '../../../app.actions';
import { isDeviceWithNativePayments } from '../../../app.helpers';
import { ShowNotificationAction } from "../../notifications/notifications.actions";
import { cancelSubscription } from "../../true/true.actions";
import subscriptionQrCodeImage from "../../../assets/images/qr-codes/subscription.png";
import { antstreamService } from '../../../app.reducer';
import { addPopup } from '../../popup/popup.component';
import GenericPopup, { TYPE_HAS_TWO_BUTTONS } from '../../popup/generic-popup/generic-popup.component';


const PAGE_ID = 'settings_1';
const maxValue20 = maxLengthValidator(20);
const minValue2 = minLengthValidator(2);
const formId = "gamertagForm";

export const externalFocusableComponent = {
    SETTINGS_TAB_MENU: 'tab-menu',
    ACCOUNT_TAB: 'tab-menu-Account',
    NAME_INPUT: 'nameInput',
    EMAIL_INPUT: 'emailInput',
    CHANGE_AVATAR_BUTTON: 'changeAvatarButton',
    RESET_PASSWORD_BUTTON: 'resetPasswordButton',
    UPDATE_SUBSCRIPTION_BUTTON: 'updateSubscriptionButton',
    CANCEL_SUBSCRIPTION_BUTTON: 'cancelSubscriptionButton',
    DELETE_ACCOUNT_BUTTON: 'deleteAccountButton',
    SUBSCRIBE_BUTTON: 'subscribeButton',
    LOGOUT_BUTTON: 'logoutButton',
    SAVE_SETTINGS_BUTTON: 'saveSettingsButton'
}
class SettingsAccountComponent extends Component {
    constructor() {
        super();
        this.state = { betaAttempt: false, isUpdateDisplayNamePending: false };
    }

    componentDidMount() {
        const { focusElement } = this.props.currentParameters
        if (focusElement) {
            inputManager.setCurrentChildById(focusElement)
        } else {
            // focus on Account tab menu
            inputManager.setCurrentChildById(externalFocusableComponent.ACCOUNT_TAB, externalFocusableComponent.SETTINGS_TAB_MENU);
        }
    }

    UNSAFE_componentWillMount() {
        // If users have this session made a subscription then fetch payment data is out of date so don't do it
        !this.props.paymentData && this.props.dispatch(FetchPaymentDataAction());
        this.props.dispatch(SetTutorialId(PAGE_ID));
    }

    changeAvatar = () => {
        navigateToLocation(ROUTES.CHANGE_AVATAR);
    };    

    deleteAccount = (skipConfirmation) => {
        // TODO: remove this if statement after we make sure the feature works fine
        // for now it should work only for STORE_TYPE_APPLE_IOS
        if (deviceInfo.storeType !== STORE_TYPE_APPLE_IOS) {
            openUrlOnDefaultBrowser("https://support.antstream.com/hc/en-gb/articles/4407873960849-How-to-Manage-Account-Data-Deletion");
            return;
        }

        const forbiddenPlatforms = [
            deviceInfo.isPlayStationPlatform(),
            deviceInfo.isXboxPlatform(),
            deviceInfo.storeType === STORE_TYPE_EPIC,
            deviceInfo.storeType === STORE_TYPE_SAMSUNG_TV
        ];
        if (forbiddenPlatforms.some(Boolean)) {
            openUrlOnDefaultBrowser("https://support.antstream.com/hc/en-gb/articles/4407873960849-How-to-Manage-Account-Data-Deletion");
            return;
        }

        // You will be taken to the Antstream account management website. Proceed?
        if (!skipConfirmation) {
            addPopup(<GenericPopup
                title="Confirm"
                message="You will be taken to the Antstream account management website. Proceed?"
                cancelButtonLabel="No"
                okButtonLabel="Yes"
                buttonType={TYPE_HAS_TWO_BUTTONS}
                onOkClicked={() => this.deleteAccount(true)}
            />);
            return;
        }

        const paymentsAppUrlWithToken = antstreamService.getPaymentUrl();
        const urlWithDeleteAccountBtn = paymentsAppUrlWithToken + '&showAccountDeleteBtn=true';
        openUrlOnDefaultBrowser(urlWithDeleteAccountBtn);
    };

    onSubscribe = () => {
        startPaymentProcess(false);
    };

    onUpdateSubscription = () => {
        let url = paymentUrl;

        const storageData = readRestUserData();
        return openUrlOnDefaultBrowser(`${url}?authToken=${storageData ? storageData.authToken : ''}`);
    };

    onCancelSubscription = () => {
        this.props.dispatch(cancelSubscription());
    };    
    
    onCancelPaypalSubscription = () => {
        openUrlOnDefaultBrowser("https://support.antstream.com/hc/en-gb/articles/360002413818-Cancel-PayPal");
    };    
    
    onUpdateIOSSubscription = () => {
        openUrlOnDefaultBrowser("https://apps.apple.com/account/subscriptions");
    };
    

    renderSubscriptionButton = () => {
        if (this.props.isXboxUser) {
            if (this.props.paymentData) {
                if (isLifeTimeSubscription(this.props.paymentData)) {
                    return (
                        <div style={{ width: '15em', textAlign: 'center' }}>
                            Lifetime Subscription
                        </div>
                    );
                }
            }
            const paymentDueDate = this.props.paymentData ? getDateStringWithEpochWithNth(this.props.paymentData.paymentDue) : '';

            return (
                <div style={{ width: '15em', textAlign: 'center' }}>
                    {paymentDueDate}
                </div>
            );
        }

        if (deviceInfo.isPlayStationPlatform()) return null;

        const isIosFreeTrial = deviceInfo.storeType === STORE_TYPE_APPLE_IOS && this.props.paymentData && isFreeTrialSubscription(this.props.paymentData);
        
        if (this.props.isSubscribed && this.props.paymentData && !isIosFreeTrial) {
            if (this.props.paymentData.paymentType === 'stripe') {
                return <Button key="updateSubscriptionButton" className="secondary" onClick={this.onUpdateSubscription} childId={externalFocusableComponent.UPDATE_SUBSCRIPTION_BUTTON}>Update Subscription</Button>;
            } else if (this.props.paymentData.paymentType === 'trueTv' && (this.props.paymentData.subscriptionStatus === "ACTIVE" || this.props.paymentData.subscriptionStatus === "TRIALING")) {
                return <Button key="cancelSubscriptionButton" className="secondary" onClick={this.onCancelSubscription} childId={externalFocusableComponent.CANCEL_SUBSCRIPTION_BUTTON}>Cancel Subscription</Button>;
            } else if (this.props.paymentData.paymentType === 'paypal') {
                return <Button key="cancelPaypalSubscriptionButton" className="secondary" onClick={this.onCancelPaypalSubscription} childId={externalFocusableComponent.CANCEL_SUBSCRIPTION_BUTTON}>Cancel Subscription</Button>;
            }
            else if (this.props.paymentData.paymentType === 'ios') {
                return <Button key="updateIOSSubscriptionButton" className="secondary" onClick={this.onUpdateIOSSubscription} childId={externalFocusableComponent.CANCEL_SUBSCRIPTION_BUTTON}>Update Subscription</Button>;
            }
            else {
                return null;
            }
        } else {
            
            if (deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV) {
                return <div>
                    <img className="qr-code-_img" src={subscriptionQrCodeImage} alt={'Go to https://accounts.antstream.com'} />
                </div>
            }
            else if (!deviceInfo.hasNativeBrowserSupport() && !isDeviceWithNativePayments()) {
                return <div>
                    <p>Go to https://accounts.antstream.com</p>
                </div>
            }
            else if (deviceInfo.storeType === STORE_TYPE_EPIC) {
                return null;
            }
            else {
                return <Button key="subscribeButton" className="secondary" onClick={this.onSubscribe} childId={externalFocusableComponent.SUBSCRIBE_BUTTON}>{isDeviceWithNativePayments() ? "Subscribe" : "Subscribe Online"} </Button>;
            }
        }
    };

    renderFreeTrialText = (expireDate) => {
        return `Your free trial expires on ${expireDate}`;
    }
    renderExpiresText = (expireDate) => {
        return `Your subscription expires on ${expireDate}`;
    }

    renderExpiredText = (expireDate) => {
        return `Your subscription expired on ${expireDate}`;
    }

    renderRenewText = (expireDate) => {
        return `Your subscription renews on ${expireDate}`;
    }

    renderSubscriptionData() {
        {/*TODO: remove this if statement after expiry date issue is resolved*/}
        if (this.props.isXboxUser) { // if xbox - show subscription for lifetime only
            if (!this.props.paymentData) {
                return null;
            }
            if (!isLifeTimeSubscription(this.props.paymentData)) {
                return null;
            }
        }

        if (deviceInfo.isPlayStationPlatform()) return null;

        return (
            <ul className="settings-list">
                <li>
                    {this.renderCardData()}
                    {this.renderSubscriptionButton()}
                </li>
            </ul>
        );
    }

    renderXboxCrossplay() {
        if (!this.props.isXboxUser) return null;

        return (
            <div className="xbox-crossplay-setting">
                <div className="xbox-crossplay-setting__col xbox-crossplay-setting__col--left">
                    <span className="xbox-crossplay-setting__crossplay">
                        Cross Play
                    </span>
                    <span className="xbox-crossplay-setting__text">
                        (Enabling or disabling cross play can be done in your Xbox settings)
                    </span>
                    <div className="xbox-crossplay-setting__info">
                        Disabling cross play will limit your place in games, challenges and tournament global leaderboards. It will also limit you to only compete in duels and giant slayer matches with only Xbox players and not with other platforms.
                    </div>
                </div>
                <div className="xbox-crossplay-setting__col xbox-crossplay-setting__col--right">
                    <div className="xbox-crossplay-setting__btn">
                        {this.props.isXboxCrossplayEnabled ? 'Enabled' : 'Disabled'}
                    </div>
                </div>
            </div>
        );
    }

    renderCardData() {
        if (this.props.isXboxUser) {
            return <p>Current Subscription Ends</p>;
        }

        if (deviceInfo.isPlayStationPlatform()) return null;

        const paymentData = this.props.paymentData;

        if (paymentData && paymentData.subscriptionStatus === "TRIALING") {
            const date = new Date(0);
            date.setUTCSeconds(paymentData.paymentDue);
            const newDateWithSubscription = new Date(date.setMonth(date.getMonth() + 1)); // Add a month on after the end of the free trial
            const expireDate = newDateWithSubscription.toLocaleDateString();

            return `Your subscription expires on ${expireDate}`;
        }

        // Has our transaction gone through?
        if (paymentData && paymentData.subscriptionStatus === "past_due") {
            return (
                <div>
                    <p>Payment declined. Please update your subscription details.</p>
                </div>
            );
        }

        if (paymentData && this.props.isSubscribed) {
            if (paymentData.paymentType === 'stripe') {

                // Have we set a cancellation date, and if so is it in the future? (We can have a past date if we re-subscribed after cancelling)
                if (paymentData.removedSubscriptionEndDate && (paymentData.removedSubscriptionEndDate > new Date().getTime() / 1000)) {
                    var cancelDate = getDateStringWithEpoch(paymentData.removedSubscriptionEndDate);
                    return (
                        <div>
                            <p>{this.renderExpiresText(cancelDate)}</p>
                        </div>
                    );
                }

                // Otherwise, give the renewal date
                const renewDate = getDateStringWithEpoch(paymentData.paymentDue);
                const { brand, last4 } = this.props.paymentData.card || {};
                return (
                    <div>
                        {brand && last4 && <p>{`${brand} ending in ${last4}`}</p>}
                        <p>{this.renderRenewText(renewDate)}</p>
                    </div>
                );
            }

            if (paymentData.paymentType === 'amazon') {
                const expireDate = getDateStringWithEpoch(paymentData.paymentDue);
                return (
                    <div>
                        <p>You are currently subscribed with your Amazon account.</p>
                        <p>{this.renderExpiresText(expireDate)}</p>
                    </div>
                );
            } else if (paymentData.paymentType === 'microsoft') {
                const expireDate = getDateStringWithEpoch(paymentData.paymentDue);
                return (
                    <div>
                        <p>You are currently subscribed with your Microsoft Store account.</p>
                        <p>{this.renderExpiresText(expireDate)}</p>
                    </div>
                );
            } else if (paymentData.paymentType === 'google') {
                const expireDate = getDateStringWithEpoch(this.props.paymentData.paymentDue);
                return (
                    <div>
                        <p>You are currently subscribed with your Google Play Store account.</p>
                        <p>{this.renderExpiresText(expireDate)}</p>
                    </div>
                );
            } else if (paymentData.paymentType === 'trueTv') {
                const expireDate = getDateStringWithEpoch(paymentData.paymentDue);
                return (
                    <div>
                        <p>You are currently subscribed with your TrueId account.</p>
                        <p>{this.renderExpiresText(expireDate)}</p>
                    </div>
                );
            } else if (paymentData.paymentType === 'paypal') {
                // PayPal doesn't provide useful information like expiry/renewal dates
                return (
                    <div>
                        <p>You are currently subscribed with your PayPal account. If you want to cancel your subscription please visit the PayPal website.</p>
                    </div>
                );
            } else if (paymentData.paymentType === 'userPlan') {
                if (deviceInfo.storeType === STORE_TYPE_APPLE_IOS && isFreeTrialSubscription(paymentData)) {
                    const expireDate = getDateStringWithEpoch(paymentData.paymentDue);
                    return <p>{this.renderFreeTrialText(expireDate)}</p>
                }
                if (paymentData.paymentDue === 0) {
                    return <p>You have a lifetime subscription.</p>;
                } else {
                    const expireDate = getDateStringWithEpoch(paymentData.paymentDue);
                    return (
                        <div>
                            <p>You have a promo code subscription</p>
                            <p>{this.renderExpiresText(expireDate)}</p>
                        </div>
                    );
                }
            }
            else if (this.props.paymentData.subscription === 'PAYMENT_PENDING') { // Waysun Payment Pending state
                return <p>Payment Result Pending</p>;
            }

            return <p>You have a subscription.</p>;
        }

        if (paymentData && paymentData.lastInvoiceMessage) { // Show the error/payment message as payment attempt failed
            return (
                <div>
                    <p>Last Payment Attempt Issue: </p>
                    <p>{paymentData.lastInvoiceMessage}</p>
                </div>
            )
        }

        // No active subscription! 

        // Have we set cancelled in the past?
        if (paymentData && paymentData.removedSubscriptionEndDate && (paymentData.removedSubscriptionEndDate < new Date().getTime() / 1000)) {
            var cancelDate = getDateStringWithEpoch(paymentData.removedSubscriptionEndDate);
            return (
                <div>
                    <p>Please subscribe to Antstream.</p>
                    <p>{this.renderExpiredText(cancelDate)}</p>
                </div>
            );
        }

        return <p>Please subscribe to Antstream.</p>;
    }

    logout = () => {
        handleLogoutFlow(this.props.dispatch);
    };

    resetPassword = () => {
        navigateToLocation(ROUTES.PASSWORD_RESET_REQUEST);
    };

    saveData = (values) => {
        const { user } = this.props;
        if (values.displayName !== user.displayName) {
            this.updateDisplayName(values.displayName)
        }
    };

    updateDisplayName = (newDisplayName) => {
        const prevName = this.props.initialValues && this.props.initialValues.displayName;
        const nextName = newDisplayName.trim();
        if (prevName === nextName) { return; }
        if (!nextName.length) { return; }
        if (!this.props.valid) { return; }

        this.setState({ isUpdateDisplayNamePending: true }); // disable input on request pending
        this.props.dispatch(SetDisplayNameAction(nextName, () => {
            this.setState({ isUpdateDisplayNamePending: false });
            this.props.dispatch(ShowNotificationAction("Name Saved"));
        }, () => {
            this.setState({ isUpdateDisplayNamePending: false });
            this.props.dispatch(ShowNotificationAction("The display name is not available."));
            // revert to prev name if update failed
            this.props.change('displayName', prevName);
        }));
    }
    onSettingButtonsContainerRef = (element) => {
        inputManager.onParentRef(element, "settingButtonsContainer");
    };

    render() {
        const isNotUsingEmail = (deviceInfo.storeType === STORE_TYPE_EPIC) || deviceInfo.isTrueTvDevice();
        return (
            <div className="settings-account">
                <ul className="settings-list">
                    <form>
                        {(!this.props.isXboxUser && !deviceInfo.isPlayStationPlatform()) && <li className="settings-display-name-el">
                            <p>Name</p>
                            <Field
                                name="displayName"
                                component={Input}
                                disabled={this.state.isUpdateDisplayNamePending}
                                formId={formId}
                                validate={[minValue2, maxValue20, requiredValidator, emojiValidator, trimValidator, specCharactersValidator]}
                                childId={externalFocusableComponent.NAME_INPUT}
                                handleBlur={(displayName, isDirty) => {
                                    // if has been changed then try updating display name to see if its in use
                                    if (isDirty) this.updateDisplayName(displayName)
                                }}
                            />
                        </li>}
                        {!isNotUsingEmail && !this.props.isXboxUser && !deviceInfo.isPlayStationPlatform() && <li>
                            <p>Email</p>
                            <Field
                                name="email"
                                component={Input}
                                formId={formId}
                                disabled={true}
                                // validate={[ emailValidator ]} // no need to validate this because we never allow to edit this field
                                childId={externalFocusableComponent.EMAIL_INPUT}
                            />
                        </li>
                        }
                    </form>
                    <li>
                        <p>Avatar</p>
                        <Button className="secondary" onClick={this.changeAvatar} childId={externalFocusableComponent.CHANGE_AVATAR_BUTTON}>Change avatar</Button>
                    </li>
                    {!isNotUsingEmail && !this.props.isXboxUser && !deviceInfo.isPlayStationPlatform() && <li>
                        <p>Password</p>
                        <Button className="secondary" onClick={this.resetPassword} childId={externalFocusableComponent.RESET_PASSWORD_BUTTON}>Reset password</Button>
                    </li>}
                    {/*{(window.config.REACT_APP_ENV !== 'live' || userAccessAllowed('waysun',this.props.access)) && <li>*/}
                    {/*        <p>Change Language</p>*/}
                    {/*        <LanguagesDropdown/>*/}
                    {/*    </li>*/}
                    {/*}*/}
                    <li>
                        <p>Region</p>
                        <RegionsDropdown />
                    </li>
                    {!this.props.isXboxUser && !deviceInfo.isPlayStationPlatform() && <li>
                        <p>Delete Account</p>
                        <Button className="secondary" onClick={this.deleteAccount} childId={externalFocusableComponent.DELETE_ACCOUNT_BUTTON}>Delete Account</Button>
                    </li>}
                </ul>
                {!this.props.isXboxUser && !deviceInfo.isPlayStationPlatform() && <p className="white">Billing method</p>}
                {this.renderSubscriptionData()}
                {this.renderXboxCrossplay()}
                <div
                    className="buttons-container"
                    ref={this.onSettingButtonsContainerRef}
                >
                    {!(deviceInfo.storeType === STORE_TYPE_EPIC) && !deviceInfo.isPlayStationPlatform() && !this.props.isXboxUser && <Button
                        className="secondary"
                        onClick={this.logout}
                        childId={externalFocusableComponent.LOGOUT_BUTTON}
                        parentId="settingButtonsContainer"
                    >
                        Logout
                    </Button>
                    }
                    {/*  <Button
                        className="primary"
                        disabled={!this.props.valid}
                        onClick={this.props.handleSubmit(this.saveData.bind(this))}
                        childId={externalFocusableComponent.SAVE_SETTINGS_BUTTON}
                        parentId="settingButtonsContainer"
                    >
                        Save settings
                    </Button> */}
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    const user = getLoggedUser(state);
    const paymentData = getPaymentData(state);
    const isSubscribed = getUserIsSubscribed(state);

    return {
        ...state.login,
        user,
        initialValues: user ? { displayName: user.displayName, email: user.userName } : null,
        regions: getRegions(state),
        access: state.settings.access || 'subscriber',
        paymentData,
        isSubscribed,
        isXboxUser: isXboxUser(state),
        isXboxCrossplayEnabled: isXboxCrossplayEnabled(state),
        currentParameters: getRoutingSearch(state)
    };
};

export const SettingsAccount = connect(mapStateToProps)(reduxForm({
    form: formId,
    enableReinitialize: true,
})(SettingsAccountComponent));
