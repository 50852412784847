import React, {Component} from 'react';
import {connect} from 'react-redux';
import { throttle } from 'lodash';
import {
	getCurrentGameDetails,
	getCurrentGameChallenges,
	getSelectedChallenge,
	getSelectedChallengeUserScore,
	getSelectedChallengeEarnedMedal,
	getSelectedChallengeBestScore,
	getSelectedChallengeNextMedal,
	getSelectedFeaturedChallengeId,
	getSelectedChallengeId,
	getStartChallengeId,
	getIsChallengeLeaderBoardEnabled,
	getSelectedGiantSlayerChallenge,
} from '../game-details.selector';
import {getAllBadgesById} from '../../../entities/entities.selectors';
import {
	SetSelectedChallengeAction,
	FetchGamechallengeHistoryAction,
	SetSelectedFeaturedChallengeAction, FetchGameChallengesAction, RunGameAction, SetStartChallengeAction, SetSelectedGiantSlayerInfoAction
} from '../game-details.actions';
import {UpdateMyMenuSelection} from '../game-details.actions';
import ListContainer, {LIST_TYPES} from '../../common/list/list-container.component';
import gemIcon from '../../../assets/images/global/gems.png';
import {SetTutorialId} from '../../tutorial/tutorial.actions.js';
import {UnlockChallengeAction} from '../../../entities/challenges/challenges.actions';
import ChallengeTile from '../../common/tiles/tile-challenge.component';
import inputManager, { PARENT_ENTRANCE} from '../../../assets/lib/inputmanager';
import {addPopup} from '../../../components/popup/popup.component';
import GameSessionPopup from '../../popup/game-session-popup/game-session-popup.component';
import './game-details-challenges.component.less';
import {AbandonGiantSlayerChallengeAction, GetGiantSlayerChallengesAction, GiantSlayerJoinRequestAction, setCurrentChallengeId} from '../../challenge/challenge.actions';
import {
	getIsMarketingUser,
	getPreviousRoute,
	getRoutingSearch,
	getWebviewState,
	selectedTileId
} from '../../../app.selectors';
import {navigateToLocation, ROUTES} from '../../../app.router';
import GiantSlayerInfoPanel from '../../common/giant-slayer-info-panel/giant-slayer-info-panel.component';
import ChallengeSummary from "../challenge-summary/challenge-summary.component";
import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import ShareButton from '../../common/share-button/share-button.component';
import Helmet from '../../common/helmet/helmet.component';
import { generateDeepLinkURL, DEEP_LINK_PARAMETER, DEEP_LINK_TARGET } from '../../../assets/lib/deepLinkGenerator';
import TileGiantSlayer from '../../common/tiles/tile-giant-slayer.component';
import {getGiantSlayerChallenges, getUnlockChallengePending} from '../../challenge/challenge.selectors';
import AutoscrollBox from '../../common/autoscroll-box/autoscroll-box.component';
import {
	isFreemiumAppleIosUser,
	isFreemiumV4UserNotSubscribed,
	isXboxCrossplayEnabled,
	isXboxUser
} from '../../../assets/lib/utils';
import { SetWebviewState } from '../../../app.actions';
import { APPLE_IOS_SKU, PREMIUM_POPUP_HEADER_TEXT_3, TAB_MENU_PARENT_ID } from '../../../constants';
import deviceInfo from '../../../assets/lib/deviceInfo';
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import { startNativePayment } from '../../../assets/lib/game-wrapper';

const PAGE_ID = 'game_challenges_1';
const VIEW_MODE_CHALLENGE_INFO = 'challenge-info-view';
const VIEW_MODE_GIANT_SLAYER_INFO = 'giant-slayer-info-view';
const LIST_CHALLENGES_ID = 'listChallenges';
const GIANT_SLAYER_LIST_ID = 'giantSlayer';

class GameDetailsChallengesComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDisabled: false,
			animatingChallengeId: null,
			viewMode: VIEW_MODE_CHALLENGE_INFO
		};
		this.onSelectChallenge = this.onSelectChallenge.bind(this);
		this.onListsCompleted = this.onListsCompleted.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(FetchGameChallengesAction(this.props.item._id));
		this.props.dispatch(FetchGamechallengeHistoryAction(this.props.item._id, this.onFetchComplete));
		this.props.dispatch(UpdateMyMenuSelection({title: 'game-challenges'}));
		this.props.dispatch(SetTutorialId(PAGE_ID));
		if (featureInfo.isSupported(FEATURES.GIANT_SLAYER)) {		
			this.props.dispatch(GetGiantSlayerChallengesAction());
			if (this.props.previousRoute.path !== ROUTES.GIANT_SLAYER_RESULT.path) {
				this.props.dispatch(AbandonGiantSlayerChallengeAction());
			}
			this.onChangeGiantSlayerChallenge(null);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.selectedTileId !== nextProps.selectedTileId
			|| this.props.challenges.length !== nextProps.challenges.length
			|| this.props.selectedChallengeId !== nextProps.selectedChallengeId
			|| this.props.currentBestScore !== nextProps.currentBestScore
			|| this.state.viewMode !== nextState.viewMode
			|| this.state.animatingChallengeId !== nextState.animatingChallengeId
			|| this.props.giantSlayerChallengesCurrentGame.length !== nextProps.giantSlayerChallengesCurrentGame.length;
	}

	componentDidUpdate(prevProps) {
		// refresh rects if amount of challenges was changed and user was focused on one of them
		if (prevProps?.challenges?.length !== this.props.challenges?.length && inputManager?.currentChild?.parentId === LIST_CHALLENGES_ID) {
			inputManager.refreshBoundingRects();
		}
		if (!inputManager.currentChild && !this.props.currentParameters?.challengeId) {
			inputManager.setCurrentChildById('tab-menu-Challenges', 'tab-menu');
		}
	}

	componentDidMount() {
		// always choose Challenges tab button when navigating Bottom=>Top or Top=>Bottom
		this.setAsPrioritizedNavigationItem(`${TAB_MENU_PARENT_ID}-Challenges`);

		if (this.props.currentParameters?.challengeId) {
			// do nothing - we will focus on one of the challenge item after it's rendered
		} else if (this.props.previousRoute.path === ROUTES.HOW_TO_PLAY.path) {
			// this is the fix for a bug when user clicks 'navigate back' in how-to-play page
			// the bug is that 2 elements become focused at the same time in game challenges page
			inputManager.setCurrentChildById('tab-menu-Challenges', 'tab-menu');
		} else if (!inputManager.currentChild) {
			inputManager.setCurrentChildById('tab-menu-Challenges', 'tab-menu');
		}

		if (this.props.currentParameters?.prevPage === ROUTES.DAILY_CHALLENGES.path) {
			inputManager.setBackFunction(() => navigateToLocation(ROUTES.DAILY_CHALLENGES));
		} else { // it prevents to go back to how-to-play screen
			inputManager.setBackFunction(() => navigateToLocation(ROUTES.HOMEPAGE));
		}

		// if for some reason (xbox suspend) webview was not set to visible - set it now
		if (!this.props.isWebViewVisible) {
			console.log('WARNING! webview was not set to visible');
			this.props.dispatch(SetWebviewState({visible: true}));
		}
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null);
	}

	setAsPrioritizedNavigationItem = (childId) => {
		const tabsParent = inputManager.getParent(TAB_MENU_PARENT_ID);
		if (tabsParent && !tabsParent.currentChild) {
			inputManager.setCurrentChildOfParent(TAB_MENU_PARENT_ID, childId);
		}
	}

	onFetchComplete = () => {
		if (this.props.selectedFeaturedChallengeId) {
			this.onChangeChallenge(this.props.selectedFeaturedChallengeId);
			this.props.dispatch(SetSelectedFeaturedChallengeAction(null));
		}
	};

	renderSummary = () => {
		const {item, selectedChallenge, currentBestScore, currentMedal, nextMedal, selectedChallengeUserScore, badges, leaderBoardEnabled, isXboxCrossplayDisabledPlayer} = this.props;

		return (<ChallengeSummary
			leaderBoardEnabled={leaderBoardEnabled}
			badges={badges}
			gameId={item._id}
			selectedChallenge={selectedChallenge}
			currentBestScore={currentBestScore}
			currentMedal={currentMedal}
			nextMedal={nextMedal}
			selectedChallengeUserScore={isXboxCrossplayDisabledPlayer ? null : selectedChallengeUserScore}
		/>);
	};

	renderChallengeInfo = (item) => {
		if (!item) return null;
		const unlockCost = !item.unlocked && item.costs && item.costs.unlockCost;

		return (
			<div className="left-inner">
				<div>
					<h2>{item.title}</h2>
					<AutoscrollBox>
						<p className="description">
							{item.text}
						</p>
					</AutoscrollBox>
				</div>
				<div className="challenge-options">
					{!item.unlocked &&
					<div className="to-unlock">
						{`Unlock for ${unlockCost}`}
						<img src={gemIcon} alt=""/>
					</div>
					}
					{this.shouldDisplayShareButton() && <ShareButton
						text={`Remember ${item.title}? See if you can beat my score!`}
						deepLink={generateDeepLinkURL(DEEP_LINK_TARGET.CHALLENGE_INFO, {
							[DEEP_LINK_PARAMETER.GAME_ID]: item.gameId,
							[DEEP_LINK_PARAMETER.CHALLENGE_ID]: item._id
						})}
					/>}
				</div>
			</div>
		);
	};

	onChangeChallenge = (challengeId) => {
		this.props.dispatch(SetSelectedChallengeAction(challengeId));
	};

	onChangeGiantSlayerChallenge = (giantSlayerChallengeId) => {
		this.props.dispatch(SetSelectedGiantSlayerInfoAction(giantSlayerChallengeId));
	};

	onSelectChallenge = throttle((challengeId) => {
		if (!this.props.selectedChallenge || this.props.selectedChallenge._id !== challengeId) {
			this.onChangeChallenge(challengeId);
			return;
		}
		const id = this.props.item._id;
		if (this.props.selectedChallenge.unlocked) {
			if(featureInfo.isSupported(FEATURES.GAMEPLAY)){
				if (this.props.isMarketingUser) {
					this.props.dispatch(RunGameAction({
						gameId: id,
						challengeId,
						challengeStyle: 'intro',
					}));
					navigateToLocation(ROUTES.HOW_TO_PLAY, {id: id, challengeId});
				} else {
					this.props.dispatch(setCurrentChallengeId(id, challengeId));
					addPopup(
						<GameSessionPopup
							id={id}
							challengeId={challengeId}
						/>,
						{},
						this.props.isXboxPlayer
					);
				}
			}
		} else {
			if(challengeId === this.state.animatingChallengeId) {
				clearTimeout(this.unlockTimeout);
				this.setState({
					isDisabled: false,
					animatingChallengeId: null,
				});
			}
			if(this.state.isDisabled) return // if we are animating don't do anything
			if (this.props.unlockChallengePending) return; // prevent multiple clicking

			this.props.dispatch(UnlockChallengeAction({gameId: id, challengeId}, () => {
				clearTimeout(this.unlockTimeout);
				this.setState({
					isDisabled: true,
					animatingChallengeId: challengeId,
				});
				this.unlockTimeout = setTimeout(() => {
					this.setState({
						isDisabled: false,
						animatingChallengeId: null,
					});
				}, 3100);
			}, (errorResponse) => {
				// errors are handled in listen websocket
			}));
			this.setState({
				isDisabled: false,
				animatingChallengeId: null,
			});
		}
	}, 1000, { trailing: false }); // invoke this function only once in 1 second (all other invocations will be ignored during this period)

	onGameTileClicked = (itemId, listIndex) => {
		if (listIndex === GIANT_SLAYER_LIST_ID) {
			this.onSelectGiantSlayerChallenge(itemId);
		}
	}

	onSelectGiantSlayerChallenge = (giantSlayerChallengeId) => {
		if (this.props.isFreemiumAppleIos) {
			startNativePayment(APPLE_IOS_SKU);
			return;
		}

		if (this.props.isFreemiumV4User) {
			const subscriptionPopup = <SubscriptionComparisonsPopup
				leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_3}
				pageId='game_details_challenges_1'
			/>;
			addPopup(subscriptionPopup);
			return;
		}

		if (!featureInfo.isSupported(FEATURES.GIANT_SLAYER) || !this.props.giantSlayerChallenge || this.props.giantSlayerChallenge._id !== giantSlayerChallengeId) {
			this.onChangeGiantSlayerChallenge(giantSlayerChallengeId);
			return;
		}
		// join as a slayer
		const challengeId = this.props.giantSlayerChallenge.challengeId;
		const gameId = this.props.item._id;
		this.props.dispatch(RunGameAction({
			gameId,
			challengeId,
			challengeStyle: 'intro',
			giantSlayerChallengeId,
            role: 'slayer',
		}));
		navigateToLocation(ROUTES.HOW_TO_PLAY, {
			id: gameId,
			challengeId,
			giantSlayerChallengeId
		});
	}

	// Effectively we load the page and then once we get the challenges and the list has loaded we then set the challengeId 
	// inside of redux from inside the child component which then causes the extra unneeded re render
	onListsCompleted = () => {
		const {challenges, startChallengeId, dispatch, currentParameters} = this.props;
		const { focusElement, challengeId } = currentParameters;

		if (challenges && challenges.length > 0) {
			let id;
            
			if (startChallengeId) {
				id = startChallengeId;
				this.onSelectChallenge(id);
				dispatch(SetStartChallengeAction(null));
            } 
			else {
				id = (challengeId || challenges[0]._id);
			}

			const reDefinedFocusElement = focusElement === "initialChallenge" ? challenges[0]._id : focusElement;
			focusElement && inputManager.setCurrentChildById(reDefinedFocusElement, LIST_CHALLENGES_ID)
			dispatch(SetSelectedChallengeAction(id));
		}
	};

	onTileFocus = (tileId, listIndex) => {
		if (listIndex === GIANT_SLAYER_LIST_ID) {
			if (this.state.viewMode !== VIEW_MODE_GIANT_SLAYER_INFO) {
				this.setState({ viewMode: VIEW_MODE_GIANT_SLAYER_INFO });
			}
			this.onChangeGiantSlayerChallenge(tileId);
			this.onChangeChallenge(null);
		} else {
			if (this.props.challenges.length > 0) {
				inputManager.emitEvent=true;
				if (this.state.viewMode !== VIEW_MODE_CHALLENGE_INFO) {
					this.setState({ viewMode: VIEW_MODE_CHALLENGE_INFO });
				}
				this.onChangeChallenge(tileId);
				this.onChangeGiantSlayerChallenge(null);
				if (this.props.selectedChallenge && this.props.selectedChallenge._id !== tileId) {
					this.props.dispatch(SetSelectedChallengeAction(tileId));
					return;
				}
			}
		}
	};

	shouldDisplayShareButton = () => {
		if (this.props.isXboxPlayer) return false;
		if (deviceInfo.isPlayStationPlatform()) return false;

		return true;
	};

	handleChallengeTileClick = (challengeId) => {
		if (this.props.isFreemiumAppleIos) {
			startNativePayment(APPLE_IOS_SKU);
			return;
		}

		if (this.props.isFreemiumV4User) {
			const subscriptionPopup = <SubscriptionComparisonsPopup
				leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_3}
				pageId='game_details_challenges_1'
			/>;
			addPopup(subscriptionPopup);
			return;
		}

		this.onSelectChallenge(challengeId);
	};

	render() {
		const {item} = this.props
		const isNotSupportedShareDevice = !featureInfo.isSupported(FEATURES.SHARE_BUTTON)

		return (
			<div className={`game-details-challenges-component ${isNotSupportedShareDevice ? 'game-details-challenges-component--no-share' : ''}`}>
				<Helmet
					image={item.featured_image_uri}
				/>
				<div className={`top-container ${this.state.viewMode}`}>
					<div className="challenge-info">
						<div className="content-left">
							{this.renderChallengeInfo(this.props.selectedChallenge)}
						</div>
						<div className="content-right">
							{this.props.selectedChallenge && this.renderSummary()}
						</div>
					</div>
                    { featureInfo.isSupported(FEATURES.GIANT_SLAYER) && 
					    <GiantSlayerInfoPanel />
                    }
				</div>

				{ this.props.challenges && this.props.challenges.length > 0 && !featureInfo.isSupported(FEATURES.GIANT_SLAYER) &&
					<ListContainer
						lists={[{
							title: 'Challenges',
							'id': LIST_CHALLENGES_ID,
							'data': this.props.challenges,
							type: LIST_TYPES.HORIZONTAL,
							parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL,
							renderer: (props) => {
								return (
									<div className="game-details-challenge-wrapper">
										<ChallengeTile
											{...props}
											animate={true}
											animatingChallengeId={this.state.animatingChallengeId}
											childId={props.id}
											handleClick={() => {
												this.handleChallengeTileClick(props.id)
											}}
										/>
									</div>
								)
							}
						}
					]}
						onTileFocus={this.onTileFocus}
						onTileBlur={this.onTileBlur}
						onListGenerateComplete={this.onListsCompleted}
						scrollToTop={this.props.scrollTopActive}
					/>
				}
				{ this.props.challenges && this.props.challenges.length > 0 && featureInfo.isSupported(FEATURES.GIANT_SLAYER) &&
					<ListContainer
						lists={[{
							title: 'Challenges',
							'id': LIST_CHALLENGES_ID,
							'data': this.props.challenges,
							type: LIST_TYPES.HORIZONTAL,
							parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL,
							shouldComponentForceUpdate: true,
							renderer: (props) => {
								return (
									<div className="game-details-challenge-wrapper">
										<ChallengeTile
											{...props}
											animate={true}
											animatingChallengeId={this.state.animatingChallengeId}
											childId={props.id}
											handleClick={() => {
												this.handleChallengeTileClick(props.id)
											}}
										/>
									</div>
								)
							}
						},
                        {
                            title: 'Giant Slayer',
                            id: GIANT_SLAYER_LIST_ID,
                            data: this.props.giantSlayerChallengesCurrentGame,
                            type: LIST_TYPES.HORIZONTAL,
                            parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL,
                            renderer: (props) => {
                                return (
									<div className="game-details-giant-slayer-wrapper">
										<TileGiantSlayer
											{...props}
											childId={props.id}
											handleClick={() => {
												this.onSelectGiantSlayerChallenge(props.id)
											}}
										/>
									</div>
                                )
                            }
                        }
					]}
						onTileFocus={this.onTileFocus}
						onTileBlur={this.onTileBlur}
						onListGenerateComplete={this.onListsCompleted}
						scrollToTop={this.props.scrollTopActive}
						onTileClick={this.onGameTileClicked}
					/>
				}                
			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const currentGameDetails = getCurrentGameDetails(state);
	const challenges = getCurrentGameChallenges(state);
	const selectedChallenge = getSelectedChallenge(state);
	const selectedChallengeUserScore = getSelectedChallengeUserScore(state);
	const selectedFeaturedChallengeId = getSelectedFeaturedChallengeId(state);
	const currentMedal = getSelectedChallengeEarnedMedal(state) || {};
	const nextMedal = getSelectedChallengeNextMedal(state) || {};
	const currentBestScore = getSelectedChallengeBestScore(state);
	const isMarketingUser = getIsMarketingUser(state);
	const selectedChallengeId = getSelectedChallengeId(state);
	const startChallengeId = getStartChallengeId(state);
	const leaderBoardEnabled = getIsChallengeLeaderBoardEnabled(state);
	const giantSlayerChallenges = getGiantSlayerChallenges(state);
	const giantSlayerChallengesCurrentGame = giantSlayerChallenges.filter(item => item.gameId === currentGameDetails._id && item.role === 'eligible');
	const isXboxPlayer = isXboxUser(state);
	const isWebViewVisible = getWebviewState(state).visible;

	return {
		isFreemiumAppleIos: isFreemiumAppleIosUser(state),
		isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
		isWebViewVisible,
		selectedTileId: selectedTileId(state),
		item: currentGameDetails,
		isXboxPlayer,
		isXboxCrossplayDisabledPlayer: isXboxPlayer && !isXboxCrossplayEnabled(state),
		challenges,
		hasChallenges: challenges.length > 0,
		selectedChallenge,
		selectedChallengeUserScore,
		selectedFeaturedChallengeId,
		currentMedal,
		nextMedal,
		currentBestScore,
		badges: getAllBadgesById(state),
		previousRoute: getPreviousRoute(state),
		isMarketingUser,
		selectedChallengeId,
		startChallengeId,
		leaderBoardEnabled,
		currentParameters: getRoutingSearch(state),
		giantSlayerChallengesCurrentGame,
		giantSlayerChallenge: getSelectedGiantSlayerChallenge(state),
		unlockChallengePending: getUnlockChallengePending(state),
	};
};

export const GameDetailsChallenges = connect(mapStateToProps)(GameDetailsChallengesComponent);
