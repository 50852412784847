import { antstreamAPIService, antstreamService } from '../../app.reducer';
import { navigateToLocation, ROUTES } from '../../app.router';
import {getAllGamesById, getGameById} from '../entities.selectors';
import {getAssetsUrlWithFolder} from '../../app.selectors';
import {SaveStateAction} from '../../components/game-details/game-details.actions';
import {FOLDER_TYPES} from '../../assets/lib/FolderTypes';
import { FEATURED_BANNER_TYPES, GAMEPAD_BUTTONS } from '../../constants';
import { readRestUserData } from '../../assets/lib/local-storage';
import {isLowSpecDevice} from '../../app.helpers';
import React from 'react';
import {applyMapping, handleNoAuthTokenIssue} from "../../assets/lib/utils";
import {adaptMappingForServerAndFromServer, GET_CONTROLLER_MAPPINGS_ACTION_SUCCESS} from "../mapping/mapping.actions";

export const FETCH_HOMEPAGE_GAMES_ACTION = 'FETCH_HOMEPAGE_GAMES_ACTION';
export const FetchHomepageGamesAction = onComplete => {
    return (dispatch, getState) => {
        dispatch({ type: FETCH_HOMEPAGE_GAMES_ACTION });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue('FETCH_HOMEPAGE_GAMES_ACTION');
            antstreamAPIService.homepageGames
                .homepageGamesList({
                    headers: { Authorization: 'Bearer ' + authToken }
                })
                .then(({ data }) => {
                    if (data.error) throw new Error(data.error);

                    const response = data.data.homepageData;

                    const challengesGamesFolder = getAssetsUrlWithFolder(getState(), FOLDER_TYPES.CHALLENGE_GAMES);
                    const brochureContentsFolder = getAssetsUrlWithFolder(getState(), FOLDER_TYPES.BROCHURE_CONTENT_ASSETS);

                    response.lists = response.lists.map(l => {
                        if (!l.isChallengeList) return l;

                        return {
                            ...l,
                            challenges: l.challenges.map(el => ({
                                _id: el._id,
                                id: el._id,
                                title: el.challenge.title,
                                unlocked: true,
                                tier: el.challenge._id?.split('Tier-')[1]?.split('-Challenge')[0],
                                gameImgSrc: brochureContentsFolder + el.game.coverImage,
                                gameTitle: el.game.title,
                                withBorders: true,
                                challengeId: el.challenge._id,
                                gameId: el.game._id,
                                img_src: challengesGamesFolder + el.challenge.coverImage
                            }))
                        };
                    });

                    if (isLowSpecDevice()) {
                        const excludeRows = ["Driving Games", "Casino Games", "Board Games", "Text Adventure Games", "Maze Games", "Adventure Games", "Flight Sim Games", "Strategy Games"];

                        response.lists = response.lists.filter(function (item) {
                            item.games = item.games.slice(0, 14);// reduce number of games per row to 14
                            return !excludeRows.includes(item._id)
                        })
                    }

                    if (response.featuredContent) {
                        response.featuredContent.forEach(content => {
                            const contentType = content.type;
                            // Featured Tournaments Banners are located in the Tournaments Folder
                            if (
                                contentType === FEATURED_BANNER_TYPES.TOURNAMENTS ||
                                contentType === FEATURED_BANNER_TYPES.TOURNAMENTS_GROUP
                            ) {
                                content.featured_banner_image =
                                    getAssetsUrlWithFolder(getState(), FOLDER_TYPES.TOURNAMENTS) + content.featured_banner_image;
                            } else {
                                // default to the banners folder
                                content.featured_banner_image =
                                    getAssetsUrlWithFolder(getState(), FOLDER_TYPES.BANNERS) + content.featured_banner_image;
                            }
                        });
                    }
                    dispatch(FetchHomepageGamesSuccessAction(response));
                    if (onComplete) onComplete();
                })
                .catch(homepageGamesErr => {
                    dispatch(FetchHomepageGamesErrorAction());
                    console.error('Error homepage-games: ' + homepageGamesErr.message);
                });
    };
};
export const FETCH_HOMEPAGE_GAMES_ACTION_SUCCESS = 'FETCH_HOMEPAGE_GAMES_ACTION_SUCCESS';
export const FetchHomepageGamesSuccessAction = (homepageData) => {
    return (dispatch, getState) => {
        dispatch(UpdateGamesData(homepageData.games));

		if(homepageData.featuredTournaments && homepageData.featuredTournaments.length){
			homepageData.featuredTournaments.forEach(item=>{
				item.featured_banner_image = getAssetsUrlWithFolder(getState(),FOLDER_TYPES.TOURNAMENTS)+item.featuredBanner;
				item.isTournament = true;
			})
		}

        dispatch({
            type: FETCH_HOMEPAGE_GAMES_ACTION_SUCCESS,
            payload: homepageData
        });
    };
};

export const FETCH_HOMEPAGE_GAMES_ACTION_ERROR = 'FETCH_HOMEPAGE_GAMES_ACTION_ERROR';
export const FetchHomepageGamesErrorAction = () => {
    return dispatch => dispatch({ type: FETCH_HOMEPAGE_GAMES_ACTION_ERROR });
};

export const FAVOURITED_GAMES_ACTION_SUCCESS = 'FAVOURITED_GAMES_ACTION_SUCCESS';
export const FavouritedGamesSuccessAction = (favouritedGames) => {
    return (dispatch) => {
        dispatch({
            type: FAVOURITED_GAMES_ACTION_SUCCESS,
            favouritedGames
        });
    };
};

export const ADD_GAME_TO_PLAY_AGAIN_LIST = 'ADD_GAME_TO_PLAY_AGAIN_LIST';
export const addGameToPlayAgainList = (gameId) => {
    return (dispatch) => {
        dispatch({
            type: ADD_GAME_TO_PLAY_AGAIN_LIST,
            gameId
        });
    };
};

const appendAssetsUrlToGames = (games, state) => {
    const appendedGames = [];

    games.forEach(game => {
        appendedGames.push(appendAssetsUrlToGame(game,state))
    });

    return appendedGames;
};

const appendAssetsUrlToGame = (game, state) => {
	const brochureContentsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.BROCHURE_CONTENT_ASSETS);
	const featuredFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.FEATURED);
	const screenshotAssetsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.SCREENSHOT);
	const homepageScreenshotAssetsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.HOMEPAGE_SCREENSHOT);
	const bannerAssetsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.BANNERS);
	const genreIconsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.GENRE_ICONS);
	const yearIconsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.YEAR_ICONS);
	const homepageIconsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.HOMEPAGE_ICONS);
	return	{
        ...game,
        image_uri: brochureContentsFolder + game.image_uri,
        featured_image_uri: featuredFolder + game.featured_image_uri,
        screenshot_1: screenshotAssetsFolder + game.screenshot_1,
        screenshot_2: screenshotAssetsFolder + game.screenshot_2,
        homepage_screenshot: homepageScreenshotAssetsFolder + game.homepage_screenshot,
        featured_banner_image: game.featured_banner_image ? bannerAssetsFolder + game.featured_banner_image:'',
        genre_image_uri: genreIconsFolder + game.genre_image_uri,
        year_image_uri: yearIconsFolder + game.year_image_uri,
        trials_image_uri: game.trials_image_uri ? homepageIconsFolder + game.trials_image_uri : '',
    };
};



export const UPDATE_ALL_GAMES_DATA = 'UPDATE_ALL_GAMES_DATA';
export const UpdateGamesData = (games) => {
	return (dispatch, getState) => {
		const allGamesById = getAllGamesById(getState());
		let filteredGames = games.filter(game=>!allGamesById[game._id]);
		filteredGames = appendAssetsUrlToGames(filteredGames,getState());
		dispatch({
			type: UPDATE_ALL_GAMES_DATA,
			games:filteredGames
		});
	};
};

export const FETCH_GAME_DETAILED_ACTION = 'FETCH_GAME_DETAILED_ACTION';
export const FetchGameDetailed = (gameId, onComplete) => {
    return (dispatch, getState) => {
        if (!getGameById(getState(), gameId) || (getGameById(getState(), gameId) && !getGameById(getState(), gameId).isDetailed)) {
            dispatch({
                type: FETCH_GAME_DETAILED_ACTION,
                gameId
            });

                return void getDetailedGameFromAntSparks(gameId, getState, dispatch, onComplete);
        }
    };
};

const getDetailedGameFromAntSparks = (gameId, getState, dispatch, onComplete) => {
    const { authToken } = readRestUserData() || {};
    if (!authToken) handleNoAuthTokenIssue('getDetailedGameFromAntSparks');

    antstreamAPIService.game
        .gameList(
            { gameId },
            {
                headers: { Authorization: 'Bearer ' + authToken }
            }
        )
        .then(async ({ data }) => {
            if (data.error) throw new Error(data.error);
            const gameDetailed = data.data.game;
            const savedStates = data.data.savedState;

            // TODO: Note for future frontend - this should redirect to a "game not found page",
            // 		 but it is not worth doing it now, so just redirecting to homepage.
            if (!gameDetailed) {
                return navigateToLocation(ROUTES.HOMEPAGE);
            }

            const gameDetailedWithUrl = appendAssetsUrlToGame(gameDetailed, getState());

            // if asvi_actions is null - use empty object
            if (!gameDetailedWithUrl.asvi_actions) {
                gameDetailedWithUrl.asvi_actions = {};
            }

            // attach default description for movement buttons
            Object.keys(gameDetailedWithUrl.asvi_actions).forEach(key => {
                const action = gameDetailedWithUrl.asvi_actions[key];
                const gKeyId = action?.gamepad?.[0];
                if (action.description) return;

                switch (gKeyId) {
                    case GAMEPAD_BUTTONS.Gamepad_DPadUp.name: {
                        action.description = GAMEPAD_BUTTONS.Gamepad_DPadUp.defaultName;
                        break;
                    }
                    case GAMEPAD_BUTTONS.Gamepad_DPadDown.name: {
                        action.description = GAMEPAD_BUTTONS.Gamepad_DPadDown.defaultName;
                        break;
                    }
                    case GAMEPAD_BUTTONS.Gamepad_DPadLeft.name: {
                        action.description = GAMEPAD_BUTTONS.Gamepad_DPadLeft.defaultName;
                        break;
                    }
                    case GAMEPAD_BUTTONS.Gamepad_DPadRight.name: {
                        action.description = GAMEPAD_BUTTONS.Gamepad_DPadRight.defaultName;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
            
            let gameDetailedWithMapping = {
                ...gameDetailedWithUrl,
                DEFAULT_asvi_actions: JSON.parse(JSON.stringify(gameDetailedWithUrl.asvi_actions))
            };

            try {
                const mappingRes = await antstreamAPIService.v2.settingsControllerMappingsList(
                    { gameId },{
                    headers: { Authorization: 'Bearer ' + authToken }
                });
                if (mappingRes.data.error) throw new Error(mappingRes.data.error);

                const mapping = JSON.parse(mappingRes.data.body)?.data?.mapping;
                const adapted = adaptMappingForServerAndFromServer(mapping);
                dispatch({ type: GET_CONTROLLER_MAPPINGS_ACTION_SUCCESS, payload: {
                    gameId,
                    mapping: adapted
                }});
                if (adapted?.gamepad?.mappings && Object.keys(adapted?.gamepad?.mappings).length) {
                    const applied = applyMapping(gameDetailedWithUrl.asvi_actions, adapted);
                    gameDetailedWithMapping = {...gameDetailedWithMapping, asvi_actions: applied};
                }
            } catch (settingsControllerMappingsErr) {
                console.log('Failed to get mapping: ', settingsControllerMappingsErr.message);
            }

            dispatch(FetchGameDetailedSuccess(gameDetailedWithMapping));
            dispatch(
                SaveStateAction({
                    gameId: gameDetailed._id,
                    savedStates
                })
            );
            if (onComplete) onComplete();
        })
        .catch(gameErr => {
            // addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" />);
            console.error(gameErr.message);
            dispatch(FetchGameDetailedError());
        });
}

export const FETCH_GAME_DETAILED_ACTION_SUCCESS = 'FETCH_GAME_DETAILED_ACTION_SUCCESS';
export const FetchGameDetailedSuccess = (gameDetailed) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_GAME_DETAILED_ACTION_SUCCESS,
            gameDetailed
        });
    };
};

export const ASVI_UPDATED_ACTION = 'ASVI_UPDATED_ACTION';
export const asviUpdatedAction = (gameId, asvi) => {
    return (dispatch) => {
        dispatch({
            type: ASVI_UPDATED_ACTION,
            gameId,
            asvi
        });
    };
};

export const FETCH_GAME_DETAILED_ACTION_ERROR = 'FETCH_GAME_DETAILED_ACTION_ERROR';
export const FetchGameDetailedError = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_GAME_DETAILED_ACTION_ERROR,
        });
    };
};
