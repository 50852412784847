import React, { Component } from 'react'
import './how-to-play-content.component.less';
import { Loader } from '../../common/loader/loader.component'
import SVG from 'react-inlinesvg';
import deviceInfo from '../../../assets/lib/deviceInfo';
//import { SVG as SVG2 } from '@svgdotjs/svg.js';

const leftSideButtonTextIds = [
    'Gamepad_LeftTrigger_Text',
    'Gamepad_LeftShoulder_Text',
    'Gamepad_DPadUp_Text',
    'Gamepad_DPadLeft_Text',
    'Gamepad_DPadRight_Text',
    'Gamepad_DPadDown_Text',
    'Gamepad_LeftThumbstickUp_Text',
    'Gamepad_LeftThumbstickLeft_Text',
    'Gamepad_LeftThumbstickRight_Text',
    'Gamepad_LeftThumbstickDown_Text',
];

export class HowToPlayContent extends Component {
    constructor() {
        super();
        this.state = {
            isDiagramLabelsUpdated: false,
            isImageLoading: true,
        };
    }

    componentDidUpdate(prevProps) {
        const { asvi_actions, inputTypes } = this.props;

        if (prevProps.asvi_actions
            && asvi_actions
            && JSON.stringify(prevProps.asvi_actions) !== JSON.stringify(asvi_actions)
        ) {
            this.updateDiagram(asvi_actions, inputTypes);
        }
    }

    updateDiagram = (asvi_actions, inputTypes) => {
        //var mainSVGLayer = SVG2('.image-container svg g#Layer_2');
        const touchButtonCount = this.getTouchButtonCount(asvi_actions);
        
        // hide all input elements by default
        const inputElements = document.querySelectorAll('.input-element');
        inputElements.forEach((element) => {
            element.classList.add('hidden');
        });

        // Add Home button to asvi list
        asvi_actions["action_home"]={
            "label": "Home",
            "description": "",
            "input_cluster": 1,
            "gamepad": [
                "Gamepad_Home"
            ]
        };

        if (deviceInfo.isPlayStationPlatform()){
            asvi_actions["action_view"] = {
                "label": "Menu/Pause",
                "description": "",
                "input_cluster": 1,
                "gamepad": [
                    "Gamepad_View"
                ]
            };
        }
        // find entries in asvi_actions that contains a label
        for (const [asvi_action, entry] of Object.entries(asvi_actions)) {
            // if (!entry.label && !entry.description) {
            if (!entry.label) {
                continue;
            }

            inputTypes.forEach((inputType) => {
                if (!entry[inputType]) {
                    return;
                }
                entry[inputType].forEach((diagramElement) => {
                    const elements = [];
                    let key = null;

                    if (typeof diagramElement === 'string')
                    {
                        key = diagramElement;
                    }
                    else
                    {
                        key=diagramElement.element;
                    }

                    if (key === "Key_+") // svg doesn't like this
                    {
                        key = "Key_plus";
                    }
                    // Search the SVG DOM for key
                    const domKey = document.querySelector('#' + key);
                    const domKey2 = document.querySelector('#' + key + "_2");

                    if (domKey) {
                        elements.push(domKey);
                    }
                    if (domKey2) {
                        elements.push(domKey2);//if there is 2 fields with same key in the svg file. Use "_2".
                    }

                    /* Disable this for now as requires Drupal support
                    if (key.startsWith('Label')) {
                        if (mainSVGLayer) {
                            var translateX = diagramElement.translateX ? diagramElement.translateX : 0;
                            var translateY = diagramElement.translateY ? diagramElement.translateY : 0;
                            var labelFont = diagramElement.font ? diagramElement.font : 40;
                            var labelColour = diagramElement.colour ? diagramElement.colour : "white";
                            var rotate = diagramElement.rotate ? diagramElement.rotate : 0;
                            var scale = diagramElement.scale ? diagramElement.scale : 1;

                            var labelElement = mainSVGLayer.text(entry.label).font({ size: labelFont })
                                .fill(labelColour).center(mainSVGLayer.width() / 2, mainSVGLayer.height() / 2);
                            labelElement.transform({
                                rotate: rotate,
                                translateX: translateX,
                                translateY: translateY,
                                scale: scale
                            })
                        }
                    }*/

                    elements.forEach((element) => {
                        if (element) {
                            // show the input element
                            element.classList.remove('hidden');
                            if (key.includes('Gamepad_LeftThumbstick')) {
                                const leftThumbstick = document.querySelector('#Gamepad_LeftThumbstick')
                                leftThumbstick && document.querySelector('#Gamepad_LeftThumbstick').classList.remove('hidden');
                            }
                            else if (key.includes('Gamepad_RightThumbstick')) {
                                const rightThumbstick = document.querySelector('#Gamepad_RightThumbstick')
                                rightThumbstick && rightThumbstick.classList.remove('hidden');
                            }
                            else if (key.includes('Gamepad_DPad')) {
                                const dPad = document.querySelector('#D-Pad')
                                dPad && dPad.classList.remove('hidden');
                            }
                            else if (key === 'Key_Left') {
                                const keyLeft = document.querySelector('#Key_Left')
                                const keyRight = document.querySelector('#Key_Right')
                                const keyUp = document.querySelector('#Key_Up')
                                const keyDown = document.querySelector('#Key_Down')
                                // show all direction keys
                                keyLeft && keyLeft.classList.remove('hidden');
                                keyRight && keyRight.classList.remove('hidden');
                                keyUp && keyUp.classList.remove('hidden');
                                keyDown && keyDown.classList.remove('hidden');
                            }
                            else if (key === 'VJ_Left') {
                                const leftDPad = document.querySelector('#Left_DPAD')
                                leftDPad && leftDPad.classList.remove('hidden');
                            }
                            else if (key === 'VJ2_Right') {
                                const vj2Right = document.querySelector('#VJ2_Right')
                                const rightDPad = document.querySelector('#Right_DPAD')
                                rightDPad && rightDPad.classList.remove('hidden');

                                if(touchButtonCount>0) // Shift VJ" up to make space for buttons
                                {
                                    vj2Right.setAttribute('transform','translate(0,-50)');
                                    rightDPad.setAttribute('transform','translate(0,-50)');
                                }
                            }
                            else if (key === 'VJ2_Left') {
                                const vj2Left = document.querySelector('#VJ2_Left')
                                if(touchButtonCount>0) // Shift VJ" up to make space for buttons
                                {
                                    vj2Left.setAttribute('transform','translate(0,-50)');
                                }
                            }
                            else if (key === 'VJ2_Up') {
                                const vj2Up = document.querySelector('#VJ2_Up')
                                if(touchButtonCount>0) // Shift VJ" up to make space for buttons
                                {
                                    vj2Up.setAttribute('transform','translate(0,-50)');
                                }
                            }
                            else if (key === 'VJ2_Down') {
                                const vj2Down = document.querySelector('#VJ2_Down')
                                if(touchButtonCount>0) // Shift VJ" up to make space for buttons
                                {
                                    vj2Down.setAttribute('transform','translate(0,-50)');
                                }
                            }
                            else if (key.startsWith('Touch_Swipe')) {
                                element = document.querySelector('#SWIPE_HAND');
                                element && element.classList.remove('hidden');
                            }
                            else if (key === 'Touch_Tap') {
                                const tapHand = document.querySelector('#TOUCH_HAND');
                                tapHand && tapHand.classList.remove('hidden');
                            }
                            
                            this.setTextOfElements(key, entry, element);
                        }
                    });
                });

            });

        }
    }

    setTextOfElements = (key, entry, element) => {
        const textElements = element.getElementsByTagName('text');
        const regex = /Key_F\d/gm;
        const isFunctionKey = regex.test(key);

        if ((textElements.length > 0) && !isFunctionKey) {
            const selectedTextElement = textElements[textElements.length - 1];//use the last text element in the block
            let text = entry.label;

            // if no label try description
            // if (!entry.label) {
            //     text = entry.description;
            // }
            // if (entry.label === 'Movement') {
            //     text = entry.description;
            // }

            selectedTextElement.textContent = text;// Set text element of SVG DOM element

            /**
             * Align left side button labels based on the text width and length.
             * Platfrom: PS4, PS5
             **/
            if (deviceInfo.isPlayStationPlatform()
                && leftSideButtonTextIds.includes(selectedTextElement.id)
            ) {
                if (this.state.isDiagramLabelsUpdated) return;
                this.setState({isDiagramLabelsUpdated: true});

                const translateX = selectedTextElement.transform.baseVal.consolidate().matrix.e;
                const translateY = selectedTextElement.transform.baseVal.consolidate().matrix.f;

                const oneCharLength = 12;
                const charsWidth = text.length * oneCharLength;

                selectedTextElement.setAttribute('transform', `translate(${translateX - charsWidth} ${translateY})`)
            }
        }
    }

    getDynamicDiagram = (name, touchLayout, asvi_actions) => {
        let diagramName = name;
        if (name === 'touch' && touchLayout === 'game-controls-slant-low') {
            diagramName = 'touch-slant';
        } else if (name === 'touch' && touchLayout === 'game-controls-pinball') {
            diagramName = 'touch-pinball';
        }
        else if(name==='touch' && this.getTouchButtonCount(asvi_actions)>4)
        {
            diagramName = 'touch-slant';
        }
        return require(`../../../assets/images/how-to-play/controls-${diagramName}-diagram.svg`);
    }

    getTouchButtonCount = (asvi_actions) => {
        let totalTouchButtons = 0;
        for (const [key, entry] of Object.entries(asvi_actions)) {
            if (!entry.label || !entry['touch']) {
                continue;
            }
            entry['touch'].forEach((key) => {
                let diagramElement = null;
                if (typeof key === 'string') {
                    diagramElement = key;
                }
                else {
                    diagramElement = key.element;
                }
                if (
                    diagramElement === 'Touch_A' ||
                    diagramElement === 'Touch_B' ||
                    diagramElement === 'Touch_X' ||
                    diagramElement === 'Touch_Y' ||
                    diagramElement === 'Touch_5' ||
                    diagramElement === 'Touch_6' ||
                    diagramElement === 'Touch_7'
                ) {
                    totalTouchButtons += 1;
                }
            });
        }
        return totalTouchButtons;
    }

    renderKeyList = (asvi_actions, name) => {
        if (!asvi_actions || name !== 'keyboard') return;

        // count the total of direction keys, if only one key found: show the DPAD
        let totalDirectionsKeys = 0;
        for (const [key, entry] of Object.entries(asvi_actions)) {
            if (!entry.label || !entry['key']) {
                continue;
            }
            entry['key'].forEach((key) => {
                if (key === 'Key_Left' || key === 'Key_Right' || key === 'Key_Up' || key === 'Key_Down') {
                    totalDirectionsKeys += 1;
                }
            });
        }

        const reactElements = [];
        // eslint-disable-next-line no-unused-vars
        for (const [key, entry] of Object.entries(asvi_actions)) {
            if (!entry.label || !entry['key']) {
                continue;
            }

            const label = entry.label;
            let keyName = entry['key'].filter(s => s.includes('Key_'))[0];

            if ((keyName === 'Key_Left' || keyName === 'Key_Right' || keyName === 'Key_Up' || keyName === 'Key_Down') && totalDirectionsKeys === 1) {
                keyName = 'Key_DPAD';
            }

            reactElements.push(<div className="key-entry" key={keyName}>
                <div className="key-entry-inner">
                    <span className="key-label">{label}</span>
                    <span className="key-illus">
                        <img className={keyName} alt={label} src={require(`../../../assets/images/how-to-play/${keyName}.svg`)} />
                    </span>
                </div>
            </div>);
        }

        return <div className={`key-list ${this.getClassDependsOnElementsCount(reactElements.length)}`}>{reactElements}</div>;
    };

    getClassDependsOnElementsCount = (elementsCount) => {
        if (elementsCount > 14) return 'very-long-list';
        if (elementsCount > 9) return 'long-list';
        return '';
    };

    shouldDisplayBackgroundImage = () => {
        const { asvi_actions, backgroundImage } = this.props;
        if (deviceInfo.isPlayStationPlatformPS4() && backgroundImage) {
            return true;
        }
        if (deviceInfo.isPlayStationPlatformPS5() && backgroundImage) {
            return true;
        }

        return !asvi_actions && backgroundImage;
    };

    render() {
        const { name, backgroundImage, image, asvi_actions, inputTypes, touchLayout } = this.props;
        const { isImageLoading } = this.state;
        return (
            <div key={name} className={`control ${name}`}>
                {this.renderKeyList(asvi_actions, name)}
                <div className="image-container">
                    {isImageLoading && <Loader loading={true}/>}
                    {this.shouldDisplayBackgroundImage() && <img src={backgroundImage} alt="" />}
                    {!asvi_actions && !backgroundImage &&
                        <div className="gradient-top-border">
                            <div className="inner"></div>
                        </div>
                    }
                    {!asvi_actions && image && <img className="controls" src={image} alt={`control-${name}`} onLoad={() => {
                        this.setState({
                            isImageLoading: false
                        })
                    }} />}

                    {asvi_actions &&
                        <SVG className='controls-image-svg'
                             src={this.getDynamicDiagram(name, touchLayout, asvi_actions)}
                             onLoad={() => this.updateDiagram(asvi_actions, inputTypes)} />}
                </div>
            </div>
        );
    }
}
export default HowToPlayContent

