import React,{Component} from 'react';
import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import inputManager from '../../../assets/lib/inputmanager';
import './quit-game-popup.component.less';

const navLayer=2;

class QuitGamePopup extends Component {
	componentDidMount() {
		inputManager.setCurrentChildById("QuitGamePopup-cancelButton");
		inputManager.setBackFunction(this.props.setBackFunction || this.onCancelClicked);
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null,navLayer);
	}

	onOkClicked = () => {
		if(this.props.onOkClicked)this.props.onOkClicked();
		closePopup();
	};

	onCancelClicked = () => {
		if(this.props.onCancelClicked)this.props.onCancelClicked();
		closePopup();
	};

	render() {
		return (
			<div className="quit-game-popup-component">
				<div className="message-text">
					{this.props.message}
				</div>
				<div className="buttons-container">
					<Button
						className="secondary"
						onClick={this.onCancelClicked}
						childId="QuitGamePopup-cancelButton"
						layer={navLayer}
					>
						Cancel
					</Button>
					<Button
						className="primary"
						onClick={this.onOkClicked}
						childId="QuitGamePopup-okButton"
						layer={navLayer}
					>
						OK
					</Button>
				</div>
			</div>
		);
	}
};

export default QuitGamePopup;
