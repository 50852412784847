import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './how-to-play-mapping.less';
import { getRoutingSearch } from '../../../app.selectors';
import { getGetMappingPending } from "../../../entities/entities.selectors";
import { Loader } from '../../common/loader/loader.component';
import { GAMEPAD_AXIS_INVERSIONS, GAMEPAD_BUTTONS, TAB_MENU_PARENT_ID } from '../../../constants';
import DropDown from '../../common/drop-down/drop-down.component';
import { Button } from '../../common/button/common-button.component';
import inputManager from '../../../assets/lib/inputmanager';
import { getGamepadButtonShortName } from '../../../assets/lib/utils';

function HowToPlayMappingComponent({ asvi_actions, mapping, ...props }) {
	useEffect(() => {
		// props.dispatch(GetControllerMappingsAction(props.id));
		if (props.focusOnFirstControl) {
			inputManager.setCurrentChildById(GAMEPAD_BUTTONS.Gamepad_A.name);
		} else if (!inputManager.currentChild) {
			inputManager.setCurrentChildById(`${TAB_MENU_PARENT_ID}-Mapping`, TAB_MENU_PARENT_ID);
		}
	}, [])

	if (props.isGetMappingPending) {
		return <Loader loading={true} />;
	}

	function handleResetRemapButton() {
		if (props.handleResetRemapButton) props.handleResetRemapButton();
	}

	function handleDoneRemapButton() {
		if (props.handleDoneRemapButton) props.handleDoneRemapButton();
	}

	const prepared = prepareAsviActions(asvi_actions, mapping, 'gamepad');
	const preparedAxis = prepareAxisControls(mapping);

	function onAxisButtonClicked(c) {
		props.onAxisChanged(c);
	}

	function onDropDownChanged(changeTo, focusedElement) {
		props.onDropDownChanged(changeTo, focusedElement);
	}

	return (
		<div className='how-to-play-controller-mapping'>
			<div className='left-column'>
				<div className="controls">
					{prepared.map(el => {
						const defaultItem = {name: el.label, value: el.name};
						return (
							<DropDown
								key={el.id}
								childId={el.id}
								ignoreClick={!el.menuItems}
								noArrowIcon={!el.menuItems}
								menuItems={el.menuItems}
								defaultItem={defaultItem}
								throttleMs={500} // this fixes button freezing bug on quick multiple clicking
								onChange={(changed) => onDropDownChanged(changed, el)}
							/>
						);
					})}
				</div>
				<div className='axis-controls'>
					{preparedAxis.map(c => (
						<Button
							className="secondary"
							onClick={() => onAxisButtonClicked(c)}
							childId={"axis-control-btn-"+c.id}
							key={"axis-control-btn-"+c.id}
							throttleMs={500} // this fixes button freezing bug on quick multiple clicking
						>
							{`${c.label} ${c.inverted ? 'Yes' : 'No'}`}
						</Button>
					))}
				</div>
			</div>
			<div className="children">
				{props.children}
				<div className="buttons-container">
					<Button
						className="secondary"
						onClick={handleResetRemapButton}
						childId="resetRemapButton"
					>
						Reset
					</Button>
					<Button
						className="secondary"
						onClick={handleDoneRemapButton}
						childId="doneRemapButton"
					>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
}

function prepareAxisControls(mapping) {
	const gamepadAxis = mapping?.gamepad?.axisInversions;
	return Object.keys(GAMEPAD_AXIS_INVERSIONS).map(key => {
		const action = GAMEPAD_AXIS_INVERSIONS[key];
		const inverted = gamepadAxis ? gamepadAxis[action.name] : false;

		return {
			id: action.name,
			label: action.shortName,
			inverted: inverted || false
		};
	});
}

/**
 * @param asvi_actions Object<{[key: string]: { description: string; label: string; firetv: string[]; gamepad: string[]; key: string[]; touch: string[]}}>
 * @param mapping {{}}
 * @param controllerType {'gamepad'}
 **/
function prepareAsviActions(asvi_actions, mapping, controllerType) {
	const usedButtons = asvi_actions
		? Object.keys(asvi_actions).map(key => {
			return asvi_actions[key];
		}) : [];
	const prepared = Object.keys(GAMEPAD_BUTTONS).map(key => {
		const defaultBtn = GAMEPAD_BUTTONS[key];
		const usedBtn = usedButtons.find(ub => {
			return ub[controllerType]?.some(keyId => keyId === defaultBtn.name);
		});
		if (usedBtn) {
			let label = usedBtn.label || usedBtn.description;
			if (label === 'Movement') {
				label = usedBtn.description;
			}
			label = label + ` (${getGamepadButtonShortName(defaultBtn)})`;

			return {
				id: defaultBtn.name,
				// label: `${usedBtn.label || usedBtn.description || defaultBtn.defaultName || ''} (${defaultBtn.shortName})`,
				label: label,
				menuItems: Object.keys(GAMEPAD_BUTTONS).map(gbKey => {
					const item = GAMEPAD_BUTTONS[gbKey];
					if (item.name === defaultBtn.name) return;

					const usedBtn2 = usedButtons.find(ub => {
						return ub[controllerType]?.some(keyId => keyId === item.name);
					});

					return {
						name: usedBtn2
							? `Swap with ${getGamepadButtonShortName(item)}`
							: `Move to ${getGamepadButtonShortName(item)}`,
						value: item.name,
						defaultValue: defaultBtn.name,
						isSwap: !!usedBtn2
					};
				}).filter(el => !!el)
			};
		}

		// not used btn
		return {
			id: defaultBtn.name,
			label: getGamepadButtonShortName(defaultBtn)
		};
	});

	return prepared;
}

const mapStateToProps = (state) => {
	const id = getRoutingSearch(state).id;

	return {
		id: id,
		isGetMappingPending: getGetMappingPending(state)
	};
};

export const HowToPlayMapping = connect(mapStateToProps)(HowToPlayMappingComponent);