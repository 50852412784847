import React, { Component } from 'react';
import './icon.component.less';

class Icon extends Component {
    render() {
        const { text, iconUrl, iconAltText, largeText } = this.props;
        return (
            <div className="iconBlock">
                { largeText && <div className="iconLargeText">{ largeText }</div> }
                { iconUrl && iconAltText && <img className="iconImage" src={iconUrl} alt={iconAltText} /> }
                { text && <div className="iconText">{ text }</div> }
            </div>
        );
    }
};

export { Icon };
