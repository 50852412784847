import React from 'react';
import FontSizeByLength from '../../common/font-size-by-length/font-size-by-length.component';
import { Avatar } from '../../common/avatar/avatar.component';
import './tile-challenge-history.component.less';
import {PulsarComponent} from '../pulsar/pulsar.component';
import { useSelector } from 'react-redux';
import { getLoggedUser } from '../../../app.selectors';
import { getGiants } from '../../challenge/challenge.selectors';
import SlayerBar from '../slayer-bar/slayer-bar.component';
import { withBadWordMask } from "../../../assets/lib/utils";
import deviceInfo, {OS_TYPE_ANDROID} from '../../../assets/lib/deviceInfo';

const TileChallengeHistory = ({ item, selected }) => {
	if (!item) return null;
	const { gameTitle, gameId, challengeTitle,challengeId, me, opponent, isMultiPlayer , scoreLabel, challengeState, giantSlayerChallengeId, giantSlayerDetails} = item;
	const loggedUserObject = useSelector((state) => getLoggedUser(state));
	const giants = useSelector((state) => getGiants(state));
	let giant = null;
	const transitionClass = deviceInfo.osType === OS_TYPE_ANDROID ? '' : 'with-transition';

	let tileColor = challengeState;
	if (giantSlayerDetails) {
		if (giantSlayerDetails.state === 'live') {
			tileColor = 'issued';
		}
		if (giantSlayerDetails.state === 'completed') {
			if (giantSlayerDetails.winner === 'giant') {
				tileColor = (giantSlayerDetails.giantId === loggedUserObject._id) ? 'win' : 'loss';
			} else {
				tileColor = (giantSlayerDetails.giantId === loggedUserObject._id) ? 'loss' : 'win';
			}
		}
		giant = giants.find(giant => giant._id === giantSlayerDetails.giantId);

		const extraClass = (gameTitle.length > 23) ? 'long-title' : '';

		return (
			<div className={`tile-challenge-history-component tile-giant-slayer-history ${tileColor}`}>
				<div className={`animation-container ${selected?'selected':''}`}>
					<div className={`content-wrapper ${!deviceInfo.isRunningOnNativeClientV2() ? 'clientV1' : ''}`}>
						<div className="content-inner">
							<p className={extraClass}>{gameTitle}</p>
							<h3><span>{challengeTitle}</span></h3>

							<SlayerBar
								currentSlayerWinFraction={giantSlayerDetails.currentSlayerWinFraction || 0}
								targetSlayerWinFraction={giantSlayerDetails.targetSlayerWinFraction}
							/>

							<div className="illus-block">
								<div className={`${giantSlayerDetails.role === 'giant' ? 'slayer-illus' : 'giant-illus'}`}></div>
								<div className={`vs-icon ${!deviceInfo.isRunningOnNativeClientV2() ? 'clientV1' : ''}`}></div>
							</div>

							<div className="slayer-count-block">
								{giantSlayerDetails.numberSlayers || 0}
								<br />Slayer count
							</div>
						</div>
					</div>
					<PulsarComponent borderWithRadius />
				</div>
			</div>
		);
	}

	const reactFZOptions = {
		changePerChar: 2,
		startAtChar: 12
	};

	return (
		<div className={`tile-challenge-history-component ${tileColor}`}>
			<div className={`animation-container ${selected?'selected':''} ${transitionClass}`}>
				<div className="top-border"></div>
				<div className={`content-wrapper ${!deviceInfo.isRunningOnNativeClientV2() ? 'clientV1' : ''}`}>
					<div className="left">
						<FontSizeByLength {...reactFZOptions}>
							{gameTitle || ''}
						</FontSizeByLength>
						<h3>{challengeTitle}</h3>
						{!isMultiPlayer && !giantSlayerChallengeId && <h2 className="number">{me.score !== 'FAIL' ? me.score + ' ' + scoreLabel : me.score}</h2>}
						{giantSlayerDetails && <div className="gs-percentage">
							{`${giantSlayerDetails.targetSlayerWinFraction * 100}% to win`}
						</div>}
					</div>
					<div className="right">
						{isMultiPlayer && <Avatar userData={opponent} />}
						{!isMultiPlayer && !giantSlayerChallengeId && me.medal && <img className="badge" src={me.medal} alt="" />}
						{giant && <Avatar userData={giant} />}
					</div>
				</div>
				{getResultMessage(item)}
				<PulsarComponent borderWithRadius />
			</div>
		</div>
	);
};

const getResultMessage = (item) => {
	const {me, opponent, isMultiPlayer, challengeState, giantSlayerDetails } = item;
	let resultText;

	if (giantSlayerDetails) {
		if (giantSlayerDetails.numberSlayers) {
			const numberSlayers = giantSlayerDetails.numberSlayers;
			const plural = (numberSlayers !== 1) ? 's' : '';
			const percentage = Math.round(giantSlayerDetails.currentSlayerWinFraction * 100);
			let outcome = '';
			if (giantSlayerDetails.state === 'completed') {
				outcome = (giantSlayerDetails.currentSlayerWinFraction > giantSlayerDetails.targetSlayerWinFraction) ? 'won' : 'lost';
			} else {
				outcome = (giantSlayerDetails.currentSlayerWinFraction > giantSlayerDetails.targetSlayerWinFraction) ? 'winning' : 'losing';
			}
			resultText = `${numberSlayers} slayer${plural} ${outcome} at ${percentage}%`;
		} else {
			resultText = '0 slayers';
		}
	} else if (isMultiPlayer) {
		const opponentDisplayName = withBadWordMask(opponent.displayName);
		const myDisplayName = withBadWordMask(me.displayName);
		const opponentScore = opponent.score;

		const reactFZOptions = {
			changePerChar: 5,
			startAtChar: 12
		};

		if(challengeState === 'waiting'){
			resultText = (
				<>
					<span>Waiting for </span>
					<FontSizeByLength {...reactFZOptions}>
						{withBadWordMask(opponentDisplayName)}
					</FontSizeByLength>
				</>
			);
		}else if(challengeState === 'issued') {
			resultText = (
				<>
					<span>Waiting for </span>
					<FontSizeByLength {...reactFZOptions}>
						{withBadWordMask(myDisplayName)}
					</FontSizeByLength>
				</>
			);
		} else {
			resultText = (
				<>
					<FontSizeByLength {...reactFZOptions}>
						{withBadWordMask(opponentDisplayName)}
					</FontSizeByLength>
					<span>&nbsp;scored {opponentScore}</span>
				</>
			);
		}

	} else {
		resultText = 'Solo Challenge';
	}

	return (
		<div className="result-message">
			<span className="result-text">{resultText}</span>
		</div>
	);
};

export default TileChallengeHistory;
