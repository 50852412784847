import {Component} from 'react';
import inputManager from '../../assets/lib/inputmanager';
import { connect } from 'react-redux';
import React from 'react';
import './payment-selection.component.less';
import logo from '../../assets/images/splash/Antstream_Logo_White_Long.svg';
import {Button} from '../common/button/common-button.component';
import {PaymentPlanItem} from './payment-plan-item.component';
import {navigateBack} from '../../app.router';
import { getPaymentPlansByCurrency } from '../../app.selectors';
import {FetchPaymentPlans, SendSelectedSKU} from '../login/login.actions';
import {addPopup} from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import deviceInfo, {
	PLATFORM_TYPE_NVIDIA_SHIELD,
	STORE_TYPE_AMAZON,
	STORE_TYPE_APPLE_IOS,
	STORE_TYPE_GOOGLE,
} from '../../assets/lib/deviceInfo';
import {antstreamService} from '../../app.reducer';
import {openUrlOnDefaultBrowser} from '../../assets/lib/game-wrapper';
import Elastic, { LOG_LEVEL } from '../../assets/lib/elastic.lib';
import {isFreeTierV2FeatureFlagEnabled} from '../../assets/lib/utils';
import { APPLE_IOS_SKU } from '../../constants';
import { isDeviceWithNativePayments } from '../../app.helpers';

class PaymentSelectionComponent extends Component {
	constructor(props) {
		super(props);
		this.selectedSku = null;
	}

	componentDidMount() {
		if(!this.props.plans){
			this.props.dispatch(FetchPaymentPlans());
		}

		if(this.props.plans){
			const term = this.props.isFreeTierV2FeatureEnabled
				? "monthlyItem" : "annualItem";
			inputManager.setCurrentChildById(term,"plansContainer");
		}else{
			inputManager.setCurrentChildById("backButton");
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.plans && !prevProps.plans){
			const term = this.props.isFreeTierV2FeatureEnabled
				? "monthlyItem" : "annualItem";
			inputManager.setCurrentChildById(term,"plansContainer");
		}
	}

	onPlanSelected = (sku) => {
		this.selectedSku = sku;
	};

	renderPlans = () => {
		const items = [];
		const {plans, isFreeTierV2FeatureEnabled} = this.props;

		if (isFreeTierV2FeatureEnabled && plans && plans.length) {
			const annualPlan  = plans.find((plan)=>plan.term === 'annual');
			let monthlyPlan = plans.find((plan)=>plan.term === 'monthly_V2.2');
			if (!monthlyPlan && isDeviceWithNativePayments()) {
				monthlyPlan = plans.find((plan)=>plan.term === 'monthly');
			}

			if (monthlyPlan) {
				items.push(<PaymentPlanItem
					isFreeTierV2FeatureEnabled={isFreeTierV2FeatureEnabled}
					key={monthlyPlan.sku}
					childId="monthlyItem"
					parentId="plansContainer"
					className="monthly"
					term={"Monthly"}
					freemiumV2Price={monthlyPlan.price}
					currencyString={monthlyPlan.currencyString}
					sku={monthlyPlan.sku}
					description="Pay monthly with absolutely no tie in obligation. At any point, you are able to upgrade to our annual saving benefits."
					onSelect = {this.onPlanSelected}
				/>);
			}
			if (annualPlan) {
				items.push(<PaymentPlanItem
					isFreeTierV2FeatureEnabled={isFreeTierV2FeatureEnabled}
					key={annualPlan.sku}
					childId="annualItem"
					parentId="plansContainer"
					className="annual"
					term={annualPlan.term}
					freemiumV2Price={annualPlan.price}
					currencyString={annualPlan.currencyString}
					sku={annualPlan.sku}
					description="With our annual one off payment option, you will save nearly two and a half months subscription cost. Which works out to a total of £24 over the year’s subscription fee."
					onSelect = {this.onPlanSelected}
				/>);
			}
		} else if (plans && plans.length>1) {
			const annualPlan  = plans.find((plan)=>plan.term === 'annual');
			const monthlyPlan = plans.find((plan)=>plan.term === 'monthly');

			if (annualPlan && monthlyPlan){
				items.push(<PaymentPlanItem
					isFreeTierV2FeatureEnabled={isFreeTierV2FeatureEnabled}
					key={monthlyPlan.sku}
					childId="monthlyItem"
					parentId="plansContainer"
					className="monthly"
					term={monthlyPlan.term}
					priceAnnually={monthlyPlan.price * 12}
					priceMonthly={monthlyPlan.price}
					currencyString={monthlyPlan.currencyString}
					sku={monthlyPlan.sku}
					description="Pay monthly with absolutely no tie in obligation. At any point, you are able to upgrade to our annual saving benefits."
					onSelect = {this.onPlanSelected}
				/>);
				items.push(<PaymentPlanItem
					isFreeTierV2FeatureEnabled={isFreeTierV2FeatureEnabled}
					key={annualPlan.sku}
					childId="annualItem"
					parentId="plansContainer"
					className="annual"
					term={annualPlan.term}
					priceAnnually={annualPlan.price}
					priceMonthly={(annualPlan.price/12).toFixed(2)}
					currencyString={annualPlan.currencyString}
					sku={annualPlan.sku}
					description="With our annual one off payment option, you will save nearly two and a half months subscription cost. Which works out to a total of £24 over the year’s subscription fee."
					onSelect = {this.onPlanSelected}
				/>);
			}
		} else {
            Elastic.logDirect(LOG_LEVEL.ERROR,"renderPlans: No plans to render, although I was planning to.");
			return null;
		}

		return items;
	};

	onBackClicked = () => {
		navigateBack();
	};

	onRedeemOkClicked = () => {
		if( deviceInfo.storeType === STORE_TYPE_GOOGLE && deviceInfo.platformType !== PLATFORM_TYPE_NVIDIA_SHIELD ) {
			let url = antstreamService.getPaymentUrl();
			openUrlOnDefaultBrowser(url);
		}
	};

	onRedeemClicked = () => {
		addPopup(<GenericPopup
			okButtonLabel="Ok"
			onOkClicked={this.onRedeemOkClicked}
			message="Go to accounts.antstream.com on a browser to redeem your code."
		/>);
	};

	onPlansContainerRef = (element) => {
		inputManager.onParentRef(element, "plansContainer",true);
	};

	onContinueClicked = () => {	
		if (deviceInfo.storeType === STORE_TYPE_AMAZON && !this.selectedSku)
		{
			const {plans, isFreeTierV2FeatureEnabled} = this.props;
            if (plans) {
            	let term = 'annual';
            	if (isFreeTierV2FeatureEnabled) {
					term = 'monthly';
				}
				const somePlan = plans.find((plan)=>plan.term === term);
            	if (!somePlan) {
                	Elastic.logDirect(LOG_LEVEL.ERROR,"AMAZON_PAYMENT_PLANS onContinueClicked: not found plan with term " + term + " Plans: " + JSON.stringify(plans));                
            	}
				this.selectedSku = somePlan.sku;
            } else {
                Elastic.logDirect(LOG_LEVEL.ERROR,"AMAZON_PAYMENT_PLANS onContinueClicked: There are no plans to continue to. Payments broken.");                
            }
		}

		else if(deviceInfo.storeType===STORE_TYPE_APPLE_IOS)
		{
			this.selectedSku = APPLE_IOS_SKU;
		}

		if(this.selectedSku)
		{
			this.props.dispatch(SendSelectedSKU(this.selectedSku));
			navigateBack();
		}
	};

	render() {
		return (
			<section className="payment-selection">
				<img src={logo} className="logo" alt="logo" />
				<h1>You are not subscribed yet!</h1>
				<p>Enjoy a truly evolved retro experience from our ever growing collection of treasured titles. Immerse yourself in a world of challenges anywhere, on any supported device.</p>
				{deviceInfo.storeType === STORE_TYPE_AMAZON && <p className="offerText">EXCLUSIVE OFFER: Two months free for Amazon customers.</p>}
				<div
					className="plans-container"
					ref={this.onPlansContainerRef}
				>
					{this.renderPlans()}
				</div>
				<p>With Antstream, all you need is an account and our app.  You can play cross platform, with controllers, keyboards or touchscreens. You can access any game in our library at any time and play with no limits.</p>
				<div className="buttons-container">
				{deviceInfo.storeType !== STORE_TYPE_APPLE_IOS && <Button
						className="secondary"
						onClick={this.onRedeemClicked}
						childId="redeemCodeButton"
					>
						Redeem Code
					</Button>
	}
					<Button
						className="secondary"
						onClick={this.onBackClicked}
						childId="backButton"
					>
						Back
					</Button>
					<Button
						className="primary"
						onClick={this.onContinueClicked}
						childId="continueButton"
					>
						{deviceInfo.storeType === STORE_TYPE_AMAZON && 'Start Free Trial'}
						{deviceInfo.storeType !== STORE_TYPE_AMAZON && 'Continue'}
					</Button>
				</div>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		plans: getPaymentPlansByCurrency(state)
	};
};

export const PaymentSelection = connect(mapStateToProps)(PaymentSelectionComponent);