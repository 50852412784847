import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getIsPostAuthFetched,
	getLoggedUser,
	areVideoAdsEnabled,
	getUserIsSubscribed,
	getPaymentData,
	getCurrencyCode
} from '../../app.selectors';
import AudioManager, {TYPE_GEM_COUNTER, TYPE_GEM_SPENT, TYPE_XP_GAIN} from '../../app.audio';
import CountAnimation from '../animations/count.animation';
import GemAnimation, {TYPE_EXP, TYPE_GEM} from '../animations/gem.animation';
import { updateRankAnimationData } from '../rankup/rankup.component';
import './user-header.component.less';
import {PlayerProfileHeaderComponent} from '../common/player-profile/player-profile-header.component';
import inputManager from '../../assets/lib/inputmanager';
import {navigateToLocation, ROUTES} from '../../app.router';
import { addPopup } from '../popup/popup.component';
import ShopPopup from "../popup/shop-popup/shop-popup.component";
import { NavigationItem } from '../navigation/navigation-item.component';
import StorePopup from '../popup/store-popup/store-popup.component';
import {
	FREEMIUM_V2_PLANS, getFreemiumV2Price,
	isFreeTierV2FeatureFlagEnabled, isFreeTrialSubscription,
	isXboxCrossplayEnabled,
	isXboxUser
} from '../../assets/lib/utils';
import xboxCrossplayEnabledIcon from '../../assets/images/generic-icons/xbox-crossplay-enabled-icon.png';
import xboxCrossplayDisabledIcon from '../../assets/images/generic-icons/xbox-crossplay-disabled-icon.png';
import XboxSubscriptionPopup, { popupType as xboxPopupType } from '../popup/xbox-subscription-popup/xbox-subscription-popup.component';
import deviceInfo, { isDeviceIphone, STORE_TYPE_APPLE_IOS } from '../../assets/lib/deviceInfo';
import { startNativePayment } from '../../assets/lib/game-wrapper';
import { APPLE_IOS_SKU } from '../../constants';

let initialDataFetched = false;
let currentGem = -1;
let currentExpObject = null;
let lastGemsUpdateId = -1;
const setCurrentGem = (gem,gemsUpdateId) => {
	if(lastGemsUpdateId<=gemsUpdateId) {
		currentGem = gem;
		lastGemsUpdateId = gemsUpdateId;
		return true;
	}else {
		return false;
	}
};

export const clearHeaderData = () => {
	initialDataFetched = false;
	currentGem = -1;
	currentExpObject = null;
	lastGemsUpdateId = -1;
};

const updateGemDataWithoutAnimation = (gemsObject) => {
	setCurrentGem(gemsObject.amount,gemsObject.gemsUpdateId);
};
const updateExpDataWithoutAnimation = (expObject) => {
	currentExpObject = expObject;
};

export let addAnimationToStack = ()=>null;
export let cancelHeaderAnimations = ()=>null;
export let updateGemAnimationData = updateGemDataWithoutAnimation;
export let updateExpAnimationData = updateExpDataWithoutAnimation;

const clearFunctions = () => {
	addAnimationToStack = ()=>null;
	cancelHeaderAnimations = ()=>null;
	updateGemAnimationData = updateGemDataWithoutAnimation;
	updateExpAnimationData = updateExpDataWithoutAnimation;
};

class UserHeaderComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAnimating:false,
			animationStack:[],
			currentAnimationItem:null,
			xpWithTransition:true,
			expPercentage:0,
			profileButtonSelected:false,
		};

		initialDataFetched = false;

		this.onGemAnimationStart = this.onGemAnimationStart.bind(this);
		this.onGemAnimationEnd = this.onGemAnimationEnd.bind(this);
		this.countAnimationStart = this.countAnimationStart.bind(this);
		this.addAnimationToStack = this.addAnimationToStack.bind(this);
		this.cancelHeaderAnimations = this.cancelHeaderAnimations.bind(this);
		this.applyAnimationItemData = this.applyAnimationItemData.bind(this);
		addAnimationToStack = this.addAnimationToStack;
		cancelHeaderAnimations = this.cancelHeaderAnimations;
		updateGemAnimationData = this.setGems;
		updateExpAnimationData = this.setExp;
	}

	componentDidMount() {
		this.setUserInitialValues();
	}

	componentDidUpdate() {
		this.setUserInitialValues();

		if(!this.state.isAnimating && this.state.animationStack.length>0) {
			this.setState((prevState)=>{
				const newStack = [ ...prevState.animationStack ];
				const currentAnimationItem = newStack.shift();
				if (!currentAnimationItem || !currentAnimationItem.count) {
					console.error('Animation skipped because count is 0 or not a number');
				}
				return{
					isAnimating: true,
					currentAnimationItem: currentAnimationItem,
					animationStack: [ ...newStack ],
				};
			});
		}
	}

	componentWillUnmount () {
		clearFunctions();
	}

	setUserInitialValues() {
		if(this.props.loggedIn && !initialDataFetched) {
			initialDataFetched = true;
			this.setGems();
			this.setExp();
		} else if (!this.props.loggedIn) {
			clearHeaderData();
		}
	}

	addAnimationToStack (type, count, startPoint, containerType, props, containerId, animationStartCallback) {
		this.setState((prevState)=>{
			return {
				animationStack: [
					...prevState.animationStack,
					{
						type,
						count,
						startPoint,
						containerType,
						props,
						containerId,
						animationStartCallback,
					}
				]
			};
		});
	}

	applyAnimationItemData(item) {
		const {type,props} = item;
		const {gemsObject,expObject,animationStartCallback} = props;

		if(type === TYPE_GEM) {
			this.setGems(gemsObject,animationStartCallback);
		} else if (type === TYPE_EXP) {
			if(currentExpObject.rank < expObject.rank) {
				updateRankAnimationData(currentExpObject.rank, expObject.rank);
			}
			this.setExp(expObject,animationStartCallback);
		}
	}

	cancelHeaderAnimations(containerType,containerId) {
		if(this.state.currentAnimationItem && this.state.currentAnimationItem.containerId===containerId) {
			this.applyAnimationItemData(this.state.currentAnimationItem);
			this.setState({
				isAnimating:false,
				currentAnimationItem: null
			});
		}

		const filteredStack = this.state.animationStack.filter((animation)=>{
			if(animation.containerType!==containerType) {
				return true;
			} else {
				this.applyAnimationItemData(animation);
				return false;
			}
		});
		this.setState({
			animationStack: filteredStack
		});
	}

	setExp = (expObject,animationStartCallback) => {
		if(expObject) {
			if(animationStartCallback) animationStartCallback();
			currentExpObject = expObject;
		}else {
			if(!currentExpObject) {
				const {rank, exp, expPercentage} = this.props;
				currentExpObject = {rank, exp, expPercentage};
			} else {
				// this case is when header component was unmounted (user was at the game page) and then mounted again
				// in this case we need to update currentExpObject if exp changes happened 
				const {rank, exp, expPercentage} = this.props;
				if (currentExpObject.rank !== rank ||
					currentExpObject.exp !== exp ||
					currentExpObject.expPercentage !== expPercentage
				) {
					currentExpObject = {rank, exp, expPercentage};
				}
			}
		}

		const {rank, expPercentage} = currentExpObject;

		let xpWithTransition;

		if(expObject && this.state.expPercentage<expPercentage) {
			AudioManager.playAudio(TYPE_XP_GAIN);
			xpWithTransition = true;
		} else if(!expObject && this.state.expPercentage!==0) {
			xpWithTransition = true;
		} else {
			xpWithTransition = false;
		}

		this.setState({
			xpWithTransition,
			expPercentage,
			rank,
		});
	};

	setGems = (gemsObject,animationStartCallback) => {
		if(currentGem === -1) {
			setCurrentGem(this.props.gems,-1);
		}

		if(gemsObject) {
			const updated = setCurrentGem(gemsObject.amount,gemsObject.gemsUpdateId);
			if(animationStartCallback) animationStartCallback();
			if(!updated)return;//current Gem Update Id was bigger. gem does not updated
		}

		this.setState(prevState => ({
			gems: gemsObject ? gemsObject.amount : currentGem,
			oldGems: prevState.gems ? prevState.gems : currentGem,
		}));
	};

	countAnimationStart = () => {
		const {oldGems, gems} = this.state;

		if (oldGems && gems && oldGems !== gems) {
			if(gems>oldGems) {
				AudioManager.playAudio(TYPE_GEM_COUNTER);
			} else {
				AudioManager.playAudio(TYPE_GEM_SPENT);
			}
		}
	};

	getAnimationElement = (containerType) => {
		if(this.state.currentAnimationItem && this.state.currentAnimationItem.type===containerType) {
			const {type,count,startPoint} = this.state.currentAnimationItem;

			return (
				<GemAnimation
					startPoint={startPoint}
					count={count}
					onAnimationStart={this.onGemAnimationStart}
					onAnimationEnd={this.onGemAnimationEnd}
					animationType={type}
				/>
			);
		}

		return null;
	};

	onGemAnimationStart() {
		if(this.state.currentAnimationItem) this.applyAnimationItemData(this.state.currentAnimationItem);
	}

	onGemAnimationEnd() {
		this.setState({isAnimating: false, currentAnimationItem: null});
	}

	renderGemValueWithCountAnimation = () => {
		const {gems, oldGems} = this.state;

		return(<CountAnimation
			startValue={oldGems ? oldGems : 0}
			endValue={gems ? gems : 0}
			onAnimationStart={this.countAnimationStart}
			duration={1.5}
		/>);
	};

	onComponentRef = (element) => {
		inputManager.onChildRef(element,"profileButton",this.onProfileButtonFocus,"navBar");
	};

	onProfileButtonFocus = (focus) => {
		this.setState({profileButtonSelected:focus});
	};

	onProfileButtonClicked = () => {
		inputManager.setCurrentChildById("profileButton","navBar");
		navigateToLocation(ROUTES.MY_PROFILE_INFO);
	};

	onStoreIconClicked = () => {
		if (this.props.isXboxUser) {
			addPopup(<XboxSubscriptionPopup type={xboxPopupType.SUBSCRIPTION_RENEWAL}/>, {}, true);
			return;
		}
		if(deviceInfo.isDeviceIphone())
			{
			startNativePayment(APPLE_IOS_SKU);
			return;
			}

		if (!this.props.isPostAuthFetched || this.props.isUserSubscribed) return;
		addPopup(<StorePopup price={this.props.freemiumV2Price}/>);
	};

	renderXboxIcon = () => {
		if (!this.props.isXboxUser) return null;

		const src = this.props.isXboxCrossplayEnabled
			? xboxCrossplayEnabledIcon : xboxCrossplayDisabledIcon;
		const alt = this.props.isXboxCrossplayEnabled
			? "Cross Play ON" : "Cross Play OFF";

		return (
			<div>
				<img className='xboxIcon' src={src} alt={alt} />
			</div>
		);
	};

	render () {
		if (!this.props.user) return null;

		const {rank, expPercentage} = this.state;
		const {isPostAuthFetched, isUserSubscribed, isFreeTierV2FeatureFlagEnabled} = this.props;
		const isShowStoreIcon = this.props.isXboxUser ||
			(isPostAuthFetched && deviceInfo.storeType === STORE_TYPE_APPLE_IOS && isFreeTrialSubscription(this.props.paymentData)) ||
			(isFreeTierV2FeatureFlagEnabled && isPostAuthFetched && !isUserSubscribed);

		return (<>
			{this.renderXboxIcon()} 
			<div className="user-header">
				<PlayerProfileHeaderComponent
					userData={this.props.user}
					expPercentage={expPercentage}
					rank={rank}
					gemAnimationElement={()=>this.getAnimationElement(TYPE_GEM)}
					expAnimationElement={()=>this.getAnimationElement(TYPE_EXP)}
					renderGemValueWithCountAnimation={this.renderGemValueWithCountAnimation}
					selected={this.state.profileButtonSelected}
					showVideoPlus={this.props.showVideoPlus}
					handleShopButtonClick={() => addPopup(<ShopPopup />)}
					isShowStoreIcon={isShowStoreIcon}
					handleStoreIconClicked={this.onStoreIconClicked}
					profileComponentRef={this.onComponentRef}
					handlePlayerProfileHeaderClicked={() => this.onProfileButtonClicked()}
				/>
				<NavigationItem
					key="settings"
					parentId={'navBar'}
					itemType="settings"
					section="settings"
					onClick={() => {
						navigateToLocation(ROUTES.SETTINGS_ACCOUNT);
					}}
				/>
			</div>
		</>
		);
	}
};


const mapStateToProps = (state) => {
	const user = getLoggedUser(state);
	if(!user) return {loggedIn:false};
	const loggedIn = true;
	const {displayName, profile_image_id, currencies, expPercentage, Rank, avatarType} = user;
	const { GEMS, EXP } = currencies;

	return {
		paymentData: getPaymentData(state),
		isXboxUser: isXboxUser(state),
		isXboxCrossplayEnabled: isXboxCrossplayEnabled(state),
		freemiumV2Price: getFreemiumV2Price(getCurrencyCode(state), FREEMIUM_V2_PLANS.annual),
		isFreeTierV2FeatureFlagEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		user,
		loggedIn,
		displayName,
		profile_image_id,
		avatarType,
		rank:Rank,
		exp:EXP,
		expPercentage,
		gems:GEMS,
		showVideoPlus: areVideoAdsEnabled(state)
	};
};

export const UserHeader = connect(mapStateToProps)(UserHeaderComponent);
