import React, {Component} from 'react';
import deviceInfo from '../../../assets/lib/deviceInfo';

import './popup-animation.component.less';

class PopupAnimation extends Component {
	constructor (props) {
		super(props);

		this.state = {
			componentClass:''
		};
	}

	startShowAnimation = () => {
		this.setState({
			componentClass: deviceInfo.isWebviewUltralight() ? 'show-xbox' : 'show'
		});
	};

	startHideAnimation = () => {
		this.setState({
			componentClass:'hide'
		});
	};

	onAnimationEnd = () => {
		if(this.state.componentClass==='hide' && this.props.onHideAnimationEnd)this.props.onHideAnimationEnd();
		if(this.state.componentClass==='show' && this.props.onShowAnimationEnd)this.props.onShowAnimationEnd();
		if(this.state.componentClass==='show-xbox' && this.props.onShowAnimationEnd)this.props.onShowAnimationEnd();
	};

	render() {
		return (
			<div onAnimationEnd={this.onAnimationEnd} className={`popup-animation ${this.state.componentClass}`}>
				{this.props.children}
			</div>
		);
	}
}

export default PopupAnimation;
