import React, { Component, useEffect } from 'react';

import { connect } from 'react-redux';

import ListContainer, {
	checkScrollPosition,
	getDurationForScrollPosition,
	LIST_TYPES
} from '../common/list/list-container.component';
import GameInfoPanel from '../common/game-info-panel/game-info-panel.component';
import GiantSlayerInfoPanel from '../common/giant-slayer-info-panel/giant-slayer-info-panel.component';
import GiantSlayerRandomPanel from '../common/giant-slayer-random-panel/giant-slayer-random-panel.component';
import HeroCarousel from '../common/hero-carousel/hero-carousel.component';
import { SetScrollTopAction} from './homepage.actions';
import { getScrollTopActive } from './homepage.selectors';
import {getGameLists, getFeaturedBanners} from '../../entities/entities.selectors';
import { ROUTES, navigateToLocation } from '../../app.router';
import {
	setFreeTierInformationShown,
	nextGemsUpdateId,
	addVideoGems,
	fetchCommsMessages,
	allowGemPopupAction,
	setPaymentPastDuePopupShown,
	SetWebviewState
} from '../../app.actions';
import { SetTutorialId } from '../tutorial/tutorial.actions.js';
import {Loader} from '../common/loader/loader.component';
import inputManager, { PARENT_ENTRANCE } from '../../assets/lib/inputmanager';
import {
	clearGameDetailsState,
	RunGameAction,
	SetSelectedGameInfoAction,
	SetSelectedGiantSlayerInfoAction
} from '../game-details/game-details.actions';

import {
	getPreviousRoute,
	getIsFreeTierPopupShown,
	isFreeTierBannerNeeded,
	getRoutingSearch,
	getLoggedUser,
	getAllowGemPopup,
	getGemPlayGiantSlayerRandomCost,
	getLoggedUserAccess,
	getIsPaymentPastDuePopupShown,
	getPaymentData,
	getIsPostAuthFetched,
	getUserIsSubscribed,
	getWebviewState
} from '../../app.selectors';
import {addPopup} from '../popup/popup.component';
import {SetSelectedFeaturedChallengeAction} from '../game-details/game-details.actions';
import ChallengeTile from '../common/tiles/tile-challenge.component';

// import TestConnectionPopup from '../popup/test-connection-popup/test-connection-popup.component';
// import {onConnectionTesterPopupSeen} from '../settings/settings.actions';
import {getConnectionTesterPopupSeen} from '../settings/settings.selector';
import {CheckTournamentInfoPopups} from "../tournaments/tournaments.actions";
import {externalFocusableComponent} from "../game-details/info/game-details-info.component";
import deviceInfo, {OS_TYPE_ANDROID, STORE_TYPE_SAMSUNG_TV} from "../../assets/lib/deviceInfo";
import featureInfo, {FEATURES} from "../../assets/lib/featureInfo";
import './homepage.component.less';
import {openQuitPopup} from "../../app.actions";
import { APPLE_IOS_SKU, FEATURED_BANNER_TYPES, MORE_BUTTON_ID, PREMIUM_POPUP_HEADER_TEXT_1 } from '../../constants';
import {externalFocusableComponent as externalFocusableFreeTierComponent} from '../free-tier-information/free-tier-information.component'
import GemCreditPopup from '../popup/gem-credit-popup/gem-credit-popup.component';
import { AbandonGiantSlayerChallengeAction, GetGiantSlayerChallengesAction, GiantSlayerRandomJoinRequestAction } from '../challenge/challenge.actions';
import TileGiantSlayer from '../common/tiles/tile-giant-slayer.component';
import { getGiantSlayerChallenges } from '../challenge/challenge.selectors';
import { getSelectedGiantSlayerChallenge, getSelectedGiantSlayerChallengeGame } from '../game-details/game-details.selector';
import PaymentPastDuePopup from '../popup/payment-past-due-popup/payment-past-due-popup.component';
import { readPaymentPastDuePopupTimestamp } from '../../assets/lib/local-storage';
import {
	getNavigationData,
	isFreemiumAppleIosUser,
	isFreemiumV4UserNotSubscribed,
	isFreeTierV2FeatureFlagEnabled
} from '../../assets/lib/utils';
import {isLowSpecDevice} from '../../app.helpers';
import { ClearDelayedPopupAction } from '../popup/popup.actions';
import { FetchGameDetailed } from '../../entities/games/games.actions';
import {updateSearch} from "../search/search.component";
import {setSearchQueryAction} from "../search/search.actions";
import moreLightTileImage from '../../assets/images/generic-icons/more_light.png';
import moreDarkTileImage from '../../assets/images/generic-icons/more_dark.png';
import { fetchLoginData, ToggleNativePaymentOnHomepage } from '../login/login.actions';
import GenericPopup, { TYPE_HAS_TWO_BUTTONS } from '../popup/generic-popup/generic-popup.component';
import SubscriptionComparisonsPopup
	from "../popup/subscription-comparisons-popup/subscription-comparisons-popup.component";
import { startNativePayment } from '../../assets/lib/game-wrapper';

const PAGE_ID = 'homepage_1';

const REFRESH_PREVIEW_DELAY_VERTICAL = 1550;
const REFRESH_PREVIEW_DELAY = 400;			// Wait before attempting to refresh the game preview pane - This is so we don't interrupt the scrolling
const VIEW_MODE_CAROUSEL = 'carousel-view';
const VIEW_MODE_INFO = 'info-view';
const VIEW_MODE_GIANT_SLAYER_INFO = 'giant-slayer-info-view';
const VIEW_MODE_GIANT_SLAYER_RANDOM = 'giant-slayer-random-view';
let prevTile = null;
let timer = null;

class HomepageComponent extends Component {
	constructor() {
		super();
		this.state = {
			viewMode: VIEW_MODE_CAROUSEL,
			topContainerHidden:false,
			loaded:false,
			hasCheckedMessages: false,
			randomGiantSlayerChallengeId: null,
			giantSlayerListUpdating: false,
			homepageGiantSlayerChallenges: [],
		};
		this.onChange = this.onChange.bind(this);
		this.infoTimerId = null;
		this.selectedGameId = null;
		this.lists = null;
		this.selectedGiantSlayerChallengeId = null;
		this.selectedListIndex = null;
		this.setSelectedItem=this.setSelectedItem.bind(this);
		this.onGameTileClicked = this.onGameTileClicked.bind(this);
		this.onListScroll = this.onListScroll.bind(this);
		this.hideTopContainer = this.hideTopContainer.bind(this);
		this.showTopContainer = this.showTopContainer.bind(this);
		this.clickTimer = null;

		this.rowsWithMoreOption={
			"Games with Challenges":"Challenges",
			"Multiplayer Games":"Multiplayer",
			"Board Games":"Board",
			"Racing Games":"Racing",
			"Casino Games":"Casino",
			"Fighting Games":"Fighting",
			"Arcade Adventure Games":"Adventure",
			"Maze Games":"Maze",
			"Strategy Games":"Strategy",
			"Role Playing Games":"Role",
			"Beat'em-Up Games":"Beat'em-Up",
			"Text Adventure Games":"Text",
			"Driving Games":"Driving",
			"Flight Sim Games":"Flight",
			"Pinball Games":"Pinball",
			"Puzzle Games":"Puzzle",
			"Adventure Games":"Adventure",
			"Action Games":"Action",
			"Shooting Games":"Shooting",
			"Sports Games":"Sports",
			"Platform Games":"Platform"
		}
	}

	componentDidUpdate(prevProps) {
		const {scrollTopActive, userId, dispatch, isFreeTierPopUpNeeded, currentParameters} = this.props

		if(scrollTopActive) {
			dispatch(SetScrollTopAction(false));
		}

		if(isFreeTierPopUpNeeded) dispatch(setFreeTierInformationShown());

		/* 
		 * Set a short delay to determine if comms messages are available. 
		 * It seems that there is still some processing going on at this point in the post-authentication
		 * functions, which can cause timing issues if there are dependencies.
		 */
		if (userId && !this.state.hasCheckedMessages) {
			this.setState({ hasCheckedMessages: true }, () => {
				timer = setTimeout(() => {
					dispatch(fetchCommsMessages(userId));
				}, 2000);
			})
		}

		// if(typeof this.props.connectionTesterPopupSeen !== 'undefined' && !this.props.connectionTesterPopupSeen){
		// 	this.props.dispatch(onConnectionTesterPopupSeen());
		// 	addPopup(<TestConnectionPopup/>);
		// }

		if (this.props.userAccess !== prevProps.userAccess) {
			// because the check for FEATURES.GIANT_SLAYER is based on userAccess, the first check in componentDidMount was always false on first page load (userAccess was not set yet)
			if (featureInfo.isSupported(FEATURES.GIANT_SLAYER)) {
				dispatch(GetGiantSlayerChallengesAction());
				dispatch(AbandonGiantSlayerChallengeAction());
			}
		}

		if (this.props.giantSlayerChallenges.length !== prevProps.giantSlayerChallenges.length
			|| !this.giantSlayerListIdsAreEqual(prevProps.giantSlayerChallenges, this.props.giantSlayerChallenges)
		) {
			this.createHomepageGiantSlayerChallenges();
			if (currentParameters.focusElement === 'giantSlayer') {
				if (this.props.giantSlayerChallenges.length > 0) {
					// when coming from giant slayer result page, select a giant slayer tile
					inputManager.setCurrentChildById(this.props.giantSlayerChallenges[0].giantSlayerChallengeId, "giantSlayer");
				} else {
					// no more giant slayer in the list, select the home button by default
					inputManager.setCurrentChildById("home", "navBar");
				}
			}
			// force 'refreshNavigationData()' after giantSlayerChallenges list has changed (typically after creating or finishing a GS challenge) to prevent navigation bugs
			this.setState({ giantSlayerListUpdating: true });
			setTimeout(() => {
				this.setState({ giantSlayerListUpdating: false }, () => {
					// if the giant slayer list was updated - scroll to the preselected tile again after the lists were updated
					this.scrollToPreviouslySelectedTile();
				});
				// prevent arrow navigation order bugs
				inputManager.refreshBoundingRects();
			}, 1000);

		} else if (this.props.gameLists.length !== prevProps.gameLists.length) {
			// force 'refreshNavigationData()' in ListContainer after gameLists are loaded to prevent scroll bugs
			this.setState({ giantSlayerListUpdating: true });
			setTimeout(() => {
				this.setState({ giantSlayerListUpdating: false });
				// prevent arrow navigation order bugs
				inputManager.refreshBoundingRects();
			}, 1000);
		}

		this.checkPaymentPastDuePopup();

		if (!deviceInfo.isPlayStationPlatform()) {
			if (prevProps.homepageGamesFetching
				&& !this.props.homepageGamesFetching
				&& !this.props.homepageGamesFetched) {
				this.displayFailedHomepageGamesPopup();
			}
		}

		if (deviceInfo.isRunningOnNativeClientV2() && !inputManager.currentChild) { // fix for lost selection focus on internet connection reestablished
			// if no focus - focus on play now
			inputManager.setCurrentChildById("home", "navBar");
		}
	}

	quitPopupBackFunction = () => {
		inputManager.setBackFunction(()=>{
			if (deviceInfo.osType === OS_TYPE_ANDROID || deviceInfo.storeType === STORE_TYPE_SAMSUNG_TV) {
				openQuitPopup(this.quitPopupBackFunction);
			}
		});
	};
	
	componentDidMount () {
		window.addEventListener('scroll', this.handleWindowScroll, true);

		const {currentParameters={}, gems, dispatch, gemPopupAllowed, delayedPopups} = this.props;
		this.quitPopupBackFunction();

		/** if showHowToPlay is turned on for some reason - clear all gameDetails state **/
		if (this.props.showHowToPlay) {
			dispatch(clearGameDetailsState());
		}

		if (featureInfo.isSupported(FEATURES.GIANT_SLAYER)) {
			dispatch(GetGiantSlayerChallengesAction());
			dispatch(AbandonGiantSlayerChallengeAction());
			this.createHomepageGiantSlayerChallenges();
		}

		dispatch(CheckTournamentInfoPopups());
		const {showGemsPopup, gemsGain, event='watched'} = currentParameters
		
		if(showGemsPopup && gemsGain!=null && gems!=null && gemPopupAllowed) {
			const gemsUpdateId = nextGemsUpdateId()
			const gemsObject = {
				gemsUpdateId: gemsUpdateId,
				amount: parseInt(gems) + parseInt(gemsGain)
			};
			dispatch(allowGemPopupAction(false));
			dispatch(addVideoGems(event));
			addPopup(<GemCreditPopup 
				value={gemsGain}
				gemsObject={gemsObject}
				title="Earned Gems!"
				message={`Ad ${event}. You’ve gained:`}
				buttonText="Got It!"
			/>);
		}

		if ((deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) && !inputManager.currentChild) { // fix for lost selection focus on internet connection reestablished
			// if no focus - focus on play now
			inputManager.setCurrentChildById("home", "navBar");

			setTimeout(() => { // if still no focus - focus on play now
				if (!inputManager.currentChild) {
					inputManager.setCurrentChildById('home', 'navBar');
				}
			}, 1000);
		}

		if (delayedPopups.length) {
			// if we have some delayed popups - show them now
			setTimeout(() => {
				delayedPopups.forEach(p => addPopup(p));
				dispatch(ClearDelayedPopupAction());
			}, 2000);
		}

		this.startNativeIosPayment();

		// if failed to load homepage lists - display popup with retry button
		if (!deviceInfo.isPlayStationPlatform()) {
			if (!this.props.homepageGamesFetched && !this.props.homepageGamesFetching) {
				this.displayFailedHomepageGamesPopup();
			}
		}

		// if for some reason (xbox suspend) webview was not set to visible - set it now
		if (!this.props.isWebViewVisible) {
			console.log('WARNING! webview was not set to visible for some reason. Setting it now.');
			this.props.dispatch(SetWebviewState({visible: true}));
		}
	}

	startNativeIosPayment = () => {
		if (!this.props.nativePaymentOnHomepage) return;

		this.props.dispatch(ToggleNativePaymentOnHomepage(false));

		startNativePayment(APPLE_IOS_SKU);
	};

	retryHomepageGamesFetch = async () => {
		await new Promise((resolve) => setTimeout(resolve, 3000));

		fetchLoginData(this.props.dispatch);
	};

	displayFailedHomepageGamesPopup = () => {
		addPopup(<GenericPopup
			okButtonLabel="Retry"
			buttonType={TYPE_HAS_TWO_BUTTONS}
			title="Error"
			message="Failed to load games lists. Please check your internet connection and try again"
			onOkClicked={this.retryHomepageGamesFetch}
		/>);
	};

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleWindowScroll);

		this.setSelectedItem(null,null);
		if(this.prevTileTimeout) {
			clearTimeout(this.prevTileTimeout);
			this.prevTileTimeout = null;
			prevTile = null;
		}
		if(this.clickTimer) {
			clearTimeout(this.clickTimer);
			this.clickTimer=null;
		}
		if(this.infoTimerId) {
			clearTimeout(this.infoTimerId);
			this.infoTimerId=null;
		}
		this.props.dispatch(SetSelectedGameInfoAction(null));
		inputManager.setBackFunction(null);
		clearTimeout(timer);

		if (this.selectedGiantSlayerChallengeId) {
			this.setSelectedGiantSlayerItem(null);
		}
	}

	giantSlayerListIdsAreEqual(prev, next) {
		if (prev.length !== next.length) return false;

		let hasNewChallenge = false;
		for (let theIndex = 0; theIndex < next.length; theIndex++) {
			const nextChallenge = next[theIndex];
			const found = prev.find(prevChallenge => prevChallenge._id === nextChallenge._id);
			if (!found) {
				hasNewChallenge = true;
				break;
			}
		}

		return !hasNewChallenge;
	}

	scrollToPreviouslySelectedTile = () => {
		const { selectedListIndex, selectedTileId } = this.props;
		if (!selectedListIndex || !selectedTileId) return;
		if (!checkScrollPosition) return;
		if (!inputManager?.currentChild) return;

		console.log('scrollToPreviouslySelectedTile');
		try {
			// get navigation data of the DOM element
			const navData = getNavigationData(inputManager.currentChild.element);

			// scroll to the selected item
			checkScrollPosition(selectedListIndex, navData);

			// show the top container with selected game after scroll animation is finished
			if (!getDurationForScrollPosition) return;
			const scrollAnimationDurationMS = getDurationForScrollPosition(selectedListIndex) * 1000; 
			setTimeout(() => {
				this.showTopContainer((selectedListIndex === 'giantSlayer') ? (selectedTileId === 'randomGiantSlayer') ? VIEW_MODE_GIANT_SLAYER_RANDOM : VIEW_MODE_GIANT_SLAYER_INFO : VIEW_MODE_INFO);
			}, scrollAnimationDurationMS);
		} catch (err) {
			console.log('scrollToPreviouslySelectedTile FAILED: ' + err?.message);
		}
	};

	checkPaymentPastDuePopup = () => {
		const {dispatch, isPaymentPastDuePopupShown, paymentData} = this.props;
		if (
			!paymentData ||
			paymentData.subscriptionStatus !== "past_due" ||
			isPaymentPastDuePopupShown
		) {
			return;
		}

		const paymentPastDuePopupTimestamp = readPaymentPastDuePopupTimestamp();

		const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

		if (!paymentPastDuePopupTimestamp ||
			(paymentPastDuePopupTimestamp && Date.now() - paymentPastDuePopupTimestamp > sevenDaysInMs)
		) {
			addPopup(<PaymentPastDuePopup />);
			dispatch(setPaymentPastDuePopupShown()); // prevents re-opening the popup during this session
		}
	}

	createHomepageGiantSlayerChallenges = () => {
		const data = this.props.giantSlayerChallenges.filter(item => item.state === 'live' && (item.role === 'eligible' || item.role === 'giant'));

		// if at least 2 eligible GS challenges, add the "random" tile at the beginning
		if (!isLowSpecDevice()) {
			const eligibleCount = data.filter(item => item.role === 'eligible').length;
			if (eligibleCount >= 2) {
				data.unshift({
					_id: 'randomGiantSlayer'
				});
			}
		}

		// move all GS challenges created by the logged user at the beginning of the list
		data.sort((a, b) => {
			if (a.role === 'giant' && b.role !== 'giant') {
				return -1;
			}
			return 0;
		});

		// provide giantCount for each "giant" (to display "1 of 3")
		data.forEach((item, index) => {
			if (item.role === 'giant') {
				item.giantCount = index + 1;
			}
		});

		this.setState({ homepageGiantSlayerChallenges: data }, () => {
			inputManager.refreshBoundingRects();
		});
	};

	onTopContainerFocus = () => {
		this.setSelectedItem(null,null);
		this.setState({ viewMode: VIEW_MODE_CAROUSEL });
		if(this.infoTimerId)clearTimeout(this.infoTimerId);
	};

	onGameTileClicked = (itemId, listIndex, hasChallenges) => {
		if (listIndex === '7 Brutal Challenges') {
			return;
		}

		if(this.selectedGameId===itemId && this.selectedListIndex===listIndex) {
			if(listIndex==='Games with Challenges') {
				navigateToLocation(ROUTES.GAME_CHALLENGES, {id: itemId, focusElement: 'initialChallenge'});
			} else {
				if (this.props.isFreeTierV2FeatureEnabled) {
					if (this.props.isPostAuthFetched && !this.props.isUserSubscribed && hasChallenges) {
						navigateToLocation(ROUTES.GAME_CHALLENGES, {id: itemId, focusElement: 'initialChallenge'});
					} else {
						navigateToLocation(ROUTES.GAME_INFO, {id: itemId, focusElement: externalFocusableComponent.PLAY_BUTTON});
					}
				} else {
					navigateToLocation(ROUTES.GAME_INFO, {id: itemId, focusElement: externalFocusableComponent.PLAY_BUTTON});
				}
			}
			prevTile = {itemId,listIndex};
		}
		else if (itemId === MORE_BUTTON_ID) {
			navigateToLocation(ROUTES.SEARCH);
			var searchTerm = this.rowsWithMoreOption[listIndex]
			if (updateSearch) {
				updateSearch(searchTerm);
			} else {
				this.props.dispatch(setSearchQueryAction(searchTerm));
			}
		}
		if (this.selectedGiantSlayerChallengeId === itemId && this.selectedListIndex === listIndex) {
			if (this.selectedGiantSlayerChallengeId === 'randomGiantSlayer' && this.props.giantSlayerRandomCost <= this.props.gems) {
				inputManager.lockInputAndScreen(true);
				// join a random GS challenge as a slayer
				this.props.dispatch(GiantSlayerRandomJoinRequestAction((result) => {
					if (!result.success || !result.details) {
						inputManager.lockInputAndScreen(false);
						console.error('GiantSlayerRandomJoinRequest error');
						return;
					}
					const challengeId = result.details.challengeId;
					const gameId = result.details.gameId;
					const giantSlayerChallengeId = result.details.giantSlayerChallengeId;

					// starts animation (slows down carousel in banner to stop on selected GS challenge)
					this.setState({ randomGiantSlayerChallengeId: giantSlayerChallengeId  });

					setTimeout(() => {
						inputManager.lockInputAndScreen(false);
						this.props.dispatch(RunGameAction({
							gameId: gameId,
							challengeId,
							challengeStyle: 'intro',
							giantSlayerChallengeId: 'randomGiantSlayer',
                            role: 'slayer',                            
						}));
						navigateToLocation(ROUTES.HOW_TO_PLAY, {
							id: gameId,
							challengeId,
							giantSlayerChallengeId
						});
					}, 3000);
				}));
			} else if (this.props.giantSlayerChallenge && this.props.giantSlayerChallenge.role === 'eligible' && this.props.giantSlayerChallenge.attemptCost <= this.props.gems) {
				if (this.props.isFreemiumAppleIos) {
					startNativePayment(APPLE_IOS_SKU);
					return;
				}

				if (this.props.isFreemiumV4User) {
					addPopup(<SubscriptionComparisonsPopup leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_1} customClassName={'subscription-comparisons-popup-1300'} pageId={'homepage_1'}/>);
					return;
				}

				// join as a slayer
				const challengeId = this.props.giantSlayerChallenge.challengeId;
				const gameId = this.props.giantSlayerChallenge.gameId;
				this.props.dispatch(RunGameAction({
					gameId: gameId,
					challengeId,
					challengeStyle: 'intro',
					giantSlayerChallengeId: this.selectedGiantSlayerChallengeId,
                    role: 'slayer',                    
				}));
				navigateToLocation(ROUTES.HOW_TO_PLAY, {
					id: gameId,
					challengeId,
					giantSlayerChallengeId: this.selectedGiantSlayerChallengeId
				});
			}
			
		}
		this.startClickTimer();
		this.showTopContainer((listIndex === 'giantSlayer') ? (itemId === 'randomGiantSlayer') ? VIEW_MODE_GIANT_SLAYER_RANDOM : VIEW_MODE_GIANT_SLAYER_INFO : VIEW_MODE_INFO);
		this.onChange(itemId, listIndex, true);
	}

	startClickTimer = () => {
		if(this.clickTimer) {
			clearTimeout(this.clickTimer);
			this.clickTimer=null;
		}
		this.clickTimer = setTimeout(()=>{this.clickTimer=null;},REFRESH_PREVIEW_DELAY_VERTICAL+100);
	}

	setSelectedItem(gameId,listIndex) {
		if (gameId === MORE_BUTTON_ID) return;

		this.selectedGameId = gameId;
		this.selectedListIndex = listIndex;
		this.props.dispatch(SetSelectedGameInfoAction(gameId));
	}

	retrieveGameInfoIfNeeded = () => {
		if (this.props.selectedGiantSlayerGame) return;
		if (!this.props.giantSlayerChallenge) return;

		this.props.dispatch(FetchGameDetailed(this.props.giantSlayerChallenge.gameId))
	}

	setSelectedGiantSlayerItem(giantSlayerChallengeId) {
		this.selectedGiantSlayerChallengeId = giantSlayerChallengeId;
		this.props.dispatch(SetSelectedGiantSlayerInfoAction(giantSlayerChallengeId));

		if (giantSlayerChallengeId && giantSlayerChallengeId !== 'randomGiantSlayer') {
			this.retrieveGameInfoIfNeeded();
		}
	}

	onChange = (itemId, listIndex, withClick) => {
		const delayTimeout = REFRESH_PREVIEW_DELAY;
		clearTimeout(this.infoTimerId);

		if (withClick) {
			if (listIndex === 'giantSlayer') {
				if (this.state.viewMode!==VIEW_MODE_GIANT_SLAYER_INFO) {
					this.setState({ viewMode: VIEW_MODE_GIANT_SLAYER_INFO });
				}
				(itemId === 'randomGiantSlayer') ? this.showTopContainer(VIEW_MODE_GIANT_SLAYER_RANDOM) : this.showTopContainer(VIEW_MODE_GIANT_SLAYER_INFO);
				this.setSelectedGiantSlayerItem(itemId);
				this.selectedListIndex = 'giantSlayer';
			} else {
				if (this.state.viewMode!==VIEW_MODE_INFO) {
					this.setState({ viewMode: VIEW_MODE_INFO });
				}
				this.setSelectedItem(itemId,listIndex);
			}
		} else {
			this.infoTimerId = setTimeout(() => {
				if (this.topContainerHidden && listIndex === 'giantSlayer') {
					if (itemId !== 'randomGiantSlayer') {
						this.showTopContainer(VIEW_MODE_GIANT_SLAYER_RANDOM);
					} else {
						this.showTopContainer(VIEW_MODE_GIANT_SLAYER_INFO);
					}
				}
				if(!this.topContainerHidden) {
					if (listIndex === 'giantSlayer') {
						if (this.state.viewMode!==VIEW_MODE_GIANT_SLAYER_INFO) {
							this.setState({ viewMode: VIEW_MODE_GIANT_SLAYER_INFO });
						}
						(itemId === 'randomGiantSlayer') ? this.showTopContainer(VIEW_MODE_GIANT_SLAYER_RANDOM) : this.showTopContainer(VIEW_MODE_GIANT_SLAYER_INFO);
						this.setSelectedGiantSlayerItem(itemId);
						this.selectedListIndex = 'giantSlayer';
					} else {
						if (this.state.viewMode!==VIEW_MODE_INFO) {
							this.setState({ viewMode: VIEW_MODE_INFO });
						}
						this.setSelectedItem(itemId,listIndex);
					}
				}
			}, delayTimeout);
		}
		
	};

	onListGenerateComplete = () => {
		const { currentParameters } = this.props;
		this.props.dispatch(SetTutorialId(PAGE_ID));
		const isPreviousRouteGameDetailPage = this.props.previousRoute.path.includes(ROUTES.GAME.path);

		if (currentParameters.focusElement !== 'giantSlayer') {
			if(prevTile && isPreviousRouteGameDetailPage) {
				this.prevTileTimeout = setTimeout(()=>{
					this.prevTileTimeout =null;
					this.startClickTimer();
					inputManager.setCurrentChildById(prevTile.itemId,prevTile.listIndex);
					prevTile = null;
				},10);
			} else {
				inputManager.setCurrentChildById("home","navBar");
				prevTile = null;
			}
		}
	};

	onListScroll(event) {
		const className= event.target.className;
		if (className==='list-container') {
			if(this.topContainerHidden && this.gamesList && this.gamesList.children[0].scrollTop === 0) {
				this.showTopContainer(VIEW_MODE_CAROUSEL);
			}else if(!this.clickTimer) {
				this.hideTopContainer();
			}
		}
	}

	hideTopContainer() {
		if(!this.topContainerHidden) {
			this.topContainerHidden = true;//object variable created to effect component instantly for prevent run multiple times
			this.setState({topContainerHidden:true});//state var for rendering
		}
	}

	showTopContainer(viewMode) {
		this.topContainerHidden = false;//object variable created to effect component instantly for prevent run multiple times
		this.setState({
			topContainerHidden: false,
			viewMode
		});//state var for rendering
	}

	onLoaderRef = (element) => {
		if (element) setTimeout(()=>this.setState({loaded: true}),10) ;
	};

	onBrutalGameTileClicked = (gameId, challengeId) => {
		navigateToLocation(ROUTES.GAME_CHALLENGES, {
			id: gameId,
			focusElement: challengeId,
			challengeId
		});
	}

	renderGamesList () {
		const { gameLists } = this.props;
		const defaultValuesForList = {
			type: LIST_TYPES.HORIZONTAL,
			parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL
		};

		if (!this.lists) {
			this.lists = gameLists.map((list) => {
				if (list.isChallengeList) {
					return {
						...list,
						id: list._id,
						title: list._id,
						data: list.challenges,
						renderer: (props) => {
							return (
								<div className="brutal-challenge-wrapper">
									<ChallengeTile
										{...props}
										useShouldComponentUpdate={false}
										noUnlock={true}
										childId={props.id}
										handleClick={() => {
											if (props?.selected) {
												this.onBrutalGameTileClicked(
													props?.item?.gameId,
													props?.item?.challengeId
												);
											}
										}}
									/>
								</div>
							);
						},
						...defaultValuesForList,
					};
				}

				const filteredGames = list.games.filter((game) => game)
				return {
					...list,
					id: list._id,
					title: list._id,
					data: filteredGames,
					...defaultValuesForList,
				};
			});

			// Determine if we can add the search shortcut tile to the end of the carousel
			this.lists.map((list) => {
				const moreGames = {
					_id: MORE_BUTTON_ID,
					image_uri: isLowSpecDevice() ? moreLightTileImage : moreDarkTileImage
				};

				if (this.rowsWithMoreOption[list.id] && list.games[list.games.length-1]?._id!=moreGames._id) {
					list.games.push(moreGames);
					list.data.push(moreGames);
				}
			});
		}

		// if active GS challenges, add "Giant Slayer" list on top
		if (this.lists[0]._id === 'giantSlayer') {
			this.lists.shift();
		}

		if (this.state.homepageGiantSlayerChallenges) {
			this.lists.unshift({
				_id: 'giantSlayer',
				index: -5,
				id: 'giantSlayer',
				title: 'Giant Slayer',
				renderer: (props) => {
					return (<TileGiantSlayer {...props} />)},
				data: this.state.homepageGiantSlayerChallenges,
				...defaultValuesForList,
			})
		}
	
		return (
				<ListContainer
					lists={this.lists}
					newDataLoading={this.state.giantSlayerListUpdating}
					onTileFocus={this.onChange}
					onTileClick={this.onGameTileClicked}
					onListGenerateComplete={this.onListGenerateComplete}
					scrollToTop={this.props.scrollTopActive}
					isFocusItemListTop={true}
					isLazyLoading={true}
					lazyLoadingChunkSize={3}
				/>
		);
	}

	onClick = (selectedItem, hasX) => {
		if(hasX) {
			const contentType = selectedItem.type;
			const isTournament = contentType === FEATURED_BANNER_TYPES.TOURNAMENTS || 
							contentType === FEATURED_BANNER_TYPES.TOURNAMENTS_GROUP

			if(isTournament){
				if(contentType===FEATURED_BANNER_TYPES.TOURNAMENTS_GROUP){
					navigateToLocation(ROUTES.TOURNAMENTS_LIST, {group:selectedItem.group, groupTitle:selectedItem.title});
				}else{
					navigateToLocation(ROUTES.TOURNAMENTS_INFO, {id:selectedItem._id});
				}
				inputManager.setCurrentChildById("tournaments","navBar");
			}else if(contentType===FEATURED_BANNER_TYPES.GAME){
				navigateToLocation(ROUTES.GAME_INFO, {id: selectedItem._id, focusElement: externalFocusableComponent.PLAY_BUTTON});
			}else if(selectedItem.type===FEATURED_BANNER_TYPES.FREE_TIER){
				navigateToLocation(ROUTES.FREE_TIER_INFORMATION,{ focusElement: externalFocusableFreeTierComponent.BACK_BUTTON})
			} else {
				this.props.dispatch(SetSelectedFeaturedChallengeAction(selectedItem._id));
				navigateToLocation(ROUTES.GAME_CHALLENGES, { id: selectedItem.gameId, focusElement: selectedItem._id });
			}
		}
	};

	handleWindowScroll = (e) => {
		if (e.target?.className === 'list-container') {
			this.onListScroll(e);
		}
	};

	render() {
		const { gameLists, banners } = this.props;
		if (!this.state.loaded || gameLists.length<=0) {
			return (
				<div ref={this.onLoaderRef} className="loader-games">
					<Loader loading={true}/>
				</div>
			);
		}

		return (
			<section className="homepage-component">
				<div
					className={`top-container ${this.state.viewMode} ${this.state.topContainerHidden?'hidden':''}`}
				>
					<HeroCarousel
						items={banners}
						onSelect={this.onGameTileClicked}
						onClick={this.onClick}
						isActive={this.state.viewMode===VIEW_MODE_CAROUSEL && !this.state.topContainerHidden}
						onFocus={this.onTopContainerFocus}
					/>
					<GameInfoPanel/>
					<GiantSlayerInfoPanel/>
					{this.state.viewMode === VIEW_MODE_GIANT_SLAYER_RANDOM && 
						<GiantSlayerRandomPanel
							isVisible={this.state.viewMode === VIEW_MODE_GIANT_SLAYER_RANDOM}
							randomGiantSlayerChallengeId={this.state.randomGiantSlayerChallengeId}
							giantSlayerChallenges={this.state.homepageGiantSlayerChallenges}
						/>}
				</div>
				<div
					ref={element=>{this.gamesList=element;}}
					className={`gameslist-container ${this.state.topContainerHidden?'top-container-hidden':''}`}
				>
					{this.renderGamesList()}
				</div>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	const isFreeTierPopUpNeeded = (getIsFreeTierPopupShown(state) === false) && isFreeTierBannerNeeded(state)
	const isPaymentPastDuePopupShown = getIsPaymentPastDuePopupShown(state);
	const paymentData = getPaymentData(state);
	const user = getLoggedUser(state) || {};
	const {currencies, _id} = user || {};
	const { GEMS } = currencies || {};
	const userAccess = getLoggedUserAccess(state);
	const giantSlayerChallenges = getGiantSlayerChallenges(state);
	const showHowToPlay = state.gameDetails ? state.gameDetails.showHowToPlay : false;

	return {
		isPopupHidden: state.popup.isHidden,
		nativePaymentOnHomepage: state.login.nativePaymentOnHomepage,
		isWebViewVisible: getWebviewState(state)?.visible,
		selectedListIndex: state.navigation.selectedListIndex,
		selectedTileId: state.navigation.selectedTileId,
		delayedPopups: state.popup.delayedPopups,
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		giantSlayerChallenges: giantSlayerChallenges,
		giantSlayerChallenge: getSelectedGiantSlayerChallenge(state),
		selectedGiantSlayerGame: getSelectedGiantSlayerChallengeGame(state),
		homepageGamesFetching: state.entities?.games?.homepageGamesFetching,
		homepageGamesFetched: state.entities?.games?.homepageGamesFetched,
		gameLists: getGameLists(state),
		scrollTopActive: getScrollTopActive(state),
		previousRoute: getPreviousRoute(state),
		connectionTesterPopupSeen: getConnectionTesterPopupSeen(state),
		isFreeTierPopUpNeeded: isFreeTierPopUpNeeded,
		isPaymentPastDuePopupShown,
		paymentData,
		showHowToPlay,
		banners: getFeaturedBanners(state),
		currentParameters: getRoutingSearch(state),
		gemPopupAllowed: getAllowGemPopup(state),
		isFreemiumAppleIos: isFreemiumAppleIosUser(state),
		isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
		giantSlayerRandomCost: getGemPlayGiantSlayerRandomCost(state),
		gems:GEMS,
		userId: _id,
		userAccess
	};
};

export const Homepage = connect(mapStateToProps)(HomepageComponent);
