import React from 'react';
import actionIcon from '../../../assets/images/genre-icons/action.png';
import adventureIcon from '../../../assets/images/genre-icons/adventure.png';
import arcadeAdventureIcon from '../../../assets/images/genre-icons/arcade-adventure.png';
import beatEmUpIcon from '../../../assets/images/genre-icons/beat-em-up.png';
import drivingIcon from '../../../assets/images/genre-icons/driving.png';
import fightingIcon from '../../../assets/images/genre-icons/fighting.png';
import flightSimIcon from '../../../assets/images/genre-icons/flight-sim.png';
import flightSimulatorIcon from '../../../assets/images/genre-icons/flight-simulator.png';
import mazeIcon from '../../../assets/images/genre-icons/maze.png';
import pinballIcon from '../../../assets/images/genre-icons/pinball.png';
import platformIcon from '../../../assets/images/genre-icons/platform.png';
import puzzleIcon from '../../../assets/images/genre-icons/puzzle.png';
import racingIcon from '../../../assets/images/genre-icons/racing.png';
import rolePlayingGameIcon from '../../../assets/images/genre-icons/role-playing-game.png';
import shootingIcon from '../../../assets/images/genre-icons/shooting.png';
import sportsIcon from '../../../assets/images/genre-icons/sports.png';
import strategyIcon from '../../../assets/images/genre-icons/strategy.png';

const genreIcons = {
	"action":{
		genre:"action",
		icon:actionIcon,
	},
	"adventure":{
		genre:"adventure",
		icon:adventureIcon,
	},
	"arcade-adventure":{
		genre:"arcade-adventure",
		icon:arcadeAdventureIcon,
	},
	"beat-em-up":{
		genre:"beat-em-up",
		icon:beatEmUpIcon,
	},
	"driving":{
		genre:"driving",
		icon:drivingIcon,
	},
	"fighting":{
		genre:"fighting",
		icon:fightingIcon,
	},
	"flight-sim":{
		genre:"flight-sim",
		icon:flightSimIcon,
	},
	"flight-simulator":{
		genre:"flight-simulator",
		icon:flightSimulatorIcon,
	},
	"maze":{
		genre:"maze",
		icon:mazeIcon,
	},
	"pinball":{
		genre:"pinball",
		icon:pinballIcon,
	},
	"platform":{
		genre:"platform",
		icon:platformIcon,
	},
	"puzzle":{
		genre:"puzzle",
		icon:puzzleIcon,
	},
	"racing":{
		genre:"racing",
		icon:racingIcon,
	},
	"role-playing-game":{
		genre:"role-playing-game",
		icon:rolePlayingGameIcon,
	},
	"shooting":{
		genre:"shooting",
		icon:shootingIcon,
	},
	"sports":{
		genre:"sports",
		icon:sportsIcon,
	},
	"strategy":{
		genre:"strategy",
		icon:strategyIcon,
	},
};

const GenreIcon = ({genre,className}) => {
	let genreIconType;
	if (genre === "Beat'em-Up") {
		genreIconType = "beat-em-up";
	} else {
		genreIconType = genre?.replace(' ', '-').toLowerCase();
	}

	if(genreIcons[genreIconType]){
		return (
			<img className={className} src={genreIcons[genreIconType].icon} alt={genreIconType}/>
		);
	}else{
		return null;
	}
};

export {GenreIcon} ;