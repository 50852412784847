import React, {Component} from 'react';
import leaderboardIconHover from "../../../assets/images/generic-icons/icon-leaderboard-podium-hover.png";
import leaderboardIcon from "../../../assets/images/generic-icons/icon-leaderboard-podium.png";
import medal_blank_boss_attack from "../../../assets/images/medal-icons/boss_attack.svg";
import medal_blank_boss_rush from "../../../assets/images/medal-icons/boss_rush.svg";
import medal_blank_carnage from "../../../assets/images/medal-icons/carnage.svg";
import medal_blank_collector from "../../../assets/images/medal-icons/collector.svg";
import medal_blank_deathwish from "../../../assets/images/medal-icons/deathwish.svg";
import medal_blank_pacifist from "../../../assets/images/medal-icons/pacifist.svg";
import medal_blank_pole_position from "../../../assets/images/medal-icons/pole_position.svg";
import medal_blank_score_attack from "../../../assets/images/medal-icons/score_attack.svg";
import medal_blank_speed_run from "../../../assets/images/medal-icons/speed_run.svg";
import medal_blank_survivor from "../../../assets/images/medal-icons/survivor.svg";
import medal_blank_time_attack from "../../../assets/images/medal-icons/time_attack.svg";

import inputManager from "../../../assets/lib/inputmanager";
import {navigateToLocation, ROUTES} from "../../../app.router";
import {externalFocusableComponent} from "../../leaderboard/leaderboard.component";
import { isEmpty } from "lodash";

import "./challenge-summary.less";
import {getDigitCount} from "../../../assets/lib/utils";

const medal_blank = {
	boss_attack: medal_blank_boss_attack,
	boss_rush: medal_blank_boss_rush,
	carnage: medal_blank_carnage,
	collector: medal_blank_collector,
	death_wish: medal_blank_deathwish,
	pacifist: medal_blank_pacifist,
	pole_position: medal_blank_pole_position,
	score_attack: medal_blank_score_attack,
	speed_run: medal_blank_speed_run,
	survivor: medal_blank_survivor,
	time_attack: medal_blank_time_attack,
};

class ChallengeSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			challengeSelected: false
		}
	}

	onTileFocus = (focus) => {
		this.setState({challengeSelected: focus});
	};

	onTileRef = (element) => {
		inputManager.onChildRef(element, null, this.onTileFocus);
	};

	onClickRank = () => {
		const {selectedChallenge, gameId} = this.props;

		navigateToLocation(ROUTES.GAME_CHALLENGE_LEADERBOARD, {
			id: gameId,
			challengeId: selectedChallenge._id,
			focusElement: externalFocusableComponent.PLAY_BUTTON
		});
	};

	render() {
		const {isFreeTierUser, selectedChallenge, currentBestScore, currentMedal, nextMedal, selectedChallengeUserScore, leaderBoardEnabled} = this.props;
		const {challengeSelected} = this.state;
		const tier = selectedChallenge.tier;
		let userPosition = "---";
		let badgeUrl = "";
		const challengeType = selectedChallenge.type && selectedChallenge.type.replace('_', ' ');

		if (selectedChallenge.type && this.props.badges) {
			const badgeObj = this.props.badges[selectedChallenge.type];
			if (badgeObj && badgeObj.outcomes) {
				badgeUrl = badgeObj.outcomes.draw.replace('.svg', '.png');
			}
		}

		if (selectedChallengeUserScore && !isEmpty(selectedChallengeUserScore)) {
			userPosition = selectedChallengeUserScore.rank;
		}

		const minimizedNumbers = getDigitCount(currentBestScore)>6 ||  getDigitCount(nextMedal.score)>6;

		return (
			<div className={`challenge-summary-component t-${tier}`}>
				<div className="top-gradient">
					<div className="challenge-type-container">
						<div className="challenge-type">
							<img src={badgeUrl} alt={badgeUrl}/>
							<span>{challengeType}</span>
						</div>
						<div className={`difficulty t-${tier}`}>
							<span className="one"></span>
							<span className="two"></span>
							<span className="three"></span>
							<span className="four"></span>
							<span className="five"></span>
						</div>
					</div>
				</div>
				<div className="inner">
					<div className="summary-info achieved">
						<div className={`badge achieved ${currentMedal.img_src ? '' : 'blank'}`}>
							{currentMedal.img_src ? <img src={currentMedal.img_src} alt={currentMedal.medal}/> : <img src={medal_blank[selectedChallenge.type]} alt="blank"/>}
						</div>
						<div>
							<div className={`number ${minimizedNumbers && 'minimized'}`}><span>{currentBestScore}</span></div>
							<div>Best Score</div>
						</div>
					</div>
					<div className="summary-info next-badge">
						<div className="badge to-achieve">
							{nextMedal.img_src ? <img src={nextMedal.img_src} alt={nextMedal.medal}/> : null}
						</div>
						<div>
							<div className={`number ${minimizedNumbers && 'minimized'}`}><span>{nextMedal.score}</span></div>
							{nextMedal.score && <div>Next Medal</div>}
						</div>
					</div>
					{leaderBoardEnabled &&
						<div className={`summary-info link`} ref={this.onTileRef} onClick={this.onClickRank}>
							<div className="badge leaderboard">
								<img className={`leaderboard-icon ${challengeSelected ? 'selected' : ''}`}
									 src={challengeSelected ? leaderboardIconHover : leaderboardIcon} alt=""/>
							</div>
							{isFreeTierUser ? (
								<div>
									<div className={`number ${minimizedNumbers && 'minimized'}`} style={{visibility: 'hidden'}}><span>0</span></div>
									<div>"No Position"</div>
								</div>
							) : (
								<div>
									<div className={`number ${minimizedNumbers && 'minimized'}`}><span>{userPosition}</span></div>
									<div>Current Position</div>
								</div>
							)}
						</div>
					}
				</div>
			</div>
		);
	}
}

export default ChallengeSummary;
