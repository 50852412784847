import React,{Component} from 'react';
import { connect } from 'react-redux';
import {ROUTES} from '../../app.router';

import './navigation-item.component.less';
import inputmanager from '../../assets/lib/inputmanager';
import AudioManager, {TYPE_VERTICAL_LIST_ROW_CHANGE} from '../../app.audio';
import {getIsTouchDevice} from "../../app.selectors";
import deviceInfo from '../../assets/lib/deviceInfo';

class NavigationItemComponent extends Component {
	constructor(props) {
		super(props);
		const { selected } = this.props;

		this.touchStarted=false;
		this.state = {
			selected,
		};
	}

	getNavigationObjects = () => {
		const navigationObjects = {
			back:{
				type:"back",
				title:"BACK"
			},
			home:{
				type:"home",
				title:"Play now"
			},
			tournaments:{
				type:"tournaments",
				title:"Tournaments"
			},
			dailyChallenges:{
				type:"dailyChallenges",
				title:"Challenges"
			},
			achievements:{
				type:"achievements",
				title: deviceInfo.isPlayStationPlatform() ? "Trophies" : "Achievements"
			},
			message:{
				type:"message",
				title:"Messages"
			},
			community:{
				type:"community",
				title:"Community"
			},
			library:{
				type:"library",
				title:"LIBRARY"
			},
			xboxLibrary:{
				type:"xboxLibrary",
				title:"Search"
			},
			storeCart: {
				type: 'storeCart',
				title: ''
			},
			playstationLibrary:{
				type:"playstationLibrary",
				title:"Search"
			},
			settings:{
				type:"settings",
				title:"SETTINGS"
			},
			atari:{
				type:"atari",
				title:""
			},
		};

		return navigationObjects;
	}

	onCustomFocus = (focus/*, nextElement*/) => {
		this.setState({selected:focus});
	};
	onClick = (withTouch) =>  {
		if(this.props.disabled) return;

		if(withTouch) {
			if(this.props.onClick) {
				this.sendClickEventToParent();
			}
			this.touchStarted = true;
		} else if(!withTouch) {
			if(!this.touchStarted && this.props.onClick) {
				this.sendClickEventToParent();//do not fire click event after Touch end
			}
			this.touchStarted = false;
		}
	};
	onNavItemRef=(element)=>{
		inputmanager.onChildRef(element,this.props.itemType, this.onCustomFocus, this.props.parentId);
	};

	sendClickEventToParent() {
		if(!this.props.onClick) return;
		if (!this.props.active) {
			AudioManager.playAudio(TYPE_VERTICAL_LIST_ROW_CHANGE);
		}
		inputmanager.setCurrentChildById(this.props.itemType, this.props.parentId);
		this.props.onClick(this.props.isScrollTopActive);
	}

	render() {
		const {disabled, className, badgeTitle, isTouchDevice} = this.props;

		return (
			<div
				className={`nav-item-component ${className?className:''} ${!isTouchDevice?'hover-enabled':''} ${this.state.selected?'selected':''} ${this.props.active?'active':''} ${disabled?'disabled':''} ${this.props.itemType}`}
				onClick={()=>{this.onClick(false);}}
				onTouchStart={()=>{this.onClick(true);}}
				ref={this.onNavItemRef}
			>
				{badgeTitle &&
					<div className="badge">
						<div className="title">{badgeTitle}</div>
					</div>
				}
				<div className="background"/>
				<div className="button-title">
					{this.getNavigationObjects()[this.props.itemType].title}
				</div>
				{this.props.children}
			</div>
		);
	}
}

const mapStateToProps = (state,ownProps) => {
	const currentRoute = state.routing.currentRoute;
	const active = currentRoute.path.match(`/^|${ownProps.section}|$/`) ? 'selected' : '';
	const isScrollTopActive = ownProps.itemType==='home' && currentRoute.path===ROUTES.HOMEPAGE.path;
	const isTouchDevice = getIsTouchDevice(state);

	return {
		active,
		isScrollTopActive,
		isTouchDevice,
	};
};

export const NavigationItem = connect(mapStateToProps)(NavigationItemComponent);
