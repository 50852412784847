import React, { Component } from 'react';

import './in-game-header.component.less';
import menuButton from '../../assets/images/webrtc-game/menu-button.svg';
import iconVirtualKeyboard from '../../assets/images/webrtc-game/icon_virtual_keyboard.svg';
import iconNoneConnection from '../../assets/images/generic-icons/signal00.png';
import iconPoorConnection from '../../assets/images/generic-icons/signal01.png';
import iconAcceptableConnection from '../../assets/images/generic-icons/signal02.png';
import iconGoodConnection from '../../assets/images/generic-icons/signal03.png';
import iconExcellentConnection from '../../assets/images/generic-icons/signal04.png';
import { AvatarWithCircle } from '../common/avatar/avatar-with-circle.component';
import AvatarWithPodium from '../common/podium/podium.component';

class InGameHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuButtonAnimationActive: false,
      startButtonAnimationActive: false,
      oskButtonAnimationActive: false,
    }
  }

  onMenuClicked = () => {
    this.setState({
      menuButtonAnimationActive: true
    }, this.onAnimationStart);

    if (this.props.onMenuClicked) {
      this.props.onMenuClicked();
    }
  }

  onAnimationStart = () => {
    setTimeout(() => {
      this.setState({
        menuButtonAnimationActive: false,
        startButtonAnimationActive: false,
        oskButtonAnimationActive: false,
      });
    }, 150);
  }

  onStartClicked = () => {
    this.setState({
      startButtonAnimationActive: true
    }, this.onAnimationStart);

    if (this.props.onStartClicked) {
      this.props.onStartClicked();
    }
  }

  onStartPressed = () => {
    if (this.props.onStartPressed) {
      this.props.onStartPressed();
    }
  }

  onStartReleased = () => {
    if (this.props.onStartReleased) {
      this.props.onStartReleased();
    }
  }

  onOSKClicked = () => {
    this.setState({
      oskButtonAnimationActive: true
    }, this.onAnimationStart);

    if (this.props.onOSKClicked) {
      this.props.onOSKClicked();
    }
  }

  renderScores = () => {
    if (!this.props.isChallenge) {
      return;
    }
    if (!this.props.scores) {
      return;
    }

    const totalCharacters = this.props.scores.reduce((total, score) => {
      if (!score || !score.title) return total;
      return total + score.title.length;
    }, 0);
    const scoresLargeClass = `scores-large-${totalCharacters >= 20 ? 20 : totalCharacters >= 15 ? 15 : 0}`;

    const scores = this.props.scores.map((score, index) => {
      if (!score) return;
      return (
        <div key={ index } className="score">
          <p className="value">
            { score.value }
          </p>
          <p>
            { score.title }
          </p>
        </div>
      )
    });

    return (
      <div className={`scores ${scores.length > 1 && 'scores-multiple-padding'} ${scoresLargeClass}`}>
        {scores.length <= 1 && this.props.giantSlayerRole &&
          <div className={`gs-illus ${this.props.giantSlayerRole}`}></div>
        }
        { scores }
      </div>
    );
  }

  renderGiantScores = () => {
    if (!this.props.giantSlayerRole || !this.props.giantSlayerChallenge || !this.props.challengeObject) {
      return;
    }

    const giantScoreValue = this.props.giantSlayerChallenge.giantScore;
    const giantScoreLabel = this.props.challengeObject.score_label;

    return (
      <div className="scores giantScores">
        <div className="gs-illus giant"></div>
        <div className="score">
          <p className="value">
            { giantScoreValue }
          </p>
          <p>
            { giantScoreLabel }
          </p>
        </div>
      </div>
    );
  }

  renderAvatar = () => {
    if (this.props.giantSlayerRole === 'giant' && this.props.giantUserData) {
      return (
        <AvatarWithCircle progressCircleCssClass="in-game-header-progress-circle" userData={ this.props.giantUserData } />
      );
    }

    return (
      <AvatarWithCircle progressCircleCssClass="in-game-header-progress-circle" userData={ this.props.userData } />
    );
  }

  getConnectionIcon = (strength) => {
    switch (strength) {
      case 0:
        return iconNoneConnection;
      case 1:
        return iconPoorConnection;
      case 2:
        return iconAcceptableConnection;
      case 3:
        return iconGoodConnection;
      case 4:
        return iconExcellentConnection;
      default:
        return '';
    }
  }

  renderConnectionStrengthIndicator = () => {
    const { connectionStrength } = this.props;
    if (typeof connectionStrength !== 'number' || connectionStrength === -1) return;

    return (
        <div className='connection-strength-indicator'>
          <img className='connection-strength-indicator__img'
               src={this.getConnectionIcon(connectionStrength)} alt='' />
        </div>
    );
  }

  renderPodium = () => {
    return (
      <AvatarWithPodium
        userData={ this.props.userData }
        rank={ this.props.rank }
        isBetterRank={ this.props.isBetterRank }
      />
    );
  }

  render() {
    const {
      menuButtonAnimationActive,
      startButtonAnimationActive,
      oskButtonAnimationActive,
    } = this.state;

    const active = this.props.active ? 'active' : ''
    const menuButtonAnimation = menuButtonAnimationActive ? 'menu-bounce-animation' : ''
    const startButtonAnimation = startButtonAnimationActive ? 'header-button-bounce-animation' : ''
    const oskButtonAnimation = oskButtonAnimationActive ? 'header-button-bounce-animation' : ''

    return (
      <div className="in-game-header">
        { this.props.isTournament && this.renderPodium() }

        { this.renderScores() }

        { this.renderGiantScores() }

        { this.renderAvatar() }

        { this.renderConnectionStrengthIndicator() }

        <div className={ `buttons ${active}` }>
          <img
            alt="menu button"
            className={ `menu-button ${menuButtonAnimation}` }
            src={ menuButton }
            onClick={ this.onMenuClicked } />

          {/* Challenges don't have a start button */}
          { !this.props.isChallenge &&
            <button
              className={ `header-button ${startButtonAnimation}` }
              onClick={ this.onStartClicked }
              onMouseDown={ this.onStartPressed }
              onMouseUp={ this.onStartReleased }
            >
              <div className="content">Start</div>
            </button>
          }

          { this.props.allowOSK &&
            <button
              className={ `header-button osk-button ${oskButtonAnimation}` }
              onClick={ this.onOSKClicked }>
              <div className="content">
                <img
                  alt=""
                  className={ `` }
                  src={ iconVirtualKeyboard }
                />
              </div>
            </button>
          }
        </div>
      </div>
    );
  }
}

export default InGameHeader;
