import { SET_SELECTED_MESSAGE_ID_ACTION, CLEAR_SELECTED_CHALLENGE_ID } from './inbox.actions';
import { FETCH_MESSAGES, FETCH_MESSAGES_SUCCESS } from '../../entities/messages/messages.actions';
import {generateUUID} from '../../app.helpers';

const initialState = {
	loading: false,
	selectedChallengeId:null,
	messagesUpdateId:-1,
};

export const inboxReducer = (state = initialState, action) => {
	switch (action.type) {
	case CLEAR_SELECTED_CHALLENGE_ID:
		return {
			...state,
			selectedChallengeId: null,
		};
	case SET_SELECTED_MESSAGE_ID_ACTION:
		return {
			...state,
			selectedMessageId: action.payload,
		};
	case FETCH_MESSAGES:
		return { 
			...state,
			loading: true
		};
	case FETCH_MESSAGES_SUCCESS:
		return { 
			...state,
			messagesUpdateId:generateUUID(),
			loading: false
		};
	
	default:
		return state;
	}
};
