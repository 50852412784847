import React from 'react';
import GenericContainer, {externalFocusableComponents as genericContainerFocusable} from '../../common/generic-container/generic-container.component';
import { closePopup } from '../../popup/popup.component';
import { BUTTON_STYLES } from '../../common/button/common-button.component';
import { startPaymentProcess } from '../../../app.actions';
import premiumDiamondIcon from '../../../assets/images/global/premium-diamond.png';
import './payment-past-due-popup.component.less';
import { writePaymentPastDuePopupTimestamp } from '../../../assets/lib/local-storage';

const navLevel = 2;

export const externalFocusableComponents = {
    SKIP: 'payment_past_due_popup_cancel',
    REMIND: 'payment_past_due_popup_remind',
    UPDATE: 'payment_past_due_popup_update',
};

export const PaymentPastDuePopup = () => {

    return (
        <GenericContainer 
            className="payment-past-due-popup"
            title="Premium Subscription Renewal"
            buttons={[
                {
                    key: 'skip',
                    label: "Skip",
                    handleClick: () => {
                        writePaymentPastDuePopupTimestamp(Date.now()); // prevents re-opening the popup for one week
                        closePopup()
                    },
                    childId: externalFocusableComponents.SKIP,
                    className: `${BUTTON_STYLES.PRIMARY}`,
                    navLayerValue: navLevel
                },
                {
                    key: 'remind',
                    label: 'Remind me later',
                    handleClick: () => {
                        closePopup();
                    },
                    childId: externalFocusableComponents.REMIND,
                    className: `${BUTTON_STYLES.PRIMARY}`,
                    navLayerValue: navLevel
                },
                {
                    key: 'update',
                    label: 'Update subscription',
                    handleClick: () => {
                        closePopup();
                        startPaymentProcess();
                    },
                    childId: externalFocusableComponents.UPDATE,
                    className: `${BUTTON_STYLES.PRIMARY}`,
                    navLayerValue: navLevel
                }
            ]}
            customBackFunction={ () => closePopup()}
            showPopupBackground={true}
            focusParentId={genericContainerFocusable.BUTTON_CONTAINER}
            focusElement={externalFocusableComponents.UPDATE}
            navLayerValue={navLevel}
        >
            <div className="payment-past-due-popup-content">
                <p>
                Your subscription renewal details need updating to continue enjoying the benefits of the premium level.
                </p>
                <p>
                Your account will be placed on the free tier until you update your subscription.
                </p>
                <img className="premium-diamond-icon" alt="premium-diamond-icon" src={premiumDiamondIcon}/>
            </div>
        </GenericContainer>
    )
}

export default PaymentPastDuePopup;
