import React, { Component } from 'react';

// Assets
import deviceInfo, { STORE_TYPE_EPIC } from '../../../assets/lib/deviceInfo';

// TODO: On new UI, this should be moved to its own store, which would be fed by our backend
//       It should also have its own methods to update and re-render
//       Optionally, we can add an ON and OFF, where if the feature is toggled OFF, it displays another code
//       That could would come from the parent calling <FeatureToggle> with <On> and <Off>

export const FEATURES = {
    PREMIUM_SAVE_ONLY: 'PREMIUM_SAVE_ONLY'
}

const FEATURE_TOGGLES = {
    PREMIUM_SAVE_ONLY: true,
};

export default class FeatureToggle extends Component {

    platformIncludes(platforms, platform) {
        return platforms.filter(element => element === platform).length > 0;
    }

    render() {
        const { children, feature, platforms } = this.props;

        if (!feature || !platforms || !FEATURE_TOGGLES[feature]) {
            return <></>;
        }

        // If EPIC GAMES, needs to check store type
        if (deviceInfo.storeType === STORE_TYPE_EPIC && this.platformIncludes(platforms, STORE_TYPE_EPIC)) {
            return <>{children}</>;
        }

        // TODO: on new UI, add more locks here as needed

        return <></>;
    }
}