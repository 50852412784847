import gamePad from './gamepad.lib.js';
import {addPopup} from '../../components/popup/popup.component';
import GenericPopup, {TYPE_HAS_TWO_BUTTONS} from '../../components/popup/generic-popup/generic-popup.component';
import React from 'react';
import {openUrlOnDefaultBrowser, addGamepadMapping} from './game-wrapper';
import { store } from '../../configureStore';
import {setAsTouchDevice} from "../../app.actions";

let deviceInfoInstance = null;
export const OS_TYPE_ANDROID = 'android';
export const OS_TYPE_IOS = 'ios';
export const OS_TYPE_TV_OS = 'tvos';
export const OS_TYPE_OSX = 'osx';
export const OS_TYPE_WIN_32 = 'win32';
export const OS_TYPE_WIN_UWP = 'winuwp';
export const OS_TYPE_LINUX = 'linux';
export const OS_TYPE_XBOX = 'xbox';
export const OS_TYPE_PLAY_STATION = 'playstation';

export const PLATFORM_TYPE_NVIDIA_SHIELD = 'nvidiaShield';
export const PLATFORM_TYPE_FIRE_TV = 'firetv';
export const PLATFORM_TYPE_ANDROID = 'android';
export const PLATFORM_TYPE_ANDROID_SAMSUNG = 'android-samsung';
export const PLATFORM_TYPE_IPHONE = 'iphone';
export const PLATFORM_TYPE_APPLE_TV = 'appletv';
export const PLATFORM_TYPE_MAC = 'mac';
export const PLATFORM_TYPE_WIN_UWP_X64 = 'winuwp-x64';
export const PLATFORM_TYPE_XBOX_ONE = 'xboxone';
export const PLATFORM_TYPE_WIN32_X86 = 'win32-x86';
export const PLATFORM_TYPE_ANDROID_CROSSWALK = 'androidcrosswalk';
export const PLATFORM_TYPE_ATARI = 'atari';
export const PLATFORM_TYPE_LINUX = 'linux';
export const PLATFORM_TYPE_WEB = 'web';
export const PLATFORM_TYPE_PLAY_STATION_4 = 'ps4';
export const PLATFORM_TYPE_PLAY_STATION_5 = 'ps5';

export const STORE_TYPE_AMAZON       = 'amazon';
export const STORE_TYPE_GOOGLE       = 'google';
export const STORE_TYPE_APPLE_IOS    = 'apple';
export const STORE_TYPE_MICROSOFT    = 'microsoft';
export const STORE_TYPE_ANTSTREAM    = 'antstream';
export const STORE_TYPE_SAMSUNG      = 'samsung';
export const STORE_TYPE_SAMSUNG_TV   = 'samsung-tv';
export const STORE_TYPE_CHINA_MOBILE = 'chinaMobile';
export const STORE_TYPE_TRUE_ID_TV   = 'trueidtv';
export const STORE_TYPE_EPIC         = 'epic';
export const STORE_TYPE_XBOX         = 'xbox';
export const STORE_TYPE_PLAY_STATION = 'playstation';

export const CONTROLLER_TYPE_KEYBOARD_EVENT = 'keyboardEvent';
export const CONTROLLER_TYPE_WEBVIEW = 'webview';

export const FEATURE_VERSIONS = {
    CONTROL_OPTIONS: '2.1.1096',
    SAVE_STATES: "2.1.734",
    CONNECTION_TESTER: "2.1.875",
    DEEP_LINKS: "2.1.1378",
    AMAZON_CURRENCY_DISPLAY: "2.1.1433",
    CONTROL_VOLUME: "2.1.1440"
}
class DeviceInfo {
    constructor() {
        this.osType = null;
        this.platformType = PLATFORM_TYPE_WEB;
        this.deviceID = null;
        this.clientRunID = null;
        this.version = null;
        this.internalVersion = null;
        this.versions = null;
        this.latestVersion = null;
        this.acceptableVersion = null;
        this.storeUrl = null;
        this.storeType = null;
        this.uiController = null;// webview - keyboardEvent
        this.isTouchDevice = false;
        this.model = null;
        this.webview = null;
        this.abi = null;
        this.hardwareID = null;
        this.activeControllers = {};
        this.isWebRTC = true;
        this.safeAreaHorizontalOn = false;
        this.safeAreaVerticalOn = false;
        this.safeAreaHorizontal = 1;
        this.safeAreaVertical = 1;

        const samsungTV = /SMART-TV/.test(navigator.userAgent)
        if(samsungTV)
        {
            this.storeType = STORE_TYPE_SAMSUNG_TV;
        }

        document.addEventListener('touchstart', this.onTouchStart, true);
    }

    setDeviceInfo(osType, platformType, deviceID, clientRunID, version, internalVersion, storeType, uiController, model, webview, abi, hardwareID, safeAreaHorizontal, safeAreaVertical) {
        this.osType = osType;
        this.platformType = platformType;
        this.deviceID = deviceID;
        this.clientRunID = clientRunID;
        this.version = version;
        this.internalVersion = internalVersion;
        this.storeType = storeType;
        this.uiController = uiController;
        this.model = model;
        this.webview = webview;
        this.abi = abi;
        this.hardwareID = hardwareID;
        this.isWebRTC = false;

        // if (safeAreaHorizontal && safeAreaHorizontal !== 1) {
        //     this.safeAreaHorizontalOn = true;
        // }
        // if (safeAreaVertical && safeAreaVertical !== 1) {
        //     this.safeAreaVerticalOn = true;
        // }

        // if (safeAreaHorizontal) {
        //     this.safeAreaHorizontal = safeAreaHorizontal; 
        // }
        // if (safeAreaVertical) {
        //     this.safeAreaVertical = safeAreaVertical;
        // }

        if (navigator.userAgent.match(/ AFT/gi)) {
            this.platformType = PLATFORM_TYPE_FIRE_TV;
        } else if(navigator.userAgent.match(/ SHIELD/gi)) {
            this.platformType = PLATFORM_TYPE_NVIDIA_SHIELD;
        } else if(this.platformType === PLATFORM_TYPE_ANDROID && this.storeType===STORE_TYPE_SAMSUNG){
            this.platformType = PLATFORM_TYPE_ANDROID_SAMSUNG;
        }

        // Do we need to override the PS4 mapping string?
        if (this.model === "HCH03") {   // One of the True TV devices
            addGamepadMapping("050000004c050000cc090000ffff3f00,PS4 Controller Legacy,a:b1,b:b17,back:b15,dpdown:b12,dpleft:b13,dpright:b14,dpup:b11,guide:b5,leftshoulder:b3,leftstick:b4,lefttrigger:a3,leftx:a0,lefty:a1,rightshoulder:b18,rightstick:b6,righttrigger:a4,rightx:a2,righty:a5,start:b16,x:b0,y:b2,platform:Android,");
        }

        // This is a missing mapping for a Xbox Wireless Controller on OSX. Added here temporarily as it is quicker than adding it to the native client.
        addGamepadMapping("030000005e040000130b000011050000,Xbox Wireless Controller,platform:Mac OS X,a:b0,b:b1,x:b3,y:b4,back:b10,guide:b12,start:b11,leftstick:b13,rightstick:b14,leftshoulder:b6,rightshoulder:b7,dpup:h0.1,dpdown:h0.4,dpleft:h0.8,dpright:h0.2,leftx:a0,lefty:a1,rightx:a2,righty:a3,lefttrigger:a5,righttrigger:a4,");

        gamePad.pause();
        gamePad.resume();
    }

    checkVersions(){
        this.checkSaveStatesSupported();
    }

    setVersions(versions) {
        this.versions = versions;
        if (this.platformType !== null) this.setCurrentVersionObject(versions);
    }

    setCurrentVersionObject(versions) {
        const currentVersionObject = versions.find(versionItem => versionItem.platformType === this.platformType);

        if (currentVersionObject) {
            this.latestVersion = currentVersionObject.latestVersion;
            this.acceptableVersion = currentVersionObject.acceptableVersion;
            this.storeUrl = currentVersionObject.storeUrl;

            if (this.isOldVersion(this.acceptableVersion, this.version)) {//have to update
                addPopup(<GenericPopup
                    okButtonLabel="Update Now"
                    title="Update Required"
                    message="An application update is required before you can continue."
                    isPermanent
                    onOkClicked={() => {
                        openUrlOnDefaultBrowser(this.storeUrl);
                    }}
                />, {}, true);
            } else if(this.isOldVersion(this.latestVersion, this.version)) {//warn for new version
                addPopup(<GenericPopup
                    buttonType={TYPE_HAS_TWO_BUTTONS}
                    okButtonLabel="Update Now"
                    cancelButtonLabel="Later"
                    title="Update Recommended"
                    message="For the best experience we recommend you update to the latest version."
                    onOkClicked={() => {
                        openUrlOnDefaultBrowser(this.storeUrl);
                    }}
                />, {}, true);
            }
        }
    }

    isSafeAreaTurnedOn = () => {
        // we need safe area always to be enabled for PS platforms
        // if (this.isPlayStationPlatform()) {
        //     return true;
        // }

        return this.safeAreaVerticalOn || this.safeAreaHorizontalOn;
    }

    isOldVersion(nextVersion, currentVersion) {
        const numericNextVersion = this.getNumericVersion(nextVersion);
        const numericCurrentVersion = this.getNumericVersion(currentVersion);

        let isOld = false;
        if (numericNextVersion.major > numericCurrentVersion.major) {
            isOld = true;
        } else if (numericNextVersion.major === numericCurrentVersion.major && numericNextVersion.minor > numericCurrentVersion.minor) {
            isOld = true;
        } else if (numericNextVersion.major === numericCurrentVersion.major && numericNextVersion.minor === numericCurrentVersion.minor && numericNextVersion.patch > numericCurrentVersion.patch) {
            isOld = true;
        }

        return isOld;
    }

    checkSaveStatesSupported() {
        if(!this.versionSupportsFeature(FEATURE_VERSIONS.SAVE_STATES)){  // This is a bad place for this check, as it doesn't check other reasons for not supporting save states.
            addPopup(<GenericPopup
                buttonType={TYPE_HAS_TWO_BUTTONS}
                okButtonLabel="Update Now"
                cancelButtonLabel="Update Later"
                title="Update Available"
                message="A recommended update is available to improve performance and enable game saves."
                onOkClicked={() => {
                    openUrlOnDefaultBrowser(this.storeUrl);
                }}
            />);
        }
    }

    versionSupportsFeature(versionNumber){
        if(this.version) {// all clients have to have version number
            return !this.isOldVersion(versionNumber, this.version);
        } else {// this means it is test build
            return true;
        }
    }

    getNumericVersion(version) {
        let major;
        let minor;
        let patch;

        try {
            const numbersArray = version.split(".");
            major = parseInt(numbersArray[0]);
            minor = parseInt(numbersArray[1]);
            patch = parseInt(numbersArray[2]);
        } catch (e) {
            console.log("version number error => ", e)
        }

        return {major, minor, patch}
    }

    isDeviceAbleToCloseTheApp = () => {
        const notSupportedPlatforms = [PLATFORM_TYPE_NVIDIA_SHIELD,PLATFORM_TYPE_FIRE_TV,PLATFORM_TYPE_ANDROID,PLATFORM_TYPE_IPHONE];
        return !notSupportedPlatforms.includes(this.platformType);
    };

    isCssFilterDropShadowSupported = () => {
        return !this.isRunningOnNativeClientV2();
    };

    onTouchStart = () => {
        if(!this.isTouchDevice){
            this.isTouchDevice = true;
            store.dispatch(setAsTouchDevice());
        }
    }

    isWaysunDevice = () => {
        return this.osType === OS_TYPE_ANDROID && this.storeType === STORE_TYPE_CHINA_MOBILE;
    }

    isTrueTvDevice = () => {
        return this.osType === OS_TYPE_ANDROID && this.storeType === STORE_TYPE_TRUE_ID_TV;
    }

    isSetTopBoxPaymentFlow= () => {
        return this.isWaysunDevice() || this.isTrueTvDevice();
    }

   // Note - Google sites can be also be blocked by ad-blockers, 
    // this ONLY tests the places where we KNOW if shouldn't be used.
    // Always check any Google-reliant code on a device with google domains blocked.
    platformBlocksGoogle = () => {
        return this.isDeviceIphone() ||               // Need explicit user permission on iOS. Removing until we can do that.
               this.storeType === STORE_TYPE_SAMSUNG   // Samsung declaration that we don't use Google so they put us on all markets
    }

    supportsVideoAds = () => {
        return false;
        /*this.platformType !== PLATFORM_TYPE_FIRE_TV && this.storeType !== STORE_TYPE_SAMSUNG_TV;
        this.hasNativeBrowserSupport() && 
        this.platformType !== PLATFORM_TYPE_XBOX_ONE &&
        this.platformType !== PLATFORM_TYPE_FIRE_TV && this.isSupportedFeature(FEATURE_VERSIONS.ADS)*/
    }

    hasNativeBrowserSupport = () => {
        // Devices that cannot open a browser
        return this.platformType !== PLATFORM_TYPE_NVIDIA_SHIELD &&
        this.platformType !== PLATFORM_TYPE_ATARI && this.storeType !== STORE_TYPE_SAMSUNG_TV
    }
    isRunningOnNative = () => getDeviceInfo().deviceID;

    isRunningOnNativeClientV2 = () => {
        const version = getDeviceInfo().version || '';
        return (typeof version === 'string') && (version.split('.')[0] === '3');
    }

    isWebviewUltralight = () => {
        return this.isRunningOnNativeClientV2()
            && this.webview === 'ultralight'
        ;
    }

    isXboxPlatform = () => {
        return this.isRunningOnNativeClientV2()
            && this.osType === OS_TYPE_XBOX
            && this.platformType === PLATFORM_TYPE_XBOX_ONE
        ;
    };

    isPlayStationPlatformPS4 = () => {
        return this.platformType === PLATFORM_TYPE_PLAY_STATION_4;
    };

    isPlayStationPlatformPS5 = () => {
        return this.platformType === PLATFORM_TYPE_PLAY_STATION_5;
    };

    isPlayStationPlatform = () => {
        return this.isRunningOnNativeClientV2()
            && this.osType === OS_TYPE_PLAY_STATION
            && (this.isPlayStationPlatformPS4()
                || this.isPlayStationPlatformPS5())
        ;
    };

    isDeviceIphone = () => {
        return this.isRunningOnNative()
        && this.osType === OS_TYPE_IOS
            && this.platformType === PLATFORM_TYPE_IPHONE;
    };

    isBrowserOnIphone = () => {
        if (this.isDeviceIphone()){
            return false;
        }
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        return iOS;
    }

    /**
     * Sets the active controllers to true or false.
     * @param {object} controllers 
     */
    setActiveControllers = controllers => {
        if (controllers && !Array.isArray(controllers) && typeof controllers === 'object') {
            return this.activeControllers = controllers;
        }
    }

    hasNeoLegendActive = () => this.activeControllers.neoLegend;
}

const getDeviceInfo = () => {
    if (deviceInfoInstance === null) {
        deviceInfoInstance = new DeviceInfo();
    }
    return deviceInfoInstance;
};

export default getDeviceInfo();

export const getBrowserData = () => {
    const browsers = [
        {
            string: navigator.userAgent,
            subString: 'Chrome',
            identity: 'Chrome'
        },
        {
            string: navigator.userAgent,
            subString: 'OmniWeb',
            versionSearch: 'OmniWeb/',
            identity: 'OmniWeb'
        },
        {
            string: navigator.vendor,
            subString: 'Apple',
            identity: 'Safari',
            versionSearch: 'Version'
        },
        {
            prop: window.opera,
            identity: 'Opera',
            versionSearch: 'Version'
        },
        {
            string: navigator.vendor,
            subString: 'iCab',
            identity: 'iCab'
        },
        {
            string: navigator.vendor,
            subString: 'KDE',
            identity: 'Konqueror'
        },
        {
            string: navigator.userAgent,
            subString: 'Firefox',
            identity: 'Firefox'
        },
        {
            string: navigator.vendor,
            subString: 'Camino',
            identity: 'Camino'
        },
        {
            string: navigator.userAgent,
            subString: 'Netscape',
            identity: 'Netscape'
        },
        {
            string: navigator.userAgent,
            subString: 'MSIE',
            identity: 'Explorer',
            versionSearch: 'MSIE'
        },
        {
            string: navigator.userAgent,
            subString: 'Gecko',
            identity: 'Mozilla',
            versionSearch: 'rv'
        },
        {
            string: navigator.userAgent,
            subString: 'Mozilla',
            identity: 'Netscape',
            versionSearch: 'Mozilla'
        }
    ];

    const operatingSystems = [
        {
            string: navigator.platform,
            subString: 'Win',
            identity: 'Windows'
        },
        {
            string: navigator.platform,
            subString: 'Mac',
            identity: 'Mac'
        },
        {
            string: navigator.userAgent,
            subString: 'iPhone',
            identity: 'iPhone/iPod'
        },
        {
            string: navigator.platform,
            subString: 'Linux',
            identity: 'Linux'
        }
    ];

    function searchForIdentity(data) {
        for (let i = 0; i < data.length; i++) {
            const string = data[i].string;
            const prop = data[i].prop;

            if (string) {
                // Look for the sub string in the string.
                if (string.indexOf(data[i].subString) !== -1) {
                    return data[i].identity;
                }
            } else if (prop) {
                return data[i].identity;
            }
        }
    }

    return {
        browser: searchForIdentity(browsers),
        operatingSystem: searchForIdentity(operatingSystems)
    };
};



export const isDeviceIphoneX = () => {
    // Really basic check for the ios platform
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    var agent = window.navigator.userAgent;
    if (agent.indexOf("iPhone") > -1) {
        return true;
    }
    return false;
};

export const isDeviceChrome = () => {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== 'undefined';
    var isIEedge = winNav.userAgent.indexOf('Edg') > -1;
    var isIOSChrome = winNav.userAgent.match('CriOS');
    if (isIOSChrome) {
      // is Google Chrome on IOS
      return false;
    } else if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
    ) {
      return true;
    }
    return false;
};

export const isDeviceSafari = navigator.vendor && 
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

export const isDeviceFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
