// use to avoid compatibility issues
// between browsers for WebRTC
// https://github.com/webrtchacks/adapter
// import adapter from "webrtc-adapter";

import AntRTCNetwork from "./AntRTCNetwork";
import { PacketTypeString, InputSetupRawFlags, XINPUT_BUTTON, ControlPadMapping } from "./AntRTCNetworkConstants";
import Controls from "./Controls";
import deviceInfo, {
  STORE_TYPE_SAMSUNG_TV,
} from "./deviceInfo";
import Perf from "./perf";
import { AntRTCTelemetry } from "./webrtcTelemetry";

const oskHeight = 230; // height of osk (on screen keyboard) when visible

export default class AntRTC {
  constructor(
    webRTCServer,
    webSocketPort,
    webSocketProtocol,
    sessionId,
    remoteVideo,
    remoteAudio,
    videoOutputTarget,
    videoPostprocessingCanvas,
    superSamplingCanvas,
    controlsCanvas,
    perfCanvas,
    challengeStyle,
    onVideoSetup,
    onSaveResponse,
    onErrorCb,
    onInGameEvent,
    mute,
    fixedJoysticks,
    isUIPaused,
    callUIPause,
    gameId,
    serverIP
  ) {
    this.debugLogs = false;
    this.isRunning = false;
    this.webRTCServer = webRTCServer;
    this.webSocketPort = webSocketPort;
    this.webSocketProtocol = webSocketProtocol;
    this.sessionID = sessionId;
    this.gameId = gameId;
    this.serverIP = serverIP;
    this.onErrorCb = onErrorCb;
    this.onInGameEvent = onInGameEvent;
    this.dataChannel = null;
    this.onVideoSetup = onVideoSetup;
    this.onSaveResponse = onSaveResponse;
    this.messageSendTime = performance.now();
    this.unmuteEnabled = false;
    this.mute = mute;
    this.oskVisible = false;
    this.network = null;
    this.fixedJoysticks = fixedJoysticks;
    this.isUIPaused = isUIPaused;
    this.callUIPause = callUIPause;
    this.gotVideoTrack = false;
    this.gotAudioTrack = false;
    this.isInitiallyPaused = this.isUIPaused();
    this.initialPauseSent = false;

    this.controls = null;
    this.controlsCanvas = controlsCanvas;
    this.videoOutputTarget = videoOutputTarget;
    this.videoPostprocessingCanvas = videoPostprocessingCanvas;
    this.videoSuperSamplingCanvas = superSamplingCanvas;
    this.videoScalingMode = 0;
    this.perfCanvas = perfCanvas;
    this.postprocessing = videoPostprocessingCanvas !== null;
    this.postprocessLatencyDebugging = false;
    this.controlPadStates = [0, 0, 0, 0];
    this.controlPadStatesPrev = [0, 0, 0, 0];
    this.playerPads = {};
    this.playerPadsCount = 0;
    this.multiplayerClient = false;
    this.sessionStart = Date.now();

    this.initialUnpause = false;
    this.isGamepadStartPressed = false;
    this.isGamepadStartPressedPrev = false;
    this.isGamepadStartPressedCounter = 0;

    this.dest = "local:";
    this.remoteVideo = remoteVideo;
    this.remoteAudio = remoteAudio;
    this.preview = null;
    this.mic = false;
    this.cam = false;
    this.mediaConnected = false;
    this.timeoutRunning = false;
    this.iceTimeout = 3000;
    this.peerConnection = null;
    this.webSocket = null;
    this.httpRequest = null;
    this.localStream = null;
    this.constraints = {
      voiceActivityDetection: false,
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    };

    this.controllers = [];
    this.lastGamepadsTimestamps = [0,0,0,0,0,0,0,0];
    this.onEscapeKeyPressed = null;
    this.onTabKeyPressed = null;
    this.onEvent = null;
    this.isPaused = false;
    this.controlsString = "";
    this.gameType = 0;
    this.disableInputs = false;
    this.perfInterval = null;
    this.updateInterval = null;

    // // Check if requestVideoFrameCallback is a function on the video element
    // this.supportsRequestVideoFrameCallback =
    //   typeof this.remoteVideo.requestVideoFrameCallback === "function";

    //WebCodecsDecoding
    this.videoDecoder = null;
    this.readableStream = null;

    this.backButtonIsCoin = false;
    this.bCoinButtonPressed = false;
    this.bVirtualKeybEnabled = false;

    if (window.config.REACT_APP_ENV === 'dev' || window.config.REACT_APP_ENV === 'stg') {
        this.debugLogs = true;
    }

    let params = new URLSearchParams(window.location.search);
    if (params.get("input_map") !== null) {
      this.controlsString = decodeURIComponent(
        decodeURIComponent(params.get("input_map"))
      );
    } else {
      throw new Error("Controls string not found.");
    }

    this.gameProfile = JSON.parse(
      decodeURIComponent(decodeURIComponent(params.get("game_profile")))
    );

    // 0: Ordinary Free Play
    // 1: Challenge
    // 2: Tournament
    // 3: Connection Test
    if (challengeStyle) {
      if (challengeStyle === "intro" || challengeStyle === "challenge") {
        this.gameType = 1;
      } else if (challengeStyle === "tournament") {
        this.gameType = 2;
      } else if (challengeStyle === "test") {
        this.gameType = 3;
      }
    }

    document.addEventListener("touchend", this.initialUnmute);
    document.addEventListener("click", this.initialUnmute);
    document.addEventListener("keydown", this.initialUnmute);
  }

  setEnableVirtualKeyboard(enable) {
    this.bVirtualKeybEnabled = enable;
  }

  sendSave(data) {
    if(this.network) {
        this.network.sendSave(data);
    }
    else {
        console.err("Can't send Save. No network object present");
    }
  }

  initialUnmute = (e) => {
    if (!this.unmuteEnabled) {
      return;
    }
    document.removeEventListener("touchend", this.initialUnmute);
    document.removeEventListener("click", this.initialUnmute);
    document.removeEventListener("keydown", this.initialUnmute);
    this.mute(false);
  };

  onCreateOfferSuccess(desc) {
 
    let videoSettings = {
      codec: "H264",
      maxBitrate: 3000, //kbps
    };

    if (deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV) {
      console.warn(
        "Forcing Samsung TV to use H264 at 2x native games resolution!"
      );
      videoSettings = {
        codec: "H264",
        resolutionFactor: 2.0,
        rescalingFilter: "None",
        maxBitrate: 3000, //kbps
      };
    }

    // When the offer SDP is ready, send it to the server over the WebSocket
    var message = {
      msgType: "connectRequest",
      sessionID: this.sessionID,
      data: {
        videoSettings,
        sdp: {
          type: "falseOffer",
          sdp: desc.sdp,
        },
      },
    };

    this.webSocket.send(JSON.stringify(message));
    this.addKeyListeners();
  }

  sendWebSocketMessage = (message) => {
    // console.log("[WEBSOCKET] Sending: ", message);
    if (this.webSocket) {
      this.webSocket.send(JSON.stringify(message));
    } else {
      console.warn(
        "[WEBSOCKET] Can't send websocket message. Websocket no ready"
      );
    }
  };

  call = () => {
    return new Promise((resolve, reject) => {

      this.timeoutRunning = false;

      const wsURL = this.webSocketProtocol + this.webRTCServer + ":" + this.webSocketPort;
      if(this.debugLogs === true) {
        console.info("[WS] Connecting WebSocket to " + wsURL);
      }
      
      if (!this.webSocket) {
        this.webSocket = new WebSocket(wsURL);

        this.webSocket.onclose = (error) => {
            if(this.debugLogs) {
                console.info("Websocket connection closed");
            }         
            this.webSocket = null;
            if(this.peerConnection) {
                this.peerConnection.close();
                this.peerConnection = null;
            }
            reject(error); // This will reject the promise
        };

        this.webSocket.onopen = () => {
          this.onWebSocketConnectionOpen();
          resolve(); // This will resolve the promise
        };

        this.webSocket.onerror = (error) => {
          this.onWebSocketConnectionError(error);
          reject(error); // This will reject the promise
        };

        this.webSocket.onmessage = this.onWebSocketMessage.bind(this);
      } else {
        resolve();
      }
    });
  };

  endCall = () => {
    if(this.debugLogs) {
        console.log("[WEBRTC] endCall");
    }
    
    this.isRunning = false;

    if (this.network) {
        this.network.sendDisconnect();
        this.network.deInit();
        this.network = null;
      }

    window.removeEventListener("resize", this.resizeVideo.bind(this));

    if (this.remoteVideo) {
      this.remoteVideo.removeEventListener(
        "playing",
        this.onVideoStartPlaying.bind(this)
      );

      this.remoteVideo.removeEventListener(
        "loadedmetadata",
        this.onVideoLoadMetadata.bind(this)
      );
      this.remoteVideo.removeEventListener(
        "error",
        this.onVideoError.bind(this)
      );
    }

    this.gotVideoTrack = false;
    this.gotAudioTrack = false;

    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }

    if (this.perfInterval) {
      clearInterval(this.perfInterval);
      this.perfInterval = null;
    }

    this.removeKeyListeners();

    if (this.controls) {
      console.log("[WEBRTC] ending controls");
      this.controls.remove();
      this.controls = null;
    }

    if (this.perf) {
      this.perf.remove();
      this.perf = null;
    }

    if (this.tele) {
      this.tele.stop();
      this.tele = null;
    }

    this.onErrorCb = null;

    this.onEscapeKeyPressed = null;
    this.onTabKeyPressed = null;
    this.isInCall = false;

    setTimeout(() => {
        if (this.webSocket) {
            console.info("[WEBRTC] Closing WebSocket now ...");
            this.webSocket.close();
            this.webSocket = null;
          }

          if (this.peerConnection) {
            if(this.debugLogs) {
                console.info("[WEBRTC] Closing PeerConnection now ...");
            }      
            this.peerConnection.close();
            this.peerConnection = null;
          }
    
      }, 500); // Delay
  };

  onWebSocketConnectionOpen() {
    if(this.debugLogs) {
        console.info("[WEBRTC] WebSocket connection open");
    }
    
    var setupMessage = {
      msgType: "serverSetup",
      sessionID: this.sessionID,
      data: {
      },
    };

    this.webSocket.send(JSON.stringify(setupMessage));

    window.addEventListener("resize", this.resizeVideo.bind(this));

    this.remoteVideo.addEventListener(
      "playing",
      this.onVideoStartPlaying.bind(this)
    );

    this.remoteVideo.addEventListener(
      "loadedmetadata",
      this.onVideoLoadMetadata.bind(this)
    );
    this.remoteVideo.addEventListener("error", this.onVideoError.bind(this));

    this.peerConnection = new RTCPeerConnection({
        iceServers: [
          {      
            urls: "stun:stun.l.google.com:19302",
          },
          {
            urls: "stun:stun1.l.google.com:19302",
          },
          {
            urls: "stun:stun2.l.google.com:19302",
          },
          {
            urls: "stun:stun3.l.google.com:19302",
          },
          {
            urls: "stun:stunserver.org:3478",
          },
        ],
      });

    // Add a transceiver to indicate you want to receive video.
    this.peerConnection.addTransceiver("video", { direction: "recvonly" });
    this.peerConnection.addTransceiver("audio", { direction: "recvonly" });

    this.peerConnection.onicecandidate = this.onIceCandidate.bind(this);
    this.peerConnection.oniceconnectionstatechange =
      this.onIceConnectionStateChanged.bind(this);
    this.peerConnection.onicegatheringstatechange =
      this.onIceGatheringStateChange.bind(this);
    this.peerConnection.ontrack = this.onRemoteTrackAdded.bind(this);
    this.peerConnection.ondatachannel = this.onDataChannel.bind(this);

    if (this.perfCanvas) {
      this.perf = new Perf(
        this.sessionID,
        this.perfCanvas,
        this.peerConnection,
        false,
      );
      this.perf.init();
    }

    this.tele = new AntRTCTelemetry(
      this.sessionID,
      this.peerConnection,
      this.remoteVideo,
      this.sendWebSocketMessage.bind(this),
      'v2',
      10000,
      this.gameId,
      this.serverIP
    );
    this.tele.start();

    this.isRunning = true;
    this.update();

    return this.peerConnection
      .createOffer(this.constraints)
      .then(this.onCreateOfferSuccess.bind(this))
      .catch((error) => {
        this.onError("[WEBRTC] failed to create offer: ", error.toString());
      });
  }

  onWebSocketConnectionError(ws, ev) {
    console.warn("[WEBRTC] WebSocket connection error: ", ev);
    // (imayo) Don't enable this until we don't need backwards compatibility support
    // this.onError("Failed connecting to gamesession: " + ev.toString());
  }

  onWebSocketMessage(message) {

    const messageData = JSON.parse(message.data);
    // console.log("[WEBSOCKET] Rcv: ", messageData);

    if (messageData.msgType === "nativeProtocol") {
        this.parseNativeProtocolMessage(messageData.data);
    }
    else if (messageData.msgType === "avsServerStatsReport") {
        if(this.tele) {
            this.tele.setLastServerReport(messageData.data);
        }       
    } else if (messageData.msgType === "offer") {
      var offer = new RTCSessionDescription(messageData.data.sdp);

      this.peerConnection
        .setRemoteDescription(offer)
        .then(() => {
          return this.peerConnection.createAnswer(); // Step 2: Create an answer
        })
        .then((answer) => {
          return this.peerConnection.setLocalDescription(answer); // Step 3: Set the answer as the local description
        })
        .then(() => {
          // Step 4: Send the answer through WebSocket
          this.webSocket.send(
            JSON.stringify({
              msgType: "answer",
              sessionID: this.sessionID,
              data: {
                sdp: {
                  type: this.peerConnection.localDescription.type,
                  sdp: this.peerConnection.localDescription.sdp,
                },
              },
            })
          );
        })
        .catch((error) => {
          console.error("Failed during offer processing: ", error);
        });
    } else if (messageData.msgType === "answer") {
      var answer = new RTCSessionDescription(messageData.data.sdp);

      this.peerConnection.setRemoteDescription(answer).catch(function (error) {
        console.error("Failed to set remote description: ", error);
      });
    } else if (messageData.msgType === "candidate") {
      var candidate = new RTCIceCandidate(messageData.data.candidate);

      this.peerConnection.addIceCandidate(candidate).catch(function (error) {
        console.error("Failed to add received ICE candidate: ", error);
      });
    } else if (messageData.msgType === "serverHello") {
        const avsVersion = messageData["avsServerVersion"];
        const clientVersion = messageData["clientVersion"];
        const versionString = "[avs v" + avsVersion + "][" + clientVersion + "]";
        if(this.debugLogs) {
            console.info("[WEBRTC] AVS Server version: ", versionString);
        }
        else {
            console.info("Server: ", avsVersion, clientVersion);
        }
        
        if (this.tele) {
            this.tele.setAVSVersion(avsVersion);
        }
    } else {
        console.warn("[WEBRTC] message not processed: ", messageData);
    }
  }

  disableInput = (disable) => {
    this.disableInputs = disable;
  };

  clampPadTriggerValue = (value) => {
    value = Math.max(0.0, Math.min(value, 1.0));
    return (value * 255) | 0;
  };

  clampPadAxisValue = (value, flip) => {
    value = Math.max(-1.0, Math.min(value, 1.0));
    value *= flip ? -1 : 1;
    value *= value < 0 ? 32768 : 32767;
    return value | 0;
  };

  scangamepads = () => {
    let gamepads = [];

    if (navigator.getGamepads) {
      gamepads = navigator.getGamepads();
    }

    if (navigator.webkitGetGamepads) {
      gamepads = navigator.webkitGetGamepads();
    }

    for (let i = 0; i < gamepads.length; i++) {
      if (gamepads[i]) {
        this.controllers[i] = gamepads[i];
      }
    }
  };

  pollControlPads = () => {
    this.isGamepadStartPressedPrev = this.isGamepadStartPressed;
    this.isGamepadStartPressed = false;

    // we don't listen to events
    // if the game is paused
    if (this.isPaused) {
      return;
    }

    if (this.oskVisible) {
      return;
    }

    var padId = 0;

    this.controllers.forEach((gamepad) => {
        
        if(!gamepad || this.lastGamepadsTimestamps[gamepad.index] === gamepad.timestamp) {
            return;
        }

        this.lastGamepadsTimestamps[gamepad.index] = gamepad.timestamp;

        this.controlPadStates[padId] = 0;
        for (var i = 0; i <= 15; i++) {
            if (gamepad.buttons[i].pressed) {
            this.controlPadStates[padId] |= ControlPadMapping[i];
            }
        }

        this.isGamepadStartPressed |= gamepad.buttons[9].pressed;

        var leftTrigger = 0;
        var rightTrigger = 0;
        var leftThumbX = 0;
        var leftThumbY = 0;
        var rightThumbX = 0;
        var rightThumbY = 0;

        if (gamepad.buttons[6].pressed) leftTrigger += 1;
        if (gamepad.buttons[7].pressed) rightTrigger += 1;

        if (gamepad.axes[0]) leftThumbX = gamepad.axes[0];
        if (gamepad.axes[1]) leftThumbY = gamepad.axes[1];
        if (gamepad.axes[2]) rightThumbX = gamepad.axes[2];
        if (gamepad.axes[3]) rightThumbY = gamepad.axes[3];

        // Assign player number to PadID if a button has been pressed.
        if (
            (this.controlPadStates[padId] !== 0 ||
            leftThumbX !== 0 ||
            leftThumbY !== 0 ||
            rightThumbX !== 0 ||
            rightThumbY !== 0) &&
            this.playerPads[padId] === undefined
        ) {
            if (this.multiplayerClient) {
            this.playerPads[padId] = 1;
            } else {
            this.playerPads[padId] = this.playerPadsCount++;
            }
        }

        if (this.playerPads[padId] !== undefined) {

            let playerId = this.playerPads[padId];

            /**
             * fixes an issue when a browser detects some fake gamepads and orders them with higher priority then true gamepad
             * these fake unknown gamepads have Gamepad.mapping === ''
             * In this case we should:
             *    ignore fake/unknown gamepads
             *    increase Gamepad.index of true gamepads to 0 or 1 in order to send them as player1 or player2 to Game Server
             * */
            if (this.controllers.length > 1) {
                if (!gamepad.mapping) {
                    // if unknown gamepad
                    padId++;
                    return;
                }

                this.controllers.forEach((currentGamepad) => {
                    if(!currentGamepad) {
                        return;
                    }
                    // if fake/unknown gamepad and it has higher index than the real gamepad
                    if (!currentGamepad.mapping && currentGamepad.index < gamepad.index) {
                    playerId = playerId - 1;
                    }
                });
            }

            const clamPadTriggerValue = {
                left: this.clampPadTriggerValue(leftTrigger),
                right: this.clampPadTriggerValue(rightTrigger),
            };

            const clampPadAxisValue = {
                left: {
                    x: this.clampPadAxisValue(leftThumbX),
                    y: this.clampPadAxisValue(leftThumbY, true),
                },
                right: {
                    x: this.clampPadAxisValue(rightThumbX),
                    y: this.clampPadAxisValue(rightThumbY, true),
                },
            };

            if(this.backButtonIsCoin === true) {

                if(this.controlPadStates[padId] & XINPUT_BUTTON.XINPUT_BUTTON_BACK) {
                    if(!this.bCoinButtonPressed) {
                        if (this.network) {
                            this.network.sendSpecialButtonInput(0, 0x01, 1);
                        }           
                        this.bCoinButtonPressed = true;
                    }
                }
                else {
                    if(this.bCoinButtonPressed) {
                        if (this.network) {
                            this.network.sendSpecialButtonInput(0, 0x01, 0);
                        }            
                        this.bCoinButtonPressed = false;
                    }
                }

                this.controlPadStates[padId] = this.controlPadStates[padId] & (~XINPUT_BUTTON.XINPUT_BUTTON_BACK);
            }

            if(this.bVirtualKeybEnabled) {
                if(this.controlPadStates[padId] & XINPUT_BUTTON.XINPUT_BUTTON_RIGHT_THUMB) {
                    this.controlPadStates[padId] = this.controlPadStates[padId] & (~XINPUT_BUTTON.XINPUT_BUTTON_RIGHT_THUMB);
                }

            }
        
            if (this.network) {
                this.network.sendContollerInputs(
                    playerId,
                    this.controlPadStates[padId],
                    clamPadTriggerValue,
                    clampPadAxisValue
                    );
            }
        }

        padId++;
    });

    if (!this.isGamepadStartPressedPrev && this.isGamepadStartPressed) {
    this.isGamepadStartPressedCounter = 0;
    }
    if (this.isGamepadStartPressed) {
    this.isGamepadStartPressedCounter++;
    }
    const startHeldMaxFrameCount = 60;
    if (
    this.isGamepadStartPressed &&
    this.isGamepadStartPressedCounter > startHeldMaxFrameCount
    ) {
    this.callUIPause();
    }

  };

  onError = (message, errorObject) => {
    console.error(message, errorObject);

    if (this.onErrorCb) {
      this.onErrorCb(message, errorObject);
    }
  };

  getIsPaused = () => {
    return this.isPaused;
  }

  togglePause = () => {
    if(this.isPaused) {
        this.unpause();
    }
    else {
        this.pause();
    }
  }

  pause = () => {
    
    if (this.network) {
        this.isPaused = true;
        this.network.sendPause(this.isPaused);
    }
   
  };

  unpause = () => {
    
    if (this.network) {
        this.isPaused = false;
        this.network.sendPause(this.isPaused);
    }

  };

  setOSKVisible = (value) => {
    this.oskVisible = value;
  };

  startGame = () => {
    console.log("[AntRTC] startGame");
    this.network.sendSpecialButtonInput(0, 0x00, 1);
    // we need to wait a few frames before sending the key up
    setTimeout(() => {
      this.network.sendSpecialButtonInput(0, 0x00, 0);
    }, 100);
  };

  onPerfInterval = () => {
    // console.log("[AntRTC] onPerfInterval");
    if (this.perf) {
      this.perf.updateStats();
    }
  };

  parseInputSetupMessage = (data) => {
    if (data.type === PacketTypeString.CG_PACKET_INPUT_SETUP_RAW) {
        if(data.flags & InputSetupRawFlags.BACK_BUTTON_IS_COIN) {
            this.backButtonIsCoin = true;
        }
        else {
            this.backButtonIsCoin = false;
        }
    }
    else {
        console.warn("This is not a {PacketTypeString.CG_PACKET_INPUT_SETUP_RAW} message");
    }

  };

  parseNativeProtocolMessage = (message) => {
    switch (message.type) {
        case PacketTypeString.CG_PACKET_VIDEO_SETUP:
            this.videoSize = message;
            this.onVideoSetup();
            this.resizeVideo();
            this.tryUnmute();
            this.renderPostProcessedVideo();
            break;

        case PacketTypeString.CG_PACKET_PING_REPLY:
            let ping = 0;
            if (this.network) {
                ping = this.network.getPingDelay(window.performance.now());
            }

            if (this.perf) {
                this.perf.reportPing(ping);
            }

            if (this.tele) {
                this.tele.reportPing(ping);
            }

            break;

        case PacketTypeString.CG_PACKET_PAUSE_CONFIRM:
            this.isPaused = message.paused > 0 ? true : false;
            break;
        case PacketTypeString.CG_PACKET_DATA_DISPLAY_SETUP:
            // TODO. Contain HUD setup values
            this.onInGameEvent(message);
            break;
        case PacketTypeString.CG_PACKET_SAVE_RESPONSE:
            this.onSaveResponse(message);
            break;
        case PacketTypeString.CG_PACKET_INPUT_SETUP_RAW:
            if (this.initiallyPaused && !this.initialPauseSent) {
                console.warn('[WEBRTC] Pausing game is it was requested initially paused');
                this.pause();
                this.initialPauseSent = true;
              } else {
                this.unpause();
              }
              this.onVideoSetup();
              this.tryUnmute();
              this.network.sendLocalPlayerSlot(0, 0);
              this.onInGameEvent(message);
              this.parseInputSetupMessage(message);
              if(!this.controls) {
                this.controls = new Controls(
                  this.controlsCanvas,
                  this.network,
                  this.gameProfile,
                  message,
                  this.fixedJoysticks,
                  this.videoOutputTarget
                  );
                  this.controls.init();
              }
              break;
        default:
            // Got an event from Lua.
            this.onInGameEvent(message);
            break;
      }
  }

  onDataChannel = (event) => {

    this.dataChannel = event.channel;
    this.network = new AntRTCNetwork(
      this.dataChannel,
      this.webSocket,
      this.sessionStart,
      this.controlsString
    );

    this.dataChannel.onopen = (error) => {
        if(this.debugLogs) {
            console.log("Data channel is now open.");
        }
    
        this.messageSendTime = performance.now();
        this.network.sendPacketID();
        this.network.sendControlsString();

        if (this.perfCanvas && !this.perfInterval) {
            this.perfInterval = setInterval(this.onPerfInterval.bind(this), 2000);
        }
    };

    this.dataChannel.onerror = (error) => {
      this.onError("Data channel error", error);
    };

    this.dataChannel.onmessage = (e) => {
      var obj = null;
      try {

        obj = JSON.parse(e.data);
        if (!obj) {
            console.warn("DataChannel message not processed: ", obj);
            return;
          }

          if(obj.msgType === "nativeProtocol") {
            this.parseNativeProtocolMessage(obj.data);
          }
          else {
            this.parseNativeProtocolMessage(obj);
          }
          

      } catch (error) {
        this.onError("Could not parse JSON event from server", error);
      }
     
    };
  };

  onIceCandidate = (iceEvent) => {
    const message = {
      msgType: "candidate",
      sessionID: this.sessionID,
      data: {
        candidate: iceEvent.candidate,
      },
    };

    if (this.webSocket) {
      this.webSocket.send(JSON.stringify(message));
    }
  };

  onIceConnectionStateChanged = (evt) => {
    if (this.peerConnection && this.peerConnection.iceConnectionState) {
        if(this.debugLogs) {
            console.info(
                "[WEBRTC] ICE connection state:",
                this.peerConnection.iceConnectionState
              );
        }
        if (this.peerConnection.iceConnectionState === "connected") {
            this.mediaConnected = true;
        } else if (this.peerConnection.iceConnectionState === "disconnected") {
            this.onErrorCb(
                "Game stream has disconnected",
                this.peerConnection.iceConnectionState
                );
            }
    }
  };

  onIceGatheringStateChange = () => {
    if (this.peerConnection && this.peerConnection.iceGatheringState) {
        if(this.debugLogs) {
            console.info(
                "[WEBRTC] ICE gathering state: " + this.peerConnection.iceGatheringState
              );
        }
    }
  };

  addKeyListeners = () => {
    document.addEventListener("keyup", this.keyUpListener);
    document.addEventListener("keydown", this.keyDownListener);
  };

  removeKeyListeners = () => {
    document.removeEventListener("keyup", this.keyUpListener);
    document.removeEventListener("keydown", this.keyDownListener);
  };

  keyUpListener = (event) => {
    event.preventDefault();

    if (this.network) {
      this.network.sendRawKeyInput(event.code, 0);
      if (event.key === "Shift") {
        if (event.code === "ShiftRight") {
          //(imayo) Send shift up twice to avoid pinball flippers to get stuck up
          this.network.sendRawKeyInput("ShiftLeft", 0);
        } else if (event.code === "ShiftLeft") {
          //(imayo) Send shift up twice to avoid pinball flippers to get stuck up
          this.network.sendRawKeyInput("ShiftRight", 0);
        }
      }
    }

    if (
      this.postprocessLatencyDebugging &&
      this.postprocessing &&
      event.code == "F8"
    ) {
      this.videoScalingMode = (this.videoScalingMode + 1) % 3;
      if (this.videoScalingMode == 0)
        console.log("SCALING: SUPER SAMPLING: " + this.videoScalingMode);
      if (this.videoScalingMode == 1)
        console.log("SCALING: PIXEL SCALING: " + this.videoScalingMode);
      if (this.videoScalingMode == 2)
        console.log("SCALING: DEFAULT WebRTC: " + this.videoScalingMode);
    }
  };

  keyDownListener = (event) => {
    event.preventDefault();

    // if(event.repeat === true) {
    //   return;
    // }

    if (!this.disableInputs && this.network) {
      this.network.sendRawKeyInput(event.code, 1);
    }

    switch (event.code) {
      case "Escape":
        this.onEscapeKeyPressed();
        break;
      case "Tab":
        this.onTabKeyPressed();
        break;
      case "F2":
        this.multiplayerClient = true;
        break;
      default:
    }
  };

  update = () => {
    // console.log("[WEBRTC] update:");

    if (!this.disableInputs) {
      this.scangamepads();
      this.pollControlPads(true);
    }

    if (this.isRunning) {
      window.requestAnimationFrame(this.update.bind(this));
    }
  };

  onRemoteTrackAdded = (evt) => {
    if (evt.streams === undefined || evt.streams.length === 0) {
      console.warn("WebRTC onRemoteTrackAdded: no streams");
      return;
    }

    if (evt.track.kind === "video") {
      if (this.remoteVideo.srcObject !== evt.streams[0]) {
        // this.readableStream = evt.track.readable; // get ReadableStream of encoded frames
        // decodeAndRender(readableStream);
        this.remoteVideo.srcObject = evt.streams[0];
        this.gotVideoTrack = true;
      }
    } else if (evt.track.kind === "audio") {
      this.remoteAudio.srcObject = evt.streams[0];
      this.gotAudioTrack = true;
    } else {
      console.log(`[ERROR] No track added: `, evt);
    }

    if (this.gotVideoTrack && this.gotAudioTrack) {
      this.onVideoSetup();
      this.resizeVideo();
    }
  };

  onVideoStartPlaying = () => {
    if (this.remoteVideo) {
        console.log(
            "Video size: " +
                this.remoteVideo.videoWidth +
                " x " +
                this.remoteVideo.videoHeight
            );
        this.resizeVideo();
    }
  };

  onVideoLoadMetadata = (event) => {
    // console.log("[WEBRTC] onVideoLoadMetadata: ", event);
  };

  onVideoError = (event) => {
    console.error("[WEBRTC] onVideoError: ", event);
  };

  resizeVideo = () => {
    const cw = window.innerWidth;
    const ch = this.oskVisible
      ? window.innerHeight - oskHeight
      : window.innerHeight;
    const ct = 0;
    const cl = 0;

    if (this.videoSize != null) {
      var ow = this.remoteVideo.videoWidth;
      var oh = this.remoteVideo.videoHeight;

      var aspect = this.videoSize.aspect;
      if (aspect < 0) aspect = ow / oh;

      var vw, vh;
      if (ch * aspect < cw) {
        vw = Math.round(ch * aspect);
        vh = ch;
      } else {
        vw = cw;
        vh = Math.round(cw / aspect);
      }

      // If postprocessing is enabled ensure canvas is resized.
      if (this.postprocessing) {
        if (this.videoPostprocessingCanvas.width != vw) {
          this.videoPostprocessingCanvas.width = vw;
        }
        if (this.videoPostprocessingCanvas.height != vh) {
          this.videoPostprocessingCanvas.height = vh;
        }
      }

      if (this.videoOutputTarget.style.width != vw + "px") {
        this.videoOutputTarget.style.width = vw + "px";
      }
      if (this.videoOutputTarget.style.height != vh + "px") {
        this.videoOutputTarget.style.height = vh + "px";
      }

      this.videoOutputTarget.style.marginTop = +(ch / 2 - vh / 2 - ct) + "px";
      this.videoOutputTarget.style.marginLeft = +(cw / 2 - vw / 2 - cl) + "px";
    } else {
      this.videoOutputTarget.style.width = "0px";
      this.videoOutputTarget.style.height = "0px";
    }

    if (
      this.postprocessing &&
      !this.postprocessLatencyDebugging &&
      this.remoteVideo
    ) {
      this.remoteVideo.style.height = "0px";
      this.remoteVideo.style.width = "0px";
    }
  };

  renderPostProcessedVideo = () => {
    if (!this.postprocessing) {
      return;
    }

    if (this.remoteVideo && this.remoteVideo.videoWidth != 0) {
      const vh = this.remoteVideo.videoHeight;

      if (this.postprocessLatencyDebugging) {
        this.remoteVideo.style.height = this.remoteVideo.videoHeight + "px";
        this.remoteVideo.style.width = this.remoteVideo.videoWidth + "px";
      }

      // Ensure super-sampling canvas is not too large
      const maxSuperSamplingHeight = 1080;
      var superSampling = 4;
      while (vh * superSampling > maxSuperSamplingHeight) {
        superSampling /= 2;
      }

      const ctxPostProcessedVideo =
        this.videoPostprocessingCanvas.getContext("2d");

      if (this.videoScalingMode == 0) {
        const ssc = this.videoSuperSamplingCanvas;
        if (ssc.width != this.remoteVideo.videoWidth * superSampling) {
          ssc.width = this.remoteVideo.videoWidth * superSampling;
          this.videoPostprocessingCanvas.width = ssc.width;
          console.log("[WBRTC] New Super Sampling canvas width: " + ssc.width);
        }

        if (ssc.height != this.remoteVideo.videoHeight * superSampling) {
          ssc.height = this.remoteVideo.videoHeight * superSampling;
          this.videoPostprocessingCanvas.height = ssc.height;
          console.log("[WBRTC] New Super Sampling canvas width: " + ssc.height);
        }

        const ctxSuperSampling = this.videoSuperSamplingCanvas.getContext("2d");

        // // Set the fill style to green
        // ctxPostProcessedVideo.fillStyle = 'green';
        // // Create a green rectangle that covers the entire canvas
        // ctxPostProcessedVideo.fillRect(0, 0, this.videoPostprocessingCanvas.width, this.videoPostprocessingCanvas.height);

        ctxSuperSampling.imageSmoothingEnabled = false;
        ctxSuperSampling.drawImage(
          this.remoteVideo,
          0,
          0,
          ssc.width,
          ssc.height
        );

        ctxPostProcessedVideo.imageSmoothingEnabled = true;
        ctxPostProcessedVideo.drawImage(
          this.videoSuperSamplingCanvas,
          0,
          0,
          this.videoPostprocessingCanvas.width,
          this.videoPostprocessingCanvas.height
        );
      } else if (this.videoScalingMode == 1) {
        ctxPostProcessedVideo.imageSmoothingEnabled = false;
        ctxPostProcessedVideo.drawImage(
          this.remoteVideo,
          0,
          0,
          this.videoPostprocessingCanvas.width,
          this.videoPostprocessingCanvas.height
        );
      } else if (this.videoScalingMode == 2) {
        ctxPostProcessedVideo.imageSmoothingEnabled = true;
        ctxPostProcessedVideo.drawImage(
          this.remoteVideo,
          0,
          0,
          this.videoPostprocessingCanvas.width,
          this.videoPostprocessingCanvas.height
        );
      }
    } else {
      console.warn("Post processing could not be done!");
    }

    this.remoteVideo.requestVideoFrameCallback(this.renderPostProcessedVideo);
  };

  setFixedJoysticks = (flag) => {
    this.fixedJoysticks = flag;

    if (this.controls) {
      this.controls.setFixedJoysticks(flag);
    }
  };

  tryUnmute = () => {
    this.unmuteEnabled = true;
    this.mute(false);
  };
}
