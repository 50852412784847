import React, { Component } from 'react';
import PopupAnimation from './popup-animation/popup-animation.component';
import inputManager from '../../assets/lib/inputmanager';
import AudioManager, {TYPE_NOTIFICATION} from '../../app.audio';
import './popup.component.less';
import {getLoggedUser, getWebviewState} from '../../app.selectors';
import { connect } from 'react-redux';
import {ROUTES} from '../../app.router';
import {PopupVisibilityAction} from "./popup.actions";
import {LatestNotificationAction} from "../notifications/notifications.actions";
import {isXboxUser} from "../../assets/lib/utils";
import deviceInfo from "../../assets/lib/deviceInfo";
import { GAME_STATE } from '../../constants';
const navLayer=2;

export const TYPE_QUIT = 'quit';
export const TYPE_REWARD = 'reward';
export const TYPE_WS_ERROR = 'ws_error'; // Websocket error

let addPopup = null;
let closePopup = null;
let clearPopupList = null;

class PopupComponent extends Component {
	popupList = []

	constructor(props) {
		super(props);

		this.prevLayerIndex = -1;
		this.animationRef = null;

		this.state = {
			selectedPopup:null,
		};

		addPopup = this.addNewPopup;
		closePopup = this.closePopup;
		clearPopupList = this.clearPopupList;
	}

	componentDidUpdate () {
		if(this.props.isWebViewVisible && !this.state.selectedPopup && this.popupList.length>0)this.setSelectedPopup();
	}

	clearPopupList = () => {
		this.popupList = [];
	}

	showPopupOverlay = () => {
		const {dispatch} = this.props;
		if(inputManager.layerIndex !== navLayer) {
			dispatch(PopupVisibilityAction(false))
			this.prevLayerIndex = inputManager.layerIndex;
			inputManager.setLayer(navLayer);
			// document.getElementById('dialog-overlay').classList.add('active');
			document.getElementById('app-main-container').classList.add('active');
		}
	};

	hidePopupOverlay = () => {
		inputManager.setLayer(this.prevLayerIndex);
		// document.getElementById('dialog-overlay').classList.remove('active');
		document.getElementById('app-main-container').classList.remove('active');
	};

	// check if a popup of the specified type
	// is currently opened or in the popupList
	hasPopupType = (type) => {
		const selectedPopup = this.state.selectedPopup;
		if (selectedPopup && selectedPopup.props.type === type) {
			return true;
		}

		const isInsidePopupList = this.popupList.find((popup) => {
			return popup.props.type === type;
		});

		if (isInsidePopupList) {
			return true;
		}

		return false;
	}

	addNewPopup = (element, props={}, force = false) => {
		const { type } = props;

		// we don't stack popups of TYPE_QUIT
		if (type === TYPE_QUIT && this.hasPopupType(TYPE_QUIT)) {
			return;
		}

		// we don't stack popups of TYPE_WS_ERROR
		if (type === TYPE_WS_ERROR && this.hasPopupType(TYPE_WS_ERROR)) {
			return;
		}

		// do not show popup if user is logged out
		// after user logs out the access token became invalid
		// all requests that are in pending state will fail
		if (!this.props.isUserLoggedIn && !force) {
			return;
		}

		// do not show pop-ups if websocket connection is down
		if (!deviceInfo.isRunningOnNativeClientV2() && !this.props.isOnline && !force) {
			return;
		}

		const listItem = {
			props,
			element,
		};
		this.popupList.push(listItem);
		this.forceUpdate();
	};

	setSelectedPopup = () => {
		if (this.state.selectedPopup) return;

		let nextPopup=null;
		if (this.props.isInternalPopupsMode) {
			// in this mode we show only popups that don't have doNotShowInInternalPopupsOnlyMode prop
			// the popups with 'doNotShowInInternalPopupsOnlyMode: true' will be shown later
			const internalPopupIndex = this.popupList.findIndex(p => p.props.doNotShowInInternalPopupsOnlyMode !== true);
			if (internalPopupIndex !== -1) {
				nextPopup = this.popupList.find((p, i) => i === internalPopupIndex);
				this.popupList.splice(internalPopupIndex,1);
			}
		} else if(this.props.ignoreReward && this.popupList[0].props.type===TYPE_REWARD) {
			nextPopup = this.popupList.find((item)=>item.props.type!==TYPE_REWARD);
			const index = this.popupList.indexOf(nextPopup);
			if(index>-1) this.popupList.splice(index,1);
		} else {
			nextPopup = this.popupList.shift();
		}

		if(nextPopup) {
			if (this.animationRef) this.animationRef.startShowAnimation();
			this.showPopupOverlay();
			AudioManager.playAudio(TYPE_NOTIFICATION);
			this.setState({
				selectedPopup: nextPopup,
				hiding: false,
			});
		}
	};

	closePopup = () => {
        // Only close the popup if it's actually open
		if (this.animationRef && this.state && this.state.selectedPopup) {
            this.animationRef.startHideAnimation();
        }
	};

	onHideAnimationEnd = () => {
		const {dispatch} = this.props;
		let hideOverlay = true;
		if(this.popupList.length > 0) {
			// close popup if reward popups ignored and there is not any popup rather than Reward Popup
			hideOverlay = this.props.ignoreReward && !this.popupList.some((item) => item.props.type !== TYPE_REWARD);
		}

		if(hideOverlay) {
			dispatch(PopupVisibilityAction(true))
			dispatch(LatestNotificationAction(true));
			this.hidePopupOverlay();
		}

		this.setState({
			selectedPopup:null
		});
	};

	onShowAnimationEnd = () => {};

	renderContent = () => {
		return (
			<div className="popup-component">
				{this.state.selectedPopup.element}
			</div>
		);
	};

	render() {
		return (
			<PopupAnimation
				isXboxPlayer={this.props.isXboxPlayer || deviceInfo.isPlayStationPlatform()}
				onHideAnimationEnd={this.onHideAnimationEnd}
				onShowAnimationEnd={this.onShowAnimationEnd}
				ref = {element => this.animationRef = element}
			>
				{this.state.selectedPopup?this.renderContent():null}
			</PopupAnimation>
		);
	}
}

const mapStateToProps = (state) => {
	const ignoreReward = state.routing.currentRoute.path===ROUTES.IN_GAME_MENU.path
		|| state.routing.currentRoute.path===ROUTES.IN_GAME_HUD.path
		|| state.routing.currentRoute.path===ROUTES.RESULTS_WAITING.path
		|| state.routing.currentRoute.path===ROUTES.HOW_TO_PLAY.path
		|| state.routing.currentRoute.path===ROUTES.HOW_TO_PLAY_GAMEPAD.path
		|| state.routing.currentRoute.path===ROUTES.HOW_TO_PLAY_KEYBOARD.path
		|| state.routing.currentRoute.path===ROUTES.HOW_TO_PLAY_INSTRUCTIONS.path
		|| state.routing.currentRoute.path===ROUTES.HOW_TO_PLAY_FIRETV.path
		|| state.routing.currentRoute.path===ROUTES.HOW_TO_PLAY_REMOTE.path
		|| state.routing.currentRoute.path===ROUTES.HOW_TO_PLAY_TOUCH.path;
	const isWebViewVisible =  getWebviewState(state).visible;
	const user = getLoggedUser(state);
	const isXboxPlayer = isXboxUser(state);
	const isInternalPopupsMode = state.gameDetails.gameState === GAME_STATE.LOADING
		|| state.gameDetails.gameState === GAME_STATE.READY
		|| state.gameDetails.gameState === GAME_STATE.ERROR
		|| state.gameDetails.gameState === GAME_STATE.PAUSED
		|| state.gameDetails.gameState === GAME_STATE.PLAYING;

	return {
		isXboxPlayer,
		isOnline: user && user.isOnline,
		isUserLoggedIn: state.login.logged,
		isInternalPopupsMode,
		isWebViewVisible,
		ignoreReward,
	};
};

export { addPopup, closePopup, clearPopupList };
export default connect(mapStateToProps)(PopupComponent);
