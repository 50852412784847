import React,{Component} from 'react';
import './recommendation-item.component.less';
import inputmanager from '../../assets/lib/inputmanager';

class RecommendationItem extends Component {
	constructor(props) {
		super(props);
		const { selected } = this.props;

		this.state = {
			selected,
		};
	}

	onCustomFocus = (focus/*, nextElement*/) => {
		this.setState({selected:focus});
	};

	onClick = (event) =>  {
		event.preventDefault();
		if(this.props.onClick) this.props.onClick(this.props.item.text);
	};

	onItemRef = (element) => {
		inputmanager.onChildRef(element, 'recommendation-item-'+this.props.index , this.onCustomFocus, this.props.parentId);
	};

	render() {
		return (
			<div
				className={`recommendation-item-component ${this.state.selected?'selected':''}`}
				onClick={this.onClick}
				ref={this.onItemRef}
			>
				{this.props.item.text.replace("Playstation", "PSX")}
			</div>
		);
	}
}

export default RecommendationItem;
