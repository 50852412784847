import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Leaderboard, LEADERBOARD_TYPES} from '../../leaderboard/leaderboard.component';

import {
	getCurrentGameChallenges,
	getCurrentGameDetails, getCurrentGameDetailsId, getSelectedChallenge, getSelectedChallengeId
} from "../game-details.selector";

import "./game-details-challenge-leaderboard.component.less";
import {
	ClearGameLeaderboardAction,
	FetchGameChallengesAction,
	SetSelectedChallengeAction,
	SetStartChallengeAction
} from "../game-details.actions";
import {navigateToLocation, ROUTES} from "../../../app.router";
import { getAllGameIds } from '../../../entities/entities.selectors';
import { addPopup } from '../../popup/popup.component';
import GenericPopup from '../../popup/generic-popup/generic-popup.component';
import {isLowSpecDevice} from '../../../app.helpers';
import { isFreemiumAppleIosUser, isFreemiumV4UserNotSubscribed, isXboxUser } from '../../../assets/lib/utils';
import deviceInfo from "../../../assets/lib/deviceInfo";
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import { APPLE_IOS_SKU, PREMIUM_POPUP_HEADER_TEXT_1 } from '../../../constants';
import { startNativePayment } from '../../../assets/lib/game-wrapper';


class GameDetailsChallengeLeaderBoardComponent extends Component {
	constructor(props) {
		super(props)
		this.accessForbidden = false;
	}

	componentWillUnmount() {
		this.props.dispatch(ClearGameLeaderboardAction());
	}

	componentDidMount() {
		const {dispatch, selectedChallenge, gameId} = this.props;
		if (!selectedChallenge) {
			dispatch(FetchGameChallengesAction(gameId));
		}
	}

	componentDidUpdate() {
		const {
			allGameIds,
			gameData,
		} = this.props;

		// we use this variable to avoid
		// stacking the "Access denied" popup
		if (this.accessForbidden) {
			return;
		}

		// if the game list is not empty but we still
		// can't find the current game,
		// we assume that the user doesn't have the right permission
		if (allGameIds.length > 0 && !gameData) {
			this.accessForbidden = true;
			addPopup(<GenericPopup
				title="Access denied"
				okButtonLabel="Got it!"
				message="You do not have permission to view this game"
				onOkClicked={() => {
					navigateToLocation(ROUTES.HOMEPAGE);
				}}
			/>);
		}
	}

	onClickChallenge = () => {
		const {isFreemiumV4User, gameId, selectedChallenge, dispatch} = this.props;

		if (this.props.isFreemiumAppleIos) {
			startNativePayment(APPLE_IOS_SKU);
			return;
		}

		if (isFreemiumV4User) {
			addPopup(<SubscriptionComparisonsPopup customClassName={'subscription-comparisons-popup-1300'} leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_1} pageId={'game_details_challenge_leaderboard_1'}/>);
			return;
		}

		dispatch(SetSelectedChallengeAction(selectedChallenge._id));
		dispatch(SetStartChallengeAction(selectedChallenge._id));
		navigateToLocation(ROUTES.GAME_CHALLENGES, {id: gameId , focusElement: selectedChallenge._id});
	};

	render() {
		const {gameData, selectedChallenge, isXboxPlayer} = this.props;
		const isPlayStationPlayer = deviceInfo.isPlayStationPlatform();

		if (!gameData) return null;
		const challengeTitle = selectedChallenge && `${gameData.title } - ${selectedChallenge.title}`
		return (
			<>
				<div className={isLowSpecDevice()?"bg-full-screen-lowspec":"bg-full-screen"}>
					<img src={gameData.featured_image_uri} alt=""/>
				</div>
				<section className="game-details-challenge-leaderboard-component">
					<div className="content-with-bg-full-screen">
						<h1>{challengeTitle}</h1>
						<p className={`description ${(isXboxPlayer || isPlayStationPlayer) ? 'description--75' : ''}`}>
							{selectedChallenge && selectedChallenge.text}
						</p>
						<div className="leaderboard-wrapper">
							<Leaderboard
								challengeData={selectedChallenge}
								onClickPlay={this.onClickChallenge}
								playButtonVisible
								leaderboardType={LEADERBOARD_TYPES.CHALLENGES_LEADERBOARD}
							/>
						</div>
					</div>
				</section>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const gameData = getCurrentGameDetails(state);
	const allGameIds = getAllGameIds(state);
	const challengeId = getSelectedChallengeId(state);
	const gameId = getCurrentGameDetailsId(state);
	const challenges = getCurrentGameChallenges(state);
	const selectedChallenge = getSelectedChallenge(state);
	const isXboxPlayer = isXboxUser(state);

	return {
		isFreemiumAppleIos: isFreemiumAppleIosUser(state),
		isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
		isXboxPlayer,
		allGameIds,
		challenges,
		gameId,
		challengeId,
		selectedChallenge,
		gameData,
	};
};

export const GameDetailsChallengeLeaderBoard = connect(mapStateToProps)(GameDetailsChallengeLeaderBoardComponent);