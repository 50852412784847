import React from 'react';
import { useSelector } from 'react-redux';

import { UserHeader } from '../user-header/user-header.component';
import { Navigation } from '../navigation/navigation.component';

import './header.component.less';
import { getIsNoHeader,getPaymentData,getUserIsSubscribed } from '../../app.selectors';
import {
    isLifeTimeSubscription,
	isFreeTrialSubscription
} from '../../assets/lib/utils';
export const Header = () => {
	const noHeader = useSelector(state => getIsNoHeader(state));
	const isSubscribed = useSelector(state => getUserIsSubscribed(state));
	const paymentData = useSelector(state => getPaymentData(state));
	let logo = "free";

	if (noHeader) return null;

	if (isSubscribed) {
		logo = "premium";
		if (paymentData && isFreeTrialSubscription(paymentData)) {
			logo = "freetrial";
		}
		if (paymentData && isLifeTimeSubscription(paymentData)) {
			logo = "lifetime";
		}
	}

	return (
		<header className={`header ${logo}`} id='header'>
			<Navigation />
			<UserHeader />
		</header>
	);
}
