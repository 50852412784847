import React, { Component } from 'react';
import { setSelectedListIndex, setSelectedTileId } from  '../../../app.actions';
import { connect } from 'react-redux';
import './vertical-list.component.css';
import VerticalListTile from './vertical-list-tile.component';
import inputManager from '../../../assets/lib/inputmanager';

class VerticalList extends Component {
	componentWillUnmount() {
		this.onBlur();
	}

	setSelectedTileIndex(index) {
		if (this.isScrolling) return;
		const tileId = this.props.items[index][this.props.idAttribute];
		this.props.dispatch(setSelectedTileId(tileId));
		this.props.dispatch(setSelectedListIndex(this.props.title));

		this.props.onChange && this.props.onChange(tileId, this.props.title, this);
	}

	onBlur = () => {
		this.props.dispatch(setSelectedTileId(null));
		this.props.dispatch(setSelectedListIndex(null));
	};

	onTileBlur = (tile, nextElement) =>{
		const track = this.refs.track;
		if (!track) return;
		if(nextElement && !track.contains(nextElement)) {
			this.onBlur();
		}
	};
	
	renderItem = (item, index) => {
		const addTileProps = {};
		addTileProps.style = {};
		const key = this.props.title.toString() + item[this.props.idAttribute];
		return (
			<VerticalListTile
				idAttribute={this.props.idAttribute}
				item={item}
				key={key}
				listIndex = {this.props.title}
				onSelect={this.onTileSelect}
				onClick={this.onTileClick}
				onTileBlur={this.onTileBlur}
				parentId={this.props.title}
				index={index}
				renderer={this.props.renderer}
				onListGenerateComplete = {this.onListGenerateComplete}
				{...addTileProps}
			/>
		);
	};

	onTileSelect = (tile, index) => {
		const tileId = tile[this.props.idAttribute];
		this.setSelectedTileIndex(index);
		this.props.onSelect && this.props.onSelect(tileId, this.props.title, this);
	};

	onTileClick = (tile, index) => {
		const tileId = tile[this.props.idAttribute];
		this.props.onTileClick && this.props.onTileClick(tileId);
		this.setSelectedTileIndex(index);
	};

	onPagerRef = (element) => {
		inputManager.onParentRef(element, this.props.title, true, this.props.parentEntrance);
		this.props.onListGenerateComplete && this.props.onListGenerateComplete();
	};

	render() {
		if (!this.props.items.length) return null;
		const addPagerProps = {};
		return (
			<div className={`vertical-list ${this.props.className}`} ref="frame">
				<div className="pager" ref={this.onPagerRef} {...addPagerProps}>
					<div  ref="track" className="track">
						{this.props.items.map((item, index) => this.renderItem(item, index))}
					</div>
				</div>
			</div>
		);
	};
}

const mapStateToProps = () => {
	return {};
};

export {VerticalList};
export default connect(mapStateToProps)(VerticalList);
