import React, { Component } from 'react';
import rankIcon from '../../../assets/images/global/icon-rank.png';
import {AvatarWithCircle} from '../avatar/avatar-with-circle.component';
import PlayerProfileGems from './player-profile-gems.component';
import { withBadWordMask } from "../../../assets/lib/utils";

import './player-profile-header.component.less';

export let getGemIconRef = () => null;

const parentId = 'navBar';
const navLayer = 0;

class PlayerProfileHeaderComponent extends Component {
	constructor() {
		super();

		this.gemIconRef = null;
		getGemIconRef = this.getGemIconRef;
	}

	getGemIconRef = () => {
		return this.gemIconRef;
	}

	onGemIconRef = (element) => {
		this.gemIconRef = element;
	}

	render() {
		const {userData, rank, expPercentage, gems, gemAnimationElement, renderGemValueWithCountAnimation, showVideoPlus, handleVideoPlusClick,  handleStoreIconClicked, isShowStoreIcon, handleShopButtonClick, handlePlayerProfileHeaderClicked, profileComponentRef} = this.props;

		return (
			<div className="player-profile-header-container">
				<div className="left-container">
					<div className="gem-rank-container">
						<PlayerProfileGems 
							gems={gems}
							gemAnimationElement={gemAnimationElement}
							renderGemValueWithCountAnimation={renderGemValueWithCountAnimation}
							showVideoPlus={showVideoPlus}
							handleVideoPlusClick={handleVideoPlusClick}
							handleShopButtonClick={handleShopButtonClick}
							onGemIconRef={this.onGemIconRef}
							parentId={parentId}
							navLayer={navLayer}
							isShowStoreIcon={isShowStoreIcon}
							handleStoreIconClicked={handleStoreIconClicked}
						/>
						<span className="rank-text">{rank}</span>
						<img className="rank-icon" alt="rank-icon"  src={rankIcon} />
					</div>
				</div>
				<AvatarWithCircle
					progressCircleCssClass="player-profile-header"
					selected={this.props.selected}
					expPercentage = {expPercentage}
					userData = {userData}
					expAnimationElement = {this.props.expAnimationElement}
					handleClicked={() => {handlePlayerProfileHeaderClicked && handlePlayerProfileHeaderClicked()}}
					onComponentRef={profileComponentRef}
				/>
				<div className="display-name">
					<div className="display-name-inner">
						{withBadWordMask(userData.displayName)}
					</div>
				</div>
			</div>
		);
	}
}

export { PlayerProfileHeaderComponent };
