import { antstreamAPIService } from '../../app.reducer';
import {getAssetsUrlWithFolder, getIsPostAuthFetched, getUserIsSubscribed} from "../../app.selectors";
import {TOURNAMENT_RESULT_TYPES} from '../popup/tournament-result-popup/tournament-result-popup.compontent';
import {addPopup} from '../popup/popup.component';
import TournamentResultPopup from '../popup/tournament-result-popup/tournament-result-popup.compontent';
import React from 'react';
import GenericPopup from "../popup/generic-popup/generic-popup.component";
import {UpdateChallengesData} from "../../entities/challenges/challenges.actions";
import {getTournamentPopups} from "./tournaments.selector";
import {FOLDER_TYPES} from "../../assets/lib/FolderTypes";
import {readRestUserData} from "../../assets/lib/local-storage";
import {handleNoAuthTokenIssue, isFreeTierV2FeatureFlagEnabled} from "../../assets/lib/utils";
import { PREMIUM_POPUP_HEADER_TEXT_2, TOURNAMENT_STATUS_TYPE } from "../../constants";
import SubscriptionComparisonsPopup from "../popup/subscription-comparisons-popup/subscription-comparisons-popup.component";
import {navigateToLocation, ROUTES} from '../../app.router';
import deviceInfo, { STORE_TYPE_APPLE_IOS } from '../../assets/lib/deviceInfo';

export const appendAssetsUrlToTournaments = (tournaments, assetsUrl) => {
	const appendedTournaments = [];

	tournaments.forEach(tournamentObject => {
		appendedTournaments.push(appendAssetsUrlToTournament(tournamentObject,assetsUrl))
	});

	return appendedTournaments;
};

//needs to be deleted after all old messages are expired in the system
const temporaryHackTheTournamentMessageImagePath = (path) => {
	if(path){
		return path.replace("/drupal-files/tournament-assets/","");
	}
}

export const appendAssetsUrlToTournament = (tournamentObject,assetsUrl) => {
	return	{
		...tournamentObject,
		banner:assetsUrl+tournamentObject.banner,
		smallBanner:assetsUrl+tournamentObject.smallBanner,
		detailImage:assetsUrl+tournamentObject.detailImage,
		messageImage:assetsUrl+temporaryHackTheTournamentMessageImagePath(tournamentObject.messageImage),
	};
};

export const FETCH_TOURNAMENTS_LIST_ACTION = 'FETCH_TOURNAMENTS_LIST_ACTION';
export const FetchTournamentsListAction = (group, onComplete) => {
	return (dispatch, getState) => {
		dispatch({ type: FETCH_TOURNAMENTS_LIST_ACTION});

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('FETCH_TOURNAMENTS_LIST_ACTION');
			const query = { group: group ? group : undefined };
			const params = { headers: { Authorization: 'Bearer ' + authToken } };
			antstreamAPIService.tournaments.byTypeList(query, params)
				.then(({ data }) => {
					if (data.error) throw new Error(data.error);

					let resp = data.data.tournaments;
					resp = appendAssetsUrlToTournaments(resp, getAssetsUrlWithFolder(getState(), FOLDER_TYPES.TOURNAMENTS));
					resp.sort((a, b) => {
						return a.endDate > b.endDate ? -1 : 1;
					});

					dispatch(FetchTournamentsListActionSuccess(resp));
					onComplete();
				})
				.catch(catchErr => {
					addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" message="Failed to get tournaments list. Please check your internet connection and try again. If the problem persists, please contact support."/>);
					console.error('Failed to get tournaments list: ' + catchErr.message);
				});

	};
};

export const FETCH_TOURNAMENTS_LIST_ACTION_SUCCESS = 'FETCH_TOURNAMENTS_LIST_ACTION_SUCCESS';
const FetchTournamentsListActionSuccess = (payload) => ({
	type: FETCH_TOURNAMENTS_LIST_ACTION_SUCCESS, payload
});

export const FETCH_TOURNAMENT_DETAIL_ACTION = 'FETCH_TOURNAMENT_DETAIL_ACTION';
export const FetchTournamentsDetailAction = (tournamentId, showFreemiumPopup = false, autoJoinOnSponsored = false) => {
	return (dispatch, getState) => {
		dispatch({ type: FETCH_TOURNAMENT_DETAIL_ACTION});

			const {authToken} = readRestUserData();
			const query = { tournamentId };
			const params = {headers: {Authorization: 'Bearer ' + authToken}};
			antstreamAPIService.tournament.tournamentList(query, params)
				.then(async ({data}) => {
					if (data.error) throw new Error(data.error);

					const resp = data.data;

					// if sponsored tournament and not joined - join it automatically
					if (autoJoinOnSponsored && resp.tournamentData.sponsored === true && !resp.isPlayerJoined) {
						await new Promise((resolve) => {
							dispatch(AddPlayerToTournamentAction(tournamentId), () => {
								resolve();
							});
						})
					}

					resp.tournamentData = appendAssetsUrlToTournament(resp.tournamentData, getAssetsUrlWithFolder(getState(), FOLDER_TYPES.TOURNAMENTS));
					dispatch(UpdateChallengesData([resp.challengeData]));
					dispatch(FetchTournamentsDetailActionSuccess(resp));

					const {tournamentData, challengeData, leaderBoardObject, rewards} = resp;
					const resultObject = {
						tournamentData,
						challengeObject: challengeData,
						leaderBoardObject,
						rewards,
					};

					if (rewards && rewards.showRewardPopup) {
						resultObject.resultType = TOURNAMENT_RESULT_TYPES.RESULT_RANK;
						addPopup(<TournamentResultPopup
							resultObject={{...resultObject}}
						/>);

						if (rewards.currencies && rewards.currencies["GEMS"]) {
							resultObject.resultType = TOURNAMENT_RESULT_TYPES.RESULT_GEM;
							addPopup(<TournamentResultPopup
								resultObject={{...resultObject}}
							/>);
						}

						if (resultObject.leaderBoardObject.rank <= 3) {
							resultObject.resultType = TOURNAMENT_RESULT_TYPES.RESULT_ACHIEVEMENT;
							addPopup(<TournamentResultPopup
								resultObject={{...resultObject}}
							/>);
						}
					}

					if (showFreemiumPopup) {
						const PAGE_ID = 'tournaments_info_1';
						const isFreeTierUserV2 = isFreeTierV2FeatureFlagEnabled(getState())
							&& getIsPostAuthFetched(getState())
							&& !getUserIsSubscribed(getState());
						const isTournamentStarted = tournamentData
							&& tournamentData.status === TOURNAMENT_STATUS_TYPE.STARTED;

						if (isFreeTierUserV2 && isTournamentStarted && (deviceInfo.storeType !== STORE_TYPE_APPLE_IOS)) {
							addPopup(<SubscriptionComparisonsPopup leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_2} pageId={PAGE_ID} />);
						}
					}

				})
				.catch(catchErr => {
					if (catchErr.message.includes('No tournament data for tournament')) { // if tournament not found - navigate to homepage
						navigateToLocation(ROUTES.HOMEPAGE);
					} else {
						addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" message="Failed to get tournament details. Please check your internet connection and try again. If the problem persists, please contact support."/>);
					}
					console.error('Failed to get tournament details: ' + catchErr.message);
				});

	};
};

export const FETCH_TOURNAMENT_DETAIL_ACTION_SUCCESS = 'FETCH_TOURNAMENT_DETAIL_ACTION_SUCCESS';
const FetchTournamentsDetailActionSuccess = (payload) => ({
	type: FETCH_TOURNAMENT_DETAIL_ACTION_SUCCESS, payload
});

export const RESET_TOURNAMENT_DETAIL_ACTION = 'RESET_TOURNAMENT_DETAIL_ACTION';
export const ResetTournamentsDetailAction = () => ({
	type: RESET_TOURNAMENT_DETAIL_ACTION
});


export const ADD_PLAYER_TO_TOURNAMENT_ACTION = 'ADD_PLAYER_TO_TOURNAMENT_ACTION';
export const AddPlayerToTournamentAction = (tournamentId, onComplete, isPopup) => {
	return (dispatch) => {
		dispatch({ type: ADD_PLAYER_TO_TOURNAMENT_ACTION});

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('ADD_PLAYER_TO_TOURNAMENT_ACTION');
			const headers = { Authorization: 'Bearer ' + authToken };
			const body = { tournamentId, isPopup };

			antstreamAPIService.tournament.participantsCreate({
				headers: headers,
				body: body
			}).then(({ data }) => {
				if (!data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);

				const resp = data.data;
				if (resp.success === false) {
					addPopup(<GenericPopup
						title='Sorry, not enough gems to enter.'
						message='Try some challenges or achievements to earn more.'
					/>);
					dispatch(AddPlayerToTournamentActionFailed());
				} else {
					dispatch(AddPlayerToTournamentActionSuccess(resp));

					dispatch(FetchTournamentsDetailAction(tournamentId))
				}

				if (onComplete) onComplete();
			})
				.catch(execGame => {
					dispatch(AddPlayerToTournamentActionFailed());
					addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"
						message={'Failed to join tournament: ' + execGame.message} />);
					if (onComplete) onComplete();
				});

	};
};

export const ADD_PLAYER_TO_TOURNAMENT_ACTION_SUCCESS = 'ADD_PLAYER_TO_TOURNAMENT_ACTION_SUCCESS';
const AddPlayerToTournamentActionSuccess = (payload) => ({
	type: ADD_PLAYER_TO_TOURNAMENT_ACTION_SUCCESS, payload
});

export const ADD_PLAYER_TO_TOURNAMENT_ACTION_FAILED = 'ADD_PLAYER_TO_TOURNAMENT_ACTION_FAILED';
const AddPlayerToTournamentActionFailed = () => ({
	type: ADD_PLAYER_TO_TOURNAMENT_ACTION_FAILED 
});

const FETCH_TOURNAMENTS_PLAYERS = 'FETCH_TOURNAMENTS_PLAYERS';
export const FetchTournamentsPlayers = (tournamentId, onComplete) => {
	return (dispatch) => {
		dispatch({ type: FETCH_TOURNAMENTS_PLAYERS});

			const {authToken} = readRestUserData();
			const query = {tournamentId: tournamentId};
			const params = {headers: {Authorization: 'Bearer ' + authToken}};
			antstreamAPIService.tournament.participantsList(query, params)
				.then(({data}) => {
					if (data.error) throw new Error(data.error);

					const {players, followedPlayers} = data.data;
					dispatch(FetchTournamentsPlayersSuccess({players, followedPlayers}));
					onComplete();
				})
				.catch(catchErr => {
					addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" message="Failed to get participants list. Please check your internet connection and try again. If the problem persists, please contact support."/>);
					console.error('Failed to get participants list: ' +  catchErr.message);
				});

	};
};

export const FETCH_TOURNAMENTS_PLAYERS_SUCCESS = 'FETCH_TOURNAMENTS_PLAYERS_SUCCESS';
const FetchTournamentsPlayersSuccess = ({players, followedPlayers}) => ({
	type: FETCH_TOURNAMENTS_PLAYERS_SUCCESS,
	players,
	followedPlayers
});

export const FETCH_TOURNAMENTS_HISTORY_SUCCESS = 'FETCH_TOURNAMENTS_HISTORY_SUCCESS';
export const FetchTournamentsHistorySuccess = (tournamentHistory) => {
	return (dispatch,getState) => {
		if(tournamentHistory && tournamentHistory.length){
			tournamentHistory.forEach(item=>{
				item.tournamentObject = appendAssetsUrlToTournament(item.tournamentObject,getAssetsUrlWithFolder(getState(),FOLDER_TYPES.TOURNAMENTS))
			});
		}
		dispatch({
			type: FETCH_TOURNAMENTS_HISTORY_SUCCESS,
			tournamentHistory
		});
	};
};

const SET_IS_TOURNAMENT_INFO_SEEN = 'SET_IS_TOURNAMENT_INFO_SEEN';
export const SetIsTournamentInfoPopupSeen = (tournamentId) => {
	return (dispatch) => {
		dispatch({ type: SET_IS_TOURNAMENT_INFO_SEEN});

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('SET_IS_TOURNAMENT_INFO_SEEN');
			const params = {
				body: { tournament_id: tournamentId },
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.tournament.popupSeenCreate(params)
				.then(({ data }) => {
					if (!data || data.result !== 'OK') {
						throw new Error('Failed to Set Tournament Info Popup Seen');
					}
				})
				.catch(catchErr => {
					// addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" />);
					console.error(catchErr.message);
				});

	};
};


const CHECK_TOURNAMENT_INFO_POPUPS = 'CHECK_TOURNAMENT_INFO_POPUPS';
export const CheckTournamentInfoPopups = () => {
	return (dispatch,getState) => {
		dispatch({ type: CHECK_TOURNAMENT_INFO_POPUPS});
		if (getTournamentPopups(getState()) !== null) return;

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('CHECK_TOURNAMENT_INFO_POPUPS');
			const params = { headers: { Authorization: 'Bearer ' + authToken } };
			antstreamAPIService.tournament.getInfoPopupsList(params).then(({ data }) => {
				if (data.error) throw new Error(data.error);
				dispatch(CheckTournamentInfoPopupsSuccess(data.data));
			})
			.catch(catchErr => {
				// addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
				console.error('Failed to get info popups list: ' +  catchErr.message);
			});

	};
};

export const CHECK_TOURNAMENT_INFO_POPUPS_SUCCESS = 'CHECK_TOURNAMENT_INFO_POPUPS_SUCCESS';
const CheckTournamentInfoPopupsSuccess = ({tournamentPopups}) => {
	return (dispatch,getState) => {
		dispatch({
			type: CHECK_TOURNAMENT_INFO_POPUPS_SUCCESS,
			tournamentPopups
		});

		if(tournamentPopups){
			tournamentPopups.forEach((tournamentData)=>{
				var resultObject = {
					tournamentData:appendAssetsUrlToTournament(tournamentData,getAssetsUrlWithFolder(getState(),FOLDER_TYPES.TOURNAMENTS))
				};
				resultObject.resultType = TOURNAMENT_RESULT_TYPES.HOMEPAGE_INFO ;
				addPopup(<TournamentResultPopup
					resultObject={resultObject}
				/>);
			});
		}
	};
};

export const EXTERNAL_SPONSOR_TOURNAMENT_LINK_USED = 'EXTERNAL_SPONSOR_TOURNAMENT_LINK_USED';
export const ExternalSponsorTournamentLinkUsed = (payload) => ({
	type: EXTERNAL_SPONSOR_TOURNAMENT_LINK_USED, payload
});
