import * as ACTIONS from './game-details.actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { SET_CURRENT_CHALLENGE_ID } from '../challenge/challenge.actions';
import { GAME_STATE } from '../../constants';
import {
    FOLLOW_GAME_ACTION,
    FOLLOW_GAME_ACTION_ERROR,
    FOLLOW_GAME_ACTION_SUCCESS,
    SET_LOAD_GAME_SLOT,
    SET_RETRY_ENABLED,
    SET_SELECTED_GAME_INFO_ACTION,
    SET_SELECTED_GIANT_SLAYER_INFO_ACTION,
    UNFOLLOW_GAME_ACTION,
    UNFOLLOW_GAME_ACTION_ERROR,
    UNFOLLOW_GAME_ACTION_SUCCESS
} from './game-details.actions';
import {LOGOUT_USER_ACTION} from '../login/login.actions';
import {gameNormalizer} from '../../entities/entities.normaliser';
import {SAVE_STATE_ACTION} from './game-details.actions';
import {NATIVE_SAVE_STATE_RESULT} from './game-details.actions';
import {SAVE_REQUESTED_ACTION} from './game-details.actions';
import {SAVE_TIMEOUT_ACTION} from './game-details.actions';
import {FETCH_HOW_TO_PLAY_SUCCESS} from './game-details.actions';
import { paramsToObject } from '../../app.helpers';

const initialState = {
	currentId: null,
	platforms: [],
	challengeHistory: [],
    failedRequests: [],
    cancelledRequests: [],
    similar: [],
    firstUser: [],
    leaderboard: {},
    isLeaderboardUpdated: false,
    savedGameStates: {},
    selectedChallengeId: null,
    selectedFeaturedChallengeId: null,
    challengeOutcome: null,
    challengeOver: false,
    scoreSetup: {},
    scoreValues: {},
    activeMenu: {},
    gameState: GAME_STATE.IDLE,
    isUpvoted: false,
    totalVotes: 0,
    voteGoal: 20,
    selectedGameInfoId: null,
    selectedGiantSlayerInfoId: null,
    saving: false,
    savingSuccessful: false,
    lastSessionObject: null,
    howToPlayImages: null,
    isRetryEnabled: false,
    loadGameSlot: {
        slot: null,
        region: null
    },
    isFollowGamePending: false,
    isGameChallengesHistoryPending: false,
    isFetchLeaderboardPending: false,
    leaderboardListType: null,
    isOskVisible: false,
    connectionStrength: -1,
    showHowToPlay: false
};

export const gameDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FOLLOW_GAME_ACTION:
        case UNFOLLOW_GAME_ACTION: {
            return {
                ...state,
                isFollowGamePending: true
            };
        }
        case UNFOLLOW_GAME_ACTION_ERROR:
        case UNFOLLOW_GAME_ACTION_SUCCESS:
        case FOLLOW_GAME_ACTION_SUCCESS:
        case FOLLOW_GAME_ACTION_ERROR: {
            return {
                ...state,
                isFollowGamePending: false
            };
        }
        case SET_CURRENT_CHALLENGE_ID:
        case LOCATION_CHANGE:
            const query = action.payload.query
                || paramsToObject(action.payload.location.search);
            const { id, challengeId } = query;

            if (id !== state.currentId) {
                return {
                    ...state,
                    currentId: id,
                    selectedChallengeId: challengeId,
                    leaderboard: {},
                    firstUser: [],
                    challenges: []
                };
            }

            return {
                ...state,
                currentId: id
            };

        case ACTIONS.CLEAR_GAME_LEADERBOARD: {
            return {
                ...state,
                firstUser: [],
                leaderboard: {},
            };
        }

        case ACTIONS.FETCH_GAME_PLATFORMS_SUCCESS:
            const normalizedPlatformData = gameNormalizer(action.payload);
            return {
                ...state,
                platforms: [...normalizedPlatformData.result]
            };

        case ACTIONS.FETCH_GAME_CHALLENGES_HISTORY_SUCCESS:
            const history = action.payload.response.challengesHistory || [];
            const scores = action.payload.response.challengeScoresOfUser;
            return {
                ...state,
                isGameChallengesHistoryPending: false,
                challengeHistory: [...history],
                challengeScores: scores
            };

        case ACTIONS.FETCH_GAME_LEADERBOARD_SUCCESS:
            // do not update leaderboard if this response is from different list type
            if (state.leaderboardListType
                && action.payload.listType
                && state.leaderboardListType !== action.payload.listType) {
                return state;
            }

            const leaderboard = typeof action.payload.offset !== 'undefined' ? { ...state.leaderboard } : {};
            action.payload.response.users.forEach(user => {
                leaderboard[user._id] = user;
            });
            return {
                ...state,
                isFetchLeaderboardPending: false,
                firstUser: action.payload.response.firstUser,
                leaderboard
            };

        case ACTIONS.FETCH_GAME_LEADERBOARD_ERROR: {
            return {
                ...state,
                isFetchLeaderboardPending: false
            };
        }

        case ACTIONS.GAME_LEADERBOARD_UPDATED:
            if (action.gameId === state.currentId) {
                return {
                    ...state,
                    isLeaderboardUpdated: true
                };
            }
            return state;

        case ACTIONS.UPDATE_LEADERBOARD_LIST_TYPE: {
            return {
                ...state,
                leaderboardListType: action.payload
            };
        }

        case ACTIONS.FETCH_GAME_LEADERBOARD: {
            const clearPodiumAndList = {};
            if (action.payload.listType !== state.leaderboardListType) {
                clearPodiumAndList.firstUser = [];
                clearPodiumAndList.leaderboard = [];
            }

            return {
                ...state,
                ...clearPodiumAndList,
                leaderboardListType: action.payload.listType,
                isFetchLeaderboardPending: true,
                isLeaderboardUpdated: false
            };
        }

        case ACTIONS.FETCH_SIMILAR_GAMES:
            return {
                ...state,
                similar: []
            };

        case ACTIONS.FETCH_SIMILAR_GAMES_SUCCESS:
            const normalizedData = gameNormalizer(action.payload);
            return {
                ...state,
                similar: [...normalizedData.result]
            };

        case ACTIONS.SET_START_CHALLENGE_ACTION:
            return {
                ...state,
                startChallengeId: action.payload
            };

        case ACTIONS.SET_SELECTED_CHALLENGE_ACTION:
            return {
                ...state,
                selectedChallengeId: action.payload
            };

        case ACTIONS.SET_SELECTED_FEATURED_CHALLENGE_ACTION:
            return {
                ...state,
                selectedFeaturedChallengeId: action.payload
            };

        case ACTIONS.SET_GAME_LOADING:
            return {
                ...state,
                gameState: GAME_STATE.LOADING
            };

        case ACTIONS.SET_GAME_READY:
            return {
                ...state,
                gameState: GAME_STATE.READY
            };

        case ACTIONS.SET_GAME_ERROR:
            return {
                ...state,
                gameState: GAME_STATE.ERROR
            };

        case ACTIONS.PAUSE_GAME_ACTION:
            return {
                ...state,
                gameState: GAME_STATE.PAUSED
            };

        case ACTIONS.UNPAUSE_GAME_ACTION:
            return {
                ...state,
                gameState: GAME_STATE.PLAYING
            };

        case ACTIONS.SET_GAME_QUIT:
            return {
                ...state,
                gameState: GAME_STATE.IDLE,
                challengeOutcome: null
            };

        case ACTIONS.SET_CHALLENGE_OVER:
            return {
                ...state,
                challengeOver: action.payload
            }

        case ACTIONS.SET_SCORE_SETUP:
            return {
                ...state,
                scoreSetup: action.payload
            }

        case ACTIONS.SET_SCORE_VALUES:
            return {
                ...state,
                scoreValues: action.payload
            }

        case ACTIONS.SET_GAME_FINISHED:
            return {
                ...state,
                gameState: GAME_STATE.FINISHED
            };

        case ACTIONS.UPDATE_MY_MENU_SELECTION:
            return {
                ...state,
                activeMenu: action.payload
            };

        case ACTIONS.ADD_UPVOTE_GAME_ACTION_SUCCESS:
            return {
                ...state,
                isUpvoted: true,
                totalVotes: action.payload.totalVotes
            };

        case ACTIONS.REMOVE_UPVOTE_GAME_ACTION_SUCCESS:
            return {
                ...state,
                isUpvoted: false,
                totalVotes: state.totalVotes - 1
            };

        case ACTIONS.GET_UPVOTES_GAME_ACTION_SUCCESS:
            const upvotes = action.upvotes.upvotes;
            return {
                ...state,
                isUpvoted: upvotes && upvotes.filter(upvote => upvote.user_uuid === action.userId).length > 0,
                totalVotes: action.upvotes.totalVotes
            };

        case SET_SELECTED_GAME_INFO_ACTION:
            return {
                ...state,
                selectedGameInfoId: action.payload.gameId
            };

        case SET_SELECTED_GIANT_SLAYER_INFO_ACTION:
            return {
                ...state,
                selectedGiantSlayerInfoId: action.payload.giantSlayerChallengeId
            };

        case SAVE_STATE_ACTION:
            const savedGameStateData = action.payload;
            return {
                ...state,
                saving: false,
                savingSuccessful: true,
                savedGameStates: {
                    ...state.savedGameStates,
                    [savedGameStateData.gameId]: savedGameStateData.savedStates
                }
            };

        case SAVE_REQUESTED_ACTION:
            return {
                ...state,
                savingSuccessful: false,
                saving: true
            };

        case NATIVE_SAVE_STATE_RESULT:
            return {
                ...state,
                savingSuccessful: action.payload && action.payload.success === "true",
                saving: false
            };

        case SAVE_TIMEOUT_ACTION:
            return {
                ...state,
                savingSuccessful: false,
                saving: false
            };

        case ACTIONS.CLEAR_LAST_SESSION_OBJECT:
            return {
                ...state,
                lastSessionObject: null
            };

        case ACTIONS.RUN_GAME_ACTION:
            return {
                ...state,
                lastSessionObject: action.payload
            };

        case ACTIONS.FAILED_REQUEST_ACTION:
            return {
                ...state,
                failedRequests: [...state.failedRequests, action.payload.requestId]
            };

        case ACTIONS.CANCELLED_REQUEST_ACTION:
            return {
                ...state,
                cancelledRequests: [...state.cancelledRequests, action.payload.requestId]
            };

        case LOGOUT_USER_ACTION:
            return {
                ...state,
                selectedGameInfoId: null
            };

        case FETCH_HOW_TO_PLAY_SUCCESS:
            return {
                ...state,
                howToPlayImages: { ...action.payload }
            };

        case SET_RETRY_ENABLED:
            return {
                ...state,
                isRetryEnabled: action.enabled
            };

        case SET_LOAD_GAME_SLOT:
            return {
                ...state,
                loadGameSlot: {
                    slot: action.slot,
                    region: action.region
                }
            };

        case ACTIONS.SHOW_HOW_TO_PLAY:
            return {
                ...state,
                showHowToPlay: true
            };

        case ACTIONS.HIDE_HOW_TO_PLAY:
            return {
                ...state,
                showHowToPlay: false
            };

        case ACTIONS.FETCH_GAME_CHALLENGES_HISTORY: {
            return {
                ...state,
                isGameChallengesHistoryPending: true
            };
        }

        case ACTIONS.FETCH_GAME_CHALLENGES_HISTORY_FAIL: {
            return {
                ...state,
                isGameChallengesHistoryPending: false
            };
        }

        case ACTIONS.TOGGLE_OSK_VISIBILITY: {
            return {
                ...state,
                isOskVisible: action.payload
            };
        }

        case ACTIONS.SET_CONNECTION_STRENGTH_INDICATOR_ACTION: {
            return {
                ...state,
                connectionStrength: action.payload
            };
        }

        case ACTIONS.CLEAR_GAME_DETAILS_STATE: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
