import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getOpponents, getCurrentChallenge} from '../challenge.selectors';
import {
	createMultiplayerChallengeHistoryObject,
	SetSelectedChallengeHistory
} from '../challenge.actions';
import './mpc-opponents.component.less';
import {SetTutorialId} from '../../tutorial/tutorial.actions.js';
import {PlayerSearch} from '../../search/player-search.component';
import TileUserOpponent from '../../common/tiles/tile-user-opponent.component';
import {getCurrentGameDetails} from '../../game-details/game-details.selector';
import {getLoggedUser} from '../../../app.selectors';
import {ButtonFacebook} from '../../common/button/common-button-with-facebook-icon.component';
import {shareWithFriendsLinkWithRedirectUri} from '../../../assets/lib/facebook.lib';
import inputmanager from '../../../assets/lib/inputmanager';
import deviceInfo, {PLATFORM_TYPE_ATARI} from '../../../assets/lib/deviceInfo';
import {isXboxUser} from '../../../assets/lib/utils';

const PAGE_ID = 'game_challenges_3';
class MPCOpponentsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vsSummary : null,
			results: []
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(SetTutorialId(PAGE_ID));
	}

	componentDidMount() {
		inputmanager.setCurrentChildById("playerSearchInput");
	}

	selectChallenged = (opponentId) => {
		const {challengeId, opponents, gameId, gameTitle, userId, displayName, profile_image_id, avatarType} = this.props;

		const opponentObj = opponents.find(opponent => opponent._id === opponentId);

		const opponent = {
			_id: opponentObj._id,
			displayName: opponentObj.displayName,
			profile_image_id: opponentObj.profile_image_id,
			avatarType: opponentObj.avatarType,
		};

		const normalizedChallengeHistoryObject = this.props.dispatch(createMultiplayerChallengeHistoryObject(gameId, challengeId, gameTitle, {_id:userId, displayName, profile_image_id, avatarType}, opponent));
		this.props.dispatch(SetSelectedChallengeHistory(normalizedChallengeHistoryObject));
	};

	getShareRedirectUrl = () => {
		const { gameId } = this.props;
		return `/game-details/${gameId}/challenges?id=${gameId}`;
	};

	onFacebookClicked = (redirectUri) => {
		shareWithFriendsLinkWithRedirectUri(redirectUri);
	};

	render() {
		const { challengeTitle, currentChallengeId } = this.props;

		return (
			<section className="pre-challenge" >
				<div className="title-box">
					<h1>{challengeTitle}</h1>
				</div> 
				{!deviceInfo.isWaysunDevice() && !this.props.isXboxUser && deviceInfo.platformType !== PLATFORM_TYPE_ATARI && !deviceInfo.isPlayStationPlatform() && <div className="invite-container">
					Want to challenge friends on facebook. Please share us to spread the retro love!
					<ButtonFacebook
						onClick={()=>this.onFacebookClicked(this.getShareRedirectUrl())}
						childId="facebookButton"
					>
						Share
					</ButtonFacebook>
				</div>}

				<PlayerSearch
					itemRenderer = {(props) => <TileUserOpponent {...props} opponentId={this.state.opponentId} />}
					onItemSelected={this.selectChallenged}
					isChallengeOpponentsSearch
					challengeId = {this.props.challengeId}
				/>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	const currentGame = getCurrentGameDetails(state);
	const currentChallenge = getCurrentChallenge(state);
	const loggedUser = getLoggedUser(state);
	const opponents = getOpponents(state);

	return {
		isXboxUser: isXboxUser(state),
		userId:loggedUser._id,
		displayName:loggedUser.displayName,
		profile_image_id:loggedUser.profile_image_id,
		avatarType:loggedUser.avatarType,
		gameId: currentGame._id,
		gameTitle: currentGame.title,
		challengeId:currentChallenge._id,
		challengeTitle:currentChallenge.title,
		currentChallengeId: `${currentGame._id}${currentChallenge._id}`,
		opponents,
	};
};

export const MPCOpponents = connect(mapStateToProps)(MPCOpponentsComponent);