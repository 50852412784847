import React, {Component} from 'react';
import {connect} from 'react-redux';
import inputManager, {PARENT_ENTRANCE} from '../../../assets/lib/inputmanager';
import ListContainer, {LIST_TYPES} from "../../common/list/list-container.component";
import {navigateToLocation, ROUTES} from "../../../app.router";
import {FetchTournamentsListAction} from "../tournaments.actions";
import {getTournamentsList} from "../tournaments.selector";
import TournamentsListItem from "./tournaments-list-item.component";
import './tournaments-list.component.less';
import {getRoutingSearch} from "../../../app.selectors";
import downArrow from "../../../assets/images/generic-icons/tournament_grouping_arrow_down.svg";
import {TOURNAMENT_OBJECT_TYPE} from "../tournamentConstants";

class TournamentsListComponent extends Component {
	componentDidMount() {
		const {dispatch,group} = this.props;
		dispatch(FetchTournamentsListAction(group, this.onListResponse));

		if (!inputManager.currentChild) {
			setTimeout(() => {
				inputManager.setCurrentChildById("tournaments", "navBar");
			}, 1000);
		}
	}

	componentDidUpdate(prevProps) {
		const {dispatch,group} = this.props;

		if(prevProps.group !== group){
			dispatch(FetchTournamentsListAction(group, this.onListResponse));
		}
	}

	renderResults = (list) => {
		if (!list || list.length === 0) {
			return null;
		}

		const lists = [];
		lists.push({
			type: LIST_TYPES.VERTICAL,
			id: "tournamentsResult",
			data: list,
			renderer: this.renderTournamentItem,
			parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL
		});

		return (
			<ListContainer
				lists={lists}
				onTileClick={this.onTileClick}
			/>
		);
	};

	onTileClick = (id) => {
		var tournamentObject = this.props.list.find(item=>item._id===id);
		if(tournamentObject.type === TOURNAMENT_OBJECT_TYPE.GROUP){
			navigateToLocation(ROUTES.TOURNAMENTS_LIST, {group:tournamentObject.group, groupTitle:tournamentObject.title});
		}else{
			navigateToLocation(ROUTES.TOURNAMENTS_INFO, {id});
		}
		inputManager.setCurrentChildById("tournaments", "navBar");
	};

	renderTournamentItem = ({item, selected}) => {
		return (
			<TournamentsListItem
				tournamentObject={item}
				selected={selected}
			/>
		);
	};

	onListResponse = () => {
		inputManager.refreshBoundingRects('tournamentsResult');
	};

	render() {
		const {list,groupTitle} = this.props;

		return (
			<section className="tournaments-list-component">
				<h1>Latest Tournaments</h1>
				{list && list.length === 0 && "Sorry no tournaments are available yet"}
				{list && list.length > 0 && groupTitle && <p className="group-title">{groupTitle} <img src={downArrow} alt="down-arrow"/></p>}
				{list && list.length > 0 && this.renderResults(list)}
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	const list = getTournamentsList(state);
	const group= getRoutingSearch(state).group;
	const groupTitle= getRoutingSearch(state).groupTitle;

	return {
		list,
		group,
		groupTitle
	}
};

export const TournamentsList = connect(mapStateToProps)(TournamentsListComponent);
