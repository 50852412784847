
export const SET_SELECTED_MESSAGE_ID_ACTION = 'Set Selected Message Id Action';
export const SetSelectedMessageId = (messageId) => ({
	type: SET_SELECTED_MESSAGE_ID_ACTION, payload: messageId
});

export const CLEAR_SELECTED_CHALLENGE_ID = 'Set Selected Message Challenge Id';
export const ClearSelectedChallengeId = () => ({
	type: CLEAR_SELECTED_CHALLENGE_ID
});

