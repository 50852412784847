import React, {Component} from 'react';
import {connect} from 'react-redux';

import {ButtonWithGem} from '../../common/button/common-button-with-gem.component';
import {navigateToLocation, ROUTES} from '../../../app.router';
import {
	getCurrentTournamentData, getCurrentTournamentDetailId,
	getIsAddPlayerToTournamentPending,
	getIsPlayerJoined,
	getLeaderBoardObject, getRewards
} from "../tournaments.selector";
import {RunGameAction} from "../../game-details/game-details.actions";
import {
	APPLE_IOS_SKU,
	PREMIUM_POPUP_HEADER_TEXT_3,
	TOURNAMENT_STATUS_TYPE,
	unlimitedPlaysKey
} from '../../../constants';
import { AddPlayerToTournamentAction } from '../tournaments.actions';
import Countdown from './countdown.component';
import './tournaments-info.component.less';
import LeaderboardPositionComponent from './leaderboard-position.component';
import {TournamentsInfoSpectatorState} from "./tournaments-info-spectator-state.component";
import TournamentsInfoFinishedState from "./tournaments-info-finished-state.component";
import inputmanager from "../../../assets/lib/inputmanager";
import GemAnimation, {TYPE_GEM} from "../../animations/gem.animation";
import {getGemIconRef} from "../../common/player-profile/player-profile-header.component";
import {getChallengeById} from "../../../entities/entities.selectors";

import Lottie from 'react-lottie';
import prizePotAnimationData from "../../../assets/animations/prizePot.json";
import {getFreeTierTournamentsInformation, getGemPlayTournamentsCost, getIsInterimScoreEnabled, getLoggedUser, getTournamentsLeft, isFreeTier} from "../../../app.selectors";

import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import ShareButton from '../../common/share-button/share-button.component';
import { DEEP_LINK_PARAMETER, DEEP_LINK_TARGET, generateDeepLinkURL } from '../../../assets/lib/deepLinkGenerator';
import Helmet from '../../common/helmet/helmet.component';
import {
	isFreemiumAppleIosUser,
	isFreemiumV4UserNotSubscribed,
	isXboxCrossplayEnabled,
	isXboxUser
} from '../../../assets/lib/utils';
import { addPopup } from '../../popup/popup.component';
import XboxCrossplayOnlyPopup from '../../popup/xbox-crossplay-only-popup/xbox-crossplay-only-popup.component';
import { buildSponsoredTournamentLabel, determineTournamentPlayCost } from '../tournaments.utils';
import { openUrlOnDefaultBrowser, startNativePayment } from '../../../assets/lib/game-wrapper';
import { Button } from '../../common/button/common-button.component';
import { analyticsEvent } from '../../../assets/lib/antstreamAnalytics';
import deviceInfo from '../../../assets/lib/deviceInfo';
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';

class TournamentsInfoComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startGemTransferAnimation: false,
			showPrizePotAnimation: true
		}

		this.prizePotAnimationOptions = {
			loop: false,
			autoplay: true,
			animationData: prizePotAnimationData
		};
	}

	componentDidMount() {
		if (!inputmanager.currentChild) { // if no focus - focus on Info tab
			inputmanager.setCurrentChildById('tab-menu-Info', 'tab-menu');
		}
	}

	componentDidUpdate() {
		if (!inputmanager.currentChild) { // if no focus - focus on Info tab
			inputmanager.setCurrentChildById('tab-menu-Info', 'tab-menu');
		}
	}
	
	renderGemAnimation = () => {
		const gemIconRef = getGemIconRef();
		if (!gemIconRef) return;
		const position = {x: gemIconRef.getBoundingClientRect().x, y: gemIconRef.getBoundingClientRect().y};
		return (
			<GemAnimation
				startPoint={position}
				count={5}
				animationType={TYPE_GEM}
				animationDelayPerGem={0.060}
				animationStartDelay={0.1}
				onAnimationEnd={this.onGemTransferAnimationEnd}
			/>
		);
	}

	onGemTransferAnimationEnd = () => {
		const {dispatch, tournamentData} = this.props;

		this.setState({
			startGemTransferAnimation: false
		});

		dispatch(AddPlayerToTournamentAction(tournamentData._id));
		inputmanager.setCurrentChildById("playButton");
	}

	playHandler = () => {
		if (this.props.isFreemiumAppleIos) {
			startNativePayment(APPLE_IOS_SKU);
			return;
		}
		if (this.props.isFreemiumV4User) {
			const subscriptionPopup = <SubscriptionComparisonsPopup
				leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_3}
				pageId='tournaments_info_1'
			/>;
			addPopup(subscriptionPopup);
			return;
		}

		if (this.props.isXboxCrossplayTurnedOff) {
			this.showXboxPopup();
			return;
		}

		const {tournamentData, challengeData, dispatch} = this.props;

		const gameId = challengeData.gameId;
		const challengeId = challengeData._id;
		const tournamentId = tournamentData._id;
		dispatch(RunGameAction({
			gameId,
			challengeId,
			tournamentId,
			challengeStyle: this.props.isInterimScoreEnabled?'tournament':'intro'
		}));
		navigateToLocation(ROUTES.HOW_TO_PLAY, { id: gameId, challengeId, tournamentId });
	}

	showXboxPopup = () => {
		addPopup(<XboxCrossplayOnlyPopup />);
	}

	joinHandler = () => {
		if (this.props.isFreemiumAppleIos) {
			startNativePayment(APPLE_IOS_SKU);
			return;
		}
		if (this.props.isFreemiumV4User) {
			const subscriptionPopup = <SubscriptionComparisonsPopup
				leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_3}
				pageId='tournaments_info_1'
			/>;
			addPopup(subscriptionPopup);
			return;
		}

		if (this.props.isXboxCrossplayTurnedOff) {
			this.showXboxPopup();
			return;
		}

		this.setState({
			startGemTransferAnimation: true
		});
	}

	renderTermsAndConditionsButton = () => {
		const url = this.props.tournamentData?.sponsoredData?.termsAndConditions;

		return (
			<Button
				className="primary"
				onClick={() => openUrlOnDefaultBrowser(url)}
				childId="TournamentInfoComponent-sponsorTermsButton"
				disabled={!url}
			>
				T&Cs
			</Button>
		);
	}

	handleDetailImageClick = () => {
		const link = this.props.tournamentData?.sponsoredData?.links?.exitLinkUrl;

		analyticsEvent({
			category: 'Sponsored Tournaments v1.5',
			action: 'info_page_image_clicked',
			label: buildSponsoredTournamentLabel(this.props?.tournamentData)
		});

		if (!link) return;

		openUrlOnDefaultBrowser(link);
	}

	onPrizePotAnimationComplete = () => {
		this.setState({
			showPrizePotAnimation: false
		})
	}

	renderPrizePot = () => {
		const { startGemTransferAnimation, showPrizePotAnimation } = this.state;
		const { tournamentData } = this.props;

		// If Prize Pot is 0 don't show it for sponsored tournaments
		if (tournamentData?.sponsored && !tournamentData.prizePot) return null;

		return (
			<div className="trophy-container">
				{ showPrizePotAnimation &&
					<div className="trophy-container-animation">
						<Lottie options={this.prizePotAnimationOptions}
								eventListeners={[
									{
										eventName: 'complete',
										callback: this.onPrizePotAnimationComplete,
									},
								]}
						/>
					</div>
				}
				<div className="trophy-icon animation">
					<div className={`trophy-icon-score ${startGemTransferAnimation ? 'animation' : ''}`}>
						<div className="label">Prize Pot</div>
						{new Intl.NumberFormat('en-GB').format(tournamentData.prizePot)}</div>
						{startGemTransferAnimation &&
							<div className="gem-animation">{this.renderGemAnimation()}</div>
						}
				</div>
			</div>
		);
	}

	shouldDisplayShareButton = () => {
		if (this.props.isXboxPlayer) return false;
		if (this.props.tournamentData?.sponsored) return false;
		if (deviceInfo.isPlayStationPlatform()) return false;

		return true;
	};

	render() {
		const { startGemTransferAnimation } = this.state;
		const { isAddPlayerToTournamentPending, rewards, leaderBoardObject, tournamentData, challengeData, isPlayerJoined, isUserFreeTier, tournamentsLeftValue, gemPlayGameCost, user, isFreemiumV4User, isFreemiumAppleIos } = this.props;
		const freeTierAdditionalText = isUserFreeTier ? `(${tournamentsLeftValue})` : '';
		const { currencies } = user;
		const { GEMS } = currencies;
		if (!tournamentData) return null;

		const status = tournamentData.status;
		let rank = leaderBoardObject ? leaderBoardObject.rank : null;
		const isSponsored = tournamentData?.sponsored;

		if (status === TOURNAMENT_STATUS_TYPE.FINISHED) {
			if (isPlayerJoined) {
				return <TournamentsInfoFinishedState
					challengeData={challengeData}
					leaderBoardObject={leaderBoardObject}
					tournamentData={tournamentData}
					rewards={rewards} />;
			} else {
				return <TournamentsInfoSpectatorState
					challengeData={challengeData}
					tournamentData={tournamentData} />;
			}
		}

		const freemiumClass = (isFreemiumV4User || isFreemiumAppleIos) ? 'freemium-v2-benefits' : '';

		return (
			<div className="tournaments-info-component">
				<Helmet
					image={tournamentData && tournamentData.detailImage}
				/>
				<div className="content-left">
					<div className="top-container">
						<p className="tournaments-info-component__description">
							{tournamentData.description}
						</p>
						<div className="sub-description">
							<h2>{challengeData.title}</h2>
							<p>{challengeData.text}</p>
						</div>
					</div>
					<div className="bottom-container">
						<div className="bottom-left-container">
							<div className="buttons-container">
								{featureInfo.isSupported(FEATURES.GAMEPLAY) &&
									<ButtonWithGem
										className={`primary play-button two-line ${isPlayerJoined ? '' : 'hidden'} ${freemiumClass}`}
										onClick={this.playHandler}
										childId="playButton"
										cost={(isFreemiumV4User || isFreemiumAppleIos) ? null : determineTournamentPlayCost(tournamentData, gemPlayGameCost)}
									>
										{(isFreemiumV4User || isFreemiumAppleIos) ? 'Play on Premium' : `Play ${freeTierAdditionalText}`}
									</ButtonWithGem>
								}
								{!isPlayerJoined &&
									<ButtonWithGem
										className={`primary two-line ${freemiumClass}`}
										onClick={this.joinHandler}
										cost={(isFreemiumV4User || isFreemiumAppleIos) ? null : tournamentData.requiredCurrencies.GEMS}
										childId="joinButton"
										disabled={(GEMS < tournamentData.requiredCurrencies.GEMS) || startGemTransferAnimation || isAddPlayerToTournamentPending}
									>
										{(isFreemiumV4User || isFreemiumAppleIos) ? 'Join on Premium': 'Join'}
									</ButtonWithGem>
								}
								{isSponsored && this.renderTermsAndConditionsButton()}
								{this.shouldDisplayShareButton() && <ShareButton
									deepLink={generateDeepLinkURL(DEEP_LINK_TARGET.TOURNAMENT_INFO, {[DEEP_LINK_PARAMETER.TOURNAMENT_ID]: tournamentData._id})}
								/>}
								{rank && <LeaderboardPositionComponent rank={rank}/>}
							</div>
						</div>
						{this.renderPrizePot()}
					</div>
				</div>
				<div className="content-right">
					<div className={`image-container ${isSponsored ? 'image-container--clickable' : ''}`} onClick={isSponsored ? this.handleDetailImageClick : null}>
						<Countdown startTimeStamp={tournamentData.startDate} endTimeStamp={tournamentData.endDate}/>
						<img className="tournament-image" src={tournamentData.detailImage} alt=""/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const tournamentId = getCurrentTournamentDetailId(state);
	const tournamentData = getCurrentTournamentData(state);
	const challengeData = tournamentData ? getChallengeById(state,tournamentData.challengeId) : {};
	const isPlayerJoined = getIsPlayerJoined(state);
	const isAddPlayerToTournamentPending = getIsAddPlayerToTournamentPending(state);
	const leaderBoardObject = getLeaderBoardObject(state);
	const rewards = getRewards(state);
	const isInterimScoreEnabled = getIsInterimScoreEnabled(state);
	const isUserFreeTier = isFreeTier(state) && getFreeTierTournamentsInformation(state).limit !== unlimitedPlaysKey
	const isXboxCrossplayTurnedOff = isXboxUser(state) && !isXboxCrossplayEnabled(state);

	return {
		isFreemiumAppleIos: isFreemiumAppleIosUser(state),
		isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
		isXboxPlayer: isXboxUser(state),
		isXboxCrossplayTurnedOff,
		isUserFreeTier,
		tournamentsLeftValue: isUserFreeTier ? getTournamentsLeft(state) : 0,
		tournamentId,
		tournamentData,
		challengeData,
		isPlayerJoined,
		leaderBoardObject,
		rewards,
		isInterimScoreEnabled,
		isAddPlayerToTournamentPending,
		gemPlayGameCost: getGemPlayTournamentsCost(state),
		user: getLoggedUser(state)
	};
};

export const TournamentsInfo = connect(mapStateToProps)(TournamentsInfoComponent);