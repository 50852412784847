import React from "react";

import { getRemainingTime } from "../../../assets/lib/utils";

import "./countdown.component.less";
import { format as dateFormat, formatDistanceToNowStrict } from 'date-fns'
import enLocale from 'date-fns/locale/en-US'

function Countdown({ startTimeStamp, endTimeStamp }) {
    let countdownStatus = null;
    let countdownDefault = null;

    const currentDate = new Date();
    const startDate = new Date(0);
    startDate.setUTCSeconds(startTimeStamp);
    const endDate = new Date(0);
    endDate.setUTCSeconds(endTimeStamp);

    let countdownTextStatus = '';
    let countdownTextTitle = '';
    let countdownTextSubtitle = '';

    if (endDate <= currentDate) { //Tournament finished
        countdownStatus = 'countdownEnded';
        countdownTextStatus = '';
        countdownTextTitle = 'Ended';
        countdownTextSubtitle = dateFormat(endDate,'do MMM',{locale:enLocale});
    } else {
        if (startDate < currentDate && endDate > currentDate) { //Tournament in-progress
            countdownStatus = 'countdownStarted';
            countdownTextStatus = 'Started';
            countdownTextTitle = formatDistanceToNowStrict(endDate, { addSuffix: false, locale: enLocale});
            countdownTextSubtitle = 'left';
        } else if (getRemainingTime(startDate).days <= 7) { //Less than 7 days until the tournament starts
            countdownStatus = 'countdownNearFuture';
            countdownTextStatus = 'Starts';
            countdownTextTitle = formatDistanceToNowStrict(startDate, {addSuffix: true, locale: enLocale});
            countdownTextSubtitle = '';
        } else if (getRemainingTime(startDate).days > 7) { //There are more than 7 days until the tournament starts
            countdownStatus = 'countdownFarFuture';
            countdownTextStatus = 'Starts';
            countdownTextTitle = dateFormat(startDate,'do MMM',{locale:enLocale});
            countdownTextSubtitle = '';
        }
    }

    const classes = `countdown-component ${countdownStatus}`;

    return (
        <div className={classes}>
            <div className="status">{countdownTextStatus}</div>
            <div className="countdown-title">{countdownTextTitle}</div>
            <div className="sub-title">{countdownTextSubtitle}</div>
        </div>
    );
}

export default Countdown;