import React, { Component } from 'react';
import './scrollable-box.component.less';
import inputmanager from '../../../assets/lib/inputmanager';
import {PulsarComponent} from '../pulsar/pulsar.component';
import AudioManager, {TYPE_VERTICAL_LIST_ROW_CHANGE} from '../../../app.audio';
import {DIRECTION} from '../../../assets/lib/inputmanager';
// import TweenLite, {Power4} from 'gsap/TweenLite';
import gsap from 'gsap';

class ScrollableBoxComponent extends Component {
	constructor() {
		super();
		this.refComponent = null;
		this.refContent = null;
		this.scrollTop = 0;
		this.contentHeight = null;
		this.contentScollHeight = null;

		this.state = {
			scrollVisible:false,
			selected:false,
			active:false,
		};
	}

	onCustomFocus = (focus/*, nextElement*/) => {
		if(focus && this.refComponent) {
			this.refComponent.focus();
			this.setState({selected:true});
		} else {
			this.setState({
				selected:false,
				active:false
			});
		}
		if(this.props.onFocus)this.props.onFocus(focus);
	};

	onComponentRef = (element) => {
		this.refComponent = element;
		if(this.props.childId){
			inputmanager.onChildRef(element,this.props.childId, this.onCustomFocus, this.props.parentId, this.props.layer, this.areDirectionsInUse);
		}
	};

	onContentRef = (element) => {
		this.refContent = element;
		if(element){
			this.contentHeight = this.refContent.clientHeight;
			this.contentScollHeight = this.refContent.scrollHeight;
			if(this.contentHeight<this.contentScollHeight){
				this.setState({scrollVisible:true});
			}
		}
	};

	onClicked = (event) => {
		event.preventDefault();
		AudioManager.playAudio(TYPE_VERTICAL_LIST_ROW_CHANGE);
		if(!this.state.selected){
			inputmanager.setCurrentChildById(this.props.childId,this.props.parentId);
			return;
		}
		if(!this.state.scrollVisible)return;
		this.setState((prevState)=>{
			return {active:!prevState.active};
		});
	};

	areDirectionsInUse = (direction) => {
		if(this.refContent && this.state.active) {
			if(direction === DIRECTION.DOWN) {
				this.scrollTop = Math.min(this.scrollTop + 20, this.contentScollHeight - this.contentHeight);
			} else if(direction === DIRECTION.UP){
				this.scrollTop = Math.max( this.scrollTop - 20, 0);
			}
			const tl = gsap.timeline();
			tl.to(this.refContent, 0.5, {scrollTo:{y:this.scrollTop }, ease:'power4.easeOut'});

			return true;
		} else {
			return false;
		}
	};

	render() {
		let moreStyles = '';
		moreStyles += this.props.className;
		moreStyles += this.props.disabled ? ' disabled' : '';

		return (
			<div
				className={`scrollable-box-component ${moreStyles} ${this.state.selected?'selected':''}  ${this.state.scrollVisible?'scrollVisible':''} ${this.state.active?'active':''}`}
				ref={this.onComponentRef}
				onClick={this.onClicked}
			>
				<div
					className="scrollable-content"
					ref={this.onContentRef}
				>
					{this.props.children}
				</div>
				<PulsarComponent/>
			</div>
		);
	}
}

export const ScrollableBox = ScrollableBoxComponent;
