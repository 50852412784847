import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import {
	ROUTES,
	navigateToLocation,
	navigateToSavedLocation
} from '../../../app.router';
import { getLoading } from '../login.selectors';
import { Button } from '../../common/button/common-button.component';
import { Input } from '../../common/input/input.component';
import { LoginUserAction } from '../login.actions';
import { requiredValidator, emailValidator, trimValidator } from '../../common/input/input.validator';
import { getWebViewDimensions } from '../../../app.selectors';
import FacebookLoginComponent from '../../../components/facebook-login/facebook-login.component';
import EpicGamesLoginComponent from '../../../components/epic-games-login/epic-games-login.component';
import { PLAY_STATION_WEBRTC_REDIRECT_URI } from '../../../constants';

// Assets
import FeatureInfo, { FEATURES } from '../../../assets/lib/featureInfo';
import * as GameWrapper from '../../../assets/lib/game-wrapper';
import * as Utils from '../../../assets/lib/utils';
import InputManager from '../../../assets/lib/inputmanager';
import DeviceInfo, { OS_TYPE_IOS, PLATFORM_TYPE_ATARI, STORE_TYPE_SAMSUNG_TV } from "../../../assets/lib/deviceInfo";

// CSS
import '../login-global.less';
import './login-credentials.component.less';

const formId = "loginCredentials";
class LoginCredentialsComponent extends Component {
    constructor() {
        super();
        this.state = { email: null };
    }

    componentDidMount() {
        GameWrapper.grabControllers();
        GameWrapper.clearBackOverride();
        InputManager.setCurrentChildById('emailInput');
        const { isLoggedIn } = this.props;

        if (isLoggedIn) {
            navigateToSavedLocation();
        }
    }

    onResetPassword = () => {
        navigateToLocation(ROUTES.PASSWORD_RESET_REQUEST);
    };

    onLogin = values => {
        if (this.props.loading) return;

        const { email, password } = values;
        this.props.dispatch(LoginUserAction({ email: email.toLowerCase(), password }));
    };

    back = () => {
        navigateToLocation(ROUTES.LOGIN);
    };

    onKeyDown = event => {
        if (event.keyCode === 13) {
            this.props.handleSubmit(this.onLogin)();
        }
    };

    loginWithXbox = () => {
        const redirect = window.location.origin + ROUTES.XBOX_AUTH_ACCESS_TOKEN.path;
        const url = `https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize?client_id=ad6709cb-616e-4bc5-b22c-00866b5ce3c4&response_type=code&prompt=select_account&scope=xboxlive.signin&xboxlive.offline_access&redirect_uri=${redirect}`;

        GameWrapper.openUrl(url);
    }

    /**
     * This is used for login with Play Station from a browser only (no Native client).
     * Note: This method is not used when using Native client, this flow will be done on the Native client side.
     *      When using Native client, we just need to listen 'platformLogin' message.
     *      Native client has different redirectUri.
     * <REDIRECT_URI>/?code=v3.0hq6qs&cid=5924f938-6306-44c9-8538-d88cbcd3376c
     **/
    loginWithPlayStationViaWebrtc = () => {
        const domain = window.config.REACT_APP_PLAY_STATION_DOMAIN;
        const clientId = window.config.REACT_APP_PLAY_STATION_CLIENT_ID;
        const scope = 'psn:s2s openid id_token:psn.basic_claims';

        const url = `https://${domain}/api/authz/v3/oauth/authorize?service_entity=urn:service-entity:psn&response_type=code&client_id=${clientId}&redirect_uri=${PLAY_STATION_WEBRTC_REDIRECT_URI}&scope=${scope}`;

        GameWrapper.openUrl(url);
    }

    render() {
        const { loading, handleSubmit } = this.props;

        const xboxOrPsSupported = FeatureInfo.isSupported(FEATURES.XBOX_LOGIN)
            || FeatureInfo.isSupported(FEATURES.PLAYSTATION_LOGIN);

        return (
            <div className={`login-wrapper login-credentials ${Utils.checkWebViewDimensions(this.props.viewDimensions)}`}>
                <section className="login-section">
                    <form autoComplete="off">
                        <Field
                            onKeyDown={this.onKeyDown}
                            type="email"
                            name="email"
                            formId={formId}
                            component={Input}
                            label="Email"
                            validate={[requiredValidator, emailValidator, trimValidator]}
                            childId="emailInput"
                        />
                        <Field
                            onKeyDown={this.onKeyDown}
                            name="password"
                            type="password"
                            formId={formId}
                            component={Input}
                            label="Password"
                            validate={[requiredValidator]}
                            childId="passwordInput"
                        />
                    </form>
                    <Button onClick={handleSubmit(this.onLogin)} disabled={loading} className="primary" childId="loginButton">
                        {loading ? "Logging in ..." :"Login"}
                    </Button>
                    <div className="buttons-container">
                        <Button className="secondary small-button" onClick={this.back} childId="backButton">
                            Back
                        </Button>
                        <Button className="secondary small-button" onClick={this.onResetPassword} childId="resetPasswordButton">
                            Reset Password
                        </Button>
                    </div>

                    {DeviceInfo.osType !== OS_TYPE_IOS && DeviceInfo.platformType !== PLATFORM_TYPE_ATARI && DeviceInfo.storeType !== STORE_TYPE_SAMSUNG_TV &&(
                        <>
                            <p className="separator-with-text">
                                <span className="line"></span>
                                <span className="text">or</span>
                                <span className="line"></span>
                            </p>
                            <div className='login-with'>
                                <div className='login-with__box'>
                                    <FacebookLoginComponent />
                                    {FeatureInfo.isSupported(FEATURES.EPIC_GAMES_LOGIN) && <EpicGamesLoginComponent />}
                                </div>
                                {xboxOrPsSupported &&
                                    <div className='login-with__box login-with__box--second'>
                                        {FeatureInfo.isSupported(FEATURES.XBOX_LOGIN) &&
                                            <Button childId="loginWithXboxButton" onClick={this.loginWithXbox} className='with-xbox'>
                                                <div className="content">
                                                    <div className='login-with__fake-btn-img'></div>
                                                    <div className="text-container">
                                                        Continue with Xbox
                                                    </div>
                                                </div>
                                            </Button>}
                                        {FeatureInfo.isSupported(FEATURES.PLAYSTATION_LOGIN)
                                            && <Button
                                                childId="loginWithPlayStationButton"
                                                onClick={this.loginWithPlayStationViaWebrtc}
                                                className=' with-playstation'>
                                                <div className="content">
                                                    <div className='login-with__fake-btn-img'></div>
                                                    <div className="text-container">
                                                        Continue with PlayStation
                                                    </div>
                                                </div>
                                            </Button>}
                                    </div>}
                            </div>
                        </>
                    )}
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
	const viewDimensions = getWebViewDimensions(state);

	return {
		...state.login,
		viewDimensions,
		loading: getLoading(state),
		isLoggedIn: state.login.logged
	};
};

LoginCredentialsComponent = connect(mapStateToProps)(LoginCredentialsComponent);

export const LoginCredentials = reduxForm({
	form: formId
})(LoginCredentialsComponent);
