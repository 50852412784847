import * as ACTIONS from './daily-challenges.actions';

const initialState = {
    isChallengesCategoriesFetched: false,
    challengesCategories: [],
    isChallengesByCategoryFetched: false,
    challengesByCategoryLists: null,
    isCurrentChallengePending: false,
    isDailyChallengeConfigurationPending: false,
    dailyChallengePartyPending: false,
    playDailyChallenge: false,
    needAnimateOnFirstDailyChallengePlay: false,
    dailyChallengeEventId: null,
    currentTimeEnd: null,
    currentChallengeId: null,
    dailyChallengeParty: null,
    configuration: null,
    dailyChallengeActivity: null,
    alreadyPlayed: false
};

export const dailyChallengesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_CHALLENGES_BY_CATEGORY_SUCCESS: {
            return {
                ...state,
                challengesByCategoryLists: action.payload,
                challengesByCategoryListsFetchedAt: new Date().getTime(),
                isChallengesByCategoryFetched: true
            };
        }
        case ACTIONS.GET_CURRENT_DAILY_CHALLENGE_PENDING: {
            return {
                ...state,
                isCurrentChallengePending: true
            };
        }
        case ACTIONS.GET_CURRENT_DAILY_CHALLENGE_SUCCESS: {
            // currentChallengeId: 'cca012ba-6d58-4f35-b022-5a6cf00b29f5Tier-1-Challenge-1',
            return {
                ...state,
                dailyChallengeEventId: action.payload.dailyChallengeEventId,
                currentChallengeId: action.payload.challengeId,
                currentTimeEnd: action.payload.end,
                // alreadyPlayed: action.payload.played,
                isCurrentChallengePending: false
            };
        }
        case ACTIONS.GET_CURRENT_DAILY_CHALLENGE_ERROR: {
            return {
                ...state,
                isCurrentChallengePending: false
            };
        }
        case ACTIONS.GET_DAILY_CHALLENGE_CONFIGURATION_PENDING: {
            return {
                ...state,
                isDailyChallengeConfigurationPending: true
            };
        }
        case ACTIONS.GET_DAILY_CHALLENGE_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                configuration: action.payload,
                isDailyChallengeConfigurationPending: false
            };
        }
        case ACTIONS.GET_DAILY_CHALLENGE_CONFIGURATION_ERROR: {
            return {
                ...state,
                isDailyChallengeConfigurationPending: false
            };
        }
        case ACTIONS.GET_DAILY_CHALLENGE_PARTY_PENDING: {
            return {
                ...state,
                dailyChallengePartyPending: true,
                dailyChallengeParty: null
            };
        }
        case ACTIONS.GET_DAILY_CHALLENGE_PARTY_SUCCESS: {
            return {
                ...state,
                dailyChallengePartyPending: false,
                dailyChallengeParty: action.payload
            };
        }
        case ACTIONS.GET_DAILY_CHALLENGE_PARTY_ERROR: {
            return {
                ...state,
                dailyChallengePartyPending: false
            };
        }
        case ACTIONS.PLAY_DAILY_CHALLENGE: {
            return {
                ...state,
                playDailyChallenge: action.payload
            }
        }
        case ACTIONS.GET_DAILY_CHALLENGE_ACTIVITY_SUCCESS: {
            return {
                ...state,
                dailyChallengeActivity: action.payload
            };
        }
        case ACTIONS.ANIMATE_ON_FIRST_DAILY_CHALLENGE_PLAY: {
            return {
                ...state,
                needAnimateOnFirstDailyChallengePlay: action.payload
            };
        }
        case ACTIONS.GET_CHALLENGE_DETAILS_BY_ID_SUCCESS: {
            return {
                ...state,
                currentChallenge: action.payload
            };
        }
        default: {
            return state;
        }
    }
};
