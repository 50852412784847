import React from 'react';
import './dialog-box-overlay.component.less';
import { connect } from 'react-redux';

const DialogBoxOverlayComponent = ({ isPopupHidden }) => {
	if (isPopupHidden) return null;

	return <div className="dialog-overlay active" id="dialog-overlay" />;
};

const mapStateToProps = (state) => {
	return {
		isPopupHidden: state.popup.isHidden,
	};
};

export default connect(mapStateToProps)(DialogBoxOverlayComponent);
