import { fromJS } from 'immutable';
import { normalize, schema } from 'normalizr';

import { FETCH_LOGOS_ACTION_SUCCESS } from './logos.actions';

const initialState = fromJS({});

export const logosReducer = (state = initialState, action) => {
	switch (action.type) {
	case FETCH_LOGOS_ACTION_SUCCESS:
		const normalizedData = avatarNormalizer(action.payload);
		const byId = { ...normalizedData.entities.logos };
		const allIds = [ ...normalizedData.result ];
		return { byId, allIds };
		
	default:
		return state;
	}
};

export const avatarNormalizer = (prenormalData) => {
	const cschema = new schema.Entity('logos', undefined, { idAttribute: 'id' });
	return normalize(prenormalData, [ cschema ]);
};
