import {antstreamService} from '../../app.reducer';

const log = (message) => {
    //console.log("KeepAlive: " + message);
};

// The current state of the Keep Alive, initial state is 'stopped'.
var currentState = 'stopped';

// The timers to keep updating the last event time.
var pingTimer, afkTimer

const pingStart = () => {

    if (!pingTimer) {
        log("Updating player's lastEventTime");
        antstreamService.updateLastEventTime();
        
        log("Starting ping timer");
        const PING_INTERVAL = 2 * 60 * 1000;
        pingTimer = setInterval(() => {
            log("Updating player's lastEventTime");
            antstreamService.updateLastEventTime();
        }, PING_INTERVAL);
    }
};

const pingStop = () => {
    if (pingTimer) {
        log("Stopping ping timer");
        clearInterval(pingTimer);
        pingTimer = null;
    }
};

const afkStart = () => {
    if (!afkTimer) {
        log("Starting AFK timer");
        const AFK_TIMEOUT = 5 * 60 * 1000;
        afkTimer = setTimeout(() => {
            log("Player is AFK");
            transitionTo('noUserInteraction');
        }, AFK_TIMEOUT);
    }
};

const afkStop = () => {
    if (afkTimer) {
        log("Stopping AFK timer");
        clearTimeout(afkTimer);
        afkTimer = null;
    }
}

// Actions to perform when the current state is transitioning to another
// state. Transitioning only succeeds if the action returns true.
const transitionActions = {
    // The keeper starts in this state when the client is started by the user.
    // No ping messages will be sent to Gamesparks, as we don't have an user
    // ID.
    stopped: function() {
        pingStop();
        afkStop();
        return true;
    },

    // We have an user ID and the keeper will send ping messages to Gamesparks
    // every Y minutes. If the user stops interaction after X minutes, it'll
    // transition to Paused.
    running: function() {
        pingStart();
        afkStart();
        return true;
    },

    // The keeper won't send ping messages to Gamesparks, and will transition
    // back to Running when interaction resumes. Paused means the user is Away
    // From Keyboard.
    paused: function() {
        pingStop();
        afkStop();
        return true;
    },
    
    // The keeper will send ping messages to Gamesparks every Y minutes, even
    // though the UI don't see any user interaction because the user is in a
    // game session.
    playing: function() {
        pingStart();
        return true;
    }
};

// The possible transitions. The first key is the current state name, the
// second key is the transition name, and the value of the second key is the
// target state.
const transitions = {
    stopped: {
        userLoggedIn: 'running'
    },
    running: {
        userLoggedOut: 'stopped',
        clientClosed: 'stopped',
        noUserInteraction: 'paused',
        gameRunningReceived: 'playing'
    },
    paused: {
        userInteracted: 'running',
    },
    playing: {
        gamePausedReceived: 'running',
        gameFinishedReceived: 'running',
        errorReceived: 'running'
    }
};

// Tries to transition from the current state to a new state via the named
// transition.
const transitionTo = (transitionName) => {
    const possibleTransitions = transitions[currentState];

    if (transitionName in possibleTransitions) {
        const newState = possibleTransitions[transitionName];
        
        if ((newState in transitionActions) && transitionActions[newState]()) {
            log("Transitioned from " + currentState + " to " + newState + " via " + transitionName);
            currentState = newState;
            return true;
        }
    }
    
    // Could not transition: transitionName is unknown, or the action failed.
    log("Failed to transition from " + currentState + " via " + transitionName);
    return false;
};

const api = {
    userLoggedIn: () => { return transitionTo('userLoggedIn'); },
    userLoggedOut: () => { return transitionTo('userLoggedOut'); },
    clientClosed: () => { return transitionTo('clientClosed'); },
    gameRunningReceived: () => { return transitionTo('gameRunningReceived'); },
    userInteracted: () => { return transitionTo('userInteracted'); },
    gamePausedReceived: () => { return transitionTo('gamePausedReceived'); },
    gameFinishedReceived: () => { return transitionTo('gameFinishedReceived'); },
    errorReceived: () => { return transitionTo('errorReceived'); }
};

export default api;
