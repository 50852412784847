import { fromJS } from 'immutable';
import { normalize, schema } from 'normalizr';

import { FETCH_BADGES_ACTION_SUCCESS } from './badges.actions';

const initialState = fromJS({});

export const badgesReducer = (state = initialState, action) => {
	switch (action.type) {
	case FETCH_BADGES_ACTION_SUCCESS:
		const normalizedData = objNormalizer(action.payload);
		const byId = { ...state.byId, ...normalizedData.entities.badges };
		const allIds = Object.keys(byId);
		return { byId, allIds };
		
	default:
		return state;
	}
};

export const objNormalizer = (prenormalData) => {
	const cschema = new schema.Entity('badges', undefined, { idAttribute: 'id' });
	return normalize(prenormalData, [ cschema ]);
};
