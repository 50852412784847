import React from 'react';
import { Button } from '../../common/button/common-button.component';
import './filterButton.component.less';

const FilterButton = ({childId, className, isActive= false, onClick, text, parentId, image, imageAlt}) => (
    <Button
        className={`filterButton ${className} ${isActive ? 'active' : ''}`}
        onClick={() => onClick && onClick()}
        childId={childId}
        parentId={parentId}
    >
        <img alt={imageAlt} className="button-icon" src={image} />
        {text}
    </Button>
)

export default FilterButton;
