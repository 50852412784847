import React, {Component} from 'react';

import './gem.animation.less';

export const TYPE_GEM = 'gem';
export const TYPE_EXP = 'exp';

class GemAnimation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAnimationEnded : false,
			containerRef: null,
		};

		this.onContainerRef = this.onContainerRef.bind(this);
		this.animationStart = this.animationStart.bind(this);

		this.animationStarted = false;
	}

    getGems = (startPoint,count,animationType,animationDelayPerGemParam,animationStartDelayParam) => {
    	if(!this.state.containerRef) return null;
    	const items = [];
    	const animationDelayPerGem = animationDelayPerGemParam ? animationDelayPerGemParam : 0.075;
    	const animationStartDelay = animationStartDelayParam ? animationStartDelayParam : 1;
    	count = Math.abs(count);

    	let additionalClasses = '';

    	if(animationType===TYPE_EXP)additionalClasses += 'isExp';

    	const containerPosition = this.state.containerRef.getBoundingClientRect();
    	const startX = startPoint.x - containerPosition.left;
    	const startY = startPoint.y - containerPosition.top;

    	for(let i=0; i<count ; i++) {
    		const animationDelay = animationStartDelay + i * animationDelayPerGem;
    		items.push(
    			<div style={{'transform':`translate(${startX}px,${startY}px)`,'animationDelay':`${animationDelay}s`}}
					 key={`gem_animation_item_${i}`}
					 className={`gem-animation-image ${additionalClasses}`}
					 onAnimationEnd={i===count-1 ? this.clearAnimationContainer : null}
					 onAnimationStart={i===0 ? this.animationStart : null}
    			>
    				{animationType===TYPE_EXP ? 'XP' : null}
    			</div>
    		);
    	}

    	return items;
    };

	clearAnimationContainer = () => {
    	this.setState({isAnimationEnded:true});

    	if(this.props.onAnimationEnd)this.props.onAnimationEnd();
	};

	animationStart() {
		if(!this.animationStarted && this.props.onAnimationStart) {
			this.animationStarted = true;
			this.props.onAnimationStart();
		}
	}

	onContainerRef(element) {
		this.setState({containerRef:element});
	}

	render() {
    	const {startPoint, count, animationType, animationDelayPerGem, animationStartDelay} = this.props;

    	return(
    		<div className="gem-animation-container" ref={this.onContainerRef}>
    			{!this.state.isAnimationEnded && startPoint ? this.getGems(startPoint,count,animationType,animationDelayPerGem,animationStartDelay) : ''}
    		</div>
    	);
	}
}

export default GemAnimation;
