import { readRestUserData } from '../../assets/lib/local-storage';
import { antstreamAPIService } from '../../app.reducer';
import { getDailyChallenge } from './daily-challenges.selectors';
import { getAssetsUrlWithFolder } from '../../app.selectors';
import { FOLDER_TYPES } from '../../assets/lib/FolderTypes';
import { handleNoAuthTokenIssue } from '../../assets/lib/utils';

export const GET_CURRENT_DAILY_CHALLENGE_PENDING = 'GET_CURRENT_DAILY_CHALLENGE_PENDING';
export const getCurrentDailyChallenge = () => {
    return (dispatch, getState) => {
        dispatch({type: GET_CURRENT_DAILY_CHALLENGE_PENDING});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(GET_CURRENT_DAILY_CHALLENGE_PENDING);

        const params = {
            headers: { Authorization: 'Bearer ' + authToken }
        };

        antstreamAPIService.v2.dailyChallengeDetail('current', params).then(async ({ data }) => {
            if (!data) throw new Error('Something went wrong');
            if (data.error) throw new Error(data.error);

            /**
             * @type {{_id: string, end: number, challenge: {challenge: string, game: string}}}
             **/
            const res = data.data;

            const payload = {
                challengeId: res?.challenge?.challenge,
                end: Math.round(res?.end / 1000),
                dailyChallengeEventId: res?._id
            };

            // check if we already have such challenge in store
            const currentChallenge = getDailyChallenge(getState());
            if (currentChallenge?._id === res?.challenge?.challenge) {
                dispatch(getCurrentDailyChallengeSuccess(payload));
                return;
            }

            dispatch(getChallengeDetailsById(res?.challenge?.challenge, res?.challenge?.game, (challengeRes) => {
                if (!challengeRes) return;
                dispatch(getCurrentDailyChallengeSuccess(payload));
            }));
        }).catch(err => {
            console.error('Failed to get current daily challenge: ', err.message);
            dispatch(getCurrentDailyChallengeError(err.message));
        });
    };
};

export const GET_CURRENT_DAILY_CHALLENGE_SUCCESS = 'GET_CURRENT_DAILY_CHALLENGE_SUCCESS';
export const getCurrentDailyChallengeSuccess = (payload) => ({
    type: GET_CURRENT_DAILY_CHALLENGE_SUCCESS, payload
});

export const GET_CURRENT_DAILY_CHALLENGE_ERROR = 'GET_CURRENT_DAILY_CHALLENGE_ERROR';
export const getCurrentDailyChallengeError = (error) => ({
    type: GET_CURRENT_DAILY_CHALLENGE_ERROR, error
});

export const GET_CHALLENGE_DETAILS_BY_ID_PENDING = 'GET_CHALLENGE_DETAILS_BY_ID_PENDING';
export const getChallengeDetailsById = (challengeId, gameId, onFinish) => {
    return (dispatch, getState) => {
        dispatch({type: GET_CHALLENGE_DETAILS_BY_ID_PENDING});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(GET_CHALLENGE_DETAILS_BY_ID_PENDING);

        const params = {
            headers: { Authorization: 'Bearer ' + authToken }
        };

        antstreamAPIService.v2.challengeDetail(challengeId, params).then(async ({ data }) => {
            if (!data) throw new Error('Something went wrong');
            if (data.error) throw new Error(data.error);

            const res = data.data.challenge || data.data;
            const challengesGamesFolder = getAssetsUrlWithFolder(getState(),FOLDER_TYPES.CHALLENGE_GAMES);

            res.img_src = challengesGamesFolder + res.coverImage;

            dispatch(getChallengeDetailsByIdSuccess(res));
            if (onFinish) onFinish(res);
        }).catch(err => {
            console.error('Failed to retrieve challenge details: ', err.message);
            if (onFinish) onFinish(null);
            dispatch(getChallengeDetailsByIdError(err.message));
        });
    };
};

export const GET_CHALLENGE_DETAILS_BY_ID_SUCCESS = 'GET_CHALLENGE_DETAILS_BY_ID_SUCCESS';
export const getChallengeDetailsByIdSuccess = (payload) => {
    return {type: GET_CHALLENGE_DETAILS_BY_ID_SUCCESS, payload};
};
export const GET_CHALLENGE_DETAILS_BY_ID_ERROR = 'GET_CHALLENGE_DETAILS_BY_ID_ERROR';
export const getChallengeDetailsByIdError = (payload) => {
    return {type: GET_CHALLENGE_DETAILS_BY_ID_ERROR, payload};
};

export const GET_DAILY_CHALLENGE_CONFIGURATION_PENDING = 'GET_DAILY_CHALLENGE_CONFIGURATION_PENDING';
export const getDailyChallengeConfiguration = () => {
    return (dispatch) => {
        dispatch({type: GET_DAILY_CHALLENGE_CONFIGURATION_PENDING});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(GET_DAILY_CHALLENGE_CONFIGURATION_PENDING);

        const params = {
            headers: { Authorization: 'Bearer ' + authToken }
        };

        antstreamAPIService.v2.dailyChallengesConfigurationList(params).then(async ({ data }) => {
            if (!data) throw new Error('Something went wrong');
            if (data.error) throw new Error(data.error);

            /**
             * @type {{
             *  _id: string,
             *  durationInDays: number,
             *  partySize: number,
             *  renewTimeOfDayGMT: string,
             *  playRewards: {GEMS: {amount: number}},
             *  prizes: {[number]: {GEMS: {amount: number}}},
             *  points: {
             *      bronze: number,
             *      silver: number,
             *      gold: number
             *  },
             *  active: boolean,
             *  createdAt: number
             * }}
             **/
            const res = data.data;

            dispatch(getDailyChallengeConfigurationSuccess(res));
        }).catch(err => {
            console.error('Failed to retrieve daily challenge configuration: ', err.message);
            dispatch(getDailyChallengeConfigurationError(err.message));
        });
    }
};

export const GET_DAILY_CHALLENGE_CONFIGURATION_SUCCESS = 'GET_DAILY_CHALLENGE_CONFIGURATION_SUCCESS';
export const getDailyChallengeConfigurationSuccess = (payload) => ({
    type: GET_DAILY_CHALLENGE_CONFIGURATION_SUCCESS, payload
});

export const GET_DAILY_CHALLENGE_CONFIGURATION_ERROR = 'GET_DAILY_CHALLENGE_CONFIGURATION_ERROR';
export const getDailyChallengeConfigurationError = (payload) => ({
    type: GET_DAILY_CHALLENGE_CONFIGURATION_ERROR, payload
});

export const GET_DAILY_CHALLENGE_PARTY_PENDING = 'GET_DAILY_CHALLENGE_PARTY_PENDING';
export const getDailyChallengeParty = (dailyChallengeEventId = 'current', isRetry = false) => {
    return (dispatch) => {
        dispatch({type: GET_DAILY_CHALLENGE_PARTY_PENDING});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(GET_DAILY_CHALLENGE_PARTY_PENDING);

        const params = {
            headers: { Authorization: 'Bearer ' + authToken }
        };

        antstreamAPIService.v2.dailyChallengePartyDetail(dailyChallengeEventId, params).then(async ({ data }) => {
            if (!data) throw new Error('Something went wrong');
            if (data.error) throw new Error(data.error);

            let rank = 1;
            const res = data.data.map((el, i) => {
                let rankVisible = '1';
                if (i !== 0) {
                    const prevPlayerScore = data.data[i - 1].points;
                    if (prevPlayerScore === el.points) {
                        rankVisible = `${rank}`;
                    } else {
                        rank = rank + 1;
                        rankVisible = `${rank}`;
                    }
                }

                return {
                    ...el,
                    _id: el.playerId,
                    rank: rankVisible,
                    profile_image_id: el.avatar,
                    rankForGems: rank,
                    score: el.points
                };
            });

            dispatch(getDailyChallengePartySuccess(res));
        }).catch(async err => {
            if (!isRetry) {
                await createDailyChallengeParty(dailyChallengeEventId, params);
                return dispatch(getDailyChallengeParty(dailyChallengeEventId, true));
            }

            console.error('Failed to retrieve daily challenge party: ', err.message);
            dispatch(getDailyChallengePartyError(err.message));
        });
    }
};

export const GET_DAILY_CHALLENGE_PARTY_SUCCESS = 'GET_DAILY_CHALLENGE_PARTY_SUCCESS';
export const getDailyChallengePartySuccess = (payload) => ({
    type: GET_DAILY_CHALLENGE_PARTY_SUCCESS, payload
});

export const GET_DAILY_CHALLENGE_PARTY_ERROR = 'GET_DAILY_CHALLENGE_PARTY_ERROR';
export const getDailyChallengePartyError = (payload) => ({
    type: GET_DAILY_CHALLENGE_PARTY_ERROR, payload
});

export const GET_DAILY_CHALLENGE_ACTIVITY_PENDING = 'GET_DAILY_CHALLENGE_ACTIVITY_PENDING';
export const getDailyChallengeActivity = () => {
    return (dispatch) => {
        dispatch({type: GET_DAILY_CHALLENGE_ACTIVITY_PENDING});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(GET_DAILY_CHALLENGE_ACTIVITY_PENDING);

        const params = {
            headers: { Authorization: 'Bearer ' + authToken }
        };

        antstreamAPIService.v2.dailyChallengesActivityList(params).then(async ({ data }) => {
            if (!data) throw new Error('Something went wrong');
            if (data.error) throw new Error(data.error);

            /**
             * @type {{
             *     lastEventHistory?: {
             *         dailyChallengesEventId: string,
             *         start: number,
             *         end: number,
             *         dailyChallengesEventId: string,
             *         stats: {rank: number, points: number}
             *     }
             *     currentEventHistory?: Object
             * }}
             **/
            const res = data.data;

            dispatch(getDailyChallengeActivitySuccess(res));
        }).catch(err => {
            console.error('Failed to retrieve daily challenge activity: ', err.message);
            dispatch(getDailyChallengeActivityError(err.message));
        });
    }
};

export const GET_DAILY_CHALLENGE_ACTIVITY_SUCCESS = 'GET_DAILY_CHALLENGE_ACTIVITY_SUCCESS';
export const getDailyChallengeActivitySuccess = (payload) => ({
    type: GET_DAILY_CHALLENGE_ACTIVITY_SUCCESS, payload
});

export const GET_DAILY_CHALLENGE_ACTIVITY_ERROR = 'GET_DAILY_CHALLENGE_ACTIVITY_ERROR';
export const getDailyChallengeActivityError = (payload) => ({
    type: GET_DAILY_CHALLENGE_ACTIVITY_ERROR, payload
});

export const PLAY_DAILY_CHALLENGE = 'PLAY_DAILY_CHALLENGE';
export const playDailyChallengeAction = (payload) => ({
    type: PLAY_DAILY_CHALLENGE, payload
});

export const ANIMATE_ON_FIRST_DAILY_CHALLENGE_PLAY = 'ANIMATE_ON_FIRST_DAILY_CHALLENGE_PLAY';
export const animateOnFirstDailyChallengePlayAction = (payload) => ({
    type: ANIMATE_ON_FIRST_DAILY_CHALLENGE_PLAY, payload
});

const createDailyChallengeParty = (id, params) => {
    return antstreamAPIService.v2.dailyChallengePartyUpdate(id, params);
};

export const GET_CHALLENGES_CATEGORIES_PENDING = 'GET_CHALLENGES_CATEGORIES_PENDING';
export const getChallengesCategories = () => {
    return (dispatch) => {
        dispatch({type: GET_CHALLENGES_CATEGORIES_PENDING});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(GET_CHALLENGES_CATEGORIES_PENDING);

        const params = {
            headers: { Authorization: 'Bearer ' + authToken }
        };

        antstreamAPIService.v2.challengesCategoriesList(params).then(async ({ data }) => {
            if (!data) throw new Error('Something went wrong');
            if (data.error) throw new Error(data.error);

            const res = data.data;

            dispatch(getChallengesCategoriesSuccess(res));
        }).catch(err => {
            console.error('Failed to retrieve daily challenge activity: ', err.message);
            dispatch(getChallengesCategoriesError(err.message));
        });
    }
};

export const GET_CHALLENGES_CATEGORIES_SUCCESS = 'GET_CHALLENGES_CATEGORIES_SUCCESS';
export const getChallengesCategoriesSuccess = (payload) => ({
    type: GET_CHALLENGES_CATEGORIES_SUCCESS, payload
});

export const GET_CHALLENGES_CATEGORIES_ERROR = 'GET_CHALLENGES_CATEGORIES_ERROR';
export const getChallengesCategoriesError = (payload) => ({
    type: GET_CHALLENGES_CATEGORIES_ERROR, payload
});

export const MOST_POPULAR_CHALLENGES_LIST_ID = 'top_10_challenges';

export const GET_CHALLENGES_BY_CATEGORY_PENDING = 'GET_CHALLENGES_BY_CATEGORY_PENDING';
export const getChallengesByCategory = () => {
    return async (dispatch, getState) => {
        dispatch({type: GET_CHALLENGES_BY_CATEGORY_PENDING});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(GET_CHALLENGES_BY_CATEGORY_PENDING);

        const params = {
            headers: { Authorization: 'Bearer ' + authToken }
        };

        const mostPop = await antstreamAPIService.v2.challengesMostPopularList({groupKey: '7days'}, params);
        /**
         * @type {Array<{
         *     _id: string,
         *     rank: number,
         *     game: {
         *         _id: string,
         *         title: string,
         *         description: string,
         *         coverImage: string,
         *     }
         *     challenge: {
         *         _id: string,
         *         title: string,
         *         coverImage: string,
         *         type: string,
         *         gameId: string
         *     }
         * }>}
         **/
        const mostPopList = mostPop?.data?.data?.challenges?.map((el, index) => ({
            ...el,
            isMostPopular: true,
            rank: el.rank <= 10 ? el.rank : index + 1
        }));

        antstreamAPIService.v2.challengesList({category: 'all'}, params).then(async ({ data }) => {
        // new Promise((resolve) => resolve({data: mockData})).then(async ({ data }) => {
            if (!data) throw new Error('Something went wrong');
            if (data.error) throw new Error(data.error);

            const challengesGamesFolder = getAssetsUrlWithFolder(getState(), FOLDER_TYPES.CHALLENGE_GAMES);
            const brochureContentsFolder = getAssetsUrlWithFolder(getState(), FOLDER_TYPES.BROCHURE_CONTENT_ASSETS);

            /**
             * @type {Array<{
             *     _id: string,
             *     isMostPopular?: boolean,
             *     rank?: number,
             *     game: {
             *         _id: string,
             *         title: string,
             *         description: string,
             *         coverImage: string,
             *     }
             *     challenge: {
             *         _id: string,
             *         title: string,
             *         coverImage: string,
             *         type: string,
             *         gameId: string
             *     }
             * }>}
             **/
            const res = mostPopList.concat(data.data);

            const grouped = {};
            res.forEach(el => {
                const key = el.isMostPopular
                    ? MOST_POPULAR_CHALLENGES_LIST_ID
                    : el?.challenge?.type;
                const modified = {
                    ...el,
                    title: el.challenge.title,
                    rank: el.rank || undefined,
                    unlocked: true,
                    tier: el.challenge._id?.split('Tier-')[1]?.split('-Challenge')[0],
                    gameImgSrc: brochureContentsFolder + el.game.coverImage,
                    gameTitle: el.game.title,
                    withBorders: true,
                    img_src: challengesGamesFolder + el.challenge.coverImage
                };

                if (grouped[key]) {
                    grouped[key].list.push(modified); 
                } else {
                    grouped[key] = {
                        challengeType: key,
                        list: [modified]
                    };
                }
            });

            dispatch(getChallengesByCategorySuccess(grouped));
        }).catch(err => {
            console.error('Failed to retrieve challenges by category list: ', err.message);
            dispatch(getChallengesByCategoryError(err.message));
        });
    }
};

export const GET_CHALLENGES_BY_CATEGORY_SUCCESS = 'GET_CHALLENGES_BY_CATEGORY_SUCCESS';
export const getChallengesByCategorySuccess = (payload) => ({
    type: GET_CHALLENGES_BY_CATEGORY_SUCCESS, payload
});

export const GET_CHALLENGES_BY_CATEGORY_ERROR = 'GET_CHALLENGES_BY_CATEGORY_ERROR';
export const getChallengesByCategoryError = (payload) => ({
    type: GET_CHALLENGES_BY_CATEGORY_ERROR, payload
});
