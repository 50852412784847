import {antstreamAPIService, antstreamService} from '../../app.reducer';
import {UpdateGamesData} from '../games/games.actions';
import {getAssetsUrlWithFolder} from '../../app.selectors';
import {FOLDER_TYPES} from '../../assets/lib/FolderTypes';
import {getPushServiceID} from "../../assets/lib/game-wrapper";
import {
	FetchTournamentsHistorySuccess
} from "../../components/tournaments/tournaments.actions";
import {readRestUserData} from "../../assets/lib/local-storage";
import {addPopup} from "../../components/popup/popup.component";
import GenericPopup from "../../components/popup/generic-popup/generic-popup.component";
import React from "react";
import deviceInfo from "../../assets/lib/deviceInfo";
import {handleNoAuthTokenIssue} from "../../assets/lib/utils";

export const GET_MY_USER_DETAILS_ACTION = 'Get My User Details Status Action';
export const GetMyUserDetailsAction = (onComplete) => {
	return (dispatch) => {
		dispatch({ type: GET_MY_USER_DETAILS_ACTION});

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(GET_MY_USER_DETAILS_ACTION);

			const params = {
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.profileDetails.profileDetailsList({}, params).then(({ data }) => {
				if (!data || !data.data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);

				dispatch(GetMyUserDetailsActionSuccess(data.data));
				if(onComplete) setTimeout(() => onComplete(data.data));
			}).catch(catchErr => {
				addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
			   	console.error('Failed to fetch profile details: ' + catchErr.message);
			});

	};
};

export const GET_MY_USER_DETAILS_ACTION_SUCCESS = 'Get My User Details Success';
export const GetMyUserDetailsActionSuccess = (payload) => {
	return (dispatch) => {
		dispatch(UpdateGamesData(payload.favouritedGames.games));
		dispatch(UpdateGamesData(payload.recentPlayedGames.games));
		dispatch(FetchTournamentsHistorySuccess(payload.tournamentHistory));
		dispatch({
			type: GET_MY_USER_DETAILS_ACTION_SUCCESS,
			payload
		});
	};
};

export const GET_OTHER_USER_DETAILS_ACTION = 'Get Other User Details Status Action';
export const GetOtherUserDetailsAction = (id,onComplete) => {
	return (dispatch) => {
		dispatch({ type: GET_OTHER_USER_DETAILS_ACTION, payload: id });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(GET_OTHER_USER_DETAILS_ACTION);

			const params = {
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.profileDetails.profileDetailsList({
				userId: id
			}, params).then(({ data }) => {
				if (!data || !data.data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);

				dispatch(GetOtherUserDetailsActionSuccess(data.data));
				if (onComplete) onComplete();
			}).catch(catchErr => {
				addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
			   	console.error('Failed to fetch profile details: ' + catchErr.message);
			});

	};
};

export const GET_OTHER_USER_DETAILS_ACTION_SUCCESS = 'Get Other User Details Success';
export const GetOtherUserDetailsActionSuccess = (payload) => {
	return (dispatch) => {
		dispatch(UpdateGamesData(payload.favouritedGames.games));
		dispatch(UpdateGamesData(payload.recentPlayedGames.games));
		dispatch(FetchTournamentsHistorySuccess(payload.tournamentHistory));
		dispatch({
			type: GET_OTHER_USER_DETAILS_ACTION_SUCCESS,
			payload
		});
	};
};

export const FOLLOW_USER_ACTION = 'Follow User Action';
export const FollowUserAction = (id) => {
	return (dispatch) => {
		dispatch({ type: FOLLOW_USER_ACTION, payload: id });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(FOLLOW_USER_ACTION);

			const params = {
				body: { followedId: id },
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.player.followCreate(params).then(({ data }) => {
				if (!data || data.result !== 'OK') throw new Error('Failed to follow user');
				if (data.error) throw new Error(data.error);

				dispatch(FollowUserActionSuccess(id));
			}).catch(catchErr => {
				dispatch(FollowUserActionFailed(id));
				addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" />);
				console.error(catchErr.message);
			});

	};
};

export const FOLLOW_USER_ACTION_SUCCESS = 'Follow User Success';
export const FollowUserActionSuccess = (id) => {
	return (dispatch) => {
		dispatch({ type: FOLLOW_USER_ACTION_SUCCESS, payload: id });
		// dispatch(GetOtherUserDetailsAction(id));
		// dispatch(GetMyUserDetailsAction());
	};
};
export const FOLLOW_USER_ACTION_FAILED = 'Follow User Failed';
export const FollowUserActionFailed = (id) => {
	return (dispatch) => {
		dispatch({ type: FOLLOW_USER_ACTION_FAILED, payload: id });
	};
};

export const UNFOLLOW_USER_ACTION = 'Unfollow User Action';
export const UnfollowUserAction = (id) => {
	return (dispatch) => {
		dispatch({ type: UNFOLLOW_USER_ACTION, payload: id });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(UNFOLLOW_USER_ACTION);

			const params = {
				body: { followedId: id },
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.player.unfollowCreate(params).then(({ data }) => {
				if (!data || data.result !== 'OK') throw new Error('Failed to unfollow user');
				if (data.error) throw new Error(data.error);

				dispatch(UnfollowUserActionSuccess(id));
			}).catch(catchErr => {
				dispatch(UnfollowUserActionFailed(id));
				addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" />);
				console.error(catchErr.message);
			});

	};
};

export const UNFOLLOW_USER_ACTION_SUCCESS = 'Unfollow User Success';
export const UnfollowUserActionSuccess = (id) => {
	return (dispatch) => {
		dispatch({ type: UNFOLLOW_USER_ACTION_SUCCESS, payload: id });
		// dispatch(GetOtherUserDetailsAction(id));
		// dispatch(GetMyUserDetailsAction());
	};
};

export const UNFOLLOW_USER_ACTION_FAILED = 'Unfollow User Failed';
export const UnfollowUserActionFailed = (id) => {
	return (dispatch) => {
		dispatch({ type: UNFOLLOW_USER_ACTION_FAILED, payload: id });
	};
};

export const FETCH_MY_ACHIEVEMENTS_ACTION = 'Fetch My Achievements Action';
export const FetchMyAchievementsAction = (onComplete) => {
	return (dispatch) => {
		dispatch({ type: FETCH_MY_ACHIEVEMENTS_ACTION });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(FETCH_MY_ACHIEVEMENTS_ACTION);

			const params = {
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.achievements.getAchievements(params).then(({ data }) => {
				if (!data || !data.data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);

				const resp = data.data;
				dispatch(FetchMyAchievementsActionSuccess(resp.achievements));
				dispatch(FetchTournamentsHistorySuccess(resp.tournamentHistory));
				if (onComplete) onComplete(resp);
			}).catch(catchErr => {
				addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
			   	console.error('Failed to fetch achievements list: ' + catchErr.message);
			});

	};
};

export const FETCH_MY_ACHIEVEMENTS_SUCCESS = 'Fetch My Achievements Success';
export const FetchMyAchievementsActionSuccess = (achievements) => {
	return (dispatch,getState) => {
		const achievementsFolder = getAssetsUrlWithFolder(getState(),FOLDER_TYPES.ACHIEVEMENTS);
		achievements.forEach((achievement)=>{
			achievement.img_src = achievementsFolder + achievement.img_src;
		});
		dispatch({type: FETCH_MY_ACHIEVEMENTS_SUCCESS, payload:achievements});
	};
};

export const GET_PUSH_SERVICE_ID_ACTION = 'GET_PUSH_SERVICE_ID_ACTION';
export const GetPushServiceIdAction = () => {
	return (dispatch) => {
		dispatch({ type: GET_PUSH_SERVICE_ID_ACTION });
		getPushServiceID();
	};
};

export const GET_PUSH_SERVICE_ID_ACTION_SUCCESS = 'GET_PUSH_SERVICE_ID_ACTION_SUCCESS';
export const GetPushServiceIdSuccess = (payload) => {
	return (dispatch) => {
		dispatch({ type: GET_PUSH_SERVICE_ID_ACTION_SUCCESS, payload });

		const { authToken, userId } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(GET_PUSH_SERVICE_ID_ACTION_SUCCESS);

			const params = {
				body: {
					action: 'register',
					pushId: payload.id,
					deviceOS: deviceInfo.osType,

					userId: userId // why userId is in the body?
				},
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.notifications.setPushIdCreate(params).then(({ data }) => {
				if (!data || data.result !== 'OK') throw new Error('Something went wrong');
				if (data.error || data.Error) throw new Error(data.error || data.Error);
			}).catch(catchErr => {
				const errMsg = 'Failed to set pushId';
				console.error(errMsg, catchErr);
			});

	};
};

export const CHANGE_PROFILE_IMAGE_SUCCESS = 'CHANGE_PROFILE_IMAGE_SUCCESS';
export const ChangeProfileImageActionSuccess = (profileImageId) => ({
	type: CHANGE_PROFILE_IMAGE_SUCCESS,
	payload: profileImageId
});
