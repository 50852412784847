export const getTournamentsList = state  => state.tournaments.list;
export const getCurrentTournamentDetailId = state => state.tournaments.currentId;
export const getCurrentTournamentData = state => state.tournaments.currentTournamentData;
export const getIsPlayerJoined = state => state.tournaments.isPlayerJoined;
export const getIsAddPlayerToTournamentPending = state => state.tournaments.isAddPlayerToTournamentPending;
export const getPlayers = state  => state.tournaments.players;
export const getTournamentHistory = state  => state.tournaments.tournamentHistory;
export const getFollowedPlayers = state  => state.tournaments.followedPlayers;
export const getLeaderBoardObject = state  => state.tournaments.leaderBoardObject;
export const getRewards = state  => state.tournaments.rewards;
export const getTournamentPopups = state  => state.tournaments.tournamentPopups;