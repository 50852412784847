import React from 'react';

import { Button } from './common-button.component';
import './challenge-button.component.less';

const ChallengeButton = (props) => {

    const displayGiantCount = props.giantCountNext && props.giantLimit && props.giantCountNext <= props.giantLimit;
    const giantLimitReached = props.giantCountNext && props.giantLimit && props.giantCountNext > props.giantLimit;

    return (
        <Button
            {...props}
            className={`${props.className} challenge-button ${displayGiantCount ? 'with-giant-count' : ''}`}
        >
            <div className="button-title">
                {props.title}
            </div>
            <div className="button-illus">
            </div>
            <div className="button-cost">
                {giantLimitReached &&
                    <div className={`button-cost-inner`}>
                        {`${props.giantLimit} in progress`}
                    </div>
                }
                {!giantLimitReached &&
                    <div className={`button-cost-inner ${props.cost > 0 ? 'gem-icon' : ''}`}>
                        {props.cost > 0 ? props.cost : 'FREE'}
                    </div>
                }
                {displayGiantCount && 
                    <div className="giant-count-block">
                        <div className="giant-count">{props.giantCountNext}</div>
                        of
                        <div className="giant-limit">{props.giantLimit}</div>
                    </div>
                }
            </div>
            {props.prizeText && <div className="button-prize">
                <div className="prize-label">
                    Prize
                </div>
                {props.isFreeTierUserV2 ?
                    <div className="prize-premium-only">
                        Premium Only
                    </div>
                    :
                    <div className="prize-value">
                        {props.prizeText}
                    </div>
                }
            </div>}
        </Button>
    );
};

export { ChallengeButton };
