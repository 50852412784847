import React from 'react';
import './share-button.component.less'
import {Button} from '../button/common-button.component'
import shareIcon from '../../../assets/images/generic-icons/share-icon-back.svg'
import { addPopup } from '../../popup/popup.component';
import ShareModal, {SOCIAL_MEDIA_TYPES} from './share-modal/share-modal.component';
import deviceInfo, {OS_TYPE_ANDROID} from '../../../assets/lib/deviceInfo';
import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import { sendSocialSharingToNative } from '../../../assets/lib/game-wrapper';

const defaultShareButtons = [
    SOCIAL_MEDIA_TYPES.FACEBOOK,
    SOCIAL_MEDIA_TYPES.TWITTER,
    // SOCIAL_MEDIA_TYPES.EMAIL
]

export const ShareButton = ({shareButtons = defaultShareButtons, deepLink, text="Come play some retro games and see if you can beat my score!"}) => {
    // don't show on set top boxes or atari
    if (!shareButtons.length > 0|| !deepLink || !featureInfo.isSupported(FEATURES.SHARE_BUTTON)) {
        return null  
    }

	return (
		<Button
            className="share-button secondary"
            childId="share-button"
            onClick={
                () => {
                    if(deviceInfo.osType === OS_TYPE_ANDROID) sendSocialSharingToNative(text, deepLink)
                     else {
                        addPopup(<ShareModal
                            shareButtons={shareButtons}
                            deepLink={deepLink}
                            text={text}
                        />);
                    }
                }
            }
		>
            <div className="share-button-content-container">
                <img className="share-icon" src={shareIcon} alt="share"/>
                <div className="text-container">
                    Share
                </div>
            </div>
		</Button>
    )
}
export default ShareButton;