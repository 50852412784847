import React from 'react'
import { useSelector } from 'react-redux';
import GenericContainer, { externalFocusableComponents } from '../common/generic-container/generic-container.component';
import { BUTTON_STYLES } from '../common/button/common-button.component';
import { closePopup, addPopup } from '../popup/popup.component';
import { startPaymentProcess } from '../../app.actions';
import { navigateBack, navigateToLocation, ROUTES } from '../../app.router';
import purchaseGemsIcon from '../../assets/images/generic-icons/purchase-gems.svg';
import { ButtonWithGem } from '../common/button/common-button-with-gem.component';
import { getGemGainPerVideoWatch, getLoggedUser } from '../../app.selectors';
import './gem-payment-popup.component.less';
import PlayerProfileGems from '../common/player-profile/player-profile-gems.component';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import deviceInfo, { STORE_TYPE_EPIC, STORE_TYPE_GOOGLE, STORE_TYPE_SAMSUNG_TV } from '../../assets/lib/deviceInfo';
import { isFreeTierV2FeatureFlagEnabled, openShopPopupOrEpicLauncher } from '../../assets/lib/utils';
import ShopPopup from '../../components/popup/shop-popup/shop-popup.component';


const videoAdsSupportedData = {
    topText: 'You are on the free subscription which needs gems to play. Play now by watching an ad.',
    bottomText: 'You can earn gems any time by clicking your gems icon. Also earn gems by logging in each day, obtaining medals in the solo challenges, battle for gems in VS challenges or making the Top 10 in tournaments.'
}

const notvideoAdsSupportedData = {
    topText: 'You are on the free subscription which needs gems to play.',
    bottomText: 'You can earn gems by logging in each day, obtaining medals in the solo challenges, battle for gems in VS challenges or making the Top 10 in tournaments.'
}

const notvideoAdsSupportedDataV2 = {
    topText: 'You are on the free subscription which needs gems to play.',
    bottomText: 'You can earn gems by logging in each day, obtaining medals in the solo challenges, battle for gems in VS challenges or making the Top 10 in tournaments.'
};

const epicStoreUsersData = {
    topText: 'You are on the free subscription which needs gems to play.',
    bottomText: 'You can purchase extra gems in the store. Also earn gems by logging in each day, obtaining medals in the solo challenges, battle for gems in VS challenges or making the Top 10 in tournaments.'
}

const navLevel = 2;
const parentId = 'gem-payment-popup';

const GemPaymentPopup = () => {
    const user = useSelector((state) => getLoggedUser(state))
    const isFreeTierV2FeatureEnabled = useSelector((state) => isFreeTierV2FeatureFlagEnabled(state))
    const { currencies } = user;
    const { GEMS } = currencies;
    const gemGainPerVideo = useSelector(state => getGemGainPerVideoWatch(state))
    const supportsVideoAds = featureInfo.isSupported(FEATURES.VIDEO_ADS)
    const isEpicStore = (deviceInfo.storeType === STORE_TYPE_EPIC);
    const isGoogleStore = deviceInfo.storeType === STORE_TYPE_GOOGLE;
    let { topText, bottomText } = isEpicStore ?
        epicStoreUsersData :
        supportsVideoAds ? videoAdsSupportedData : notvideoAdsSupportedData;
    
    if (isFreeTierV2FeatureEnabled) {
        topText = isEpicStore
            ? epicStoreUsersData.topText
            : notvideoAdsSupportedDataV2.topText;
        bottomText = isEpicStore
            ? epicStoreUsersData.bottomText
            : notvideoAdsSupportedDataV2.bottomText;
    }

    const buttons = [
        {
            key: 'got-it',
            label: "Got it",
            handleClick: () => {
                closePopup()
                navigateBack();
            },
            childId: 'got-it',
            className: `${BUTTON_STYLES.SECONDARY} full-height fixedWidth`,
            navLayerValue: navLevel,
            style: { minWidth: '10em' }
        }
    ];

    if (!deviceInfo.isPlayStationPlatform() && !isEpicStore && deviceInfo.storeType !== STORE_TYPE_SAMSUNG_TV)  {
        buttons.push({
            key: 'subscribe',
            label: "Subscribe",
            handleClick: () => {
                closePopup()
                startPaymentProcess()

                if (isGoogleStore) return;

                navigateBack();
            },
            childId: 'subscribe-button',
            className: `${BUTTON_STYLES.PRIMARY} full-height fixedWidth`,
            navLayerValue: navLevel,
            style: { minWidth: '10em' }
        });
    } else if (!deviceInfo.isPlayStationPlatform()) {
        buttons.push({
            key: 'watch-ad',
            label: "Buy Gems",
            handleClick: () => {
                addPopup(<ShopPopup />)
                closePopup();
                navigateBack();
            },
            childId: 'watch-ad',
            className: `${BUTTON_STYLES.PRIMARY} full-height fixedWidth}`,
            navLayerValue: navLevel,
            style: { minWidth: '10em', color: 'red' }
        });
    }

    return (
        <GenericContainer
            title="Not enough gems!"
            customBackFunction={() => {
                closePopup()
                navigateBack();
                }
            }
            showPopupBackground={true}
            focusParentId={externalFocusableComponents.BUTTON_CONTAINER}
            focusElement='watch-ad'
            navLayerValue={navLevel}
            buttonContainerClass='flex-end'
            buttons={buttons}
        >
            <div className="Gem-Payment-Popup">
                <p className="Gem-Payment-Popup-top-text">{topText}</p>
                <div className="Gem-Payment-Popup-center-content">
                    {!isFreeTierV2FeatureEnabled && supportsVideoAds && <>
                        <PlayerProfileGems
                            gems={GEMS}
                            showVideoPlus={true}
                            handleShopButtonClick={() => {
                                openShopPopupOrEpicLauncher()
                                closePopup();
                                navigateBack();
                            }}
                            parentId={parentId}
                            navLayer={navLevel}
                        />
                        <p className="Gem-Payment-Popup-equals">=</p>
                    </>
                    }
                    <img className="Gem-Payment-Popup-Purchase-Gems-Icon" src={purchaseGemsIcon} alt="" />
                </div>
                <p className="Gem-Payment-Popup-bottom-text">{bottomText}</p>
            </div>

        </GenericContainer>
    )
}

export default GemPaymentPopup
