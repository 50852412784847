import React, { Component } from 'react';
import TileGame from '../tiles/tile-game.component';
import { connect } from 'react-redux';
import {selectedListIndex, selectedTileId} from '../../../app.selectors';
import {setSelectedListIndex, setSelectedTileId} from '../../../app.actions';
import inputmanager from '../../../assets/lib/inputmanager';
import AudioManager, {TYPE_VERTICAL_LIST_ROW_CHANGE} from '../../../app.audio';

class Tile extends Component {
	componentWillUnmount() {
		if(this.props.selected) this.setSelectedTile(null,null);
	}

	shouldComponentUpdate(nextProps) {
		if (this.props.shouldComponentForceUpdate) return true;

		return this.props.selected !== nextProps.selected
			|| this.props.outerInViewUsed !== nextProps.outerInViewUsed
			|| this.props.outerInViewStatus !== nextProps.outerInViewStatus;
	}

	onMouseOver = () => {
	};

	onMouseOut = () => {
	};

	onCustomFocus = (focus/*, nextElement*/) => {
		if(focus) {
			this.setSelectedTile(this.props.listId,this.props.item._id);
			if(this.props.onTileFocus) this.props.onTileFocus(this.props.item._id, this.props.listId, this.props.index);
		} else if(!focus) {
			if(this.props.selected) this.setSelectedTile(null,null);
			if(this.props.onTileBlur) this.props.onTileBlur(this.props.item._id, this.props.listId);
		}
	};

	onClickSelect = () => {
		if(!this.isMoved) {//work after scroll
			if (!this.props.selected) {
				AudioManager.playAudio(TYPE_VERTICAL_LIST_ROW_CHANGE);
			}
			inputmanager.setCurrentChildById(this.props.item._id,this.props.listId);
			this.setSelectedTile(this.props.listId,this.props.item._id);

			if(this.props.onTileClick) this.props.onTileClick(this.props.item._id, this.props.listId, this.props.item.hasChallenges);
		}

		this.isMoved = false;
	};

	setSelectedTile = (listId, tileId) => {
		this.props.dispatch(setSelectedListIndex(listId));
		this.props.dispatch(setSelectedTileId(tileId));
	};

	onTouchEnd = () => {
		this.isMoved = false;
	};

	onTouchMove = () => {
		this.isMoved = true;
	};

	renderContent = (props) => {
		const { item } = props;
		const { renderer, listLength, index } = this.props;

		if (renderer && renderer(props)) {
			return renderer(props);
		}
		return (
			<TileGame position={index} listLength={listLength} selected={this.props.selected} item={item} onLoad={this.handleImageLoaded} outerInViewStatus={this.props.outerInViewStatus} outerInViewUsed={this.props.outerInViewUsed} />
		);
	};

	onTileRef = (element) => {
		this.isMoved = false;

		inputmanager.onChildRef(element, this.props.item._id, this.onCustomFocus, this.props.listId);
		this.props.onTileRef(this.props.item._id, element);
	};

	render() {
		return (
			<div
				tabIndex={0}
				ref={this.onTileRef}
				className={`tile`}
				onClick={this.onClickSelect}
				onTouchEnd={this.onTouchEnd}
				onTouchMove={this.onTouchMove}
				onMouseOver={this.onMouseOver}
				onMouseOut={this.onMouseOut}
			>
				{this.props.listId === 'Trending Now' &&
					<div className='trending-index'>
						<div className='trending-index-text'>
							{this.props.index + 1}
						</div>
					</div>
				}
				{this.renderContent(this.props)}
			</div>
		);
	}
}

const mapStateToProps = (state,ownProps) => {
	const selected = selectedListIndex(state)===ownProps.listId && selectedTileId(state)===ownProps.item._id;

	return {
		selected
	};
};

export {Tile};
export default connect(mapStateToProps)(Tile);
