import {getAssetsUrlWithFolder} from '../../app.selectors';
import {FOLDER_TYPES} from '../../assets/lib/FolderTypes';

export const FETCH_BADGES_ACTION_SUCCESS = 'Fetch All Badges Success';
export const FetchBadgesSuccessAction = (badges) => {
	return (dispatch,getState) => {
		badges = appendAssetsUrlToBadges(badges,getState());
		dispatch({
			type: FETCH_BADGES_ACTION_SUCCESS, payload:badges
		});
	};
};

const appendAssetsUrlToBadges = (badgeObjects, state) => {
	const appendedBadges = [];
	const challengeMedalsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.CHALLENGE_MEDALS);

	badgeObjects.forEach(badge => {
		appendedBadges.push({
			...badge,
			outcomes:{
				win: challengeMedalsFolder + badge.outcomes.win,
				loss: challengeMedalsFolder + badge.outcomes.loss,
				draw: challengeMedalsFolder + badge.outcomes.draw,
			}
		})
	});

	return appendedBadges;
};