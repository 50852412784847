import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../../app.router';
import {FetchUserChallenges, UpdateMyMenuSelection} from '../my-profile.actions';
import { GetMyUserDetailsAction } from '../../../entities/users/users.actions';
import {getMyUserRecentGames,getMyUserDetails} from '../../../entities/entities.selectors';
import { SetTutorialId } from '../../tutorial/tutorial.actions.js';
import './my-profile-info.component.less';
import {getChallengesHistory, getChallengesHistoryUpdateId, getIssuedChallenges} from '../my-profile.selectors';
import {findChallengeHistoryObject, SetSelectedChallengeHistory} from '../../challenge/challenge.actions';
import inputmanager, { PARENT_ENTRANCE} from '../../../assets/lib/inputmanager';
import TileChallengeHistory from '../../common/tiles/tile-challenge-history.component';
import ListContainer, {LIST_TYPES} from '../../common/list/list-container.component';

import UserProfileStats from '../../user-profile/user-profile-stats.component';
import {getTournamentHistory} from "../../tournaments/tournaments.selector";
import TileTournamentHistory from "../../common/tiles/tile-tournament-history.component";
import {externalFocusableComponent} from "../../game-details/info/game-details-info.component"
import {externalFocusableComponent as externalFocusableLeaderboardComponent} from "../../leaderboard/leaderboard.component";
import {externalFocusableComponent as externalFocusableSettingsComponent } from '../../settings/account/settings-account.component'
import {getIsPostAuthFetched, getUserIsSubscribed} from "../../../app.selectors";
import {isFreeTierV2FeatureFlagEnabled, isXboxUser} from "../../../assets/lib/utils";
import {XboxGamerCardButton} from "../../common/xbox-gamer-card-button/xbox-gamer-card-button.component";
import deviceInfo from '../../../assets/lib/deviceInfo';

const PAGE_ID = 'user_profile_1';

class MyProfileInfoComponent extends Component {
	constructor(props){
		super(props);

		this.state = {
			lists:[],
		};

		this.recentlyPlayedTitle = 'Recently Played';
		this.tournamentHistoryTitle = 'Tournament History';
	}

	componentDidMount() {
		this.props.dispatch(FetchUserChallenges());
		this.props.dispatch(GetMyUserDetailsAction(this.onListsFetched));
		this.props.dispatch(UpdateMyMenuSelection({title: 'Profile', route: ROUTES.MY_PROFILE_INFO}));
		this.props.dispatch(SetTutorialId(PAGE_ID));
		inputmanager.setCurrentChildById('tab-menu-Profile', 'tab-menu');
	}

	componentDidUpdate(prevProps){
		if(this.props.challengesHistoryUpdateId !== prevProps.challengesHistoryUpdateId){
			inputmanager.refreshBoundingRects();

			// sometimes refreshBoundingRects removes focus from the profileButton that become an issue for xbox.
			// so if no focus - focus on profileButton
			if (!inputmanager.currentChild) {
				inputmanager.setCurrentChildById('profileButton');
			}
		}
	}

	onEditProfileFocusElement = () => {
		return this.props.isXboxUser || deviceInfo.isPlayStationPlatform()
			? externalFocusableSettingsComponent.CHANGE_AVATAR_BUTTON
			: externalFocusableSettingsComponent.NAME_INPUT
		;
	};

	onEditProfile = () => {
		navigateToLocation(ROUTES.SETTINGS_ACCOUNT,{focusElement: this.onEditProfileFocusElement()} );
	};

	onSelectGame = (gameId, hasChallenges) => {
		// free users not allowed to play games except challenges
		if (this.props.isFreeTierV2FeatureEnabled && this.props.isPostAuthFetched && !this.props.isUserSubscribed && hasChallenges) {
			navigateToLocation(ROUTES.GAME_CHALLENGES, {id: gameId, focusElement: 'initialChallenge'});
			return;
		}

		navigateToLocation(ROUTES.GAME_INFO, {id: gameId, focusElement: externalFocusableComponent.PLAY_BUTTON});
	};

	onSelectChallenge = (itemId) => {
		const historyObject = this.props.dispatch(findChallengeHistoryObject(itemId));
		this.props.dispatch(SetSelectedChallengeHistory(historyObject));
	};

	onListsFetched = () => {
		const lists = [];
		const {recentGames, challengesHistory, issuedChallenges, tournamentHistory} = this.props;
		const recentChallenges = [...issuedChallenges,...challengesHistory];

		const defaultValuesForList = {
			type: LIST_TYPES.HORIZONTAL,
			renderer: (props) => {
				if (props.item.gameId) {//this is a challenge
					return <TileChallengeHistory {...props} />;
				}else if(props.item.tournamentObject){
					return <TileTournamentHistory {...props} />;
				}else{
					return null;
				}
			},
			parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL
		}
		if (tournamentHistory && tournamentHistory.length > 0) {
			lists.push({
				'id': this.tournamentHistoryTitle,
				'title': this.tournamentHistoryTitle,
				'data': tournamentHistory,
				...defaultValuesForList
			});
		}

		if (recentGames && recentGames.length > 0) {
			lists.push({
				'id': this.recentlyPlayedTitle,
				'title': this.recentlyPlayedTitle,
				'data': recentGames,
				...defaultValuesForList
			});
		}

		if (recentChallenges && recentChallenges.length > 0) {
			lists.push({
				'id':'Recent Challenges',
				'title': 'Recent Challenges',
				'data': recentChallenges,
				...defaultValuesForList,
				type: deviceInfo.isPlayStationPlatform() ? LIST_TYPES.GRID : LIST_TYPES.HORIZONTAL
			});
		}

		this.setState({lists});
	};

	onItemSelected = (itemId, listIndex, hasChallenges) => {
		if(listIndex===this.recentlyPlayedTitle) {
			this.onSelectGame(itemId, hasChallenges);
		}else if(listIndex ===this.tournamentHistoryTitle) {
			const selectedTournamentItem = this.props.tournamentHistory.find(item=>item._id===itemId);
			navigateToLocation(ROUTES.TOURNAMENTS_LEADERBOARD, {id:selectedTournamentItem.tournamentObject._id, focusElement: externalFocusableLeaderboardComponent.PLAY_BUTTON});
		}else {
			this.onSelectChallenge(itemId);
		}
	};

	getXuidFromDetails = (details) => {
		return details
			&& details.externalIds
			&& details.externalIds['XBL']
			&& details.externalIds['XBL'].xuid;
	};

	render() {
		const {details, isXboxUser} = this.props;
		const xuid = this.getXuidFromDetails(details);

		return (
			<div className="my-profile-info">
				{isXboxUser && details && <XboxGamerCardButton xboxUserId={xuid} />}

				<div className="top-container">
					<UserProfileStats
						details={details}
						onEditProfile={this.onEditProfile}
					/>
				</div>

				{ this.state.lists && this.state.lists.length > 0 &&
				<ListContainer
					lists={this.state.lists}
					onTileClick={this.onItemSelected}
				/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const userDetails = getMyUserDetails(state);
	const challengesHistoryUpdateId = getChallengesHistoryUpdateId(state);

	return {
		isXboxUser: isXboxUser(state),
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		details:userDetails,
		recentGames: getMyUserRecentGames(state),
		issuedChallenges: getIssuedChallenges(state),
		challengesHistory: getChallengesHistory(state),
		tournamentHistory: getTournamentHistory(state),
		challengesHistoryUpdateId,
	};
};

export const MyProfileInfo = connect(mapStateToProps)(MyProfileInfoComponent);

