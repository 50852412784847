import axios from 'axios'
import { consoleLogWithTimestamp } from './utils';

if (window.config.REACT_APP_ENV === 'dev' || window.config.REACT_APP_ENV === 'stg') {
	axios.interceptors.request.use(request => {
		// console.log('Starting Axios Request' + JSON.stringify(request, null, 2));
		return request;
	});
	axios.interceptors.response.use(response => {
		// console.log('Axios Response: ' + JSON.stringify(response, null, 2));
		return response;
	});
}

export const getTrueAuthCredentials = (authCode, deviceId, deviceModel, ip, latitude, longitude) => {
	const payload = {
		action: "getLoginCredentials",
		authCode,
		deviceId,
		deviceModel,
		ip,
		latitude,
		longitude
	};
	return new Promise(function(resolve, reject) {
		axios.post('/api/callbacks/3rdparties/true/login', payload).then((response) => {
			resolve(response.data.data);
		}).catch(error =>{
			reject(error);
		});
	});
}

/**
 * Attempts to login with Epic Games in our backend.
 * Requires one of the two tokens (authToken or accessToken), but accessToken is preferred.
 * If accessToken is provided, a refreshToken must also be sent
 * @param {string} authCode
 * @param {string} accessToken
 * @param {string} refreshToken
 * @returns {Promise}
 */
export const getEpicGamesCredentials = (authCode, accessToken, refreshToken) => {
	let payload = null;
		payload = {
			provider: "EOS",
			operation: "login",
			data: {
				action: "getLoginCredentials",
				authCode,
				accessToken,
				refreshToken,
			}
		};


	return new Promise(function(resolve, reject) {
		const url = `${window.config.REACT_APP_ANTSTREAM_API_BASE_URL}/${window.config.REACT_APP_ANTSTREAM_API_STAGE}/login`;
		axios.post(url, payload).then((response) => {
			// FUTURE: this error should not need to be here, it is due to GameSparks
			if (response.data.errors) {
				reject(response.data);
				return;
			}

			resolve(response.data.data || response.data);
		}).catch(error =>{
			reject(error);
		});
	});
}

/**
 * this method is used for testing only, because on production it should work only via Native client
 * @param {string} authCode
 * @returns {Promise}
 */
export const getXboxCredentialsFromAntsparks = (authCode) => {
	const payload = { authenticationCode: authCode };

	return new Promise(function(resolve, reject) {
		const url = `${window.config.REACT_APP_ANTSTREAM_API_BASE_URL}/${window.config.REACT_APP_ANTSTREAM_API_STAGE}/v2/auth/external/xbl/login`;
		axios.post(url, payload).then((response) => {
			if (response.data.errors) {
				reject(response.data);
				return;
			}

			resolve(response.data.data || response.data);
		}).catch(error =>{
			reject(error);
		});
	});
}

/**
 * Get PS credentials from AOS
 * @param {string} authCode
 * @param {string} redirectUri
 * @returns {Promise}
 */
export const getPlayStationCredentialsFromAntsparks = async (authCode, redirectUri) => {
	const payload = { authenticationCode: authCode, redirectURI: redirectUri };
	const { REACT_APP_ANTSTREAM_API_BASE_URL, REACT_APP_ANTSTREAM_API_STAGE } = window.config; 
	const url = `${REACT_APP_ANTSTREAM_API_BASE_URL}/${REACT_APP_ANTSTREAM_API_STAGE}/v2/auth/external/psn/login`;

	let response = null;
	if (window.config.REACT_APP_ENV === 'dev') {
		watchdog(1);
		consoleLogWithTimestamp('getPlayStationCredentialsFromAntsparks_1');
		const batch = await Promise.all([
			watchdog(2),
			fetch(url, {
				method: 'POST',
				body: JSON.stringify(payload),
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				}
			}),
			watchdog(3)
		]);
		watchdog(4);
		response = batch[1];
		watchdog(5);
		if (!response.ok) {
			throw new Error(`getPlayStationCredentialsFromAntsparks Response status: ${response.status}`);
		}
		watchdog(6);
		response = await response.json();
		watchdog(7);
		return response.data || response;
	} else {
		response = await axios.post(url, payload);
	}

	if (response.data.errors) throw new Error(response.data);

	return response.data.data || response.data;
}

export function getNoCors(requestNumber) {
	consoleLogWithTimestamp(`getNoCors request ${requestNumber} started.`);
	return fetch('https://get.geojs.io/v1/ip/country.json')
		.then((res) => {
			console.log(res);
			consoleLogWithTimestamp(`getNoCors request ${requestNumber} finished.`);
		})
		.catch(err => {
			consoleLogWithTimestamp(`getNoCors request ${requestNumber} finished with ERROR: ${err}.`);
		})
}

function watchdog(id) {
	consoleLogWithTimestamp(`watchdot ${id}`);
}
