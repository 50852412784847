import React from 'react';

import ShareModalButton from './share-modal-button/share-modal-button.component';
import shareFacebookIcon from '../../../../assets/images/generic-icons/share-facebook.svg';
import shareTwitterIcon from '../../../..//assets/images/generic-icons/share-twitter.svg';
import shareEmailIcon from '../../../../assets/images/generic-icons/share-email.svg';
import shareCopyAndPasteIcon from '../../../../assets/images/generic-icons/share-copy.png';
import GenericContainer, {MESSAGE_SIZE, BUTTONS_LABELS, externalFocusableComponents as genericContainerFocusable} from '../../generic-container/generic-container.component';
import { Input } from '../../input/input.component';
import { Button, BUTTON_STYLES } from '../../button/common-button.component';
import { sendCopyTextToNative } from '../../../../assets/lib/game-wrapper';
import deviceInfo, { isDeviceSafari } from '../../../../assets/lib/deviceInfo';
import './share-modal.component.less'
import { closePopup } from '../../../popup/popup.component';
import { PARENT_ENTRANCE, useInputManagerOnParent } from '../../../../assets/lib/inputmanager';
import { useState } from 'react';

const parentId = "shareModal"
const navLevel = 2
export const SOCIAL_MEDIA_TYPES = {
    FACEBOOK: "FACEBOOK",
    TWITTER: "TWITTER",
    EMAIL: "EMAIL",
    // TUMBLR: "TUMBLR",
}

export const externalFocusableComponents = {
    CANCEL: 'share_modal_cancel',
};

export const SOCIAL_MEDIA_TYPES_INFORMATION = {
    FACEBOOK: {
        icon: shareFacebookIcon,
        urlGenerator: (deeplink, text) => {
            const urlPrefix = 'https://facebook.com/sharer/sharer.php?u='
            const link = `${urlPrefix}${deeplink}`
            return link
        },
        alt: 'Share on Facebook',
        ariaLabel: 'Share on Facebook',
    }, // https://developers.facebook.com/docs/sharing/webmasters#markup
    TWITTER: {
        icon: shareTwitterIcon,
        urlGenerator: (deeplink, text) => {
            return`https://twitter.com/intent/tweet/?text=${text}&url=${deeplink}&via=AntstreamArcade`
        },
        alt: 'Share on Twitter',
        ariaLabel: 'Share on Twitter',
    },
    'EMAIL': {
        icon:shareEmailIcon,
        urlGenerator: (deeplink, text) => {
            return `mailto:?subject=${text}&body=${deeplink}`
        },
        alt: 'Share by email',
        ariaLabel: 'Share by email',
    },
    // TUMBLR: {
    //     icon:'',
    //     urlGenerator: (deeplink, text) => {
    //     const urlPrefix = ''
    //     const link = ""
    //     },
    //     alt: 'Share on Tumbler',
    //     'aria-label': 'Share on Tumbler',
    // },
}

export const ShareModal = ({shareButtons = [], deepLink, text, handleBack}) => {
    const shareModalRef = useInputManagerOnParent(parentId, true, PARENT_ENTRANCE.ONLY_VERTICAL, 2)
    const [isCopiedToClipboard, setIsCopiedToClipBoard] = useState(false)

    var clipboardToIE11Formatting = {
        "text/plain": "Text",
        "text/html": "Url",
        "default": "Text"
    }

    var defaultMessage = "Copy to clipboard: #{key}, Enter";
    function format(message) {
        var copyKey = (/mac os x/i.test(navigator.userAgent) ? "⌘" : "Ctrl") + "+C";
        return message.replace(/#{\s*key\s*}/g, copyKey);
    }

    function copy(text, options) {
        var debug,
            message,
            range,
            selection,
            mark,
            success = false;
        if (!options) {
            options = {};
        }
        debug = options.debug || false;
        try {

            range = document.createRange();
            selection = document.getSelection();

            mark = document.createElement("span");
            mark.textContent = text;
            // reset user styles for span element
            mark.style.all = "unset";
            // prevents scrolling to the end of the page
            mark.style.position = "fixed";
            mark.style.top = 0;
            mark.style.clip = "rect(0, 0, 0, 0)";
            // used to preserve spaces and line breaks
            mark.style.whiteSpace = "pre";
            // do not inherit user-select (it may be `none`)
            mark.style.webkitUserSelect = "text";
            mark.style.MozUserSelect = "text";
            mark.style.msUserSelect = "text";
            mark.style.userSelect = "text";
            mark.addEventListener("copy", function (e) {
                e.stopPropagation();
                if (options.format) {
                    e.preventDefault();
                    if (typeof e.clipboardData === "undefined") { // IE 11
                        debug && console.warn("unable to use e.clipboardData");
                        debug && console.warn("trying IE specific stuff");
                        window.clipboardData.clearData();
                        var format = clipboardToIE11Formatting[options.format] || clipboardToIE11Formatting["default"]
                        window.clipboardData.setData(format, text);
                    } else { // all other browsers
                        console.log(text);
                        e.clipboardData.clearData();
                        e.clipboardData.setData(options.format, text);
                    }
                }
                if (options.onCopy) {
                    e.preventDefault();
                    options.onCopy(e.clipboardData);
                }
            });

            document.body.appendChild(mark);

            range.selectNodeContents(mark);
            selection.addRange(range);

            var successful = document.execCommand("copy");
            if (!successful) {
                throw new Error("copy command was unsuccessful");
            }
            success = true;
        } catch (err) {
            debug && console.error("unable to copy using execCommand: ", err);
            debug && console.warn("trying IE specific stuff");
            try {
                window.clipboardData.setData(options.format || "text", text);
                options.onCopy && options.onCopy(window.clipboardData);
                success = true;
            } catch (err) {
                debug && console.error("unable to copy using clipboardData: ", err);
                debug && console.error("falling back to prompt");
                message = format("message" in options ? options.message : defaultMessage);
                window.prompt(message, text);
            }
        } finally {
            if (selection) {
                if (typeof selection.removeRange == "function") {
                    selection.removeRange(range);
                } else {
                    selection.removeAllRanges();
                }
            }

            if (mark) {
                document.body.removeChild(mark);
            }
        }

        return success;
    }

    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(
                () => {
                    console.log('Async: Copying to clipboard was successful!');
                },
                (err) => {
                    console.error('Async: Could not copy text: ', err);
                }
            );
        } else {
            // fallback to old method
            copy(text);
        }
    }

    return (
        <GenericContainer 
            title="Share"
            message="Where would you like to share to?"
            buttons={[
                {
                    key: BUTTONS_LABELS.BACK,
                    label: BUTTONS_LABELS.BACK,
                    handleClick: () => {
                        closePopup()
                        handleBack && handleBack()
                    },
                    
                    childId: externalFocusableComponents.CANCEL,
                    className: `${BUTTON_STYLES.SECONDARY} ShareModal-Back-Button` ,
                    navLayerValue: navLevel
                }
            ]}
            customBackFunction={ () => closePopup()}
            showPopupBackground={true}
            messageSize={MESSAGE_SIZE.SMALL}
            focusParentId={genericContainerFocusable.BUTTON_CONTAINER}
            focusElement={shareButtons[0] || externalFocusableComponents.CANCEL}
            navLayerValue={navLevel}
        >{
            <div className="ShareModal" ref={shareModalRef}>
                <div 
                    className="ShareModal-Buttons"
                >
                    {shareButtons && shareButtons.map((id) => {
                        const socialMediaButtonInformation = SOCIAL_MEDIA_TYPES_INFORMATION[id];
                        if(!socialMediaButtonInformation) return undefined;
                        const {urlGenerator, ...rest} = socialMediaButtonInformation;
                        const url = urlGenerator(encodeURIComponent(deepLink), encodeURIComponent(text))
                        return (
                            <ShareModalButton
                                key={id}
                                childId={id}
                                url={url}
                                parentId={parentId}
                                layer={navLevel}
                                {...rest}
                            />
                        )
                    })}
                </div>
                
                <div className="ShareModal-CopyAndPaste-container">
                    <Input 
                        className="ShareModal-CopyAndPaste-text-container" 
                        placeholder={deepLink}
                        disabled={true}
                    />
                    <img 
                        className="ShareModal-CopyAndPaste-icon"
                        src={shareCopyAndPasteIcon}
                        alt=""
                    />
                    <Button
                        rawButtonClick={isDeviceSafari}
                        className={`${BUTTON_STYLES.PRIMARY} ShareModal-CopyAndPaste-button`}
                        onClick={() => {
                            setIsCopiedToClipBoard(true)
                            if (deviceInfo.isRunningOnNative()) {
                                sendCopyTextToNative(deepLink);
                            } else {
                                copyToClipboard(deepLink);
                            }
                        }}
                        childId="COPY"
                        parentId={parentId}
                        layer={navLevel}
                    >
                        COPY
                    </Button>
                </div>
                {isCopiedToClipboard && <div className="ShareModal-copiedToClipboardText">
                    Copied To Clipboard
                </div>}
            </div>
        }
        </GenericContainer>
    )
}

export default ShareModal;
