import React, {Component} from 'react';
import { connect } from 'react-redux';
import {getLoggedUser} from '../../../app.selectors';
import {UserFullyRegisteredRequestAction} from '../login.actions';
import { SignupGamertag } from './signup-gamertag.component.js'
import { SignupAvatar }  from './signup-avatar.component.js'
import {navigateToLocation, ROUTES} from "../../../app.router";
import deviceInfo from '../../../assets/lib/deviceInfo';
import inputmanager from '../../../assets/lib/inputmanager';

// To be used when the user has just signed up with the shorted sign up process

class SignupFinishComponent extends Component {
	constructor(props){
		super(props);

		this.state = {
            displayName: null,
            avatar: null,
		};
	}
	componentDidMount() {
		inputmanager.setBackFunction(() => {})
	}
	componentWillUnmount() {
		inputmanager.setBackFunction(null)
	}

	onAvatarApproved = (avatar) => {
		const { displayName } = this.state;
		this.props.dispatch(UserFullyRegisteredRequestAction(avatar,displayName));
		navigateToLocation(ROUTES.HOMEPAGE);
	}

	render () {
		const {avatar, displayName} = this.state
		const {userDetails = {}} = this.props || {};
		const {isNotFullyRegistered} = userDetails || {};
		if(isNotFullyRegistered && !displayName) {
			return (
				<SignupGamertag
					isRegularRegistration={false}
					handleValidDisplayName ={(displayName) => this.setState({displayName: displayName})}
					handleBackButton={() => this.setState({avatar: avatar,displayName:null})}
				/>
			);
		}
		if(isNotFullyRegistered && !avatar) {
			return (
				<SignupAvatar
					displayNameProp={this.state.displayName}
					isRegularRegistration={false}
					handleSelect={this.onAvatarApproved}
				/>
			);
		}
		navigateToLocation(ROUTES.HOMEPAGE) // we ended up here via an error so send them to the homepage
		return null;
	}
}

const mapStateToProps = (state) => {
	return {
		userDetails: getLoggedUser(state)
	};
};

export const SignupFinish = connect(mapStateToProps)(SignupFinishComponent);