import * as ACTIONS from './users.actions';

const initialState = {
	loading:false,
	myUserDetails:{},
	otherUserDetails:null,
	myUserFollowedList: [],
	myUserFollowersList: [],
	myUserAchievements: [],
	myUserFavouritedGames: [],
	myUserRecentlyPlayedGame: [],
	otherUserFavouritedGames: [],
	otherUserRecentlyPlayedGame: [],
	isFollowUserPending: false,
};

export const usersReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACTIONS.GET_MY_USER_DETAILS_ACTION_SUCCESS:
		return {
			...state,
			loading:false,
			myUserDetails:action.payload.userData,
			myUserFavouritedGames: action.payload.favouritedGames.gameIds,
			myUserRecentlyPlayedGame: action.payload.recentPlayedGames.gameIds,
		};
	case ACTIONS.GET_OTHER_USER_DETAILS_ACTION:
		return {
			...state,
			otherUserDetails:null,
			otherUserFavouritedGames:[],
			otherUserRecentlyPlayedGame:[],
		};

	case ACTIONS.FOLLOW_USER_ACTION:
	case ACTIONS.UNFOLLOW_USER_ACTION: {
		return {
			...state,
			isFollowUserPending: true
		};
	}
	case ACTIONS.FOLLOW_USER_ACTION_FAILED:
	case ACTIONS.UNFOLLOW_USER_ACTION_FAILED: {
		return {
			...state,
			isFollowUserPending: false
		};
	}
	case ACTIONS.FOLLOW_USER_ACTION_SUCCESS:
		return {
			...state,
			isFollowUserPending: false,
			otherUserDetails: {
				...state.otherUserDetails,
				youFollow: true
			},
		};
	case ACTIONS.UNFOLLOW_USER_ACTION_SUCCESS:
		return {
			...state,
			isFollowUserPending: false,
			otherUserDetails: {
				...state.otherUserDetails,
				youFollow: false
			},
		};

	case ACTIONS.GET_OTHER_USER_DETAILS_ACTION_SUCCESS:
		return {
			...state,
			otherUserDetails:action.payload.userData,
			otherUserFavouritedGames: action.payload.favouritedGames.gameIds,
			otherUserRecentlyPlayedGame: action.payload.recentPlayedGames.gameIds,
		};
	case ACTIONS.FETCH_MY_ACHIEVEMENTS_SUCCESS: 
		return {
			...state,
			myUserAchievements: action.payload
		};
	case ACTIONS.CHANGE_PROFILE_IMAGE_SUCCESS:
		if (!state || !state.myUserDetails) {
			return state;
		}
		return {
			...state,
			myUserDetails: {
				...state.myUserDetails,
				profile_image_id: action.payload,
			},
		};
	default:
		return state;
	}
};
