import React, {Component} from 'react';
import gsap from 'gsap';
import {isLowSpecDevice} from '../../app.helpers';

class CountAnimation extends Component {
	constructor(props) {
		super(props);
		this.updateHandler = this.updateHandler.bind(this);
		this.startCountAnimation = this.startCountAnimation.bind(this);
		this.onSpanRef = this.onSpanRef.bind(this);
		this.prevScore = 0;
		this.endValue = 0;
	}

	onSpanRef(element) {
		this.counterRef = element;
	}

	componentDidMount() {
		this.startCountAnimation();
	}

	componentDidUpdate () {
		this.startCountAnimation();
	}

	startCountAnimation() {
		if(!this.counterRef) return;
		const { startValue, endValue, duration } = this.props;
		const valueChanged = this.endValue!==endValue && startValue!==endValue;

		if(valueChanged && !isLowSpecDevice()) {
			this.endValue = endValue;
			if(this.props.onAnimationStart)this.props.onAnimationStart();
			this.score = startValue;
			this.updateHandler();
			const tl = gsap.timeline();
			tl.to(this, duration || 2, {score:`${endValue}`, roundProps:'score', onUpdate:this.updateHandler, ease:'ease.easeOut'});
		}else {
			if(valueChanged && isLowSpecDevice()) {
				if(this.props.onAnimationStart)this.props.onAnimationStart();
			}
			if(this.endValue!==endValue) {
				this.counterRef.innerHTML = endValue;
			}
			this.endValue = endValue;
		}
	}

	updateHandler () {
		if(!this.counterRef) return;
		const nextScore= Math.round(this.score);
		if(nextScore!==this.prevScore) {
			this.prevScore = nextScore;
			this.counterRef.innerHTML = this.prevScore;
			if(this.prevScore===this.endValue) {
				if(this.props.onAnimationEnd)this.props.onAnimationEnd();
			}
		}
	}

	render() {
    	const { textClass} = this.props;

    	return(
			<span ref={this.onSpanRef} className={textClass}>
				0
			</span>
    	);
	}
}

export default CountAnimation;
