import React, { Component } from 'react';
import './settings-terms-of-use.component.less';
import {addPopup} from "../../popup/popup.component";
import QrCodePopup from "../../popup/qr-code-popup/qr-code-popup.component";
import subscriptionTermsImage from "../../../assets/images/qr-codes/antstream-comsubscriber-terms-and-conditions.png";
import contactEmailUsQrCodeImage from "../../../assets/images/qr-codes/contact@anstream-com.png";
import complaintEmailQrCodeImage from "../../../assets/images/qr-codes/complaint@antstream-com.png";
import {Button} from "../../common/button/common-button.component";
import {navigateToLocation, ROUTES} from "../../../app.router";
import inputManager from "../../../assets/lib/inputmanager";
import { getCurrentDeviceName } from '../../../assets/lib/utils';
import deviceInfo from '../../../assets/lib/deviceInfo';

class SettingsTermsOfUseComponent extends Component {
	componentDidMount() {
		inputManager.setCurrentChildById('tab-menu'+'-'+'Terms Of Use', 'tab-menu');
	}

	onSubscriptionTermsClicked = () => {
		addPopup(<QrCodePopup
			title='Subscription Terms - QR Code Link'
			image={subscriptionTermsImage}
		/>);
	};

	onContactUsClicked = () => {
		addPopup(<QrCodePopup
			title='Contact Us - QR Code Link'
			image={contactEmailUsQrCodeImage}
		/>);
	};

	onComplaintEmailClicked = () => {
		addPopup(<QrCodePopup
			title='Complaint - QR Code Link'
			image={complaintEmailQrCodeImage}
		/>);
	};

	render() {
		return (
			<div className="settings-terms-of-use">
				<Button className='fake-focusable' childId='1'></Button>
				<span className="letter-spacing-normal mb-1">PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING ANTSTREAM</span>

				<h5 className="font_5 letter-spacing-normal">What's in these terms?</h5>

				<p className="w-100 line-height-normal">
					These terms tell you the rules for using {deviceInfo.isPlayStationPlatform() ? 'Antstream (our product) and' : ''} our website antstream.com (<span className="font-weight-bold">our site</span>).
				</p>

				<p className="w-100 line-height-normal"><span className="font-weight-bold">Who we are and how to contact us</span></p>

				<p className="w-100 line-height-normal">
					Anstream.com is a site operated by Antstream Limited ("<span
						className="font-weight-bold">We</span>"). We are registered in England and Wales and have our registered office at Studio I, 175 Long Lane, London, SE1 4GT.
				</p>

				<p className="w-100 line-height-normal">
					<span>To contact us, please email </span>
					<Button onClick={this.onContactUsClicked} className='focusable-hyperlink' childId='54653746866'>
						contact@anstream.com
					</Button>
					.
				</p>

				<h5 className="font_5">By using our site, you accept these terms
				</h5>
				<Button className='fake-focusable' childId='2'></Button>

				<p className="w-100 line-height-normal">By using our site, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our site.</p>

				<p className="w-100 line-height-normal letter-spacing-normal">
					We recommend that you print a copy of these terms for future reference.
				</p>

				<h5 className="font_5">There are other terms that may apply to you
				</h5>
				<Button className='fake-focusable' childId='3'></Button>

				<p className="w-100 line-height-normal">
					These terms of use refer to the following additional terms, which also apply to your use of our site:
				</p>

				<p className="w-100 line-height-normal">
					
						● Our <Button onClick={() => navigateToLocation(ROUTES.SETTINGS_PRIVACY_POLICY)} className='focusable-hyperlink' childId='2346537658'>Privacy Policy</Button>
					
				</p>

				<p className="w-100 line-height-normal">
					<span>If you purchase a subscription from our site, our </span> 
					<Button onClick={this.onSubscriptionTermsClicked} className='focusable-hyperlink' childId='356768468579'>
						Subscription Terms
					</Button>
					<span> will apply to your subscription.</span>
				</p>

				<h5 className="font_5">We may make changes to these terms</h5>
				<Button className='fake-focusable' childId='4'></Button>

				<p className="w-100 line-height-normal">We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p>

				<h5 className="font_5">We may make changes to our site</h5>
				<Button className='fake-focusable' childId='4.1'></Button>

				<p className="w-100 line-height-normal">We may update and change our site from time to time.</p>

				<h5 className="font_5">We may suspend or withdraw our site</h5>
				<Button className='fake-focusable' childId='4.2'></Button>

				<p className="w-100 line-height-normal">We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw, or restrict the availability of, all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>
				<Button className='fake-focusable' childId='4.3'></Button>

				<p className="w-100 line-height-normal">
					You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.
				</p>
				<Button className='fake-focusable' childId='4.4'></Button>

				<h5 className="font_5">
					You must keep your account details safe
				</h5>
				<Button className='fake-focusable' childId='5'></Button>

				<p className="font_5">If you choose, or you are provided with, a username, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>
				<Button className='fake-focusable' childId='5.1'></Button>

				<p className="font_5">
					We have the right to disable any username or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.
				</p>
				<Button className='fake-focusable' childId='5.2'></Button>

				<p className="font_5">
					If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at support@anstream.com
				</p>

				<h5 className="font_5">How you may use material on our site</h5>
				<Button className='fake-focusable' childId='6'></Button>

				<p className="w-100">We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
				<Button className='fake-focusable' childId='6.1'></Button>

				<p className="w-100">You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
				<Button className='fake-focusable' childId='6.2'></Button>

				<p className="w-100">
					You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
				<Button className='fake-focusable' childId='6.3'></Button>

				<p className="w-100">
					Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
				<Button className='fake-focusable' childId='6.4'></Button>

				<p className="w-100">
					You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so
					from us or our licensors.</p>
				<Button className='fake-focusable' childId='6.5'></Button>

				<p className="w-100 line-height-normal">
					If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
					&nbsp;</p>

				<h5 className="font_5">Information on this site</h5>
				<Button className='fake-focusable' childId='7'></Button>

				<p className="w-100 line-height-normal">Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.
					&nbsp;</p>

				<h5 className="font_5">We are not responsible for websites we link to
				</h5>
				<Button className='fake-focusable' childId='8'></Button>

				<p className="w-100 line-height-normal">Where our site or services contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the content of those sites or resources.
					&nbsp;</p>

				<h5 className="font_5">Our responsibility for loss or damage suffered by you
					&nbsp;</h5>
				<Button className='fake-focusable' childId='9'></Button>

				<ul className="w-100">
					<li>
						<p className="w-100 line-height-normal">We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</p>
						<Button className='fake-focusable' childId='9.1'></Button>
					</li>
					<li>
						<p className="w-100 line-height-normal">Different limitations and exclusions of liability will apply to liability arising as a result of the supply of our services to you as set out in our Subscription Terms.</p>
						<Button className='fake-focusable' childId='9.2'></Button>
					</li>
					<li>
						<p className="w-100 line-height-normal">{`If defective digital content that we have supplied, damages the ${getCurrentDeviceName()} or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.`}</p>
						<Button className='fake-focusable' childId='9.3'></Button>
					</li>
					<li>
						<p className="w-100 line-height-normal">Please note that we only provide our site to you for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
						<Button className='fake-focusable' childId='9.4'></Button>
					</li>
				</ul>

				<h5 className="font_5">How we may use your personal information</h5>
				<Button className='fake-focusable' childId='10'></Button>

				<p className="w-100 line-height-normal">We will only use your personal information as set out in our&nbsp;
					<Button onClick={() => navigateToLocation(ROUTES.SETTINGS_PRIVACY_POLICY)} className='focusable-hyperlink' childId='133565376489579579'>
						Privacy Policy
					</Button>
					.
				</p>

				<p className="w-100 line-height-normal">
					<span className="font-weight-bold">Uploading content to our services</span> Whenever you make use of a feature that allows you to upload content to our site, or to make contact with other users of our site, you must comply with the content standards set out below.
				</p>

				<p className="w-100 line-height-normal">
					You warrant that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
				</p>
				<Button className='fake-focusable' childId='10.1'></Button>

				<p className="w-100">
					Any content or material you upload to our site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us a limited licence to use, store and copy that content and to distribute and make it available to third parties.
				</p>
				<Button className='fake-focusable' childId='10.2'></Button>

				<p className="w-100">
					We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.
				</p>
				<Button className='fake-focusable' childId='10.3'></Button>

				<p className="w-100">
					We have the right to remove any posting you make on our site if, in our opinion, your post does not comply with the content standards set out below.
				</p>
				<Button className='fake-focusable' childId='11'></Button>

				<p className="w-100">
					<span className="font-weight-bold">User generated content is not approved by us &nbsp;</span>
					<p>
						Our services may include information and materials uploaded by other users of the Platform. This information and these
						materials have not been verified or approved by us. The views expressed by other users on our site do not represent our
						views or values.
					</p>
				</p>
				<Button className='fake-focusable' childId='11.1'></Button>

				<p className="w-100">
					<span>If you wish to complain about content uploaded by other user’s please contact us at </span> 
					<Button onClick={this.onComplaintEmailClicked} className='focusable-hyperlink' childId='2453467578468'>
						complaint@antstream.com
					</Button>
					.
				</p>
				<Button className='fake-focusable' childId='12'></Button>

				<p className="w-100 line-height-normal">
					<span className="font-weight-bold">We are not responsible for viruses and you must not introduce them</span>
				</p>

				<p className="w-100 line-height-normal">
					{deviceInfo.isPlayStationPlatform()
						? 'We do not guarantee that our product/site will be secure or free from bugs or viruses. You are responsible for configuring your own console/system, additional accessories and information technology to access Antstream and Antstream’s website. You should use your own virus protection software.'
						: 'We do not guarantee that our site will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.'
					}
				</p>
				<Button className='fake-focusable' childId='12.1'></Button>

				<p className="w-100 line-height-normal">
					<span>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored, or any server, computer or database connected to our site.</span>
					<Button className='fake-focusable' childId='12.2'></Button>
					<span>You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities, and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</span>
				</p>

				<h5 className="font_5">
					Rules about linking to our site</h5>
				<Button className='fake-focusable' childId='13'></Button>

				<p className="w-100 line-height-normal">You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
				<Button className='fake-focusable' childId='13.1'></Button>
				
				<p className="w-100 line-height-normal">You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
				<Button className='fake-focusable' childId='13.2'></Button>

				<p className="w-100 line-height-normal">You must not establish a link to our site in any website that is not owned by you.</p>

				<p className="w-100 line-height-normal">Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
				<Button className='fake-focusable' childId='13.3'></Button>

				<p className="w-100 line-height-normal">We reserve the right to withdraw linking permission without notice.</p>

				<p className="w-100 line-height-normal">The website in which you are linking must comply in all respects with the content standards set out in our acceptable use terms set out below.</p>
				<Button className='fake-focusable' childId='13.4'></Button>

				<p className="w-100 line-height-normal">
					If you wish to link to or make any use of content on our site other than that set out above, please contact&nbsp;
					<Button onClick={this.onContactUsClicked} className='focusable-hyperlink' childId='24537648483576'>
						contact@anstream.com
					</Button>
				</p>

				<h5 className="font_5 mb-1">Acceptable Use of our Services</h5>
				<Button className='fake-focusable' childId='14'></Button>

				You may use our site only for lawful purposes. You may not use our site:
				<ul className="w-100 list-style-disc mb-1">
					<li>
						In any way that breaches any applicable local, national or international law or regulation.
					</li>
					<li>
						In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.
						<Button className='fake-focusable' childId='14.1'></Button>
					</li>
					<li>
						For the purpose of harming or attempting to harm minors in any way.
					</li>
					<li>
						To bully, insult, intimidate or humiliate any person.
						<Button className='fake-focusable' childId='14.2'></Button>
					</li>
					<li>
						To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards (as set out below).
					</li>
					<li>
						To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).
						<Button className='fake-focusable' childId='14.3'></Button>
					</li>
					<li>
						{deviceInfo.isPlayStationPlatform()
							? 'To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any console, PlayStation®4 system, PlayStation®5 console, computer software or hardware.'
							: 'To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.'
						}
						<Button className='fake-focusable' childId='14.4'></Button>
					</li>
				</ul>

				You also agree:
				<Button className='fake-focusable' childId='15'></Button>
				<ul className="w-100 font-size-18 list-style-disc mb-1">
					<li>
						Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of our terms of use.
					</li>
					<li>
						Not to access without authority, interfere with, damage or disrupt

						<ul className="w-100 list-style-disc">
							<li>
								​any part of our site;
							</li>
							<li>
								any equipment or network on which our site is stored;
							</li>
							<li>
								any software used in the provision of our site; or
							</li>
							<li>
								any equipment or network or software owned or used by any third party
							</li>
						</ul>
					</li>
				</ul>

				<Button className='fake-focusable' childId='16'></Button>
				<h5 className="font_5">Content standards</h5>

				<p className="w-100">These content standards apply to any and all material which you contribute to our site (Contribution), and to any interactive services associated with it.</p>
				<Button className='fake-focusable' childId='16.1'></Button>

				<p className="w-100">The Content Standards must be complied with in spirit as well as to the letter. The standards apply to each part of any Contribution as well as to its whole.</p>
				<Button className='fake-focusable' childId='16.2'></Button>

				<p className="w-100">
					We will determine, in our discretion, whether a Contribution breaches the Content Standards.
				</p>
				<span className="letter-spacing-normal font-weight-bold">A Contribution must:</span>
				<Button className='fake-focusable' childId='17'></Button>
				<ul className="w-100 font-size-18 list-style-disc mb-1">
					<li>
						Be accurate (where it states facts).
					</li>
					<li>
						Be genuinely held (where it states opinions).
					</li>
					<li>
						Comply with the law applicable in England and Wales and in any country from which it is posted.
					</li>
				</ul>

				<span className="font-weight-bold">A Contribution must not:</span>
				<Button className='fake-focusable' childId='18'></Button>
				<ul className="w-100 font-size-18 list-style-disc mb-1">
					<li>
						Be defamatory of any person.
					</li>
					<li>
						Be obscene, offensive, hateful or inflammatory.
					</li>
					<li>
						Bully, insult, intimidate or humiliate.
					</li>
					<li>
						Promote sexually explicit material.
					</li>
					<li>
						Promote violence.
						<Button className='fake-focusable' childId='18.1'></Button>
					</li>
					<li>
						Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.
					</li>
					<li>
						Infringe any copyright, database right or trade mark of any other person.
					</li>
					<li>
						Be likely to deceive any person.
						<Button className='fake-focusable' childId='18.2'></Button>
					</li>
					<li>
						Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.
					</li>
					<li>
						Promote any illegal activity.
						<Button className='fake-focusable' childId='19'></Button>
					</li>
					<li>
						Be in contempt of court.
					</li>
					<li>
						Be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety.
					</li>
					<li>
						Be likely to harass, upset, embarrass, alarm or annoy any other person.
						<Button className='fake-focusable' childId='19.1'></Button>
					</li>
					<li>
						Impersonate any person, or misrepresent your identity or affiliation with any person.
					</li>
					<li>
						Give the impression that the Contribution emanates from us, if this is not the case.
						<Button className='fake-focusable' childId='19.2'></Button>
					</li>
					<li>
						{deviceInfo.isPlayStationPlatform()
							? 'Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse on PlayStation®4 system, PlayStation®5 console or any related console and device.'
							: 'Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse.'
						}
					</li>
					<li>
						Contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism.
						<Button className='fake-focusable' childId='19.3'></Button>
					</li>
					<li>
						Contain any advertising or promote any services or web links to other sites.
					</li>
				</ul>

				<h5 className="font_5">Breach of these terms</h5>
				<Button className='fake-focusable' childId='20'></Button>

				<p className="w-100">When we consider that a breach of these terms has occurred, we may take such action as we deem appropriate. Failure to comply with these terms may result in our taking all or any of the following actions:
				</p>

				<ul className="w-100 font-size-18 list-style-disc">
					<li>
						Immediate, temporary or permanent withdrawal of your right to use our services.
					</li>
					<li>
						Immediate, temporary or permanent removal of any Contribution uploaded by you to our services.
						<Button className='fake-focusable' childId='20.1'></Button>
					</li>
					<li>
						Issue of a warning to you.
					</li>
					<li>
						Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.
					</li>
					<li>
						Further legal action against you
						<Button className='fake-focusable' childId='20.2'></Button>
					</li>
					<li>
						Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as required by law.
					</li>
				</ul>

				<h5 className="font_5">&nbsp;</h5>

				<h5 className="font_5 mb-1">Which country's laws apply to any disputes?</h5>
				<Button className='fake-focusable' childId='21'></Button>

				<p className="w-100">These terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.</p>
				<Button className='fake-focusable' childId='21.1'></Button>

				<h5 className="font_5 mb-1">
					Our trade marks are registered &nbsp;
					&nbsp;</h5>

				<p className="w-100">ANTSTREAM and the ANTSTREAM device are UK registered trade marks of Antstream Limited. You are not permitted to use them without our approval.</p>
				<Button className='fake-focusable' childId='22'></Button>
			</div>
		);
	}
}

export default SettingsTermsOfUseComponent;
