import {addPopup} from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import React from 'react';
import {getMyTutorials} from './tutorial.selector';
import {antstreamAPIService} from '../../app.reducer';
import {readRestUserData} from '../../assets/lib/local-storage';
import { handleNoAuthTokenIssue, replaceAchievementsToTrophies } from '../../assets/lib/utils';

export const TUTORIAL_COMPLETE_ACTION = 'Tutorials Complete Action';
/**@param payload {{id: string}}**/
export const TutorialCompleteAction = (payload) => {
	return (dispatch) => {
		dispatch({type:TUTORIAL_COMPLETE_ACTION});

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('TUTORIAL_COMPLETE_ACTION');
			const params = {
				body: { tutorialId: payload.id },
				headers: { Authorization: 'Bearer ' + authToken }
			};

			antstreamAPIService.tutorials.postTutorials(params).then(({ data }) => {
				if (!data || !data.data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);

				const resp = data.data.allTutorials;
				dispatch(TutorialCompleteActionSuccess(resp));
			}).catch(catchErr => {
				addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
				console.error('Failed to post tutorials: ', catchErr.message);
			});

	};
};

export const TUTORIAL_COMPLETE_ACTION_SUCCESS = 'Tutorial Complete Action Success';
export const TutorialCompleteActionSuccess = (payload) => {
	return (dispatch) => {
		dispatch({type:TUTORIAL_COMPLETE_ACTION_SUCCESS, payload});
	};
};

export const GET_TUTORIALS_COMPLETED_ACTION_SUCCESS = 'Get Tutorials Completed Action Success';
export const GetTutorialsCompletedActionSuccess = (payload) => {
	return (dispatch) => {
		dispatch({type:GET_TUTORIALS_COMPLETED_ACTION_SUCCESS, payload});
	};
};

export const SHOW_TUTORIAL_ACTION = 'Show Tutorial Action';
export const SetTutorialId = (tutorialId) => {
	return (dispatch, getState) => {
		const state = getState();
		const tutorials = getMyTutorials(state);
		const tutorial = tutorials.find((item) => {
			return item.id === tutorialId;
		});
		if (!tutorial) return;
		if (tutorial.completed) return;
		if (state.tutorial.shownTutorialsIds?.find(id => id === tutorialId)) return;

		dispatch({ type: SHOW_TUTORIAL_ACTION, payload: tutorialId });

		addPopup(<GenericPopup
			okButtonLabel="Got it!"
			title={replaceAchievementsToTrophies(tutorial.titleText, true)}
			message={replaceAchievementsToTrophies(tutorial.mainText)}
			onOkClicked={()=> dispatch(TutorialCompleteAction({id:tutorialId}))}
		/>);
	};
};
