import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListContainer, { LIST_TYPES } from '../../common/list/list-container.component';
import {getIssuedChallenges, getChallengesHistory, getChallengesHistoryUpdateId} from '../my-profile.selectors';
import {FetchUserChallenges, UpdateMyMenuSelection} from '../my-profile.actions';
import { getLoggedUser } from '../../../app.selectors';
import TileChallengeHistory from '../../common/tiles/tile-challenge-history.component';
import './my-profile-challenges.component.less';
import inputManager, {PARENT_ENTRANCE}  from '../../../assets/lib/inputmanager';
import {findChallengeHistoryObject, SetSelectedChallengeHistory} from '../../challenge/challenge.actions';
import deviceInfo from '../../../assets/lib/deviceInfo';

class MyProfileChallengesComponent extends Component {
	constructor(props) {
		super(props);
		this.selectedItemId = null;
	}

	componentDidMount() {
		this.props.dispatch(FetchUserChallenges());
		this.props.dispatch(UpdateMyMenuSelection({title: 'ChallengesList'}));
	}

	componentDidUpdate(prevProps){
		if(this.props.challengesHistoryUpdateId !== prevProps.challengesHistoryUpdateId){
			inputManager.refreshBoundingRects();
		}
	}

	onItemChanged = (itemId) => {
		this.selectedItemId = itemId;
	};

	onItemSelected = (itemId) => {
		if(this.selectedItemId===itemId) {
			const historyObject = this.props.dispatch(findChallengeHistoryObject(itemId));
			this.props.dispatch(SetSelectedChallengeHistory(historyObject));
		}
	};

	getAmendedName = (name) => {
		switch (name) {
			case 'win':
				return 'won'
			case 'loss':
				return 'lost'
			case 'giant-slayer-live':
				return 'Giant Slayer - Live'
			case 'giant-slayer-win':
				return 'Giant Slayer - Won'
			case 'giant-slayer-loss':
				return 'Giant Slayer - Lost'
			default:
				return name
		}
	}

	prepareLists = () => {
		const {challengesHistory, issuedChallenges, user} = this.props;
		const challengesList = challengesHistory.reduce((accum, item) => {
			let typeListName = item.challengeState;
			if (item?.giantSlayerDetails) {
				if (item.giantSlayerDetails.state === 'live') {
					typeListName = 'giant-slayer-live';
				}
				if (item.giantSlayerDetails.state === 'completed') {
					if (item.giantSlayerDetails.winner === 'giant') {
						typeListName = (item.giantSlayerDetails.giantId === user._id) ? 'giant-slayer-win' : 'giant-slayer-loss';
					} else {
						typeListName = (item.giantSlayerDetails.giantId === user._id) ? 'giant-slayer-loss' : 'giant-slayer-win';
					}
				}
			}

			if (!accum[typeListName]) return accum
			const typeList = accum[typeListName]	
			const extendedTypeList = {
				[typeListName]:[...typeList,item]
			}
			return {
				...accum,
				...extendedTypeList,
			}
		}, {
			"giant-slayer-live": [],
			"giant-slayer-win": [],
			"giant-slayer-loss": [],
			"issued": [],
			"waiting": [],
			"win": [],
			"loss": [],
			"draw": [],
			"solo": []
		})
		const defaultValuesForList = {
			type: deviceInfo.isPlayStationPlatform() ? LIST_TYPES.GRID : LIST_TYPES.HORIZONTAL,
			renderer: (props) => <TileChallengeHistory {...props} />,
			parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL
		}
		const issuedChallengesListReformatted = {
			...defaultValuesForList,
			id: "Issued",
			title: "Issued",
			data: issuedChallenges
		}
		const challengesListReformated = Object.keys(challengesList).reduce((accum, name) => {
			const item = challengesList[name]
			if(item.length === 0) return accum
			const amendedName = this.getAmendedName(name);
			const newCaptalizeName = amendedName && amendedName[0].toUpperCase() + amendedName.slice(1);
			return [
				...accum,
				{
					id: name,
					data: item,
					title: newCaptalizeName,
					...defaultValuesForList
				}
			]
		}, [])

		const withIssuedChallenges = issuedChallenges && issuedChallenges.length ?[
			issuedChallengesListReformatted,
			...challengesListReformated
		] : challengesListReformated
		return withIssuedChallenges
	};

	render() {
		const lists = this.prepareLists();
		return (
			<div className="my-profile-challenges-component">
				{lists && lists.length === 0 && 
					<p className="my-profile-challenges-component-not-found">You haven't played or received any challenges yet!</p>
				}
				{lists && lists.length > 0 && 
					<ListContainer
						lists={lists}
						onTileClick={this.onItemSelected}
						onTileFocus={this.onItemChanged}
					/>}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const challengesHistoryUpdateId = getChallengesHistoryUpdateId(state);
	const challengesHistory = getChallengesHistory(state);
	const issuedChallenges = getIssuedChallenges(state);

	return {
		user: getLoggedUser(state),
		issuedChallenges,
		challengesHistory,
		challengesHistoryUpdateId,
	};
};

export const MyProfileChallenges = connect(mapStateToProps)(MyProfileChallengesComponent);
