import {
    XINPUT_BUTTON,
    KeyMapping,
    PacketTypes,
    InputRawType,
    MAX_RAW_CONTROLS_HISTORY_BYTES,
    MAX_INPUT_MAPPING_BYTES,
} from "./AntRTCNetworkConstants";

export default class AntRTCNetwork {
  constructor(dataChannel, webSocket, sessionStart, controlsString) {
    this.dataChannel = dataChannel;
    this.webSocket = webSocket;
    this.sessionStart = sessionStart;
    this.controlsString = controlsString;
    this.sequenceNumber = 0;
    this.messageSendTime = performance.now();

    this.rawControlsPacketsHistory = [];
    this.pingInterval = setInterval(this.onPingInterval.bind(this), 2000);
    this.lastPingTime = 1;
  }

  deInit = () => {
    if(this.pingInterval){
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  onPingInterval = () => {
    // console.log("[AntRTCNetwork] onPingInterval");  
    this.sendPing();    
  };

  getPingDelay = (replyTimestamp) => {

    if(this.lastPingTime > 0) {
      const delay = replyTimestamp - this.lastPingTime;
      this.lastPingTime = -1;
      return delay;
    }

    return 0;
  }

  isDataChannelOpen = () => {
    return this.dataChannel && this.dataChannel.readyState === "open";
  };

  /**
   * obj should be something like:
   * {
   *   "sessionId": "bbbad566-8ffd-4ccb-b0e8-1bb75da63a00",
   *   "slot": "3",
   *   "region": "uks"
   * }
   */
  sendSave = (obj) => {
    const data = JSON.stringify(obj);

    var bytearray = new Uint8Array(13 + data.length);
    var dv = new DataView(bytearray.buffer);
    dv.setUint16(0, PacketTypes.CG_PACKET_SAVE, true);
    dv.setUint32(2, 7 + data.length, true);
    dv.setUint32(6, 1, true); // version
    dv.setUint8(10, 0, true); // player
    dv.setUint16(11, data.length, true); // json length

    for (var i = 0; i < data.length; i++) {
      dv.setUint8(13 + i, data[i].charCodeAt(0), true);
    }

    this.sendData(bytearray.buffer, true);
  };

  sendDisconnect = () => {
    var bytearray = new Uint8Array(6);
    var dv = new DataView(bytearray.buffer);
    dv.setUint16(0, PacketTypes.CG_PACKET_INVALID, true);
    dv.setUint32(2, 0, true);

    this.sendData(bytearray.buffer, true);

    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  };

  sendPause = (paused) => {
    var bytearray = new Uint8Array(8);
    var dv = new DataView(bytearray.buffer);
    dv.setUint16(0, PacketTypes.CG_PACKET_PAUSE_CONTROL, true);
    dv.setUint32(2, 2, true);
    dv.setUint8(6, 0, true); // player
    dv.setUint8(7, paused); // paused flag

    this.sendData(bytearray.buffer, true);
  };

  sendPing = () => {
    // (imayo) Send using UDP DataChannel + binary mode
    var bytearray = new Uint8Array(6);
    var dv = new DataView(bytearray.buffer);
    dv.setUint16(0, PacketTypes.CG_PACKET_PING_REQ, true);
    dv.setUint32(2, 0, true); 
    this.sendData(bytearray.buffer, false);

    /*  
    // (imayo) Send using WebSocket + JSON mode
    const pingMessage = {
        msgType: 'nativeProtocol',
        data: {
            type: "CG_PACKET_PING_REQ"
        }
    }   
    this.sendData(JSON.stringify(pingMessage), true); 
    */

    this.lastPingTime = window.performance.now();
  };

  sendPacketID = (paused) => {
    var bytearray = new Uint8Array(16);
    var dv = new DataView(bytearray.buffer);
    dv.setUint16(0, PacketTypes.CG_PACKET_ID, true);
    dv.setUint32(2, 10, true);

    dv.setUint16(6, 5, true); // iEntityType = CG_ENT_CLIENT (5)
    dv.setUint8(8, 4, true); // iVersionRelease
    dv.setUint8(9, 0, true); // iVersionMinor
    dv.setUint8(10, 0, true); // iVersionPatch
    dv.setUint8(11, 0, true); // iContentType
    dv.setUint16(12, 0, true); // iRTPPort
    dv.setUint8(14, 0, true); // iStreamQuality
    dv.setUint8(15, 1, true); // u8PlayersActive
    this.sendData(bytearray.buffer, true);
  };

  sendLocalPlayerSlot = (u8Change, u8Slot) => {
    var bytearray = new Uint8Array(8);
    var dv = new DataView(bytearray.buffer);
    dv.setUint16(0, PacketTypes.CG_PACKET_LOCAL_PLAYER_SLOT, true);
    dv.setUint32(2, 0, true);
    dv.setUint8(6, u8Change, true);
    dv.setUint8(7, u8Slot, true);
    this.sendData(bytearray.buffer, true);
  };

  sendControlsString = () => {
    for (
      var b = 0;
      b < this.controlsString.length;
      b += MAX_INPUT_MAPPING_BYTES
    ) {
      var segmentLength = Math.min(
        this.controlsString.length - b,
        MAX_INPUT_MAPPING_BYTES
      );
      var bytearray = new Uint8Array(18 + segmentLength);
      var dv = new DataView(bytearray.buffer);
      dv.setUint16(0, PacketTypes.CG_PACKET_INPUT_MAPPING, true);
      dv.setUint32(2, 12 + segmentLength, true);
      dv.setUint16(6, 0, true);
      dv.setUint16(8, this.gameType, true);
      dv.setUint32(10, this.controlsString.length, true);
      dv.setUint32(14, segmentLength, true);

      for (var i = 0; i < segmentLength; i++) {
        dv.setUint8(18 + i, this.controlsString[i + b].charCodeAt(0), true);
      }

      this.messageSendTime = performance.now();
      this.sendData(bytearray.buffer, true);
    }
  };

  sessionDuration = () => {
    return Date.now() - this.sessionStart;
  };

  concatTypedArrays = (a, b) => {
    var c = new a.constructor(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
  };

  setRawControlsBase = (dv, len, type) => {
    dv.setUint16(0, 0, true);
    dv.setUint16(2, len, true); // Length in bytes
    dv.setUint16(4, type, true);
    dv.setUint16(6, this.sequenceNumber++, true);
    dv.setUint32(8, 0, true);// frameNumber
    dv.setUint32(12, this.sessionDuration(), true); // ms since session start
  };

  sendData = (dataBuffer, reliable) => {
    try {
        if (reliable) {
          if(this.webSocket) {
            this.webSocket.send(dataBuffer);
          }
          else {
            console.warn("Cant send over webSocket. webSocket: ", this.webSocket);
          }
            
        }
        else {
          if(this.dataChannel.readyState === 'open'){
            this.dataChannel.send(dataBuffer);
          }
          else {
            console.warn("Cant send over datachannel. readyState: ", this.dataChannel.readyState);
          }
            
        }     
    } catch (error) {
      console.warn(error);
    }
  };

  sendRawControlsPacket = (latestPacket, bReliable) => {
    var header = new Uint8Array(6);
    var dv = new DataView(header.buffer);
    dv.setUint16(0, PacketTypes.CG_PACKET_INPUT_RAW, true);
    dv.setUint32(2, latestPacket.length, true);
    const finalPacket = this.concatTypedArrays(header, latestPacket);
    this.sendData(finalPacket.buffer, bReliable);
  };

  sendVirtualJoystickInput = (id, type, x, y) => {
    var bytearray = new Uint8Array(26);
    var dv = new DataView(bytearray.buffer);

    this.setRawControlsBase(dv, bytearray.length, InputRawType.VIRTUALJOYSTICK);
    dv.setUint8(16, id, true);
    dv.setUint8(17, type, true); //relative or auto center
    dv.setFloat32(18, x, true);
    dv.setFloat32(22, y, true);

    this.sendRawControlsPacket(bytearray, false);
  };

  sendSpecialButtonInput = (client, button, state) => {
    var bytearray = new Uint8Array(19);
    var dv = new DataView(bytearray.buffer);

    this.setRawControlsBase(dv, bytearray.length, InputRawType.SPECIALBUTTON);
    dv.setUint8(16, client, true);
    dv.setUint8(17, button, true);
    dv.setUint8(18, state, true);

    this.sendRawControlsPacket(bytearray, true);
  };

  sendRawKeyInput = (code, value) => {

    if (!KeyMapping.hasOwnProperty(code)) {
      console.warn("[AntRTCNetwork] Key not mapped: ", code);
      return;
    }

    var bytearray = new Uint8Array(18);
    var dv = new DataView(bytearray.buffer);

    this.setRawControlsBase(dv, bytearray.length, InputRawType.SINGLEKEY);
    dv.setUint8(16, KeyMapping[code], true);
    dv.setUint8(17, value, true); // is pressed

    this.sendRawControlsPacket(bytearray, false);
  };

  sendPointerInput = (id, pointerType, eventType, primary, x, y, buttons) => {
    var bytearray = new Uint8Array(34);
    var dv = new DataView(bytearray.buffer);

    this.setRawControlsBase(dv, bytearray.length, InputRawType.POINTER);
    dv.setUint32(16, id, true);
    dv.setUint8(20, pointerType + (primary ? 128 : 0), true);
    dv.setInt8(21, eventType, true); // Event type
    dv.setFloat32(22, x, true);
    dv.setFloat32(26, y, true);
    dv.setUint32(30, buttons, true); // buttons

    this.sendRawControlsPacket(bytearray, false);
  };

  sendContollerInputs = (
    playerId,
    controlPadState,
    clampPadTriggerValue,
    clampPadAxisValue
  ) => {
    var bytearray = new Uint8Array(34);
    var dv = new DataView(bytearray.buffer);

    this.setRawControlsBase(dv, bytearray.length, InputRawType.CONTROLLER_UPDATE);
    dv.setUint16(16, playerId, true); // Player number
    dv.setUint32(18, controlPadState, true);
    dv.setUint8(22, clampPadTriggerValue.left, true);
    dv.setUint8(23, clampPadTriggerValue.right, true);
    dv.setInt16(24, clampPadAxisValue.left.x, true);
    dv.setInt16(26, clampPadAxisValue.left.y, true);
    dv.setInt16(28, clampPadAxisValue.right.x, true);
    dv.setInt16(30, clampPadAxisValue.right.y, true);
    dv.setUint16(32, 0, true);

    this.sendRawControlsPacket(bytearray, false);
  };
}
