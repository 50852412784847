import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { ROUTES, navigateToLocation } from '../../../app.router';
import {getLoggedUser, getWebViewDimensions} from '../../../app.selectors';
import { getLoading } from '../login.selectors';
import { Button } from '../../common/button/common-button.component';
import { Input } from '../../common/input/input.component';
import {requiredValidator, emailValidator, trimValidator} from '../../common/input/input.validator';
import { RequestPasswordResetAction, SetSignupFieldAction } from '../login.actions';
import inputManager from '../../../assets/lib/inputmanager';
import '../login-global.less';
import {checkWebViewDimensions} from '../../../assets/lib/utils';

const formId = "passwordResetRequest";
class PasswordResetRequestComponent extends Component {
	constructor() {
		super();
		this.state = {};
		this.back = this.back.bind(this);
	}

	componentDidMount() {
		inputManager.setCurrentChildById("emailInput");
	}

	onRequestReset = (values) => {
		const email = values.email.toLowerCase();
		this.props.dispatch(SetSignupFieldAction({email}));
		this.props.dispatch(RequestPasswordResetAction(email));
	};
	
	back = () => {
		if(!this.props.user) {
			navigateToLocation(ROUTES.LOGIN_CREDENTIALS);
		} else {
			navigateToLocation(ROUTES.SETTINGS_ACCOUNT);
		}
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13) {
			this.props.handleSubmit(this.onRequestReset)();
		}
	};

	render () {
		return(
			<div className={`login-wrapper password-reset-request-component ${checkWebViewDimensions(this.props.viewDimensions)}`}>
				<section className="login-section login-section--left">
					<h1 className="title">Password Reset</h1>
					<p>
						Enter your email address that you used to register. We'll send you an email with a token to reset your password.
					</p>
					<form>
						<Field
							name="email"
							onKeyDown={this.onKeyDown}
							component={Input}
							formId={formId}
							label="Email"
							validate={[ requiredValidator, emailValidator, trimValidator]}
							childId="emailInput"
						/>
					</form>
					<Button
						disabled={this.props.loading}
						className="primary right-button"
						onClick={this.props.handleSubmit(this.onRequestReset)}
						childId="sendEmailButton"
					>
						Send Email
					</Button>
					<Button
						className="secondary right-button"
						onClick={this.back}
						childId="backButton"
					>
						Back
					</Button>
					<p>If you still need help, contact support@antstream.zendesk.com</p>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const viewDimensions = getWebViewDimensions(state);

	return { 
		login: state.login,
		error: state.login.error,
		viewDimensions,
		user : getLoggedUser(state),
		loading: getLoading(state)
	};
};
  
PasswordResetRequestComponent = connect(mapStateToProps)(PasswordResetRequestComponent);

export const PasswordResetRequest = reduxForm({
	form: formId,
})(PasswordResetRequestComponent);
