import React, {Component} from 'react';
import './follower-message.component.less';
import {Avatar} from "../../common/avatar/avatar.component";
import {navigateToLocation, ROUTES} from "../../../app.router";
import {withBadWordMask} from "../../../assets/lib/utils";


class FollowerMessageComponent extends Component {

	onItemClick = () => {
		const {follower}= this.props.item;

		navigateToLocation(ROUTES.USER_PROFILE, {id:follower._id});
	}

	renderMessage = () => {
		const {follower}= this.props.item;

		return (
			<div className="follower-message" onClick={this.onItemClick}>
				<div className="content">
					<div className="content-left">
						<h2>You have a new follower</h2>
						<p><span className="display-name">{withBadWordMask(follower.displayName)}</span> has followed you.</p>
					</div>
					<div className="content-right">
						<Avatar userData={follower}/>
					</div>
				</div>
			</div>
		);
	};
	
	render() {
			return this.renderMessage();
	}
}

export const FollowerMessage = FollowerMessageComponent;