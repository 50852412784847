import React,{Component} from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import inputManager from '../../../assets/lib/inputmanager';
import './generic-popup.component.less';

const navLayer=2;

export const TYPE_HAS_TWO_BUTTONS='hasTwoButtons';
export const TYPE_HAS_NO_BUTTONS='hasNoButtons';

export const MESSAGE_SIZE = {
	LARGE: 'large-text',
	MEDIUM: 'medium-text',
	SMALL: 'small-text'
}

class GenericPopup extends Component {
	componentDidMount() {
		inputManager.setCurrentChildById("GenericPopup-okButton");
		if (!this.props.ignoreBackFunction) {
			inputManager.setBackFunction(this.customBackFunction,navLayer);
		}
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null,navLayer);
	}

	customBackFunction = () => {
		if (this.props.isBackFunctionDisabled) return;

		if(this.props.buttonType !== TYPE_HAS_NO_BUTTONS){
			if(this.props.onCancelClicked || this.props.dismissOnBackFunction)this.onCancelButtonClicked();
			else if(this.props.onOkClicked)this.onOkButtonClicked();
			else this.onCancelButtonClicked();
		}
	};

	onOkButtonClicked = () => {
		if(this.props.onOkClicked)this.props.onOkClicked();
		if(!this.props.isPermanent)closePopup();
	};

	onCancelButtonClicked = () => {
		if(this.props.onCancelClicked)this.props.onCancelClicked();
		if(!this.props.isPermanent)closePopup();
	};

	render() {
		const {
			buttonType,
			message,
			title,
			cancelButtonLabel,
			okButtonLabel,
			genericClassName = '',
			primaryButtonClassName = '',
			messageSize = MESSAGE_SIZE.LARGE,
			children,
		} = this.props;

		return (
			<div className={`generic-popup-component ${genericClassName}`}>
				<div className="title-text">
					{title}
				</div>
				{this.props.message?<div className={`message-text ${messageSize}`} >
					{message}
				</div>:null}
				{children}
				<div className="buttons-container">
					{buttonType===TYPE_HAS_TWO_BUTTONS?<Button
						className="secondary"
						onClick={this.onCancelButtonClicked}
						childId="GenericPopup-cancelButton"
						layer={navLayer}
					>
						{cancelButtonLabel||'Cancel'}
					</Button>:null}
					{buttonType!==TYPE_HAS_NO_BUTTONS?<Button
						className={`primary ${primaryButtonClassName}`}
						onClick={this.onOkButtonClicked}
						childId="GenericPopup-okButton"
						layer={navLayer}
					>
						{okButtonLabel||'OK'}
					</Button>:null}
				</div>
			</div>
		);
	}
};

GenericPopup.propTypes = {
	onSelect: PropTypes.func,
	onOkClicked:PropTypes.func,
	onCancelButtonClicked:PropTypes.func,
	buttonType: PropTypes.oneOf([TYPE_HAS_TWO_BUTTONS, TYPE_HAS_NO_BUTTONS]),
	messageSize: PropTypes.oneOf([MESSAGE_SIZE.LARGE, MESSAGE_SIZE.MEDIUM, MESSAGE_SIZE.SMALL]),
	message: PropTypes.string,
	title: PropTypes.string,
	cancelButtonLabel: PropTypes.string,
	okButtonLabel: PropTypes.string,
	children: PropTypes.node,
};

export default GenericPopup;
