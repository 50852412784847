import React, { Component } from 'react';
import { navigateToLocation, ROUTES } from '../../app.router';
import './leaderboard.component.less';
import { SetTutorialId } from '../tutorial/tutorial.actions.js';
import { LIST_TYPES, SCROLL_DIRECTION_TYPES, SCROLL_EDGE_TYPES } from '../common/list/list-container.component';
import ListContainer from '../common/list/list-container.component';
import { PARENT_ENTRANCE } from '../../assets/lib/inputmanager';
import inputmanager from '../../assets/lib/inputmanager';
import { observe, unobserve } from '../../assets/lib/intersectionObserver';
import podiumWorldwide from '../../assets/images/global/podium-blue.png';
import podiumFollowing from '../../assets/images/global/podium-green.png';
import podiumTwitch from '../../assets/images/global/podium-purple.png';
import podiumXbox from '../../assets/images/global/podium-xbox.png';
import podiumStarhub from '../../assets/images/global/podium-light-green.png';
import podiumKickstarter from '../../assets/images/global/M-image-podium-leaderboard_kickstarter.svg';
import joystickGrey from '../../assets/images/global/joystick-grey.png';
import joystickPurple from '../../assets/images/global/joystick-purple.png';
import globeBlue from '../../assets/images/global/globe-blue.png';
import globeGray from '../../assets/images/global/globe-gray.png';
import starFollowing from '../../assets/images/global/star-green.png';
import starGray from '../../assets/images/global/star-gray.png';
import { connect } from "react-redux";
import { ClearGameLeaderboardAction, FetchGameLeaderboardAction, UpdateLeaderboardListTypeAction } from "../game-details/game-details.actions";
import { isFreeTier, getLoggedUser, getLoggedUserAccess, getRoutingSearch, getTournamentsLeft, getGamesLeft, getChallengesLeft, getFreeTierTournamentsInformation, getFreeTierGamesInformation, getFreeTierChallengesInformation, getGemPlayTournamentsCost, getGemPlayGameCost, getGemPlayChallengeCost, getIsPostAuthFetched, getUserIsSubscribed, getGemPlayChallengeCostV2 } from "../../app.selectors";
import {
    getCurrentGameDetails,
    getCurrentGameDetailsId,
    getCurrentGameLeaderboard,
    getCurrentGameLeaderboardFirstUser, getIsLeaderboardUpdated
} from "../game-details/game-details.selector";
import inputManager from '../../assets/lib/inputmanager';
import PodiumComponent from './podium.component';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import {
	getTournamentShortCode, isFreemiumV4UserNotSubscribed,
	isFreeTierV2FeatureFlagEnabled,
	isXboxCrossplayEnabled,
	isXboxUser
} from "../../assets/lib/utils";
import FilterButton from './filterButton/filterButton.component';
import ShareButton from '../common/share-button/share-button.component';
import { DEEP_LINK_PARAMETER, DEEP_LINK_TARGET, generateDeepLinkURL } from '../../assets/lib/deepLinkGenerator';
import Helmet from '../common/helmet/helmet.component';
import { LEADERBOARD_LIST_ID, unlimitedPlaysKey } from '../../constants';
import { ButtonWithGem } from '../common/button/common-button-with-gem.component';
import { userAccessAllowed } from '../../app.helpers';
import { XboxGamerCardButton } from '../common/xbox-gamer-card-button/xbox-gamer-card-button.component';
import { determineTournamentPlayCost } from '../tournaments/tournaments.utils';
import { LeaderboardItem } from './item/leaderboard-item.component';
import deviceInfo, {isPlayStationPlatform} from '../../assets/lib/deviceInfo';

export const LIST_WORLDWIDE = 'LIST_WORLDWIDE';
const LIST_SOCIAL = 'LIST_SOCIAL';
export const LIST_XBOX = 'LIST_XBOX';

const LOGGED_USER_POSITION_TOP = 'top';
const LOGGED_USER_POSITION_BOTTOM = 'bottom';

const buttonsParentId = 'buttonsContainer'

export const LEADERBOARD_TYPES = {
    TOURNAMENT_LEADERBOARD: 'TOURNAMENT_LEADERBOARD',
    CHALLENGES_LEADERBOARD: 'CHALLENGES_LEADERBOARD',
    GAME_LEADERBOARD: 'GAME_LEADERBOARD',
}

export const externalFocusableComponent = {
    PLAY_BUTTON: 'playButton'
}
const externalFocusableParents = {
    'playButton': buttonsParentId
}

export const defaultButtons = [{
    list: LIST_WORLDWIDE,
    isTranslateText: true,
    text: 'global',
    enabledImage: globeBlue,
    disabledImage: globeGray,
    className: "global",
},{
    list: LIST_SOCIAL,
    isTranslateText: true,
    text: 'following',
    enabledImage: starFollowing,
    disabledImage: starGray,
    className: "following",
}]
export const extraFilterButtons = []

export const ListElementsDetails = {
    LIST_WORLDWIDE:  {
        listClass: 'global',
        podiumImageSource: podiumWorldwide
    },
    LIST_SOCIAL: {
        listClass: 'following',
        podiumImageSource: podiumFollowing,
    },
	LIST_XBOX: {
		listClass: 'xbox',
		podiumImageSource: podiumXbox,
	},
    LIST_TWITCH: {
        listClass: 'twitch',
        podiumImageSource: podiumTwitch,
    },
    LIST_KICKSTARTER: {
        listClass: 'kickstarter',
        podiumImageSource: podiumKickstarter,
    },
    LIST_FILTER_WITH_ACCESS: {
        listClass: 'filter-with-access',
        podiumImageSource: podiumStarhub,
    }
}

export const getEnabledFilterButtons = (buttons, tournamentData, userAccess) => {

    return buttons.reduce((accum, button) => {
        if (tournamentData.leaderboardType === button.tournamentType) {
            if (!button.isFilteredByAccessGroup || userAccessAllowed(tournamentData.leaderboardFilterAccessType, userAccess)) {
                accum.push(button)
            }
        } 
        return accum
    }, [])
}

const generateLeaderBoardDeepLink = (leaderboardType, gameId, challengeId, tournamentId) => {
    if ((leaderboardType === LEADERBOARD_TYPES.TOURNAMENT_LEADERBOARD) && tournamentId) {
        return generateDeepLinkURL(DEEP_LINK_TARGET.TOURNAMENT_LEADERBOARD, {[DEEP_LINK_PARAMETER.TOURNAMENT_ID]: tournamentId});
    } 
    
    if ((leaderboardType === LEADERBOARD_TYPES.GAME_LEADERBOARD) && gameId) {
        return generateDeepLinkURL(DEEP_LINK_TARGET.GAME_LEADERBOARD, {[DEEP_LINK_PARAMETER.GAME_ID]: gameId});
    }
    
    if ((leaderboardType === LEADERBOARD_TYPES.CHALLENGES_LEADERBOARD) && challengeId) {
        return generateDeepLinkURL(DEEP_LINK_TARGET.CHALLENGE_LEADERBOARD, {[DEEP_LINK_PARAMETER.GAME_ID]: gameId, [DEEP_LINK_PARAMETER.CHALLENGE_ID]: challengeId});
    } 

    return generateDeepLinkURL(DEEP_LINK_TARGET.HOMEPAGE, {});
}

const generateTournamentLeaderboardDeepLinkText = (tournamentStatus, tournamentTitle) => {
    const postTournamentMessage = `The ${tournamentTitle} tournament was so good, can’t wait for the next one!`
    return tournamentStatus === 'FINISHED' ? postTournamentMessage : `I am playing in the ${tournamentTitle} tournament, can you beat my score?` 
}
class LeaderboardComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedList: LIST_WORLDWIDE,
			isLoading: false,
			offset: undefined,
			showLoggedUserTile: false,
			loggedUserProfilePosition: "",
			showDuration: false,
		};
		this.lastScrollDirection = null;
		this.alternateDisplayInterval = null;
	}

	alternateDisplay = () => {
		this.setState({ showDuration: !this.state.showDuration });
	}

	componentDidMount() {
		const { dispatch, pageId, isXboxPlayer, isXboxCrossplaySettingEnabled } = this.props;

		dispatch(SetTutorialId(pageId));
		if (isXboxPlayer && !isXboxCrossplaySettingEnabled) {
			this.setList(LIST_XBOX);
		} else {
			this.setList(LIST_WORLDWIDE);
		}
		const { focusElement } = this.props.currentParameters
		if(focusElement){
			const parentElement = externalFocusableParents[focusElement]
			parentElement ? inputManager.setCurrentChildById(focusElement, parentElement) : inputManager.setCurrentChildById(focusElement)
		}
		else if (!inputmanager.currentChild) {
			inputmanager.setCurrentChildById(externalFocusableComponent.PLAY_BUTTON);
		}

	}

	componentWillUnmount() {
		if (this.alternateDisplayInterval !== null) {
			clearInterval(this.alternateDisplayInterval);
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.isLeaderboardUpdated && this.props.isLeaderboardUpdated) this.fetchList(this.state.selectedList);

		// alternate display to show secondaryScore only if at least one item contains a secondaryScore
		if (this.alternateDisplayInterval === null && this.props.leaderboard !== null && this.props.leaderboard.find((item) => item.secondaryScore)) {
			this.alternateDisplayInterval = setInterval(this.alternateDisplay, 6000);
		}

		if (!inputmanager.currentChild) {
			inputmanager.setCurrentChildById('tab-menu-Score', 'tab-menu');
		}
	}

	onTileClick = (id) => {
		navigateToLocation(ROUTES.USER_PROFILE, { id });
	};

	onLoggedUserClicked = (event) => {
		event.preventDefault();
		this.onTileClick(this.props.loggedUserId);
	};

	onLoggedUserTileRef = (element) => {
		if (element) {
			this.tileRef = element;
			this.tileRef.onEnterViewport = this.onEnterViewport;
			this.tileRef.onLeaveViewport = this.onLeaveViewport;
			observe(this.tileRef);
		} else if (this.tileRef) {
			this.tileRef.onEnterViewport = null;
			this.tileRef.onLeaveViewport = null;
			unobserve(this.tileRef);
		}
	};

	onEnterViewport = () => {
		this.setState({ showLoggedUserTile: false });
	};

	onLeaveViewport = () => {
		if (this.lastScrollDirection === SCROLL_DIRECTION_TYPES.UP) this.setState({
			showLoggedUserTile: true,
			loggedUserProfilePosition: LOGGED_USER_POSITION_BOTTOM
		});
		else if (this.lastScrollDirection === SCROLL_DIRECTION_TYPES.DOWN) this.setState({
			showLoggedUserTile: true,
			loggedUserProfilePosition: LOGGED_USER_POSITION_TOP
		});
	};

	onScrollEdge = (type) => {
		const { leaderboard } = this.props;

		if (leaderboard && leaderboard.length > 0) {
			if (type === SCROLL_EDGE_TYPES.START) {
				this.fetchList(this.state.selectedList, Math.max(leaderboard[0].rank - 10, 0));
			} else {
				this.fetchList(this.state.selectedList, leaderboard[leaderboard.length - 1].rank);
			}
		}
	};

	setLastScrollDirection = (type) => {
		this.lastScrollDirection = type;
	};

	renderLeaderboardItem = ({ item, selected, outOfList }) => {
		const isLoggedUserItem = item._id === this.props.loggedUserId && !outOfList;
		if (isLoggedUserItem) this.loggedUser = item;
		const isXboxUser = this.props.isXboxPlayer && !!item.externalAuthentications['XBL'];
		const isPlaystationUser = deviceInfo.isPlayStationPlatform() && !!item.externalIds['PSN'];

		return (
			<LeaderboardItem
				isXboxUser={isXboxUser}
				isPlaystationUser={isPlaystationUser}
				isLoggedUserItem={isLoggedUserItem}
				item={item}
				selected={selected}
				onLoggedUserTileRef={this.onLoggedUserTileRef}
			/>
		);
	};

	setList = (listType) => {
		// if xbox crossplay disabled user selects global leaderboard - show 'Enable cross play to view' message
		if (this.props.isXboxCrossplayDisabledPlayer && listType === LIST_WORLDWIDE) {
			this.setState({selectedList: listType});
			this.props.dispatch(ClearGameLeaderboardAction());
			this.props.dispatch(UpdateLeaderboardListTypeAction(listType));
			this.toggleXboxUserCard(false);
			return null;
		}

		this.fetchList(listType);
		this.toggleXboxUserCard(false);
		this.setState({
			selectedList: listType,
		});
	};

	fetchList = (listType, offset) => {
		const { challengeData, gameId, tournamentData, dispatch } = this.props;
		let queryId;
		let tournamentId;
		let accessType;
		let challengeId = null;

		if (gameId) queryId = gameId;

		if (challengeData) {
			challengeId = challengeData._id;
			queryId = challengeData._id;
		}

		if (tournamentData) {
			queryId = getTournamentShortCode(tournamentData._id, challengeData._id);
			tournamentId = tournamentData._id;
			accessType = tournamentData.leaderboardFilterAccessType || null;
		}

		this.setState({ offset, isLoading: true});

		if (this.props.previousRoute.path === ROUTES.WEBRTC_GAME.path) {
			setTimeout(() => { // if prev page was webrtc game - wait some time to make sure leaderboard data updated
				dispatch(FetchGameLeaderboardAction(listType, queryId, challengeId, tournamentId, offset, accessType, this.onListResponse));
			}, 3000);
		} else {
			dispatch(FetchGameLeaderboardAction(listType, queryId, challengeId, tournamentId, offset, accessType, this.onListResponse));
		}
	};

	onListResponse = (offset) => {
		inputmanager.refreshBoundingRects('playerResults');
		inputmanager.refreshBoundingRects('podium-container');
		this.setState({
			offset,
			isLoading: false
		}, () => {
			if (typeof offset === 'undefined') {
				inputmanager.focusChildById(this.props.loggedUserId, 'playerResults');
				inputmanager.setCurrentChildOfParent('playerResults', this.props.loggedUserId);
				if (!inputmanager.currentChild) {
					inputManager.setCurrentChildById("home", "navBar");
				}
			}

			if (this.props.isXboxPlayer
				&& this.props.loggedUserId
				&& this.props.leaderboard
				&& this.props.leaderboard.find(el => el._id === this.props.loggedUserId)
			) {
				this.toggleXboxUserCard(true);
			}
		});
	};

	onPodiumContainerRef = (element) => {
		inputmanager.onParentRef(element, "podium-container", false, PARENT_ENTRANCE.ONLY_HORIZONTAL);
	};

	toggleXboxUserCard = (flag, userId) => {
		if (this.state.showXboxUserCard === flag && this.state.focusedUserId === userId) return;

		this.setState({showXboxUserCard: flag, focusedUserId: userId});
	}

	onTileFocus = (tileId) => {
		if (!this.props.isXboxPlayer) return;

		const { leaderboard } = this.props;
		const focused = leaderboard.find(el => el._id === tileId);
		const isFocusedXboxUser = focused
			&& focused.externalAuthentications  
			&& !!focused.externalAuthentications['XBL'];
		const XUID = focused
			&& focused.externalIds
			&& focused.externalIds['XBL']
			&& focused.externalIds['XBL'].xuid;

		this.toggleXboxUserCard(isFocusedXboxUser, isFocusedXboxUser ? XUID : null);
	};

	renderResults = () => {
		if (this.state.isLoading && typeof this.state.offset === 'undefined') return null;
		const { leaderboard, isXboxCrossplayDisabledPlayer } = this.props;

		if (!leaderboard || leaderboard.length === 0) {
			const { listClass } = ListElementsDetails[this.state.selectedList]
			const className = `no-result-message ${listClass}`

			// if cross play disabled xbox user and Global leaderboard selected
			if (isXboxCrossplayDisabledPlayer && this.state.selectedList === LIST_WORLDWIDE) {
				return (
					<div className={className}>
						Enable cross play to view
					</div>
				);
			}

			return this.state.selectedList === LIST_SOCIAL ? (
				<div className={className}>
					<p>
						You are not following anyone!
					</p>
					<p>
						Why not check out some of the other users in the global leaderboard
					</p>
				</div>
			) : (
					<div className={className}>
						<p>
							There are no scores as yet logged.
						</p>
						<p>
							Claim your place!
						</p>
					</div>
				);
		}
		const lists = [{
			type: LIST_TYPES.VERTICAL,
			id: LEADERBOARD_LIST_ID,
			title: "leaderboard",
			data: leaderboard,
			renderer: this.renderLeaderboardItem,
			keepCurrentChild: true,
			parentEntrance: PARENT_ENTRANCE.ONLY_HORIZONTAL
		}];

		return (
			<ListContainer
				onTileFocus={this.onTileFocus}
				lists={lists}
				newDataLoading={this.state.isLoading}
				onTileClick={this.onTileClick}
				onScrollEdge={this.onScrollEdge}
				setLastScrollDirection={this.setLastScrollDirection}
			/>
		);
	};

	onTileParentRef = (element) => {
		inputmanager.onParentRef(element, buttonsParentId, false, PARENT_ENTRANCE.BOTH);
	};

	onFilterButtonsContainerRef = (element) => {
		inputmanager.onParentRef(element, "filterButtonsContainer", false, PARENT_ENTRANCE.BOTH);
	};

	handleFilterBtnClick = (list) => {
		this.setState({ showLoggedUserTile: false })
		return this.setList(list);
	}

	determinePlayCost = () => {
		const { isFreemiumV4User, tournamentData={}, leaderboardType = LEADERBOARD_TYPES.GAME_LEADERBOARD, gemPlayCosts, isFreeTierV2User } = this.props;

		if (tournamentData) {
			return determineTournamentPlayCost(tournamentData, gemPlayCosts[leaderboardType]);
		}

		if (isFreeTierV2User || isFreemiumV4User) return null;

		return gemPlayCosts[leaderboardType];
	}

	shouldDisplayShareButton = () => {
		if (this.props.isXboxPlayer) return false;
		if (deviceInfo.isPlayStationPlatform()) return false;

		return true;
	};

	render() {
		const { isFreemiumV4User, gameId, gameData, challengeData={}, onClickPlay, playButtonVisible, tournamentData={}, userAccess, firstUser, isUserFreeTier, leaderboardType = LEADERBOARD_TYPES.GAME_LEADERBOARD, freeTierUsageInformation, freeTierUsageLimit, isFreeTierV2User } = this.props;

		const isFreeTierNumbersNeeded = isUserFreeTier && freeTierUsageLimit[leaderboardType] !== unlimitedPlaysKey
		const usageLeft = isFreeTierNumbersNeeded ? freeTierUsageInformation[leaderboardType] : 0

		const { listClass, podiumImageSource } = ListElementsDetails[this.state.selectedList];
		const filterButtons = tournamentData ? [...defaultButtons, ...getEnabledFilterButtons(extraFilterButtons, tournamentData, userAccess)] : defaultButtons 

		if (this.props.isXboxPlayer) {
			filterButtons.push({
				list: LIST_XBOX,
				isTranslateText: true,
				text: 'xbox',
				enabledImage: joystickPurple,
				disabledImage: joystickGrey,
				className: 'xbox'
			});
		}

		const playCost = this.determinePlayCost();
		const deepLink = generateLeaderBoardDeepLink(leaderboardType, gameId, (challengeData?challengeData._id:""), tournamentData._id);
		const deepLinkText= gameData && gameData.title ? `Remember ${gameData.title}? See if you can beat my score!` : generateTournamentLeaderboardDeepLinkText(tournamentData.status, tournamentData.title)// TODO there is a bug with the tournament leaderboards, they get passed tournament id but it tries to load a game id with it
		const extraPlayText = `${isFreeTierNumbersNeeded ? `(${usageLeft})` : ''}  `
		let playButtonText = `Play${extraPlayText} `;

		const isGameLeaderboardType = leaderboardType === LEADERBOARD_TYPES.GAME_LEADERBOARD;
		const isFreemiumV2User = isGameLeaderboardType && isFreeTierV2User;
		if (isFreemiumV2User || isFreemiumV4User) {
			playButtonText = 'Play on Premium';
		}

		return (
			<div className={`leaderboard-component`}>
				<Helmet
					image={(tournamentData && tournamentData.detailImage) || (gameData && gameData.featured_image_uri)}
				/>
				<div
					className={`content-left  ${tournamentData ? 'tournament' : ''} ${listClass}`}
				>
					<div className="filter-buttons-container" ref={this.onFilterButtonsContainerRef}>
						{
							filterButtons && filterButtons.map((filterButton) => {
								const {list, enabledImage, disabledImage, text, isTranslateText} = filterButton;
								const isActive = this.state.selectedList === filterButton.list;
								return (
									<FilterButton
										{...filterButton}
										key={`${text}-button`}
										onClick={() => this.handleFilterBtnClick(list)}
										isActive={isActive}
										image={isActive ? enabledImage : disabledImage}
										parentId="filterButtonsContainer"
										text={isTranslateText ? text : text}
										imageAlt={`${text}-icon`}
										childId={`${text}-button`}
										
								/>)
							})
						}
					</div>

					<PodiumComponent
						onPodiumContainerRef={this.onPodiumContainerRef}
						firstUser={firstUser}
						podiumImageSource={podiumImageSource}
					/>
					<div className="buttons-container" ref={this.onTileParentRef}>
						{playButtonVisible && featureInfo.isSupported(FEATURES.GAMEPLAY) && 
							<ButtonWithGem
								className={`primary two-line ${(isFreemiumV2User || isFreemiumV4User) ? 'freemium-v2-benefits' : ''}`}
								onClick={onClickPlay}
								cost={(isFreemiumV2User || isFreemiumV4User) ? null : playCost}
								childId={externalFocusableComponent.PLAY_BUTTON}
								parentId={buttonsParentId}
							>
								{playButtonText}
							</ButtonWithGem>
						}
						{this.shouldDisplayShareButton() &&
							<ShareButton
								text={deepLinkText}
								deepLink={deepLink}
							/>}
					</div>
				</div>
				<div className="content-right">
					{this.state.showXboxUserCard && <XboxGamerCardButton xboxUserId={this.state.focusedUserId} />}
					<div className={`leaderboard-container ${listClass} ${this.state.showDuration ? 'showDuration' : 'showTimeAgo'}`}>
						<div className={`gradient-top-border`} />
						{
							this.renderResults()
						}
						<div
							className={`logged-user-container ${this.state.loggedUserProfilePosition}`}
							onClick={this.state.showLoggedUserTile && this.loggedUser ? this.onLoggedUserClicked : null}
						>
							{
								this.state.showLoggedUserTile && this.loggedUser && this.renderLeaderboardItem({
									item: this.loggedUser,
									outOfList: true
								})
							}
						</div>
						<div className={`loading-container ${this.state.isLoading ? 'visible' : null}`}>
							Loading...
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const loggedUserId = getLoggedUser(state)._id;
	const userAccess = getLoggedUserAccess(state);
	const gameId = getCurrentGameDetailsId(state);
	const isUserFreeTier = isFreeTier(state);
	const freeTierUsageInformation = isUserFreeTier ? {
		[LEADERBOARD_TYPES.GAME_LEADERBOARD]: getGamesLeft(state),
		[LEADERBOARD_TYPES.CHALLENGES_LEADERBOARD]: getChallengesLeft(state),
		[LEADERBOARD_TYPES.TOURNAMENT_LEADERBOARD]: getTournamentsLeft(state),
	} : {}

	const freeTierUsageLimit = isUserFreeTier ? {
		[LEADERBOARD_TYPES.GAME_LEADERBOARD]: getFreeTierGamesInformation(state).limit,
		[LEADERBOARD_TYPES.CHALLENGES_LEADERBOARD]: getFreeTierChallengesInformation(state).limit,
		[LEADERBOARD_TYPES.TOURNAMENT_LEADERBOARD]: getFreeTierTournamentsInformation(state).limit,
	} : {}

	const isFreeTierUserV2 = isFreeTierV2FeatureFlagEnabled(state)
		&& getIsPostAuthFetched(state)
		&& !getUserIsSubscribed(state);

	const gemPlayCosts = {
		[LEADERBOARD_TYPES.GAME_LEADERBOARD]: getGemPlayGameCost(state),
		[LEADERBOARD_TYPES.CHALLENGES_LEADERBOARD]: isFreeTierUserV2
			? getGemPlayChallengeCostV2(state)
			: getGemPlayChallengeCost(state),
		[LEADERBOARD_TYPES.TOURNAMENT_LEADERBOARD]: getGemPlayTournamentsCost(state),
	}
	const isXboxPlayer = isXboxUser(state);

	return {
		isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
		isXboxPlayer,
		isXboxCrossplaySettingEnabled: isXboxCrossplayEnabled(state),
		isXboxCrossplayDisabledPlayer: isXboxPlayer && !isXboxCrossplayEnabled(state),
		isFreeTierV2User: isFreeTierUserV2,
		previousRoute: state.routing.previousRoute,
		gameId,
		loggedUserId,
		userAccess,
		gameData: getCurrentGameDetails(state),
		leaderboard: getCurrentGameLeaderboard(state),
		isLeaderboardUpdated: getIsLeaderboardUpdated(state),
		firstUser: getCurrentGameLeaderboardFirstUser(state),
		currentParameters: getRoutingSearch(state),
		freeTierUsageInformation,
		freeTierUsageLimit,
		isUserFreeTier,
		gemPlayCosts,
	};
};

export const Leaderboard = connect(mapStateToProps)(LeaderboardComponent);