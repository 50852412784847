import React from 'react';
import {antstreamService, antstreamAPIService} from '../../app.reducer';
import {RunGameAction} from '../game-details/game-details.actions';
import {startBandWidthTest} from '../../assets/lib/game-wrapper';
import {getBandwidthTestResults} from './settings.selector';
import {ShowNotificationAction} from "../notifications/notifications.actions";
import {setCurrentRegion} from "../../app.actions";
import {readRestUserData} from '../../assets/lib/local-storage';
import inputManager from '../../assets/lib/inputmanager';
import { handleNoAuthTokenIssue } from '../../assets/lib/utils';

const SET_DEBUG_SETTINGS_ACTION = 'Set Debug Settings Action';
export const SetDebugSettingsAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: SET_DEBUG_SETTINGS_ACTION, payload });

		const { authToken, userId } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(SET_DEBUG_SETTINGS_ACTION);

		const params = {
			headers: { Authorization: 'Bearer ' + authToken },
			body: {
				operation: 'postDebugSettings',
				playerId: userId,
				authToken: authToken,
				debugSettings: payload
			}
		};

		antstreamAPIService.player.debugSettingsCreate(params).then(({ data }) => {
			if (!data) throw new Error('Something went wrong');
			if (data.error) throw new Error(data.error);
			dispatch(SetDebugSettingsActionSuccess(payload));
			dispatch(ShowNotificationAction("Settings Saved"));
		}).catch(catchErr => {
			console.error('Failed to save settings ' + catchErr.message);
			dispatch(ShowNotificationAction("Failed to save settings. Please try later."));
		});
	};
};

export const SET_DEBUG_SETTINGS_ACTION_SUCCESS = 'Set Debug Settings Success';
const SetDebugSettingsActionSuccess = (payload) => ({
	type: SET_DEBUG_SETTINGS_ACTION_SUCCESS, payload
});

const FETCH_DEBUG_SETTINGS_ACTION = 'Fetch Debug Settings Action';
export const FetchDebugSettingsAction = (onComplete) => {
	return (dispatch) => {
		dispatch({ type: FETCH_DEBUG_SETTINGS_ACTION });

			const { authToken, userId } = readRestUserData() || {};
			if (!authToken) handleNoAuthTokenIssue(FETCH_DEBUG_SETTINGS_ACTION);

			const params = {
				headers: { Authorization: 'Bearer ' + authToken },
				body: {
					operation: 'getDebugSettings',
					playerId: userId,
					authToken: authToken
				}
			};
			antstreamAPIService.player
				.debugSettingsCreate(params)
				.then(({ data }) => {
					if (!data) throw new Error('Something went wrong');
					if (data.error) throw new Error(data.error);
					dispatch(FetchDebugSettingsActionSuccess(data.body));
					if (onComplete) onComplete();
				})
				.catch(catchErr => {
					// addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
				   	console.error('Error /player/debug-settings : ' + catchErr.message);
				});

	};
};

export const FETCH_DEBUG_SETTINGS_ACTION_SUCCESS = 'Fetch Debug Settings Success';
const FetchDebugSettingsActionSuccess = (payload) => ({
	type: FETCH_DEBUG_SETTINGS_ACTION_SUCCESS, payload
});

export const SET_USER_REGION_ACTION = 'Set User Region';
export const SetUserRegionAction = (payload, regionObj) => {
	return (dispatch) => {
		dispatch({ type: SET_USER_REGION_ACTION, payload });

			const { authToken } = readRestUserData() || {};
			if (!authToken) handleNoAuthTokenIssue(SET_USER_REGION_ACTION);

			const updRegionBody = {
				requestId: new Date().getTime() + "",
				data: {UserRegion: payload}
			};
			const updRegionOpts = {headers: {'Authorization': "Bearer " + authToken}};
			antstreamAPIService.player.regionUpdate(updRegionBody, updRegionOpts).then(({data}) => {
				if (data.result === 'OK') {
					dispatch(setCurrentRegion(regionObj.code));
					dispatch(ShowNotificationAction("Region Saved"));
					inputManager.setCurrentChildById("regionDropdown");

					
				}
			}).catch(regionUpdateErr => {
				console.error('Region update error: ', regionUpdateErr.message);
			});

	};
};

export const GET_USER_REGION_ACTION = 'Get User Region';
export const GetUserRegionAction = (callback) => {
	return (dispatch) => {
			const { authToken } = readRestUserData() || {};
			if (!authToken) handleNoAuthTokenIssue(GET_USER_REGION_ACTION);

			const regionsListOpts = {headers: {'Authorization': "Bearer " + authToken}};
			antstreamAPIService.regions.regionsList(regionsListOpts).then(({data}) => {
				const {regions, currentRegionObject} = data.data;
				if (data) {
					dispatch({type: GET_USER_REGION_ACTION, regions, currentRegionObject});
					if (callback) callback();
				}
			}).catch(regionsListErr => {
			});

	};
};


export const SET_CONNECTION_TESTER_SUPPORTED = 'SET_CONNECTION_TESTER_SUPPORTED';
export const SetConnectionTesterSupported = (connectionTesterSupported) => {
	return (dispatch) => {
		dispatch({ type: SET_CONNECTION_TESTER_SUPPORTED, connectionTesterSupported });
	};
};


export const SET_DISPLAY_NAME_ACTION = 'Set DisplayName Action';
export const SetDisplayNameAction = (payload, onSuccess, onError) => {
	return (dispatch) => {
		dispatch({ type: SET_DISPLAY_NAME_ACTION, payload });

		const { authToken, userId } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(SET_DISPLAY_NAME_ACTION);

			const updNameBody = {
				operation: '/player/name',
				newDisplayName: payload,
				accessToken: authToken,
				playerId: userId
			};
			const updNameOpts = {headers: {'Authorization': "Bearer " + authToken}};
			antstreamAPIService.player.nameUpdate(updNameBody, updNameOpts).then(({data}) => {
				if (data.body && data.body.result === "OK") {
					dispatch(SetDisplayNameActionSuccess({displayName: data.body.newDisplayName}));
					onSuccess({displayName: data.body.newDisplayName});
				} else {
					onError(data);
				}
			}).catch(updNameErr => {
				onError(updNameErr);
			});

	};
};

export const SET_DISPLAY_NAME_ACTION_SUCCESS = 'Set DisplayName Success';
export const SetDisplayNameActionSuccess = (payload) => {
	return { type: SET_DISPLAY_NAME_ACTION_SUCCESS, payload };
};

export const ON_TEST_SESSION_CREATED = 'ON_TEST_SESSION_CREATED';
export const onTestSessionCreated = () => {
	return { type: ON_TEST_SESSION_CREATED };
};

export const ON_TEST_SESSION_REQUESTED = 'ON_TEST_SESSION_REQUESTED';
export const onTestSessionRequested = () => {
	return (dispatch) => {
		dispatch({ type: ON_TEST_SESSION_REQUESTED });
		dispatch(RunGameAction({
			gameId:"732d84b4-20bd-4c0b-8eab-580fa33f9bd6",
			challengeStyle: 'test',
		}));
	};
};

export const CLEAR_TEST_SESSION_DATA = 'CLEAR_TEST_SESSION_DATA';
export const clearTestSessionData = () => {
	return { type: CLEAR_TEST_SESSION_DATA };
};

export const REQUEST_BANDWIDTH_TEST = 'REQUEST_BANDWIDTH_TEST';
export const requestBandWidthTest = (url) => {
	return (dispatch) => {
		dispatch({ type: REQUEST_BANDWIDTH_TEST,url });
		startBandWidthTest(url);
	}
};

export const ON_BANDWIDTH_TEST_RESULTS = 'ON_BANDWIDTH_TEST_RESULTS';
export const onBandwidthTestResults = (payload) => {
	return { type: ON_BANDWIDTH_TEST_RESULTS, payload };
};

export const CLEAR_BANDWIDTH_TEST_DATA = 'CLEAR_BANDWIDTH_TEST_DATA';
export const clearBandwidthTestData = () => {
	startBandWidthTest('',true);
	return { type: CLEAR_BANDWIDTH_TEST_DATA };
};

export const TEST_RESULT_TYPES = {
	BANDWIDTH:"BANDWIDTH",
	INPUT_LAG:"INPUT_LAG",
	VID_EFFICIENCY:"VID_EFFICIENCY",
	PACKETS_RECEIVED:"PACKETS_RECEIVED",
	PACKETS_LOST:"PACKETS_LOST",
	PACKETS_PERCENTAGE:"PACKETS_PERCENTAGE",
	CONNECTION_TYPE:"CONNECTION_TYPE",
};

const TEST_RESULT_NAMES = {
	BANDWIDTH:"Bandwidth",
	INPUT_LAG:"Input Lag",
	VID_EFFICIENCY:"Streaming",
	PACKETS_PERCENTAGE:"Packetloss",
	CONNECTION_TYPE:"Connections",
};

const TEST_RESULT_BREAKPOINTS = {
	BANDWIDTH:{POOR:2, GOOD:3, EXCELLENT:4},
	INPUT_LAG:{EXCELLENT:40, GOOD:60, OK:80,  POOR:100},
	VID_EFFICIENCY:{POOR:0.8, OK:0.9, GOOD:0.95, EXCELLENT:0.99},
	PACKETS_PERCENTAGE:{EXCELLENT:0,  GOOD:0.05, OK:0.1, POOR:1},
	CONNECTION_TYPE:{OK:0, EXCELLENT:1},
};

const TEST_RESULT_FIELD_NAMES = {
	INPUT_LAG:"InputLagAvg",
	VID_EFFICIENCY:"VidEfficiency",
	PACKETS_RECEIVED:"PacketsReceived",
	PACKETS_LOST:"PacketsLost",
	PACKETS_PERCENTAGE:"PacketsPercentage",
	CONNECTION_TYPE:"ConnectionType",
};

const generateTestResultObject = (type,value) => {
	const sourceArray = Object.keys(TEST_RESULT_BREAKPOINTS[type]);
	const emojiIndex = sourceArray.findIndex(key => {
		const breakPoint = TEST_RESULT_BREAKPOINTS[type][key];
		return value<=breakPoint;
	});
	const emojiType = sourceArray[emojiIndex===-1?sourceArray.length-1:emojiIndex];
	let withBar = true;

	if(type===TEST_RESULT_TYPES.CONNECTION_TYPE){
		withBar = false;
		value = parseInt(value)===1?'Connection via UDP':'Connection via TCP';
	}else if(type===TEST_RESULT_TYPES.BANDWIDTH){
		value=value+'Mbps';
	} else if(type===TEST_RESULT_TYPES.INPUT_LAG){
		value=value+'ms';
	} else if(type===TEST_RESULT_TYPES.PACKETS_PERCENTAGE){
		value=value+'%';
	} else if(type===TEST_RESULT_TYPES.VID_EFFICIENCY){
		value=Math.round(value*100)+'%';
	}

	return {
		type,
		name: TEST_RESULT_NAMES[type],
		value,
		withBar,
		emojiType
	};
};

export const ON_CONNECTION_TEST_RESULTS = 'ON_CONNECTION_TEST_RESULTS';
export const onConnectionTestResults = (payload) => {
	return (dispatch, getState) => {
		// dispatch(onBandwidthTestResults({bytesDownloaded:'21878000',millisecsTaken:'6000'}));
		// payload =  {"type":"connectionTestResults","metrics":{"BandwidthIn": "22.215 Mbps (2.777 MBps)","VidFPSAvg": "25.0","VidFPSMin": "18.0","VidFPSMax": "41.0","VidEfficiency": "0.19","VidDecodeFPSAvg": "44.4","VidDecodeFPSMin": "34.0","VidDecodeFPSMax": "54.0","VidDecodeEfficiency": "0.13","AudFPSAvg": "0.0","AudFPSMin": "0.0","AudFPSMax": "0.0","PingLast": "0.00","PingAverage": "0.00","PingMin": "0","PingMax": "0","PacketsReceived": "7259","PacketsLost": "249","Burstiness": "5.82","ConnectionLast": "1","ConnectionAvg": "1","ConnectionMin": "1","ConnectionMax": "1","ConnectionType": "1","LastInputTime": "12785","VideoLagAvg": "14.18","VideoLagMin": "9","VideoLagMax": "23","InputLagAvg": "0","InputLagMin": "0","InputLagMax": "0","AudBytesIn": "0","VidBytesIn": "4334923"}};

		const metrics = payload.metrics;
		const resultObjects = [];
		const packetLossValue = parseFloat(metrics[TEST_RESULT_FIELD_NAMES.PACKETS_LOST]) / parseFloat(metrics[TEST_RESULT_FIELD_NAMES.PACKETS_RECEIVED]);

		const bandwidthResults = getBandwidthTestResults(getState());
		let bandwidthValue = 0;
		if(bandwidthResults){
			const {bytesDownloaded,millisecsTaken} = bandwidthResults;
			const seconds = parseFloat(millisecsTaken) / 1000;
			const downloadedMegabits= parseFloat(bytesDownloaded*8) / 1000000;
			bandwidthValue = (parseFloat(downloadedMegabits) / seconds).toFixed(2);
		}

		resultObjects.push(generateTestResultObject(TEST_RESULT_TYPES.BANDWIDTH,bandwidthValue));
		resultObjects.push(generateTestResultObject(TEST_RESULT_TYPES.INPUT_LAG,metrics[TEST_RESULT_FIELD_NAMES.INPUT_LAG]));
		resultObjects.push(generateTestResultObject(TEST_RESULT_TYPES.VID_EFFICIENCY,metrics[TEST_RESULT_FIELD_NAMES.VID_EFFICIENCY]));
		resultObjects.push(generateTestResultObject(TEST_RESULT_TYPES.PACKETS_PERCENTAGE,parseFloat( Math.round(packetLossValue * 100) / 100).toFixed(2)));
		resultObjects.push(generateTestResultObject(TEST_RESULT_TYPES.CONNECTION_TYPE,metrics[TEST_RESULT_FIELD_NAMES.CONNECTION_TYPE]));
		dispatch({ type: ON_CONNECTION_TEST_RESULTS, payload:resultObjects });
	}
};

export const UPDATE_USER_LANGUAGE_ACTION = 'UPDATE_USER_LANGUAGE_ACTION';
export const UpdateUserLanguageAction = (language) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_USER_LANGUAGE_ACTION, language });
	};
};

export const SET_CONTROL_PREFERENCES_DPAD_ACTION = 'SET_CONTROL_PREFERENCES_DPAD_ACTION';
export const SetControlPreferencesDpadAction = (dpad) => {
    return (dispatch) => {
        dispatch({ type: SET_CONTROL_PREFERENCES_DPAD_ACTION, payload: dpad });
    }
}

export const SET_CONTROL_PREFERENCES_RUMBLE_ACTION = 'SET_CONTROL_PREFERENCES_RUMBLE_ACTION';
export const SetControlPreferencesRumbleAction = (rumble) => {
    return (dispatch) => {
        dispatch({ type: SET_CONTROL_PREFERENCES_RUMBLE_ACTION, payload: rumble });
    }
}

export const SET_CONTROL_PREFERENCES_TRANSPARENCY_ACTION = 'SET_CONTROL_PREFERENCES_TRANSPARENCY_ACTION';
export const SetControlPreferencesTransparencyAction = (transparency) => {
    return (dispatch) => {
        dispatch({ type: SET_CONTROL_PREFERENCES_TRANSPARENCY_ACTION, payload: transparency });
    }
}

export const SET_CONTROL_PREFERENCES_VOLUME_ACTION = 'SET_CONTROL_PREFERENCES_VOLUME_ACTION';
export const SetControlPreferencesVolumeAction = (volume) => {
    return (dispatch) => {
        dispatch({ type: SET_CONTROL_PREFERENCES_VOLUME_ACTION, payload: volume });
    }
}
