import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentGame, getContentByType } from '../how-to-play.selector';
import { ScrollableBox } from '../../common/scrollable-box/scrollable-box.component';
import inputManager from '../../../assets/lib/inputmanager';
import './how-to-play-instructions.less';
import { getRoutingSearch } from '../../../app.selectors';
import deviceInfo from '../../../assets/lib/deviceInfo';

class HowToPlayInstructionsComponent extends Component {
	renderDescription = (content, id) => {
		if (!content['help-full']) return;
		let removeTerminology = content['help-full'].replace(/right thumb-stick|right thumbstick/gi, 'R3')
		removeTerminology = removeTerminology.replace("Playstation", "PS4™");
		if (deviceInfo.isPlayStationPlatform()) {
			removeTerminology = removeTerminology.replace(/gamepad|gamepads|controller|controllers|control pad/gi, 'wireless controller');
			removeTerminology = removeTerminology.replace(/Direction buttons|Directional buttons|Directional keys|Movement controls|Movement buttons|Movement keys|Movement/gi, 'up/down/left/right button');

			removeTerminology = removeTerminology.replace(/(Up Key|Down Key|Left Key|Right Key|Up|Down|Left|Right)/g, '$1 button');
			removeTerminology = removeTerminology.replace(/button button/g, 'button');
			removeTerminology = removeTerminology.replace(/wireless wireless/g, 'wireless');
			removeTerminology = removeTerminology.replace(/left analogue stick/gi, 'left stick');
			removeTerminology = removeTerminology.replace(/right analogue stick/gi, 'right stick');
		}

		return (
			<ScrollableBox className="content-left" parentId="instructionsContainer" childId="howToPlayBox">
				<h3>How to play</h3>
				<div dangerouslySetInnerHTML={{__html: removeTerminology}}/>
			</ScrollableBox>
		);
	};

	renderTrivia = (content, id) => {
		if (!content.trivia) return;

		let removeTerminology = content.trivia.replace(/playstation [2|4]/gi, "32-Bit Console");
		removeTerminology = removeTerminology.replace(/playstation/gi, "32-Bit Console");
		return (
			<ScrollableBox className="side-info" parentId="instructionsContainer" childId="triviaBox">
				<h3>Trivia</h3>
				<div dangerouslySetInnerHTML={{__html: removeTerminology}}/>
			</ScrollableBox>
		);
	};

	onInstructionsContainerRef = (element) => {
		inputManager.onParentRef(element,"instructionsContainer");
	};

	render() {
		return (
			<div
				className="instructions"
				ref={this.onInstructionsContainerRef}
			>
				{this.renderDescription(this.props.contentByType, this.props.id)}
				{this.renderTrivia(this.props.contentByType, this.props.id)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		id: getRoutingSearch(state).id,
		currentGame: getCurrentGame(state),
		contentByType: getContentByType(state),
	};
};

export const HowToPlayInstructions = connect(mapStateToProps)(HowToPlayInstructionsComponent);