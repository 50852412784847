import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCurrentUserDetailsId } from './user-details.selectors';
import { GetOtherUserDetailsAction } from '../../entities/users/users.actions';
import {getOtherUserDetails} from '../../entities/entities.selectors';
import { TabMenu } from '../common/tab-menu/tab-menu.component';
import { ROUTES, navigateToLocation } from '../../app.router';
import './user-details.component.less';
import {getLoggedUser} from '../../app.selectors';
import {withBadWordMask} from "../../assets/lib/utils";
import inputmanager from "../../assets/lib/inputmanager";
import { UserDetailsProfile } from './profile/user-details-profile.component';
import { UserDetailsFavourites } from './favourites/user-details-favourites.component';

class UserDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.menuSelect = this.onMenuSelect.bind(this);

		this.MENU = [
			{title: "Profile", route: ROUTES.USER_PROFILE, index:0},
		];
	}

	componentDidMount() {
		this.props.dispatch(GetOtherUserDetailsAction(this.props.id));

		if (!inputmanager.currentChild) { // if no focus - focus on play now 
			inputmanager.setCurrentChildById("home", "navBar");
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.id === nextProps.myUserId) {
			navigateToLocation(ROUTES.MY_PROFILE_INFO);
		}
	}

	onMenuSelect(menuItem) {
		const id = this.props.id;
		navigateToLocation(menuItem.route, {id});
	}

	render() {
		const {details,id,myUserId} = this.props;
		if (!details || id===myUserId)return null;

		return (
			<section className="user-details">
				<h1>{withBadWordMask(details.displayName)}</h1>
				<TabMenu
					items={this.MENU}
					onSelect={this.menuSelect}
				>
					<>
						<Route path={ROUTES.USER_PROFILE.path} component={UserDetailsProfile} />
						<Route path={ROUTES.USER_FAVOURITES.path} component={UserDetailsFavourites} />
					</>
				</TabMenu>
		 	</section>
		);
	}
}

const mapStateToProps = (state) => {
	const myUserId = getLoggedUser(state)._id;

	return { 
		id: getCurrentUserDetailsId(state),
		details: getOtherUserDetails(state),
		myUserId,
	};
};

export const UserDetails = connect(mapStateToProps)(UserDetailsComponent);