import React, { Component } from 'react';
import '../settings.component.less';
import deviceInfo from '../../../assets/lib/deviceInfo';
import {Button} from '../../common/button/common-button.component';
import {navigateToLocation, ROUTES} from '../../../app.router';
import { connect } from 'react-redux';
import {getConnectionTesterSupported} from '../settings.selector';
import { openUrlOnDefaultBrowser } from '../../../assets/lib/game-wrapper';
import {addPopup} from "../../popup/popup.component";
import QrCodePopup from "../../popup/qr-code-popup/qr-code-popup.component";
import { getCurrentDeviceName, isXboxUser } from '../../../assets/lib/utils';
import supportImage from "../../../assets/images/qr-codes/antstream-zendesk-com.png";
import supportEmailImage from "../../../assets/images/qr-codes/support@antstream-zendesk-com.png";
import inputManager from "../../../assets/lib/inputmanager";

const TEST_CONNECTION_BTN_ID = 'testYourConnectionButton';

class SettingsSupportComponent extends Component {

	onTestConnectionClicked = () => {
		navigateToLocation(ROUTES.TEST_CONNECTION);
	};

	componentDidMount() {
		// focus on connection button for xbox users
		if (!inputManager.currentChild && this.shouldDisplayConnectionButton() && (this.props.isXboxUser || deviceInfo.isPlayStationPlatform())) {
			inputManager.setCurrentChildById(TEST_CONNECTION_BTN_ID);
		}
	}

	onSupportLinkClicked = () => {
		if (this.props.isXboxUser || deviceInfo.isPlayStationPlatform()) {
			addPopup(<QrCodePopup
				title='Support - QR Code Link'
				image={supportImage}
			/>);
			return;
		}
		openUrlOnDefaultBrowser('https://antstream.zendesk.com');
	};

	onSupportEmailClicked = () => {
		if (this.props.isXboxUser || deviceInfo.isPlayStationPlatform()) {
			addPopup(<QrCodePopup
				title='Email - QR Code Link'
				image={supportEmailImage}
			/>);
			return;
		}
		openUrlOnDefaultBrowser('mailto:support@antstream.zendesk.com');
	};

	shouldDisplayConnectionButton = () => {
		return deviceInfo.isRunningOnNative()
			&& this.props.isConnectionTesterSupported
		;
	}

	render() {
		return (
			<div className="settings-support">
				{this.shouldDisplayConnectionButton() && <div className="test-connection-container">
					<div className="test-explanation-text">
						<p>Check your connection quality to our Antstream Arcade servers.</p>
						<p>{`Please note that speeds may vary depending on your ${getCurrentDeviceName()}, the time of the day and how many users are online at your service provider's local exchange.`}</p>
					</div>
					<Button className="secondary" onClick={this.onTestConnectionClicked} childId={TEST_CONNECTION_BTN_ID}>Test Your Connection</Button>
				</div>}
				<div className="support-info-container">
					<p>For support or questions please go to <Button childId='3142134624537' className='focusable-hyperlink' onClick={this.onSupportLinkClicked}>https://antstream.zendesk.com</Button> <br/>or you can e-mail <Button childId='2465735768648' className='focusable-hyperlink' onClick={this.onSupportEmailClicked}>support@antstream.zendesk.com</Button>.</p>
					
					{deviceInfo.version &&
						<p className="version-text">Version : {deviceInfo.version}</p>
					}
				</div>
				{deviceInfo.internalVersion && <div className="support-info-container">
					<p className="version-text">Internal Version : {deviceInfo.internalVersion}</p>
				</div>}
			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		isXboxUser: isXboxUser(state),
		isConnectionTesterSupported:getConnectionTesterSupported(state),
	};
};

export const SettingsSupport = connect(mapStateToProps)(SettingsSupportComponent);
