import React from 'react';

import { Button } from './common-button.component';
import './common-button-with-upvote.component.less';
import thumbImage from '../../../assets/images/generic-icons/thumb_icon.svg';

const ButtonUpvote = (props) => {
	return (
		<Button
			{...props}
			className={`${props.className} with-upvote`}
		>
			<div>
				{props.children}
			</div>
			<div className="upvote-image-container">
				<img className="upvote-image" src={thumbImage} alt="upvote"/>
			</div>
		</Button>
	);
};

export { ButtonUpvote };
