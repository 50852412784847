// import './wdyr'; // TODO - this seems to break on waysun devices
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app.component';
import './assets/lib/ObjectPolyfill';
import {unregister} from './registerServiceWorker';
import { runUnitTests } from './assets/lib/unitTests';

import {unitTestDeepLinks}  from './assets/lib/testDeepLinks';
import {unitTestLocalStorage}  from './assets/lib/testLocalStorage';
import {registerUnitTest} from './assets/lib/unitTests';
import { unitTestAppHelpers } from './app.helpers.test';

/*
registerUnitTest(unitTestAppHelpers);
registerUnitTest(unitTestLocalStorage);
registerUnitTest(unitTestDeepLinks);
runUnitTests();  // Would be nice if we could do this automatically on internal branch builds, but not live
*/

import AntstreamDown from './assets/images/as-down.png';

console.log('[TEMP log]: INDEX_JS_FILE_LOADED'); // todo remove this

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
unregister();
