import React, {Component} from 'react';
import { connect } from 'react-redux';
import {addAnimationToStack, cancelHeaderAnimations, updateGemAnimationData, updateExpAnimationData} from '../../user-header/user-header.component';
import inputManager from '../../../assets/lib/inputmanager';
import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import RewardItem, {ITEM_TYPE_GEM, ITEM_TYPE_EXP, ITEM_TYPE_ACHIEVEMENTS, ITEM_TYPE_MEDAL} from './reward-item.component';
import './reward-popup.component.less';
import {TYPE_EXP, TYPE_GEM} from '../../animations/gem.animation';
import {generateUUID, isLowSpecDevice} from '../../../app.helpers';
import {getAssetsUrlWithFolder} from '../../../app.selectors';
import {FOLDER_TYPES} from '../../../assets/lib/FolderTypes';
import {promptForReview} from "../../../assets/lib/game-wrapper";
import deviceInfo from '../../../assets/lib/deviceInfo';

const navLayer=2;

const TYPE_REWARD_SOLO_CHALLENGE = 'solo_challenge';
const TYPE_REWARD_ACHIEVEMENTS = 'achievement_unlocked';

const TYPE_ANIMATION_CONTAINER = 'rewardPopup';

class RewardPopup extends Component {
	constructor(props) {
		super(props);

		this.onContinueClicked = this.onContinueClicked.bind(this);
		this.onShareClicked = this.onShareClicked.bind(this);
		this.startAnimation = this.startAnimation.bind(this);
		this.expRef=null;
		this.gemRef=null;
		this.containerId = generateUUID();

		if(!this.props.response)return;

		const { reward, gems, gemsObject, exp, expObject} = this.props.response;
		let title = null;
		let bigImgSrc = null;
		let resultContent = null;
		let messageTitle = null;
		let messageContent = null;
		let medalImgSrc = null;
		let achievementImgSrc = null;
		let type = null;
		this.gemsObject = gemsObject;
		this.expObject = expObject;

		if(reward) {
			type = reward.type;
			if(type === TYPE_REWARD_SOLO_CHALLENGE) {
				messageTitle = reward.title;
				messageContent = reward.description;
				bigImgSrc = this.props.challengesGamesFolder + reward.challengeImgSrc;
				resultContent = `${reward.userScore} ${reward.scoreLabel}`;
				medalImgSrc = this.props.challengesMedalsFolder + reward.medalImg;
			}else if(type === TYPE_REWARD_ACHIEVEMENTS) {
				messageTitle = reward.title;
				messageContent = reward.description;
				achievementImgSrc = this.props.achievementsFolder + reward.achievementImg;
				bigImgSrc = this.props.brochureContentsFolder + (reward.gameDetails.image_uri || reward.gameDetails.featured_image_uri);
			}
			title = reward.gameDetails.title;
		}

		this.state = {
			type,
			title,
			bigImgSrc,
			messageTitle,
			messageContent,
			resultContent,
			medalImgSrc,
			achievementImgSrc,
			gemCount:gems || -1,
			expCount:exp || -1,
		};
	}

	componentDidMount() {
		this.startAnimations();
		inputManager.setCurrentChildById("RewardPopup-okButton");
		inputManager.setBackFunction(()=>{this.onContinueClicked()},navLayer);
	}

	componentDidUpdate() {
		this.startAnimations();
	}

	startAnimations() {
		if(!this.animationsStarted) {
			this.animationsStarted = true;
			this.delayTimer = setTimeout(()=> {
				this.delayTimer = null;
				if(isLowSpecDevice()) { //without animation.
					updateGemAnimationData(this.gemsObject);
					updateExpAnimationData(this.expObject);
				} else {
					if(this.state.gemCount!==-1) this.startAnimation(TYPE_GEM,this.state.gemCount);
					if(this.state.expCount!==-1) this.startAnimation(TYPE_EXP,this.state.expCount);
				}
			}, 1000);
		}
	}

	componentWillUnmount() {
		this.didUnmounted=true;
		cancelHeaderAnimations(TYPE_ANIMATION_CONTAINER,this.containerId);
		if(this.delayTimer) { //if popup closed before timer end, it should update header immediately
			clearTimeout(this.delayTimer);
			this.delayTimer=null;
			updateGemAnimationData(this.gemsObject);
			updateExpAnimationData(this.expObject);
		}

		inputManager.setBackFunction(null,navLayer);
	}

	shouldPromptForReview = () => {
		if (!deviceInfo.isDeviceIphone()) return;
		if (this.state.type !== TYPE_REWARD_ACHIEVEMENTS) return;
		if (this.state.messageTitle !== "The Bronze Age") return;

		return true;
	};

	onContinueClicked() {
		if(this.props.onContinueClicked)this.props.onContinueClicked();

		closePopup();

		if (this.shouldPromptForReview()) { // On platforms that support it, this is a good place to ask the user to rate the app
			promptForReview();
		}
	}

	onShareClicked() {
		if(this.props.onShareClicked)this.props.onShareClicked();
		closePopup();
	}

	startAnimation(animationType, count) {
		let startPoint;
		if(count>20)count=20;

		if(animationType==='gem') {
			if(!this.gemRef)return null;
			const gemRect = this.gemRef.getBoundingClientRect();
			startPoint = {
				x:gemRect.left,
				y:gemRect.top,
			};
		}else if (animationType==='exp') {
			if(!this.expRef)return null;
			const expRect = this.expRef.getBoundingClientRect();
			startPoint = {
				x:expRect.left,
				y:expRect.top,
			};
		}

		addAnimationToStack (
			animationType,
			count,
			startPoint,
			TYPE_ANIMATION_CONTAINER,
			{
				gemsObject:this.gemsObject,
				expObject:this.expObject,
				animationStartCallback:() => this.onHeaderAnimationStart(animationType)
			},
			this.containerId
		);
	}

	onHeaderAnimationStart = (type) => {
		if(this.didUnmounted)return;
		if(type === TYPE_GEM) {
			this.setState({gemCount:0});
		} else if(type === TYPE_EXP) {
			this.setState({expCount:0});
		}
	};

	render() {
		return (
			<div className="reward-popup-component">

				<div className="left-container">
					<div className="title">
						{this.state.title}
					</div>
					<div className="image-container">
						{this.state.bigImgSrc ? <img src={this.state.bigImgSrc} alt="gameImage"/> : null}
					</div>
				</div>

				<div className="right-container">
					<div className="result-title">
						Congratulations
					</div>
					<div className="message-title">
						{this.state.messageTitle}
					</div>
					<div className="message-content">
						{this.state.messageContent}
					</div>
					<div className="result-content">
						{this.state.resultContent}
					</div>
					<div className="message-title">
						You just earned:
					</div>
					<div className="icon-container">
						{this.state.medalImgSrc ? <RewardItem imgSrc={this.state.medalImgSrc}  itemType={ITEM_TYPE_MEDAL}/> : null}
						{this.state.achievementImgSrc ? <RewardItem imgSrc={this.state.achievementImgSrc}  itemType={ITEM_TYPE_ACHIEVEMENTS}/> : null}
						{this.state.gemCount!==-1 ? <RewardItem
							gemCount={this.state.gemCount}
							iconRefFunction = {element => this.gemRef=element}
							itemType={ITEM_TYPE_GEM}
						/> : null}
						{this.state.expCount!==-1 ? <RewardItem
							expCount={this.state.expCount}
							iconRefFunction = {element => this.expRef=element}
							itemType={ITEM_TYPE_EXP}
						/> : null}
					</div>
					<div className="buttons-container">
						<Button
							className="primary"
							onClick={this.onContinueClicked}
							childId="RewardPopup-okButton"
							layer={navLayer}
						>
							Continue
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const achievementsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.ACHIEVEMENTS);
	const challengesGamesFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.CHALLENGE_GAMES);
	const challengesMedalsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.CHALLENGE_MEDALS);
	const brochureContentsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.BROCHURE_CONTENT_ASSETS);


	return {
		achievementsFolder,
		challengesGamesFolder,
		challengesMedalsFolder,
		brochureContentsFolder,
	};
};

export default connect(mapStateToProps)(RewardPopup);
