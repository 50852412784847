import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../common/button/common-button.component';
import inputManager, {PARENT_ENTRANCE} from '../../../assets/lib/inputmanager';
import './generic-container.component.less';

const navLayer=0;

export const MESSAGE_SIZE = {
	LARGE: 'large-text',
	MEDIUM: 'medium-text',
	SMALL: 'small-text'
}

export const BUTTONS_LABELS = {
	OK: 'OK',
	BACK: 'Back',
	CONFIRM: 'Confirm',
	CANCEL: 'Cancel',
}

export const externalFocusableComponents = {
    BUTTON_CONTAINER: 'buttons-container',
}

export class GenericContainer extends React.Component {

	componentDidMount() {
		const { focusElement, focusParentId, navLayerValue, customBackFunction } = this.props
		if(focusParentId) {
			focusElement && inputManager.setCurrentChildById(focusElement, focusParentId)
		} else {
			focusElement && inputManager.setCurrentChildById(focusElement)
		}
		customBackFunction && inputManager.setBackFunction(() => customBackFunction(), navLayerValue || navLayer);
	}

	componentWillUnmount() {
		const { navLayerValue } = this.props
		inputManager.setBackFunction(null, navLayerValue || navLayer);

		// delete buttons container info from the inputManager on unmount
		// it helps to avoid navigation issue because this is generic component and it can have different amount of buttons
		inputManager.deleteParent(externalFocusableComponents.BUTTON_CONTAINER);
	}

	onButtonContainerRef = (element) => {
		inputManager.onParentRef(element,".generic-container .buttonContainer", true, PARENT_ENTRANCE.ONLY_VERTICAL);
	};

	render() {
		const {
			message,
			title,
            children,
            messageSize = MESSAGE_SIZE.LARGE,
			buttons,
			showPopupBackground,
			extraStyles={},
			className='',
			buttonContainerClass=''
		} = this.props;

		return (
			<div className={`generic-container ${showPopupBackground ? 'popup-container': ''} ${className}`}  style={extraStyles}>
				{title && <div className="title-text">
					{title}
				</div>}
				{message && <div className={`message-text ${messageSize}`} >
					{message}
				</div>}
                {children}
				<div
					ref={this.onButtonContainerRef}
					className={`${externalFocusableComponents.BUTTON_CONTAINER} ${buttonContainerClass}`}
				>
                    {buttons && buttons.map(({key, className, handleClick, childId, navLayerValue, label, Component = Button, ...rest}, index) => {
                        return (
							<div key={key || index}>
								<Component
									parentId={externalFocusableComponents.BUTTON_CONTAINER}
									className={className || ""}
									onClick={(e) => {
										handleClick && handleClick(e) 
									}}
									childId={childId}
									layer={navLayerValue || navLayer}
									{...rest}
								>
									{label}
								</Component>
							</div>)
                    }) }
				</div>
			</div>
		);
	}
};

GenericContainer.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string,
        handleClick: PropTypes.func,
        childId: PropTypes.string,
        navLayerValue: PropTypes.number,
        label: PropTypes.string.isRequired
    })),
	extraStyles: PropTypes.shape({ // Shouldn't be used in 99% of cases
        padding: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
        margin: PropTypes.oneOf([PropTypes.string, PropTypes.number])
    }),
	className: PropTypes.string,
	messageSize: PropTypes.oneOf([MESSAGE_SIZE.LARGE, MESSAGE_SIZE.MEDIUM, MESSAGE_SIZE.SMALL]),
	message: PropTypes.string,
	focusElement: PropTypes.string,
	focusParentId: PropTypes.string,
	buttonContainerClass: PropTypes.string,
	isPermanent: PropTypes.bool,
	customBackFunction: PropTypes.func,
	showPopupBackground: PropTypes.bool,
};

export default GenericContainer;
