const MESSAGE_TYPES = {
	TOURNAMENT_ONE_DAY:"TOURNAMENT_ONE_DAY",
	TOURNAMENT_STARTED:"TOURNAMENT_STARTED",
	TOURNAMENT_ENDED:"TOURNAMENT_ENDED",
	GAME_STATE_SAVED:"game-state-saved",
	PAYMENT_SUCCESSFUL:"payment-successful",
	NEW_FOLLOWER:"NEW_FOLLOWER",
	GIANT_SLAYER_TAUNT:"GIANT_SLAYER_TAUNT",
	GIANT_SLAYER_RECRUIT:"GIANT_SLAYER_RECRUIT",
	GIANT_SLAYER_ENDED:"GIANT_SLAYER_ENDED",
	GIANT_SLAYER_ABANDONED:"GIANT_SLAYER_ABANDONED",
	PODIUM_UPDATE:"PODIUM_UPDATE",
	SOCIAL_UPDATE:"SOCIAL_UPDATE"
};

export {MESSAGE_TYPES};
