import { createSelector } from 'reselect';
import { get } from 'lodash';
import { getAllGamesById } from '../../entities/entities.selectors';
import { getCurrentGameDetailsId } from '../game-details/game-details.selector';

export const getCurrentGame = createSelector(
	getAllGamesById,
	getCurrentGameDetailsId,
	(gamesById, id) => {
		if (!gamesById || !id) return null;
		return gamesById[id];
	}
);

export const getContentByType = createSelector(
	getCurrentGame,
	(game) => {
		if (!game) return null;
		return get(game, 'pre_load', []).reduce((acc, obj) => {
			acc[obj.feature] = obj.content;
			return acc;
		}, {});
	}
);
