import React from 'react'
import GenericContainer, { externalFocusableComponents } from '../../common/generic-container/generic-container.component';
import { BUTTON_STYLES } from '../../common/button/common-button.component';
import { addPopup, closePopup } from '../popup.component';
import { startPaymentProcess } from '../../../app.actions';
import './store-popup.component.less';
import { STORE_TEXT_FREEMIUM_V2 as STORE_TEXT } from '../../../constants';
import shopIcon from '../../../assets/images/shop/shop-white-glow.png';
import shopPremiumOneYearWithDiamondIcon from '../../../assets/images/shop/shop-premium-one-year-with-diamond.svg';
import { analyticsEvent } from '../../../assets/lib/antstreamAnalytics';
import deviceInfo from '../../../assets/lib/deviceInfo';
import { isDeviceWithNativePayments } from '../../../app.helpers';
import GenericPopup from '../generic-popup/generic-popup.component';

const navLevel = 2;
const title = <>
    <span className="shop-header-text">Store</span>
    <img className="shop-header-icon" src={shopIcon} alt=""/>
</>;

const StorePopup = ({ price }) => {
    const buttons = [
        {
            key: 'play-for-free',
            label: "Play For Free",
            handleClick: () => {
                analyticsEvent({
                    category: "Freemium V2 Pop-Ups",
                    action: 'play_for_free_button_clicked',
                    label: `Store pop-up`
                });

                closePopup();
            },
            childId: 'play-for-free',
            className: `${BUTTON_STYLES.SECONDARY} full-height shop-exit-button`,
            navLayerValue: navLevel
        },
        {
            key: 'premium-play',
            label: price,
            handleClick: () => {
                analyticsEvent({
                    category: "Freemium V2 Pop-Ups",
                    action: 'premium_button_clicked',
                    label: `Store pop-up`
                });
                closePopup();
                setTimeout(() => {
                    if (!deviceInfo.hasNativeBrowserSupport() && !isDeviceWithNativePayments()) {
                        addPopup(<GenericPopup title="Sign up for Premium"
                            message="To sign up for a premium account, please go to https://www.antstream.com"/>);
                    } else {
                        startPaymentProcess(false);
                    }
                }, 1000); // wait until popup is closed
            },
            childId: 'premium-play',
            className: `${BUTTON_STYLES.SECONDARY} premium-button`,
            navLayerValue: navLevel
        }
    ];

    let storeText = [...STORE_TEXT];
    if (deviceInfo.isPlayStationPlatform()) {
        storeText = storeText.map(feature => {
            if (feature === 'Achievements') {
                return 'Trophies';
            }
            return feature;
        });
    }

    return (
        <GenericContainer
            title={title}
            className='store-popup-container'
            showPopupBackground={true}
            focusParentId={externalFocusableComponents.BUTTON_CONTAINER}
            focusElement='premium-play'
            navLayerValue={navLevel}
            buttons={buttons}
        >
            <div className="store-popup">
                <div className="store-popup-col left-col">
                    {storeText.map(feature =>
                        <p key={feature}>
                            {feature}
                        </p>)
                    }
                </div>
                <div className="store-popup-col right-col">
                    <h5>Premium Subscription</h5>
                    <img className="right-col__img" src={shopPremiumOneYearWithDiamondIcon} alt=""/>
                </div>
            </div>
        </GenericContainer>
    );
};

export default StorePopup;
