import React, { Component } from 'react';
import './selection-button.component.less';
import inputmanager from '../../../assets/lib/inputmanager';

class SelectionButtonOption extends Component {
	onCustomFocus = (focus) => {
		const {value,onFocus} = this.props;
		if(onFocus)onFocus(this.buttonRef, focus, value);
	};

	onButtonRef = (element) => {
		this.buttonRef = element;

		const {value,childId,parentId,layer} = this.props;
		inputmanager.onChildRef(element, childId+"-"+value, this.onCustomFocus, parentId, layer);
	};

	render() {
		const {className,value,onClick} = this.props;
		return (
			<span
				tabIndex={0}
				className={className}
				ref={this.onButtonRef}
				onClick={onClick}
			>
				{value}
			</span>
		);
	}
}

export { SelectionButtonOption };
