import React from 'react';
import { getCurrency } from '../payment-box/payment-box.component';

import { Button } from './common-button.component';
import './shop-button.component.less';

/***** TODO, we need a better way to do this *******/
const additionalInfos = {
    '766090fe8343432b8cc5c92d2c61605f': { // 60 Gems
        className: 'gems-illus-1',
    },
    '41c7198abaf84079950b561641a49c2e': { // 330 Gems
        className: 'gems-illus-2',
    },
    '5c319e7b8cbf4ddb89a57e2bb5e07df5': { // 1090 Gems
        className: 'gems-illus-3',
    },
    '33c5f31d8def4083a1e4a80dadf04740': { // 2360 Gems
        className: 'gems-illus-4',
        outlineText: 'Most popular'
    },
    '94355ce3f16b46878175978b12e24985': { // 4550 Gems
        className: 'gems-illus-5',
    },
    '43ee2d1420ce489c854f19f49323d47c': { //12100 Gems
        className: 'gems-illus-6',
        outlineText: 'Best value'
    },
};

const ShopButton = (props) => {
    const { basePrice, baseGems } = props;
    const { currencyString, sku, title, price } = props.catalogueItem;

    const className = additionalInfos[sku]?additionalInfos[sku].className:"";
    const outlineText = additionalInfos[sku]?additionalInfos[sku].outlineText:"";

    // extract gem count from sku string: "300 Gems" => 300
    const findgemsFromTitle = title.match(/\d+/);
    const gemCount = findgemsFromTitle?Number(findgemsFromTitle[0]):0;
    const extra = ((gemCount/price) - (baseGems/basePrice)) / (baseGems/basePrice);
    const extraPercentage = isNaN(extra)?0:Math.round(extra * 100);

    return (
        <Button
            {...props}
            className={`shop-button ${className} ${outlineText ? 'with-outline-text' : ''}`}
        >
            <div className="button-title">
                {title}
            </div>
            <div className="button-illus">
            </div>
            <div className="button-cost">
                <div className={`button-cost-inner`}>
                    {getCurrency(currencyString)}{price}
                </div>
            </div>
            {extraPercentage !== 0 && <div className="extra-banner">
                <div className="extra-value">
                    {extraPercentage}%
                </div>
                <div className="extra-label">
                    extra
                </div>
            </div>}
            {outlineText && <div className="outline-block">
                <div className="outline-text">
                    <div className="outline-text-inner">
                        {outlineText}
                    </div>
                </div>
            </div>}
        </Button>
    );
};

export default ShopButton;
