import React, { Component } from 'react';
import './settings-privacy-policy.component.less';
import {addPopup} from "../../popup/popup.component";
import QrCodePopup from "../../popup/qr-code-popup/qr-code-popup.component";
import requestErasureQrCodeImage from "../../../assets/images/qr-codes/antstream-zendesk-com-hc-en-gb-requests-new.png";
import requestErasureEmailQrCodeImage from "../../../assets/images/qr-codes/gdpr-antstream-com.png";
import contactUsQrCodeImage from "../../../assets/images/qr-codes/www-antstream-com-contact.png";
import icoOrgQrCodeImage from "../../../assets/images/qr-codes/www-ico-org-uk.png";
import {Button} from "../../common/button/common-button.component";
import inputManager from "../../../assets/lib/inputmanager";
import { getCurrentDeviceName } from '../../../assets/lib/utils';
import deviceInfo from '../../../assets/lib/deviceInfo';

class SettingsPrivacyPolicyComponent extends Component {
	componentDidMount() {
		inputManager.setCurrentChildById('tab-menu'+'-'+'Privacy Policy', 'tab-menu');
	}

	onRequestErasureClicked = () => {
		addPopup(<QrCodePopup
			title='Request Erasure - QR Code Link'
			image={requestErasureQrCodeImage}
		/>);
	};

	onRequestErasureEmailClicked = () => {
		addPopup(<QrCodePopup
			title='Request Erasure - QR Code Link'
			image={requestErasureEmailQrCodeImage}
		/>);
	};

	onWithdrawConsentEmailClicked = () => {
		addPopup(<QrCodePopup
			title='Withdraw Consent - QR Code Link'
			image={requestErasureEmailQrCodeImage}
		/>);
	};

	onContactUsClicked = () => {
		addPopup(<QrCodePopup
			title='Contact Us - QR Code Link'
			image={contactUsQrCodeImage}
		/>);
	};

	onIcoOrgClicked = () => {
		addPopup(<QrCodePopup
			title='ICO - QR Code Link'
			image={icoOrgQrCodeImage}
		/>);
	};

	render() {
		return (
			<div className="settings-privacy-policy">
				<Button className='fake-focusable' childId='1'></Button>
				<div>
					<div className="font-weight-bold mb-05">PRIVACY POLICY</div>
					<div className="font-weight-bold mb-05">Antstream Limited - Privacy Notice</div>
					<div className="mb-05">Last updated May 2022</div>
				</div>
				<Button className='fake-focusable' childId='1.2'></Button>
				<div className='w-100'>
					<p className="font-size-18"><span className="font-size-12">Last updated May 2022</span></p>
					<p className="font-size-18"><span className="font-weight-bold">Purpose</span></p>
					<p className="font-size-18">Antstream Limited known as “Antstream” respects your privacy and is committed to protecting your personal data. This notice applies when you visit or use our website, app and services and sets out the basis on which Antstream <Button className='fake-focusable' childId='1.2.1'></Button> processes any personal data we collect from you or which you provide to us in the course of using our services.</p>
					<p className="font-size-18"><span>​</span></p>
					<p className="font-size-18">This privacy notice also tells you about your privacy rights pursuant to the Data Protection Act 2018 and UK GDPR (“Data Protection Laws”).</p>
					<Button className='fake-focusable' childId='1.3'></Button>
					<p className="font-size-18"><span>​</span></p>
					<p className="font-size-18"><span className="font-weight-bold">Controller For Personal Data</span></p>
					<Button className='fake-focusable' childId='1.4'></Button>
					<p className="font-size-18">
						A “controller” is a person or organisation who alone or jointly determines the purposes for which and the way any personal data is or is likely to be processed. Unless we notify you otherwise Antstream is the controller of your personal data for the purpose of this website and services.
					</p>
					<p className="font-size-18"><span>​</span>
					</p>
					<Button className='fake-focusable' childId='2'></Button>
					<p className="font-size-18">
						This privacy notice applies to the processing of personal data by us in connection with any:
					</p>
					<ul className="color_15 bulleted-list">
						<li>
							<p className="font-size-18">Users: For the provision of services by us.</p>
						</li>
						<li>
							<Button className='fake-focusable' childId='2.1'></Button>
							<p className="font-size-18">Suppliers: For the provision of products and services to us by suppliers or service providers.</p>
						</li>
						<li>
							<p className="font-size-18">Visitors: For the provision of services by us as a visitor on our website.</p>
						</li>
					</ul>
					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='3'></Button>
					<p className="font-size-18"><span className="font-weight-bold">Types Of Personal Data</span></p>
					<p className="font-size-18">
						Personal data or personal information means any information about an individual from which that person can be identified. <Button className='fake-focusable' childId='3.2'></Button> It does not include data where the identity has been removed. This is known as anonymised data. Anonymised data falls outside the scope of Data Protection Laws.
					</p>
					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='4'></Button>
					<p className="font-size-18">
						Antstream may collect, use, store and transfer different kinds of personal data which we have grouped together:
					</p>
					<ul className="color_15 bulleted-list">
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Identity Data</span> includes first name, last name, username or similar identifier and title.</p>
							<Button className='fake-focusable' childId='4.1'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Contact Data</span> includes email address.</p>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Financial Data</span> includes bank account and payment card details.</p>
							<Button className='fake-focusable' childId='4.2'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Transaction Data</span> includes details about payments to and from you and other details for the provision of services you have purchased from us.</p>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Technical Data </span>{`includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the ${getCurrentDeviceName('devices')} you use to access our website.`}</p>
							<Button className='fake-focusable' childId='4.3'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Profile Data</span> includes your username and password, searches made by you, your ratings and comments, preferences, feedback and survey responses.&nbsp;</p>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Usage Data</span> includes information about how you use our website and services.</p>
							<Button className='fake-focusable' childId='4.4'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Marketing and Communications Data</span> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</p>
						</li>
					</ul>
					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='5'></Button>
					<p className="font-size-18"><span className="font-weight-bold">How We Collect Your Personal Data</span></p>
					<p className="font-size-18"><span className="font-weight-bold">Directly from you:</span> You give us your personal data in your direct interactions with us. Such personal data includes Identity Data, Contact Data, Profile Data, Usage Data, Technical Data, <Button className='fake-focusable' childId='5.2'></Button> Marketing and Communications Data (i) by filling in forms on our website (ii) by leaving ratings and comments (iii) by corresponding with us by email or post (iv) by calling us over the telephone.</p>
					<p className="font-size-18">
						This includes personal data you provide when you:
					</p>
					<Button className='fake-focusable' childId='6'></Button>
					<ul className="color_15 bulleted-list">
						<li>
							<p className="font-size-18">register/login to become a user of our service.</p>
						</li>
						<li>
							<p className="font-size-18">subscribe to our publications.</p>
						</li>
						<li>
							<p className="font-size-18">request marketing to be sent to you.</p>
							<Button className='fake-focusable' childId='6.1'></Button>
						</li>
						<li>
							<p className="font-size-18">give us feedback or contact us with any concerns.</p>
						</li>
						<li>
							<p className="font-size-18">participate in our marketing or other promotional events.</p>
						</li>
					</ul>
					<p className="font-size-18"><span>​</span></p>
					<p className="font-size-18">
						<span className="font-weight-bold">From use of our website and app collectively known as the “platform”</span>
					</p>
					<Button className='fake-focusable' childId='7'></Button>
					<p className="font-size-18">
						<span>Antstream gathers information and statistics collectively about visitors and users to our platform. Analysis of this information demonstrates the most frequently used sections of the website and assists us in continually improving the online service.</span>
						<Button className='fake-focusable' childId='7.1'></Button>
						<span>You give us your personal data, which includes Profile Data, Usage Data, Technical Data and/or Marketing and Communications Data when you use our website or which we have agreed with you to use or when you review any publications or marketing material, we send you. Please see our cookie policy for more information.</span>
					</p>
					<p className="font-size-18"><span>​</span>
					</p>
					<p className="font-size-18"><span className="font-weight-bold">Indirectly: Third-party sources:</span> We receive Identity Data, Contact Data, Financial Data, about you from third parties when:</p>
					<Button className='fake-focusable' childId='8'></Button>
					<ul className="color_15 bulleted-list">
						<li>
							<p className="font-size-18">we provide our services or other parties send us your personal data to enable the provision of those service.&nbsp;</p>
						</li>
						<li>
							<p className="font-size-18">you provide your personal data to a third party for the purpose of sharing it with us.</p>
						</li>
						<li>
							<p className="font-size-18">we need to interact with governmental or regulatory bodies or other authorities in relation to you or on your behalf.&nbsp;</p>
						</li>
					</ul>
					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='9'></Button>
					<p className="font-size-18"><span className="font-weight-bold">Providing Personal Data</span></p>
					<p className="font-size-18">Where we need to collect personal data by law or under the terms of a contract we have with you and you do not provide that information when requested we may not be able to perform the contract we have or are trying to enter into with you for example to provide you with our services. In this case we may have to cancel our service but we will notify you if this is the case at the time.</p>
					<p className="font-size-18">&nbsp;</p>
					<Button className='fake-focusable' childId='9.2'></Button>
					<p className="font-size-18"><span className="font-weight-bold">How We Use Your Personal Data</span></p>
					<p className="font-size-18">We will only use your personal data when the law allows us to. We will use your personal data in the following circumstances:
					</p>
					<Button className='fake-focusable' childId='10'></Button>
					<ul className="color_15 bulleted-list">
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Performance of a contract</span>: Where we need to perform the contract we are about to enter into or have entered into with you. For example when you sign up to our services.</p>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Legal obligation</span>: Where we need to comply with a legal obligation. For example for accounting or legal purposes.</p>
							<Button className='fake-focusable' childId='10.2'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Legitimate interests</span>: Where it is necessary for our legitimate interests or those of a third party and your interests and fundamental rights do not override those interests.</p>
						</li>
						<li>
							<p className="font-size-18">
								<span className="font-weight-bold">Consent</span>
								<span>: We do not generally rely on consent as a legal basis for processing your personal data. Where we do rely on consent you have the right to withdraw consent at any time. Please contact us at </span>
								<Button onClick={this.onWithdrawConsentEmailClicked} className='focusable-hyperlink' childId='52424635768648'>
									gdpr@anstream.com
								</Button>
								<span> to withdraw consent. Please also see </span>
								<span className="text-decoration-underline">
									Marketing Communications
								</span>
								.
							</p>
							<Button className='fake-focusable' childId='11'></Button>
						</li>
						<li>
							<p className="font-size-18">
								<span className="font-weight-bold">Downloading</span>
								{deviceInfo.isPlayStationPlatform()
									? ': When you choose a direct download option from the website or any other medium that we provide using your console/system, you consent to the download.'
									: ': When you choose a direct download option from the website for example on Windows 7+, Mac OSX, Linux (Ubuntu) or via Google Play (Android), Amazon App Stores (Fire Stick) or any other medium that we provide, you consent to the download.'
								}
							</p>
							<Button className='fake-focusable' childId='11.2'></Button>
						</li>
					</ul>
					<p className="font-size-18">&nbsp;</p>
					<p className="font-size-18"><span className="font-weight-bold">Purposes For Which We Will Use Your Personal Data</span>
					</p>
					<p className="font-size-18">We have set out below in a table format a description of all the ways we plan to use your personal data and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.
					</p>
					<Button className='fake-focusable' childId='12'></Button>
					<p className="font-size-18">&nbsp;</p>
					<p className="font-size-18">Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.
					</p>
				</div>

				<div id="tableWrapper" className="mb-1">
					<Button className='fake-focusable' childId='13'></Button>
					<table dir="auto" data-paging="false"
						   data-cascade="true" data-filter-min="3" data-paging-size="5" data-useparentwidth="true"
						   data-filter-container="#filterBox" data-filter-connectors="false"
						   data-use-parent-width="false" data-sorting="false" data-filtering="false"
						   data-filter-filters="[]" data-filter-placeholder="Search" data-filter-position="right"
						   data-show-header="true" ng-class="{'empty-table':isEmptyTable}"
						   className="theTable">
						<thead>
						<tr className="footable-header">
							<th className="wixColumns footable-first-visible table-cell">Purpose /
								Activity
							</th>
							<th className="wixColumns table-cell">Type of Data</th>
							<th className="wixColumns footable-last-visible table-cell">Lawful Basis
								for Processing Including Basis of Legitimate Interest
							</th>
						</tr>
						</thead>
						<tbody>
						<tr className="footableEven">
							<td className="wixColumns footable-first-visible table-cell">
								<Button className='fake-focusable' childId='14'></Button>
								<span>
									To register you as a user for the provision of our services &nbsp;
								</span>
							</td>
							<td className="wixColumns table-cell">(a) Identity (b) Contact&nbsp;</td>
							<td className="wixColumns footable-last-visible table-cell">Performance of
								a contract with you&nbsp;</td>
						</tr>
						<tr className="footableOdd">
							<td className="wixColumns footable-first-visible table-cell">
								<span>
									To respond to any enquires or concerns you may have&nbsp;
								</span>
								<Button className='fake-focusable' childId='15'></Button>
							</td>
							<td className="wixColumns table-cell">(a) Identity (b) Contact&nbsp;</td>
							<td className="wixColumns footable-last-visible table-cell">(a)
								Performance of a contract with you (b) Necessary for our legitimate interests&nbsp;</td>
						</tr>
						<tr className="footableEven">
							<td className="wixColumns footable-first-visible table-cell">
								<span>
									For premium users: To process and deliver our services to you including: (a) Manage payments, fees
									and charges (b) Collect and recover money owed to us&nbsp;
								</span>
								<Button className='fake-focusable' childId='16'></Button>
							</td>
							<td className="wixColumns table-cell">(a) Identity (b) Contact (c)
								Financial (d) Transaction (e) Marketing and Communications&nbsp;</td>
							<td className="wixColumns footable-last-visible table-cell">(a)
								Performance of a contract with you (b) Necessary for our legitimate interests i.e., to
								recover debts due to us&nbsp;</td>
						</tr>
						<tr className="footableOdd">
							<td className="wixColumns footable-first-visible table-cell">To manage our
								relationship with you which will include: (a) Notifying you about changes to our terms
								or privacy notice (b) Asking you to leave a review or take a survey&nbsp;</td>
							<td className="wixColumns table-cell">(a) Identity (b) Contact (c) Profile
								(d) Marketing and Communications&nbsp;</td>
							<td className="wixColumns footable-last-visible table-cell">(a)
								Performance of a contract with you (b) Necessary to comply with a legal obligation (c)
								Necessary for our legitimate interests i.e., to keep our records updated and to study
								how customers use our products/services&nbsp;</td>
						</tr>
						<tr className="footableEven">
							<td className="wixColumns footable-first-visible table-cell">
								<span>
									To administer and protect our business and this website including troubleshooting, data analysis,
									testing, system maintenance, support, reporting and hosting of data &nbsp;
								</span>
								<Button className='fake-focusable' childId='17'></Button>
							</td>
							<td className="wixColumns table-cell">(a) Identity (b) Contact (c)
								Technical&nbsp;</td>
							<td className="wixColumns footable-last-visible table-cell">(a) Necessary
								for our legitimate interests for running our business, provision of administration and
								IT services, network security, to prevent fraud and in the context of a business
								reorganisation or group restructuring exercise (b) Necessary to comply with a legal
								obligation&nbsp;</td>
						</tr>
						<tr className="footableOdd">
							<td className="wixColumns footable-first-visible table-cell">To deliver
								relevant website content and advertisements to you and measure or understand the
								effectiveness of the advertising we serve to you&nbsp;</td>
							<td className="wixColumns table-cell">(a) Identity (b) Contact (c) Profile
								(d) Usage (e) Marketing and Communications (f) Technical &nbsp;</td>
							<td className="wixColumns footable-last-visible table-cell">Necessary for
								our legitimate interests i.e., to study how customers use our products/services, to
								develop them, to grow our business and to inform our marketing strategy&nbsp;</td>
						</tr>
						<tr className="footableEven">
							<td className="wixColumns footable-first-visible table-cell">
								<span>
									To use data analytics to improve our website, products/services, marketing, customer relationships
									and experiences&nbsp;
								</span>
								<Button className='fake-focusable' childId='18'></Button>
							</td>
							<td className="wixColumns table-cell">(a) Technical (b) Usage &nbsp;</td>
							<td className="wixColumns footable-last-visible table-cell">Necessary for
								our legitimate interests to define types of customers for our products and services, to
								keep our website updated and relevant, to develop our business and to inform our
								marketing strategy Note: Where applicable consent will be used for data analytics
								obtained through cookies or similar technologies. See our cookie policy. &nbsp;</td>
						</tr>
						<tr className="footableOdd">
							<td className="wixColumns footable-first-visible table-cell">To make
								suggestions and recommendations to you about our services that may be of interest to
								you&nbsp;</td>
							<td className="wixColumns table-cell">(a) Identity (b) Contact (c)
								Technical (d) Usage (e) Profile (f) Marketing and Communications&nbsp;</td>
							<td className="wixColumns footable-last-visible table-cell">Necessary for
								our legitimate interests to develop our products/services and grow our
								business&nbsp;</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div className='w-100'>
					<p className="font-size-18">
						<span className="font-weight-bold">Marketing Communications</span>
						<Button className='fake-focusable' childId='19'></Button>
					</p>

					<p className="font-size-18">
						<span>
							Antstream may send you marketing communication. You have the right to object to the processing of your personal data for direct marketing purposes. You can unsubscribe from receiving marketing communications from us by using the unsubscribe methods contained in communications we send to you or by contacting us. See &nbsp;
						</span>
						<Button onClick={this.onContactUsClicked} className='focusable-hyperlink' childId='3557538746879'>
							Contact Us
						</Button>
						.
					</p>

					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='20'></Button>

					<p className="font-size-18">
						Where you opt-out of receiving marketing communications this will not apply to personal data provided to us as a result of registering for or using our service, your service experience, or other interactions with this website.
					</p>

					<p className="font-size-18"><span>​</span></p>

					<p className="font-size-18"><span
						className="font-weight-bold">How We Share Your Personal Data</span></p>
					<Button className='fake-focusable' childId='21'></Button>

					<p className="font-size-18">We may have to share your personal data with the parties set out below:</p>

					<ul className="color_15">
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Internally</span>: Your personal data will be used by our employees and contractors who are working on providing your services to you on a need-to-know basis.</p>
							<Button className='fake-focusable' childId='21.1'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Suppliers</span>: This would include service providers who support our business including IT and communication suppliers and outsourced business support to ensure our service runs smoothly.</p>
							<Button className='fake-focusable' childId='22'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Professional advisers</span>: This would include lawyers, bankers, auditors, and insurers who provide consultancy, banking, legal, insurance, and accounting services.</p>
							<Button className='fake-focusable' childId='22.1'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Law enforcement bodies, regulators, and other authorities</span>: This is to comply with our legal requirements or adhere to good practices.</p>
							<Button className='fake-focusable' childId='23'></Button>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Advertising networks and analytics service providers</span>: This is to support and display ads on our website, apps, and other social media tools.</p>
						</li>
						<li>
							<p className="font-size-18"><span className="font-weight-bold">Third parties</span>: This is in the context of the acquisition or transfer of any part of our business or in connection with the business reorganisation. If a change happens to our business then the new owners may use your personal data in the same way as set out in this privacy notice.</p>
							<Button className='fake-focusable' childId='24'></Button>
						</li>
					</ul>

					<p className="font-size-18">&nbsp;</p>

					<p className="font-size-18"><span
						className="font-weight-bold">International Transfers</span></p>
					<Button className='fake-focusable' childId='25'></Button>

					<p className="font-size-16"><span className="font-weight-bold">How we safeguard personal data when we transfer it internationally</span></p>
					<Button className='fake-focusable' childId='25.2'></Button>

					<p className="font-size-18">We ensure a similar degree of protection is afforded whenever we transfer personal data out of the UK by ensuring at least one of the following safeguards is implemented:</p>
					<Button className='fake-focusable' childId='25.3'></Button>

					<ol className="color_15">
						<li>
							<p className="font-size-18">We will only transfer your personal information to countries that have been deemed to provide an adequate level of protection for personal information; or</p>
							<Button className='fake-focusable' childId='26'></Button>
						</li>
						<li>
							<p className="font-size-18">We will use specific contracts approved for use in the UK which give personal information the same protection it has in the UK. For example, the use of Article 46 UK/EU GDPR safeguard mechanisms to transfer personal data.</p>
							<Button className='fake-focusable' childId='26.2'></Button>
						</li>
					</ol>

					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='27'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Data Security</span></p>

					<p className="font-size-18">We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
					<Button className='fake-focusable' childId='27.2'></Button>

					<p className="font-size-18">The transmission of information via the internet is not completely secure. Although we will take reasonable measures to protect your personal data, Antstream cannot guarantee the security of your information transmitted and any transmission is at your own risk.</p>
					<Button className='fake-focusable' childId='27.3'></Button>

					<p className="font-size-18">&nbsp;</p>
					<Button className='fake-focusable' childId='28'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Data Retention</span></p>
					<Button className='fake-focusable' childId='28.2'></Button>

					<p className="font-size-18">Antstream will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. Antstream may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
					<Button className='fake-focusable' childId='28.3'></Button>

					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='29'></Button>

					<p className="font-size-18">To determine the appropriate retention period for personal information we consider the amount, nature and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, <Button className='fake-focusable' childId='29.1'></Button> the purposes for which we process your personal information and whether we can achieve those purposes through other means and the applicable legal, regulatory, tax, accounting or other requirements.</p>
					<Button className='fake-focusable' childId='29.2'></Button>

					<p className="font-size-18"><span>​</span></p>

					<p className="font-size-18">Antstream may anonymise your personal data so that it can no longer be associated with you for research or statistical <Button className='fake-focusable' childId='29.2.1'></Button> purposes in which case we may use this information indefinitely without further notice to you.&nbsp;</p>
					<Button className='fake-focusable' childId='29.3'></Button>

					<p className="font-size-18">&nbsp;</p>
					<Button className='fake-focusable' childId='30'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Data Subject Rights</span></p>

					<p className="font-size-18">Under certain circumstances, you have rights under Data Protection Laws. You can:</p>
					<Button className='fake-focusable' childId='30.2'></Button>

					<p className="font-size-18"><span>​</span></p>

					<p className="font-size-18"><span className="font-weight-bold">Request access to your personal data</span>: This is known as a "data subject access request" and enables you to receive a copy of the personal data Antstream holds about you.</p>
					<Button className='fake-focusable' childId='31'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Request correction of your personal data</span>: This enables you to have any incomplete or inaccurate information Antstream holds about you corrected.</p>
					<Button className='fake-focusable' childId='31.2'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Request erasure of your personal data</span>: This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. Note: Antstream may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you at the time of your request.</p>
					<Button className='fake-focusable' childId='31.3'></Button>

					<p className="font-size-18">
						<Button className='fake-focusable' childId='32'></Button>
						<span>To request this either log a ticket using this link&nbsp;</span>
						<Button onClick={this.onRequestErasureClicked} className='focusable-hyperlink' childId='3567576487759'>
							https://antstream.zendesk.com/hc/en-gb/requests/new
						</Button>
						<span> or email us at </span>
						<Button onClick={this.onRequestErasureEmailClicked} className='focusable-hyperlink' childId='4526536847975'>
							gdpr@antstream.com
						</Button>
					</p>

					<p className="font-size-18"><span className="font-weight-bold">Object to processing of your personal data</span>: This is where we are processing your personal data based on a legitimate interest or those of a third party and you may challenge this.&nbsp; However, we may be entitled to continue processing your information based on our legitimate interests or where this is relevant to any legal claims.&nbsp; See also Marketing Communications.</p>
					<Button className='fake-focusable' childId='33'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Request restriction of processing your personal information</span>. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the information's accuracy (b) where our use of the information is unlawful but you do not want us to erase it (c) where you need us to hold the information even if we no longer require it as you need it to establish, exercise or defend legal claims or (d) you have objected to our use of your information but we need to verify whether we have overriding legitimate grounds to use it.</p>
					<Button className='fake-focusable' childId='33.2'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Request transfer of your personal information (“data portability”)</span>:&nbsp; This is where in some circumstances Antstream will provide to you or a third party you have chosen your personal data in a structured, commonly used, machine-readable format.</p>
					<Button className='fake-focusable' childId='34'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Right to withdraw consent</span>: This is where we are relying on consent to process your personal data. This will not affect the lawfulness of any processing carried out before you withdraw your consent. Depending on the processing activity, if you withdraw your consent we may not be able to provide certain services to you. Antstream will advise you if this is the case at the time you withdraw your consent.</p>
					<Button className='fake-focusable' childId='34.2'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Automated decision making</span>: &nbsp;This is where decisions are made about you by automated means. We do not carry out automated decision making.</p>
					<Button className='fake-focusable' childId='34.3'></Button>

					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='35'></Button>

					<p className="font-size-18"><span
						className="font-weight-bold">Keeping Personal Information Accurate &amp; Current</span>
					</p>

					<p className="font-size-18">
						<span className="color_15">
							It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us. If you wish to update your personal data please contact us. See &nbsp;
						</span>
						<Button onClick={this.onContactUsClicked} className='focusable-hyperlink' childId='245356734674686'>
							Contact Us
						</Button>
					</p>

					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='36'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Carrying Out Your Data Subject Rights</span></p>

					<Button className='fake-focusable' childId='36.1'></Button>
					<p className="font-size-18">You will not have to pay a fee to access your personal data or to exercise any of the other rights. However, Antstream may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal information or to exercise any of your other rights. This is a security measure to ensure that personal information is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='37'></Button>

					<p className="font-size-18">We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

					<p className="font-size-18"><span>​</span></p>

					<p className="font-size-18">
						<span>
							If you wish to exercise any of the rights set out above, please contact us at&nbsp; 
						</span>
						<Button onClick={this.onRequestErasureEmailClicked} className='focusable-hyperlink' childId='254653746848579'>
							gdpr@antstream.com
						</Button>.
					</p>

					<p className="font-size-18">&nbsp;</p>
					<Button className='fake-focusable' childId='38'></Button>

					<p className="font-size-18"><span className="font-weight-bold">Concerns &amp; Complaints</span></p>

					<p className="font-size-18">
						We would appreciate the chance to deal with your concerns in the first instance. Please see the &nbsp;
						<span className="text-decoration-underline">
							<Button onClick={this.onContactUsClicked} className='focusable-hyperlink' childId='2453457574687465'>
								Contact Us
							</Button>
						</span> section. If you have unresolved concerns and you live or work in the UK or believe that a personal data breach happened in the UK you have the right to complain at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (<Button onClick={this.onIcoOrgClicked} className='focusable-hyperlink' childId='2465737467'>
							www.ico.org.uk
						</Button>).
					</p>

					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='39'></Button>

					<p className="font-size-18">If you live or work outside the UK or you have a complaint concerning our personal data processing activities you may lodge a complaint with another supervisory authority. &nbsp;
					</p>

					<p className="font-size-18"><span>​</span></p>

					<p className="font-size-18"><span className="font-weight-bold">Changes To Our Privacy Notice</span></p>
					<Button className='fake-focusable' childId='40'></Button>

					<p className="font-size-18">This privacy notice may be changed from time to time in response to legal, technical or business developments. We will take appropriate measures to inform you when we update our privacy notice. We will obtain your consent to any material privacy notice changes if and where this is required by applicable Data Protection Laws.</p>

					<p className="font-size-18">&nbsp;</p>

					<p className="font-size-18"><span className="font-weight-bold">Contact Us&nbsp;</span></p>

					<p className="font-size-18">If you would like more information about how we manage personal information please contact us at:</p>

					<p className="font-size-18"><span>​</span></p>
					<Button className='fake-focusable' childId='41'></Button>

					<p className="font-size-18">
						<span>Email address: </span>
						<Button onClick={this.onRequestErasureEmailClicked} className='focusable-hyperlink' childId='2454573578468468'>
							gdpr@antstream.com
						</Button>
					</p>

					<p className="font-size-18">
						<span>Support ticket: Open a ticket by clicking </span>
						<Button onClick={this.onRequestErasureClicked} className='focusable-hyperlink' childId='253575746864'>
							here
						</Button>
					</p>
					<Button className='fake-focusable' childId='42'></Button>
				</div>
			</div>
		);
	}
}

export default SettingsPrivacyPolicyComponent;
