import React, { Component } from 'react';
import { connect } from 'react-redux';
import {get} from 'lodash';
import {getSelectedGameInfo} from '../../game-details/game-details.selector';
import SlideOutInAnimation from '../../animations/slide-out-in-animation.compontent';
import './game-info-panel.component.less';
import multiplayerIcon from '../../../assets/images/global/multiplayer.png';
import challengesIcon from '../../../assets/images/generic-icons/challenges-icon.png';
import highScoreIcon from '../../../assets/images/global/leaderboard_global.png';
import {getIsUserInGermany} from '../../../app.selectors';
import {getGermanyAgeIcon} from '../../../app.actions';
import { isLowSpecDevice }  from '../../../app.helpers'
import { Icon } from '../../common/icon/icon.component';
import { InView } from "react-intersection-observer";

class GameInfoPanel extends Component {
	constructor(props) {
		super(props);
		this.onSlideOut = this.onSlideOut.bind(this);
		this.onSlideIn = this.onSlideIn.bind(this);
		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.getContentDiv = this.getContentDiv.bind(this);
		this.startAnimation = this.startAnimation.bind(this);

		this.items = [];

		this.state = {
			animate:false,
			imageClass:'',
			currentGame:null,
		};
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (newProps.selectedGame._id) {
			this.addItemToQueue(newProps.selectedGame);
			setTimeout(() => this.resizeTitleFont(''), 250);
		}
	}

	componentDidUpdate() {
		if(!this.state.animate && this.items.length>0) {
			this.startAnimation();
		}

		setTimeout(() => this.resizeTitleFont(), 250)
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!this.state.currentGame) {
			return true;
		}
		if (this.items?.length !== nextProps.items?.length) {
			return true;
		}
		if (this.state.animate && this.items.length > 0) {
			return true;
		}
		if (this.state.imageClass !== nextState.imageClass) {
			return true;
		}
		if (this.state.animate !== nextState.animate) {
			return true;
		}

		const gameItem = this.items[0];
		if (gameItem) {
			if (this.state.currentGame._id !== gameItem._id) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Reduces the font size by 1 px recursively
	 * until it is smaller than 50% of the parent component.
	 * 
	 * @param {string} value - font size value
	 */
	resizeTitleFont(value) {
		if (typeof value !== 'undefined' && this.titleRef) {
			this.titleRef.style.fontSize = value;
			return;
		}

		if (this.titleRef && this.mainRef && this.titleRef.clientHeight / this.mainRef.clientHeight > 0.5) {
			let fontSize = window.getComputedStyle(this.titleRef, null).getPropertyValue("font-size").replace("px", ""); 
			this.titleRef.style.fontSize = fontSize - 1 + 'px';

			if (this.titleRef.clientHeight / this.mainRef.clientHeight > 0.5) {
				this.resizeTitleFont();
			}
		}
	}

	addItemToQueue = (game) => this.items.push(game);

	startAnimation() {
		this.setState({ animate: true, imageClass: 'hidden' });
	}

	onSlideOut() {
		const gameItem = this.items.shift();

		if(gameItem) {
			if(!this.state.currentGame || this.state.currentGame._id !== gameItem._id) this.setState({currentGame:gameItem});
			else this.setState({imageClass:''});
		}
	}

	onSlideIn() {
		this.setState({ animate: false });
	}

	onImageLoaded() {
		this.setState({ imageClass:'' });
	}

	getContentDiv = () => {
		const { currentGame } = this.state;
		if (!currentGame) return null;
		const { title, elevator_pitch, publisherLogo, studio, homepage_screenshot, _id } = currentGame;
		const { year_image_uri, genre_image_uri, trials_image_uri } = currentGame;
		const tags = get(currentGame, 'tags');
		const hasMultiplayer = tags ? tags.includes('multiplayer2player2pads'): false;
		const hasHighScore = tags ? tags.includes('leaderboard'): false;
		const germanyAgeIcon = this.props.isUserInGermany ? getGermanyAgeIcon(currentGame) : null;
		const publisherLogoUrl = publisherLogo && publisherLogo.url

		const year2Digits = year_image_uri?.slice(-6, -4);
		const genreIconUri = genre_image_uri?.replace('.svg', '-notext-01.png');

		return (
			<InView triggerOnce>
			{({ ref, inView }) => (
			<div ref={el => { this.mainRef = el }} className="content-main">
	
				<div  ref={ref} className="content-left">
				{inView == true &&
					<>
					<p ref={el => { this.titleRef = el }} className="title">{title}</p>
					<div className="game-info">
						<p className="description">{elevator_pitch}</p>
						<div className="logo-year-genre">
							{publisherLogoUrl && <img className="publisher" src={publisherLogoUrl} alt={studio} />}
							{!publisherLogoUrl && <p className="publisher">{studio}</p>}
							<Icon text="year" largeText={year2Digits} />
							<Icon text="genre" iconUrl={genreIconUri} iconAltText="genre" />
							{trials_image_uri &&
							<Icon
								text="challenge"
								iconUrl={challengesIcon}
								iconAltText="challenge"
							/>}
							{hasMultiplayer &&
							<Icon
								text="multiplayer"
								iconUrl={multiplayerIcon}
								iconAltText="multiplayer"
							/>
							}
							{hasHighScore && <img className="highScoreIcon" src={highScoreIcon} alt="highScoreIcon" />}
							{germanyAgeIcon && <img className="germanyAgeIcon" src={germanyAgeIcon} alt="germanyAgeIcon" />}
						</div>
					</div>
					</>
	}
				</div>
				<div  ref={ref} className={`image-container ${this.state.imageClass}`}>
				{inView == true &&
					<>
					<img onLoad={this.onImageLoaded} src={homepage_screenshot} alt=""/>
					</>
	}
				</div>
			</div>
			 )}
			 </InView>
		);
	} ;

	render() {
		return (
			<SlideOutInAnimation
				className="game-info-panel-component"
				onSlideOut={this.onSlideOut}
				onSlideIn={this.onSlideIn}
				animate = {this.state.animate}
				isSkipAnimate={isLowSpecDevice()}
			>
				{this.getContentDiv()}
			</SlideOutInAnimation>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		selectedGame: getSelectedGameInfo(state),
		isUserInGermany: getIsUserInGermany(state),
	};
};

export default connect(mapStateToProps)(GameInfoPanel);
