import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { SaveRoute } from '../../../app.actions';
import { getLoading } from '../login.selectors';
import { Button } from '../../common/button/common-button.component';
import { Input } from '../../common/input/input.component';
import { maxLengthValidator, minLengthValidator, requiredValidator } from '../../common/input/input.validator';
import { ResetPasswordAction, SetSignupFieldAction } from '../login.actions';
import { ROUTES, navigateBack } from '../../../app.router';
import inputManager from '../../../assets/lib/inputmanager';

import '../login-global.less';
import { getRoutingSearch, getWebViewDimensions } from '../../../app.selectors';
import { checkWebViewDimensions } from '../../../assets/lib/utils';

const minValue6 = minLengthValidator(6);
const maxValue20 = maxLengthValidator(20);
const validateForm = (fields) => {
	if (fields.password !== fields.confirmpassword) {
		return {confirmpassword: 'Passwords must match'};
	}
	return null;
};

const formId = "resetPasswordForm";

class ResetPasswordComponent extends Component {
	constructor() {
		super();
		this.state = {};
		this.saveForm = this.saveForm.bind(this);
	}

	componentDidMount() {
		inputManager.setCurrentChildById("tokenInput");
		this.populateFormFieldsFromQueryParams();
	}

	populateFormFieldsFromQueryParams = () => {
		const { dispatch, change, queryParams } = this.props;
		if (queryParams?.token) {
			change('token', queryParams.token);
		}
		if (queryParams?.email) {
			dispatch(SetSignupFieldAction({email: decodeURIComponent(queryParams.email)}));
		}
	}

	saveForm = (values) => {
		const payload = {
			email: this.props.login.userFields.email.toLowerCase(),
			password: values.password,
			token: values.token,
		};
		this.props.dispatch(SaveRoute(ROUTES.LOGIN));
		this.props.dispatch(ResetPasswordAction(payload));
	};

	back = () => {
		navigateBack();
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13) {
			this.props.handleSubmit(this.saveForm)();
		}
	};

	render () {
		return(
			<div className={`login-wrapper reset-password-component ${checkWebViewDimensions(this.props.viewDimensions)}`} onKeyDown={this.onKeyDown}>
				<section className="login-section login-section--left">
					<h1>Reset Password</h1>
					<p>Choose your new password</p>
					<form>
						<Field
							name="token"
							type="token"
							component={Input}
							formId={formId}
							label="Token"
							validate={[ requiredValidator ]}
							childId="tokenInput"
						/>
						<Field
							name="password"
							type="password"
							component={Input}
							formId={formId}
							label="Password"
							validate={[ maxValue20, minValue6, requiredValidator ]}
							childId="passwordInput"
						/>
						<Field
							name="confirmpassword"
							type="password"
							component={Input}
							formId={formId}
							label="Confirm Password"
							validate={[ ]}
							childId="confirmPasswordInput"
						/>
					</form>
					<div className="buttons-container">
						<Button
							className="secondary small-button"
							onClick={this.back}
							childId="backButton"
						>
							Back
						</Button>
						<Button
							className="primary small-button"
							disabled={this.props.loading}
							noSelectable={this.props.loading}
							childId="setNewPasswordButton"
							onClick={this.props.handleSubmit(this.saveForm)}
						>
							Set New Password
						</Button>
					</div>
					<p>Passwords must be at least six characters.</p>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const viewDimensions = getWebViewDimensions(state);

	return {
		queryParams: getRoutingSearch(state),
		login: state.login,
		viewDimensions,
		error: state.login.error,
		loading: getLoading(state)
	};
};

ResetPasswordComponent = connect(mapStateToProps)(ResetPasswordComponent);

export const ResetPassword = reduxForm({
	form: formId,
	validate: validateForm
})(ResetPasswordComponent);
