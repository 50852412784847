
import React, { Component } from 'react';
import './slide-out-in-animation.compontent.less';

class SlideOutInAnimation extends Component {
	constructor(props) {
		super(props);
		this.onAnimationEnd = this.onAnimationEnd.bind(this);
		this.state = {
			animationClass:'',
		};
	}

	componentDidUpdate() {
		if(this.props.animate && this.state.animationClass === '') {
			this.setState({
				animationClass:'slide-out',
			});
		}
	}

	onAnimationEnd() {
		if(this.state.animationClass === 'slide-out') {
			this.setState({
				animationClass:'slide-in',
			});
			if(this.props.onSlideOut) this.props.onSlideOut();
		} else {
			this.setState({
				animationClass:'',
			});
			if(this.props.onSlideIn) this.props.onSlideIn();
		}
	}

	render() {
		const { className, isSkipAnimate} = this.props
		const { animationClass } = this.state
		if(isSkipAnimate) { 
			if(this.props.onSlideOut) this.props.onSlideOut();
			return <div className={className}> {this.props.children} </div>
		}
		return (
			<div onAnimationEnd={this.onAnimationEnd} className={`${className} slide-out-in-animation-component slide-out-in ${animationClass}`}>
				{this.props.children}
			</div>
		);
	}
};

export default SlideOutInAnimation;
