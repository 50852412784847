import React, { useEffect } from 'react';
import './tile-giant-slayer.component.less';
import { PulsarComponent } from '../pulsar/pulsar.component';
import CountdownShortTime from '../countdown-short-time/countdown-short-time.component';
import { getChallengeById } from '../../../entities/entities.selectors';
import { useSelector } from 'react-redux';
import { getGemPlayGiantSlayerRandomCost, getLoggedUser } from '../../../app.selectors';
import gemImage from '../../../assets/images/global/gems.png';
import InputManager from '../../../assets/lib/inputmanager';

const TileGiantSlayer = ({ item, handleImageLoaded, selected, handleClick }) => {
	const challengeObject = useSelector((state) => getChallengeById(state, item.challengeId));
	const giantSlayerRandomCost = useSelector((state) => getGemPlayGiantSlayerRandomCost(state));
	const loggedUser = useSelector((state) => getLoggedUser(state));
	const userGems = loggedUser.currencies.GEMS;
	var canCleanUp=true;

	useEffect(() => {
		if (selected) {
			InputManager.emitEvent = true;
			window.addEventListener('input_manager_click', handleClick)
			canCleanUp = true;
		} else {
			if (canCleanUp) {
				canCleanUp = false;
			}
		}

		return () => {
			if (canCleanUp) {
				InputManager.emitEvent = false;
				window.removeEventListener('input_manager_click', handleClick);
				canCleanUp = false;
			}
		};
	});

	if (item._id === 'randomGiantSlayer') {
		const randomTileDisabled = giantSlayerRandomCost > userGems;
		return (
			<div className="tile-giant-slayer-skew" onClick={handleClick}>
				<div className={`tile-giant-slayer random-giant-slayer ${selected ? 'selected' : ''} ${randomTileDisabled ? 'disabled' : ''}`}>
					<div className="random-illus"></div>
					<div className="prize"></div>
					<div className="multiplier"></div>
					<div className="giant-slayer-icon"></div>
					<div className="slayer-illus"></div>

					<div className="disabled-overlay"></div>

					<div className="attempt-cost">
						{giantSlayerRandomCost}
						<img className="gem-image" src={gemImage} alt="gem"/>
					</div>

					<PulsarComponent borderWithRadius />
				</div>
			</div>
		)
	}
	if (!item || !challengeObject) {
		return null;
	}

	let endTimeSec = 0;
	if (item.endTime) {
		endTimeSec = Math.round(item.endTime / 1000);
	}

	if (item.role === 'giant') {
		return (
			<div className="tile-giant-slayer-skew" onClick={handleClick}>
				<div className={`tile-giant-slayer giant-tile ${selected ? 'selected' : ''}`}>
					<div className="giant-illus"></div>
					<div className="giant-slayer-icon"></div>

					{item.giantCount && <div className="giant-count-block">
						<div className="giant-count">{item.giantCount}</div>
						of
						<div className="giant-limit">{item.giantLimit}</div>
					</div>}

					{(endTimeSec !== 0) && <CountdownShortTime endTimeStamp={endTimeSec}/>}

					<PulsarComponent borderWithRadius />
				</div>
			</div>
		)
	}

	const slayerTileDisabled = item.attemptCost && (item.attemptCost > userGems);

	return (
		<div className="tile-giant-slayer-skew" onClick={handleClick}>
			<div className={`tile-giant-slayer ${selected ? 'selected' : ''} ${slayerTileDisabled ? 'disabled' : ''}`}>
	
				<div className="img-bg">
					<img
						className="game-image"
						src={challengeObject.img_src}
						alt={item.title}
						onLoad={handleImageLoaded}
					/>
				</div>

				<div className={`colored-header t-${challengeObject.tier}`}>
					<div className="giant-slayer-icon"></div>
				</div>

				<div className={`difficulty t-${challengeObject.tier}`}>
					<span className="one"></span>
					<span className="two"></span>
					<span className="three"></span>
					<span className="four"></span>
					<span className="five"></span>
				</div>

				<div className="slayer-illus"></div>

				<div className="play-icon">
				</div>

				{(endTimeSec !== 0) && <CountdownShortTime endTimeStamp={endTimeSec}/>}

				<div className="disabled-overlay"></div>

				<div className="attempt-cost">
					{item.attemptCost}
					<img className="gem-image" src={gemImage} alt="gem"/>
				</div>

				<PulsarComponent borderWithRadius />

			</div>
		</div>
	);
};

export default TileGiantSlayer;
