import React from 'react';

export default function FontSizeByLength(props) {
    if (typeof props.children !== 'string') {
        console.warn('The children of FontSizeByLength must be string.');
        return <span>{props.children}</span>;
    }

    const fz = getNewFontSizeInPercent(
        props.maxPercent,
        props.minPercent,
        props.changePerChar,
        props.startAtChar,
        props.stopAtChar,
        props.children.length
    );
    const style = { fontSize: `${fz}%` };

    return <span className={props.className} style={style}>{props.children}</span>;
}

function getNewFontSizeInPercent(maxPercent = 100, minPercent = 0, changePerChar, startAtChar = 0, stopAtChar = Infinity, numberOfChars) {
    const numberOfCharsAdjusted = Math.min(numberOfChars, stopAtChar);
    const numberOfCharToConsiderForCalculation = Math.max(0, numberOfCharsAdjusted - (startAtChar - 1));

    return Math.max(minPercent, maxPercent - changePerChar * numberOfCharToConsiderForCalculation);
}
