import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { Button } from '../../common/button/common-button.component';
import inputManager from '../../../assets/lib/inputmanager';
import '../login-global.less';

class SignupAuthenticationComponent extends Component {
	constructor() {
		super();
		this.onNextClick = this.onNextClick.bind(this);
	}

	componentDidMount() {
		inputManager.setCurrentChildById("continueButton");
	}

	onNextClick = () => {
		navigateToLocation(ROUTES.SIGNUP_AVATAR);
	};

	render () {
		return(
			<div className="login-wrapper">
				<section className="login-section login-section--left">
						<h1>Check your email</h1>
						<p>
							Your account has been successfully created! We've sent you an email.
						</p>
						<p>
							Please click on the magic link in the email to verify your email address.
						</p>
					<Button 
						className="primary right-button"
						onClick={this.onNextClick}
						childId="continueButton"
					>
						Continue
					</Button>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { ...state.login };
};

export const SignupAuthentication = connect(mapStateToProps)(SignupAuthenticationComponent);
