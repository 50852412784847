import * as actions from './mapping.actions';

const initialState = {
	patchMappingPending: false,
	getMappingPending: false,
	byId: {},
};

export const mappingReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.PATCH_CONTROLLER_MAPPINGS_ACTION: {
			return {
				...state,
				patchMappingPending: true
			};
		}
		case actions.PATCH_CONTROLLER_MAPPINGS_ACTION_SUCCESS: {
			return {
				...state,
				patchMappingPending: false
			};
		}
		case actions.PATCH_CONTROLLER_MAPPINGS_ACTION_ERROR: {
			return {
				...state,
				patchMappingPending: false
			};
		}
		case actions.GET_CONTROLLER_MAPPINGS_ACTION: {
			return {
				...state,
				getMappingPending: true
			};
		}
		case actions.GET_CONTROLLER_MAPPINGS_ACTION_ERROR: {
			return {
				...state,
				getMappingPending: false
			};
		}
		case actions.GET_CONTROLLER_MAPPINGS_ACTION_SUCCESS: {
			if (!action.payload) {
				return {
					...state,
					getMappingPending: false
				};
			}

			return {
				...state,
				getMappingPending: false,
				byId: {
					...state.byId,
					[action.payload.gameId]: action.payload.mapping
				}
			};
		}
		case actions.CONTROLLER_MAPPINGS_SWAP_BUTTONS_ACTION: {
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.gameId]: action.payload.mapping
				}
			};
		}
		default: {
			return state;
		}
	}
};
