import {ADD_DELAYED_POPUP_ACTION, CLEAR_DELAYED_POPUPS_ACTION, POPUP_VISIBILITY_ACTION} from './popup.actions';

const initialState = {
	delayedPopups: [],
	isHidden: true
};

export const popupReducer = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_DELAYED_POPUPS_ACTION: {
			return {
				...state,
				delayedPopups: [],
			};
		}
		case ADD_DELAYED_POPUP_ACTION: {
			return {
				...state,
				delayedPopups: [...state.delayedPopups, action.payload],
			};
		}
		case POPUP_VISIBILITY_ACTION:
			return {
				...state,
				isHidden: action.isHidden,
			};
		default:
			return state;
	}
};
