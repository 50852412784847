import React, {useEffect} from 'react';
import QRCode from 'qrcode.react';
import {navigateToLocation, ROUTES} from "../../../app.router";

import logo from '../../../assets/images/splash/Antstream_Logo_White_Long.svg';
import {Button} from "../../common/button/common-button.component";
import inputmanager from "../../../assets/lib/inputmanager";

import '../signup-link/signup-link.component.less';


export const SignupLink = () => {
	const onLoginButton = () => {
		navigateToLocation(ROUTES.LOGIN_CREDENTIALS);
	}

	useEffect(() =>{
		inputmanager.setCurrentChildById("loginButton");
	}, [])

	const link = "https://accounts.antstream.com/homepage";
	return(
		<div className="signup-link-component">
			<section>
				<div className="logo">
					<img src={logo} alt="logo" />
				</div>
				<div className="container">
					<div className="left">
						<h1>CREATING YOUR ACCOUNT</h1>
						<p>It’s quick and easy to create your own Antstream account, just go to the website</p>
						<p className="link">{link}</p>
						<p>Or, scan the QR code on this page.</p>
						<Button
							childId="loginButton"
							className="secondary"
							onClick={onLoginButton}
						>
							Login
						</Button>
					</div>
					<div className="right">
						<div className="qrcode">
							<QRCode value={link} />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}