import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGetSimilarGames, getCurrentGameDetailsId } from '../game-details.selector';
import ListContainer, {LIST_TYPES} from '../../common/list/list-container.component';
import { PARENT_ENTRANCE} from '../../../assets/lib/inputmanager';
import { FetchSimilarGamesAction } from '../game-details.actions';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { UpdateMyMenuSelection } from '../game-details.actions';
import './game-details-similar.component.less';
import {SetSelectedGameInfoAction} from '../game-details.actions';
import GameInfoPanel from '../../common/game-info-panel/game-info-panel.component';
import {externalFocusableComponent} from "../info/game-details-info.component"
import {isFreeTierV2FeatureFlagEnabled} from "../../../assets/lib/utils";
import {getIsPostAuthFetched, getUserIsSubscribed} from "../../../app.selectors";


const REFRESH_PREVIEW_DELAY = 400;
class GameDetailsSimilarComponent extends Component {
	constructor() {
		super();
		this.state = {
			animate:false,
			nextId:null,
		};
		this.onSimilarGamesFetched = this.onSimilarGamesFetched.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onTileClicked = this.onTileClicked.bind(this);

		this.selectedGameId = null;
	}

	UNSAFE_componentWillMount() {
		const id = this.props.currentId;
		this.props.dispatch(FetchSimilarGamesAction(id,this.onSimilarGamesFetched));
		this.props.dispatch(UpdateMyMenuSelection({title: 'game-similar'}));
	}

	componentDidMount() {
		this.selectedGameId = null;
	}
	componentWillUnmount() {
		this.selectedGameId = null;
		clearTimeout(this.infoTimerId);
		this.props.dispatch(SetSelectedGameInfoAction(null));
	}
	
	onChange = (gameId) => {
		this.selectedGameId = gameId;
		clearTimeout(this.infoTimerId);
		this.infoTimerId = setTimeout(() => {
			this.props.dispatch(SetSelectedGameInfoAction(gameId));
		}, REFRESH_PREVIEW_DELAY);
	};

	onTileClicked = (gameId, listName, hasChallenges) => {
		if (this.isNeedToShowSubscriptionsComparisonPopup() && hasChallenges) {
			navigateToLocation(ROUTES.GAME_CHALLENGES, {id: gameId, focusElement: 'initialChallenge'});
			return;
		}

		if(this.selectedGameId === gameId) {
			navigateToLocation(ROUTES.GAME_INFO, {id: gameId, focusElement: externalFocusableComponent.PLAY_BUTTON});
		}else {
			this.selectedGameId = gameId;
			this.props.dispatch(SetSelectedGameInfoAction(gameId));
		}
	};

	onSimilarGamesFetched = () => {
		setTimeout(() => {
			if (this.props.items?.length > 0) {
				this.props.dispatch(SetSelectedGameInfoAction(this.props.items[0]._id));
			}
		});
	};

	isNeedToShowSubscriptionsComparisonPopup() {
		const { isFreeTierV2FeatureEnabled, isPostAuthFetched, isUserSubscribed } = this.props;

		return isFreeTierV2FeatureEnabled
			&& isPostAuthFetched
			&& !isUserSubscribed;
	}

	render() {
		if (!this.props.items || this.props.items.length === 0 ) return null;
		return (
			<div className="game-details-similar-component">
				<div className="top-container">
					<GameInfoPanel/>
				</div>
				<ListContainer
					lists={[ {
						'id':'Similar Games List',
						'data': this.props.items.slice(0, 20),
						type: LIST_TYPES.HORIZONTAL,
						parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL,
					} ]}
					onTileFocus={this.onChange}
					onTileClick={this.onTileClicked}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		currentId: getCurrentGameDetailsId(state),
		items: getGetSimilarGames(state),
	};
};

export const GameDetailsSimilar = connect(mapStateToProps)(GameDetailsSimilarComponent);
