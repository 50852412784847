import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../app.router';
import { Input } from '../common/input/input.component';
import { SetTutorialId } from '../tutorial/tutorial.actions.js';
import inputManager from '../../assets/lib/inputmanager';
import './search.component.less';
import {
	getSearchIsLoading,
	getSearchQuery,
	getSearchGameResults,
	getSearchUserResults,
	getAllGames,
	getAllGamesFetched,
	getSearchPlatforms,
	getSearchGenres,
	getRecommendations,
	getSearchPublishers,
	getSearchGamesWithChallenge,
	getSearchYears,
	getSearchRelatedGames,
} from './search.selectors';
import { fetchAllGames, fetchRecommendations, fetchSearchResult } from './search.actions';
import TileUser from '../common/tiles/tile-user.component';
import { LIST_TYPES } from '../common/list/list-container.component';
import ListContainer from '../common/list/list-container.component';
import { Loader } from '../common/loader/loader.component';
import RecommendationItem from './recommendationItem.component';
import { externalFocusableComponent } from "../game-details/info/game-details-info.component"
import { isFreeTierV2FeatureFlagEnabled } from "../../assets/lib/utils";
import { getIsPostAuthFetched, getUserIsSubscribed } from "../../app.selectors";
import deviceInfo from '../../assets/lib/deviceInfo';

const PAGE_ID = 'search_1';
const parentId = 'search-page';
const filter = [
	{
		text: '<'
	},
	{
		text: 'A>C'
	},
	{
		text: 'D>F'
	},
	{
		text: 'G>J'
	},
	{
		text: 'K>N'
	},
	{
		text: 'O>R'
	},
	{
		text: 'S>T'
	},
	{
		text: 'U>Z'
	},
	{
		text: '0>9'
	}
];
let prevTile = null;

export let updateSearch = null;

class SearchComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lists: [],
			allGamesCount: 0,
			gamesCount: 0,
			usersCount: 0,
			xboxLoading: true,
			lastQuery: "",
			showFilter: false
		};
	}

	componentDidMount() {
		this.props.dispatch(SetTutorialId(PAGE_ID));
		this.props.dispatch(fetchRecommendations());
		this.props.dispatch(fetchAllGames(this.fetchAllGamesSuccess));
		updateSearch = this.updateSearchFunction;
		inputManager.setBackFunction(() => { navigateToLocation(ROUTES.HOMEPAGE) });
		setTimeout(() => { this.checkPrevSelectedTile() }, 1000);
	}

	componentDidUpdate() {
		// if (deviceInfo.isRunningOnNativeClientV2() && this.state.xboxLoading) { // TODO-XBOX
		// 	setTimeout(() => {
		// 		this.setState({ xboxLoading: false });
		// 	}, 3000);
		// }
		if (!inputManager.currentChild) {
			let resultsParent = inputManager.getParent('gameResults');
			if (resultsParent) {
				let firstTile = resultsParent.children[Object.keys(resultsParent.children)[0]];
				inputManager.setCurrentChildById(firstTile.id, 'gameResults');
			}
		}
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null);
		updateSearch = null;
	}

	fetchAllGamesSuccess = () => {
		this.prepareLists();
		if (this.props.query) {
			this.searchQuery(this.props.query);
		}
	}

	updateSearchFunction = (query) => {
		this.searchQuery(query);
	}

	searchQuery = (query) => {
		this.setState({ lastQuery: query });
		this.props.dispatch(fetchSearchResult(query, this.prepareLists));
		if (this.refs.searchInputRef) this.refs.searchInputRef.setValue(query);
	}

	checkPrevSelectedTile = () => {
		// if focused on the search input element - do not focus on a tile
		if (this.refs.searchInputRef
			&& (this.refs.searchInputRef.inputRef === document.activeElement)) {
			return;
		}

		if (prevTile) {
			inputManager.setCurrentChildById(prevTile.childId, prevTile.parentId);
			prevTile = null;
		}
	};

	onChangeText = (value) => {
		const query = value;
		if (this.searchTimer) clearTimeout(this.searchTimer);
		if (deviceInfo.isRunningOnNativeClientV2() && value.length < 3) return;
		this.searchTimer = setTimeout(() => {
			// if (deviceInfo.isRunningOnNativeClientV2()) {
			// 	this.setState({ xboxLoading: true });
			// }
			this.searchTimer = null;
			this.setState({ lastQuery: query });

			this.props.dispatch(fetchSearchResult(query, this.prepareLists));
		}, 500);
	};

	isNeedToShowSubscriptionsComparisonPopup() {
		const { isFreeTierV2FeatureEnabled, isPostAuthFetched, isUserSubscribed } = this.props;

		return isFreeTierV2FeatureEnabled
			&& isPostAuthFetched
			&& !isUserSubscribed;
	}

	onSelect = (tileId, listId, hasChallenges) => {
		prevTile = {
			parentId: listId,
			childId: tileId
		};

		if (this.isNeedToShowSubscriptionsComparisonPopup() && hasChallenges) {
			navigateToLocation(ROUTES.GAME_CHALLENGES, { id: tileId, focusElement: 'initialChallenge' });
			return;
		}

		if (listId === "playerResults") {
			navigateToLocation(ROUTES.USER_PROFILE, { id: tileId });
		} else {
			navigateToLocation(ROUTES.GAME_INFO, { id: tileId, focusElement: externalFocusableComponent.PLAY_BUTTON });
		}
	};

	onRecommendationItemClick = (text) => {
		if (text == "ALL") {
			this.setState({ showFilter: !this.state.showFilter });
			this.refs.searchInputRef.setValue("");

			setTimeout(() => {
				this.onFilterItemClick(filter[1].text);
			}, 250);
		}
		else {
			if (this.refs.searchInputRef) {
				this.refs.searchInputRef.setValue(text);
				this.setState({ lastQuery: text });
				this.props.dispatch(fetchSearchResult(text, this.prepareLists));
			}
		}
	};

	onFilterItemClick = (text) => {
		let resultsParent = inputManager.getParent(parentId);

		if (text == "<") {
			this.setState({ showFilter: !this.state.showFilter });
			setTimeout(() => {

				if (resultsParent) {
					let firstTile = resultsParent.children[Object.keys(resultsParent.children)[0]];
					inputManager.setCurrentChildById(firstTile.id, parentId);

				}
			}, 500);
		}
		else {

			this.setState({ xboxLoading: true });
			if (resultsParent) {
				let ndx = 0;
				let firstTile = null;
				for (let key in resultsParent.children) {
					if (resultsParent.children[key].element.innerText == text) {
						firstTile = resultsParent.children[Object.keys(resultsParent.children)[ndx]];
						inputManager.setCurrentChildById(firstTile.id, parentId);
						break;
					}
					ndx++;
				}


			}
			this.onChangeText(text);

		};
	}

	renderRecommendations = () => {
		const { recommendations } = this.props;

		if (!recommendations || recommendations.length === 0) {
			return null;
		}

		let recommendationsList = recommendations.slice();
		recommendationsList.unshift({ text: "ALL" });

		/**
		 *  Temporary fix while we fix search on the backend properly
		 * Remove Multiplayter recommended option as it does not work, replace with 'Data East'
		 ***/
		const hasMultiPlayer = recommendationsList.find((item) => item.text === "Multiplayer");
		if(hasMultiPlayer)hasMultiPlayer.text = "Data East";
		
		recommendationsList.pop();

		return (
			<div className="recommendation-container">
				<div className="recommendation-title">
					Suggested:
				</div>
				{
					recommendationsList.map((item, index) => (
						<RecommendationItem
							key={`recommendation-item-${index}`}
							item={item}
							index={index}
							parentId={parentId}
							onClick={this.onRecommendationItemClick}
						/>
					))
				}


			</div>
		);
	};

	renderSearchTotalString()
	{
		let allGamesCount = this.state.allGamesCount;
		let gamesCount = this.state.gamesCount;
		
		if(allGamesCount<1 && this.props.allGames)
		{
			this.props.allGames.forEach(list => {
				allGamesCount += list.games.length;
			});
			this.state.allGamesCount = allGamesCount;
		}

		if (allGamesCount<1 && gamesCount>0)
		{
			return gamesCount+" game"+(gamesCount===1?'':'s');
		}
		else if(gamesCount>0)
		{
			return gamesCount+" game"+(gamesCount===1?'':'s') +" of "+allGamesCount;
		}
		return "";
	}
	
	renderFilter = () => {
		return (
			<div className="recommendation-container">
				<div className="recommendation-title">
					Filter:
				</div>
				{
					filter.map((item, index) => (
						<RecommendationItem
							key={`filter-item-${index}`}
							item={item}
							index={index}
							parentId={parentId}
							onClick={this.onFilterItemClick}
						/>
					))
				}


			</div>
		);
	};

	prepareLists = () => {
		setTimeout(() => { // need to wait redux state is updated - otherwise it does not show the latest result
			const { query, lastQuery, searchGameResults, searchRelatedGames, searchUserResults, allGames, searchPlatforms, searchPublishers, searchGamesWithChallenge, searchGenres, searchYears } = this.props;
	
			let allGamesCount = 0;
			let gamesCount = searchGameResults.length;
			let usersCount = searchUserResults.length;
	
			const searchQuery = this.refs.searchInputRef ? this.refs.searchInputRef.getValue() : null;
	
			searchPlatforms.forEach(platform => {
				gamesCount += platform.games.length;
			});
	
			searchPublishers.forEach(publisher => {
				gamesCount += publisher.games.length;
			});
	
			searchYears.forEach(year => {
				gamesCount += year.games.length;
			});
	
			searchGamesWithChallenge.forEach(gamesWithChallenge => {
				gamesCount += gamesWithChallenge.games.length;
			});
	
			searchGenres.forEach(genre => {
				gamesCount += genre.games.length;
			});
	
			gamesCount += searchRelatedGames.length;
	
			const lists = [];
			if (gamesCount <= 0 && usersCount <= 0) {
				if (!this.state.showFilter || searchQuery?.length>0)// Show recommended games, rather than everything
				{
					let recommendedGamesList = [];
					allGames.forEach(list => {
						list.games.forEach(game => {
							allGamesCount++;
	
							if (game.studio == "LucasArts") {
								recommendedGamesList.push(game);
								gamesCount++;
							}
							else if (game.studio == "SNK") {
								recommendedGamesList.push(game);
								gamesCount++;
							}
							else if (game.platform == "Sony PlayStation") {
								recommendedGamesList.push(game);
								gamesCount++;
							}
							else if (game.platform == "SNES") {
								recommendedGamesList.push(game);
								gamesCount++;
							}
							else if (game.hasChallenges) {
								recommendedGamesList.push(game);
								gamesCount++;
							}
						});
					})
					lists.push({
						type: LIST_TYPES.GRID,
						id: "Recommended",
						title: searchQuery?.length>0?"No results found. Showing recommended:":"Recommended",
						data: recommendedGamesList,
					});
				}
				else {
					let firstLetter = null;
					let lastLetter = null;
					let regExpLiteral = null;
	
					if (this.state.lastQuery.charAt(1) == ('>')) {
						firstLetter = this.state.lastQuery.charAt(0).toUpperCase();
						lastLetter = this.state.lastQuery.charAt(2).toUpperCase();
						try {
							regExpLiteral = new RegExp("[" + firstLetter + "-" + lastLetter + "].*");
						}
						catch (ex) {
							regExpLiteral = null;
						}
					}
	
					allGames.forEach(list => {
						let showList = true;
						//let test = new RegExp(`^${char}`).test(word) // true
						// if string is 3 characters and has a >
						if (regExpLiteral) {
							showList = list.title.match(regExpLiteral);
						}
	
						if (showList) {
							lists.push({
								type: LIST_TYPES.GRID,
								id: list.title,
								title: list.title,
								data: list.games,
							});
							gamesCount += list.games.length;
						}
						allGamesCount += list.games.length;
	
					});
				}
			} else {
				if (!deviceInfo.isPlayStationPlatformPS4()) {
					lists.push({
						type: LIST_TYPES.HORIZONTAL,
						id: "playerResults",
						title: "Players",
						data: searchUserResults,
						renderer: props => <TileUser {...props} />
					});
				}
	
				lists.push({
					type: LIST_TYPES.GRID,
					id: "gameResults",
					title: "Game Titles",
					data: searchGameResults,
					shouldForceRefreshData: true,
				});
	
				searchGamesWithChallenge.forEach(gamesWithChallenge => {
					gamesWithChallenge.games.sort((a, b) => (a.title === b.title ? 0 : a.title > b.title ? 1 : -1))
	
					lists.push({
						type: LIST_TYPES.GRID,
						id: gamesWithChallenge.title,
						title: gamesWithChallenge.title,
						data: gamesWithChallenge.games,
					});
				});
	
				searchPlatforms.forEach(platform => {
					const platformTitle = platform.title.replace(/sony playstation/gi, "32-Bit Console");
					platform.games.sort((a, b) => (a.title === b.title ? 0 : a.title > b.title ? 1 : -1))
					lists.push({
						type: LIST_TYPES.GRID,
						id: "Platform (" + platform.title + ")",
						title: `Platform (${platformTitle})`,
						data: platform.games,
					});
				});
	
				searchPublishers.forEach(publisher => {
					const publisherTitle = publisher.title;
					publisher.games.sort((a, b) => (a.title === b.title ? 0 : a.title > b.title ? 1 : -1))
					lists.push({
						type: LIST_TYPES.GRID,
						id: "Publisher (" + publisher.title + ")",
						title: `Publisher (${publisherTitle})`,
						data: publisher.games,
					});
				});
	
	
				searchGenres.forEach(genre => {
					const genreTitle = genre.title;
					genre.games.sort((a, b) => (a.title === b.title ? 0 : a.title > b.title ? 1 : -1))
					lists.push({
						type: LIST_TYPES.GRID,
						id: "Genre (" + genre.title + ")",
						title: `Genre (${genreTitle})`,
						data: genre.games,
					});
				});
	
				searchYears.forEach(year => {
					const yearTitle = year.title;
					year.games.sort((a, b) => (a.title === b.title ? 0 : a.title > b.title ? 1 : -1))
					lists.push({
						type: LIST_TYPES.GRID,
						id: "Year (" + year.title + ")",
						title: `Year (${yearTitle})`,
						data: year.games,
					});
				});
	
			}
	
			this.setState({
				lists,
				gamesCount,
				usersCount
			});
	
			this.setState({ xboxLoading: false });
	
			if (allGamesCount > 0) {
				this.setState({
					allGamesCount
				});
			}
		});
	};

	renderResults = () => {
		const { query, allGamesFetched, isLoading } = this.props;

		if (isLoading || this.state.xboxLoading) {
			return (
				<div className="loader-games">
					<Loader loading={true} />
				</div>
			);
		}

		if (!this.state.gamesCount && !this.state.usersCount) {
			if (query) {
				return (
					<p className="no-result-message">
						{`No result for \'${query}\'.`}
					</p>
				);
			}
			if (!allGamesFetched) return null;
		}

		return (
			<ListContainer
				lists={this.state.lists}
				onTileClick={this.onSelect}
			/>
		);
	};

	render() {
		return (
			<section className={`search-component`}>
				<div className="search-input-container">
					<Input
						ref="searchInputRef"
						input={{ onChange: (e) => this.onChangeText(e.target.value) }}
						onValueChange={this.onChangeText}
						containerClass="novalidate"
						childId="searchInput"
						placeholder="Search..."
					/>
					<div className="result-count clearfix">
						{this.renderSearchTotalString()}					
					</div>
					{!this.state.showFilter && this.renderRecommendations()}
					{this.state.showFilter && this.renderFilter()}

				</div>

				{/* {this.state.xboxLoading && <Loader loading={true}/>}
 */}				{this.renderResults()}
			</section>
		);
	}
}


const mapStateToProps = (state) => {
	const searchGameResults = getSearchGameResults(state);
	const searchRelatedGames = getSearchRelatedGames(state);
	const searchUserResults = getSearchUserResults(state);
	const searchPlatforms = getSearchPlatforms(state);
	const searchPublishers = getSearchPublishers(state);
	const searchYears = getSearchYears(state);
	const searchGamesWithChallenge = getSearchGamesWithChallenge(state);
	const searchGenres = getSearchGenres(state);
	const allGames = getAllGames(state);
	const allGamesFetched = getAllGamesFetched(state);
	const recommendations = getRecommendations(state);

	return {
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		query: getSearchQuery(state),
		allGames,
		recommendations,
		searchGameResults,
		searchRelatedGames,
		searchUserResults,
		searchPlatforms,
		searchPublishers,
		searchYears,
		searchGamesWithChallenge,
		searchGenres,
		isLoading: getSearchIsLoading(state),
		// allGamesFetched: deviceInfo.isRunningOnNativeClientV2() ? null : allGamesFetched,
		allGamesFetched,
	};
};

export const Search = connect(mapStateToProps)(SearchComponent);
