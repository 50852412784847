import { getWebviewState } from './../../app.selectors';
import inputManager from './inputmanager';
import deviceInfo, { PLATFORM_TYPE_WEB } from './deviceInfo';
import {needKeyboardFocus} from './game-wrapper';
// import * as GameWrapper from '../../assets/lib/game-wrapper';

const STICK_DELAY = 300;
const BUTTON_DELAY = 300;
const ENTER_BUTTON_DELAY = 2000;
const KEY_DELAY = 100;
const STICK_THRESHOLD = 0.7;
export const arrowKeys = [ 37, 38, 39, 40 ];

let gamePadInstance = null;
class GamePad {
	constructor(eventCallback) {
		this.eventCallback = eventCallback;
		this.prevViewState = true;
		this.needKeyboardFocusHasSent = false;

		this.buttonsPressed = {};
		this.axesTimeOut = null;//timeout for axes press
		this.buttonsTimeOut = null;//timeout for buttons press
		this.keysTimeOut = null;//timeout for keys press
		this.enterKeyTimeOut = null;//timeout for enter button release

		this._keyMapping = {
			gamepad: {
				'button_1': 0,
				'button_2': 1,
				'button_3': 2,
				'button_4': 3,
				'shoulder_top_left': 4,
				'shoulder_top_right': 5,
				'shoulder_bottom_left': 6,
				'shoulder_bottom_right': 7,
				'select': 8,
				'start': 9,
				'stick_button_left': 10,
				'stick_button_right': 11,
				'd_pad_up': 12,
				'd_pad_down': 13,
				'd_pad_left': 14,
				'd_pad_right': 15,
				'vendor': 16
			},
			axes: {
				'd_pad_up': 1,
				'd_pad_down': 3,
				'd_pad_left': 0,
				'd_pad_right': 2,
			},
			keyboard: {
				'button_1': [ 13 ],
				'button_2': [ 27 ],
			    'button_2': [ 10009 ],
				'd_pad_up': [ 38 ],
				'd_pad_down': [ 40 ],
				'd_pad_left': [ 37 ],
				'd_pad_right': [ 39 ],
				'tab': [ 9 ],
			}
		};

		this.handleKeyboardEventListener = this.handleKeyboardEventListener.bind(this);
		this.handleKeyUpEventListener = this.handleKeyUpEventListener.bind(this);
		this.onAnimationFrame = this.onAnimationFrame.bind(this);
		this.checkButtons = this.checkButtons.bind(this);
		this.checkAxes = this.checkAxes.bind(this);
		this.onAxesTimeoutEnd = this.onAxesTimeoutEnd.bind(this);
		this.onButtonsTimeoutEnd = this.onButtonsTimeoutEnd.bind(this);
		this.onKeysTimeoutEnd = this.onKeysTimeoutEnd.bind(this);
		this.startEnterKeyTimer = this.startEnterKeyTimer.bind(this);
		this.stopEnterKeyTimer = this.stopEnterKeyTimer.bind(this);
		this.onStoreUpdate = this.onStoreUpdate.bind(this);
		this.isEnterKey = this.isEnterKey.bind(this);

		// wait for deviceInfo to be ready before it is used in resume()
		setTimeout(() => {
			this.resume();
		}, 0);
	}

	findKeyMapping(index, mapping) {
		const results = [];

		Object.keys(mapping).forEach((key) => {
			if (mapping[key] === index) {
				results.push(key);
			} else if (Array.isArray(mapping[key]) && mapping[key].indexOf(index) !== -1) {
				results.push(key);
			}
		});

		return results;
	}

	handleKeyboardEventListener(e) {
		// FOR DEBUGGING ONLY! - fake xbox 'B' button click 
		// if (e.keyCode === 27) { // Escape
		// 	GameWrapper.messageFromNative(JSON.stringify({
		// 		type: 'onkeyboardevent',
		// 		keyboardKey: 'button_2'
		// 	}));
		// }

		if(e.target.tagName === 'INPUT' && (e.keyCode === 37 || e.keyCode === 39)) {
			// console.log(e,e.keyCode);
		}else{
			if (arrowKeys.indexOf(e.keyCode) > -1 || e.keyCode===13 || e.keyCode===9) {
				e.preventDefault();
			}
		}

		if(this.keysTimeOut===null) {
			if(this.isEnterKey(e.keyCode)) {// ignore repeated enter key events for 2 seconds
				if(this.enterKeyTimeOut) {
					return;
				} else {
					this.startEnterKeyTimer();
				}
			}

			this.keysTimeOut = setTimeout(this.onKeysTimeoutEnd,KEY_DELAY);
			const keys = this.findKeyMapping(e.keyCode, this._keyMapping.keyboard);
			keys.forEach((key) => {
				this.eventCallback(key);
			});
		}
	};

	handleKeyUpEventListener(e) {
		if(this.isEnterKey(e.keyCode)) {
			this.stopEnterKeyTimer();
		}
	};

	onStoreUpdate(store) {
		const viewState = getWebviewState(store.getState()).visible;
		if (this.prevViewState !== viewState) {
			this.prevViewState = viewState;
			if(viewState) {
				this.resume();
			}else {
				this.pause();
			}
		}
	}

	pause() {
		document.removeEventListener('keydown', this.handleKeyboardEventListener);
		document.removeEventListener('keyup', this.handleKeyUpEventListener);

		if (this.requestAnimationFrameId) {
			window.cancelAnimationFrame(this.requestAnimationFrameId);
			this.requestAnimationFrameId = null;
		}
	};

	resume() {
		document.addEventListener('keydown', this.handleKeyboardEventListener);
		document.addEventListener('keyup', this.handleKeyUpEventListener);
		if (deviceInfo && deviceInfo.platformType === PLATFORM_TYPE_WEB) {
			this.onAnimationFrame();
		}
		document.body.focus();
	};

	onAnimationFrame() {
		const gamePads = navigator.getGamepads();
		for (let i = 0; i < gamePads.length; i++) {
			const gamePad = gamePads[i];
			if(gamePad) {
				this.checkButtons(gamePad,i);
				this.checkAxes(gamePad);

				if(!this.needKeyboardFocusHasSent){
					this.needKeyboardFocusHasSent = true;
					needKeyboardFocus();
				}
			}
		}

		this.requestAnimationFrameId = window.requestAnimationFrame(this.onAnimationFrame);
	}

	checkButtons(gamePad,gamePadIndex) {
		gamePad.buttons.forEach((button,index) => {
			if(button.pressed) {
				if(this.buttonsTimeOut===null) {
					let isAbleToPress= false;
					if(!this.buttonsPressed[gamePadIndex+'-'+index])isAbleToPress=true;
					if(this.buttonsPressed[gamePadIndex+'-'+index]) {//only direction buttons have keyrepeat abilty
						if(	index===this._keyMapping.gamepad.d_pad_up
								|| index===this._keyMapping.gamepad.d_pad_down
								|| index===this._keyMapping.gamepad.d_pad_left
								|| index===this._keyMapping.gamepad.d_pad_right
						) isAbleToPress=true;
						else isAbleToPress=false;
					}

					if(isAbleToPress) {
						this.buttonsTimeOut = setTimeout(this.onButtonsTimeoutEnd,BUTTON_DELAY);
						this.buttonsPressed[gamePadIndex+'-'+index] = true;
						const keys = this.findKeyMapping(index, this._keyMapping.gamepad);
						if(keys) {
							keys.forEach((key) => {
								this.eventCallback(key);
							});
						}
					}
				}
			}else {
				if(this.buttonsPressed[gamePadIndex+'-'+index]) {
					clearTimeout(this.buttonsTimeOut);
					this.onButtonsTimeoutEnd();
					delete this.buttonsPressed[gamePadIndex+'-'+index];
				}
			}
		});
	}

	checkAxes(gamePad) {
		let calculatedIndex = -1;

		gamePad.axes.forEach((axis,index) => {
			if(Math.abs(axis)>STICK_THRESHOLD) {
				if(index===0 || index===2) {
					calculatedIndex = 1+Math.round(axis);
				}else if(index===1 || index===3) {
					calculatedIndex = 2+Math.round(axis);
				}
			}

			if(calculatedIndex!==-1 && this.axesTimeOut===null) {
				this.axesTimeOut = setTimeout(this.onAxesTimeoutEnd,STICK_DELAY);
				const keys = this.findKeyMapping(calculatedIndex, this._keyMapping.axes);
				if(keys) {
					keys.forEach((key) => {
						this.eventCallback(key);
					});
				}
			}
		});
	}

	startEnterKeyTimer() {
		this.enterKeyTimeOut = setTimeout(this.stopEnterKeyTimer,ENTER_BUTTON_DELAY);
	}

	stopEnterKeyTimer() {
		if(this.enterKeyTimeOut){
			clearTimeout(this.enterKeyTimeOut);
			this.enterKeyTimeOut = null;
		}
	}

	onAxesTimeoutEnd() {
		this.axesTimeOut = null;
	}

	onButtonsTimeoutEnd() {
		this.buttonsTimeOut = null;
	}

	onKeysTimeoutEnd() {
		this.keysTimeOut = null;
	}

	onKeyboardEvent({keyboardKey}) {
		if(this.eventCallback) {
			this.eventCallback(keyboardKey);
		}
	}

	isEnterKey(keyCode) {
		return keyCode===13;
	}
}

const getGamePad = () => {
	if(gamePadInstance===null) {
		gamePadInstance = new GamePad((key) => {
			inputManager.handleInputEvent(key);
		});
	}
	return gamePadInstance;
};

export default getGamePad();
