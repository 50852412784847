import {Component} from 'react';
import React from 'react';
import './payment-plan-item.component.less';
import {PulsarComponent} from '../common/pulsar/pulsar.component';
import {capitalizeFirstLetter} from '../../assets/lib/utils';
import inputmanager from '../../assets/lib/inputmanager';
import AudioManager, {TYPE_VERTICAL_LIST_ROW_CHANGE} from '../../app.audio';
import Elastic, {LOG_LEVEL} from '../../assets/lib/elastic.lib';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import DeviceInfo, {STORE_TYPE_AMAZON} from '../../assets/lib/deviceInfo';

class PaymentPlanItemComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
                selected:false,
        };
    }

    onCustomFocus = (focus, nextElement) => {
        if (focus) {
                this.setState({selected:true,active:true});
                this.props.onSelect(this.props.sku);
        } else {
            if (nextElement && nextElement.classList.contains('payment-plan-item')) {
                    this.setState({selected:false,active:false});
            } else {
                    this.setState({selected:false});
            }
        }
    };

    onClick = () => {
        AudioManager.playAudio(TYPE_VERTICAL_LIST_ROW_CHANGE);
        inputmanager.setCurrentChildById(this.props.childId, this.props.parentId);
    };

    renderDescription = () => {
        const {term} = this.props;
        if (term==="annual") {
            return (
                    <>
                        <p>With our annual one off payment option, you will save nearly two and a half months subscription cost.</p>
                        {/* <p>Which works out to a total of £24 over the year’s subscription fee.</p> */}
                    </>
            )
        } else {
            return (
                    <>
                        <p>Pay monthly with absolutely no tie in obligation.</p>
                        <p> At any point, you are able to upgrade to our annual saving benefits.</p>
                    </>
            )
        }
    };

    onItemRef = (element) => {
        inputmanager.onChildRef(element, this.props.childId, this.onCustomFocus, this.props.parentId);
    };

    getDisplayCurrencyString = (currencyString) => {

        // Older Amazon devices had currency hardcoded to "US", so this needs ignoring.
        if (DeviceInfo.storeType === STORE_TYPE_AMAZON && !featureInfo.isSupported(FEATURES.AMAZON_CURRENCY_DISPLAY)) 
        {
            return "";
        }    

        // This lists currencies by country code (currency code is country code plus a letter)
        // To look up by currency, just take the first two letters of the currency
        // This list courtesy of public-use list maintained at xe.com
        const CURRENCIES = {    
            "AF" : "؋",   // AFN -  Afghanistan Afghani
            "AL" : "Lek", // ALL -  Albania Lek
            "AN" : "ƒ",   // ANG -  Netherlands Antilles Guilder
            "AR" : "$",   // ARS -  Argentina Peso
            "AU" : "$",   // AUD -  Australia Dollar
            "AW" : "ƒ",   // AWG -  Aruba Guilder
            "AZ" : "₼",   // AZN -  Azerbaijan Manat
            "BA" : "KM",  // BAM -  Bosnia and Herzegovina Convertible Mark
            "BB" : "$",   // BBD -  Barbados Dollar
            "BG" : "лв",  // BGN -  Bulgaria Lev
            "BM" : "$",   // BMD -  Bermuda Dollar
            "BN" : "$",   // BND -  Brunei Darussalam Dollar
            "BO" : "$b",  // BOB -  Bolivia Bolíviano
            "BR" : "R$",  // BRL -  Brazil Real
            "BS" : "$",   // BSD -  Bahamas Dollar
            "BW" : "P",   // BWP -  Botswana Pula
            "BY" : "Br",  // BYN -  Belarus Ruble
            "BZ" : "BZ$", // BZD -  Belize Dollar
            "CA" : "$",   // CAD -  Canada Dollar
            "CH" : "CHF", // CHF -  Switzerland Franc
            "CL" : "$",   // CLP -  Chile Peso
            "CN" : "¥",   // CNY -  China Yuan Renminbi
            "CO" : "$",   // COP -  Colombia Peso
            "CR" : "₡",   // CRC -  Costa Rica Colon
            "CU" : "₱",   // CUP -  Cuba Peso
            "CZ" : "Kč",  // CZK -  Czech Republic Koruna
            "DK" : "kr",  // DKK -  Denmark Krone
            "DO" : "RD$", // DOP -  Dominican Republic Peso
            "EG" : "£",   // EGP -  Egypt Pound
            "EU" : "€",   // EUR -  Euro Member Countries
            "FJ" : "$",   // FJD -  Fiji Dollar
            "FK" : "£",   // FKP -  Falkland Islands (Malvinas) Pound
            "GB" : "£",   // GBP -  United Kingdom Pound
            "GG" : "£",   // GGP -  Guernsey Pound
            "GH" : "¢",   // GHS -  Ghana Cedi
            "GI" : "£",   // GIP -  Gibraltar Pound
            "GT" : "Q",   // GTQ -  Guatemala Quetzal
            "GY" : "$",   // GYD -  Guyana Dollar
            "HK" : "$",   // HKD -  Hong Kong Dollar
            "HN" : "L",   // HNL -  Honduras Lempira
            "HR" : "kn",  // HRK -  Croatia Kuna
            "HU" : "Ft",  // HUF -  Hungary Forint
            "ID" : "Rp",  // IDR -  Indonesia Rupiah
            "IL" : "₪",   // ILS -  Israel Shekel
            "IM" : "£",   // IMP -  Isle of Man Pound
            "IN" : "₹ ",  // INR -  India Rupee
            "IS" : "kr",  // ISK -  Iceland Krona
            "JE" : "£",   // JEP -  Jersey Pound
            "JM" : "J$",  // JMD -  Jamaica Dollar
            "JP" : "¥",   // JPY -  Japan Yen
            "KG" : "лв",  // KGS -  Kyrgyzstan Som
            "KH" : "៛",    // KHR -  Cambodia Riel
            "KP" : "₩",   // KPW -  Korea (North) Won
            "KR" : "₩",   // KRW -  Korea (South) Won
            "KY" : "$",   // KYD -  Cayman Islands Dollar
            "KZ" : "лв",  // KZT -  Kazakhstan Tenge
            "LA" : "₭",   // LAK -  Laos Kip
            "LB" : "£",   // LBP -  Lebanon Pound
            "LK" : "₨",   // LKR -  Sri Lanka Rupee
            "LR" : "$",   // LRD -  Liberia Dollar
            "MK" : "ден", // MKD -  Macedonia Denar
            "MN" : "₮",   // MNT -  Mongolia Tughrik
            "MU" : "₨",   // MUR -  Mauritius Rupee
            "MX" : "$",   // MXN -  Mexico Peso
            "MY" : "RM",  // MYR -  Malaysia Ringgit
            "MZ" : "MT",  // MZN -  Mozambique Metical
            "NA" : "$",   // NAD -  Namibia Dollar
            "NG" : "₦",   // NGN -  Nigeria Naira
            "NI" : "C$",  // NIO -  Nicaragua Cordoba
            "NO" : "kr",  // NOK -  Norway Krone
            "NP" : "₨",   // NPR -  Nepal Rupee
            "NZ" : "$",   // NZD -  New Zealand Dollar
            "PA" : "B/.", // PAB -  Panama Balboa
            "PE" : "S/.", // PEN -  Peru Sol
            "PH" : "₱",   // PHP -  Philippines Peso
            "PK" : "₨",   // PKR -  Pakistan Rupee
            "PL" : "zł",  // PLN -  Poland Zloty
            "PY" : "Gs",  // PYG -  Paraguay Guarani
            "RO" : "lei", // RON -  Romania Leu
            "RS" : "Дин.",// RSD -  Serbia Dinar
            "RU" : "₽",   // RUB -  Russia Ruble
            "SB" : "$",   // SBD -  Solomon Islands Dollar
            "SC" : "₨",   // SCR -  Seychelles Rupee
            "SE" : "kr",  // SEK -  Sweden Krona
            "SG" : "$",   // SGD -  Singapore Dollar
            "SH" : "£",   // SHP -  Saint Helena Pound
            "SO" : "S",   // SOS -  Somalia Shilling
            "SR" : "$",   // SRD -  Suriname Dollar
            "SV" : "$",   // SVC -  El Salvador Colon
            "SY" : "£",   // SYP -  Syria Pound
            "TH" : "฿",   // THB -  Thailand Baht
            "TR" : "₺",   // TRY -  Turkey Lira
            "TT" : "TT$", // TTD -  Trinidad and Tobago Dollar
            "TV" : "$",   // TVD -  Tuvalu Dollar
            "TW" : "NT$", // TWD -  Taiwan New Dollar
            "UA" : "₴",   // UAH -  Ukraine Hryvnia
            "US" : "$",   // USD -  United States Dollar
            "UY" : "$U",  // UYU -  Uruguay Peso
            "UZ" : "лв",  // UZS -  Uzbekistan Som
            "VE" : "Bs",  // VEF -  Venezuela Bolívar
            "VN" : "₫",   // VND -  Viet Nam Dong
            "XC" : "$",   // XCD -  East Caribbean Dollar
            "ZA" : "R",   // ZAR -  South Africa Rand
            "ZW" : "Z$",  // ZWD -  Zimbabwe Dollar

            // Euro countries. Because the Euro is an international currency, country codes
            // don't map directly to currency.
            "AT" : "€",
            "BE" : "€",
            "CY" : "€",
            "EE" : "€",
            "FI" : "€",
            "FR" : "€",
            "DE" : "€",
            "GR" : "€",
            "IE" : "€",
            "IT" : "€",
            "LV" : "€",
            "LT" : "€",
            "LU" : "€",
            "MT" : "€",
            "NL" : "€",
            "PT" : "€",
            "SK" : "€",
            "SI" : "€",
            "ES" : "€",
        };

        // Purely the currency symbols, so we don't double-convert
        const CURRENCY_SYMBOLS = [ "$", "$U", "$b", "B/.", "BZ$", "Br", "Bs", "C$", "CHF", "Ft", "Gs", "J$", "KM", "Kč", "L", "Lek", "MT", "NT$", "P", 
                                "Q", "R", "R$", "RD$", "RM", "Rp", "S", "S/.", "TT$", "Z$", "kn", "kr", "lei", "zł", "¢", "£", "¥", "ƒ", "Дин.",
                                "ден", "лв", "؋", "៛", "₡", "₦", "₨", "₩", "₪", "₫", "€", "₭", "₮", "₱", "₴","₹", "₺", "₼", "₽" ];


        if (typeof currencyString !== 'string') {
            Elastic.log(LOG_LEVEL.ERROR,"getDisplayCurrencyString: Passed currency string that is not a string! '" + JSON.stringify(currencyString) + "'");
            return currencyString;
        }

        // Should use a proper internationalisation function to display currency, but that doesn't fit into a design where
        // the currency symbol is a different style to the actual cost. *Sigh*. Also, the 'currencyString' can be the currency
        // symbol, or a country code, or quite possibly a language code depending on the payment system.
        if (CURRENCY_SYMBOLS.includes(currencyString)) {
            return currencyString;
        }

        // Check for country codes
        if (currencyString.length >= 2) {
            const convertedString = CURRENCIES[currencyString.substr(0,2).toUpperCase()];
            if (convertedString) {
                Elastic.log(LOG_LEVEL.INFO,"getDisplayCurrencyString: Converted county/currency code " + JSON.stringify(currencyString) + " to currency symbol.");
                return convertedString;
            }
        }

        // Otherwise, note the oddity and return it
        Elastic.log(LOG_LEVEL.WARNING, "getDisplayCurrencyString: Unrecognised currency string passed in: '" + JSON.stringify(currencyString) + "'");
        return currencyString;
    }

    render() {
        const { className, term, priceAnnually, priceMonthly, currencyString, isFreeTierV2FeatureEnabled, freemiumV2Price } = this.props;
        const currencyDisplayString = this.getDisplayCurrencyString(currencyString);

        return (
            <div
                ref={this.onItemRef}
                className={`payment-plan-item ${className?className:""} ${this.state.selected?'selected':''} ${this.state.active?'active':''} ${isFreeTierV2FeatureEnabled ? ' freetier-v2' : ''}`}
                onClick={this.onClick}
            >
                <div className="top-bar"/>
                <div className={isFreeTierV2FeatureEnabled ? "new-content-container" : "content-container"}>
                    {isFreeTierV2FeatureEnabled ? (
                        <>
                            <h2 className="new-content-container__head">
                                Unlimited Gaming for
                            </h2>
                            <h1 className="new-content-container__price">
                                <span>{currencyDisplayString}</span>{freemiumV2Price}
                            </h1>
                            <p className="new-content-container__text">
                            {capitalizeFirstLetter(term) + ' Subscription'}
                            </p>
                        </>
                    ) : (
                        <>
                            <div className="left-content">
                                <h5>Benefits</h5>
                                {this.renderDescription()}
                            </div>
                            <div className="right-content">
                                <h2>{capitalizeFirstLetter(term)}</h2>
                                <h5><span>{currencyDisplayString}</span>{priceAnnually}</h5>
                                <p>{term==="annual"?"Total Payable":"Equivalent To Annually"}</p>
                                <h1><span>{currencyDisplayString}</span>{priceMonthly}</h1>
                                <p>{term==="annual"?"Equivalent To Per Month":"Per Month"}</p>
                            </div>
                        </>
                    )}
                    
                </div>
                <div className="most-popular-frame">
                    <div className="title"><span>Most Popular</span></div>
                </div>
                <PulsarComponent />
            </div>
        );
    }
}

export const PaymentPlanItem = PaymentPlanItemComponent;