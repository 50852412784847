import React,{Component} from 'react';
import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import inputManager from '../../../assets/lib/inputmanager';
import './tournament-result-popup.compontent.less';
import gemIcon from "../../../assets/images/global/gems.svg";
import LeaderboardPositionComponent from '../../tournaments/info/leaderboard-position.component';
import {AddPlayerToTournamentAction, SetIsTournamentInfoPopupSeen} from "../../tournaments/tournaments.actions";
import {connect} from "react-redux";
import {navigateToLocation, ROUTES} from "../../../app.router";
import { ButtonWithGem } from '../../common/button/common-button-with-gem.component';
import {openUrlOnDefaultBrowser} from "../../../assets/lib/game-wrapper";
import {getBadgeIconSrc} from "../../../assets/lib/utils";
import { analyticsEvent } from '../../../assets/lib/antstreamAnalytics';
import { buildSponsoredTournamentLabel, determineTournamentPlayCost } from '../../tournaments/tournaments.utils';
import { QRCodeSVG } from 'qrcode.react';
import { userAccessAllowed } from '../../../app.helpers';
import deviceInfo from '../../../assets/lib/deviceInfo';

const navLayer=2;
export const TOURNAMENT_RESULT_TYPES={
	HOMEPAGE_INFO:"HOMEPAGE_INFO",
	SESSION_RESULT_RANK:"SESSION_RESULT_RANK",
	RESULT_ACHIEVEMENT:"RESULT_ACHIEVEMENT",
	RESULT_RANK:"RESULT_RANK",
	RESULT_GEM:"RESULT_GEM",
};

class TournamentResultPopup extends Component {
	componentDidMount() {
		const {resultType,tournamentData} =this.props.resultObject;

		if(resultType===TOURNAMENT_RESULT_TYPES.HOMEPAGE_INFO){
			inputManager.setCurrentChildById("TournamentResultPopup-joinButton");
			this.props.dispatch(SetIsTournamentInfoPopupSeen(tournamentData._id));
		}else if(resultType===TOURNAMENT_RESULT_TYPES.SESSION_RESULT_RANK){
			inputManager.setCurrentChildById("TournamentResultPopup-retryButton");
		}else{
			inputManager.setCurrentChildById("TournamentResultPopup-okButton");
		}
		inputManager.setBackFunction(()=>{this.onOkClicked()},navLayer);
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null,navLayer);
	}

	onSkipClicked = () => {
		if(this.props.onSkipClicked)this.props.onSkipClicked();
		closePopup();
	};

	onJoinClicked = () => {
		const {tournamentData} =this.props.resultObject;
		if(this.props.onJoinClicked)this.props.onJoinClicked();
		closePopup();
		this.props.dispatch(AddPlayerToTournamentAction(tournamentData._id,null,true));
		navigateToLocation(ROUTES.TOURNAMENTS_INFO,{id:tournamentData._id});
	};

	onOkClicked = () => {
		if(this.props.onOkClicked)this.props.onOkClicked();
		closePopup();
	};

	onRetryClicked = () => {
		if (this.isSponsored()) {
			analyticsEvent({
				category: 'Sponsored Tournaments v1.5',
				action: 'retry_button_clicked',
				label: buildSponsoredTournamentLabel(this.props.resultObject?.tournamentData)
			});
		}

		if(this.props.onRetryClicked)this.props.onRetryClicked();
		closePopup();
	};

	renderResult = () => {
		const {resultType} =this.props.resultObject;

		if(resultType===TOURNAMENT_RESULT_TYPES.RESULT_RANK){
			return this.renderRankResult();
		}else if(resultType===TOURNAMENT_RESULT_TYPES.RESULT_GEM){
			return this.renderGemResult();
		}else if(resultType===TOURNAMENT_RESULT_TYPES.SESSION_RESULT_RANK){
			return this.renderSessionRankResult();
		}else if(resultType===TOURNAMENT_RESULT_TYPES.RESULT_ACHIEVEMENT){
			return this.renderAchievementResult();
		} else if(resultType===TOURNAMENT_RESULT_TYPES.HOMEPAGE_INFO){
			return this.renderHomepageInfo();
		}
	};

	renderRankResult = () => {
		const {leaderBoardObject, challengeObject} = this.props.resultObject;

		return(
			<>
				<h1>Tournament Over</h1>
				<h3>Final Results</h3>
				<div className="reward-container">
					<div className="reward-detail">
						<LeaderboardPositionComponent rank={leaderBoardObject.rank} />
					</div>
					<div className="score-container">
						<span className="score-label"> You Scored: </span>
						<span className="score-text"> {leaderBoardObject.score}</span>
						<span className="score-content"> {challengeObject.score_label}</span>
					</div>
				</div>
			</>
		);
	};

	renderHomepageInfo = () => {
		const {tournamentData} = this.props.resultObject;
		const tournamentTitle = tournamentData?.title;

		return(
			<>
				<h1>Special Event On Now!</h1>
				<h3>
					{`Join us for our first ever ${tournamentTitle}. Click now and the gems are on us!`} <img className="pro-tip-gem-image" src={gemIcon} alt="gems"/>
				</h3>

				{tournamentData.proTip && <div className="pro-tip-container">
					<div className="pro-tip-title">Pro tip:</div>
					<div className="pro-tip">{tournamentData.proTip}</div>
				</div>}
			</>
		);
	};

	renderGemResult = () => {
		const {rewards} = this.props.resultObject;

		return(
			<>
				<h1>Tournament Over</h1>
				<h3>Gem Prize Pot Won</h3>
				<div className="reward-container">
					<div className="reward-detail">
						<div className="trophy-icon">
							<div className="trophy-icon-score">
								<div className="label">
									Prize Pot
								</div>
								<div className="gained-gems">
									{rewards.currencies.GEMS}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	renderSessionRankResult = () => {
		const { isFail } = this.props;
		const {challengeHistoryObject, challengeObject, leaderBoardObject, tournamentData} = this.props.resultObject;

		return(
			<>
				<h1>Results</h1>
				<h3 className="slide-fade-in-top">{challengeObject.title}</h3>
				<p className="slide-fade-in-top">{challengeObject.text}</p>
				{!isFail && <div className="reward-container">
					<div className="score-container">
						{isFail && (
							<div className="score-label slide-fade-in">
								Failed
							</div>
						)}
						{!isFail && (
							<div className="score-label slide-fade-in"> You Scored:
								<span>{challengeHistoryObject.score}</span>
								{challengeObject.score_label}
							</div>
						)}
						<div className="score-label slide-fade-in-delayed"> Your Best:
							<span>{leaderBoardObject.finalScore}</span>
							{challengeObject.score_label}
						</div>
					</div>
					<div className="reward-detail slide-fade-in-delayed-2">
						<LeaderboardPositionComponent rank={leaderBoardObject.rank}/>
					</div>
				</div>}
			</>
		);
	};

	renderAchievementResult = () => {
		const {leaderBoardObject} = this.props.resultObject;

		const badgeIcon = getBadgeIconSrc(leaderBoardObject.rank);

		return(
			<>
				<h1>Congratulations!</h1>
				<h3>Here, take this achievement,<br/> you earned it!</h3>
				<div className="reward-container">
					<div className="reward-detail">
						<img className="icon" src={badgeIcon} alt="badge"/>
					</div>
				</div>
				<p>This is now displaying on your Profile Page!</p>
			</>
		);
	};

	isSponsored = () => {
		return this.props.resultObject.tournamentData
			&& this.props.resultObject.tournamentData.sponsored;
	}

	renderSponsorExitButton = () => {
		const {resultObject: { tournamentData }} = this.props;
		if (!tournamentData.sponsored) return null;
		const links = tournamentData.sponsoredData && tournamentData.sponsoredData.links;
		if (!links) return null;

		return (
			<Button
				className="primary"
				onClick={() => {
					analyticsEvent({
						category: 'Sponsored Tournaments v1.5',
						action: 'submit_score_button_clicked',
						label: buildSponsoredTournamentLabel(tournamentData)
					});
					openUrlOnDefaultBrowser(links.exitLinkUrl);
					closePopup();
				}}
				childId="TournamentResultPopup-sponsorExitButton"
				layer={navLayer}
			>
				{links.exitLinkText || 'Submit Score'}
			</Button>
		);
	}

	renderResultButtons = () => {
		const {resultObject, isUserFreeTier, getTournamentsLeft, gemPlayTournamentCost} = this.props;
		const isSponsored = this.isSponsored();
		const retryButtonText = isSponsored ? 'Retry' : 'Play';
		const fullPlayButtonText = isUserFreeTier ? `${retryButtonText} (${getTournamentsLeft})` : retryButtonText;

		return(
			<>
				{this.renderSponsorExitButton()}
				{!isSponsored && <Button
					className="primary"
					onClick={this.onOkClicked}
					childId="TournamentResultPopup-okButton"
					layer={navLayer}
				>
					Continue
				</Button>}
				{resultObject.resultType===TOURNAMENT_RESULT_TYPES.SESSION_RESULT_RANK &&
					<ButtonWithGem
						className="primary two-line"
						onClick={this.onRetryClicked}
						cost={determineTournamentPlayCost(resultObject?.tournamentData, gemPlayTournamentCost)}
						layer={navLayer}
						childId="TournamentResultPopup-retryButton"
					>
						{fullPlayButtonText}
					</ButtonWithGem>
				}
			</>
		);
	};

	renderHomepageButtons = () => {
		return(
			<>
				<Button
					className="primary"
					onClick={this.onSkipClicked}
					childId="TournamentResultPopup-skipButton"
					layer={navLayer}
				>
					Skip
				</Button>
				<Button
					className="primary"
					onClick={this.onJoinClicked}
					childId="TournamentResultPopup-joinButton"
					layer={navLayer}
				>
					Join
				</Button>
			</>
		);
	};

	renderButtons = () => {
		const {resultType} =this.props.resultObject;

		if(resultType===TOURNAMENT_RESULT_TYPES.HOMEPAGE_INFO){
			return this.renderHomepageButtons();
		}else {
			return this.renderResultButtons();
		}
	};

	generateQrUrl() {
		const { resultObject } = this.props;
		const { tournamentData, challengeHistoryObject } = resultObject;
		const { sessionId, score } = challengeHistoryObject;
		const title = tournamentData?.title || '';

		const qrServerUrl = "http://api.qrserver.com/v1/create-qr-code/?size=325x325&level=M&data=";
		const data = "https://aarca.de/qrcomp?sessionId=" + sessionId + "&score=" + score + "&title=" + encodeURI(title);

		if (!deviceInfo.isXboxPlatform() && !deviceInfo.isPlayStationPlatform()) {
			return data;
		}

		return qrServerUrl + encodeURIComponent(data);
	}

	render() {
		const { resultObject } = this.props;
		const { tournamentData, userAccess } = resultObject;
		const isEvent = userAccessAllowed("event", userAccess);
		const isXbox = deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform();

		let qrUrl = null;
		if (isEvent) {
			qrUrl = this.generateQrUrl();
		}

		return (
			<div className="tournament-result-popup-component">
				<div className="content-container">
					<div className="image-container">
						{isEvent && !isXbox &&
							<QRCodeSVG
								value={qrUrl}
								size={325}
								bgColor={"#ffffff"}
								fgColor={"#000000"}
								level={"M"}
								includeMargin={true}
							/>}
						{isEvent && isXbox &&
							<img src={qrUrl} className='no-border-radius' alt='qr-code' />}
						{!isEvent &&
							<img src={tournamentData.detailImage} alt="banner-img" />
						}
					</div>
					<div className={`result-container ${resultObject.resultType}`} >
						{this.renderResult()}
					</div>
				</div>
				<div className="buttons-container">
					{this.renderButtons()}
				</div>
			</div>
		);
	}
}

export default connect()(TournamentResultPopup);
