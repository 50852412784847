export const GAME_SECTION = {
	ALL: 'all',
	FEATURED: 'featured',
};

export const GOOGLE_CLIENT_ID = '1065831886597-ucbshop527spgmm79s1qg03ufhblebds.apps.googleusercontent.com';
export const FACEBOOK_APP_ID_PROD = '759851207448214';
export const FACEBOOK_CLIENT_ID_PROD = '3c8254eb772c9081a5a47b9f455e2463';
export const FACEBOOK_APP_ID = window.config.REACT_APP_ENV === 'dev' ? '1181301875729172' : FACEBOOK_APP_ID_PROD;
export const FACEBOOK_CLIENT_ID = window.config.REACT_APP_ENV === 'dev'
	? '4c40ad601754257618546cd3914f58ab'
	: FACEBOOK_CLIENT_ID_PROD;
export const EPIC_GAMES_PRODUCT_ID = 'e237c9f119524de199e0bdc9d52d8246';
export const EPIC_GAMES_APPLICATION_ID = 'fghi4567nuHdsKa23l4F8hR3lF6ghqo0';
export const EPIC_GAMES_CLIENT_ID = 'xyza7891hJ51IOJl3VUoM5tyvTJmjPeP';

export const AUTH_TYPE = {
	XBOX: 'xbox',
	EPIC_GAMES: 'epic_games',
	FACEBOOK: 'facebook'
};

export const GAME_STATE = {
	PAUSED: "PAUSED",
	IDLE: "IDLE",
	READY: "READY",
	LOADING: "LOADING",
	PLAYING: "PLAYING",
	ERROR: "ERROR",
	FINISHED: "FINISHED"
};

export const CHALLENGE_RESULT_TYPE = {
	WIN: 'win',
	LOSE: 'loss',
	DRAW: 'draw',
	ISSUED: 'issued',
	WAITING: 'waiting',
	EXPIRED: 'expired',
	RUNNING: 'running'
};

export const TOURNAMENT_STATUS_TYPE = {
	WILL_START: "WILL_START",
	STARTED: "STARTED",
	FINISHED: "FINISHED"
};

export const NOTIFICATION_MESSAGES = {
	ChallengeIssuedMessage: [
		"Your opponent awaits!",
		"A challenger approaches. Click to engage",
		"Click to go easy on them... or not",
		"Click to step up!",
		"Beat them for some gems! Click here!"
	],
	ChallengeIssuedMessageNoClick: [
		"Your opponent awaits!",
		"Beat them for some gems!"
	],
	ChallengeStartedMessage: [
		"The match is set!",
		"It's all about the challenge",
		"Gems are on the line",
		"You took the bait"
	],
	ChallengeDrawnMessage: [
		"Click here for a rematch",
		"Click to settle this!",
		"That means nobody won. Try again?",
		"Again? Let's dance!",
		"You're not gonna settle for a draw, are you?",
		"Do you want those gems or not?"
	],
	ChallengeDrawnMessageNoClick: [
		"That means nobody won. Try again?",
		"Again? Let's dance!",
		"You're not gonna settle for a draw, are you?",
		"Do you want those gems or not?"
	],
	ChallengeWonMessage: [
		"You are victorious!",
		"You came out on top!",
		"Enjoy your spoils",
		"A winner is you!",
		"Goal in!",
		"Fatality!",
		"Yum gems!",
		"All their base are belong to you"
	],
	ChallengeLostMessage: [
		"Better luck next time. Click here to make it now!",
		"Game Over - Continue?",
		"Click here to reclaim your honour",
		"Blame Karl",
		"You're not gonna let them get away with it, are you?",
		"Click me! Try to get those gems back!",
		"Your head a splode! Get it back?"
	],
	ChallengeLostMessageNoClick: [
		"Game Over - Continue?",
		"Blame Karl",
		"You're not gonna let them get away with it, are you?",
		"Your head a splode! Get it back?"
	]
};

export const FREE_TIER_GAMES_INFORMATION_ID = "GAME_PLAY_COUNT";
export const FREE_TIER_CHALLENGES_INFORMATION_ID = "CHALLENGE_PLAY_COUNT";
export const FREE_TIER_TOURNAMENT_INFORMATION_ID = "TOURNAMENT_PLAY_COUNT";
export const FREE_TIER_REFRESH_INFORMATION_ID = "REFRESH_TIMER";

export const FEATURED_BANNER_TYPES={
	GAME:"GAME",
	CHALLENGE:"CHALLENGE",
	FREE_TIER: "FREE_TIER",
	TOURNAMENTS: "TOURNAMENTS",
    TOURNAMENTS_GROUP: "TOURNAMENTS_GROUP",
};

export const unlimitedPlaysKey = 'unlimited'

export const TAB_MENU_PARENT_ID = 'tab-menu';

export const CG_DATA_DISPLAY_TYPES = {
	CG_DATA_DISPLAY_TYPE_STRING: 0, // String
	CG_DATA_DISPLAY_TYPE_TIME_A: 1, // Ascending timer
	CG_DATA_DISPLAY_TYPE_TIME_D: 2, // Descending timer
	CG_DATA_DISPLAY_TYPE_INT: 3, // Integer
};

export const PREMIUM_FEATURES = [
	'Daily gem reward',
	'Play game challenges',
	'Compete in tournaments',
	'Earn Tournament rewards',
	'Play & Set Giant Slayers',
	'Earn Slayer & Giant rewards',
	'Play & Set Duel challenges',
	'Register high scores',
	'Save and continue your games',
	'Player over 1200 classic games',
	'New games every month',
	'Unlimited ranking up',
	'Achievements'
];

export const STORE_TEXT_FREEMIUM_V2 = [
	'Play Games',
	'Leaderboards',
	'Game Saves',
	'Monthly New Games',
	'Rank Up',
	'Achievements',
	'Challenge Medals',
	'Earn Rewards',
];

export const STORE_TEXT_FREEMIUM_V3 = [
	'Play Over 1300 Games',
	'Weekly Tournaments',
	'Challenge Medals',
	'Monthly New Games',
	'Leaderboards',
	'Rank Up',
	'Achievements',
	'Game Saves',
	'Earn Rewards'
];

export const MORE_BUTTON_ID = 'SEARCH_SHORTCUT';

export const GAMEPAD_BUTTONS = {
	'Gamepad_A': { id: '1', name: 'Gamepad_A', shortName: 'A', playStationShortName: 'Cross button' },
	'Gamepad_B': { id: '2', name: 'Gamepad_B', shortName: 'B', playStationShortName: 'Circle button' },
	'Gamepad_X': { id: '3', name: 'Gamepad_X', shortName: 'X', playStationShortName: 'Square button' },
	'Gamepad_Y': { id: '4', name: 'Gamepad_Y', shortName: 'Y', playStationShortName: 'Triangle button' },
	'Gamepad_DPadUp': { id: '5', name: 'Gamepad_DPadUp', shortName: 'Up', defaultName: 'Move Up', specialName: 'Dpad Up', playStationShortName: 'Up button' },
	'Gamepad_DPadDown': { id: '6', name: 'Gamepad_DPadDown', shortName: 'Down', defaultName: 'Move Down', specialName: 'Dpad Down', playStationShortName: 'Down button' },
	'Gamepad_DPadLeft': { id: '7', name: 'Gamepad_DPadLeft', shortName: 'Left', defaultName: 'Move Left', specialName: 'Dpad Left', playStationShortName: 'Left button' },
	'Gamepad_DPadRight': { id: '8', name: 'Gamepad_DPadRight', shortName: 'Right', defaultName: 'Move Right', specialName: 'Dpad Right', playStationShortName: 'Right button' },
	// 'Gamepad_Menu': { id: '9', name: 'Gamepad_Menu', shortName: 'Menu' }, // this button should not be configurable
	// 'Gamepad_Home': { id: '10', name: 'Gamepad_Home', shortName: 'Home' }, // this button should not be configurable
	'Gamepad_RightShoulder': { id: '11', name: 'Gamepad_RightShoulder', shortName: 'RS', playStationShortName: 'R1', xboxShortName: 'RB' },
	'Gamepad_LeftShoulder': { id: '12', name: 'Gamepad_LeftShoulder', shortName: 'LS', playStationShortName: 'L1', xboxShortName: 'LB' },
	'Gamepad_RightTrigger': { id: '13', name: 'Gamepad_RightTrigger', shortName: 'RT', playStationShortName: 'R2' },
	'Gamepad_LeftTrigger': { id: '14', name: 'Gamepad_LeftTrigger', shortName: 'LT', playStationShortName: 'L2' },
};

export const GAMEPAD_AXIS_INVERSIONS = {
	'Gamepad_LeftX': { id: '1', name: 'Gamepad_LeftX', shortName: 'Invert left X axis' },
	'Gamepad_LeftY': { id: '2', name: 'Gamepad_LeftY', shortName: 'Invert left Y axis' },
	'Gamepad_RightX': { id: '3', name: 'Gamepad_RightX', shortName: 'Invert right X axis' },
	'Gamepad_RightY': { id: '4', name: 'Gamepad_RightY', shortName: 'Invert right Y axis' }
};

export const GAME_TYPE = {
	BASIC: 'BASIC',
	SOLO_CHALLENGE: 'SOLO_CHALLENGE',
	DUEL_CHALLENGE: 'DUEL_CHALLENGE',
	GIANT_SLAYER_CHALLENGE: 'GIANT_SLAYER_CHALLENGE'
};

export const LEADERBOARD_LIST_ID = 'playerResults';

export const PLAY_STATION_AUTH_PATH = '/auth/psn';
export const PLAY_STATION_WEBRTC_REDIRECT_URI = window.location.origin + PLAY_STATION_AUTH_PATH;
export const PLAY_STATION_NATIVE_REDIRECT_URI = 'orbis://games';

export const PREMIUM_POPUP_HEADER_TEXT_1 = ['Play over 1300', 'new and classic', 'games on premium'];
export const PREMIUM_POPUP_HEADER_TEXT_2 = ['Earn gems', 'and other rewards', 'with premium'];
export const PREMIUM_POPUP_HEADER_TEXT_3 = ['Unlock', 'a world of play', 'with premium'];

export const APPLE_IOS_SKU = 'FAKE_SKU'; // TODO remove once we have worked out how this will actually work
