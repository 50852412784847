import { createSelector } from 'reselect';
import { getChallengesById } from "../../entities/entities.selectors";

const getCurrentDailyChallengeId = state => state.dailyChallenges.currentChallengeId;
export const getCurrentDailyChallengePending = state => state.dailyChallenges.isCurrentChallengePending;
export const getDailyChallenge = state => state.dailyChallenges.currentChallenge;
export const getDailyChallengePlayReward = state => state.dailyChallenges.configuration?.playRewards?.GEMS?.amount;
export const getIsChallengesByCategoryFetched = state => state.dailyChallenges.isChallengesByCategoryFetched;
export const getChallengesByCategoryLists = state => state.dailyChallenges.challengesByCategoryLists;
export const getChallengesByCategoryListsFetchedAt = state => state.dailyChallenges.challengesByCategoryListsFetchedAt;

export const getCurrentDailyChallengeSelector = createSelector(
    getChallengesById,
    getCurrentDailyChallengeId,
    (byId, challengeId) => {
        if (!byId || !challengeId) return null;

        return byId[challengeId] || null;
    }
);
