import React, { Component } from 'react';
import {Button} from './common-button.component';
import './directional-button.component.less';

class DirectionalButton extends Component {
	constructor(props) {
		super(props);
		this.state = {selectedKey: props.selected, selectedValueIndex: 0};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.selectedValueIndex > -1 && nextProps.selectedValueIndex !== this.props.selectedValueIndex) {
			this.setState({selectedValueIndex: nextProps.selectedValueIndex});
		}
	}

	UNSAFE_componentWillMount() {
		if (this.props.selectedValueIndex > -1) {
			this.setState({selectedValueIndex: this.props.selectedValueIndex});
		}
	}

	onEnter = () => {
		this.next();
	};

	next = () => {
		let newIndex = this.state.selectedValueIndex + 1;
		if ( newIndex > this.props.values.length - 1) newIndex = 0;
		this.changeSelection(newIndex);
	};


	getValue = () => {
		return this.props.values[this.state.selectedValueIndex];
	};
	
	getTranslatedValue = () => {
		return this.props.values[this.state.selectedValueIndex] || '...';
	};

	changeSelection = (newIndex) => {
		this.setState({selectedValueIndex: newIndex}, () => {
			this.props.onChange && this.props.onChange(this.getValue(), this);
		});
	};

	render() {
		return (
			<Button
				{...this.props}
				onClick={this.onEnter}
				className={`${this.props.className ? this.props.className:''} directional-button`}
			>
				{this.getTranslatedValue().replace(/sony playstation/gi, "32-Bit Console")}
			</Button>
		);
	}
}
export default DirectionalButton;