import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from '../../common/button/common-button.component';
import { maxLengthValidator, minLengthValidator, requiredValidator, spaceCharValidator } from '../../common/input/input.validator';
import { Input } from '../../common/input/input.component';
import { SetSignupFieldAction } from '../login.actions';
import { ROUTES, navigateToLocation } from '../../../app.router';
import inputManager from '../../../assets/lib/inputmanager';
import '../login-global.less';
import {getWebViewDimensions} from '../../../app.selectors';
import {checkWebViewDimensions} from '../../../assets/lib/utils';

const minValue6 = minLengthValidator(6);
const maxValue20 = maxLengthValidator(20);
const formId = "signupPasswordForm";

const validateForm = (fields) => {
	return null;
};

class SignupPasswordComponent extends Component {
	componentDidMount() {
		inputManager.setCurrentChildById("passwordInput");
	}

	saveForm = (values) => {
		const payload = {password: values.password};
		this.props.dispatch(SetSignupFieldAction(payload));
		navigateToLocation(ROUTES.SIGNUP_GAMERTAG);
	};

	back = () => {
		navigateToLocation(ROUTES.LOGIN);
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13) {
			this.props.handleSubmit(this.saveForm)();
		}
	};

	render () {
		return(
			<div className={`login-wrapper ${checkWebViewDimensions(this.props.viewDimensions)}`}>
				<section className="login-section login-section--left">
					<h1>Password</h1>
					<form>
						<Field
							onKeyDown={this.onKeyDown}
							name="password"
							type="password"
							component={Input}
							formId={formId}
							label="Password"
							validate={[ maxValue20, minValue6, requiredValidator, spaceCharValidator ]}
							childId="passwordInput"
						/>
					</form>
					<Button
						className="primary right-button"
						childId="nextButton"
						onClick={this.props.handleSubmit(this.saveForm)}
					>
						Next
					</Button>
					<Button
						className="secondary right-button"
						onClick={this.back}
						childId="backButton"
					>
						Back
					</Button>
					<p>Passwords must be at least six characters.</p>
				</section>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	const viewDimensions = getWebViewDimensions(state);

	return {
		viewDimensions
	};
};

SignupPasswordComponent = connect(mapStateToProps)(SignupPasswordComponent);

export const SignupPassword = reduxForm({
	form: formId,
	validate: validateForm
})(SignupPasswordComponent);


