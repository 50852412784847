import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMyCurrentActiveMenu } from './my-profile.selectors';
import { TabMenu } from '../common/tab-menu/tab-menu.component';
import { ROUTES, navigateToLocation } from '../../app.router';
import './my-profile.component.less';
import inputManager from '../../assets/lib/inputmanager';
import { TAB_MENU_PARENT_ID } from '../../constants';
import { Route } from 'react-router-dom';
import { MyProfileInfo } from './info/my-profile-info.component';
import { MyProfileSocial } from './social/my-profile-social.component';
import { MyProfileFavourites } from './favourites/my-profile-favourites.component';
import { MyProfileChallenges } from './challenges/my-profile-challenges.component';

class MyProfileComponent extends Component {
	constructor(props) {
		super(props);
		this.menuSelect = this.onMenuSelect.bind(this);

		this.MENU = [
			{title: 'Profile', route: ROUTES.MY_PROFILE_INFO, index:0},
			{title: 'Challenges', route: ROUTES.MY_PROFILE_CHALLENGES, index:1},
			{title: 'Social', route: ROUTES.MY_PROFILE_SOCIAL, index:2},
			{title: 'Favourites', route: ROUTES.MY_PROFILE_FAVOURITES, index:3}
		];
	}

	componentDidMount(){
		inputManager.setBackFunction(()=>{navigateToLocation(ROUTES.HOMEPAGE)});

		if (!inputManager.currentChild) {
			this.focusOnActiveTab();
		}
	}

	componentWillUnmount(){
		inputManager.setBackFunction(null);
	}

	onMenuSelect(menuItem) {
		navigateToLocation(menuItem.route);
	}
	
	focusOnActiveTab() {
		const activeRoute = this.MENU.find(el => el.route === this.props.currentRoute);
		if (!activeRoute) return;

		inputManager.setCurrentChildById(`${TAB_MENU_PARENT_ID}-${activeRoute.title}`, TAB_MENU_PARENT_ID);
	}

	renderChildren = () => {
		return (
			<>
				<Route path={ROUTES.MY_PROFILE_INFO.path} component={MyProfileInfo} />
				<Route path={ROUTES.MY_PROFILE_SOCIAL.path} component={MyProfileSocial} />
				<Route path={ROUTES.MY_PROFILE_FAVOURITES.path} component={MyProfileFavourites} />
				<Route path={ROUTES.MY_PROFILE_CHALLENGES.path} component={MyProfileChallenges} />
			</>
		);
	};
	
	render() {
		if(this.props.currentActiveMenu.title!=="Challenges") {
			return (
				<section className="my-profile">
					<TabMenu items={this.MENU} onSelect={this.menuSelect}>
						{this.renderChildren()}
					</TabMenu>
				</section>
			);
		} else {
			return (
				<section className="my-profile">
					{this.renderChildren()}
				</section>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		currentRoute: state.routing.currentRoute,
		currentActiveMenu : getMyCurrentActiveMenu(state)
	};
};

export const MyProfile = connect(mapStateToProps)(MyProfileComponent);
