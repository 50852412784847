import React from 'react';

import {requestedSessionEvent, failedRequestEvent, cancelledRequestEvent, connectToGame, endGame, startGame} from '../../assets/lib/game-wrapper';
import {
    getCurrentGameDetailsId,
    getIsRetryEnabled,
    getLastSessionObject,
    getLoadGameSlot,
    requestAlreadyFailed,
    requestAlreadyCancelled
} from './game-details.selector';
import {getCurrentRegion, getLatestRequestId, getLoggedUser, getRunningSessionId} from '../../app.selectors';
import {generateUUID, hardwareEncodingType as getHardwareEncodingType} from '../../app.helpers';
import {navigateToLocation, ROUTES} from '../../app.router';
import {
    getControlEditing,
    getShowLagGraph,
    getHeadlessNative,
    getShowControllerDebug,
    getLocalGameServer,
    getQualitySetting,
    getNoVblank,
    getUseSmoothing,
    getUseRumble,
    getTestSessionRequested
} from '../settings/settings.selector';
import { antstreamAPIService, antstreamService } from '../../app.reducer';
import { UpdateGamesData } from '../../entities/games/games.actions';
import { UpdateChallengesData } from '../../entities/challenges/challenges.actions';
import { getChallengeById } from '../../entities/entities.selectors';
import { addPopup } from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import { onTestSessionCreated } from '../settings/settings.actions';
import { handleNoAuthTokenIssue, timeDifference } from '../../assets/lib/utils';
import { getChallengeId } from '../challenge/challenge.selectors';
import Elastic, { LOG_LEVEL } from '../../assets/lib/elastic.lib';
import featureInfo, { FEATURES } from '../../assets/lib/featureInfo';
import { readRestUserData } from '../../assets/lib/local-storage';
import deviceInfo from '../../assets/lib/deviceInfo';
import { webSocketErrorHandling } from '../../assets/lib/ErrorTypes';
import { GAME_TYPE } from '../../constants';
import { fetchPostAuthenticationAction } from '../login/login.actions';

export const FETCH_GAME_CHALLENGES = 'Fetch Game Challenges Action';
export const FetchGameChallengesAction = (gameId,onChallengesFetched) => {
    return (dispatch) => {
        dispatch({ type: FETCH_GAME_CHALLENGES, payload: gameId });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FETCH_GAME_CHALLENGES);

            antstreamAPIService.challenges
                .byGameList({ gameId }, { headers: { Authorization: 'Bearer ' + authToken } })
                .then(({ data }) => {
                    if (!data) throw new Error('Something went wrong');
                    if (data.error) throw new Error(data.error);

                    dispatch(UpdateChallengesData(data.data.challenges));
                    if (onChallengesFetched) onChallengesFetched();
                })
                .catch(gameErr => {
                    dispatch(FetchGameChallengesActionFail(gameErr.message));
                });

    };
};

export const FETCH_GAME_CHALLENGES_FAIL = 'Fetch Game Challenges Fail';
const FetchGameChallengesActionFail = (payload) => ({
    type: FETCH_GAME_CHALLENGES_FAIL,
    payload
});


// XXX keep this in state?
export const SET_SELECTED_CHALLENGE_ACTION = 'Set Selected Challenge Action';
export const SetSelectedChallengeAction = (challengeId) => ({
    type: SET_SELECTED_CHALLENGE_ACTION, payload: challengeId
});

export const SET_SELECTED_FEATURED_CHALLENGE_ACTION = 'SET_SELECTED_FEATURED_CHALLENGE_ACTION';
export const SetSelectedFeaturedChallengeAction = (challengeId) => ({
    type: SET_SELECTED_FEATURED_CHALLENGE_ACTION, payload: challengeId
});

export const SET_START_CHALLENGE_ACTION = 'SET_START_CHALLENGE_ACTION';
export const SetStartChallengeAction = (challengeId) => ({
    type: SET_START_CHALLENGE_ACTION, payload: challengeId
});

function isScoreOutdated(result, getState, challengeId) {
    try {
        const state = getState();
        const currentChallengeHistory = state.challenge.challengeHistoryObject;
        const challenge = state.entities.challenges.byId[challengeId];
        const newScoreObj = result?.challengeScoresOfUser[challengeId];

        if (!newScoreObj) { // if old score not exist and new score not updated yet
            return true;
        }

        if (!currentChallengeHistory || currentChallengeHistory.challengeId !== challengeId) {
            console.error('Failed to check if score result is actual');
            return false;
        }

        const sessionScore = Number(currentChallengeHistory.score);
        const leaderboardScore = Number(newScoreObj.number);

        if (sessionScore === 0) {
            return false;
        } else if (challenge.grouped === 'max') {
            return sessionScore > leaderboardScore;
        } else if (challenge.grouped === 'min') {
            return sessionScore < leaderboardScore;
        } else {
            console.error('Failed to check if score result is actual, unknown grouped: ', challenge.grouped);
        }

        return false;
    } catch (err) {
        console.error('Failed to check if score result is actual', err);
        return false;
    }
}

/* challenges history */
export const FETCH_GAME_CHALLENGES_HISTORY = 'Fetch Game Challenges History Action';
export const FetchGamechallengeHistoryAction = (game_uuid, onFetchComplete, isNeedRetry = false, challengeId) => {
    return (dispatch, getState) => {
        dispatch({ type: FETCH_GAME_CHALLENGES_HISTORY, payload: game_uuid });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FETCH_GAME_CHALLENGES_HISTORY);

            const params = {
                headers: { Authorization: 'Bearer ' + authToken },
                body: { game_uuid: game_uuid }
            };
            antstreamAPIService.challenge.getHistoryCreate(params).then(({ data }) => {
                if (data.error) throw new Error(data.error);

                const response = data.data.result;

                if (isNeedRetry && isScoreOutdated(response, getState, challengeId)) {
                    setTimeout(() => {
                        dispatch(FetchGamechallengeHistoryAction(game_uuid, null, false));
                    }, 2000);
                    return;
                }

                dispatch(FetchGamechallengeHistoryActionSuccess({game_uuid, response}));
                if (onFetchComplete) onFetchComplete();
            }).catch(gameErr => {
                dispatch(FetchGamechallengeHistoryActionFail(gameErr.message));
            });

    };
};

export const FETCH_GAME_CHALLENGES_HISTORY_SUCCESS = 'Fetch Game Challenges History Success';
const FetchGamechallengeHistoryActionSuccess = (payload) => ({
    type: FETCH_GAME_CHALLENGES_HISTORY_SUCCESS,
    payload
});

export const FETCH_GAME_CHALLENGES_HISTORY_FAIL = 'Fetch Game Challenges History Fail';
const FetchGamechallengeHistoryActionFail = (payload) => ({
    type: FETCH_GAME_CHALLENGES_HISTORY_FAIL,
    payload
});

export const UPDATE_MY_MENU_SELECTION = 'Update My Menu Selecton';
export const UpdateMyMenuSelection = (payload) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_MY_MENU_SELECTION, payload});
    };
};


export const GAME_LEADERBOARD_UPDATED = 'GAME_LEADERBOARD_UPDATED';
export const GameLeaderboardUpdatedAction = (gameId) => {
    return (dispatch) => {
        dispatch({ type: GAME_LEADERBOARD_UPDATED, gameId});
    };
};


const updateTimeAgoLabel = (users) => {
    users.forEach(user=>{
        user.ts = timeDifference(user.ts);
    });
};

export const FETCH_GAME_LEADERBOARD = 'Fetch Game Leaderboard Action';
export const FetchGameLeaderboardAction = (listType, gameId, challengeId, tournamentId, offset, accessType, onComplete) => {
    return (dispatch) => {
        dispatch({ type: FETCH_GAME_LEADERBOARD, payload:{listType, gameId}});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FETCH_GAME_LEADERBOARD);

            const params = {
                headers: { Authorization: 'Bearer ' + authToken },
                body: {
                    operation: 'getLeaderboard',
                    listType,
                    gameId,
                    challengeId,
                    offset,
                    accessType,
                    action: tournamentId ? 'get_custom_leaderboard' :  'get_leaderboard'
                }
            };

            antstreamAPIService.leaderboard.postLeaderboard(params)
                .then(({ data }) => {
                    if (data.error) throw new Error(data.error);

                    const response = data.data.leaderboard;
                    if(response.users)updateTimeAgoLabel(response.users);
                    dispatch(FetchGameLeaderboardSuccessAction({gameId, response, offset, listType: data.data.listType}));
                    if (onComplete) onComplete(offset);
                })
                .catch(gameErr => {
                    dispatch(FetchGameLeaderboardErrorAction());
                    addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
                    console.error('Failed to get leaderboard details: ' + gameErr.message);
                });

    };
};

export const FETCH_GAME_LEADERBOARD_SUCCESS = 'Fetch Game Leaderboard Success';
const FetchGameLeaderboardSuccessAction = (payload) => ({
    type: FETCH_GAME_LEADERBOARD_SUCCESS,
    payload
});

export const FETCH_GAME_LEADERBOARD_ERROR = 'Fetch Game Leaderboard Error';
const FetchGameLeaderboardErrorAction = () => ({
    type: FETCH_GAME_LEADERBOARD_ERROR
});

export const UPDATE_LEADERBOARD_LIST_TYPE = 'Switch Leaderboard List Type';
export const UpdateLeaderboardListTypeAction = (payload) => ({
    type: UPDATE_LEADERBOARD_LIST_TYPE,
    payload
});

// similar
export const FETCH_SIMILAR_GAMES = 'FETCH_SIMILAR_GAMES';
export const FetchSimilarGamesAction = (gameId, onComplete) => {
    return (dispatch) => {
        dispatch({ type: FETCH_SIMILAR_GAMES, payload: gameId });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FETCH_SIMILAR_GAMES);

            antstreamAPIService.games
                .similarList({ gameId }, { headers: { Authorization: 'Bearer ' + authToken } })
                .then(({ data }) => {
                    if (data.error) throw new Error(data.error);

                    dispatch(FetchSimilarGamesSuccessAction(data.data.games));
                    onComplete();
                })
                .catch(gameErr => {
                    addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
                    console.error('Failed to get similar games list: ' + gameErr.message);
                });

    };
};

export const FETCH_SIMILAR_GAMES_SUCCESS = 'FETCH_SIMILAR_GAMES_SUCCESS';
const FetchSimilarGamesSuccessAction = (payload) => {
    return (dispatch) => {
        dispatch(UpdateGamesData(payload));
        dispatch({
            type: FETCH_SIMILAR_GAMES_SUCCESS,
            payload
        });
    };
};

export const localGameServerRequest =  (method, url, params, credentials) => {
    return new Promise( (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, url);
        if (credentials) {
            xhr.setRequestHeader('Authorization', 'Basic ' + credentials);
        }
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.send(JSON.stringify(params));

        // resolve('serverIP:10.0.1.132, port:2348');

        // if( 0 > 1 ) {
        //     reject( 'haha' );
        // }
    });
};


export const FAILED_REQUEST_ACTION = 'Failed Request Action';
export const FailedRequestAction = (requestId) => {
    return (dispatch, getState) => {
        Elastic.logDirect(LOG_LEVEL.INFO, "SESSION_FAILED", true);
        if (!requestAlreadyFailed(getState(), requestId) && !requestAlreadyCancelled(getState(), requestId)) {
            dispatch({ type: FAILED_REQUEST_ACTION, payload: {requestId} });
            failedRequestEvent(requestId);
        }
    }
}

export const CANCELLED_REQUEST_ACTION = 'Cancelled Request Action';
export const CancelledRequestAction = (requestId) => {
    return (dispatch, getState) => {
        Elastic.logDirect(LOG_LEVEL.INFO, "SESSION_CANCELLED", true);
        if (!requestAlreadyFailed(getState(), requestId) && !requestAlreadyCancelled(getState(), requestId)) {
            dispatch({ type: CANCELLED_REQUEST_ACTION, payload: {requestId} });
            cancelledRequestEvent(requestId);
        }
    }
}

// run game
function dispatchRunGameToServer(dispatch, getState, payload, requestId, localGameServerObject = null) {
    const { gameId, challengeStyle, challengeId, tournamentId, slot, challengeInstanceId, giantSlayerChallengeId} = payload;
    const region = payload.region || getCurrentRegion(getState());
    const hardwareEncodingType = getHardwareEncodingType(gameId, getState());

    dispatch({ type: RUN_GAME_ACTION, payload});
    dispatch(SetGameLoading());

    const { authToken, userId } = readRestUserData() || {};
    if (!authToken) handleNoAuthTokenIssue(RUN_GAME_ACTION);

        const headers = { Authorization: 'Bearer ' + authToken };
        const body = {
            action: 'run',
            requestId: requestId,
            game_uuid: gameId,
            user_uuid: userId,
            challenge_id: challengeId,
            challenge_instance_id: challengeInstanceId,
            local_session_object: localGameServerObject,
            challengeStyle: challengeStyle || undefined,
            region: region,
            slot: slot,
            platform: deviceInfo.platformType,
            tournamentId: tournamentId,
            colorProfile: hardwareEncodingType,
            giantSlayerChallengeId: giantSlayerChallengeId,
            isSoloChallengeRetry: payload.isSoloChallengeRetry,
            dailyChallengeEventId: payload.dailyChallengeEventId
        };

        antstreamAPIService.game.executeCreate({
            headers: headers,
            body: body
        }).then(({ data }) => {
            if (data?.error?.message === 'SUBSCRIPTION_EXPIRED') {
                dispatch(FailedRequestAction(requestId));
                dispatch(fetchPostAuthenticationAction());
                navigateToLocation(ROUTES.HOMEPAGE);
                return;
            }

            if (!data) throw new Error('Something went wrong creating game session.');
            if (data.error) {
                dispatch(FailedRequestAction(requestId));
                webSocketErrorHandling(data.error, dispatch, getState);
                return;
            }

            const {runGameResult, logRequestId} = data.data;
            if (localGameServerObject) {
                runGameResult.gameServer = {
                    ...runGameResult.gameServer,
                    ...localGameServerObject,
                }
            }
            const {serverIP, serverPort, sessionid} = runGameResult.gameServer;

            if (serverIP && serverPort && sessionid) {
                dispatch(RunGameActionSuccess({runGameResult, challengeStyle, logRequestId, challengeId, dailyChallengeEventId: payload.dailyChallengeEventId}));
            } else {
                dispatch(FailedRequestAction(logRequestId));
                dispatch(SetGameError());
            }
        })
        .catch(execGame => {
            dispatch(FailedRequestAction(requestId));
            dispatch(SetGameError());
        });

}


export const RUN_GAME_ACTION = 'Run Game Action';
export const CLEAR_LAST_SESSION_OBJECT = 'CLEAR_LAST_SESSION_OBJECT';
export const GENERATE_REQUEST_ID = "GENERATE_REQUEST_ID";
export const RunGameAction = (payload) => {
    return (dispatch, getState) => {
        const { gameId, challengeId, challengeInstanceId } = payload;

        const requestId = antstreamService.generateRequestId(gameId, challengeId, null, challengeInstanceId);
        dispatch({ type: GENERATE_REQUEST_ID, payload: requestId });
        requestedSessionEvent(requestId);

        // clear previous latestObject if it's tournament because in some way it can lead to endless /leaderboard/rank requests
        const lso = getLastSessionObject(getState());
        if (lso?.tournamentId) {
            dispatch({ type: CLEAR_LAST_SESSION_OBJECT, payload: requestId });
        }

        // Switch between ASL and local
        const localGameServer = getLocalGameServer(getState());
        if (!localGameServer || localGameServer==='ASL') {
            dispatchRunGameToServer(dispatch, getState, payload, requestId);
        } else {
            const user = getLoggedUser(getState());
            const serverPackageId = (challengeId ? getChallengeById(getState(),challengeId).serverPackageId : 'Default');
            getLocalGameServerObject(localGameServer, user._id, gameId, payload.slot, payload.region, serverPackageId, challengeInstanceId)
                .then((localGameServerObject)=>{
                    dispatchRunGameToServer(dispatch, getState, payload, requestId, localGameServerObject);
                })
                .catch((error) => {
                    dispatch(FailedRequestAction(requestId));
                    console.log("localGameServer Error =>"+error.toString());
                });
        }
    };
};

export const getLocalGameServerObject = (localGameServer, userId, gameId, slot, slotRegion, serverPackageId, challengeInstanceId) => {
    return new Promise( (resolve, reject) => {
        const generatedSessionId = generateUUID();

        if (!localGameServer.includes('/api/v1alpha1/gamesessions')) {
            // Regex replaces '.' with '-' e.g. 127.0.0.1 => 127-0-0-1
            // localGameServer is in format user:password:[web|native]@server
            const prefix = localGameServer.substring(0, localGameServer.lastIndexOf('@')).split(':');
            // turn into base64
            const credentials = btoa(prefix[0]+':'+prefix[1]);
            const platform = prefix[2];
            const server = localGameServer.substring(localGameServer.lastIndexOf('@')+1);
            const localGameServerDash = server.replace(/\./g, '-');
            const params={
                gameid:gameId,
                userid:userId,
                sessid:generatedSessionId,
                challengeid:serverPackageId,
                challengeInstanceId:challengeInstanceId,
                saveStateSlot:null,
                platform:platform,
            };

            if (typeof(slot) != 'undefined') {
                params.saveStateSlot = slot.toString();
            }
            localGameServerRequest('POST', `https://${localGameServerDash}.xip.antstream.com:7899/cg/jrungamep.php`,params, credentials).then( (e) => {
                const localSessionObject = JSON.parse(e);
                localSessionObject.sessionid  = generatedSessionId;
                localSessionObject.serverIP = server;
                resolve(localSessionObject);
            }, (e) => {
                reject(e);
            });
            return;
        }

        // extact params from localGameServer as an URL
        const localGameServerURL = new URL(localGameServer);
        const params = new URLSearchParams(localGameServerURL.search);
        let externalAccess = false;
        if (params.get('externalIP') === "true") {
            externalAccess = true;
        }
        params.delete('externalIP');
        localGameServerURL.search = params.toString();
        localGameServerURL.pathname = '/api/v1alpha1/gamesessions';

        const sessionEndpoint = localGameServerURL.toString();
        let platform;

        if (deviceInfo.isRunningOnNative()) {
            platform = 'native';
        } else {
            platform = 'web';
        }
        const gamesession = {
            apiVersion: 'gameserver.platform.antstream.com/v1alpha1',
            kind: 'GameSession',
            metadata: {
                name: generatedSessionId,
                namespace: 'default',
            },
            spec: {
                gameID: gameId,
                userID: userId,
                cpu: "1000m",
                platform: platform,
                clientPlatform: deviceInfo.platformType,
                challengeID: serverPackageId,
            }
        }
        if (slot) {
            gamesession.spec.saveStateSlot = slot.toString();
            gamesession.spec.saveStateSlotRegion = slotRegion;
        }
        if (!externalAccess) {
            gamesession.spec.privateAccess = true;
        }

        localGameServerRequest('POST', sessionEndpoint, gamesession, null)
            .then((response) => {
                const gamesession = JSON.parse(response);
                const sessionObj = {
                    serverPort: gamesession.status.port,
                    sessionid: gamesession.metadata.name,
                };
                if (externalAccess) {
                    sessionObj.serverIP = gamesession.status.externalIP;
                } else {
                    sessionObj.serverIP = gamesession.status.internalIP;
                }
                console.log(sessionObj);
                resolve(sessionObj);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const UPDATE_FREE_TIER_INFORMATION = 'UPDATE_FREE_TIER_INFORMATION';

export const RUN_GAME_SUCCESS = 'Get Game Address Success';
export const RunGameActionSuccess = (payload) => {
    return (dispatch, getState) => {
        const { serverIP, serverPort, webrtcPort, sessionid, region, sessionObject, role, giantScore } = payload.runGameResult.gameServer;
        dispatch({ type: RUN_GAME_SUCCESS, sessionId: sessionid, serverIP, serverPort, webrtcPort, region, sessionObject, gameConfig: payload.runGameResult.gameConfig, challengeStyle: payload.challengeStyle});
        const { challengeStyle, challengeId} = payload;
        const controlEditing = getControlEditing(getState());
        const showLagGraph   = getShowLagGraph(getState());
        const headlessNative = getHeadlessNative(getState());
        const showControllerDebug = getShowControllerDebug(getState());
        const qualitySetting = getQualitySetting(getState());
        const {input_map, game_profile} = payload.runGameResult.gameConfig;
        const gameId = getCurrentGameDetailsId(getState());
        const user = getLoggedUser(getState());
        const noVblank = getNoVblank(getState());
        const useSmoothing = getUseSmoothing(getState());
        const useRumble = getUseRumble(getState());
        // console.log('RunGameActionSuccess', ip, port, sessionId);
        const logRequestId = getLatestRequestId(getState());
        const giantScoreLabel = payload.dailyChallengeEventId ? 'Score' : (challengeId ? getChallengeById(getState(),challengeId).score_label : 'score');


        const currentRoute = getState().routing.currentRoute;

        if (currentRoute.path === ROUTES.HOW_TO_PLAY.path
            || currentRoute.path === ROUTES.TEST_CONNECTION.path
        ) {
            if (getTestSessionRequested(getState())) {
                dispatch(onTestSessionCreated());
            }

            connectToGame({
                ip:serverIP,
                port:serverPort,
                controlMap: input_map,
                qualitySetting,
                controlEditing,
                showLagGraph,
                headlessNative,
                showControllerDebug,
                noVblank,
                useSmoothing,
                useRumble,
                gameProfile: game_profile,
                sessionId:sessionid,
                challengeStyle,
                gameId,
                userId:user._id,
                requestId:logRequestId,
                encodingType: getHardwareEncodingType(gameId, getState()),
                role:       role,
                giantScore: giantScore,
                giantScoreLabel: giantScoreLabel,
            });
        } else {
            Elastic.logDirect(LOG_LEVEL.INFO, "session success received when we're on the wrong path (may have cancelled). Request: " + JSON.stringify(logRequestId) );
            dispatch(CancelledRequestAction(logRequestId));  // This is actually a success, because the session started.
        }
    };
};

export const PAUSE_GAME_ACTION = 'Pause Game Action';
export const PauseGameAction = () => {
    return (dispatch) => {
        dispatch({ type: PAUSE_GAME_ACTION });
    }
}

export const UNPAUSE_GAME_ACTION = 'Unpause Game Action';
export const UnpauseGameAction = () => {
    return (dispatch, getState) => {
        dispatch({ type: UNPAUSE_GAME_ACTION });
        startGame();
        const sessionId = getRunningSessionId(getState());

        if (!sessionId) {
            navigateToLocation(ROUTES.HOMEPAGE);
            return;
        }

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(UNPAUSE_GAME_ACTION);

        antstreamAPIService.event.playCreate({
            headers: { Authorization: 'Bearer ' + authToken },
            body: { sessionId }
        })
            .then(({ data }) => {
                if (data.error) throw new Error(data.error);
                if (!data || data.result !== 'OK') throw new Error('Something went wrong');
            })
            .catch(gameErr => {
                addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
                console.error('Failed to start playing: ' + gameErr.message);
            });
    };
};

// platforms
export const FETCH_GAME_PLATFORMS = 'Fetch Game Platforms Action';
export const FetchGamePlatformsAction = (gameId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_GAME_PLATFORMS, payload: gameId });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FETCH_GAME_PLATFORMS);

            antstreamAPIService.games.matchingPlatformList(
                { gameId },
                { headers: { Authorization: 'Bearer ' + authToken } }
            ).then(({ data }) => {
                if (data.error) throw new Error(data.error);
                dispatch(FetchGamePlatformsActionSuccess(data.data));
            }).catch(gameErr => {
                addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
                console.error('Failed to get game platforms: ' +  gameErr.message);
            });

    };
};

export const FETCH_GAME_PLATFORMS_SUCCESS = 'Fetch Game Platforms Success';
const FetchGamePlatformsActionSuccess = (payload) => {
    return (dispatch) => {
        dispatch(UpdateGamesData(payload));
        dispatch({
            type: FETCH_GAME_PLATFORMS_SUCCESS,
            payload
        });
    };
};

export const CLEAR_GAME_LEADERBOARD = 'Clear Game Leaderboard';
export const ClearGameLeaderboardAction = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_GAME_LEADERBOARD
        });
    };
};

// follow/favourite
export const FOLLOW_GAME_ACTION = 'Follow Game Action';
export const FollowGameAction = (gameId) => {
    return (dispatch) => {
        dispatch({ type: FOLLOW_GAME_ACTION, payload: gameId });

        const { authToken, userId } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FOLLOW_GAME_ACTION);

            antstreamAPIService.games
                .followCreate({
                    headers: { Authorization: 'Bearer ' + authToken },
                    body: {
                        user_uuid: userId,
                        game_uuid: gameId
                    }
                })
                .then(({ data }) => {
                    if (data.error) throw new Error(data.error);
                    if (!data || data.result !== 'OK') throw new Error('Something went wrong');

                    dispatch(FollowGameActionSuccess(gameId));
                })
                .catch(gameErr => {
                    dispatch(FollowGameActionError());
                });

    };
};

export const FOLLOW_GAME_ACTION_SUCCESS = 'Follow Game Success';
const FollowGameActionSuccess = (payload) => ({
    type: FOLLOW_GAME_ACTION_SUCCESS,
    payload
});
export const FOLLOW_GAME_ACTION_ERROR = 'Follow Game Error';
export const FollowGameActionError = () => ({ type: FOLLOW_GAME_ACTION_ERROR });

export const UNFOLLOW_GAME_ACTION = 'Unfollow Game Action';
export const UnfollowGameAction = (gameId) => {
    return (dispatch) => {
        dispatch({ type: UNFOLLOW_GAME_ACTION, payload: gameId });

        const { authToken, userId } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(UNFOLLOW_GAME_ACTION);

            antstreamAPIService.games
                .unfollowCreate({
                    headers: { Authorization: 'Bearer ' + authToken },
                    body: {
                        user_uuid: userId,
                        game_uuid: gameId
                    }
                })
                .then(({ data }) => {
                    if (data.error) throw new Error(data.error);
                    if (!data || data.result !== 'OK') throw new Error('Something went wrong');

                    dispatch(UnfollowGameActionSuccess(gameId));
                })
                .catch(gameErr => {
                    dispatch(UnfollowGameActionError());
                });

    };
};

export const UNFOLLOW_GAME_ACTION_SUCCESS = 'Unfollow Game Success';
const UnfollowGameActionSuccess = (payload) => ({
    type: UNFOLLOW_GAME_ACTION_SUCCESS,
    payload
});
export const UNFOLLOW_GAME_ACTION_ERROR = 'Unfollow Game Error';
export const UnfollowGameActionError = () => ({ type: UNFOLLOW_GAME_ACTION_ERROR });

export const SET_GAME_LOADING = 'Set Game Loading Action';
export const SetGameLoading = () => ({type: SET_GAME_LOADING});


export const SET_GAME_READY = 'Set Game Ready Action';
export const SetGameReady = () => {
    Elastic.logDirect(LOG_LEVEL.INFO, "SESSION_SUCCEEDED", true);
    return {type: SET_GAME_READY};
};

export const SET_GAME_ERROR = 'Set Game Error Action';
export const SetGameError = () => {
    Elastic.logDirect(LOG_LEVEL.INFO, "SESSION_CRASHED", true);
    return {type: SET_GAME_ERROR};
};

export const SET_GAME_QUIT = 'Set Game Quit Action';
export const SetGameQuit = (sessionId, messageId) => {
    return (dispatch) => {
        dispatch({ type: SET_GAME_QUIT });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(SET_GAME_QUIT);

            const params = {
                headers: { Authorization: 'Bearer ' + authToken },
                body: { session_uuid: sessionId, messageId }
            };
            antstreamAPIService.game.abortCreate(params)
                .then(({ data }) => {
                    if (!data) throw new Error('Something went wrong');
                    if (data.error) throw new Error(data.error);
                })
                .catch(catchErr => {
                    addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
                    console.error('Failed to abort game session: ' + catchErr.message);
                });

        setTimeout(()=>{endGame(sessionId)},100);//this delay is for waiting react router to change location.href.
    };
};

export const SET_GAME_FINISHED = 'Set Game Finished Action';
export const SetGameFinished = () => {
    return (dispatch, getState) => {
        dispatch({ type: SET_GAME_FINISHED });
        Elastic.logDirect(LOG_LEVEL.INFO, "SESSION_COMPLETED", true);

        if(window.config.REACT_APP_ENV !== 'live' || featureInfo.isSupported(FEATURES.WEBRTC)) {
            const state = getState();
            const isRetryEnabled = getIsRetryEnabled(state);
            const loadGameSlot = getLoadGameSlot(state);

            if (isRetryEnabled) {
                dispatch(SetGameRetry());
            } else if (loadGameSlot.slot !== null && loadGameSlot.region !== null) {
                dispatch(LoadGameSlotAction(loadGameSlot));
            }
        }
    };
};

export const SET_CHALLENGE_OVER = 'Set Challenge Over Action';
export const SetChallengeOver = (over) => {
    return (dispatch) => {
        dispatch({ type: SET_CHALLENGE_OVER, payload: over });
    }
};

export const SET_SCORE_SETUP = 'Set Score Setup Action';
export const SetScoreSetup = (data) => {
    return (dispatch) => {
        dispatch({ type: SET_SCORE_SETUP, payload: data });
    }
};

export const SET_SCORE_VALUES = 'Set Score Values Action';
export const SetScoreValues = (data) => {
    return (dispatch) => {
        dispatch({ type: SET_SCORE_VALUES, payload: data });
    }
};

export const SET_GAME_RETRY = 'SET_GAME_RETRY';
export const SetGameRetry = () => {
    return (dispatch, getState) => {
        dispatch({ type: SET_GAME_RETRY });

        const state = getState();
        const isRetryEnabled = getIsRetryEnabled(state);

        if (isRetryEnabled) {
            const gameId = getCurrentGameDetailsId(state);
            const challengeId = getChallengeId(state);
            const lastSessionObject = getLastSessionObject(state);

            let routeParams = {};
            if (challengeId) routeParams.challengeId = challengeId;

            let runGamePayload = {
                gameId
            };

            if (challengeId) {
                runGamePayload.challengeId = challengeId;

                if (lastSessionObject && lastSessionObject.challengeStyle) {
                    runGamePayload.challengeStyle = lastSessionObject.challengeStyle;
                }
            }

            if (lastSessionObject && lastSessionObject.tournamentId) {
                runGamePayload.tournamentId = lastSessionObject.tournamentId;
            }

            dispatch(SetRetryEnabled(false));
            dispatch(RunGameAction(runGamePayload));
            navigateToLocation(ROUTES.HOW_TO_PLAY, {id: gameId, challengeId});
        }
    };
};

export const SHOW_HOW_TO_PLAY = 'SHOW_HOW_TO_PLAY';
export const ShowHowToPlay = () => ({ type: SHOW_HOW_TO_PLAY });

export const HIDE_HOW_TO_PLAY = 'HIDE_HOW_TO_PLAY';
export const HideHowToPlay = () => ({ type: HIDE_HOW_TO_PLAY });

export const ADD_UPVOTE_GAME_ACTION = 'Add Upvote Game Action';
export const AddUpvoteGameAction = (gameId) => {
    return (dispatch) => {
        dispatch({ type: ADD_UPVOTE_GAME_ACTION, payload: gameId });
        antstreamService
            .addUpvote(gameId)
            .subscribe((res) => {
                dispatch(AddUpvoteGameActionSuccess(res.upvotes));
            });
    };
};

export const ADD_UPVOTE_GAME_ACTION_SUCCESS = 'Add Upvote Game Success';
const AddUpvoteGameActionSuccess = (payload) => ({
    type: ADD_UPVOTE_GAME_ACTION_SUCCESS,
    payload
});

export const REMOVE_UPVOTE_GAME_ACTION = 'Remove Upvote Game Action';
export const RemoveUpvoteGameAction = (gameId) => {
    return (dispatch) => {
        dispatch({ type: REMOVE_UPVOTE_GAME_ACTION, payload: gameId });
        antstreamService
            .removeUpvote(gameId)
            .subscribe(() => {
                dispatch(RemoveUpvoteGameActionSuccess(gameId));
            });
    };
};

export const REMOVE_UPVOTE_GAME_ACTION_SUCCESS = 'Remove Upvote Game Success';
const RemoveUpvoteGameActionSuccess = (payload) => ({
    type: REMOVE_UPVOTE_GAME_ACTION_SUCCESS,
    payload
});

export const GET_UPVOTES_GAME_ACTION = 'Get Upvote Game Action';
export const GetUpvoteGameAction = (userId, gameId) => {
    return (dispatch) => {
        dispatch({ type: GET_UPVOTES_GAME_ACTION, payload: gameId });
        antstreamService
            .getUpvote(gameId)
            .subscribe((res) => {
                const upvotes = res.Upvotes ? res.Upvotes : [];
                dispatch(GetUpvoteGameActionSuccess(userId, upvotes));
            });
    };
};

export const GET_UPVOTES_GAME_ACTION_SUCCESS = 'Get Upvote Game Success';
const GetUpvoteGameActionSuccess = (userId, upvotes) => ({
    type: GET_UPVOTES_GAME_ACTION_SUCCESS,
    userId,
    upvotes
});

export const SET_SELECTED_GAME_INFO_ACTION = 'Set Selected Homepage Game Action';
export const SetSelectedGameInfoAction = (gameId) => ({
    type: SET_SELECTED_GAME_INFO_ACTION, payload: {gameId}
});

export const SET_SELECTED_GIANT_SLAYER_INFO_ACTION = 'Set Selected Homepage Giant Slayer Action';
export const SetSelectedGiantSlayerInfoAction = (giantSlayerChallengeId) => ({
    type: SET_SELECTED_GIANT_SLAYER_INFO_ACTION, payload: {giantSlayerChallengeId}
});

export const SAVE_STATE_ACTION = 'SAVE_STATE_ACTION';
export const SaveStateAction = (payload) => {
    return {type: SAVE_STATE_ACTION, payload};
};

export const SAVE_REQUESTED_ACTION = 'SAVE_REQUESTED_ACTION';
export const SaveRequestedAction = () => {
    return {type: SAVE_REQUESTED_ACTION};
};


export const SAVE_TIMEOUT_ACTION = 'SAVE_TIMEOUT_ACTION';
export const SaveTimeoutAction = () => {
    return {type: SAVE_TIMEOUT_ACTION};
};


export const NATIVE_SAVE_STATE_RESULT = 'NATIVE_SAVE_STATE_RESULT';
export const onNativeSaveStateResult = (payload) => {
    return (dispatch) => {
        dispatch({type: NATIVE_SAVE_STATE_RESULT, payload});
        if(!payload || payload.success==="false") {
            addPopup(<GenericPopup
                okButtonLabel="Got it!"
                title="Oops! Error saving"
                message={payload.message}
            />);
        }
    }
};


export const FETCH_CURRENT_POSITION = 'FETCH_CURRENT_POSITION';
export const FetchCurrentPosition = (gameId, challengeId, tournamentId, onComplete) => {
    return (dispatch) => {
        // dispatch({ type: FETCH_CURRENT_POSITION, payload:{gameId, challengeId, tournamentId}});

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FETCH_CURRENT_POSITION);

            const params = {
                headers: { Authorization: 'Bearer ' + authToken },
                body: {
                    operation: 'getLeaderboardRank',
                    gameId,
                    challengeId,
                    tournamentId,
                    action: 'get_current_position'
                }
            };

            antstreamAPIService.leaderboard.rankCreate(params).then(({ data }) => {
                if (data.error) throw new Error(data.error);
                if (!data.data) {
                    throw new Error('Failed to fetch current position. Unknown rank');
                }
                if (onComplete) onComplete(data.data.currentPosition);
            }).catch(gameErr => {
                addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" />);
                console.error(gameErr.message);
            });

    };
};


export const FETCH_HOW_TO_PLAY = 'FETCH_HOW_TO_PLAY_ACTION';
// challengeId or gameId
export const FetchHowToPlay = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_HOW_TO_PLAY, payload:{id}});
        antstreamService
            .getGameChallengeHowToPlay(id)
            .subscribe((response) => {
                dispatch(FetchHowToPlaySuccessAction(response));
            },
            (message) => {
                    dispatch(FetchHowToPlayFail(message));
                }
            )
    };
};

export const FETCH_HOW_TO_PLAY_SUCCESS = 'FETCH_HOW_TO_PLAY_ACTION_SUCCESS';
const FetchHowToPlaySuccessAction = (payload) => ({
    type: FETCH_HOW_TO_PLAY_SUCCESS,
    payload
});

export const FETCH_HOW_TO_PLAY_ACTION_FAIL = 'FETCH_HOW_TO_PLAY_ACTION_FAIL';
const FetchHowToPlayFail = (payload) => ({
    type: FETCH_HOW_TO_PLAY_ACTION_FAIL,
    payload
});

export const SET_RETRY_ENABLED = 'SET_RETRY_ENABLED';
export const SetRetryEnabled = (enabled) => {
    return (dispatch) => {
        dispatch({ type: SET_RETRY_ENABLED, enabled });
    };
};

export const SET_LOAD_GAME_SLOT = 'SET_LOAD_GAME_SLOT';
export const SetLoadGameSlotAction = (slot, region) => {
    return (dispatch) => {
        dispatch({ type: SET_LOAD_GAME_SLOT, slot, region});
    };
};


export const LOAD_GAME_SLOT_ACTION = 'LOAD_GAME_SLOT_ACTION';
export const LoadGameSlotAction = (loadGameSlot) => {
    return (dispatch, getState) => {
        dispatch({ type: LOAD_GAME_SLOT_ACTION });
        const state = getState();
        const gameId = getCurrentGameDetailsId(state);
        dispatch(SetLoadGameSlotAction(null, null));
        dispatch(RunGameAction({
            gameId,
            slot:loadGameSlot.slot,
            region:loadGameSlot.region,
        }));
        navigateToLocation(ROUTES.HOW_TO_PLAY, { id: gameId, gameType: GAME_TYPE.BASIC });
    };
};

export const TOGGLE_OSK_VISIBILITY = 'TOGGLE_OSK_VISIBILITY';
export const ToggleOskVisibility = (payload) => {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_OSK_VISIBILITY,
            payload
        });
    };
};

export const SET_CONNECTION_STRENGTH_INDICATOR_ACTION = 'SET_CONNECTION_STRENGTH_INDICATOR_ACTION';
export const setConnectionStrengthIndicatorAction = strength => {
    return (dispatch) => {
        dispatch({ type: SET_CONNECTION_STRENGTH_INDICATOR_ACTION, payload: strength });
    };
};

export const CLEAR_GAME_DETAILS_STATE = 'CLEAR_GAME_DETAILS_STATE';
export const clearGameDetailsState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_GAME_DETAILS_STATE });
    };
};
