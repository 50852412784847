// Assets
import { EPIC_GAMES_CLIENT_ID, AUTH_TYPE } from '../../constants';
import * as GameWrapper from './game-wrapper';

/**
 * Sends the user to an new tab (or default browser on native) where they are logged into Epic Games.
 * @param {object} [redirectRoute={}] - the route to redirect
 * @param {boolean} [hasNativeBrowser=true] - if the device supports native browsers
 */
export const login = (redirectRoute = {}, hasNativeBrowser = true) => {
	const redirect = window.location.origin + (redirectRoute.path || '') + '?auth_type=' + AUTH_TYPE.EPIC_GAMES;
    const url = `https://www.epicgames.com/id/authorize?client_id=${EPIC_GAMES_CLIENT_ID}&response_type=code&redirect_uri=${redirect}`;
	//const url = `https://www.epicgames.com/id/authorize?client_id=${EPIC_GAMES_CLIENT_ID}&response_type=code&redirect_uri=https://as-dev-testbed-reactui-webapp.azurewebsites.net/auth/token?auth_type=${AUTH_TYPE.EPIC_GAMES}`;

    if (hasNativeBrowser) {
        GameWrapper.openUrlOnDefaultBrowser(url);
    } else {
        GameWrapper.openUrl(url);
    }
};

export const SKU_DATA = {
	'766090fe8343432b8cc5c92d2c61605f': { // 60 Gems
		storeSlug: '60-gems',
		catalogueId: '9f654f88e61344b590e38575ab926fce',
        className: 'gems-illus-1',
		gems: 60,
		contentText: 'Congratulations! 60 gems have been added to your account.'
    },
    '41c7198abaf84079950b561641a49c2e': { // 330 Gems
		storeSlug: '330-gems',
		catalgueId: '6a4d2ba1665e4eb08fc4b2b7e14dc320',
        className: 'gems-illus-2',
		gems: 330,
		contentText: 'Congratulations! 330 gems have been added to your account.'
    },
    '5c319e7b8cbf4ddb89a57e2bb5e07df5': { // 1090 Gems
		storeSlug: '1090-gems',
		catalogueId: 'e8d85912daba4a24a112ee601d341b1c',
        className: 'gems-illus-3',
		gems: 1090,
		contentText: 'Congratulations! 1090 gems have been added to your account.'
    },
    '33c5f31d8def4083a1e4a80dadf04740': { // 2360 Gems
		storeSlug: '2360-gems',
		catalogueId: 'f638032388e64feaa45526ed8274aa7d',
        className: 'gems-illus-4',
		gems: 2360,
		contentText: 'Congratulations! 2360 gems have been added to your account.',
        outlineText: 'Most popular'
    },
    '94355ce3f16b46878175978b12e24985': { // 4550 Gems
		storeSlug: '4550-gems',
		catalogueId: '8b24e256507d4a3a8bd085f515426b09',
        className: 'gems-illus-5',
		gems: 4550,
		contentText: 'Congratulations! 4550 gems have been added to your account.'
    },
    '43ee2d1420ce489c854f19f49323d47c': { // 12100 Gems
		storeSlug: '12100-gems',
		catalogueId: '32410f6e0bfc4e28a3cb0f119b5cef3a',
        className: 'gems-illus-6',
		gems: 12100,
		contentText: 'Congratulations! 12100 gems have been added to your account.',
        outlineText: 'Best value'
    },
	'8c34327be61b4c3783566492e13e6d58': { // Subscription
		storeSlug: 'premium-pass',
		catalogueId: '01c1987f76fa4e469befb7b868f78f85',
		className: 'subscription-success',
		gems: 6000,
		gemsDaily: 100,
		contentText: 'You are subscribed for 1 year!'
	},
	'd3c8cb59f587465294721ffb5d0a904c': { // Welcome Pack
		storeSlug: 'welcome-pack',
		catalogueId: '683fad1d55f842d7896e117c5e5de95d',
		className: 'gems-illus-3',
		gems: 1090,
		contentText: 'Congratulations! 1090 gems have been added to your account.'
	}
}

export const REDEEM_INTERVAL_MILISECONDS = 10000;
export const LAUNCHER_SHOP_URL = 'com.epicgames.launcher://store/product/antstream-arcade/';
export const LAUNCHER_ALL_DLC_URL = 'com.epicgames.launcher://store/all-dlc/antstream-arcade';

/**
 * Returns the Epic Launcher product URL for a specific SKU or the default URL for all items.
 * 
 * @param {string} sku - the Epic Store sku
 * @returns {string} - shop url
 */
export const getLauncherProductUrl = sku => {
	if (!sku || typeof sku !== 'string' || !SKU_DATA[sku] || !SKU_DATA[sku].storeSlug) {
		return LAUNCHER_ALL_DLC_URL;
	}

	return LAUNCHER_SHOP_URL + SKU_DATA[sku].storeSlug;
}

/**
 * Returns the item Sku of a catalogue id.
 * 
 * @param {string} catalogueId - the Epic Store catalogue id
 * @returns {string} - the sku 
 */
export const getSkuByCatalogueId = catalogueId => {
	if (!catalogueId || typeof catalogueId !== 'string') {
		return '';
	}

	for (const sku in SKU_DATA) {
		if (SKU_DATA[sku].catalogueId === catalogueId) {
			return sku;
		}
	}

	return '';
}
