import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
// import {setNativeLanguage, setTranslationDictionary} from "./game-wrapper";

import {getNthText} from "./utils";
import { format as dateFormat, formatDistanceToNowStrict } from 'date-fns'

import enLocale from 'date-fns/locale/en-US'

const dateFnsLocale = {
	'en': enLocale,
}

export const TRANS_NAMESPACES = {
	UI: 'ui',
	CONTENT: 'content',
	VALIDATION: 'validation',
	NATIVE: 'native',
	CHALLENGE_SCRIPT: 'challenge_script',
	GAME_TITLE: 'game_title', // legacy
	KEYED_GAME_TITLE: 'keyed_game_title',
	KEYED_ELEVATOR_PITCH: 'keyed_elevator_pitch',
	KEYED_TRIVA: 'keyed_triva',
	KEYED_HELP_FULL: 'keyed_help_full',
	KEYED_CHALLENGES_TEXT: 'keyed_challenges_text',
	KEYED_CHALLENGES_TITLES: 'keyed_challenges_titles',
}

export const TRANS_LANGUAGES = {
	'en': { name: 'English', value: 'en' },
}

let resources = {
	}

i18n
	.use(initReactI18next)
	.init({
		resources,

		lng: TRANS_LANGUAGES.en.value,
		fallbackLng: TRANS_LANGUAGES.en.value,
		saveMissing: false,

		ns: Object.values(TRANS_NAMESPACES),

		defaultNS: TRANS_NAMESPACES.UI,

		debug: false,
		keySeparator: '::',
		nsSeparator: ':::',

		backend: {
			projectId: window.config.REACT_APP_LOCIZE_PROJECT_ID,
			referenceLng: TRANS_LANGUAGES.en.value,
			version: window.config.REACT_APP_LOCIZE_ENV
		},

		react: {
			useSuspense: true
		},

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default 
			// example from https://github.com/i18next/react-i18next/blob/master/example/react/src/i18n.js#L22
			format: function(value, format, lng) {
				if (format === 'ordinalEnglishSuffix') {
					return getNthText(value);
				}

				if (format === 'relativeDate') {
					return formatDistanceToNowStrict(
						value,
						{
							addSuffix: false,
							locale: dateFnsLocale[lng]
						}
					);
				};
				if (format === 'relativeDateWithSuffix') {
					return formatDistanceToNowStrict(
						value,
						{
							addSuffix: true, // 'in' ... , ... 'ago'
							locale: dateFnsLocale[lng]
						}
					);
				};
				if (format === 'shortDate') {
					let formatRule = 'do MMM'; // example: '30th Aug'
					if (lng === 'zh-Hans') {
						// in Chinese: month before day, without space '8月30日'
						formatRule = 'MMMdo';
					}
					return dateFormat(
						value,
						formatRule,
						{
							locale: dateFnsLocale[lng]
						}
					);
				};
				return value;
			}
		}
	});

i18n.on('languageChanged', (lng) => {
	// setNativeLanguage(lng);
	// setTranslationDictionary(lng);
});

export const getNamespaceResource = (lng, ns) => {
	return i18n.getResourceBundle(lng, ns)
		|| i18n.getResourceBundle(i18n.options.fallbackLng[0], ns)
		|| {};
}

export default i18n;
