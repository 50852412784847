import React, { useEffect } from 'react';
import './daily-challenges-leaderboard.component.less';
import { connect } from 'react-redux';
import { LeaderboardItem } from '../../leaderboard/item/leaderboard-item.component';
import { getDailyChallengeParty } from '../daily-challenges.actions';
import ListContainer, { LIST_TYPES } from '../../common/list/list-container.component';
import { PARENT_ENTRANCE } from '../../../assets/lib/inputmanager';
import { getLoggedUser } from '../../../app.selectors';
import { navigateToLocation, ROUTES } from '../../../app.router';
import DailyChallengesMedals from '../medals/medals.component';
import { isXboxCrossplayEnabled, isXboxUser } from '../../../assets/lib/utils';
import deviceInfo, {isPlayStationPlatform} from '../../../assets/lib/deviceInfo';

const DailyChallengesLeaderboardComponent = ({dispatch, dailyChallengeParty, ...props}) => {
    useEffect(() => {
        if (!props.isXboxCrossplayDisabledPlayer) { // no need to do this if the user is xbox with crossplaydisabled
            dispatch(getDailyChallengeParty());
        }
    }, []);

    function onLoggedUserTileRef(element) {
        // do we need this?
    }

    function renderLeaderboardItem ({ item, selected, outOfList }) {
        const isLoggedUserItem = item.playerId && item.playerId === props.loggedUserId && !outOfList;
        // if (isLoggedUserItem) this.loggedUser = item;
        // const isXboxUser = this.props.isXboxPlayer && !!item.externalAuthentications['XBL'];
		const isPlaystationUser = deviceInfo.isPlayStationPlatform() && !!item.externalIds['PSN'];

        return (
            <LeaderboardItem
                //isXboxUser={isXboxUser}
                isPlaystationUser={isPlaystationUser}
                isLoggedUserItem={isLoggedUserItem}
                item={item}
                selected={selected}
                isDailyChallenge={true}
                onLoggedUserTileRef={onLoggedUserTileRef}
            />
        );
    }

    function onTileClick (id) {
        navigateToLocation(ROUTES.USER_PROFILE, { id });
    }

    function renderGems() {
        const config = props.configuration;
        if (!config) return null;
        if (!Array.isArray(dailyChallengeParty)) return null;

        return (
            <div className='daily-challenges-leaderboard__gems'>
                {dailyChallengeParty.map(item => {
                    const prize = config?.prizes[item.rankForGems - 1];
                    if (!prize) return null;
                    const prizeAmount = prize?.GEMS?.amount;

                    return (
                        <div key={item._id} className='daily-challenges-leaderboard__rank-prize'>
                            <div className={`daily-challenges-leaderboard__rank-prize-icon-bg daily-challenges-leaderboard__rank-prize-icon-bg--${item.rankForGems}`}></div>
                            <div className="daily-challenges-leaderboard__rank-prize-amount">
                                {prizeAmount}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    function renderResults () {
        if (!Array.isArray(dailyChallengeParty) || !dailyChallengeParty.length) return null;

        const lists = [{
            type: LIST_TYPES.VERTICAL,
            id: 'playerResults',
            data: dailyChallengeParty,
            renderer: renderLeaderboardItem,
            keepCurrentChild: true,
            parentEntrance: PARENT_ENTRANCE.ONLY_HORIZONTAL
        }];

        return (
            <ListContainer
                lists={lists}
                newDataLoading={false}
                onTileClick={onTileClick}
            />
        );
    }

    return (
        <div className='daily-challenges-leaderboard'>
            <DailyChallengesMedals />
            <div className="daily-challenges-leaderboard__container">
                {renderResults()}
                {props.dailyChallengePartyPending &&
                    <div className='daily-challenges-leaderboard__loading'>
                        Loading...
                    </div>
                }
                {renderGems()}
                {props.isXboxCrossplayDisabledPlayer && (
                    <div className='daily-challenges-leaderboard__crossplay'>
                        <div>Enable Cross Play</div>
                        <div>to enter</div>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const loggedUserId = getLoggedUser(state)._id;
    const isXboxPlayer = isXboxUser(state);

    return {
        isXboxCrossplayDisabledPlayer: isXboxPlayer && !isXboxCrossplayEnabled(state),
        loggedUserId, 
        dailyChallengePartyPending: state.dailyChallenges.dailyChallengePartyPending,
        configuration: state.dailyChallenges.configuration,
        dailyChallengeParty: state.dailyChallenges.dailyChallengeParty
    };
};

const DailyChallengesLeaderboard = connect(mapStateToProps)(DailyChallengesLeaderboardComponent);
export default DailyChallengesLeaderboard;
