import { addPopup, TYPE_WS_ERROR } from '../../components/popup/popup.component';
import GenericPopup, { TYPE_HAS_TWO_BUTTONS } from '../../components/popup/generic-popup/generic-popup.component';
import {navigateBack, navigateToLocation, ROUTES} from '../../app.router';
import React from 'react';
import {startPaymentProcess} from '../../app.actions';
import FreeTierBannerContent from '../../components/common/hero-carousel/free-tier-banner/free-tier-banner-content/free-tier-banner-content.component';
import GemPaymentPopup from '../../components/gem-payment-popup/gem-payment-popup.component';
import { getTranslationTemplate } from './TranslationManager';
import inputManager from './inputmanager';
import { FailedRequestAction, SetGameError } from '../../components/game-details/game-details.actions';
import { getIsPostAuthFetched, getLatestRequestId, getUserIsSubscribed } from '../../app.selectors';
import { getGameState } from '../../components/game-details/game-details.selector';
import Elastic, { LOG_LEVEL } from './elastic.lib';
import { APPLE_IOS_SKU, GAME_STATE } from '../../constants';
import { areVideoAdsEnabled } from '../../app.selectors';
import deviceInfo, { STORE_TYPE_EPIC } from '../../assets/lib/deviceInfo';
import ShopPopup from '../../components/popup/shop-popup/shop-popup.component';
import { isFreemiumAppleIosUser, isFreeTierV2FeatureFlagEnabled } from './utils';
import SubscriptionComparisonsPopup from '../../components/popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import { startNativePayment } from './game-wrapper';

const ERROR_TYPES = {
    PAYMENT_ERROR: "PAYMENT_ERROR",
    SUBSCRIPTION_ERROR: "subscriptionError",
    PAYMENT_PENDING: "pendingError",
    NOT_ENOUGH_GEMS: "notEnoughGems",
    FREE_TIER_LIMIT_EXCEEDED: "freeTierLimitExceeded",
    SESSION_OVERLOADED: "sessionOverloaded",
    VERIFICATION_ERROR: "verificationError",
    CHALLENGE_ACCEPT_ERROR: "Challenge is not found!",
    TOURNAMENT_PLAY_ERROR: "TOURNAMENT_PLAY_ERROR",
    INSUFFICIENT_GEMS: "INSUFFICIENT_GEMS",
    NO_RESPONSE_FROM_ASL: "NO_RESPONSE_FROM_ASL",
    NO_SESSION_RESPONSE_FROM_ASL: "NO_SESSION_RESPONSE_FROM_ASL",
    CANNOT_FOLLOW_YOURSELF: "CANNOT_FOLLOW_YOURSELF",
    USER_NOT_EXIST: "USER_NOT_EXIST",
    TOKEN_IS_INCORRECT: "TOKEN_IS_INCORRECT",
    INVALID_PASS_OR_TOKEN: "INVALID_PASS_OR_TOKEN",
    DISPLAY_NAME_NOT_AVAILABLE: "DISPLAY_NAME_NOT_AVAILABLE"
};

export {ERROR_TYPES};

const showSessionOverloadPopup = () => {
    addPopup(<GenericPopup
        message="Antstream is currently experiencing a higher number of users than normal. Please try again later."
        onOkClicked={() => {
            navigateToLocation(ROUTES.HOMEPAGE);
        }}
    />);
};

const showVerificationPopup = () => {
    addPopup(<GenericPopup
        title="Email Verification"
        message="Please verify your email to access the Antstream games and challenges."
        onOkClicked={() => {
            navigateToLocation(ROUTES.HOMEPAGE);
        }}
    />);
};

const showGemPaymentPopup = (getState) => {
    // This is ONLY designed for non-subscribers. As a stopgap measure until a proper version
    // is designed just don't show it if we can't see video ads.
    // Note that there are two different ideas of isSubscribed. One is as here, the other is
    // 'is there any free tier information?' which is not the same test. 
    const state = getState();
    const isEpicStore = (deviceInfo.storeType === STORE_TYPE_EPIC);

    const PAGE_ID = 'error_types_1';
    const premiumPopupHeaderText = ['Not enough gems!', 'Upgrade to Premium for', 'Unlimited play.'];
    const isFreeTierV2FeatureEnabled = isFreeTierV2FeatureFlagEnabled(state);
    const isPostAuthFetched = getIsPostAuthFetched(state);
    const isUserSubscribed = getUserIsSubscribed(state);

    if (isFreemiumAppleIosUser(state)) {
        startNativePayment(APPLE_IOS_SKU);
        return;
    }

    if (isFreeTierV2FeatureEnabled
        && isPostAuthFetched
        && !isUserSubscribed) {
        addPopup(<SubscriptionComparisonsPopup customClassName={'subscription-comparisons-popup-not-enough'} leftHeaderText={premiumPopupHeaderText} price={'£1'} pageId={PAGE_ID} />);
        return;
    }

    if (isEpicStore) {
        navigateBack();
        addPopup(<ShopPopup />)
    } else if (areVideoAdsEnabled(state)) {
        addPopup(<GemPaymentPopup/>)
    } else {
        addPopup(<GenericPopup
            okButtonLabel="Got it!"
            title="Not enough gems"
        />);
    }
};

const showFreeTierEnergyExceededPopUp = () => {
    navigateBack();
    addPopup(<GenericPopup
        title="You've reached your limit!"
        cancelButtonLabel="Exit"
        okButtonLabel="Subscribe"
        buttonType={TYPE_HAS_TWO_BUTTONS}
        onOkClicked={()=> {
            startPaymentProcess(false);
        }}
    >
        <FreeTierBannerContent isPopupStyle={true} />
    </GenericPopup>)
}

const showPaymentPendingPopUp = () => {
    addPopup(<GenericPopup
        okButtonLabel="Ok"
        title="Subscription Pending"
        message="We are processing your payment, this may take a few minutes to show as active on our system"
        onOkClicked={() => navigateBack()}
    />);
}

export const processRunGameSessionError = (error, getState) => {
    if(error === ERROR_TYPES.SUBSCRIPTION_ERROR) {
        startPaymentProcess(true);
    } else if (error === ERROR_TYPES.FREE_TIER_LIMIT_EXCEEDED) {
        showFreeTierEnergyExceededPopUp();
    }    
    else if ((error === ERROR_TYPES.NOT_ENOUGH_GEMS) || 
             (error === ERROR_TYPES.INSUFFICIENT_GEMS)) {
        showGemPaymentPopup(getState);
    }    
    else if (error === ERROR_TYPES.PAYMENT_PENDING) {
        showPaymentPendingPopUp();
    }
    else if (error === ERROR_TYPES.VERIFICATION_ERROR) {
        showVerificationPopup();
    } else if (error === ERROR_TYPES.SESSION_OVERLOADED) {
        showSessionOverloadPopup();
    }
};

// Handled in runGameError where in the code
const isRunGameError = (type) => (type && (type === ERROR_TYPES.SUBSCRIPTION_ERROR ||
    type === ERROR_TYPES.VERIFICATION_ERROR ||
    type === ERROR_TYPES.SESSION_OVERLOADED ||
    type === ERROR_TYPES.FREE_TIER_LIMIT_EXCEEDED ||
    type === ERROR_TYPES.PAYMENT_PENDING ||
    type === ERROR_TYPES.INSUFFICIENT_GEMS)
)

export const webSocketErrorHandling = (error, dispatch, getState) => {
    if(!error || error.result) return // we don't handle these here as not popups - these include untranslated messages from gamesparks which we need to resolve
    if(error.type && !isRunGameError(error.type)) { // fire template message if not defined in processRunGameSessionError
        const template = getTranslationTemplate(error.type);

        inputManager.onPressButtonB();
        addPopup(<GenericPopup
            okButtonLabel="Got it!"
            title={template.title || "Something's gone wrong!"}
            message={template.message}
        />);
    }              
    else if(error.type && isRunGameError(error.type)) { processRunGameSessionError(error.type, getState)}
    else if (error.Verification) {
        addPopup(<GenericPopup
            okButtonLabel="Got it!"
            title="Account Recovery"
            message="An access token has been sent to your email address, please input the token to reset your password."
        />);
    }
    else if (error.ResetPassword) {
        addPopup(<GenericPopup
            okButtonLabel="Got it!"
            title="Account Recovery"
            message="Your password has been reset successfully."
        />, {}, true);
    }
    else if (error.DETAILS) {
        switch (error.DETAILS) {
            case 'UNRECOGNISED':
                addPopup(<GenericPopup
                    okButtonLabel="Got it!"
                    message="The username and password you entered did not match our records. Please double-check and try again."
                />, {}, true);
                break;
            case 'LOCKED':
                addPopup(<GenericPopup
                    okButtonLabel="Got it!"
                    message="Your account has been locked due to too many failed login attempts. Please try again later."
                />, {}, true);
                break;
            default:
                addPopup(<GenericPopup
                    okButtonLabel="Got it!"
                    title="Something's gone wrong!"
                    message={error.DETAILS}
                />, {}, true);
                break;
        }
    }
    else if (error.authentication === "NOTAUTHORIZED") {
        // we silently catch the error,
        // the router will handle it
    }
    else {
        console.log('catch all ERROR:', error)
        // final catch-all for !resp.error.type, eg. ASL error
        Elastic.logDirect(LOG_LEVEL.ERROR,"Error code returned : " + error.message);
        const state = getState()
        if (getGameState(state) === GAME_STATE.LOADING) {
            const requestId = getLatestRequestId(state);
            dispatch(FailedRequestAction( requestId ));
            dispatch(SetGameError()); // On how to play page this will fire a popup
        } else {
            addPopup(<GenericPopup
                okButtonLabel="Got it!"
                title="Something's gone wrong!"
                message={ error.message || "" }
            />, { type: TYPE_WS_ERROR });
        }
    }
}

