import React from 'react';
import './qr-code-popup.component.less';
import GenericPopup from "../generic-popup/generic-popup.component";

const QrCodePopup = (props) => {

	return (
		<GenericPopup
			primaryButtonClassName='qr-code-popup__btn'
			okButtonLabel='Back'
			genericClassName='qr-code-popup-container'
			title={props.title}
			message={
				<div className='qr-code-popup'>
					<div className='qr-code-popup__col'>
						<img className="qr-code-popup__img" src={props.image} alt="Slayers" />
					</div>
					<div className='qr-code-popup__col'>
						<p className='qr-code-popup__paragraph'>
							For full details please scan the QR code.
						</p>
					</div>
				</div>
			}
		/>
	);
};

export default QrCodePopup;
