import React, { Component } from 'react';
import { get } from 'lodash';
import './input.component.less';
import deviceInfo, {
	PLATFORM_TYPE_ATARI,
	PLATFORM_TYPE_FIRE_TV,
	PLATFORM_TYPE_NVIDIA_SHIELD
} from '../../../assets/lib/deviceInfo';
import inputmanager from '../../../assets/lib/inputmanager';
import {hideUIVirtualKeyboard, showUIVirtualKeyboard} from "../../../assets/lib/game-wrapper";
import {change} from "redux-form";
import { store as reduxStore } from '../../../configureStore';
import { isXboxUser } from '../../../assets/lib/utils';

const renderError = (props) => {
	const meta = get(props, 'meta', {});
	if ((meta.touched || meta.visited) && meta.error) {
		return <div className="validation-error"><span>{meta.error}</span></div>;
	}
	return null;
};

class Input extends Component {
	constructor() {
		super();
		this.state = {
			errors: [],
			selected:false
		};
	}

	componentDidUpdate(prevProps) {
		// console.log("prevProps",prevProps,"this.props",this.props);
		if(!this.props.disabled !== !prevProps.disabled) {
			inputmanager.setChildActive(this.props.childId, !this.props.disabled);
		}
	}

	onCustomFocus = (focus) => {
		this.setState({selected:focus});
		if(!this.inputRef)return;

		if(focus) {
			if(document.activeElement!==this.inputRef) {
				if(deviceInfo.platformType!==PLATFORM_TYPE_NVIDIA_SHIELD && deviceInfo.platformType!==PLATFORM_TYPE_FIRE_TV && deviceInfo.platformType!==PLATFORM_TYPE_ATARI && !deviceInfo.isTouchDevice && !deviceInfo.isXboxPlatform() && !deviceInfo.isPlayStationPlatform()) {
					this.inputRef.focus();
				}
			}
		} else {
			this.inputRef.blur();
			if(deviceInfo.platformType===PLATFORM_TYPE_ATARI) {
				hideUIVirtualKeyboard();
			}
		}
	};

	onKeyboardEvent = (value) => {
		const currentInputValue = this.getValue();
		let updatedValue = "";
		if(value === 'back_space'){
			updatedValue = currentInputValue.slice(0, -1);
		}else{
			updatedValue = currentInputValue + value;
		}
		this.setValue(updatedValue);
		if(this.props.formId)reduxStore.dispatch(change(this.props.formId, this.props.input.name, updatedValue));
		this.props.onValueChange && this.props.onValueChange(updatedValue);
	}

	onInputClicked = () => {
		inputmanager.setCurrentChildById(this.props.childId,this.props.parentId);
		const isXboxPlayer = isXboxUser(reduxStore.getState());
		const isPlayStationPlayer = deviceInfo.isPlayStationPlatform();

		if (!isXboxPlayer && !isPlayStationPlayer) {
			this.inputRef.focus();
		}

		// AD2-14001 Temporary fix while we wait for native to solve the issue
		if(isXboxPlayer || isPlayStationPlayer) {
			if (!this.state.selected) {
				console.log('input component initial focus');
				this.inputRef.focus();
			}

			if (this.inputRef.value !== "") {
				this.setValue("");
				if(this.props.formId)reduxStore.dispatch(change(this.props.formId, this.props.input.name, ""));
				this.props.onValueChange && this.props.onValueChange("");
			}

			// if the input is already focused - blur and focus it again
			// in order to make the xbox search pop-up appear
			if (this.state.selected) {
				console.log('input component blur => focus with delay');
				this.inputRef.blur();
				setTimeout(() => this.inputRef.focus(), 300);
			}
		}
		if(deviceInfo.platformType===PLATFORM_TYPE_ATARI){
			showUIVirtualKeyboard();
		}
	};

	setValue = (value) => {
		this.inputRef.value = value;
	}

	getValue = () => {
		const value = this.inputRef ? this.inputRef.value : null;
		// in case we are not using an external form library this one handles errors if the validate array is passed
		if (this.props.validate) {
			const errors = [];
			this.props.validate.forEach( (validator) => {
				const res = validator(value);
				if (res) errors.push(res);
			});
			this.setState({errors: errors});			
		}
		return value;
	};
	
	hasError = () => {
		return !!this.state.errors.length;
	};

	renderErrors() {
		if (!this.state.errors.length) return null;
		// rendering only one at the time for now, we'd need unique keys to render more
		return <span>{this.state.errors[0]}</span>;
	}

	onInputRef = (element) => {
		this.inputRef = element;
		if(this.props.childId){
			inputmanager.onChildRef(element,this.props.childId, this.onCustomFocus, this.props.parentId, this.props.layer||0, undefined, this.onKeyboardEvent);
			inputmanager.setChildActive(this.props.childId, !this.props.disabled);
		}
	};


	render() {
		const {name, containerClass='', label,input, type="text", disabled,placeholder = "", defaultValue, meta, handleBlur, onKeyDown} = this.props;
		const finalContainerClass = `${containerClass}${disabled ? ' disabled ' : ''}`;
		return (
			<div ref="containerRef" tabIndex={0} className={`input-container ${finalContainerClass}`}>
				<label htmlFor={name}>{label}</label>
				{this.hasError() && <div className="validation-error">{this.renderErrors()}</div>}
				<input
					onKeyDown={onKeyDown}
					className={`${this.state.selected?'selected':''}`}
					{...input}
					disabled={disabled}
					ref={this.onInputRef}
					autoComplete={'off'}
					type={type}
					placeholder={placeholder}
					defaultValue={defaultValue}
					onClick={this.onInputClicked}
					onBlur={() => handleBlur && handleBlur(input.value, meta.dirty)}
				/>
				<div className="pulsing-border"/>
				{renderError(this.props)}
			</div>
		);
	}
}

export { Input };