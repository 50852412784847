import React from 'react';
import { Avatar } from '../../common/avatar/avatar.component';
import './tile-user-opponent.component.less';
import {PulsarComponent} from '../pulsar/pulsar.component';
import {withBadWordMask} from "../../../assets/lib/utils";

const TileUserOpponent = ({ item, opponentId, selected }) => {
	const selectedStyle = opponentId === item._id ? 'toggled' : '';

	return (
		<div className={`tile-user-opponent-component ${selectedStyle} ${selected? 'selected': ''}`}>
			<div className="inner-container">
				<div className="inner">
					<Avatar userData={item}/>
					<div className="username">
						<span>{withBadWordMask(item.displayName)}</span>
					</div>
					<div className="rank">Rank {item.Rank}</div>
				</div>
			</div>
			<PulsarComponent/>
		</div>
	);
};

export default TileUserOpponent;