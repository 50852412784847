import {
	ADD_PLAYER_TO_TOURNAMENT_ACTION, ADD_PLAYER_TO_TOURNAMENT_ACTION_FAILED,
	ADD_PLAYER_TO_TOURNAMENT_ACTION_SUCCESS, CHECK_TOURNAMENT_INFO_POPUPS_SUCCESS,
	FETCH_TOURNAMENT_DETAIL_ACTION_SUCCESS, FETCH_TOURNAMENTS_HISTORY_SUCCESS, FETCH_TOURNAMENTS_LIST_ACTION,
	FETCH_TOURNAMENTS_LIST_ACTION_SUCCESS,
	EXTERNAL_SPONSOR_TOURNAMENT_LINK_USED,
	FETCH_TOURNAMENTS_PLAYERS_SUCCESS, RESET_TOURNAMENT_DETAIL_ACTION
} from "./tournaments.actions";
import { LOCATION_CHANGE } from 'connected-react-router';
import { paramsToObject } from '../../app.helpers';

const initialState = {
    list: null,
    currentId: null,
    currentTournamentData: null,
    isPlayerJoined: null,
    players: null,
	leaderBoardObject: null,
	tournamentPopups: null,
    tournamentHistory: null,
	externalSponsorTournamentLinkUsed: null,
	externalSponsorTournamentId: null,
    followedPlayers: null
};

export const tournamentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case EXTERNAL_SPONSOR_TOURNAMENT_LINK_USED: {
			return {
				...state,
				externalSponsorTournamentLinkUsed: !!action.payload,
				externalSponsorTournamentId: action.payload
			};
		}
		case LOCATION_CHANGE:
			const {id} = paramsToObject(action.payload.location.search);
			return {
				...state,
				currentId: id,
			};
		case FETCH_TOURNAMENTS_LIST_ACTION:
			return {
				...state,
				list: null
			};
		case FETCH_TOURNAMENTS_LIST_ACTION_SUCCESS:
			return {
				...state,
				list: action.payload
			};
		case RESET_TOURNAMENT_DETAIL_ACTION:
			return {
				...state,
				currentTournamentData: null,
				isPlayerJoined: null,
				leaderBoardObject: null,
				rewards: null
			};
		case FETCH_TOURNAMENT_DETAIL_ACTION_SUCCESS:
			return {
				...state,
				currentTournamentData: action.payload.tournamentData,
				isPlayerJoined: action.payload.isPlayerJoined,
				leaderBoardObject: action.payload.leaderBoardObject,
				rewards: action.payload.rewards
			};
		case ADD_PLAYER_TO_TOURNAMENT_ACTION:
			return {
				...state,
				isAddPlayerToTournamentPending: true
			};
		case ADD_PLAYER_TO_TOURNAMENT_ACTION_FAILED:
			return {
				...state,
				isAddPlayerToTournamentPending: false
			};
		case ADD_PLAYER_TO_TOURNAMENT_ACTION_SUCCESS:
			return {
				...state,
				isAddPlayerToTournamentPending: false,
				isPlayerJoined: true
			};
		case FETCH_TOURNAMENTS_PLAYERS_SUCCESS:
		return {
			...state,
			players: action.players,
			followedPlayers: action.followedPlayers
		};
		case FETCH_TOURNAMENTS_HISTORY_SUCCESS:
		return {
			...state,
			tournamentHistory: action.tournamentHistory
		};
		case CHECK_TOURNAMENT_INFO_POPUPS_SUCCESS:
		return {
			...state,
			tournamentPopups: action.tournamentPopups
		};
	default:
		return state;
	}
};
