import React, {Component} from 'react';
import {connect} from 'react-redux';
import './test-connection.component.less';
import {TEST_EMOJI_TYPES, TestConnectionItem} from './test-connection-item.component';
import {Button} from '../../common/button/common-button.component';
import {
	getBandwidthTestResults,
	getConnectionTestResults,
	getCurrentRegionObject,
} from '../settings.selector';
import antLogo from '../../../assets/images/global/ant-logo.svg';
import serverIcon from '../../../assets/images/global/server-icon.svg';
import {navigateToLocation, ROUTES} from '../../../app.router';
import {
	clearBandwidthTestData,
	clearTestSessionData, GetUserRegionAction,
	onTestSessionRequested,
	requestBandWidthTest, TEST_RESULT_TYPES
} from '../settings.actions';
import inputmanager from '../../../assets/lib/inputmanager';
import {SetGameQuit} from '../../game-details/game-details.actions';
import {endGame} from '../../../assets/lib/game-wrapper';
import {getRunningSessionId} from '../../../app.selectors';
import deviceInfo, {OS_TYPE_OSX} from '../../../assets/lib/deviceInfo';
import { getCurrentDeviceName } from '../../../assets/lib/utils';

class TestConnectionComponent extends Component {
	constructor(props) {
		super(props);

		this.generalTips = [];
		this.otherTips = [];

		this.initialState =  {
			animationTimer: -1,
			isTipsPageVisible: false,
			isGeneralTips: false,
			generalTipsCount: 0,
			otherTipsCount: 0,
		};
		this.state = this.initialState;

		this.cancelClicked = false;

		this.GENERAL_TIPS = {
			GENERAL_CLOSE_OTHER_APPS : `Check you don't have any other programs or background updates running on this ${getCurrentDeviceName()} that might be using resources.`,
			GENERAL_RESTART_MACHINE : `Restart your ${getCurrentDeviceName()}.`,
			GENERAL_SWITCH_NETWORK : "If possible, try using a different network connection. Antstream Arcade generally works best with wired internet connections, then 5GHz Wi-Fi, 2.4GHz Wi-Fi and least well with mobile data. However, a personal mobile data connection will often do better than a busy, public Wi-Fi connection.",
			GENERAL_SWITCH_TO_ETHERNET : "If possible, try changing to a wired network connection. These have the smoothest streaming.",
			GENERAL_RESTART_ROUTER : "Try restarting your router.",
			GENERAL_DIFFERENT_REGION : "Check that you have the closest region set in your account settings. The physical distance to the server has a big impact.",
			GENERAL_CHECK_SIGNAL_STRENGTH : "If using a wireless connection, check the signal strength.",
		};

		this.OTHER_TIPS = {
			// CHECK_AUDIO_DRIVERS : "Check that your audio settings are correct and working.",
			DISABLE_LOCATION_SERVICES : "Turn off location services, if using a Wi-Fi connection.",
			DONT_USE_VPN : "Avoid using VPN connections.",
			// TRY_PORT_FORWARDING : "If possible, configure your router to forward port XXX to this %DEVICE_NAME%.",
			VIDEO_TOO_SLOW : "Try running the program in fullscreen mode, a smaller window or set a lower desktop resolution or monitor refresh rate.",
			DISABLE_VIDEO_RECORDING : "Disable any streaming or video recording programs that might be running.",
		};

	}

	componentDidMount() {
		this.props.dispatch(GetUserRegionAction(this.onRegionFetched));
		inputmanager.setCurrentChildById("cancelButton-1");
		this.animationTimer = null;
		this.startAnimationTimer();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.bandwidthTestResults && this.props.bandwidthTestResults) {
			this.props.dispatch(onTestSessionRequested());
		} else if (prevProps.connectionTestResults !== this.props.connectionTestResults) {
			this.generateTips();
			if (this.props.connectionTestResults) {
				this.stopAnimationTimer();
				inputmanager.setCurrentChildById("doneButton-2");
			} else {
				this.startAnimationTimer();
				inputmanager.setCurrentChildById("cancelButton-1");
			}
		}
	}

	componentWillUnmount() {
		if (!this.cancelClicked) endGame();
		this.stopAnimationTimer();
		this.props.dispatch(clearBandwidthTestData());
		this.props.dispatch(clearTestSessionData());
	}

	onRegionFetched = () => {
		if(this.props.currentRegionObject)this.props.dispatch(requestBandWidthTest(this.props.currentRegionObject.testBandwidthUrlByRegion));
	};

	startAnimationTimer = () => {
		this.stopAnimationTimer();
		this.animationTimer = setInterval(() => {
			if (this.state.animationTimer >= 9) {
				this.stopAnimationTimer();
				return;
			}
			this.setState(prevState => ({
				animationTimer: prevState.animationTimer + 1
			}));
		}, 1600);
		
	};

	stopAnimationTimer = () => {
		if (this.animationTimer) {
			clearInterval(this.animationTimer);
			this.animationTimer = null;
		}
	};

	renderTestResults = () => {
		const elements = [];
		this.props.connectionTestResults.forEach((resultObject) => {
			elements.push(
				<TestConnectionItem
					key={resultObject.name}
					resultObject={resultObject}
				/>
			)
		});

		return elements;
	};

	renderLoadingDots = () => {
		const elements = [];
		for (let i = 0; i < 11; i++) {
			elements.push(
				<span key={`loadingDot-${i}`}
					  className={`loading-dot ${i <= this.state.animationTimer ? 'selected' : ''}`}/>
			)
		}

		return elements;
	};

	generateTips = () => {
		if(!this.props.connectionTestResults) return null;

		const anyPoor = this.props.connectionTestResults.find(item=>item.emojiType===TEST_EMOJI_TYPES.POOR);
		const anyPoorAndOK = anyPoor || this.props.connectionTestResults.find(item=>item.emojiType===TEST_EMOJI_TYPES.OK);

		//streaming
		const streamingResult = this.props.connectionTestResults.find(item=>item.type===TEST_RESULT_TYPES.VID_EFFICIENCY);
		const streamingPoor = streamingResult ? streamingResult.emojiType===TEST_EMOJI_TYPES.POOR : true;
		const streamingPoorAndOK = streamingPoor || (streamingResult ? streamingResult.emojiType===TEST_EMOJI_TYPES.OK : true);

		//inputLag
		const inputLagResult = this.props.connectionTestResults.find(item=>item.type===TEST_RESULT_TYPES.INPUT_LAG);
		const inputLagPoor = inputLagResult ? inputLagResult.emojiType===TEST_EMOJI_TYPES.POOR : true;
		const inputLagPoorAndOK = inputLagPoor || (inputLagResult ? inputLagResult.emojiType===TEST_EMOJI_TYPES.OK : true);

		//packet Lost
		const packetsResult = this.props.connectionTestResults.find(item=>item.type===TEST_RESULT_TYPES.PACKETS_PERCENTAGE);
		const packetsPoor = packetsResult ? packetsResult.emojiType===TEST_EMOJI_TYPES.POOR : true;
		const packetsPoorAndOK = packetsPoor || (packetsResult ? packetsResult.emojiType===TEST_EMOJI_TYPES.OK : true);

		//bandwidth
		const bandwidthResult = this.props.connectionTestResults.find(item=>item.type===TEST_RESULT_TYPES.BANDWIDTH);
		const bandwidthPoor = bandwidthResult ? bandwidthResult.emojiType===TEST_EMOJI_TYPES.POOR : true;
		const bandwidthPoorAndOK = bandwidthPoor || (bandwidthResult ? bandwidthResult.emojiType===TEST_EMOJI_TYPES.OK : true);

		this.generalTips = [];
		if(anyPoorAndOK)this.generalTips.push(this.GENERAL_TIPS.GENERAL_CLOSE_OTHER_APPS);
		if(anyPoor)this.generalTips.push(this.GENERAL_TIPS.GENERAL_RESTART_MACHINE);
		if(anyPoorAndOK)this.generalTips.push(this.GENERAL_TIPS.GENERAL_SWITCH_NETWORK);
		if(streamingPoorAndOK)this.generalTips.push(this.GENERAL_TIPS.GENERAL_SWITCH_TO_ETHERNET);
		if(streamingPoor || bandwidthPoor || inputLagPoor)this.generalTips.push(this.GENERAL_TIPS.GENERAL_RESTART_ROUTER);
		if(streamingPoor || bandwidthPoor || inputLagPoor)this.generalTips.push(this.GENERAL_TIPS.GENERAL_DIFFERENT_REGION);
		if(packetsPoorAndOK)this.generalTips.push(this.GENERAL_TIPS.GENERAL_CHECK_SIGNAL_STRENGTH);

		this.otherTips = [];
		// this.otherTips.push(this.OTHER_TIPS.CHECK_AUDIO_DRIVERS);
		if(streamingPoorAndOK && deviceInfo.osType===OS_TYPE_OSX)this.otherTips.push(this.OTHER_TIPS.DISABLE_LOCATION_SERVICES);
		if(streamingPoor || inputLagPoor)this.otherTips.push(this.OTHER_TIPS.DONT_USE_VPN);
		if(streamingPoorAndOK && !inputLagPoorAndOK && !bandwidthPoorAndOK && !packetsPoorAndOK) {
			this.otherTips.push(this.OTHER_TIPS.VIDEO_TOO_SLOW);
			this.otherTips.push(this.OTHER_TIPS.DISABLE_VIDEO_RECORDING);
		}

		this.setState({
			generalTipsCount: this.generalTips.length,
			otherTipsCount: this.otherTips.length,
		});
	};

	renderTips = () => {
		const elements = [];
		const tips = this.state.isGeneralTips ? this.generalTips : this.otherTips;

		tips.forEach((item, index) => {
			elements.push(
				<div key={`tips-${index}`} className="tip-item">
					{item}
				</div>
			)
		});
		return elements;
	};

	onDoneClicked = () => {
		navigateToLocation(ROUTES.SETTINGS_SUPPORT);
	};

	onCancelClicked = () => {
		this.cancelClicked = true;
		this.props.dispatch(SetGameQuit(this.props.sessionId));
		navigateToLocation(ROUTES.SETTINGS_SUPPORT);
	};

	onRetestConnectionClicked = () => {
		this.setState({...this.initialState});
		this.props.dispatch(clearBandwidthTestData());
		this.props.dispatch(clearTestSessionData());
		this.props.dispatch(requestBandWidthTest(this.props.currentRegionObject.testBandwidthUrlByRegion));
	};

	onSeeTipsClicked = () => {
		this.setState({
			isTipsPageVisible:true,
			isGeneralTips: this.state.otherTipsCount===0
		},()=> {
			inputmanager.setCurrentChildById("nextButton-3");
		});
	};

	onNextClicked = () => {
		if(this.state.generalTipsCount>0){
			this.setState({
				isTipsPageVisible:true,
				isGeneralTips:true
			},()=>{
				inputmanager.setCurrentChildById("exitButton-3");
			});
		}else{
			this.onDoneClicked();
		}
	};

	render() {
		if(!this.state.isTipsPageVisible) {
			if (this.props.connectionTestResults) {
				return (
					<div className="test-connection-component">
						<div className="top-container">
							<h1>Test Complete</h1>
						</div>
						<div className="test-connection-results">
							<div className="results-container">
								{this.renderTestResults()}
							</div>
						</div>
						<div className="buttons-container">
							{(this.state.otherTipsCount>0 || this.state.generalTipsCount>0) && <Button className="secondary" onClick={this.onSeeTipsClicked} childId="tipsButton-2" key="tipsButton-2">
								See Tips
							</Button>}
							<Button className="secondary" onClick={this.onRetestConnectionClicked} childId="retestButton-2" key="retestButton-2">
								Test Again
							</Button>
							<Button className="primary" onClick={this.onDoneClicked} childId="doneButton-2" key="doneButton-2">Done</Button>
						</div>
					</div>
				);
			} else {
				return (
					<div className="test-connection-component">
						<div className="top-container">
							<h1>Connection Test</h1>
						</div>
						<div className="animation-container">
							<img className="ant_logo" alt="ant-logo" src={antLogo}/>
							{this.renderLoadingDots()}
							<img className="server-icon" alt="server-icon" src={serverIcon}/>
						</div>
						<div className="buttons-container">
							<Button className="primary"
									onClick={this.onCancelClicked}
									childId="cancelButton-1"
									key="cancelButton-1"
							>
								Cancel
							</Button>
						</div>
					</div>
				);
			}
		} else {
			return (
				<div className="test-connection-component">
					<div className="top-container">
						<h1>{this.state.isGeneralTips? 'General Connection Tips' : 'Tips Based On Your Results'}</h1>
					</div>
					<div className="tips-container">
						{this.renderTips()}
					</div>
					<div className="buttons-container">
						<Button className="secondary" onClick={this.onRetestConnectionClicked} childId="retestButton-3" key="retestButton-3">
							Test Again
						</Button>
						{!this.state.isGeneralTips && <Button className="secondary" onClick={this.onDoneClicked} childId="exitButton-3" key="exitButton-3">
							Exit
						</Button>}
						<Button className="primary" onClick={this.state.isGeneralTips?this.onDoneClicked:this.onNextClicked} childId="nextButton-3" key="nextButton-3">
							{this.state.isGeneralTips ? 'Exit' : 'Next'}
						</Button>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		sessionId: getRunningSessionId(state),
		connectionTestResults: getConnectionTestResults(state),
		bandwidthTestResults: getBandwidthTestResults(state),
		currentRegionObject: getCurrentRegionObject(state),
	};
};

export const TestConnection = connect(mapStateToProps)(TestConnectionComponent);