import { getWebviewState } from './app.selectors';
import fileLaunch from './assets/sounds/launch.mp3';
import fileGemcounter from './assets/sounds/gemcounter.mp3';
import fileGemspent from './assets/sounds/gemspent.mp3';
import fileClick from './assets/sounds/click.mp3';
import fileXpgain from './assets/sounds/xpgain.mp3';
import fileLevelup from './assets/sounds/levelup.mp3';
import fileChallengewin from './assets/sounds/challengewin.mp3';
import fileChallengefail from './assets/sounds/challengefail.mp3';
import fileNavigation from './assets/sounds/navigation.mp3';
import fileNotification from './assets/sounds/notification.mp3';
import fileGameDetailPlayButton from './assets/sounds/gameDetailPlayButton.mp3';
import fileFinalPlayButton from './assets/sounds/finalPlayButton.mp3';
import fileListTileChange from './assets/sounds/listTileChange.mp3';
import fileVerticalListRowChange from './assets/sounds/verticalListRowChange.mp3';
import fileGiantSlayer from './assets/sounds/giantSlayer.mp3';
import fileGiantSlayerResults from './assets/sounds/giantSlayerResults.mp3';
import fileGiantSlayerProgressBar from './assets/sounds/giantSlayerProgressBar.mp3';
import fileGiantSlayerWinning from './assets/sounds/giantSlayerWinning.mp3';
import fileGiantSlayerLosing from './assets/sounds/giantSlayerLosing.mp3';
import fileGiantSlayerHitGood from './assets/sounds/giantSlayerHitGood.mp3';
import fileGiantSlayerHitFail from './assets/sounds/giantSlayerHitFail.mp3';
import fileWhoosh from './assets/sounds/whoosh.mp3';
import fileGo from './assets/sounds/go.mp3';
import fileChallengeOver from './assets/sounds/challengeOver.mp3';
import {Howl, Howler} from 'howler';
import {messageNativePlaySound} from './assets/lib/game-wrapper';
import deviceInfo from "./assets/lib/deviceInfo";

const audioMasterVolume = 1.0;	//can make this configurable later

export const TYPE_LAUNCH = 'launch';
export const TYPE_GEM_COUNTER = 'gemcounter';
export const TYPE_GEM_SPENT = 'gemspent';
export const TYPE_CLICK = 'click';
export const TYPE_XP_GAIN = 'xpgain';
export const TYPE_LEVEL_UP = 'levelup';
export const TYPE_CHALLENGE_WIN = 'challengewin';
export const TYPE_CHALLENGE_FAIL = 'challengefail';
export const TYPE_NOTIFICATION = 'notification';
export const TYPE_NAVIGATION = 'navigation';
export const TYPE_GAMEDETAIL_PLAY_BUTTON = 'gameDetailPlayButton';
export const TYPE_FINAL_PLAY_BUTTON = 'finalPlayButton';
export const TYPE_LIST_TILE_CHANGE = 'listTileChange';
export const TYPE_VERTICAL_LIST_ROW_CHANGE = 'verticalListRowChange';
export const TYPE_GIANT_SLAYER = 'giantSlayer';
export const TYPE_GIANT_SLAYER_RESULTS = 'giantSlayerResults';
export const TYPE_GIANT_SLAYER_PROGRESS_BAR = 'giantSlayerProgressBar';
export const TYPE_GIANT_SLAYER_WINNING = 'giantSlayerWinning';
export const TYPE_GIANT_SLAYER_LOSING = 'giantSlayerLosing';
export const TYPE_GIANT_SLAYER_HIT_GOOD = 'giantSlayerHitGood';
export const TYPE_GIANT_SLAYER_HIT_FAIL = 'giantSlayerHitFail';
export const TYPE_WHOOSH = 'whoosh';
export const TYPE_GO = 'go';
export const TYPE_CHALLENGE_OVER = 'challengeOver';


const audioSettings = [
	{type:TYPE_LAUNCH, file:fileLaunch, volume:0.25},
	{type:TYPE_GEM_COUNTER, file:fileGemcounter, volume:0.8},
	{type:TYPE_GEM_SPENT, file:fileGemspent, volume:1.0},
	{type:TYPE_CLICK, file:fileClick, volume:0.2},
	{type:TYPE_XP_GAIN, file:fileXpgain, volume:0.75},
	{type:TYPE_LEVEL_UP, file:fileLevelup, volume:1.0},
	{type:TYPE_CHALLENGE_WIN, file:fileChallengewin, volume:1.0},
	{type:TYPE_CHALLENGE_FAIL, file:fileChallengefail, volume:1.0},
	{type:TYPE_NOTIFICATION, file:fileNotification, volume:1.0},
	{type:TYPE_NAVIGATION, file:fileNavigation, volume:0.4},
	{type:TYPE_GAMEDETAIL_PLAY_BUTTON, file:fileGameDetailPlayButton, volume:1.0},
	{type:TYPE_FINAL_PLAY_BUTTON, file:fileFinalPlayButton, volume:0.5},
	{type:TYPE_LIST_TILE_CHANGE, file:fileListTileChange, volume:0.5},
	{type:TYPE_VERTICAL_LIST_ROW_CHANGE, file:fileVerticalListRowChange, volume:0.6},
	{type:TYPE_GIANT_SLAYER, file:fileGiantSlayer, volume:1},
	{type:TYPE_GIANT_SLAYER_RESULTS, file:fileGiantSlayerResults, volume:1},
	{type:TYPE_GIANT_SLAYER_PROGRESS_BAR, file:fileGiantSlayerProgressBar, volume:1},
	{type:TYPE_GIANT_SLAYER_WINNING, file:fileGiantSlayerWinning, volume:1},
	{type:TYPE_GIANT_SLAYER_LOSING, file:fileGiantSlayerLosing, volume:1},
	{type:TYPE_GIANT_SLAYER_HIT_GOOD, file:fileGiantSlayerHitGood, volume:1},
	{type:TYPE_GIANT_SLAYER_HIT_FAIL, file:fileGiantSlayerHitFail, volume:1},
	{type:TYPE_WHOOSH, file:fileWhoosh, volume:1},
	{type:TYPE_GO, file:fileGo, volume:1},
	{type:TYPE_CHALLENGE_OVER, file:fileChallengeOver, volume:1},
];

let audioManagerInstance = null;
class AudioManager {
	constructor() {
		this.sounds = [];
		this.isWebViewVisible = true;

		this.onStoreUpdate = this.onStoreUpdate.bind(this);

		Howler.volume(audioMasterVolume);

		audioSettings.forEach(item => {
			if(!this.sounds[item.type]) {
				this.sounds[item.type] = new Howl({
					src: [ item.file ],
					volume: item.volume
				});
			}
		});
	}

	playAudio = (type) => {
		if(!this.isWebViewVisible)return;

		if (deviceInfo.isWebviewUltralight()) { // if using Ultralight - play sound via Native
			const found = audioSettings.find(el => el.type === type);
			if (found) {
				messageNativePlaySound(found.type + '.mp3', found.volume);
			}
		} else {
			if(this.sounds[type]) {
				this.sounds[type].play();
			}
		}
	};

	setMasterVolume = (volume) => {
		const normalizedVolume = volume / 100;
		Howler.volume(normalizedVolume);
	};

	onStoreUpdate(store) {
		this.isWebViewVisible = getWebviewState(store.getState()).visible;
	}
}

const getAudioManager = () => {
	if(audioManagerInstance===null) {
		audioManagerInstance = new AudioManager();
	}
	return audioManagerInstance;
};

export default getAudioManager();
