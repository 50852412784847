import React from 'react';
import { Avatar } from '../../common/avatar/avatar.component';
import './tile-user.component.less';
import {PulsarComponent} from '../pulsar/pulsar.component';
import {withBadWordMask} from "../../../assets/lib/utils";

const TileUser = ({ item, selected }) => {

	return (
		<div className={`tile-user-component ${selected?'selected':''}`}>
			<div className="user-details">
				<Avatar userData={item}/>
				<div className="username">
					<span>{withBadWordMask(item.displayName)}</span>
				</div>
				<div className="rank">Rank {item.Rank}</div>
			</div>
			<PulsarComponent/>
		</div>
	);
};

export default TileUser;