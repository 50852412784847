import React, {Component} from 'react';
import './save-button.component.less';

// Components
import FeatureToggle, { FEATURES } from '../feature-toggle/feature-toggle.component';

// Assets
import deviceInfo, { STORE_TYPE_EPIC }from '../../../assets/lib/deviceInfo';
import antstreamLogo from '../../../assets/images/global/antsream_logo.svg';
import inputmanager from '../../../assets/lib/inputmanager';

class SaveButton extends Component {
	constructor(props) {
		super(props);
		this.refButton = null;
		this.state = {
			selected:false,
			bounceAnimationActive:false
		};
	}

	componentDidUpdate(prevProps) {
		// console.log("prevProps",prevProps,"this.props",this.props);
		if(!this.props.notSelectable !== !prevProps.notSelectable){
			inputmanager.setChildActive(this.props.childId, !this.props.notSelectable);
		}
	}

	onCustomFocus = (focus/*, nextElement*/) => {
		this.setState({selected:focus});
		// if(focus && this.refButton) {
		// 	this.refButton.focus();
		// }
	};

	onButtonClicked = () => {
		if(this.props.notSelectable)return;
		this.setState({bounceAnimationActive:true});
	};

	onAnimationEnd = () => {
		this.setState({bounceAnimationActive:false});
		if(this.props.onClick)this.props.onClick(this.props.data,this.props.slot);
	};

	onButtonRef = (element) => {
		this.refButton = element;
		inputmanager.onChildRef(element, this.props.childId, this.onCustomFocus);
		inputmanager.setChildActive(this.props.childId, !this.props.notSelectable);
	};

	render() {
		let moreStyles = '';
		moreStyles += this.props.className ? this.props.className: '';
		moreStyles += !this.props.data ? ' empty' : '';
		moreStyles += this.props.disabled ? ' disabled' : '';
		moreStyles += this.state.selected ? ' selected' : '';
		moreStyles += this.state.bounceAnimationActive ? ' bounce-animation' : '';

		return (
			<button
				className={`save-button ${moreStyles}`}
				type={this.props.type || 'button'}
				disabled={this.props.disabled}
				onClick={this.onButtonClicked}
				ref={this.onButtonRef}
				onAnimationEnd={this.onAnimationEnd}
			>
				{this.props.data ? <img className="background" src={!deviceInfo.isWaysunDevice() ? this.props.data.screenShotUrl : antstreamLogo} alt="" /> : null}
				<div className="content">
					<div className="title">
						{
							!this.props.data ?
								this.props.isInGameDetailPage ?
									"Empty"
									:"+"
							: this.props.data.slot === 0 ?
								"Continue"
								: this.props.isInGameDetailPage ?
									'Save '+this.props.data.slot
									:'Slot '+this.props.data.slot}
					</div>
				</div>
				<div className="border"/>
				<FeatureToggle feature={FEATURES.PREMIUM_SAVE_ONLY} platforms={[STORE_TYPE_EPIC]}>
					{this.props.premiumBenefitsText &&
						<div className="premium-overlay">
							<div className="premium-benefits">
								<div className="premium-benefits-text">
									{this.props.premiumBenefitsText}
								</div>
							</div>
						</div>
					}
				</FeatureToggle>
			</button>
		);
	}
}

export default SaveButton;
