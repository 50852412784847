import React from "react";
import Countdown from "./countdown.component";
import gemIcon from "../../../assets/images/global/icon-gem.png";
import LeaderboardPositionComponent from "./leaderboard-position.component";
import {getBadgeIconSrc} from "../../../assets/lib/utils";

function TournamentsInfoFinishedState(props) {

	const getBadge = (props) => {
		if(props.leaderBoardObject){
			const src = getBadgeIconSrc(props.leaderBoardObject.rank);

			if(src){
				return (
					<img src={src} alt="badge"/>
				);
			}
		}

		return null;
	};

	const isSponsoredWithZero = props.tournamentData?.sponsored && !props.tournamentData.prizePot;

	return (
		<div className="tournaments-info-component finished-state">
			<div className="content-left">
				<div className="top-container">
				<h2>{props.challengeData && props.challengeData.title}</h2>
					<p>{props.challengeData && props.challengeData.text}</p>
				</div>

				<div className="bottom-container">
					<div className="bottom-left-container">
						{ props.rewards && !isSponsoredWithZero && <div className="gem"><img src={gemIcon} alt=""/>{props.rewards && props.rewards.currencies ? new Intl.NumberFormat('en-GB').format(props.rewards.currencies.GEMS) : 0}</div> }
						{ props.leaderBoardObject && <LeaderboardPositionComponent rank={props.leaderBoardObject?props.leaderBoardObject.rank:""}/> }
						{ props.leaderBoardObject && <div className="score">
							<span className="title">You Scored: </span>
							<span className="number">{props.leaderBoardObject?new Intl.NumberFormat('en-GB').format(props.leaderBoardObject.score):""}</span>
							<span className="label"> {props.leaderBoardObject?props.challengeData.score_label:""}</span>
						</div> }
					</div>
					<div className="trophy-container">
						<div className="badge-container">
							{
								getBadge(props)
							}
						</div>
						{!isSponsoredWithZero && <div className="trophy-icon">
							<div className="trophy-icon-score">
								<div className="label">Prize Pot</div>
								{new Intl.NumberFormat('en-GB').format(props.tournamentData.prizePot)}
							</div>
						</div>}
					</div>
				</div>
			</div>
			<div className="content-right">
				<div className="image-container">
					<Countdown startTimeStamp={props.tournamentData.startDate} endTimeStamp={props.tournamentData.endDate}/>
					<img className="tournament-image" src={props.tournamentData.detailImage} alt=""/>
				</div>
			</div>
		</div>
	)
}

export default TournamentsInfoFinishedState;