import React from 'react'
import { PulsarComponent } from '../../pulsar/pulsar.component';
import './hero-banner.component.less';

export const HeroBanner = ({children, isSelectable, width='100%', isSelected = false}) => {
    return (
        <div className={`hero-banner ${isSelectable ? "selectable" : ""} ${isSelected ? "selected": ""}`} style={{width: width}}>
            {children}
            <PulsarComponent/> 
        </div> 

    )
}

export default HeroBanner;