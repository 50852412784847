import React, { useEffect } from 'react'
import inputmanager from '../../../assets/lib/inputmanager';
import shopIcon from '../../../assets/images/shop/shop-white-glow.png';
import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import './shop-result-popup.component.less';

// Assets
import * as EpicGames from '../../../assets/lib/epic-games.lib';

const navLayer = 2;

export const getSkuItem = sku => EpicGames.SKU_DATA[sku] || {};

const ShopResultPopup = ({ title, contentText, className, gemsValue, gemsBonus, gemsDaily, sku }) => {
    if (sku) {
        className = getSkuItem(sku).className;
        gemsValue = getSkuItem(sku).gems;
        gemsDaily = getSkuItem(sku).gemsDaily;
        contentText = getSkuItem(sku).contentText || '';
    }

    useEffect(() => {
        inputmanager.setCurrentChildById("ShopResultPopup-doneButton");
    }, []);

    return (
        <div className={`shop-result-popup-component ${className ? className : ''}`}>
            <div className="bottom-illus">
            </div>
            <div className="title-text">
                { title }
                <img className="shop-icon" src={shopIcon} alt="shop icon" />
            </div>
            
            <p>
                { contentText }
            </p>
            <div className="illus">
            </div>
            {gemsValue && <div className="gem-count">
                <div className="gems-value">
                    {gemsValue}
                </div>
            </div>}
            {gemsBonus && <div className="gem-count gems-bonus">
                <span className="gems-value">
                    {gemsBonus}
                </span>
                <span className="gems-label">
                    Bonus
                </span>
            </div>}
            {gemsDaily && <div className="gem-count gems-daily">
                <span className="gems-value">
                    {gemsDaily}
                </span>
                <span className="gems-label">
                    Daily
                </span>
            </div>}
            <div className="bottom-container">
                <Button
                    className="secondary"
                    onClick={() => closePopup()}
                    childId="ShopResultPopup-doneButton"
                    layer={navLayer}
                >
                    Done
                </Button>
            </div>
        </div>
    )
}

export default ShopResultPopup
