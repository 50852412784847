import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../app.router';
import { getCurrentGameDetails, getCurrentGameDetailsId, getIsLoading, getCurrentGameSimilar } from './game-details.selector';
import { TabMenu } from '../common/tab-menu/tab-menu.component';
import { getMyCurrentActiveMenu , getSelectedChallenge} from './game-details.selector';
import './game-details.component.less';
import {FetchGameDetailed} from '../../entities/games/games.actions';
import { get, isEmpty } from 'lodash';
import { getLoggedUserAccess } from '../../app.selectors';
import { userAccessAllowed, isLowSpecDevice } from '../../app.helpers';
import { addPopup } from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import deviceInfo from '../../assets/lib/deviceInfo';
import { isFeatureControllerMappingEnabled } from '../../assets/lib/utils';
import { Route } from 'react-router-dom';
import { GameDetailsInfo } from './info/game-details-info.component';
import { GameDetailsChallenges } from './challenges/game-details-challenges.component';
import { GameDetailsLeaderboard } from './leaderboard/game-details-leaderboard.component';
import { GameDetailsMapping } from './mapping/game-details-mapping.component';
import { GameDetailsSimilar } from './similar/game-details-similar.component';

class GameDetailsComponent extends Component {
    constructor() {
        super();
        this.onMenuSelect = this.onMenuSelect.bind(this);
        this.accessForbidden = false;
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(FetchGameDetailed(this.props.currentId));
    }

    componentDidUpdate(prevProps) {
         if (this.props.item !== prevProps.item) {
            if (isEmpty(this.props.item)) return;

            if (!this.accessForbidden && !userAccessAllowed(this.props.item.access, this.props.userAccess)) {
                this.accessForbidden = true;
                addPopup(<GenericPopup
                    title="Access denied"
                    okButtonLabel="Got it!"
                    message="You do not have permission to view this game"
                    onOkClicked={() => {
                        navigateToLocation(ROUTES.HOMEPAGE);
                    }}
                />);
            }
        }
    }

    onMenuSelect(item) {
        const id = this.props.currentId;
        navigateToLocation(item.route, {id});
    }

    showSubMenus() {
        let MENU = [
            {title: "Info", route: ROUTES.GAME_INFO, index:0},
        ];

        const newMenu = MENU;
        if(this.props.hasChallenges) {
            const subMenuChal = {title: "Challenges", route: ROUTES.GAME_CHALLENGES, index:1};
            newMenu.push(subMenuChal);
        }

        if(this.props.hasLeaderBoard) { 
            newMenu.push({title: "Scores", route: ROUTES.GAME_LEADERBOARD, index:newMenu.length});
        }        
        newMenu.push({title: "Similar", route: ROUTES.GAME_SIMILAR, index:newMenu.length});

        if (isFeatureControllerMappingEnabled()) {
            newMenu.push({title: "Mapping", route: ROUTES.GAME_MAPPING, index:newMenu.length});
        }

        MENU = MENU.sort((a,b)=> {
            return a.index-b.index;
        });

        return MENU;
    }

    render() {
        if (!this.props.item || isEmpty(this.props.item)) return null;

        const animationEnabled = deviceInfo.isWebviewUltralight() ? '' : 'gameDetailsAnimationEnabled';
        const className = `game-details-component ${this.props.isLoading ? 'loading': ''} ${animationEnabled}`;

        const title = this.props.item.title;

        let extraClass = '';
        if (title.length > 42) {
            extraClass = 'very-long-text';
        } else if (title.length > 38) {
            extraClass = 'long-text';
        }

        return (
            <>
                <div className={isLowSpecDevice()?"bg-full-screen-lowspec":"bg-full-screen"}>
                    <img src={this.props.item.featured_image_uri} alt="" />
                </div>
                <section className={className}>
                    <div className="content-with-bg-full-screen">
                        <h1 className={extraClass}>{title}</h1>
                        <TabMenu items={this.showSubMenus()} onSelect={this.onMenuSelect}>
                            <>
                                <Route path={ROUTES.GAME_INFO.path} component={GameDetailsInfo} />
                                <Route path={ROUTES.GAME_CHALLENGES.path} component={GameDetailsChallenges} />
                                <Route path={ROUTES.GAME_LEADERBOARD.path} component={GameDetailsLeaderboard} />
                                <Route path={ROUTES.GAME_MAPPING.path} component={GameDetailsMapping} />
                                <Route path={ROUTES.GAME_SIMILAR.path} component={GameDetailsSimilar} />
                                {/*<Route path={ROUTES.GAME_SESSION_TYPE.path} 	 	  	component={GameSessionType} />*/}
                            </>
                        </TabMenu>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const currentGameDetails = getCurrentGameDetails(state);
    const userAccess = getLoggedUserAccess(state);
    const currentGamesTags = get(currentGameDetails, 'tags')
    const hasLeaderBoard = currentGamesTags && Array.isArray(currentGamesTags) ? currentGamesTags.includes('leaderboard') : false;

    return {
        item: currentGameDetails,
        userAccess,
        hasLeaderBoard,
        currentId: getCurrentGameDetailsId(state),
        isLoading: getIsLoading(state),
        hasChallenges: currentGameDetails?currentGameDetails.hasChallenges:false,
        hasSimilar: getCurrentGameSimilar(state).length > 0,
        currentActiveMenu : getMyCurrentActiveMenu(state),
        selectedChallenge : getSelectedChallenge(state)
    };
};

export const GameDetails = connect(mapStateToProps)(GameDetailsComponent);
