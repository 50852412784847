export const TYPE_GAME_HOW_TO_PLAY = 'gameHowToPlay';
export const TYPE_GAME_PLAY_STARTED = 'gamePlayStarted';
export const TYPE_GAME_VIEW = 'gameView';
export const TYPE_INITIAL_PURCHASE = 'initialPurchase';

export const SET_GTM_EVENT = 'SET_GTM_EVENT';
export const SetGTMEvent = (eventType,data) => {
	const event = {
		event:eventType,
		...data
	};

	// console.log("[gtm.actions.js:SetGTMEvent] ", event);

	if (window.dataLayer) {
		//console.log("[gtm.actions.js:SetGTMEvent] Pushing to dataLayer event:", event);
		window.dataLayer.push(event);
	} else {
		console.log("[gtm.actions.js:SetGTMEvent] There is no dataLayer");
	}

	return {type: SET_GTM_EVENT, event};
};
