import {getAssetsUrlWithFolder} from '../../app.selectors';
import {FOLDER_TYPES} from '../../assets/lib/FolderTypes';

export const FETCH_LOGOS_ACTION_SUCCESS = 'Fetch All Logos Success';
export const FetchLogosSuccessAction = (publisherLogos) => {
	return (dispatch, getState) => {
		publisherLogos = appendAssetsUrlToLogos(publisherLogos,getState());
		dispatch({type: FETCH_LOGOS_ACTION_SUCCESS, payload: publisherLogos});
	};
};

const appendAssetsUrlToLogos = (logoObjects, state) => {
	const appendedLogos = [];
	const publilsherIconsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.PUBLISHERS);

	logoObjects.forEach(logo => {
		appendedLogos.push({
			...logo,
			url:publilsherIconsFolder+logo.url,
		})
	});

	return appendedLogos;
};