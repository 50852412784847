import React, { Component } from 'react';
import GenericContainer, { BUTTONS_LABELS, externalFocusableComponents as genericContainerFocusable } from '../../common/generic-container/generic-container.component';
// import Slider from '../../common/slider/slider.component';
import RangeSlider from '../../common/range-slider/range-slider.component';
import TabMenuItem, {TEXT_SIZE} from '../../common/tab-menu/tab-menu-item.component';
import {BUTTON_STYLES} from '../../common/button/common-button.component';
import {configureInterface} from '../../../assets/lib/game-wrapper';
import { writeUserData, readUserData } from '../../../assets/lib/local-storage';
import inputManager,{ PARENT_ENTRANCE }  from '../../../assets/lib/inputmanager';

import './control-menu.component.less';
import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import AudioManager, {TYPE_GAMEDETAIL_PLAY_BUTTON} from '../../../app.audio';
import deviceInfo, {isDeviceIphoneX, isDeviceSafari} from "../../../assets/lib/deviceInfo";

export const externalFocusableComponents = {
    CANCEL: 'control_menu_cancel',
    RESET_TO_DEFAULT: 'control_menu_reset_to_default',
    CONFIRM: 'control_menu_confirm',
};

const dPadControllerValues = [
    {
        name: 'Floating',
        value: 'floating',
    },
    {
        name: 'Static',
        value: 'fixed'
    }
];

const vibrationValues = [
    {
        name: 'On',
        value: true
    },
    {
        name: 'Off',
        value: 'false',
    }
];

const transparencyValues = [
    {
        name: 'Low',
        value: 10,
    },
    {
        name: 'Medium',
        value: 50,
    },
    {
        name: 'High',
        value: 80,
    },
];

const deadZoneValues = [
    {
        name: 'Small',
        value: 0.33,
    },
    {
        name: 'Medium',
        value: 0.66,
    },
    {
        name: 'Large',
        value: 1,
    }
];

const volumeValues = [
    {
        name: '0',
        value: 0,
    },
    {
        name: '20',
        value: 20,
    },
    {
        name: '40',
        value: 40,
    },
    {
        name: '60',
        value: 60,
    },
    {
        name: '80',
        value: 80,
    },
    {
        name: '100',
        value: 100,
    }
];
const controlValues = [
    {
        label: 'D-Pad',
        values: dPadControllerValues,
        stateIdentifier: 'dpadPreference',
    },
    {
        label: 'Vibration',
        values: vibrationValues,
        stateIdentifier: 'rumble',
    },
    {
        label: 'Transparency',
        values: transparencyValues,
        stateIdentifier: 'transparency',
    },
    {
        label: 'D-Pad Deadzone',
        values: deadZoneValues,
        stateIdentifier: 'deadzone',
    }
];
export const defaultValues = {
    dpadPreference: dPadControllerValues && dPadControllerValues[0].value,
    rumble: true,
    transparency: 10,
    deadzone: 1,
    volume: 100,
};

export class ControlMenu extends Component {
    constructor(props) {
		super(props);
        const {controlPreferences = {}} = readUserData() || {};

		this.state = {
            dpadPreference: controlPreferences.dpadPreference || defaultValues.dpadPreference,
            rumble: typeof controlPreferences.rumble !== 'undefined' ? controlPreferences.rumble : defaultValues.rumble,
            transparency: controlPreferences.transparency || defaultValues.transparency,
            deadzone: controlPreferences.deadzone || defaultValues.deadzone,
            volume: controlPreferences.volume !== undefined ? controlPreferences.volume : defaultValues.volume,
        };

    };

    onBackClicked = (handleConfirm, controlPreferences) => {
        handleConfirm && handleConfirm();
        const state = {
            dpadPreference: controlPreferences.dpadPreference || defaultValues.dpadPreference,
            rumble: typeof controlPreferences.rumble !== 'undefined' ? controlPreferences.rumble : defaultValues.rumble,
            transparency: controlPreferences.transparency || defaultValues.transparency,
            deadzone: controlPreferences.deadzone || defaultValues.deadzone,
            volume: controlPreferences.volume !== undefined ? controlPreferences.volume : defaultValues.volume,
        };
        configureInterface(state);
        writeUserData({controlPreferences:state});
    }

    onTabContainerRef = (element, id) => {
		inputManager.onParentRef(element,`control-menu-option-tabs-${id}`, true, PARENT_ENTRANCE.ONLY_VERTICAL);
    };

    render() {
        const { handleConfirm, isXboxPlayer } = this.props;
        const {controlPreferences = {}} = readUserData() || {};
        const buttons = [
            {
                key: BUTTONS_LABELS.BACK,
                label: BUTTONS_LABELS.BACK,
                handleClick: () => this.onBackClicked(handleConfirm, controlPreferences),
                className: BUTTON_STYLES.SECONDARY, // should import button classes
                childId: externalFocusableComponents.CANCEL,
                closeContainer: true,
            },
            {
                key: 'Reset to Default',
                label: 'Reset to Default',
                handleClick: () => {
                    configureInterface(defaultValues)
                    this.setState(defaultValues)   
                },
                className: BUTTON_STYLES.SECONDARY,
                childId: externalFocusableComponents.RESET_TO_DEFAULT,
                closeContainer: false,
            },
            {
                key: BUTTONS_LABELS.CONFIRM,
                label: BUTTONS_LABELS.CONFIRM,
                handleClick: () => {
                    handleConfirm && handleConfirm()
                    const state = {
                        dpadPreference: this.state.dpadPreference,
                        rumble: this.state.rumble,
                        transparency: this.state.transparency,
                        deadzone: this.state.deadzone,
                        volume: this.state.volume,
                    }
                    configureInterface(state)
                    writeUserData({controlPreferences: state})
                },
                className: BUTTON_STYLES.PRIMARY,
                childId: externalFocusableComponents.CONFIRM,
                closeContainer: true,
            },
        ];
        const standardTabMenuProps = {
            isPlayAudio: false,
            textSize: TEXT_SIZE.MEDIUM
        };
        const isSafariIOS = (deviceInfo.isDeviceIphone() || isDeviceIphoneX()) && isDeviceSafari;

        return (
            <div className="control-menu">
                <GenericContainer 
                    title="Settings"
                    buttons={buttons}
                    focusElement={externalFocusableComponents.CONFIRM}
                    customBackFunction = {() => this.onBackClicked(handleConfirm, controlPreferences)}
                    showPopupBackground={true}
                    focusParentId={genericContainerFocusable.BUTTON_CONTAINER}
                >
                    <div className= "control-menu-options">
                        {!isXboxPlayer && controlValues.map(({label, values, stateIdentifier}) => (
                            <div 
                                className= "control-menu-option control-menu-tab-option"
                                key={stateIdentifier}
                            >
                                <label>{label}</label>
                                    <div 
                                        className={`control-menu-option-tabs control-menu-option-${stateIdentifier}`}
                                        ref={(element) => this.onTabContainerRef(element, stateIdentifier)}
                                    >
                                        {values.map(({name, value}) => {
                                            return (
                                                <TabMenuItem 
                                                    key={`${stateIdentifier}-${name}`}
                                                    isActive={this.state[stateIdentifier] === value} 
                                                    item={{title: name}}
                                                    parentId={stateIdentifier}
                                                    handleClick={() => {
                                                        configureInterface({[stateIdentifier]: value})
                                                        this.setState({[stateIdentifier]: value})
                                                    }}
                                                    {...standardTabMenuProps}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>    
                            ))}

                            {!isSafariIOS && featureInfo.isSupported(FEATURES.CONTROL_VOLUME) && <div 
                                className= "control-menu-option control-menu-tab-option"
                            >
                                <label>Volume</label>

                                <RangeSlider
                                    value={this.state.volume}
                                    min={0}
                                    max={100}
                                    step={10}
                                    handleChange={(value) => {
                                        configureInterface({volume: value})
                                        this.setState({volume: value})
                                        AudioManager.playAudio(TYPE_GAMEDETAIL_PLAY_BUTTON);
                                    }}
                                />
                            </div>}
                        {/* <Slider
                                id="volume"
                                name="volume" 
                                min="0"
                                max="100"
                                value={this.state.volume}
                                step="1"
                                handleChange={(value) => {
                                    configureInterface({volume: value})
                                    this.setState({volume: value})}
                                }
                                beforeLabel="Volume"
                                afterLabel={`${this.state.volume}%`}
                            /> */}
                            {/* <Slider
                                id="deadzone"
                                name="deadzone" 
                                min="0"
                                max="100"
                                value={this.state.deadzone}
                                step="1"
                                handleChange={(value) => {
                                    configureInterface({deadzone: value})
                                    this.setState({deadzone: value})}
                                }
                                beforeLabel="D-Pad Deadzone"
                                afterLabel={this.state.deadzone}
                            /> */}
                            
                    </div>
                </GenericContainer>
            </div>
        )
    }
}

export default ControlMenu
