import 'intersection-observer';

const handleIntersect = (entries) => {
	entries.forEach((entry) => {
		if (entry.intersectionRatio > 0) {
			if(entry.target.onEnterViewport)entry.target.onEnterViewport();
		} else {
			if(entry.target.onLeaveViewport)entry.target.onLeaveViewport();
		}
	});
};

let observer;
export const createObserver = () => {
	observer = new IntersectionObserver(handleIntersect,{
		root: null,
		rootMargin: '0px',
		// threshold:  [0, 1.0]
	});
};

export const observe = (element) => {
	observer.observe(element);
};

export const unobserve = (element) => {
	observer.unobserve(element);
};
