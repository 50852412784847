import React, { Component } from 'react';
import { Avatar } from '../../common/avatar/avatar.component';
import gemIcon from '../../../assets/images/global/gems.png';
import './message-mpc-result.component.less';
import { CHALLENGE_RESULT_TYPE } from '../../../constants';
import {SET_SELECTED_CHALLENGE_HISTORY} from '../../challenge/challenge.actions';
import { navigateToLocation, ROUTES } from '../../../app.router';
import {connect} from 'react-redux';
import {withBadWordMask} from "../../../assets/lib/utils";

class MessageMPCResultComponent extends Component {
	constructor() {
		super();
		this.onItemClick=this.onItemClick.bind(this);
	}

	onItemClick = (messageId) => {
		this.props.dispatch({ type: SET_SELECTED_CHALLENGE_HISTORY, historyItem:this.props.item.challengeMessageData });
		navigateToLocation(ROUTES.MPC_STATUS);	};

	renderWager = (result,wager, opponent) => {
		const opponentDisplayName = withBadWordMask(opponent.displayName);
		const { isFreeTierUserV2 } = this.props;

		switch(result) {
		case CHALLENGE_RESULT_TYPE.DRAW:
			return <p>It's a draw. {wager} <img src={gemIcon} alt="" /> has been returned to you.</p>;
		case CHALLENGE_RESULT_TYPE.WIN:
			if (isFreeTierUserV2) {
				return '';
			}
			return <p>You earned {wager} <img src={gemIcon} alt="" /> from {opponentDisplayName}</p>;
		case CHALLENGE_RESULT_TYPE.LOSE:
			return <p>You lost {wager} <img src={gemIcon} alt="" /> to {opponentDisplayName}</p>;
		default:
			break;
		}
	};

	render() {
		const {gameTitle, challengeTitle, challengeState, me, opponent, scoreLabel, wager, challengeId} = this.props.item.challengeMessageData;

		return (
			<div className="message-mpc-result" onClick={this.onItemClick}>
				<div className={`inner mp-challenge ${challengeState} ${me.score==='fail'?'failed':''}`}>
					<div className="gradient-top-border">
						<div className="content-wrapper">
							<div className="left">
								<p>{gameTitle}</p>
								<h3>{challengeTitle}</h3>
								<div className="number">{me.score}</div>
								<h3 className="score-label">{scoreLabel}</h3>
							</div>
							<div className="right">
								<Avatar userData={opponent}  />
							</div>
						</div>
					</div>
					<div className="result-message">
						{this.renderWager(challengeState,wager,opponent)}
					</div>
					<div className="pulsing-border"></div>
				</div>
			</div>
		);
	}
}
export const MessageMPCResult = connect()(MessageMPCResultComponent);
