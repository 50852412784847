import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { SetTutorialId } from '../../tutorial/tutorial.actions.js';
import TileUser from '../../common/tiles/tile-user.component';
import './my-profile-social.component.less';
import {PlayerSearch} from '../../search/player-search.component';
import {ButtonFacebook} from '../../common/button/common-button-with-facebook-icon.component';
import {shareWithFriendsLinkWithRedirectUri} from '../../../assets/lib/facebook.lib';
import deviceInfo, {PLATFORM_TYPE_ATARI, STORE_TYPE_SAMSUNG_TV, STORE_TYPE_APPLE_IOS} from '../../../assets/lib/deviceInfo';
import {isXboxUser} from '../../../assets/lib/utils';

const PAGE_ID = 'user_social_1';
const shareRedirectUrl = '/my-profile/social';

class MyProfileSocialComponent extends Component {
	UNSAFE_componentWillMount() {
		this.props.dispatch(SetTutorialId(PAGE_ID));
	}

	onSelectUser = (id) => {
		navigateToLocation(ROUTES.USER_PROFILE, {id});
	};

	onFacebookClicked = (redirectUri) => {
		shareWithFriendsLinkWithRedirectUri(redirectUri);
	};

	shouldDisplayShareButton = () => {
		if (deviceInfo.storeType === STORE_TYPE_SAMSUNG_TV) return;
		if (deviceInfo.platformType === PLATFORM_TYPE_ATARI) return;
		if (this.props.isXboxUser) return;
		if (deviceInfo.isPlayStationPlatform()) return;
		if (deviceInfo.storeType === STORE_TYPE_APPLE_IOS) return;

		return true;
	}

	render() {
		return (
			<div className="my-profile-social" >
				{this.shouldDisplayShareButton() && <div className="top-container">
					<div className="social-buttons-container">
						Love what Antstream are doing? Then please share us and let’s build a better retro world together.
						<ButtonFacebook
							onClick={()=>this.onFacebookClicked(shareRedirectUrl)}
							childId="facebookButton"
						>
							Share
						</ButtonFacebook>
					</div>
				</div>}

				<PlayerSearch
					itemRenderer = {(props) => <TileUser {...props} />}
					onItemSelected={this.onSelectUser}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isXboxUser: isXboxUser(state),
	};
};

export const MyProfileSocial = connect(mapStateToProps)(MyProfileSocialComponent);
