import React from 'react'
import Countdown from '../../../tournaments/info/countdown.component'
import './information-banner.component.less';
import { InView } from "react-intersection-observer";

const INFORMATION_BANNER_TYPES = {
    "TOURNAMENTS": "stickerTournament",
    "TOURNAMENTS_GROUP": "stickerTournament",
    "TOURNAMENTS_MEMBER": "stickerTournament",
    "GAME": "stickerGame",
    "CHALLENGE": "stickerChallenge",
}

export const InformationBanner = ({bannerType, imageSrc, imageAlt, startDate, endDate}) => {
    const BannerClass = INFORMATION_BANNER_TYPES[bannerType] ? INFORMATION_BANNER_TYPES[bannerType] : ""
    const [inView, setInView] = React.useState(false);

    return (
        <InView onChange={setInView} triggerOnce>
        {({ ref, inView }) => (
        <div ref={ref} className="information-banner"> 
        {inView == true &&
					<>
            <img 
                className="information-banner-image"
                src={imageSrc}
                alt={imageAlt} 
                draggable="false" // prevents browser's "ghost image" on click and drag
            />
            {startDate && endDate && <Countdown startTimeStamp={startDate} endTimeStamp={endDate}/>}
            <div className={`largeSticker ${BannerClass}`}> </div>
            </>}
        </div>
        )}
		</InView>
    );
}

export default InformationBanner;
