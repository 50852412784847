import AudioManager, {TYPE_LIST_TILE_CHANGE, TYPE_VERTICAL_LIST_ROW_CHANGE} from '../../app.audio';
import {navigateBack} from '../../app.router';
import {getWebviewState} from "../../app.selectors";
import {usePrevious} from "./customHooks";
import React, {useEffect, useRef} from 'react';
import deviceInfo from './deviceInfo';

let inputManagerInstance = null;

export const DIRECTION = {
	LEFT:1,
	RIGHT:2,
	UP:3,
	DOWN:4,
};

export const PARENT_ENTRANCE = {
	ONLY_VERTICAL:1,
	ONLY_HORIZONTAL:2,
	BOTH:3,
	VERTICAL_IN_BOTH_OUT:4,
};

const ENTRANCE_PERMISSION_LISTS = {
	VERTICAL_IN:[PARENT_ENTRANCE.ONLY_VERTICAL, PARENT_ENTRANCE.BOTH, PARENT_ENTRANCE.VERTICAL_IN_BOTH_OUT],
	VERTICAL_OUT:[PARENT_ENTRANCE.ONLY_VERTICAL, PARENT_ENTRANCE.BOTH, PARENT_ENTRANCE.VERTICAL_IN_BOTH_OUT],
	HORIZONTAL_IN:[PARENT_ENTRANCE.ONLY_HORIZONTAL, PARENT_ENTRANCE.BOTH],
	HORIZONTAL_OUT:[PARENT_ENTRANCE.ONLY_HORIZONTAL, PARENT_ENTRANCE.BOTH, PARENT_ENTRANCE.VERTICAL_IN_BOTH_OUT],
};

class InputManager {
	constructor() {
		this.layerIndex = 0;
		this.lastChildObjectOnBaseLayer = {};
		this.emitEvent = false;

		this.backFunctionUILayer = null;
		this.backFunctionPopupLayer = null;
		this.backFunctionDropdown = null;
		this.backFunctionSound = true;

		this.inputsSuspended = false;
		this.prevViewState = true;

		this.parents = {};
	}

	onStoreUpdate(store) {
		const viewState = getWebviewState(store.getState()).visible;
		if (this.prevViewState !== viewState) {
			this.prevViewState = viewState;
			if(viewState) {
				this.inputsSuspended = true;
				setTimeout(()=>{
					this.inputsSuspended = false;
				},1000);
			}
		}
	}

	/**
	 * Locks the input manager and covers the screen with an empty div to prevent mouse input.
	 * Important: does not use React to avoid more Redux mess.
	 * @param {boolean} locked 
	 */
	lockInputAndScreen(locked) {
		this.inputsSuspended = locked;
		document.getElementById('input-manager-locked').style.display = locked ? 'block' : 'none';
	}

	handleInputEvent(key) {
		if(this.inputsSuspended)return;

		if( key === 'button_1' || key==='start') {
			if(this.currentChild)this.currentChild.element.click();
			if(this.emitEvent) window.dispatchEvent(new Event('input_manager_click'));
		} else if( key === 'button_2') {
			if (this.backFunctionSound) {
				AudioManager.playAudio(TYPE_VERTICAL_LIST_ROW_CHANGE);
			}

			this.onPressButtonB();
		} else if( key === 'd_pad_left' || key === 'd_pad_right' || key === 'd_pad_up' || key === 'd_pad_down' || key === 'tab') {
			let direction = null;
			if(key === 'd_pad_left') {
				if(this.currentChild && this.currentChild.element.tagName==="INPUT")return;
				direction = DIRECTION.LEFT;
				if(this.emitEvent) window.dispatchEvent(new Event('left'));
			} else if (key === 'd_pad_right') {
				if(this.currentChild && this.currentChild.element.tagName==="INPUT")return;
				direction = DIRECTION.RIGHT;
				if(this.emitEvent) window.dispatchEvent(new Event('right'));
			} else if (key === 'd_pad_up'){
				direction = DIRECTION.UP;
				if(this.emitEvent) window.dispatchEvent(new Event('up'));
			} else if (key === 'd_pad_down') {
				direction = DIRECTION.DOWN;
				if(this.emitEvent) window.dispatchEvent(new Event('down'));
			}else if(key === 'tab') {
				direction = DIRECTION.DOWN;
			}

			if(this.currentChild && this.currentChild.areDirectionsInUse && this.currentChild.areDirectionsInUse(direction))return;

			let closestChild = this.findClosestChild(this.currentChild,direction);

			if(closestChild){
				AudioManager.playAudio(TYPE_LIST_TILE_CHANGE);
			}

			this.setCurrentChild(closestChild);
		} else if (key === 'button_3') {
			if(this.emitEvent) window.dispatchEvent(new Event('gamepad_button_3'));
		} else if (key === 'shoulder_top_left' || key === 'shoulder_top_right' || key === 'shoulder_bottom_left' || key === 'shoulder_bottom_right') {
			let direction = null;
			if (key === 'shoulder_top_left' || key === 'shoulder_bottom_left') {
				direction = DIRECTION.LEFT;
			} else if (key === 'shoulder_top_right' || key === 'shoulder_bottom_right') {
				direction = DIRECTION.RIGHT;
			}

			if (this.currentChild?.parentId === 'navBar') {
				const closestChild = this.findClosestChild(this.currentChild, direction);
				if (closestChild){
					AudioManager.playAudio(TYPE_LIST_TILE_CHANGE);
					this.setCurrentChild(closestChild);
				}
			} else {
				const navBarParent = this.getParent('navBar');
				if (navBarParent) {
					if (direction === DIRECTION.LEFT) {
						this.setCurrentChildById('home', 'navBar');
						AudioManager.playAudio(TYPE_LIST_TILE_CHANGE);
					} else if (direction === DIRECTION.RIGHT) {
						this.setCurrentChildById('settings', 'navBar');
						AudioManager.playAudio(TYPE_LIST_TILE_CHANGE);
					}
				}
			}
		} else if (key === 'select') {
			window.dispatchEvent(new Event('gamepad_select'));
		} else if (key === 'stick_button_right') {
			window.dispatchEvent(new Event('gamepad_stick_button_right'));
		}else { //letters - symbols - numbers and back_space
			if (this.currentChild && this.currentChild.element.tagName === "INPUT" && this.currentChild.onKeyboardEvent) {
				// if xbox platform and Y (triangle) button is pressed - ignore it
				if ((deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) && key === 'button_4') {
					return;
				}

				this.currentChild.onKeyboardEvent(key);
			}
		}
	}

	setLayer = (layer) => {
		if(this.layerIndex !== layer) {
			if(layer===0 && this.lastChildObjectOnBaseLayer) {
				this.layerIndex = layer;
				this.setCurrentChildById(this.lastChildObjectOnBaseLayer.childId, this.lastChildObjectOnBaseLayer.parentId);
				this.lastChildObjectOnBaseLayer = null;
			} else {
				if(this.layerIndex===0 && this.currentChild) {
					this.setLastChildObjectOnBaseLayer(this.currentChild);
				}
				this.layerIndex = layer;
			}
		}
	};

	setLastChildObjectOnBaseLayer = (child) => {
		this.lastChildObjectOnBaseLayer = this.lastChildObjectOnBaseLayer = {childId:child.id,parentId:child.parentId};
	};

	setBackFunction(callback,navLayer) {
		if(navLayer===3){
			this.backFunctionDropdown = callback;
		} else if(navLayer===2) {
			this.backFunctionPopupLayer = callback;
		} else {
			this.backFunctionUILayer = callback;
		}
	}

	setBackSound(soundState) {
		this.backFunctionSound = soundState === true;
	}

	onPressButtonB () {
		if(this.backFunctionDropdown) {
			this.backFunctionDropdown();
		} else if(this.backFunctionPopupLayer) {
			this.backFunctionPopupLayer();
		} else if(this.backFunctionUILayer) {
			this.backFunctionUILayer();
		} else {
			navigateBack();
		}
	}

	getCenterPoint = (boundingRect) => {
		const centerPoint = {x:0,y:0};
		centerPoint.x = boundingRect.left;// + (boundingRect.width / 2);
		centerPoint.y = boundingRect.top + (boundingRect.height / 2);
		return centerPoint
	};

	getDistance = (point1, point2) => {
		return Math.sqrt(Math.pow(point2.x-point1.x,2)+Math.pow(point2.y-point1.y,2))
	};

	calculateAngle = (sourceX, sourceY, targetX, targetY) => {
		const  dy = targetY - sourceY;
		const  dx = targetX - sourceX;
		let theta = Math.atan2(dy, dx); // range (-PI, PI]
		theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
		if (theta < 0) theta = 360 + theta; // range [0, 360)
		return theta;
	};

	checkParentEntrance = (currentChild , targetChild, direction) => {
		const currentParent = this.getParent(currentChild.parentId);
		const targetParent = this.getParent(targetChild.parentId);

		if(currentParent && targetParent) {
			if ((direction===DIRECTION.DOWN || direction===DIRECTION.UP)
				&& ENTRANCE_PERMISSION_LISTS.VERTICAL_OUT.includes(currentParent.parentEntrance)
				&& ENTRANCE_PERMISSION_LISTS.VERTICAL_IN.includes(targetParent.parentEntrance)) {
				return true;
			} else if((direction === DIRECTION.LEFT || direction === DIRECTION.RIGHT)
				&& ENTRANCE_PERMISSION_LISTS.HORIZONTAL_OUT.includes(currentParent.parentEntrance)
				&& ENTRANCE_PERMISSION_LISTS.HORIZONTAL_IN.includes(targetParent.parentEntrance)){
				return true;
			} else {
				return false;
			}
		}

		return true;
	};

	getChildCenter(child) {
		const parent = this.getParent(child.parentId);
		if(parent){
			return {
				x: (child.centerPoint.x - parent.hScrollPos),
				y: (child.centerPoint.y - parent.vScrollPos),
			};
		}
	}

	testDirection = (sourcePoint, targetPoint, direction) => {
		const angle = this.calculateAngle(sourcePoint.x,sourcePoint.y,targetPoint.x,targetPoint.y);

		if(direction===DIRECTION.LEFT &&  (95 < angle && angle < 265)) return true;//180
		else if(direction===DIRECTION.RIGHT && (275 < angle || angle < 85)) return true;//0
		else if(direction===DIRECTION.DOWN && (5 < angle && angle < 175)) return true;//90
		else if(direction===DIRECTION.UP && (185 < angle && angle < 355)) return true;//270

		return false;
	};

	shouldRefreshBoundingRects = (parentId) => {
		const currentParent = this.getParent(parentId);
		if (!currentParent) return false;

		const rects = [];
		let needToRefresh = false;

		Object.values(currentParent.children).forEach(child=> {
			if (needToRefresh) return;

			const targetPoint = this.getChildCenter(child);
			if (rects.find(el => el.y === targetPoint.y && el.x === targetPoint.x)) {
				needToRefresh = true;
			} else {
				rects.push({x: targetPoint.x, y: targetPoint.y});
			}
		});

		return needToRefresh;
	}

	findClosestChild = (currentChild, direction) => {
		if(!currentChild || currentChild.layer!==this.layerIndex) return this.getFirstChildOnScreen();

		let minimumDistance = 99999;
		let closestChild = null;
		const currentParent =  this.getParent(currentChild.parentId);

		if(currentParent) {//check the current parent first
			Object.values(currentParent.children).forEach(child=> {
				if(this.isChildActive(currentChild, child, direction)) {
					const sourcePoint = this.getChildCenter(currentChild);
					const targetPoint = this.getChildCenter(child);
					const distance = this.getDistance(sourcePoint,targetPoint);
					if(distance<minimumDistance) {
						minimumDistance = distance;
						closestChild = child;
					}
				}
			});
		}

		if(!closestChild){//check rest of the parents
			minimumDistance = 99999;
			let minimumDistanceByDirection = 99999;
			Object.values(this.parents).forEach((parent)=> {
				if(parent.id !== currentChild.parentId && parent.layer===this.layerIndex) {
					Object.values(parent.children).forEach(child=> {
						if(this.isChildActive(currentChild, child, direction)) {
							const sourcePoint = this.getChildCenter(currentChild);
							const targetPoint = this.getChildCenter(child);
							const distanceByDirection = this.getDistanceByDirection(sourcePoint, targetPoint, direction);
							if(distanceByDirection <= minimumDistanceByDirection) {
								if(distanceByDirection < minimumDistanceByDirection) {
									minimumDistanceByDirection = distanceByDirection;
									minimumDistance = 99999;
								}

								const distance = this.getDistance(sourcePoint, targetPoint);
								if(distance < minimumDistance) {
									closestChild = child;
									minimumDistance = distance;
								}
							}
						}
					});
				}
			});
		}

		if(currentChild && closestChild && currentChild.parentId !== closestChild.parentId) {
			const targetParent = this.getParent(closestChild.parentId);
			if(targetParent.keepCurrentChild) {
				if(targetParent.currentChild) closestChild = targetParent.currentChild;
			}
		}

		// if unexpected navigation cases detected - refresh rects
		if (currentParent.id === 'giantSlayer' && closestChild?.parentId === 'navBar') {
			this.refreshBoundingRects();
		} else if (currentParent.id === 'Trending Now' && closestChild?.parentId === 'navBar') {
			this.refreshBoundingRects();
		} else if (currentParent.id === 'navBar' && closestChild?.parentId === 'giantSlayer') {
			this.refreshBoundingRects();
		} else if (currentParent.id === 'carouselParent' && !closestChild && direction === DIRECTION.UP) {
			this.refreshBoundingRects();
		} else if (currentParent.id === 'giantSlayer' && !closestChild && (direction === DIRECTION.UP || direction === DIRECTION.DOWN)) {
			this.refreshBoundingRects();
		}

		return closestChild;
	};

	getFirstChildOnScreen = () => {
		const parents = Object.values(this.parents);
		let firstParent = null;
		let firstChild = null;
		if(parents.length>0) {
			parents.forEach(parent => {
				if(!firstParent && parent.layer===this.layerIndex){
					firstParent = parent;
				}
			});
		}

		if(!firstParent) return null;

		const childrenIds = Object.keys(firstParent.children);
		if(childrenIds.length>0) firstChild = firstParent.children[childrenIds[0]];

		return firstChild;
	};

	getDistanceByDirection = (sourcePoint, targetPoint, direction) => {
		if(direction===DIRECTION.DOWN || direction===DIRECTION.UP) {
			return Math.abs(sourcePoint.y - targetPoint.y);
		} else {
			return Math.abs(sourcePoint.x - targetPoint.x);
		}
	};

	isChildActive = (currentChild, child, direction) => {
		if(child.isActive && child !== currentChild) {
			if(child.parentId === currentChild.parentId || this.checkParentEntrance(currentChild, child, direction)){
				const sourcePoint = this.getChildCenter(currentChild);
				const targetPoint = this.getChildCenter(child);
				return this.testDirection(sourcePoint,targetPoint,direction);
			}
		}

		return false;
	};

	setCurrentChild = (targetChild) => {
		if(targetChild){
			if(this.currentChild) {
				this.currentChild.onFocus(false,targetChild.element);
			}
			this.currentChild = targetChild;
			this.currentChild.onFocus(true);

			const currentParent = this.getParent(this.currentChild.parentId);

			// console.log("setCurrentChild",targetChild,currentParent);

			if(currentParent.keepCurrentChild) {
				currentParent.currentChild = this.currentChild;
			}
		}
	};

	focusChild = (targetChild) => {
		if(targetChild)targetChild.element.focus();
	};

	getParent = (parentId) => {
		parentId = parentId.toString();
		return this.parents[parentId];
	};

	addParent = (element , parentId, keepCurrentChild = false, parentEntrance = PARENT_ENTRANCE.ONLY_VERTICAL, layer = 0) => {
		let parent = this.getParent(parentId);
		if(!parent) parent = this.createNewParent(parentId, layer);
		parent.element = element;
		parent.layer = layer;
		parent.keepCurrentChild = keepCurrentChild;
		parent.parentEntrance = parentEntrance;
		parent.boundingRect = element.getBoundingClientRect();
		parent.centerPoint = this.getCenterPoint(parent.boundingRect);

		// console.log("this.parents",this.parents);
	};

	createNewParent = (parentId, layer = 0) => {
		this.parents[parentId] = {
			id: parentId,
			element: null,
			layer,
			boundingRect: null,
			vScrollPos: 0,
			hScrollPos: 0,
			children: {},
			keepCurrentChild: false,
			currentChild: null,
			parentEntrance: this.isGeneratedParentId(parentId) ? PARENT_ENTRANCE.BOTH : PARENT_ENTRANCE.ONLY_VERTICAL,
		};

		return this.parents[parentId];
	};

	generateParentIdWithVerticalPos = (verticalPosition, layer) => {
		return "Y--"+layer+"-"+Math.floor(verticalPosition/10)*10;
	};

	findParentWithChildId = (childId) => {
		let targetParent = null;

		Object.values(this.parents).forEach((parent)=> {
			if(this.isGeneratedParentId(parent.id) && parent.children[childId]) {
				targetParent = parent;
			}
		});

		return targetParent;
	};

	addChild = (element, childId, onFocus, parentId, layer = 0, areDirectionsInUse=undefined, onKeyboardEvent=undefined) => {
		const boundingRect = element.getBoundingClientRect();
		if(typeof(parentId) === 'undefined') {
			parentId = this.generateParentIdWithVerticalPos(boundingRect.top, layer);
		}else{
			parentId = parentId.toString();
		}
		let parent = this.getParent(parentId);
		if(!parent) parent = this.createNewParent(parentId, layer);

		const newChild = {
			id: childId,
			parentId,
			element,
			layer,
			boundingRect,
			centerPoint: this.getCenterPoint(boundingRect),
			onFocus,
			onKeyboardEvent,
			isActive:true,
			areDirectionsInUse
		};
		parent.children[childId] = newChild;

		return newChild;
	};

	isGeneratedParentId = (parentId) => {
		return parentId.substr(0,3)==="Y--";
	};

	removeChild = (childId, parentId) => {
		let parent = null;
		if(typeof(parentId) === 'undefined') {
			parent = this.findParentWithChildId(childId);
		}else {
			parent = this.getParent(parentId);
		}
		if(parent) this.deleteChildOnParent(parent, childId);
		if(this.currentChild && this.currentChild.id === childId)this.currentChild = null;
	};

	deleteParent(parentId) {
		if (this.parents[parentId]) {
			delete this.parents[parentId];
		}
	}

	deleteChildOnParent(parent,childId) {
		delete parent.children[childId];
		if(parent.currentChild && parent.currentChild.id === childId){
			parent.currentChild = null;
		}
		if(this.isGeneratedParentId(parent.id) && Object.keys(parent.children).length === 0) delete this.parents[parent.id];
	}

	setCurrentChildOfParent = (parentId,childId) => {
		const parent = this.getParent(parentId);
		if(parent && parent.children) {
			const child = parent.children[childId];
			if(child){
				parent.currentChild = child;
			}
		}
	};

	setCurrentChildById = (childId, parentId) => {
		// console.log("setCurrentChildById",parentId,childId);
		// console.log("this.parents",this.parents);

		let parent = null;
		if(typeof(parentId) === 'undefined') {
			parent = this.findParentWithChildId(childId);
		} else {
			parent = this.getParent(parentId);
		}

		if(!parent) return null;

		if(parent.layer===this.layerIndex && parent.children){
			const child = parent.children[childId];
			if(child)this.setCurrentChild(child);

		}else if(parent.layer===0 && this.layerIndex!==0 && parent.children){
			const child = parent.children[childId];
			if(child)this.setLastChildObjectOnBaseLayer(child);
		}
	};

	getChildBoundingRectById = (childId, parentId) => {
		let parent = null;
		if(typeof(parentId) === 'undefined') {
			parent = this.findParentWithChildId(childId);
		} else {
			parent = this.getParent(parentId);
		}

		if(!parent) return null;

		if(parent.layer===this.layerIndex && parent.children){
			const child = parent.children[childId];
			if(child) {
				return child.boundingRect;
			} else {
				return null;
			}
		}
	};

	focusChildById = (childId, parentId) => {
		let parent = null;
		if(typeof(parentId) === 'undefined') {
			parent = this.findParentWithChildId(childId);
		} else {
			parent = this.getParent(parentId);
		}

		if(!parent) return null;

		if(parent.children){
			const child = parent.children[childId];
			if(child)this.focusChild(child);
		}
	};

	setChildActive = (childId, isActive, parentId) => {
		let parent = null;
		if(typeof(parentId) === 'undefined') {
			parent = this.findParentWithChildId(childId);
		}else {
			parent = this.getParent(parentId);
		}
		if(parent && parent.children){
			const child = parent.children[childId];
			if(child)child.isActive = isActive;
		}

		// console.log("setChildActive",parentId,childId, isActive);
	};

	setParentScrollPos = (parentId, verticalScrollPosition, horizontalScrollPosition) => {
		const parent = this.getParent(parentId);
		if(parent) {
			parent.vScrollPos = verticalScrollPosition;
			parent.hScrollPos = horizontalScrollPosition;
		}
	};

	onChildRef = (element, childId, onFocus, parentId, layer = 0, areDirectionsInUse=undefined, onKeyboardEvent=undefined) => {
		// console.log("onChildRef", childId, parentId, !!element);
		if(element) {
			this.addChild(element, childId, onFocus, parentId, layer, areDirectionsInUse, onKeyboardEvent);
		} else {
			this.removeChild(childId, parentId);
		}

	};

	onParentRef = (element, parentId, keepCurrentChild = false, parentEntrance = PARENT_ENTRANCE.ONLY_VERTICAL, layer = 0) => {
		// console.log("onParentRef", parentId, !!element);
		parentId = parentId.toString();
		if(element) {
			this.addParent(element, parentId, keepCurrentChild, parentEntrance, layer);
		} else {
			delete this.parents[parentId];
		}

		// console.log("onParentRef", "this.parents",this.parents);
	};

	refreshBoundingRects = (parentId) => {
		// console.log("refreshBoundingRects",parentId);

		let targetParents = [];

		if(parentId) {
			let targetParent = this.getParent(parentId);
			if(targetParent) targetParents.push(targetParent);
		} else {
			targetParents = Object.values(this.parents);
		}

		targetParents.forEach((parent)=> {
			Object.values(parent.children).forEach(child=> {
				if(this.isGeneratedParentId(parent.id)) {
					const keepChildSelected = this.currentChild && this.currentChild===child;
					this.removeChild(child.id, parent.id);
					const newChild = this.addChild(child.element, child.id, child.onFocus, undefined, child.layer, child.areDirectionsInUse, child.onKeyboardEvent);
					if(keepChildSelected) this.setCurrentChild(newChild);
				} else {
					child.boundingRect = child.element.getBoundingClientRect();
					child.centerPoint = this.getCenterPoint(child.boundingRect);
				}
			});
		});
	};
}

const inputManager = () => {
	if(inputManagerInstance === null ) inputManagerInstance = new InputManager();
	return inputManagerInstance;
};

export default inputManager();


/*
* childId: string => id of the child dom element
* onChildFocus: function => This callback is triggered when the element focused/unfocused.
* parentId: string =>  id of the parent dom element
* layer: number =>  active layer number of the child.
* areDirectionsInUse : true/false => this parameter is used for scrollable-box component. When we give the control to the scrolling functionaliy it should be "true".
* onKeyboardEvent : function => this is for the input.component. If you want to use all keyboard events (not only arrow keys and enter), you should listen this callback.
* */
export const useInputManagerOnChild = (childId, onChildFocus, parentId, layer, areDirectionsInUse, onKeyboardEvent) => {
	const childRef = useRef();
	const prevChildRef = usePrevious(childRef);
	useEffect(() => {
		if(childRef !== prevChildRef) {
			inputManager().onChildRef(childRef.current, childId, onChildFocus, parentId, layer , areDirectionsInUse, onKeyboardEvent);
		}
	}, [childRef]);

	useEffect(() => {
		return () => {
			inputManager().onChildRef(null, childId, onChildFocus, parentId, layer , areDirectionsInUse, onKeyboardEvent);
		};
	},[]);

	return childRef;
};

/*
* parentId: string =>  id of the parent dom element
* keepCurrentChild : true/false => keep the latest focused child even if the parent loose the focus.
* parentEntrance : string => for managing active navigation directions.
* layer: number =>  active layer number of the parent.
* */
export const useInputManagerOnParent = (parentId, keepCurrentChild , parentEntrance, layer) => {
	const parentRef = useRef();
	const prevParentRef = usePrevious(parentRef);
	useEffect(() => {
		if(parentRef !== prevParentRef) {
			inputManager().onParentRef(parentRef.current, parentId, keepCurrentChild , parentEntrance, layer);
		}
	},[parentRef]);

	useEffect(() => {
		return () => {
			inputManager().onParentRef(null, parentId, keepCurrentChild , parentEntrance, layer);
		};
	},[]);

	return parentRef;
};
