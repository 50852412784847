import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import FPSStats from 'react-fps-stats';
import { Hook, Console, Unhook } from '@nicksrandall/console-feed'

import './debugger.component.less';

const consoleSettings = {
	BASE_FONT_SIZE: '1.2em'
};

const LogsContainer = () => {
	const [logs, setLogs] = useState([])
  
	// run once!
	useEffect(() => {
	  Hook(
		window.console,
		(log) => setLogs((currLogs) => [log, ...currLogs]),
		false
	  )
	  return () => Unhook(window.console)
	}, [])

	return (
		<Console logs={logs} variant="dark" styles={consoleSettings} />
	);
}

export const DebuggerWrapper = () => {
	const consoleOpen = useSelector((state) => state.settings.consoleOpen)
	const fpsPanelOpen = useSelector((state) => state.settings.fpsPanelOpen)
	const [clickThrough, setClickThrough] = useState(false);

	const toggleClickThrough = () => {
		setClickThrough(!clickThrough);
	}

	return (
		<div className={`debug-container ${clickThrough ? 'click-through' : ''}`}>
			{
				consoleOpen &&
				<div className="debugger">
					Debug Console
					<div className="console-content">
						<LogsContainer/>
						{/* <OGConsoleComponent/> */}
					</div>
					<div className="toggle-click-through-button" onClick={toggleClickThrough}>
						Click-through console: {clickThrough ? 'ON' : 'OFF'}
					</div>
				</div>
			}
			{
				fpsPanelOpen &&
					<div className="fpsPanel">
						<FPSStats left={"45%"} />
					</div>
			}
		</div>
	);
}

export const Debugger = DebuggerWrapper
