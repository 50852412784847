import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import ProgressProvider from '../../../circular-progress-bar/progress-provider/progress-provider.component'

import './free-tier-banner-content.component.less';
import gameIcon from '../../../../../assets/images/generic-icons/game-icon.svg';
import challengesIcon from '../../../../../assets/images/generic-icons/challenges-icon.svg';
import tournamentsIcon from '../../../../../assets/images/generic-icons/tournaments-icon.svg';
import timerIcon from '../../../../../assets/images/generic-icons/timer-icon.svg';
import gameIconInactive from '../../../../../assets/images/generic-icons/game-icon-inactive.svg';
import challengesIconInactive from '../../../../../assets/images/generic-icons/challenges-icon-inactive.svg';
import tournamentsIconInactive from '../../../../../assets/images/generic-icons/tournaments-icon-inactive.svg';
import timerIconInactive from '../../../../../assets/images/generic-icons/timer-icon-inactive.svg';
import { getFreeTierChallengesInformation, getFreeTierEnergyRefilInformation, getFreeTierGamesInformation, getFreeTierTournamentsInformation } from '../../../../../app.selectors';
import { FREE_TIER_REFRESH_INFORMATION_ID } from '../../../../../constants';

const generateTimeDetails = (value) => {
    //get time in hours from millseconds
    const timeInHours = Math.floor((value / (1000 * 60 * 60)) % 24);
    return {
        text: timeInHours,
    }
}
const unlimitedKey = 'unlimited'

const generateNormalDetails = (value, limit) => {
    if(limit === unlimitedKey) return {
        text: limit,
        percentage: 0
    }
    const percentage = (((limit - value) / limit) * 100) 
    const text = limit - value;
    return {
        text,
        percentage: Math.floor(percentage)
    }
}

export const FreeTierBannerContent = ({challengesInformation, tournamentsInformation, gamesInformation, energyInformation, isPopupStyle = false }) => {
    if(!challengesInformation || !tournamentsInformation || !gamesInformation) return null
    const freeTierElementsArray = [
        {
            icon: timerIcon,
            inactiveIcon: timerIconInactive,
            alt: 'Timer Icon',
            ...energyInformation,
        },
        {
            icon: gameIcon,
            inactiveIcon: gameIconInactive,
            alt: 'Games Icon',
            circleColor: '71,215,128', //#47D780
            ...gamesInformation,
        },
        {
            icon: challengesIcon,
            inactiveIcon: challengesIconInactive,
            alt: 'Challenges Icon',
            circleColor: '255,173,41', // '#FFAD29'
            ...challengesInformation,
        },
        {
            icon: tournamentsIcon,
            inactiveIcon: tournamentsIconInactive,
            alt: 'Tournaments Icon',
            circleColor: '229,21,36', // '#E51524' 
            ...tournamentsInformation,
        },
    ]

    return (
        <div className={`free-tier-banner-content ${isPopupStyle ? 'free-tier-banner-content-popup' : 'free-tier-banner-content-normal'} `}>
            <div className='free-tier-banner-content-icon-groups'>
                {freeTierElementsArray.map(({icon, inactiveIcon, alt, value, limit, circleColor, type}) => {
                    const information = type === FREE_TIER_REFRESH_INFORMATION_ID ? generateTimeDetails(value) : generateNormalDetails(value, limit);
                    const isActive = information.percentage !== 0 || information.text === unlimitedKey;
                    const currentIcon = isActive ? icon : inactiveIcon;
                    return (
                        <Fragment key={alt}> 
                            <div className={`free-tier-banner-icon-group ${isActive ? 'active' : "inActive"}`} >
                                { type === FREE_TIER_REFRESH_INFORMATION_ID && 
                                <Fragment>
                                    <img 
                                        className='free-tier-banner-icon'
                                        src={currentIcon}
                                        alt={alt}>
                                    </img>
                                    <p className='free-tier-banner-pre-value'>
                                    energy refill in
                                    </p>
                                    <div className='free-tier-banner-detailed-wrapper'>
                                        <p className='free-tier-banner-value free-tier-banner-value-number'>
                                            {information.text}
                                        </p>
                                        <p className='free-tier-banner-value free-tier-banner-value-text'>
                                            {' hours'}
                                        </p>
                                    </div>
                                </Fragment>
                                }
                                { type !== FREE_TIER_REFRESH_INFORMATION_ID && 
                                <Fragment>
                                    <div className='free-tier-banner-icon-group-circle-wrapper'>
                                        <ProgressProvider valueStart={0} valueEnd={information.percentage}>
                                            {value => 
                                            <CircularProgressbarWithChildren
                                                value={value}
                                                strokeWidth={5}
                                                styles={buildStyles({
                                                    strokeLinecap: 'round',
                                                    pathTransition: "stroke-dashoffset 0.5s ease 0s",
                                                    pathColor: `rgba(${circleColor}, ${value / 100})`,
                                                })}
                                            >  
                                                <img 
                                                    className='free-tier-banner-icon'
                                                    src={currentIcon}
                                                    alt={alt}>
                                                </img>
                                            </CircularProgressbarWithChildren>}
                                        </ProgressProvider>
                                    </div>
                                    <p className='free-tier-banner-value'>
                                        {information.text}
                                    </p>
                                </Fragment>
                                }
                            </div>

                        </Fragment>
                    )
                })}
            </div>
            <p className='free-tier-banner-content-text'>
                You have 1 day until your energy refills. Wait for your energy to refill, or subscribe now for unlimited gaming!
            </p>
        </div> 
    )
}


const mapStateToProps = (state) => {
	return { 
        challengesInformation: getFreeTierChallengesInformation(state),
        tournamentsInformation: getFreeTierTournamentsInformation(state),
        gamesInformation: getFreeTierGamesInformation(state),
        energyInformation: getFreeTierEnergyRefilInformation(state),
	};
};

export default connect(mapStateToProps)(FreeTierBannerContent);

