import React, { Component } from 'react';
import '../settings.component.less';
import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import {Button} from '../../common/button/common-button.component';
import { connect } from 'react-redux';
import { openUrlOnDefaultBrowser } from '../../../assets/lib/game-wrapper';
import { addPopup } from '../../popup/popup.component';
import GenericPopup from '../../popup/generic-popup/generic-popup.component';
import { getCurrentDeviceName } from '../../../assets/lib/utils';

class SettingsHelpfulInfoComponent extends Component {

	openUrl = (url) => {
		if (featureInfo.isSupported(FEATURES.NATIVE_BROWSER)) {
			openUrlOnDefaultBrowser(url);
		} else {
			addPopup(<GenericPopup
				okButtonLabel="Got it!"
				title="GET OVER HERE!"
				message={`Your ${getCurrentDeviceName()} does not support this action. Please visit the Antstream website to view the Privacy Policy, Terms of Service, and Health & Safety Guidelines.`}
			/>);
		}
	}

	render() {
		return (
			<div className="settings-helpful-info">
				<ul>
					<li>
						<Button
						className="secondary"
						onClick={()=>this.openUrl("https://www.antstream.com/privacy")}
						childId="viewPrivacyPolicyButton"
						>
							View Privacy Policy
						</Button>
						<p>View the Antstream Privacy Policy</p>
					</li>
					<li>
						<Button
						className="secondary"
						onClick={()=>this.openUrl("https://www.antstream.com/terms-of-use")}
						childId="viewTermsOfUseButton"
						>
							View Terms of Use
						</Button>
						<p>View the Antstream Terms of Use</p>
					</li>
					<li>
						<Button
						className="secondary"
						onClick={()=>this.openUrl("https://www.antstream.com/health-and-safety")}
						childId="viewHealthAndSafetyGuidelinesButton"
						>
							View Health & Safety Guidelines
						</Button>
						<p>View the Antstream Health & Safety Guidelines</p>
						
					</li>
					<li>
						<Button
						className="secondary"
						onClick={()=>this.openUrl("https://www.antstream.com/licenses")}
						childId="viewLicensesButton"
						>
							View Source Licenses
						</Button>
						<p>View the Open Source Libraries register</p>
						
					</li>
				</ul>
			</div>
		);
	}
}


const mapStateToProps = () => {
	return {};
};

export const SettingsHelpfulInfo = connect(mapStateToProps)(SettingsHelpfulInfoComponent);
