import React, { Component } from 'react';
import { connect } from 'react-redux';
import './tab-menu.component.less';
import TabMenuItemComponent from './tab-menu-item.component';
import inputManager from '../../../assets/lib/inputmanager';
import { Loader } from '../loader/loader.component';
import { TAB_MENU_PARENT_ID } from '../../../constants';

const STATE_TO_RIGHT = 'toRight';
const STATE_TO_LEFT = 'toLeft';
const STATE_SAME = 'same';

const parentId = TAB_MENU_PARENT_ID;

class TabMenuComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			animationState: STATE_SAME,
			isLoading: false,
			selectedIndex: props.selectedIndex || 0,
		};

		this.loadTimer = null;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let currentItem = this.props.items.find(item => item.route === nextProps.currentRoute);

		// If it has no routing, use given index
		if (this.props.items[0] && !this.props.items[0].route) {
			return this.setState({ selectedIndex: nextProps.selectedIndex });
		}

		if (currentItem && currentItem.index !== this.state.selectedIndex) {
			this.setState({ selectedIndex: currentItem.index });
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.refreshSelectedTabIndex();
		}, 1000);
	}

	refreshSelectedTabIndex() {
		if (this.state.selectedIndex !== 0) return;

		const activeIndex = this.props.items?.findIndex(el => el?.route === this.props.currentRoute);
		if (activeIndex > 0 && activeIndex !== this.state.selectedIndex) {
			this.setState({ selectedIndex: activeIndex });
		}
	}

	componentWillUnmount() {
		if (this.loadTimer) clearTimeout(this.loadTimer);
	}

	onItemSelect = (item) => {
		if (item.index === this.state.selectedIndex) {
			return;
		}

		let animationState = STATE_SAME;

		if (item.index < this.state.selectedIndex) {
			animationState = STATE_TO_LEFT;
		} else if (item.index > this.state.selectedIndex) {
			animationState = STATE_TO_RIGHT;
		}

		this.setState({
			animationState,
			isLoading: true,
		});

		if (this.props.onSelect) {
			this.props.onSelect(item);
		}

		if (this.loadTimer) {
			clearTimeout(this.loadTimer);
		}

			this.loadTimer = setTimeout(() => {
				this.setState({
					animationState: STATE_SAME,
					isLoading: false,
					selectedIndex: item.index,
				}, () => { inputManager.refreshBoundingRects() });
			}, 800);
		}

	onContainerRef = (element) => {
		inputManager.onParentRef(element, parentId, true);
	};

	renderItems = () => {
		return this.props.items.map((item) => {
			return (
				<TabMenuItemComponent
					key={item.title}
					item={item}
					onSelect={this.onItemSelect}
					currentRoute={this.props.currentRoute}
					parentId={parentId}
					isActive={this.state.selectedIndex === item.index}/>
			)
		});
	}

	loader = () => {
		if (this.state.isLoading) {
			return (
				<div
					className={`loader-block loader-block--${this.state.animationState}`}
					key={`loading-${this.state.selectedIndex}`}>
						<Loader loading/>
				</div>
			)
		}

		return null;
	}

	render() {
		return (
			<div className="tab-menu-component">
				<div className={`item-container ${this.props.tabsClassName}`} ref={this.onContainerRef}>
					{ this.renderItems() }
				</div>
				<div className="content-container">
					{ this.loader() }
					<div className={`child-container child-container--${this.state.animationState}`}>
						{ !this.state.isLoading && this.props.children }
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { 
		currentRoute: state.routing.currentRoute,
	};
};

export const TabMenu = connect(mapStateToProps)(TabMenuComponent);
