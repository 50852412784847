// Components
import { store } from '../../configureStore';
import { getLoggedUser, getLoggedUserAccess } from '../../app.selectors';
import { userAccessAllowed }  from '../../app.helpers.js';
import Elastic, {LOG_LEVEL} from './elastic.lib';

// Assets
import DeviceInfo, {FEATURE_VERSIONS, PLATFORM_TYPE_ANDROID, PLATFORM_TYPE_ATARI, PLATFORM_TYPE_NVIDIA_SHIELD, PLATFORM_TYPE_XBOX_ONE, PLATFORM_TYPE_FIRE_TV, PLATFORM_TYPE_WIN32_X86, PLATFORM_TYPE_WIN_UWP_X64, STORE_TYPE_AMAZON} from "./deviceInfo";

let featureInfoInstance = null;

export const FEATURES = {
    CONTROL_OPTIONS:         "CONTROL_OPTIONS",
    SAVE_STATES:             "SAVE_STATES",
    CONNECTION_TESTER:       "CONNECTION_TESTER",
    SHARE_BUTTON:            "SHARE_BUTTON",
    AMAZON_CURRENCY_DISPLAY: "AMAZON_CURRENCY_DISPLAY",
    CONTROL_VOLUME:          "CONTROL_VOLUME",
    GAMEPLAY:                "GAMEPLAY",                // Deprecated (always true)
    CLOSE_APP:               "CLOSE_APP",               // Not used (old feature)
    VIDEO_ADS:               "VIDEO_ADS",
    NATIVE_BROWSER:          "NATIVE_BROWSER",
    DISCORD:                 "DISCORD",
    GOOGLE_ANALYTICS:        "GOOGLE_ANALYTICS",        // Even if supported it still might not work for external reasons
    GIANT_SLAYER:            "GIANT_SLAYER",
    WEBRTC:                  "WEBRTC",
    DEBUG_SETTINGS:          "DEBUG_SETTINGS",          // Quite the important DEV feature this. Seems to be enabled on most environments, but only on live with flags
    EPIC_GAMES_LOGIN:        "EPIC_GAMES_LOGIN",
    XBOX_LOGIN:              "XBOX_LOGIN",
    PLAYSTATION_LOGIN:       "PLAYSTATION_LOGIN",
    TV_REMOTES:              "TV_REMOTES",              // Do we have some 'game controllers' that are actually remotes (and so very limited). Windows does not support this.
};

class FeatureInfo {
    constructor() {
        // I guess I could cache the results?
    }

    isSupported(feature) {
        switch (feature) {
            // These are all controlled on a simple version
            case FEATURES.CONTROL_OPTIONS:    return DeviceInfo.versionSupportsFeature(FEATURE_VERSIONS.CONTROL_OPTIONS);
            case FEATURES.SAVE_STATES:        return DeviceInfo.versionSupportsFeature(FEATURE_VERSIONS.SAVE_STATES) && this.isSupported(FEATURES.GAMEPLAY);   // This line is recursive!
            case FEATURES.CONNECTION_TESTER:  return DeviceInfo.versionSupportsFeature(FEATURE_VERSIONS.CONNECTION_TESTER);
            case FEATURES.CONTROL_VOLUME:     return DeviceInfo.versionSupportsFeature(FEATURE_VERSIONS.CONTROL_VOLUME);

            // This was a passing fad (on iOS)
            case FEATURES.GAMEPLAY: return true;
            
            // Should probably move these out of deviceInfo
            case FEATURES.CLOSE_APP:        return DeviceInfo.isDeviceAbleToCloseTheApp();  
            case FEATURES.VIDEO_ADS:        return DeviceInfo.supportsVideoAds();
            case FEATURES.NATIVE_BROWSER:   return DeviceInfo.hasNativeBrowserSupport();
            case FEATURES.GOOGLE_ANALYTICS: return !DeviceInfo.platformBlocksGoogle();

            // More complex
            case FEATURES.AMAZON_CURRENCY_DISPLAY:  return ((DeviceInfo.storeType === STORE_TYPE_AMAZON) && DeviceInfo.versionSupportsFeature(FEATURE_VERSIONS.AMAZON_CURRENCY_DISPLAY));

            // These were features that were being locally tested for support
            case FEATURES.DISCORD: return (DeviceInfo.hasNativeBrowserSupport() && DeviceInfo.platformType !== PLATFORM_TYPE_ANDROID);

            case FEATURES.SHARE_BUTTON: return (!DeviceInfo.isWaysunDevice() && !DeviceInfo.isTrueTvDevice() && 
                                                DeviceInfo.platformType !== PLATFORM_TYPE_ATARI    && DeviceInfo.platformType !== PLATFORM_TYPE_NVIDIA_SHIELD && 
                                                DeviceInfo.platformType !== PLATFORM_TYPE_XBOX_ONE && DeviceInfo.platformType !== PLATFORM_TYPE_FIRE_TV && 
                                                DeviceInfo.versionSupportsFeature(FEATURE_VERSIONS.DEEP_LINKS) && DeviceInfo.hasNativeBrowserSupport());

            case FEATURES.GIANT_SLAYER: {
                return true;
            }
            case FEATURES.WEBRTC: {
                // Keep this in case we need to switch WebRTC off for any reason in an emergency.
                /*const WEBRTC_ACCESS_GROUP = "webrtc";    

                const userAccess = getLoggedUserAccess(store.getState());
                return userAccessAllowed(WEBRTC_ACCESS_GROUP, userAccess);*/
                return true;
            }
            case FEATURES.EPIC_GAMES_LOGIN: {
                return window.config.REACT_APP_ENV === 'dev';
            }
            
            case FEATURES.TV_REMOTES: {
                // XInput does not distinguish between remotes and gamepads. SDL does. 
                return (DeviceInfo.platformType !== PLATFORM_TYPE_WIN32_X86   &&    
                        DeviceInfo.platformType !== PLATFORM_TYPE_WIN_UWP_X64 &&
                        DeviceInfo.platformType !== PLATFORM_TYPE_XBOX_ONE); 
            }

            // Debug Settings are turned on by default on non-live environments, otherwise it needs an override.
            // P.S. Playstation users should not see Debug Settings on STG by default.
            case FEATURES.DEBUG_SETTINGS: {
                const env = window.config.REACT_APP_ENV;
                const playstationOnStg = (env === 'stg') && DeviceInfo.isPlayStationPlatform();

                if ((env !== 'live') && !playstationOnStg) {
                    return true;
                }

                const user = getLoggedUser(store.getState());
                return !!(user && user.debugEnabled);
            }
            case FEATURES.PLAYSTATION_LOGIN:
            case FEATURES.XBOX_LOGIN: {
                return window.config.REACT_APP_ENV === 'dev';
            }

            default: {
                Elastic.logDirect(LOG_LEVEL.ERROR, "Unrecognised feature requested: " + feature + ". Defaults to unsupported.");
                break;
            }
        }
        
        return false;  // No idea what you're talking about, so it's not supported
    }
}

const getFeatureInfo = () => {
    if (featureInfoInstance === null) {
        featureInfoInstance = new FeatureInfo();
    }
    return featureInfoInstance;
};

export default getFeatureInfo();
