import {antstreamAPIService, antstreamService} from '../../app.reducer';
import {getAssetsUrlWithFolder} from '../../app.selectors';
import {FOLDER_TYPES} from '../../assets/lib/FolderTypes';
import {readRestUserData} from "../../assets/lib/local-storage";
import React from "react";
import {webSocketErrorHandling} from "../../assets/lib/ErrorTypes";
import {handleNoAuthTokenIssue} from "../../assets/lib/utils";

export const UPDATE_ALL_CHALLENGES_DATA = 'UPDATE_ALL_CHALLENGES_DATA';
export const UpdateChallengesData = (challenges) => {
	return (dispatch,getState) => {
		const challengesGamesFolder = getAssetsUrlWithFolder(getState(),FOLDER_TYPES.CHALLENGE_GAMES);
		const challengesMedalsFolder = getAssetsUrlWithFolder(getState(),FOLDER_TYPES.CHALLENGE_MEDALS);

		challenges.forEach((challenge)=>{
			challenge.img_src = challengesGamesFolder + challenge.img_src;

			challenge.medals.forEach(function(medalObject){
				medalObject.img_src = challengesMedalsFolder + medalObject.img_src;
			});
		});

		dispatch({
			type: UPDATE_ALL_CHALLENGES_DATA,
			challenges
		});
	};
};

export const UNLOCK_CHALLENGE = 'Unlock Challenge Action';
export const UnlockChallengeAction = (payload, onSuccess, onFail) => {
	return (dispatch, getState) => {
		dispatch({ type: UNLOCK_CHALLENGE, payload });
		const {challengeId, gameId} = payload;

		const { authToken, userId } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('UNLOCK_CHALLENGE');
		const params = {
			body: { user_id: userId, challenge_id: challengeId, game_id: gameId },
			headers: { Authorization: 'Bearer ' + authToken }
		};
		antstreamAPIService.challenge.unlockCreate(params)
			.then(({ data }) => {
				if (!data) throw new Error('Something went wrong');
				if (data.error) {
					dispatch(UnlockChallengeActionFail(data.error));
					webSocketErrorHandling(data.error, dispatch, getState);
					return;
				}

				const {unlockedChallenges, unlockedChallengesType} = data.data;
				dispatch(FetchUnlockChallengesActionSuccess(unlockedChallenges, unlockedChallengesType));
				dispatch(UnlockChallengeActionSuccess());
				onSuccess();
			})
			.catch(catchErr => {
				onFail(catchErr.message);
				dispatch(UnlockChallengeActionFail(catchErr.message));
				console.error(catchErr.message);
			});
	};
};

export const FETCH_UNLOCK_CHALLENGES_SUCCESS = 'FETCH_UNLOCK_CHALLENGES_SUCCESS';
export const FetchUnlockChallengesActionSuccess = (unlockedChallenges,unlockedChallengesType) => {
	return (dispatch) => {
		dispatch({ type: FETCH_UNLOCK_CHALLENGES_SUCCESS, unlockedChallenges, unlockedChallengesType });
	};
};

export const UNLOCK_CHALLENGE_SUCCESS = 'Unlock Challenge Success';
const UnlockChallengeActionSuccess = () => ({
	type: UNLOCK_CHALLENGE_SUCCESS
});

export const UNLOCK_CHALLENGE_FAIL = 'Unlock Challenge Fail';
const UnlockChallengeActionFail = (payload) => ({
	type: UNLOCK_CHALLENGE_FAIL,
	payload
});
