import React, {Component} from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {Button} from '../../common/button/common-button.component';
import {ButtonWithGem} from '../../common/button/common-button-with-gem.component';
import {navigateToLocation, ROUTES} from '../../../app.router';
import {getChallengeHistoryObject} from '../challenge.selectors';
import inputManager from '../../../assets/lib/inputmanager';
import './rewards.component.less';
import {
	FetchGamechallengeHistoryAction,
	RunGameAction,
	SetSelectedChallengeAction, SetSelectedFeaturedChallengeAction
} from '../../game-details/game-details.actions';
import {getAllBadgesById, getChallengeById, getGameById} from '../../../entities/entities.selectors';
import {
	getIsChallengeLeaderBoardEnabled,
	getSelectedChallengeBestScore,
	getSelectedChallengeEarnedMedal,
	getSelectedChallengeNextMedal,
	getSelectedChallengeUserScore
} from "../../game-details/game-details.selector";
import ChallengeSummary from "../../game-details/challenge-summary/challenge-summary.component";
import {
	getChallengesLeft,
	getFreeTierChallengesInformation,
	getGemPlayChallengeCost, getGemPlayChallengeCostV2Retry,
	getIsPostAuthFetched, getSubscriptionsPopupDisplayCount, getUserIsSubscribed,
	isFreeTier
} from '../../../app.selectors';
import { unlimitedPlaysKey } from '../../../constants';
import { Loader } from '../../common/loader/loader.component';
import {
	isFeatureDailyChallengesEnabled,
	isFreeTierV2FeatureFlagEnabled,
	SUBSCRIPTIONS_POPUP_DISPLAY_COUNT_MAX,
	withBadWordMask
} from '../../../assets/lib/utils';
import { addPopup } from '../../popup/popup.component';
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import { incrementSubscriptionsPopupCount } from '../../../app.actions';
import {isLowSpecDevice} from '../../../app.helpers';
import { playDailyChallengeAction } from '../../daily-challenges/daily-challenges.actions';

const PAGE_ID = 'rewards_1';
const premiumPopupHeaderText = ['Unlock leaderboards', 'and medals', 'with premium'];

class GameDetailsRewardsComponent extends Component {
	componentDidMount() {
		const {gameId, challengeId, dailyChallengeScore} = this.props.challengeHistoryObject;
		this.props.dispatch(SetSelectedChallengeAction(challengeId));

		if (!dailyChallengeScore) {
			this.props.dispatch(FetchGamechallengeHistoryAction(gameId, null, true, challengeId));
		}

		inputManager.setCurrentChildById("retryButton");

		// show subscriptions popup only 2 times during app session (for challenge (solo, duel, giant) results pages only)
		if (this.isFreeTierV2User() && (SUBSCRIPTIONS_POPUP_DISPLAY_COUNT_MAX > this.props.subscriptionsPopupDisplayCount)) {
			this.props.dispatch(incrementSubscriptionsPopupCount());
			addPopup(<SubscriptionComparisonsPopup customClassName={'subscription-comparisons-popup-1300'} leftHeaderText={premiumPopupHeaderText} price={'£1'} pageId={PAGE_ID} />);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isGameChallengesHistoryPending && !this.props.isGameChallengesHistoryPending) {
			inputManager.setCurrentChildById("retryButton");
		}
	}

	isDailyChallenge = () => {
		return this.props.isPlayingDailyChallenge && isFeatureDailyChallengesEnabled();
	};

	exitResults = () => {
		const {gameId, challengeObject} = this.props;
		this.props.dispatch(SetSelectedFeaturedChallengeAction(challengeObject._id));
		if (this.isDailyChallenge()) {
			navigateToLocation(ROUTES.DAILY_CHALLENGES);
		} else {
			navigateToLocation(ROUTES.GAME_CHALLENGES,{id:gameId, focusElement: challengeObject._id} );
		}
	};

	retryDailyChallenge = () => {
		const { dailyChallengeEventId } = this.props;
		const { gameId, challengeId } = this.props.challengeHistoryObject;

		this.props.dispatch(RunGameAction({
			gameId: gameId,
			challengeId: challengeId,
			dailyChallengeEventId: dailyChallengeEventId,
			challengeStyle: 'intro',
		}));
		this.props.dispatch(playDailyChallengeAction(true));
		navigateToLocation(ROUTES.HOW_TO_PLAY, {
			id: gameId,
			challengeId: challengeId,
			dailyChallengeEventId: dailyChallengeEventId
		});
	};

	retry = () => {
		if (this.isDailyChallenge()) return this.retryDailyChallenge();

		const {gameId, challengeId} = this.props.challengeHistoryObject;
		this.props.dispatch(RunGameAction({
			gameId,
			challengeId,
			challengeStyle: 'intro',
			isSoloChallengeRetry: true,
		}));
		navigateToLocation(ROUTES.HOW_TO_PLAY, {id: gameId, challengeId});
	};

	onNewUser = () => {
		const {gameId} = this.props.challengeHistoryObject;

		navigateToLocation(ROUTES.HOMEPAGE, {showEventPopup: true, eventPopupGameId: gameId});
	};

	onAnotherChallenge = () => {
		navigateToLocation(ROUTES.HOMEPAGE);
	};

	isFreeTierV2User = () => {
		return this.props.isFreeTierV2FeatureEnabled && this.props.isPostAuthFetched && !this.props.isUserSubscribed;
	};

	renderLeaderboardPosition = () => {
		if (this.isDailyChallenge()) return null;

		const {isMarketingResult, marketingLeaderBoardRank, userLeaderBoardScore, leaderBoardEnabled} = this.props;
		const positionTitle = 'Current Leaderboard Position'

		if (this.isFreeTierV2User()) {
			return (
				<div className="leaderboard-position-result">
					Add your score to the leaderboard with premium
				</div>
			);
		} else {
			if (isMarketingResult) {
				return (
					<div className="leaderboard-position-result">
						{positionTitle}: {marketingLeaderBoardRank}
					</div>
				);
			} else if (userLeaderBoardScore && leaderBoardEnabled) {
				return (
					<div className="leaderboard-position-result">
						{positionTitle}: {userLeaderBoardScore.rank}
					</div>
				);
			} else {
				return null;
			}
		}
	};


	renderButtons = () => {
		const {isMarketingResult, marketingData, isFreeTierUser, challengesLeft, gemPlayChallengeCost, gemPlayChallengeCostV2Retry} = this.props;

		if (!isMarketingResult) {
			const retryCost = this.isDailyChallenge() ? '' : this.isFreeTierV2User() ? gemPlayChallengeCostV2Retry : gemPlayChallengeCost;
			return (
				<div className="buttons-container">
					<div className="marketing-buttons"/>
					<div className="other-buttons">
						<Button className="primary" onClick={this.exitResults} childId="exitResultsButton">Done</Button>
						<ButtonWithGem className="secondary" onClick={this.retry} childId="retryButton" cost={retryCost}>
							Retry Challenge
							{isFreeTierUser ? `(${challengesLeft})` : ''}
						</ButtonWithGem>
					</div>
				</div>
			);
		} else {
			return (
				<div className="buttons-container">
					<div className="marketing-buttons">
						<Button className="primary two-line" onClick={this.onNewUser} childId="newUserButton">New Player</Button>
					</div>
					<div className="other-buttons">
						<Button className="primary two-line" onClick={this.onAnotherChallenge} childId="anotherChallengeButton">
								Play Different<br/>Challenge
						</Button>
						<Button className="primary two-line" onClick={this.retry} childId="retryButton">Try Again<br/>{isMarketingResult ? withBadWordMask(marketingData.displayName) : ""}</Button>
					</div>
				</div>
			);
		}
	};

	getNextDailyChallengeMedal = () => {
		const { challengeObject, challengeHistoryObject } = this.props;

		const bestScore = this.getDailyChallengeBestScore() || challengeHistoryObject.score;

		let nextMedal = null;
		if (challengeObject.grouped === 'min') {
			nextMedal = challengeObject?.medals
				.filter(m => {
					if (!bestScore) return true;
					return Number(m.score) < Number(bestScore);
				})
				.sort((a, b) => Number(b.score) - Number(a.score))[0];
		} else {
			nextMedal = challengeObject?.medals
				.filter(m => Number(m.score) > Number(bestScore))
				.sort((a, b) => Number(a.score) - Number(b.score))[0];
		}

		return nextMedal || {};
	}

	getDailyChallengeBestScore = () => {
		const { challengeHistoryObject } = this.props;

		const bestScore = challengeHistoryObject?.dailyChallengeScore?.best?.score;
		if (!bestScore) return;

		return bestScore;
	}

	getCurrentDailyChallengeMedal = () => {
		const { challengeHistoryObject, challengeObject } = this.props;
		const bestMedalId = challengeHistoryObject?.dailyChallengeScore?.best?.medal;
		const bestMedal = challengeObject?.medals.find(m => m.medal === bestMedalId);

		return bestMedal || {};
	}

	renderSummary = () => {
		const {badges, isMarketingResult, gameId, challengeObject, challengeHistoryObject, currentBestScore, currentMedal, nextMedal, userLeaderBoardScore, leaderBoardEnabled} = this.props;

		if (this.isDailyChallenge()) {
			const currentDailyMedal = this.getCurrentDailyChallengeMedal();
			const nextDailyMedal = this.getNextDailyChallengeMedal();
			const bestScore = this.getDailyChallengeBestScore() || challengeHistoryObject?.score || 0;

			return (
				<div className="panels">
					<ChallengeSummary
						leaderBoardEnabled={false}
						badges={badges}
						gameId={gameId}
						selectedChallenge={challengeObject}
						currentBestScore={bestScore}
						currentMedal={currentDailyMedal}
						nextMedal={nextDailyMedal}
					/>
				</div>
			);
		}

		return isMarketingResult ? (
			<div className="panels">
				<div className={`rewards-container gradient-top-border ${this.props.medalEarned}`}>
					<div className="inner">
						<ul className="badges">
							<li className="bronze">
								<img className="badge" src={get(this.props, 'medals.bronze.img_src', '')} alt=""/>
							</li>
							<li className="silver">
								<img className="badge" src={get(this.props, 'medals.silver.img_src', '')} alt=""/>
							</li>
							<li className="gold">
								<img className="badge" src={get(this.props, 'medals.gold.img_src', '')} alt=""/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		) : (
			<div className="panels">
				<ChallengeSummary
					isFreeTierUser={this.isFreeTierV2User()}
					leaderBoardEnabled={leaderBoardEnabled}
					badges={badges}
					gameId={gameId}
					selectedChallenge={challengeObject}
					currentBestScore={currentBestScore}
					currentMedal={currentMedal}
					nextMedal={nextMedal}
					selectedChallengeUserScore={userLeaderBoardScore}
				/>
			</div>
		)
	};

	render() {
		const {isFail, score} = this.props.challengeHistoryObject;
		const {challengeId, title, text, score_label} = this.props.challengeObject;
		const {featured_image_uri} = this.props.gameObject;
		const {isGameChallengesHistoryPending} = this.props;

		if (isGameChallengesHistoryPending) {
			return <Loader loading={true}/>;
		}

		return (
			<div className="game-details-rewards-component">
				<div className={isLowSpecDevice()?"bg-full-screen-lowspec":"bg-full-screen"}>
					<img src={featured_image_uri} alt=""/>
				</div>

				<div className="content-with-bg-full-screen">
					<h1>{title}</h1>
					<h2 className="subtitle">{text}</h2>
					<div className="number-container">
						<div className="number">{isFail ? 'Failed' : 'You Achieved: ' + score + ' ' + score_label}</div>
					</div>
					{this.renderLeaderboardPosition()}
					{this.renderSummary()}
					{this.renderButtons()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const challengeHistoryObject = getChallengeHistoryObject(state);
	const {gameId, challengeId} = challengeHistoryObject;
	const gameObject = getGameById(state, gameId);
	const challengeObject = getChallengeById(state, challengeId);
	const medals = get(challengeObject, 'medals', []).reduce((acc, medal) => {
		acc[medal.medal] = medal;
		return acc;
	}, {});

	const userLeaderBoardScore = getSelectedChallengeUserScore(state);
	const currentMedal = getSelectedChallengeEarnedMedal(state) || {};
	const nextMedal = getSelectedChallengeNextMedal(state) || {};
	const currentBestScore = getSelectedChallengeBestScore(state);
	const leaderBoardEnabled = getIsChallengeLeaderBoardEnabled(state);

	return {
		subscriptionsPopupDisplayCount: getSubscriptionsPopupDisplayCount(state),
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		currentMedal,
		nextMedal,
		currentBestScore,
		userLeaderBoardScore,
		gameObject,
		gameId,
		challengeHistoryObject,
		challengeObject,
		medals,
		medalEarned: challengeHistoryObject.medals.length > 0 ? challengeHistoryObject.medals[0].medal : '',
		badges: getAllBadgesById(state),
		leaderBoardEnabled,
		isFreeTierUser: isFreeTier(state) && getFreeTierChallengesInformation(state).limit !== unlimitedPlaysKey, 
		challengesLeft: getChallengesLeft(state),
		gemPlayChallengeCost: getGemPlayChallengeCost(state),
		gemPlayChallengeCostV2Retry: getGemPlayChallengeCostV2Retry(state),
		isGameChallengesHistoryPending: state.gameDetails.isGameChallengesHistoryPending,
		dailyChallengeEventId: state.dailyChallenges.dailyChallengeEventId,
		isPlayingDailyChallenge: state.dailyChallenges?.playDailyChallenge,
	};
};

export const GameDetailsRewards = connect(mapStateToProps)(GameDetailsRewardsComponent);
