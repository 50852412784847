import React from 'react';
import { Avatar, AVATAR_TYPES } from '../../common/avatar/avatar.component';
import FontSizeByLength from '../../common/font-size-by-length/font-size-by-length.component';
import { getNthText, withBadWordMask } from '../../../assets/lib/utils';
import { PulsarComponent } from '../../common/pulsar/pulsar.component';
import deviceInfo, {isPlayStationPlatform} from '../../../assets/lib/deviceInfo';
import crossplayPlaystationIcon from '../../../assets/images/generic-icons/crossplay-playstation.png';
import crossplayOtherPlatformIcon from '../../../assets/images/generic-icons/crossplay-other-platform.png';

export const LeaderboardItem = (props) => {
	const { item, isLoggedUserItem, isXboxUser, isPlaystationUser, selected, onLoggedUserTileRef, isDailyChallenge } = props;
	const rankValue = item.rank;
	const isCrossplayUser = isXboxUser || isPlaystationUser;
	
	let durationString = `––:––.–––`; // default string to display when no duration exists
	if (item.secondaryScore) {
		const durationMs = item.secondaryScore;
		const minutes = Math.floor(durationMs / 60000).toString().padStart(2, '0');
		const seconds = (Math.floor(durationMs / 1000) % 60).toString().padStart(2, '0');
		const milliseconds = (durationMs % 1000).toString().padStart(3, '0');
		durationString = `${minutes}:${seconds}.${milliseconds}`;
	}
	if (isDailyChallenge) {
		durationString = 'Pts';
	}

	return (
		<div
			key={isDailyChallenge ? item.playerId : item.rank}
			ref={isLoggedUserItem ? onLoggedUserTileRef : null}
			className={`player-item ${selected ? 'selected' : ''} ${isLoggedUserItem ? 'logged-user' : ''} ${isLoggedUserItem && isCrossplayUser ? 'logged-user-crossplay' : isCrossplayUser ? 'player-item-crossplay' : ''}`}>
			<div className={`item-inner ${item.avatarType === AVATAR_TYPES.TWITCH ? 'twitch' : ''}`}>
				<span className="rankText">
					<span className="rank">{rankValue}</span>
					<span className="suffix">{getNthText(rankValue)}</span>
				</span>
				<Avatar userData={item} />
				<span className="name">
					<FontSizeByLength changePerChar={4} startAtChar={12} endAtChar={15}>
						{withBadWordMask(item.displayName)}
					</FontSizeByLength>
				</span>
				{deviceInfo.isPlayStationPlatform() && <span className="crossplay-platform">
					{<img className="icon" src={isPlaystationUser ? crossplayPlaystationIcon : crossplayOtherPlatformIcon} alt="PS" />}
				</span>}
				<span className="score">{new Intl.NumberFormat('en-GB').format(item.score)}</span>
				<span className="alternateInfos">
					&nbsp;
					<span className="timeago">{item.ts}</span>
					<span className="duration">{durationString}</span>
				</span>
				<PulsarComponent />
			</div>
		</div>
	);
};
