import {createSelector} from 'reselect';
import {getFavourites, getUnlockedChallenges, getUnlockedChallengesType} from '../../app.selectors';
import {
	getAllChallengeIds,
	getChallengesById,
	getAllGamesById,
	getAllLogosById,
} from '../../entities/entities.selectors';
import {find, get, isEmpty} from 'lodash';
import { getGiantSlayerChallenges } from '../challenge/challenge.selectors';

export const getCurrentGameDetailsId = state => state.gameDetails.currentId;
export const getCurrentGameLeaderboardObject = state => state.gameDetails.leaderboard;
export const getIsLeaderboardUpdated = state => state.gameDetails.isLeaderboardUpdated;
export const getIsChallengeOver = state => state.gameDetails.challengeOver;
export const getSetScoreSetup = state => state.gameDetails.scoreSetup;
export const getSetScoreValues = state => state.gameDetails.scoreValues;
export const getCurrentGameLeaderboardFirstUser = state => state.gameDetails.firstUser;
export const getCurrentGameSimilar = state => state.gameDetails.similar;
const getCurrentGamePlatformsIds = state => state.gameDetails.platforms;
export const getIsLoading = state => state.gameDetails.loading;
export const getSelectedChallengeId = state => state.gameDetails.selectedChallengeId;
export const getStartChallengeId = state => state.gameDetails.startChallengeId;
export const getSelectedFeaturedChallengeId = state => state.gameDetails.selectedFeaturedChallengeId;
export const getCurrentGameChallengeHistory = state => state.gameDetails.challengeHistory;
export const getCurrentGameChallengeUserScores = state => state.gameDetails.challengeScores;
export const getMyCurrentActiveMenu = state => state.gameDetails.activeMenu;
export const getGameState = state => state.gameDetails.gameState;
export const getIsUpvoted = state => state.gameDetails.isUpvoted;
export const getIsFollowGamePending = state => state.gameDetails.isFollowGamePending;
export const getTotalVotes = state => state.gameDetails.totalVotes;
export const getVoteGoal = state => state.gameDetails.voteGoal;
export const getSavedGameStates = state => state.gameDetails.savedGameStates;
export const getIsSaving = state => state.gameDetails.saving;
export const getIsSavingSuccessful = state => state.gameDetails.savingSuccessful;
export const getLastSessionObject = state => state.gameDetails.lastSessionObject;
export const getChallengeImages = state => state.gameDetails.howToPlayImages;
export const getIsRetryEnabled = state => state.gameDetails.isRetryEnabled;
export const getLoadGameSlot = state => state.gameDetails.loadGameSlot;
export const getConnectionStrength = state => state.gameDetails.connectionStrength;
export const getFailedRequests = state => state.gameDetails.failedRequests ? state.gameDetails.failedRequests : [];
export const getCancelledRequests = state => state.gameDetails.cancelledRequests ? state.gameDetails.cancelledRequests : [];

export const getGetSimilarGames = createSelector(
	getAllGamesById,
	getCurrentGameSimilar,
	(gamesById, ids) => {
		if (!gamesById || !ids) return [];
		return ids.map(id => gamesById[id]);
	}
);

export const getCurrentGameLeaderboard = createSelector(
	getCurrentGameLeaderboardObject,
	(leaderboard) => {
		if (isEmpty(leaderboard)) return null;
		return Object.values(leaderboard).sort((data1,data2)=>data1.rank<data2.rank?-1:1);
	}
);

export const getCurrentGamePlatforms = createSelector(
	getAllGamesById,
	getCurrentGamePlatformsIds,
	(gamesById, ids) => {
		if (!gamesById || !ids) return [];
		return ids.map(id => gamesById[id]);
	}
);

const getCurrentGameUnlockedChallengesMap = createSelector(
	getCurrentGameDetailsId,
	getUnlockedChallenges,
	(gameId, unlockedChallenges) => {
		if (!gameId) return {};
		const gameForUser = find(unlockedChallenges, {game_uuid: gameId});
		return get(gameForUser, 'challenge_ids', []);
	}
);

export const getCurrentGameChallenges = createSelector(
	getChallengesById,
	getAllChallengeIds,
	getCurrentGameDetailsId,
	getCurrentGameUnlockedChallengesMap,
	getUnlockedChallengesType,
	(byId, allChallengeIds, gameId, unlocked, unlockedChallengesType) => {
		const challenges = [];
		allChallengeIds.forEach(challengeId => {
			const challenge = byId[challengeId];
			if(challenge.gameId === gameId) {
				challenge.unlocked = (challenge.costs && challenge.costs.unlockCost === 0) || checkUnlockChallengesType(challenge.tier,unlockedChallengesType) || unlocked.indexOf(challenge._id) > -1;
				challenges.push(challenge);
			}
		});
		challenges.sort((item1,item2) => {
			return item1.tier>item2.tier ? 1 : -1;
		});
		return challenges;
	}
);

const UNLOCKED_CHALLENGES_TYPES = {
	TIER_2 : "TIER_2",
	TIER_3 : "TIER_3",
	ALL : "ALL"
};

const checkUnlockChallengesType = (tier,unlockedChallengesType) => {
	if(!unlockedChallengesType)return false;
	else if(unlockedChallengesType===UNLOCKED_CHALLENGES_TYPES.ALL) return true;
	else if(unlockedChallengesType===UNLOCKED_CHALLENGES_TYPES.TIER_3 && tier<=3) return true;
	else if(unlockedChallengesType===UNLOCKED_CHALLENGES_TYPES.TIER_2 && tier<=2) return true;
	else return false;
};

export const getCurrentGameDetails = createSelector(
	getAllGamesById,
	getCurrentGameDetailsId,
	getAllLogosById,
	getSavedGameStates,
	(gamesById, id, logos, savedGameStates) => {
		if (!gamesById || !id) return {};
		const game = gamesById[id];
		if (!game) {
			// eslint-disable-next-line no-console
			console.log(`game ${id} not found in gamelist`);
			return;
		}
		return {
			...game,
			publisherLogo: logos[game.studio],
			savedStates: savedGameStates[id] ? savedGameStates[id] : []
		};
	}
);

export const getIsCurrentGameFavourite = createSelector(
	getCurrentGameDetailsId,
	getFavourites,
	(id, favs) => {
		return favs.indexOf(id) > -1;
	}
);

export const getSelectedChallenge = createSelector(
	getSelectedChallengeId,
	getChallengesById,
	(id, challenges) => {
		return challenges[id] || null;
	}
);

const getSelectedChallengeHistory = createSelector(
	getSelectedChallengeId,
	getCurrentGameChallengeHistory,
	(id, history) => {
		return find(history, { 'challengeId': id });
	}
);

export const getSelectedChallengeBestScore = createSelector(
	getSelectedChallengeHistory,
	(history) => {
		return get(history, 'highScore', 0);
	}
);

export const getSelectedChallengeEarnedMedal = createSelector(
	getSelectedChallenge,
	getSelectedChallengeHistory,
	(challenge, history) => {
		let bestMedal = get(history, 'bestMedal', null);
		if(bestMedal && challenge?.medals){
			bestMedal = challenge.medals.find(medal=>bestMedal.medal===medal.medal);
			bestMedal.img_src = bestMedal.img_src.replace('.svg', '.png');
		}

		return bestMedal;
	}
);

export const getSelectedChallengeNextMedal = createSelector(
	getSelectedChallenge,
	getSelectedChallengeHistory,
	getSelectedChallengeEarnedMedal,
	(challenge, history, earned) => {
		let nextMedal = get(history, 'nextMedal', null);
		if(nextMedal && challenge?.medals){
			nextMedal = challenge.medals.find(medal=>nextMedal.medal===medal.medal);
			nextMedal.img_src = nextMedal.img_src.replace('.svg', '.png');
			return nextMedal
		}
		if (!earned) {
			nextMedal = get(challenge, 'medals', [])[0];
			if (nextMedal) {
				nextMedal.img_src = nextMedal.img_src.replace('.svg', '.png');
				return nextMedal
			}
		}
	}
);

export const getSelectedChallengeUserScore = createSelector(
	getSelectedChallengeId,
	getCurrentGameChallengeUserScores,
	(challengeId, scores) => {
		if (!scores) return {};
		return scores[challengeId];
	}
);

export const getRemainingVotes = createSelector(
	getTotalVotes,
	getVoteGoal,
	(totalVotes, voteGoal) => {
		const remaining = voteGoal - totalVotes;
		return remaining >= 0 ? remaining : 0;
	}
);

const getSelectedGameInfoId = state => state.gameDetails.selectedGameInfoId;
const getSelectedGameInfoData = createSelector(
	getAllGamesById,
	getSelectedGameInfoId,
	getAllLogosById,
	(gamesById, id, logos) => {
		if (!gamesById || !id) return {};
		const game = gamesById[id];
		if (!game) return {};
		return {...game, publisherLogo: logos[game.studio]};
	}
);
export const getSelectedGameInfo = state => getSelectedGameInfoData(state);

export const getIsChallengeLeaderBoardEnabled = createSelector(
	getSelectedChallenge,
	(challenge) => {
		return challenge ? challenge.isLeaderBoardEnabled : false;
	}
);

export const requestAlreadyFailed = (state, requestId) => {
    return !!getFailedRequests(state).includes(requestId);
};

export const requestAlreadyCancelled = (state, requestId) => {
    return !!getCancelledRequests(state).includes(requestId);
}

const getSelectedGiantSlayerInfoId = state => state.gameDetails.selectedGiantSlayerInfoId;
export const getSelectedGiantSlayerChallenge = createSelector(
	getSelectedGiantSlayerInfoId,
	getGiantSlayerChallenges,
	(giantSlayerChallengeId, giantSlayerChallenges) => {
		return giantSlayerChallenges.find(item => item.giantSlayerChallengeId === giantSlayerChallengeId);
	}
);

export const getSelectedGiantSlayerChallengeGame = createSelector(
	getAllGamesById,
	getSelectedGiantSlayerChallenge,
	(gamesById, giantSlayerChallenge) => {
		if (!gamesById || !giantSlayerChallenge) return {};
		return gamesById[giantSlayerChallenge.gameId];
	}
);
