import { antstreamAPIService } from '../../app.reducer';
import { UpdateGamesData } from '../../entities/games/games.actions';
import { getAllGamesFetched, getRecommendations } from './search.selectors';
import { readRestUserData } from '../../assets/lib/local-storage';
import { addPopup } from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import React from 'react';
import {isLowSpecDevice} from '../../app.helpers';
import {handleNoAuthTokenIssue} from "../../assets/lib/utils";

export const FETCH_SEARCH_RESULT = 'fetchSearchResult';
export const fetchSearchResult = (query,onComplete) => {
	return (dispatch) => {
		dispatch({ type: FETCH_SEARCH_RESULT, query });
        // If query has '>', it is a filter for ALL games e.g A>B, C>D etc.
        if (query.charAt(1) == ('>')) {
            query = "";
        }
        if ((query && !isLowSpecDevice()) || (query && isLowSpecDevice() && query.length>2)) {
            const { authToken } = readRestUserData() || {};
            if (!authToken) handleNoAuthTokenIssue(FETCH_SEARCH_RESULT);

            antstreamAPIService.games.searchCreate({
                body: {
                    action: 'defaultSearch',
                    searchTerm: query
                },
                headers: {Authorization: 'Bearer ' + authToken}
            })
                .then(({ data }) => {
                    if (data.error) throw new Error(data.error);
                    dispatch(fetchSearchResultSuccess({ query, ...data.data }));
                    if (onComplete) onComplete();
                })
                .catch(gameErr => {
                    addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
                    console.error(gameErr.message);
                });
        } else {
            dispatch(fetchSearchResultSuccess({ query }));
            if (onComplete) onComplete();
        }
    };
};


export const FETCH_SEARCH_RESULT_SUCCESS = 'fetchSearchResultSuccess';
export const fetchSearchResultSuccess = (payload) => {
	return (dispatch) => {
		dispatch({ type: FETCH_SEARCH_RESULT_SUCCESS, payload });
	};
};

export const FETCH_ALL_GAMES = 'FETCH_ALL_GAMES';
export const fetchAllGames = onSuccess => (dispatch, getState) => {
    if (getAllGamesFetched(getState())) {
        if (onSuccess) onSuccess();
        return;
    }

    dispatch({ type: FETCH_ALL_GAMES });

    const { authToken } = readRestUserData() || {};
    if (!authToken) handleNoAuthTokenIssue(FETCH_ALL_GAMES);

    antstreamAPIService.games
        .gamesList({
            headers: { Authorization: 'Bearer ' + authToken }
        })
        .then(({ data }) => {
            if (data.error) throw new Error(data.error);
            dispatch(fetchAllGamesSuccess(data.data.searchResults));
            if (onSuccess) onSuccess();
        })
        .catch(gameErr => {
            addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" />);
            console.error(gameErr.message);
            dispatch(fetchAllGamesError());
        });
};

export const FETCH_ALL_GAMES_SUCCESS = 'FETCH_ALL_GAMES_SUCCESS';
export const fetchAllGamesSuccess = (games) => {
	return (dispatch) => {
		dispatch(UpdateGamesData(games));
		dispatch({ type: FETCH_ALL_GAMES_SUCCESS, games });
	};
};

export const FETCH_ALL_GAMES_ERROR = 'FETCH_ALL_GAMES_ERROR';
export const fetchAllGamesError = () => {
    return dispatch => dispatch({ type: FETCH_ALL_GAMES_ERROR });
};


export const FETCH_RECOMMENDATIONS = 'FETCH_RECOMMENDATIONS';
export const fetchRecommendations = () => {
    return (dispatch, getState) => {
        if (getRecommendations(getState())) return;

        dispatch({ type: FETCH_RECOMMENDATIONS });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FETCH_RECOMMENDATIONS);

        antstreamAPIService.games
            .searchRecommendationsList({
                headers: { Authorization: 'Bearer ' + authToken }
            })
            .then(({ data }) => {
                if (data.error) throw new Error(data.error);
                dispatch(fetchRecommendationsSuccess(data.data.recommendations));
            })
            .catch(recommErr => {
                addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong" />);
                console.error(recommErr.message);
            });
    };
};

export const FETCH_RECOMMENDATIONS_SUCCESS = 'FETCH_RECOMMENDATIONS_SUCCESS';
export const fetchRecommendationsSuccess = (recommendations) => {
	return (dispatch) => {
		dispatch({ type: FETCH_RECOMMENDATIONS_SUCCESS, recommendations });
	};
};

export const SET_SEARCH_QUERY_ACTION = 'SET_SEARCH_QUERY_ACTION';
export const setSearchQueryAction = (query) => {
	return (dispatch) => {
		dispatch({ type: SET_SEARCH_QUERY_ACTION, query });
	};
};