import { FREE_TIER_GAMES_INFORMATION_ID, FREE_TIER_CHALLENGES_INFORMATION_ID, FREE_TIER_TOURNAMENT_INFORMATION_ID, FREE_TIER_REFRESH_INFORMATION_ID, unlimitedPlaysKey } from './constants';
import { createSelector } from 'reselect';
import { navigateToLocation, ROUTES } from './app.router';

export const getIsAppInitialised = state => state.app.initialised;
export const getLoggedUser = state => state.app.loggedUser;
export const getLoggedUserAccess = state => state.app.loggedUser && state.app.loggedUser.access;
export const getLoggedUserLocation = state => state.app.loggedUser?state.app.loggedUser.location:null;
export const getUserIsSubscribed = state => state.app.paymentData ? state.app.paymentData.subscription || state.app.paymentData.token : false ;
export const getIsMarketingUser = state => state.app.loggedUser ? state.app.loggedUser.marketingEventUser : false;
export const getPaymentData = state => state.app.paymentData;
export const getPaymentPlans = state => state.app.paymentPlans;
export const getPaymentSources = state => state.app.paymentSources;
export const getIsFreeTrialUsed = state => state.app.paymentData && state.app.paymentData.freeTrialUsed;
export const getSubscriptionPlans = state => state.app.subscriptionPlan;
export const getWebviewState = state => state.app.webviewState || {visible: true};
export const getWebViewDimensions = state => state.app.webViewDimensions;
export const getRunningSessionId = state => state.app.runningSessionId;
export const getRunningServerIP = state => state.app.runningServerIP;
export const getRunningServerPort = state => state.app.runningServerPort;
export const getRunningWebsocketServerPort = state => state.app.webSocketServerPort;
export const getLatestRequestId = state => state.app.latestRequestId;
export const getCurrentRegionCode = state => state.app.currentRegionCode;
export const getSubscriptionsPopupDisplayCount = state => state.app.subscriptionsPopupDisplayCount;
export const getIsNoHeader = state => {
	try {
		return state.routing.currentRoute.noHeader;
	} catch (err) {
		navigateToLocation(ROUTES.SPLASH);
		document.location.reload();
	}
};
export const getMicrosoftSubscriptionProducts = state => state.app.microsoftSubscriptionProducts;

export const getRoutingSearch = state => state.routing.search;
export const getVersion = state => state.routing.version;
export const getPreviousRoute = state => state.routing.previousRoute;
export const getGameIdFromQueryString = state => state.routing.search ? state.routing.search.gameId : null;
export const getWillShowEventPopup = state => state.routing.search ? state.routing.search.showEventPopup : null;
export const getEventPopupGameId = state => state.routing.search ? state.routing.search.eventPopupGameId : null;

export const selectedListIndex = state => state.navigation.selectedListIndex;
export const selectedTileId = state => state.navigation.selectedTileId;

export const getAssetsUrl = state => state.config.assetsUrl;
export const getFolderTypes = state => state.config.folderTypes;
export const getIsInterimScoreEnabled = state => state.config.isInterimScoreEnabled;
export const getIsTouchDevice = state => state.config.isTouchDevice;
export const getCurrentRegion = state => state.config.currentRegion;

export const getUnreadMessageCount = state => state.app.messageCount;
export const getUnlockedChallenges = state => state.app.unlockedChallenges;
export const getUnlockedChallengesType = state => state.app.unlockedChallengesType;
export const getFavourites = state => state.app.favouritedGames;
export const getThemeClass = state => state.app.themeClass;
export const getInternetConnectionStatus = state => state.app.internetConnected;
export const getRunningSessionObject = state => state.app.runningSessionObject;
export const getRunningGameConfigObject= state => state.app.runningGameConfig;
export const getRunningGameChallengeStyle= state => state.app.runningChallengeStyle;

export const getCurrencyCode = state => state.app.ipStackObject && state.app.ipStackObject.currency && state.app.ipStackObject.currency.code;
export const getGemPlayCosts = state => state.app.gemPlayCosts
export const getGemPlayGameCost = state => state.app.gemPlayCosts && state.app.gemPlayCosts.costPerGame
export const getGemPlayChallengeCost = state => state.app.gemPlayCosts && state.app.gemPlayCosts.costPerChallenge
export const getGemPlayChallengeCostV2 = state => state.app.gemPlayCosts && state.app.gemPlayCosts.costPerChallengeV2;
export const getGemPlayChallengeCostV2Retry = state => state.app.gemPlayCosts && state.app.gemPlayCosts.costPerChallengeV2Retry;
export const getGemPlayTournamentsCost = state => state.app.gemPlayCosts && state.app.gemPlayCosts.costPerTournament
export const getGemPlayChallengeFriendCost = state => state.app.gemPlayCosts && state.app.gemPlayCosts.costPerChallengeFriend
export const getGemPlayGiantSlayerRandomCost = state => state.app.gemPlayCosts && state.app.gemPlayCosts.costPerGiantSlayerRandom
export const getGemGainPerVideoWatch = state => state.app.gemPlayCosts && state.app.gemPlayCosts.gemGainPerVideoWatch
export const getGemGainPerVideoClick = state => state.app.gemPlayCosts && state.app.gemPlayCosts.gemGainPerVideoClick
export const getGemGainPerVideoSkip = state => state.app.gemPlayCosts && state.app.gemPlayCosts.gemGainPerVideoSkip
export const areVideoAdsEnabled = state => state.app.gemPlayCosts && state.app.gemPlayCosts.gemGainPerVideoWatch

export const isFreeTier = state => !!state.app.freeTierInformation;
export const getIsFreeTierPopupShown = state => state.app.isFreeTierPopupShown;
export const getIsPaymentPastDuePopupShown = state => state.app.isPaymentPastDuePopupShown;
export const getAllowGemPopup = state => state.app.allowGemPopup;
export const getFreeTierInformation = state => state.app.freeTierInformation;
export const getFreeTierEnergyRefilInformation = state => state.app.freeTierInformation && state.app.freeTierInformation[FREE_TIER_REFRESH_INFORMATION_ID];
export const getFreeTierGamesInformation = state => state.app.freeTierInformation && state.app.freeTierInformation[FREE_TIER_GAMES_INFORMATION_ID];
export const getFreeTierChallengesInformation = state => state.app.freeTierInformation && state.app.freeTierInformation[FREE_TIER_CHALLENGES_INFORMATION_ID];
export const getFreeTierTournamentsInformation = state => state.app.freeTierInformation && state.app.freeTierInformation[FREE_TIER_TOURNAMENT_INFORMATION_ID];
export const getEnergyRefilTime = state => state.app.freeTierInformation && state.app.freeTierInformation[FREE_TIER_REFRESH_INFORMATION_ID].value;
export const getGamesLeft = state => {
	const freeTierGameInformation = state.app.freeTierInformation && state.app.freeTierInformation[FREE_TIER_GAMES_INFORMATION_ID];
	return freeTierGameInformation && freeTierGameInformation.limit - freeTierGameInformation.value
}
export const getIsPostAuthFetched = state => state.app.isPostAuthenticationRequestFetched;

export const isFreeTierBannerNeeded = state => {
	const challengesInformation = getFreeTierChallengesInformation(state)
	const gamesInformation = getFreeTierGamesInformation(state)
	const tournamentsInformation = getFreeTierTournamentsInformation(state)
	return (isFreeTier(state)) && (challengesInformation.limit !== unlimitedPlaysKey || tournamentsInformation.limit !== unlimitedPlaysKey || gamesInformation.limit !== unlimitedPlaysKey)
}

export const getChallengesLeft = state => {
	const freeTierChallengeInformation = state.app.freeTierInformation && state.app.freeTierInformation[FREE_TIER_CHALLENGES_INFORMATION_ID];
	return freeTierChallengeInformation && freeTierChallengeInformation.limit - freeTierChallengeInformation.value
}

export const getTournamentsLeft = state => {
	const freeTierTournamentInformation = state.app.freeTierInformation && state.app.freeTierInformation[FREE_TIER_TOURNAMENT_INFORMATION_ID];
	return freeTierTournamentInformation && freeTierTournamentInformation.limit - freeTierTournamentInformation.value
}

export const getIsUserInGermany = state => {
	const location  = getLoggedUserLocation(state);
	return location && location.country==="DE";
	// return location && location.country==="GB";
};

export const getAssetsUrlWithFolder = (state,folderType) => {
	const assetsUrl  = getAssetsUrl(state);
	const folderTypes  = getFolderTypes(state);

	return assetsUrl+folderTypes[folderType]["folder"];
};

const supportedCurrencies = ['gbp','usd','eur'];
export const getPaymentPlansByCurrency = createSelector(
	getPaymentPlans,
	getCurrencyCode,
	(plans, currency) => {
		if (!currency) return plans;

		let currentCurrency = 'usd';
		if (supportedCurrencies.includes(currency.toLowerCase())) {
			currentCurrency = currency.toLowerCase();
		}

		const filteredPlans = plans?.filter(plan => plan.currency === currentCurrency);

		// if something is wrong - return ALL plans
		if (!filteredPlans?.length) return plans; 

		return filteredPlans;
	}
);
