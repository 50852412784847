import React, {Component} from 'react';

// Components
import { ROUTES } from '../../app.router';
import * as Router from '../../app.router';
import { Button } from '../common/button/common-button.component';
import { Loader } from '../common/loader/loader.component';
import { messageNativeXboxRetryLogin } from '../../assets/lib/game-wrapper';
import Elastic, { LOG_LEVEL } from '../../assets/lib/elastic.lib';

// Assets
import logoConsole from '../../assets/images/splash/Antstream_Logo_White_Long.png';
import InputManager from '../../assets/lib/inputmanager';
import DeviceInfo, { PLATFORM_TYPE_ATARI, OS_TYPE_ANDROID, STORE_TYPE_SAMSUNG_TV } from '../../assets/lib/deviceInfo';
import * as GameWrapper from '../../assets/lib/game-wrapper';

// CSS
import './login-global.less';

class LoginComponent extends Component {
	constructor(props) {
		super(props);

		this.xboxRetryLoginTimer = null;
		this.playstationRetryLoginTimer = null;
	}

	componentDidMount() {
		if (this.isXboxPlatformAndClientV2()) {
			// fake xbox login message
			// setTimeout(() => {
			// 	GameWrapper.messageFromNative(JSON.stringify(fakeResponse));
			// }, 10000);

			// if no login from native in about 1 minute - send retry login message to native
			const RETRY_TIMEOUT = 60 * 1000;
			this.xboxRetryLoginTimer = setTimeout(() => {
				const logText = DeviceInfo.isPlayStationPlatform() ? 'PLAYSTATION_RETRY_LOGIN' : 'XBOX_RETRY_LOGIN';
				console.log(`${logText} after 1 minute waiting`);
				messageNativeXboxRetryLogin();
				Elastic.logDirect(LOG_LEVEL.ERROR, `${logText} after 1 minutes waiting`);
			}, RETRY_TIMEOUT);

			return;
		}

		if (DeviceInfo.isPlayStationPlatform()) {
			this.startPlaystationResendTimer();
			return;
		}

		InputManager.setCurrentChildById("loginButton");
		InputManager.setBackFunction(()=>{
			if (DeviceInfo.osType === OS_TYPE_ANDROID ||  DeviceInfo.storeType == STORE_TYPE_SAMSUNG_TV) {
				GameWrapper.quitApp(); // Android defined behaviour if you go back from the first app screen
			}			
		});
	}

	startPlaystationResendTimer = (timeoutMs, attempt = 0) => {
		const DEFAULT_TIMEOUT_MS = 30 * 1000;
		if (this.playstationRetryLoginTimer) {
			clearTimeout(this.playstationRetryLoginTimer);
		}

		const nextAttempt = attempt + 1;

		/** STOP retrying if 3 attempts**/
		if (nextAttempt > 3) {
			Elastic.logDirect(LOG_LEVEL.ERROR, `[PLAYSTATION_RETRY_LOGIN] fatal error. Login does not working after 3 attempts.`);
			return;
		}

		let nextTimeoutMs = DEFAULT_TIMEOUT_MS;
		if (timeoutMs) {
			nextTimeoutMs = timeoutMs + DEFAULT_TIMEOUT_MS;
		}

		this.playstationRetryLoginTimer = setTimeout(() => {
			messageNativeXboxRetryLogin();

			const logText = `[PLAYSTATION_RETRY_LOGIN] Attempt: ${nextAttempt}. Timeout: ${Math.round(nextTimeoutMs/1000)} sec.`;
			console.log(logText);
			Elastic.logDirect(LOG_LEVEL.ERROR, logText);

			this.startPlaystationResendTimer(nextTimeoutMs, nextAttempt);
		}, nextTimeoutMs);
	};

	isXboxPlatformAndClientV2() {
		return DeviceInfo.isXboxPlatform();
	}

	isPlatformFreeTrial() {
		return DeviceInfo.isDeviceIphone();
	}

	componentWillUnmount() {
		InputManager.setBackFunction(null);
		if (this.xboxRetryLoginTimer) {
			clearTimeout(this.xboxRetryLoginTimer);
		}

		if (this.playstationRetryLoginTimer) {
			clearTimeout(this.playstationRetryLoginTimer);
			this.playstationRetryLoginTimer = null;
		}
	}

	onSignUp = () => {
		if(DeviceInfo.platformType === PLATFORM_TYPE_ATARI) {
			Router.navigateToLocation(ROUTES.SIGNUP_LINK);
		} else {
			Router.navigateToLocation(ROUTES.SIGNUP);
		}
	};

	onLogin = () => {
		Router.navigateToLocation(ROUTES.LOGIN_CREDENTIALS);
	};

	render () {
		if (this.isXboxPlatformAndClientV2() || DeviceInfo.isPlayStationPlatform()) {
			return(
				<div className="login-component login-wrapper">
					<section className="login-section login-section--animate">
						<div className="logo-container">
							<img src={logoConsole} alt="logo" />
						</div>
						<Loader loading={true}/>
					</section>
				</div>
			);
		}

		return (
			<div className="login-component login-wrapper login-first login-wrapper--normal">
				<div className='top-text'>
					<span className='top-text__item top-text__item--first'>
						Join a global community of retro gamers
					</span>
					<span className='top-text__item'>
						Stream and play over 1300 retro games,
					</span>
					<span className='top-text__item'>
						over 600 mini game challenges,
					</span>
					<span className='top-text__item'>
						global tournaments, leaderboards,
					</span>
					<span className='top-text__item'>
						achievements and more...
					</span>
				</div>
				<section className="login-section login-section--animate login-section--firstscreen">
					<Button
						className="primary small-button"
						onClick={this.onLogin}
						childId="loginButton"
					>
						Sign In
					</Button>
					<Button
						className="secondary small-button"
						onClick={this.onSignUp}
						childId="createAccountButton"
					>
						{this.isPlatformFreeTrial() ? 'Start Free Trial' : 'Join'}
					</Button>
				</section>
				<div className='bottom-text'>
					<span className='bottom-text__item bottom-text__item--first'>
						Subscribe Today
					</span>
					<span className='bottom-text__item'>
						Antstream is a cloud streaming service.
					</span>
					<span className='bottom-text__item'>
					Your experience may vary depending on the stability and speed of your connection.
					</span>
				</div>
				<div className='footer-text'>
					<span className='footer-text__item'>
						new games & tournaments every week
					</span>
				</div>
			</div>
		);
	}
}

export const Login = LoginComponent;