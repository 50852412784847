// Packages
import React,{Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import ButtonEpicGames from '../common/button/common-button-with-epic-games-icon.component';

// Assets
import { ROUTES } from '../../app.router';
import * as GameWrapper from '../../assets/lib/game-wrapper';
import * as EpicGames from '../../assets/lib/epic-games.lib';

class EpicGamesLoginComponent extends Component {
    constructor() {
        super();
        this.state = { authenticationInterval: null }
    }

    /**
	 * Attempts to log the user in with Epic Games OAuth.
	 * If button is disabled, stops.
	 */
    login = () => {
		if (this.props.disabled) {
			this.props.onClickDisabled && this.props.onClickDisabled();
			return;
		}

		// if (!DeviceInfo.hasNativeBrowserSupport()) {
		// 	GameWrapper.releaseControllers();
		// 	GameWrapper.setBackOverride();
		// }

		// EpicGames.login(ROUTES.AUTH_ACCESS_TOKEN, DeviceInfo.hasNativeBrowserSupport());

		GameWrapper.releaseControllers();
		GameWrapper.setBackOverride();
		EpicGames.login(ROUTES.AUTH_ACCESS_TOKEN, false);
	};

	render() {
		return (
			<ButtonEpicGames onClick={this.login} childId="loginWithEpicGamesButton" inverted>
				Continue with Epic Games
			</ButtonEpicGames>
		);
	}
};

EpicGamesLoginComponent.propTypes = {
	disabled: PropTypes.bool,
	onClickDisabled:PropTypes.func,
};

export default connect()(EpicGamesLoginComponent);
