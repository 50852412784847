import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import './challenge-animation.component.less'
import AudioManager, {
    TYPE_CHALLENGE_OVER,
    TYPE_FINAL_PLAY_BUTTON,
    TYPE_GO,
} from '../../../app.audio';
import {isLowSpecDevice} from '../../../app.helpers';

const ChallengeAnimation = (props) => {

    const textRef = useRef();

    useEffect(() => {
        if (props.challengeAnim === 'start') {
            startAnimation();
        }
        if (props.challengeAnim === 'end') {
            endAnimation();
        }
    }, [props.challengeAnim]);

    const startAnimation = () => {
        const elt = textRef.current;
        const tl = gsap.timeline();

        tl.to(elt, 0.1, { scale: 1 });

        for (let index = 3; index > 0; index--) {
            tl.call(() => {
                AudioManager.playAudio(TYPE_FINAL_PLAY_BUTTON);
            });
            tl.set(elt, { innerHTML: index, scale: 1, opacity: 1 });
            tl.fromTo(elt, 0.7,
                { top: '0%' },
                { top: '40%', ease: 'bounce.easeOut' }
            );
            if (isLowSpecDevice()) {
                tl.to(elt, 0.3, { opacity: 0, ease: 'ease.easeOut', scale: 1 });
            }
            else {
                tl.to(elt, 0.3, { scale: 2, opacity: 0, ease: 'ease.easeOut' });
            }
        }

        tl.call(() => {
            AudioManager.playAudio(TYPE_GO);
        });
        tl.set(elt, { innerHTML: 'GO', className: 'text-content colorLoop', scale: 1, opacity: 1 });
        if (isLowSpecDevice()) {

            tl.fromTo(elt, 0.3,
                { top: '0%' },
                { top: '40%', ease: 'ease.easeIn' }
            );
            tl.to(elt, 0.3, { opacity: 0, delay: 2, ease: 'ease.easeOut' });
        }
        else {
            tl.fromTo(elt, 0.3,
                { top: '0%', scale: 2 },
                { top: '40%', scale: 1, ease: 'ease.easeIn' }
            );
            tl.to(elt, 0.3, { scale: 2, opacity: 0, delay: 2, ease: 'ease.easeOut' });
        }
        tl.call(countdownReachZero);

    }

    const endAnimation = () => {
        const elt = textRef.current;
        const tl = gsap.timeline();

        tl.call(() => {
            AudioManager.playAudio(TYPE_CHALLENGE_OVER);
        });
        tl.set(elt, {innerHTML: 'CHALLENGE OVER', className: 'text-content colorLoop endText', scale: 1, opacity: 1});
        tl.fromTo(elt, 0.6,
            {top: '0%', scale: 2},
            {top: '30%', scale: 1, ease: 'ease.easeIn'}
        );
        tl.to(elt, 0.3, {scale: 2, opacity: 0, delay: 3, ease: 'ease.easeOut'});
        tl.call(endAnimationFinish);
    }

    const countdownReachZero = () => {
        props.onChallengeStartAnimFinish();
    }

    const endAnimationFinish = () => {
        props.onChallengeEndAnimFinish();
    }

    return (
        <div className="challenge-animation">
            <div className="text-content" ref={textRef}>0</div>
        </div>
    )
}
export default ChallengeAnimation;
