
import React,{Component} from 'react';
import { store as reduxStore } from '../../../configureStore';
import { getLoggedUser } from '../../../app.selectors';
import Elastic from '../../../assets/lib/elastic.lib';
import { Button } from '../button/common-button.component';
import deviceInfo from '../../../assets/lib/deviceInfo';
import {readRestUserData} from '../../../assets/lib/local-storage';
import {messageNativeXboxRetryLogin} from '../../../assets/lib/game-wrapper';
import {navigateToLocation, ROUTES} from '../../../app.router';
import inputManager from '../../../assets/lib/inputmanager';

import heroImage from '../../../assets/images/global/error_title.svg'
import backgroundImage from '../../../assets/images/global/error_arcade_graphic.png'

import './error-boundary.component.less'

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.messageCount = 0;

		this.state = {
			hasError: false,
			error: undefined
		};

		if (!('toJSON' in Error.prototype))

			Object.defineProperty(Error.prototype, 'toJSON', {
				value: function () {
					var alt = {};

					Object.getOwnPropertyNames(this).forEach(function (key) {
						alt[key] = this[key];
					}, this);

					return alt;
				},
				configurable: true,
				writable: true
			});
		window.addEventListener('error', this.onJSError);
	}
	componentDidMount () {
		if (!inputManager.currentChild) {
			inputManager.setCurrentChildById('reconnectButton');
		}
	}
	componentWillUnmount() {
		window.removeEventListener('error', this.onJSError);
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error: error};
	}

	componentDidCatch(error, errorInfo) {
		if(this.messageCount>1)return;
		this.messageCount++;
		Elastic.logUnHandledError({error,errorInfo});

		// if authToken error happened on xbox platform - restart the app automatically 
		if (deviceInfo.isXboxPlatform() && error && error.toString().includes('authToken')) {
			console.log('restartTheApp');
			this.restartTheApp();
		}

		// if authToken error happened on ps platform - restart the app automatically 
		if (deviceInfo.isPlayStationPlatform() && error && error.toString().includes('authToken')) {
			console.log('restartTheApp');
			this.restartTheApp();
		}
	}

	onJSError = (event) => {
		if(this.messageCount>1)return;
		this.messageCount++;
		Elastic.logUnHandledError(event.error);
	};

	restartTheApp() {
		if (deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) {
			// check if we have accessToken in localstorage
			// if not - ask the native to retry login
			const restUserData = readRestUserData();
			if (!restUserData) {
				navigateToLocation(ROUTES.LOGIN);
				messageNativeXboxRetryLogin();
			}
		}

		window.location.href = window.config.REACT_APP_URL;
	}

	render() {
		const {hasError, error} = this.state;
		const {children} = this.props;
		const loggedUser = getLoggedUser(reduxStore.getState());
		
		if(hasError) {
			return (
				<div className="error-boundary">
					<div className="error-boundary-hero-content">
						<img className="error-boundary-hero-image" src={heroImage} alt="" />
						<p className="error-boundary-hero-text">"We can’t seem to find the arcade...try restarting, or contact support if you’re still lost!"</p>
					</div>
					<img className="error-boundary-image" src={backgroundImage} alt=""/>
					<div className="error-boundary-content">
						<div className="error-boundary-button">
							<Button 
								className="primary"
								onClick={this.restartTheApp}
								childId="reconnectButton"
							>
								Restart App
							</Button>
						</div>
						<div className="error-boundary-information">
							<div className="error-boundary-information-row">
								<p className="error-boundary-information-row-key">Error:</p>
								<p>{error && error.toString()}</p>
							</div>
							<div className="error-boundary-information-row">
								<p className="error-boundary-information-row-key">User ID:</p>
								{loggedUser && loggedUser._id && <p>{loggedUser._id}</p>}
							</div>
						</div>
					</div>
				</div>
			);
		}
		return children;
	}
}

export default ErrorBoundary;