import React,{Component} from 'react';
import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import inputManager from '../../../assets/lib/inputmanager';
import './new-content-popup.component.less';

const navLayer=2;

class NewContentPopup extends Component {
	componentDidMount() {
		inputManager.setCurrentChildById("NewContentPopup-okButton");
		inputManager.setBackFunction(()=>{closePopup()},navLayer);
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null,navLayer);
	}

	onOkButtonClicked = () => {
		if(this.props.onOkClicked)this.props.onOkClicked();
		closePopup();
	};

	render() {
		return (
			<div className="new-content-popup-component">
				<div className="title-text">
					{this.props.title}
				</div>
				<div className="img-Container">
				{this.props.imgSrc && <img className="new-content-img" src={this.props.imgSrc} alt="new-content"/>}
				<div className="message-text">
					{this.props.message}
				</div>
				</div>
				<div className="buttons-container">
					<Button
						className="primary"
						onClick={this.onOkButtonClicked}
						childId="NewContentPopup-okButton"
						layer={navLayer}
					>
						Got It!
					</Button>
				</div>
			</div>
		);
	}
};

export default NewContentPopup;
