import { fromJS } from 'immutable';
import { normalize, schema } from 'normalizr';

import { FETCH_AVATARS_ACTION_SUCCESS } from './avatars.actions';

const initialState = fromJS({});

export const avatarsReducer = (state = initialState, action) => {
	switch (action.type) {
	case FETCH_AVATARS_ACTION_SUCCESS:
		const normalizedData = avatarNormalizer(action.payload);
		const byId = { ...state.byId, ...normalizedData.entities.avatars };
		const allIds = Object.keys(byId);
		return { byId, allIds };
		
	default:
		return state;
	}
};

export const avatarNormalizer = (prenormalData) => {
	const cschema = new schema.Entity('avatars', undefined, { idAttribute: 'type' });
	return normalize(prenormalData, [ cschema ]);
};
