import { connect } from 'react-redux';
import React, { Component } from 'react';
import ListContainer, { LIST_TYPES } from '../common/list/list-container.component';

import {FetchMyAchievementsAction} from '../../entities/users/users.actions';
import {SetTutorialId} from '../tutorial/tutorial.actions.js';
import AchievementTile from '../common/tiles/tile-achievement.component';
import './achievements.component.less';
import inputManager, {PARENT_ENTRANCE} from '../../assets/lib/inputmanager';
import {navigateToLocation, ROUTES} from '../../app.router';
import TileTournamentHistory from "../common/tiles/tile-tournament-history.component";
import {externalFocusableComponent} from "../leaderboard/leaderboard.component";
import {getIsPostAuthFetched, getUserIsSubscribed} from "../../app.selectors";
import {addPopup} from "../popup/popup.component";
import {isFreeTierV2FeatureFlagEnabled, isXboxUser} from '../../assets/lib/utils';
import SubscriptionComparisonsPopup from "../popup/subscription-comparisons-popup/subscription-comparisons-popup.component";
import { getTournamentHistory } from '../tournaments/tournaments.selector';
import deviceInfo, { STORE_TYPE_APPLE_IOS } from '../../assets/lib/deviceInfo';

const PAGE_ID = 'user_achievements_1';

class AchievementsComponent extends Component {
	constructor(props){
		super(props);

		this.state = {
			lists:[],
			tournamentHistoryTitle: deviceInfo.isPlayStationPlatform() ? 'Tournament Trophies' : 'Tournament Achievements',
			leftHeaderText: ['Earn', deviceInfo.isPlayStationPlatform() ? 'trophies' : 'achievements', 'with premium']
		};
	}

	componentDidMount() {
		this.props.dispatch(FetchMyAchievementsAction(this.onListsFetched));
		this.props.dispatch(SetTutorialId(PAGE_ID));

		inputManager.setBackFunction(()=>{navigateToLocation(ROUTES.HOMEPAGE)});

		const { isFreeTierV2FeatureEnabled, isPostAuthFetched, isUserSubscribed } = this.props;
		if (isFreeTierV2FeatureEnabled && isPostAuthFetched && !isUserSubscribed && (deviceInfo.storeType !== STORE_TYPE_APPLE_IOS)) {
			this.showSubscriptionsComparisonPremiumPopup();
		}
	}

	componentDidUpdate(prevProps) {
		// for the case if /post-login request not fetched yet
		const { isFreeTierV2FeatureEnabled, isPostAuthFetched, isUserSubscribed } = this.props;
		if (isFreeTierV2FeatureEnabled && (prevProps.isPostAuthFetched !== isPostAuthFetched) && !isUserSubscribed && (deviceInfo.storeType !== STORE_TYPE_APPLE_IOS)){
			this.showSubscriptionsComparisonPremiumPopup();
		}
	}

	showSubscriptionsComparisonPremiumPopup() {
		addPopup(<SubscriptionComparisonsPopup leftHeaderText={this.state.leftHeaderText} price={'£1'} pageId={PAGE_ID}/>);
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null);
	}

	onListsFetched = (data) => {
		const { achievements, tournamentHistory } = data; 
		const { isXboxPlayer } = this.props;
		const defaultValuesForList = {
			type: LIST_TYPES.GRID,
			renderer: (props) => {
				if(props.item.tournamentObject){
					return <TileTournamentHistory {...props} />;
				}else{
					return <AchievementTile {...props} isXboxPlayer={isXboxPlayer} />
				}
			},
			parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL
		}
		const lists = [{
				'id':deviceInfo.isPlayStationPlatform() ? 'Antstream Trophies' : 'Antstream Achievements',
				'title': deviceInfo.isPlayStationPlatform() ? 'Antstream Trophies' : 'Antstream Achievements',
				'data':achievements,
				...defaultValuesForList
			},{
				'id':this.state.tournamentHistoryTitle,
				'title':this.state.tournamentHistoryTitle,
				'data':tournamentHistory,
				...defaultValuesForList
			}
		];

		this.setState({lists});
	};

	onItemSelect = (itemId, listIndex) => {
		if(listIndex === this.state.tournamentHistoryTitle) {
			const selectedTournamentItem = this.props.tournamentHistory.find(item=>item._id===itemId);
			navigateToLocation(ROUTES.TOURNAMENTS_LEADERBOARD, {id:selectedTournamentItem.tournamentObject._id, focusElement: externalFocusableComponent.PLAY_BUTTON});
		}
	};

	render() {
		return (
			<div className="achievements-component">
				<h1>{deviceInfo.isPlayStationPlatform() ? 'Trophies' : 'Achievements'}</h1>
				{ this.state.lists && this.state.lists.length > 0 && 
					<ListContainer
						lists={this.state.lists}
						onTileClick={this.onItemSelect}
					/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isXboxPlayer: isXboxUser(state),
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		tournamentHistory: getTournamentHistory(state),
	};
};

export const Achievements = connect(mapStateToProps)(AchievementsComponent);
