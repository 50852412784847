/* eslint-disable no-console */
/* global appActivity */
import { store as reduxStore } from '../../configureStore';
import {
    deviceInfoReceivedAction,
    OnWebViewSizeChange,
    SetInternetConnectionStatusAction,
    SetUserOnlineAction,
    SetWebviewState
} from '../../app.actions';
import {
    FetchPaymentPlansSuccess,
    OnNativePaymentResponse,
    sendLogOutRequest,
    setMicrosoftStoreId,
    RequestWaysunTokenAction,
    LoginEpicGamesAction,
    OnEpicGamesRedemptionResponse,
    GetAccountDetailsAction,
    XboxSubscriptionActionFinished,
    FetchMicrosoftProductsActionSuccess,
    FetchMicrosoftProductsActionError,
    LoginPlayStationAction,
    FetchPaymentDataActionSuccess,
} from '../../components/login/login.actions';
import {
    SetGameReady,
    SetGameFinished,
    SetGameQuit,
    onNativeSaveStateResult,
    UnpauseGameAction,
    ToggleOskVisibility,
    SetChallengeOver,
    SetScoreSetup,
    SetScoreValues,
    setConnectionStrengthIndicatorAction
} from '../../components/game-details/game-details.actions';
import {addPopup, closePopup} from '../../components/popup/popup.component';
import GenericPopup, {TYPE_HAS_TWO_BUTTONS} from '../../components/popup/generic-popup/generic-popup.component';
import React from 'react';
import gamePad from './gamepad.lib.js';
import deviceInfo, {
    STORE_TYPE_EPIC,
    STORE_TYPE_CHINA_MOBILE,
    STORE_TYPE_XBOX,
    STORE_TYPE_SAMSUNG_TV,
    STORE_TYPE_APPLE_IOS,
    STORE_TYPE_PLAY_STATION
} from './deviceInfo';
import {navigateBack, navigateToLocation, getLastHistoryLocation, ROUTES} from '../../app.router';
import {
    onBandwidthTestResults,
    onConnectionTestResults,
    SetControlPreferencesTransparencyAction,
    SetControlPreferencesVolumeAction
} from '../../components/settings/settings.actions';
import {getTestSessionCreated} from '../../components/settings/settings.selector';
import {getLoggedUser, getPaymentData, getPaymentPlans} from "../../app.selectors";
import {GetPushServiceIdSuccess} from "../../entities/users/users.actions";
import KeepAlive from './KeepAlive';
import {
    initNativeLocalStorage,
    removeDeepLinkStorage,
    writeDeepLinkStorage,
    readUserData,
    writeOskData,
    readRestUserData,
    readOskData
} from './local-storage';
import { tryDecodeDeepLinkAndNavigate } from './deepLinkGenerator';
import Elastic, {LOG_LEVEL} from '../../assets/lib/elastic.lib';
import Axios from 'axios';
import AudioManager from '../../app.audio';
import { antstreamService as Antstream } from '../../app.reducer';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import * as LocalStorage from "./local-storage";
import XboxSubscriptionPopup, {popupType as xboxPopupType} from "../../components/popup/xbox-subscription-popup/xbox-subscription-popup.component";
import { consoleLogWithTimestamp, showDebugNotification } from "./utils";
import inGameHudStore from '../../components/in-game-hud/in-game-hud.store';
import { PLAY_STATION_NATIVE_REDIRECT_URI } from '../../constants';

let showingOsk = false;
let xboxProductId = null;
let isXboxErrorPopupOpened = false;
let lastUsedAuthToken = null;
let setupVariables = null;

const messageToNative = (url) => {
    if (deviceInfo.isWebviewUltralight()) { // seems like ultralight does not support multiple arguments for console log
        console.log('📟 messageToNative: ' + url);
    } else {
        console.log('📟 messageToNative', url);
    }

    if (window.webkit && 'antstream' in window.webkit.messageHandlers) {
        window.webkit.messageHandlers.antstream.postMessage(url);  // Apple navigation doesn't use URLs
    } else if (window.external && 'notify' in window.external) {
        window.external.notify(url);                             // Neither does UWP
    } else if (typeof window.cefMessageToNative !== 'undefined') {
        window.cefMessageToNative(url);                            // This should do CEF/win32
    } else if ((typeof appActivity !== 'undefined') && appActivity.messageToNative) {
        appActivity.messageToNative(url);                          // Android
    }
};

/**
 * Env variables from /api/setup endpoint
 **/
export const getSetupVariables = () => {
    return setupVariables;
};
export const setSetupVariables = (data) => {
    setupVariables = data;
};

export const connectToGame = (payload) => {
    var {ip, port, controlMap, qualitySetting, controlEditing, showLagGraph, headlessNative, showControllerDebug, noVblank, useSmoothing, useRumble, gameProfile, sessionId, challengeStyle, gameId, userId, requestId, encodingType, role, giantScore, giantScoreLabel} = payload;
    const map     = encodeURIComponent(controlMap);
    const profile = encodeURIComponent(gameProfile);
    controlEditing = controlEditing || "false";
    showLagGraph   = showLagGraph   || "false";
    headlessNative = headlessNative || "false";
    showControllerDebug = showControllerDebug || "false";
    noVblank       = noVblank       || "true";
    useSmoothing   = useSmoothing   || "false";
    useRumble      = useRumble      || "false";
    challengeStyle = challengeStyle || '';
    qualitySetting = qualitySetting || 0;

    const headlessNativeArguments = (headlessNative.toLowerCase()==="false") ? "" : "&novideo=true&noaudio=true";
    const roleArgument = (role ? "&role="+role : "");
    const giantDetails = (giantScore && giantScoreLabel ? "&giantScore="+encodeURIComponent(giantScore)+"&giantScoreLabel="+encodeURIComponent(giantScoreLabel) : "");
    const colorProfile = encodingType ? `&colorProfile=${encodingType}` : ''
    const RUN_GAME_URL = `antstream://server=${ip}&port=${port}&quality=${qualitySetting}&controlEdit=${controlEditing}&showLagGraph=${showLagGraph}&showControllerDebug=${showControllerDebug}&noVblank=${noVblank}&useSmoothing=${useSmoothing}&rumble=${useRumble}&challengeStyle=${challengeStyle}&sessionId=${sessionId}&requestId=${requestId}&gameProfile=${profile}&waitForStart=true&control=${map}&gameId=${gameId}&userId=${userId}${colorProfile}${headlessNativeArguments}${roleArgument}${giantDetails}`;
    if (deviceInfo.isRunningOnNative()) {
        messageToNative(RUN_GAME_URL);
    }
    else if (window.config.REACT_APP_ENV !== 'live' || 	featureInfo.isSupported(FEATURES.WEBRTC)) { // WebRTC
        reduxStore.dispatch(SetGameReady());
    }
};

export const configureLogging = (region="initialUnknownRegion", onApiSetupResponse) => {
    console.log("configureLogging region: " + region);

    console.log('API_SETUP AXIOS GET started');
    Axios.get('/api/setup')
    .then(resp => {
        console.log('API_SETUP AXIOS GET finished');
        const data = resp.data || {};
        setSetupVariables(data || null);

        const elasticConfig = {
            ...data,
            environment: window.config.REACT_APP_ENV,
            service: 'REACT',
            region,
        };
        Elastic.set(elasticConfig);

        const nativeConfig = {
            url: data.logEndpoint,
            sas: data.logSAS,
            buildNumber: data.buildNumber,
            buildHash: data.buildHash,
            logLevel: data.logLevel,
            environment: window.config.REACT_APP_ENV,
            region,
        };
        const configParams = Object.keys(nativeConfig).map(key => `${key}=${encodeURIComponent(nativeConfig[key])}`).join("&");
        messageToNative(`antstream://configureLogging?${configParams}`)

        if (onApiSetupResponse) {
            onApiSetupResponse(data);
        }
    }).catch(err => {
        console.log(`Cannot setup logger: ${err}`);
    });
};

export const configureMetrics = (apiSetupData) => {
    const { metricEndpoint, metricSAS } = apiSetupData;
    messageToNative(`antstream://configureMetrics?url=${encodeURIComponent(metricEndpoint)}&sas=${encodeURIComponent(metricSAS)}`);
};

export const configureInterface = (preferences) => {
    if(!preferences) return
    if (preferences.volume !== undefined) {
        AudioManager.setMasterVolume(preferences.volume);
        reduxStore.dispatch(SetControlPreferencesVolumeAction(preferences.volume));
    }
    if (preferences.transparency !== undefined) {
        reduxStore.dispatch(SetControlPreferencesTransparencyAction(preferences.transparency));
    }
    const urlString = Object.keys(preferences).reduce((accum, preferenceKey)=> {
        const value = preferences[preferenceKey]
        if (value === null || value === undefined) return accum
        return accum + `&${preferenceKey}=${value}`
    }, '')
    if (!urlString) return
    const prefixedString = '?'+ urlString.substring(1) // replace & with ? in first instance
    messageToNative(`antstream://configureInterface${prefixedString}`);
};

export const logMessage = (dataString, logLevel) => {
    const encodedMessage = encodeURIComponent(dataString);
    const encodedLogLevel = encodeURIComponent(logLevel);
    messageToNative(`antstream://logMessage?message=${encodedMessage}&level=${encodedLogLevel}`);
};

export const openUrlOnDefaultBrowser = (url , windowContext = window.open) => {
    if (deviceInfo.isRunningOnNative()) {
        const encodedUrl = encodeURIComponent(url);
        messageToNative(`antstream://openURL=${encodedUrl}`);
    } else {
        windowContext(url);
    }
};

export const requestedSessionEvent = (requestID) => {
    messageToNative(`antstream://sessionRequested?requestId=${requestID}`);
};

// Use via FailedRequestAction only
export const failedRequestEvent = (requestID) => {
    messageToNative(`antstream://startFailed?requestId=${requestID}`);
};

// Use via CancelledRequestAction only
export const cancelledRequestEvent = (requestID) => {
    messageToNative(`antstream://startCancelled?requestId=${requestID}`);
};

export const startNativePayment = (sku, id) => {
    let urlString = 'antstream://payment';

    if (deviceInfo.isPlayStationPlatform()) {
        if (id) {
            urlString += `?productId=${id}`;
        }
        messageToNative(urlString);
        return;
    }

    if (deviceInfo.isXboxPlatform()) {
        xboxProductId = id;
        urlString += `?productId=${id}`;
        messageToNative(urlString);
        return;
    }

    if (id) {
        urlString += `?id=${id}&payment=${sku}`;
    }
    else {
        urlString += `=${sku}`
    }
    messageToNative(urlString);
};

export const bringToFront = () => {
    // messageToNative('antstream://bringToFront');
};

export const startGame = () => {
    messageToNative('antstream://startgame');
};

export const pauseGame = () => {
    messageToNative('antstream://pauseGame');
};

export const beginGame = () => {
    messageToNative('antstream://beginGame');
};

export const showUIVirtualKeyboard = () => {
    showingOsk = true;
    messageToNative('antstream://showUIVirtualKeyboard');
};

export const hideUIVirtualKeyboard = () => {
    messageToNative('antstream://hideUIVirtualKeyboard');
    showingOsk = false;
};

export const getStoreCatalogue = () => {
    messageToNative('antstream://storeCatalogue');
};

export const getMicrosoftStoreId = (token) => {
    messageToNative(`antstream://getStoreCustomerInfo&token=${token}`);
};

export const setNativeUserId = (userid) => {
    messageToNative(`antstream://identityLink&id=${userid}`);
};

export const endGame = (sessionId) => {
    consoleLogWithTimestamp('antstream://endgame for session ID: ' + sessionId);

    const ENDGAME_URL = 'antstream://endgame';
    messageToNative(ENDGAME_URL);
};

export const causeCrash = () => {
    const CAUSE_CRASH_URL = 'antstream://causeCrash';
    messageToNative(CAUSE_CRASH_URL);
};

export const openUrl = (url) => {
    window.location.href = url;
};

export const getDeviceInfo = () => {
    const env = encodeURIComponent(window.config.REACT_APP_ENV);
    const xblLoginUrl = encodeURIComponent(`${window.config.REACT_APP_ANTSTREAM_API_BASE_URL}/${window.config.REACT_APP_ANTSTREAM_API_STAGE}/v2/auth/external/xbl/login`);
    const aosBackendUrl = encodeURIComponent(`${window.config.REACT_APP_ANTSTREAM_API_BASE_URL}/${window.config.REACT_APP_ANTSTREAM_API_STAGE}`);

    messageToNative(`antstream://deviceInfo?env=${env}&xblLoginUrl=${xblLoginUrl}&aosBackendUrl=${aosBackendUrl}`); // Also grabs controllers automatically
};

export const sendRemoteUpdateList = (list) => {
    if (featureInfo.isSupported(FEATURES.TV_REMOTES) && list && list.length) {
        messageToNative("antstream://addTVRemoteNames?names=" + encodeURIComponent(JSON.stringify(list)));
    }
};

export const addGamepadMapping = (newMapping) => {
    messageToNative("antstream://addGamepadMapping?mapping=" + encodeURIComponent(newMapping));
};

export const getPlatformLoginInfo = ({ refreshToken = null } = {}) => {
    // Trigger an attempt to log in to the current platform service or gets Waysun device user information
    let PLATFORM_LOGIN_URL = 'antstream://platformLogin';
    if (refreshToken) {
        PLATFORM_LOGIN_URL += `?refreshToken=${refreshToken}`;
    }

    messageToNative(PLATFORM_LOGIN_URL);

    // const fakeResponse = { // Debug code for testing waysun
    //     "type":"platformLogin",
    //     "service": "chinaMobile",
    //     "status": "success",
    //     "details":
    //     {
    //         "userID": "SK2311nnnn1plnplmkml"
    //     }
    // }
    // messageFromNative(JSON.stringify(fakeResponse));
};

export const grabControllers = () => {
    const GRAB_CONTROLLERS_URL = 'antstream://grabControllers';
    messageToNative(GRAB_CONTROLLERS_URL);
}

export const releaseControllers = () => {
    const RELEASE_CONTROLLERS_URL = 'antstream://releaseControllers';
    messageToNative(RELEASE_CONTROLLERS_URL);
}

export const needKeyboardFocus = () => {
    const NEED_KEYBOARD_FOCUS_URL = 'antstream://needKeyboardFocus';
    messageToNative(NEED_KEYBOARD_FOCUS_URL);
};

export const saveState = (slot,region) => {
    messageToNative(`antstream://saveState?slot=${slot}&region=${region}`);
};

export const startBandWidthTest = (url, cancel) => {
    const encodedUrl = encodeURIComponent(url);
    messageToNative(`antstream://bandwidthMeasure?url=${encodedUrl}&cancel=${cancel?'true':'false'}`);
};

const updateUserDetails = (avatarBase64) => {
    const userData = readRestUserData() || {};

    const encodedAvatarBase64 = encodeURIComponent(avatarBase64);
    messageToNative(`antstream://updateUserDetails?userId=${userData?.userId}&avatar=${encodedAvatarBase64}`);
};

export const updateTournamentPosition = (position) => {
    messageToNative(`antstream://updateTournamentPosition?position=${position}`);
};

export const getPushServiceID = () => {
    messageToNative('antstream://getPushServiceID');
};

export const setBackOverride = () => {
    messageToNative('antstream://setBackOverride');
};

export const clearBackOverride = () => {
    messageToNative('antstream://clearBackOverride');
};

export const buttonPressed = (button) => {
    messageToNative('antstream://buttonPressed?button=' + button);
};

export const buttonReleased = (button) => {
    messageToNative('antstream://buttonReleased?button=' + button);
};

export const promptForReview = (button) => {
    messageToNative('antstream://promptForReview')
}

export const sendBase64AvatarToNative = (url) => {
    const onImageLoad = (parentContainer, imageElement) => {
        const canvasElement = document.createElement("canvas");
        canvasElement.width  = imageElement.width;
        canvasElement.height = imageElement.height;

        const context = canvasElement.getContext("2d");
        context.drawImage(imageElement, 0, 0);

        parentContainer.appendChild(canvasElement);
        const dataURL = canvasElement.toDataURL("image/png");
        updateUserDetails(dataURL.replace(/^data:image\/png;base64,/, ""));
        document.body.removeChild(parentContainer);
    };

    // if(window.config.REACT_APP_BUILD_NUMBER === 'local') {//if you try to load any file outside of local, "canvasElement.toDataURL()" function gives security error.
    //     url = "/static/media/star.42a1aa43.svg";
    // }

    const imageContainerElement = document.createElement("div");
    imageContainerElement.classList.add("base64-container");

    const imageElement = new Image();
    imageElement.crossOrigin = 'Anonymous';
    imageElement.classList.add("base64-image");
    imageElement.addEventListener("load",(event) => {
        onImageLoad(imageContainerElement, event.currentTarget);
    });
    imageElement.addEventListener("error",(error) => {
        console.log("image load Error",error);
        document.body.removeChild(imageContainerElement);
    });

    imageContainerElement.appendChild(imageElement);
    document.body.appendChild(imageContainerElement);
    imageElement.setAttribute("src",url);
};

export const getPlanDetails = (skuid) => {
    const plans = getPaymentPlans(reduxStore.getState());
    if(plans) {
        for(let i=0; i<plans.length; i++) {
            if(plans[i].sku === skuid) {
                console.log("[gw:getPlanDetails] Plan found:", plans[i]);
                return plans[i];
            }
        }
    }

    console.log("[gw:getPlanDetails] No plans available. Returning default");
    return {price: "9.99", currencyString: "GBP"}
};

let numberOfXboxLoginRetries = 1;

export function messageFromNative(json) {
    let resp;
    try {
        resp = JSON.parse(json);
    } catch(e) {
        console.log('messageFromNative parse failed');

        const escaped = escapeNewLines(json);
        if (!escaped.error) {
            resp = escaped.value;
        } else {
            console.log('messageFromNative escape json new lines did not help');
            Elastic.logDirect(LOG_LEVEL.ERROR, "messageFromNative JSON Parse ERROR " + e + " in " + JSON.stringify(json));
            return;
        }
    }

    if (deviceInfo.isWebviewUltralight()) { // seems like ultralight does not support multiple arguments for console log
        console.log('📟 messageFromNative: ' + json);
    } else {
        console.log('📺 messageFromNative', resp);
    }
    switch (resp.type.toLowerCase()) {
    case 'init':
        const {os, platform, deviceID, clientRunID, version, internalVersion, store, uiController, model, webview, abi, hardwareID, localStorage, startup, safeAreaHorizontal, safeAreaVertical} = resp;
        deviceInfo.setDeviceInfo(os, platform, deviceID, clientRunID, version, internalVersion, store, uiController, model, webview, abi, hardwareID, safeAreaHorizontal, safeAreaVertical);
        initNativeLocalStorage(localStorage);

        const {controlPreferences = {}} = readUserData() || {};
        if (controlPreferences.volume !== undefined) {
            AudioManager.setMasterVolume(controlPreferences.volume);
        }

        if(typeof startup === 'undefined') {
            //ensure there's nothing old lurking there
            removeDeepLinkStorage();
        } else {
            writeDeepLinkStorage(startup);
        }
        reduxStore.dispatch(deviceInfoReceivedAction(deviceInfo));

        const KNOWN_REMOTES_LIST = [// Remotes
                                    "MStar Smart TV IR Receiver",
                                    "flirc.tv flirc",
                                    "MemsArt MA144 RF Controller",

                                    // Mice
                                    "2.4G Mouse",
                                    "YE Wireless Mouse",
                                    "Razer Razer DeathAdder Chroma",
                                    // Keyboards
                                    "MStar Smart TV Keypad",
                                    "aml_keypad",
                                    "Logitech Dell WK636",
                                    "Logitech USB Receiver",
                                    // Other
                                    "uinput-fpc",            // A fingerprint sensor, I think
                                    "amazon-cec",            // An Amazon something?
                                    "SG.Ltd SG Control Mic"  // A microphone
        ];
        sendRemoteUpdateList(KNOWN_REMOTES_LIST);

        // Should TVOS Ever come back, we may need an automated LoginUserAction() here
        if (window.config.REACT_APP_MONO_BUILD !== 'true') {
            configureLogging("initialUnknownRegion", (apiSetupData) => {
                setTimeout(() => {
                    configureMetrics(apiSetupData);
                },500);
            });
        }

        break;

    case 'deeplinknavigation':
        Elastic.logDirect(LOG_LEVEL.INFO, "Deeplink Navigation Received: " + JSON.stringify(resp));
        const deepLink = decodeURI(resp.link);
        if(!getLoggedUser(reduxStore.getState()))
        {
            //user not yet logged in
            writeDeepLinkStorage(deepLink);
            navigateToLocation(ROUTES.LOGIN);
        }
        else
        {
            //ensure there's nothing old lurking there
            removeDeepLinkStorage();
            tryDecodeDeepLinkAndNavigate(deepLink);
        }
        break;

    case 'notifiablecontrollers':
        const { neoLegendsStick } = resp;
        deviceInfo.setActiveControllers({
            neoLegend: neoLegendsStick === "true"
        });
        break;

    case 'platformlogin':
        const { details, service, status } = resp;
        console.log("Platform login message received.");
        if (!service) {
            console.log("Platform login message had no details.");
            break;
        }

        if (service === STORE_TYPE_CHINA_MOBILE) {
            console.log("China Mobile login message received.");
            if (details.userID) {
                reduxStore.dispatch(RequestWaysunTokenAction(details.userID, true));
            }
        }
        else if (service === STORE_TYPE_EPIC) {

            // TODO: this shouldn't be here, if the native refreshes the token and relogs]
            //       it should have a different "case" endpoint to separate responsabilities
            const isLogged = reduxStore.getState().login.logged;
            if (isLogged) {
                console.log("Epic login message received but user already logged in, break.")
                return;
            }

            console.log("Epic login message received.");
            if (details && details.userId && details.detailJwt && details.refreshToken) {
                console.log("Epic login messages with details received");
                reduxStore.dispatch(LoginEpicGamesAction(details.accessToken, details.detailJwt, details.refreshToken));

                // TODO: Sadly it has to be a setTimeout because the redux store isn't returning a promise
                //       and it would take too much time to fix for tomorrow. This should be fixed in the redesign.
                if (details.entitlements === 'someUnprocessed') {
                    console.log("unprocessed");
                    setTimeout(() => {
                        Antstream
                            .redeemEpicGamesEntitlements()
                            .subscribe(result => reduxStore.dispatch(OnEpicGamesRedemptionResponse(result)));
                    }, 5000);
                }
            }
        }
        else if (service === 'xboxLive') {
            if (!status) {
                Elastic.logDirect(LOG_LEVEL.ERROR, 'XBOX_PLATFORM_LOGIN failed: ' + json);
                console.error('Failed to login via native client: ' + details);
                if (!isXboxErrorPopupOpened) {
                    addPopup(<GenericPopup
                        onOkClicked={() => {
                            isXboxErrorPopupOpened = false;
                        }}
                        okButtonLabel="Ok"
                        title="Error"
                        message="Failed to login to Antstream servers. Retrying..."
                    />, {}, true);
                    isXboxErrorPopupOpened = true;
                }
                break;
            } else {
                if (isXboxErrorPopupOpened) {
                    closePopup();
                    isXboxErrorPopupOpened = false;
                }
            }

            console.log("Platform login xboxLive received.");
            try {
                let parsedDetails = details;
                if (typeof parsedDetails === "string") {
                    parsedDetails = JSON.parse(details);
                }

                const { accessToken, userId, player } = parsedDetails.data;
                Elastic.logDirect(LOG_LEVEL.INFO, 'XBOX_PLATFORM_LOGIN success: ' + userId);

                LocalStorage.writeRestUserData({
                    email: player.userName,
                    authToken: accessToken,
                    userId: userId
                });

                reduxStore.dispatch(GetAccountDetailsAction(null, false, false, numberOfXboxLoginRetries > 0));
                numberOfXboxLoginRetries--;
                console.log("Platform login xboxLive GetAccountDetailsAction started.");
            } catch (e) {
                console.error('Failed to parse platformLogin message from native client: ' + e.message);
                addPopup(<GenericPopup
                    okButtonLabel="Got it!"
                    title="Error"
                    message={'Failed to parse platformLogin message from native client: ' + e.message}
                />, {}, true);
            }
        }
        else if (service === STORE_TYPE_PLAY_STATION) {
            console.log("Platform login PLAYSTATION received.");

            try {
                let parsedDetails = details;
                if (typeof parsedDetails === "string") {
                    parsedDetails = JSON.parse(details);
                }

                // if (window.config.REACT_APP_ENV === 'dev') {
                    // Elastic.logDirect(LOG_LEVEL.WARN, 'PLAYSTATION platform login data: ' + json);
                // }

                const { code } = parsedDetails.data;
                // Elastic.logDirect(LOG_LEVEL.INFO, 'PLAYSTATION platform login: ' + code);

                reduxStore.dispatch(LoginPlayStationAction(code, PLAY_STATION_NATIVE_REDIRECT_URI));

                console.log("Platform login PLAYSTATION LoginPlayStationAction started.");
            } catch (e) {
                console.error('Failed to parse platformLogin message PLAYSTATION from native client: ' + e.message);
                addPopup(<GenericPopup
                    okButtonLabel="Got it!"
                    title="Error"
                    message={'Failed to parse login message from native client: ' + e.message}
                />, {}, true);
            }
        }
        else {
            console.log("No idea what store you're talking about though : " + service +  " isn't " + deviceInfo.STORE_TYPE_EPIC);
        }
        console.log("Platform login message handled");
        break;

    case 'shownotification': { // this native message is used to receive all websocket messages from backend, because in Client V2 implementation we do NOT connect to webscoket on the UI side, we just listen to this native message instead
        try {
            Antstream.websocketAPImessage$.next(JSON.parse(resp.data));
        } catch (err) {
            console.error('Failed to process ws message from native client: ', err);
        }
        break;
    }

    case 'cg_packet_data_display_setup': {
        inGameHudStore.addScore(resp);
        reduxStore.dispatch(SetScoreSetup(resp))
        break;
    }

    case 'hud update': {
        inGameHudStore.updateScore(resp);
        reduxStore.dispatch(SetScoreValues(resp))
        break;
    }

    case 'webviewshown':
        reduxStore.dispatch(SetWebviewState({visible: true}));
        break;
    case 'webviewhidden':
        reduxStore.dispatch(SetWebviewState({visible: false}));
        break;
    case 'visiblesizechange':
        if (!deviceInfo || !deviceInfo.webview || (deviceInfo.webview !== "crosswalk")) {
            const {x,y} = resp;
            reduxStore.dispatch(OnWebViewSizeChange({x,y}));
        }
        break;
    case 'gameready':
        reduxStore.dispatch(SetGameReady());
        break;
    case 'paymentresult': {
        if (deviceInfo.storeType === STORE_TYPE_APPLE_IOS) {
            handleIosIphonePaymentResultMessage(resp);
            return;
        }

        if (deviceInfo.isXboxPlatform()) {
            handleXboxPaymentResultMessage(resp);
            return;
        }

        let storeType = deviceInfo.storeType;
        if (deviceInfo.isPlayStationPlatform() && storeType !== STORE_TYPE_PLAY_STATION) {
            storeType = STORE_TYPE_PLAY_STATION;
        }

        const subPlan = getPlanDetails(resp.skuid);
        resp.price = subPlan.price;
        resp.currency = subPlan.currencyString;
        reduxStore.dispatch(OnNativePaymentResponse({
            store: storeType,
            response:resp
        }));
        break;
    }
    case 'onkeyboardevent':
        // keyboardKey: "button_1" - gamepad A button
        // keyboardKey: "button_2" - gamepad B button
        // keyboardKey: "button_3" - gamepad X button
        // keyboardKey: "button_4" - gamepad Y button

        const gamepadNavigationEvents = {
            d_pad_left: true, d_pad_right: true, d_pad_down: true, d_pad_up: true
        };

        if (showingOsk && gamepadNavigationEvents[resp.keyboardKey]) {
            // Swallow navigation events as the OSK is showing
        }
        else if (resp.keyboardKey === 'button_3') {
            Antstream.gamepadKeyPressedMessage$.next('xButton');
        }
        else {
            gamePad.onKeyboardEvent(resp);
            if (resp?.keyboardKey === 'button_4') {
                Antstream.gamepadKeyPressedMessage$.next('yButton');
            }
        }
        break;
    case 'virtualkeyboardhidden':
        showingOsk = false;
        break;
    case 'error':
        KeepAlive.errorReceived();
        const title = resp.title ? resp.title : decodeURI(resp.code);
        const message = getErrorMessage(decodeURI(resp.message));
        const doNotNavigateBack = resp.goBack === false || resp.goBack === 'false';

        Elastic.log(LOG_LEVEL.ERROR, "Error message from native:"+title+" - "+message);

        if (title=="N99") // Show a more user friendly error message. Needs properly fixing in ClientV2
        {
            addPopup(<GenericPopup
                okButtonLabel="Got it!"
                onOkClicked={() => doNotNavigateBack ? undefined : navigateBack()}
                title={"Please try again"}
                message={"There was an issue with your game session. If the issue persists please go to our FAQ at https://aarca.de/support"}
            />, {}, true);
        }
        else
        {
            addPopup(<GenericPopup
                okButtonLabel="Got it!"
                onOkClicked={() => doNotNavigateBack ? undefined : navigateBack()}
                title={title}
                message={"Please try again. "+message}
            />, {}, true);
        }
        break;
    case 'gamefinished':
        KeepAlive.gameFinishedReceived();
        reduxStore.dispatch(SetGameFinished());

        // In case we cancel the challenge we don't get the challenge-over message. This code here should be harmless.
        inGameHudStore.resetScores();
        reduxStore.dispatch(SetChallengeOver(true));
        reduxStore.dispatch(SetWebviewState({visible: true}));

        const lastLoc = getLastHistoryLocation();
        setTimeout(() => {
            if (lastLoc === ROUTES.IN_GAME_HUD.path
                || (lastLoc && lastLoc.includes(ROUTES.HOW_TO_PLAY.path))
            ) {
                console.log('Quit game and navigate back');
                reduxStore.dispatch(SetGameQuit());
                navigateBack();
            }
        },2000);

        setTimeout(() => {
            console.log('getLastHistoryLocation: ' + getLastHistoryLocation());
        }, 4000);
        break;
    case 'challengeover':
        KeepAlive.gameFinishedReceived();
        inGameHudStore.resetScores();
        reduxStore.dispatch(SetChallengeOver(true));
        reduxStore.dispatch(SetWebviewState({visible: true}));

        break;
    case 'storecatalogue':
        if(resp.catalogue && resp.catalogue.length>0) {
            Elastic.logDirect(LOG_LEVEL.ERROR,"storecatalogue payment plans: " + JSON.stringify(resp.catalogue));
            reduxStore.dispatch(FetchPaymentPlansSuccess(resp.catalogue));
        }
        break;
    case 'storecustomerinfo':
        if(resp && resp.id) {
            reduxStore.dispatch(setMicrosoftStoreId(resp.id));
        }
        break;
    case 'savestateresult':
        reduxStore.dispatch(onNativeSaveStateResult(resp));
        break;
    case 'connectiontestresults':
        reduxStore.dispatch(onConnectionTestResults(resp));
        break;
    case 'gamepaused':
        KeepAlive.gamePausedReceived();

        if(getTestSessionCreated(reduxStore.getState())){
            reduxStore.dispatch(UnpauseGameAction());
        }
        break;
    case 'bandwidthmeasureresults':
        const {bytesDownloaded,millisecsTaken,cancelled} = resp;
        if(!cancelled) {
            reduxStore.dispatch(onBandwidthTestResults({bytesDownloaded,millisecsTaken}));
        }
        break;
    case 'pushservicedetails':
        const {error} = resp;
        if(error === "") {
            reduxStore.dispatch(GetPushServiceIdSuccess(resp));
        }
        break;
    case 'gamerunning':
        KeepAlive.gameRunningReceived();
        break;
    case 'challengeend':
        navigateToLocation(ROUTES.RESULTS_WAITING);
        break;
    case 'requestquit':
        addPopup(
            <GenericPopup
                title="Close Antstream?"
                message="Are you sure you want to close the app?"
                cancelButtonLabel="Back"
                okButtonLabel="Close"
                buttonType={TYPE_HAS_TWO_BUTTONS}
                onOkClicked={()=> quitApp()}
                onCancelClicked={()=> quitCancelled()}
            />
        );
        break;
    case 'virtualkeyboard': {
        const { show } = resp; // {"type":"virtualKeyboard", "show": boolean}

        // do not show empty OSK if game doesn't need it
        if (show) {
            const oskData = readOskData();
            const oskEnabled = oskData && oskData.vk_enabled && !!oskData.vk_enabled.find(entry => entry > 0);
            if (!oskEnabled) return;
        }

        reduxStore.dispatch(ToggleOskVisibility(show));
        messageVirtualKeyboard(show);
        break;
    }
    case 'CG_PACKET_INPUT_SETUP_RAW':
    case 'cg_packet_input_setup_raw':
        writeOskData(resp);
        break;
    case 'internetconnection': {
        const { connected } = resp;
        if (connected) {
            // const isPopupHidden = getPopupVisibility(reduxStore.getState()); 
            // if (!isPopupHidden) { // RESTART APP ON RECONNECT
            //     // if popup was opened on connection lost - restart the app (because popup cannot be closed in a normal way in this case)
            //     window.location.href = window.config.REACT_APP_URL;
            //     return;
            // }

            reduxStore.dispatch(SetUserOnlineAction(true));
            reduxStore.dispatch(SetInternetConnectionStatusAction(true));

            // const currentPath = reduxStore.getState().routing.currentRoute.path;
            // const ignorePages = [
            //     ROUTES.SIGNUP_AVATAR.path,
            //     ROUTES.SIGNUP_AUTHENTICATION.path,
            //     ROUTES.SIGNUP_COMPLETION.path
            // ];
            // if (!ignorePages.includes(currentPath)) {
            //     navigateToLocation(ROUTES.HOMEPAGE);
            // }
        } else {
            reduxStore.dispatch(SetUserOnlineAction(false));
            reduxStore.dispatch(SetInternetConnectionStatusAction(false));
        }
        break;
    }
    case 'microsoftproductlistings': {
        try {
            const { listings, error } = resp;
            if (!Array.isArray(listings) || !listings.length) {
                showDebugNotification('Failed to retrieve microsoft products list: ' + JSON.stringify(resp));
                reduxStore.dispatch(FetchMicrosoftProductsActionError(error || 'Failed to retrieve microsoft products list'));
            } else {
                reduxStore.dispatch(FetchMicrosoftProductsActionSuccess(listings));
            }
        } catch (e) {
            showDebugNotification('Failed to retrieve microsoft products list2: ' + e.message);
            reduxStore.dispatch(FetchMicrosoftProductsActionError(e.message));
        }
        break;
    }
    case 'suspend': {
        break;
    }
    case 'resume': {
        const state = reduxStore.getState();
        const challengeId = state.app?.runningSessionObject?.challenge_id;
        const sessionId = state.app?.runningSessionId;
        const currentPath = state.routing.currentRoute?.path;
        const gamePages = [
            ROUTES.IN_GAME_HUD.path,
            ROUTES.HOW_TO_PLAY.path,
            ROUTES.HOW_TO_PLAY_GAMEPAD.path,
            ROUTES.HOW_TO_PLAY_INSTRUCTIONS.path,
            ROUTES.HOW_TO_PLAY_KEYBOARD.path
        ];

        console.log('resume: ', currentPath);

        // If playing a game or in the How to Play page, go to the game Info page or Results.
        if (gamePages.includes(currentPath) && sessionId) {
            if (challengeId) {
                navigateToLocation(ROUTES.RESULTS_WAITING);
                reduxStore.dispatch(SetGameQuit(sessionId));
            } else {
                navigateToLocation(ROUTES.GAME_INFO, {id: state.app?.runningSessionObject?.game_uuid});
                reduxStore.dispatch(SetGameQuit(sessionId));
            }
        } else if (currentPath === ROUTES.TEST_CONNECTION.path) {
            navigateToLocation(ROUTES.SETTINGS_SUPPORT);
        }
        break;
    }
    case 'multiplayergame': {
        const { showThePopup } = resp; // {"type":"multiplayerGame", "showThePopup": boolean}

        if (showThePopup) {
            const controllerName = deviceInfo.isPlayStationPlatform()
                ? 'wireless controller' : 'controller';
            addPopup(
                <GenericPopup
                    title={`Second ${controllerName}`}
                    message={`Please sign in with another account in order to use second ${controllerName}`}
                    okButtonLabel="Ok"
                />,
                {},
                true
            );
        }

        break;
    }
    case 'connectionstrengthindicator': {
        reduxStore.dispatch(setConnectionStrengthIndicatorAction(resp.strength));
        break;
    }

    default:
    }
}

export function messageVirtualKeyboard(show) {
    messageToNative(`antstream://virtualKeyboardState?shown=${show}&size=${show ? 25 : 0}`);
}

export function clearLoginCredentials() {
    const CLEAR_LOGIN_URL = 'antstream://clearLoginCredentials';
    messageToNative(CLEAR_LOGIN_URL);
}

export function quitApp() {
    if (deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV) {
        const { application } = require('tizen-common-web');
		application.getCurrentApplication().exit();
    }
    else {
        messageToNative('antstream://quit');
        sendLogOutRequest(); // this is odd, why?

    }
}

export function quitCancelled() {
    messageToNative('antstream://quitCancelled');
}

export function updateNativeLocalStorage(contents) {
    const data = encodeURIComponent(contents);
    messageToNative(`antstream://updateNativeLocalStorage?contents=${data}`);
}

/**
* @param key {string}
* @param pressed {boolean}
**/
export const messageNativeClientOnOSKKeyEvent = (key , pressed) => {
    if (!deviceInfo.isRunningOnNative()) return;

    messageToNative(`antstream://virtualKeyEvent?key=${key}&pressed=${pressed}`);
};

// This fires social sharing on mobile devices
export const sendSocialSharingToNative = (text, link) => {
    const encodedText = encodeURIComponent(text);
    const encodedLink = encodeURIComponent(link);
    messageToNative(`antstream://socialShare?text=${encodedText}&link=${encodedLink}`);
}

export const sendCopyTextToNative = (text) => {
    const encodedText = encodeURIComponent(text);
    messageToNative(`antstream://copyToClipboard?text=${encodedText}`);
}

export const sendAchivementUpdateToNative = (params) => {
    if (!params || !params.lockedAchievement) {
        console.error('empty params in sendAchivementUpdateToNative');
        return;
    }

    const info = params.lockedAchievement;

    const title = encodeURIComponent(info.title);
    const progress = Math.min(parseInt(params.player_score / info.target_score * 100, 10), 100);
    
    if (deviceInfo.isPlayStationPlatform()) {
        if (deviceInfo.isPlayStationPlatformPS4() && info.psn?.ps4ID && progress === 100) {
            const text = `antstream://updateAchievement?id=${info._id}&ps4ID=${info.psn.ps4ID}&progress=${params.player_score}&type=${info.type}&title=${title}&playerScore=${params.player_score}&targetScore=${info.target_score}`;
            messageToNative(text);
        }

        if (deviceInfo.isPlayStationPlatformPS5() && info.psn?.ps5ID) {
            const text = `antstream://updateAchievement?id=${info._id}&ps5ID=${info.psn.ps5ID}&progress=${params.player_score}&type=${info.type}&title=${title}&playerScore=${params.player_score}&targetScore=${info.target_score}`;
            messageToNative(text);
        }
    } else {
        const text = `antstream://updateAchievement?id=${info._id}&xboxID=${info.xboxID}&progress=${progress}&type=${info.type}&title=${title}&playerScore=${params.player_score}&targetScore=${info.target_score}`;
        messageToNative(text);
    }
}

/**
 * @param wsUrl {string}
 * @param authToken {string}
 **/
export const messageNativeClientWebsocketCreds = (wsUrl , authToken) => {
    if (!deviceInfo.isRunningOnNative()) return;
    if (lastUsedAuthToken === authToken) {
        console.log('Warning! Ignored sending notificationsWebsocket with the same authToken.');
        return;
    }

    lastUsedAuthToken = authToken;
    messageToNative(`antstream://notificationsWebsocket?url=${wsUrl}&token=${authToken}`);
};

/**
 * @param xboxPlayerId {string} ex. 2814651152401539
 **/
export const messageNativeXboxShowGamerCard = (xboxPlayerId) => {
    messageToNative(`antstream://xboxShowGamerCard?xuid=${xboxPlayerId}`);
};

export const messageNativeXboxRetryLogin = () => {
    messageToNative(`antstream://platformLoginResend`);
};

export const messageNativeMicrosoftProductListings = () => {
    messageToNative(`antstream://microsoftProductListings`);
};

export const checkMultiplayerGameForXbox = () => {
    messageToNative('antstream://checkMultiplayerGame');
};

/**
 * This is used for playing MP3 files in Native
 * this is used because we are having issues playing MP3 files in Ultralight (that we are using on Native Client V2)
 * @param path {string} - example: challengefail.mp3
 * @param multiplier {string|number} - example: 1, default: 1
 **/
export const messageNativePlaySound = (path, multiplier) => {
    let url = `antstream://playSound?path=${path}`;
    if (multiplier) {
        url += `&multiplier=${multiplier}`;
    }
    messageToNative(url);
};

const getErrorMessage = (message) => {
    if (!message) return;
    const errorCode = message.substring(message.length - 3);
    const text = message.substring(0, message.length - 3) + errorCode;
    return text;
}

function handleXboxPaymentResultMessage(resp) {
    if (!resp || !resp.success) {
        // if something went wrong or user just cancelled buying - stop spinner and keep showing subscription popup
        showDebugNotification('Warning: native payment result was not successful. Rollback to previous state.');
        reduxStore.dispatch(XboxSubscriptionActionFinished());
        return;
    }

    const prevPaymentData = getPaymentData(reduxStore.getState());
    reduxStore.dispatch(OnNativePaymentResponse({store: STORE_TYPE_XBOX, response: resp}, (nextPaymentData) => {
        // why we need to compare paymentDue? Because there is a case when user can buy new subscription, but old subscription is not expired yet
        if (nextPaymentData && nextPaymentData.subscription && (prevPaymentData.paymentDue !== nextPaymentData.paymentDue)) {
            closePopup(); // close subscription popup
            reduxStore.dispatch(XboxSubscriptionActionFinished()); // stop spinner
            addPopup(<XboxSubscriptionPopup type={xboxPopupType.PURCHASE_COMPLETE} planId={xboxProductId} />); // show
        } else {
            showDebugNotification('Native payment response error: new payment data is invalid or equal to the old one: ', nextPaymentData);
            console.log('SOMETHING WENT WRONG: new payment data is invalid or equal to old one: ', nextPaymentData);
            reduxStore.dispatch(XboxSubscriptionActionFinished());
        }

        xboxProductId = null;
    }, () => {
        reduxStore.dispatch(XboxSubscriptionActionFinished());
        xboxProductId = null;
    }));
}

function handleIosIphonePaymentResultMessage(resp) {
    const { userId } = readRestUserData() || {};
    Elastic.logDirect(LOG_LEVEL.INFO, `${userId} handleIosIphonePaymentResultMessageTry: ${JSON.stringify(resp)}`, true);

    if (!resp || !resp.transaction) {
        return;
    }

    if (typeof resp.transaction === 'string') {
        try {
            const result = JSON.parse(resp.transaction);
            resp.transaction = result;
        } catch (e) {
            Elastic.logDirect(LOG_LEVEL.ERROR, `${userId} handleIosIphonePaymentResultMessageTry ERROR PARSING: ${JSON.stringify(resp)}`, true);
            return;
        }
    }

    const reqData = {store: STORE_TYPE_APPLE_IOS, response: resp};
    reduxStore.dispatch(OnNativePaymentResponse(reqData, (nextPaymentData) => {
        if (nextPaymentData && nextPaymentData.subscription) {
            closePopup(); // close subscription popup
            reduxStore.dispatch(FetchPaymentDataActionSuccess(nextPaymentData));
            // addPopup(<XboxSubscriptionPopup type={xboxPopupType.PURCHASE_COMPLETE} planId={xboxProductId} />); // show
        } else {
            Elastic.logDirect(LOG_LEVEL.ERROR, `${userId} handleIosIphonePaymentResultMessageNoSubscription`, true);
        }
    }, (errMessage) => {
        Elastic.logDirect(LOG_LEVEL.ERROR, `${userId} handleIosIphonePaymentResultMessageError: ${errMessage}`, true);
        console.error(`handleIosIphonePaymentResultMessageError: ${errMessage}`);
    }));
}

function escapeNewLines(json) {
    const regExp = new RegExp(/\n|\r/g);
    const result = {
        value: null,
        error: false
    };

    try {
        result.value = JSON.parse(json.replace(regExp, ''));
    } catch (err) {
        result.error = true;
    }

    return result;
}

window.messageFromNative = messageFromNative;
