// Packages
import { Subject } from 'rxjs/Subject';
import Axios from 'axios';

// Assets
import * as GameWrapper from './game-wrapper';
import {
	FACEBOOK_APP_ID,
	FACEBOOK_CLIENT_ID,
	AUTH_TYPE,
	FACEBOOK_APP_ID_PROD,
	FACEBOOK_CLIENT_ID_PROD
} from '../../constants';
import defaultAvatar from '../images/global/DefaultAvatar.png';

/**
 * Sends the user to an new tab (or default browser on native) where they are logged into Facebook.
 * @param {object} [redirectRoute={}] - the route to redirect
 * @param {boolean} [hasNativeBrowser=true] - if the device supports native browsers
 */
export const login = (redirectRoute = {}, hasNativeBrowser = true) => {
	const redirect = window.location.origin + (redirectRoute.path || '') + '?auth_type=' + AUTH_TYPE.FACEBOOK;
	const url = `https://www.facebook.com/v3.2/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${redirect}&response_type=token&scope=email`;

	if (hasNativeBrowser) {
        GameWrapper.openUrlOnDefaultBrowser(url);
    } else {
        GameWrapper.openUrl(url);
    }
};

/**
 * Requests a user code to login with a device.
 * The response should contain { user_code, code, expires_in }
 * Check: https://developers.facebook.com/docs/facebook-login/for-devices#tech 
 * @returns {Promise}
 */
export const loginWithDeviceCode = () => {
	const access_token = `${FACEBOOK_APP_ID}|${FACEBOOK_CLIENT_ID}`;
	return Axios.post(`https://graph.facebook.com/v2.6/device/login`, { access_token })
}

/**
 * Polls Facebook's API for confirmation on device authorization.
 * The response should return the user access_token to store in our user data.
 * Check: https://developers.facebook.com/docs/facebook-login/for-devices#tech
 * @param {string} userCode - user code from facebook device login request
 * @returns {Promise}
 */
export const requestDeviceAuthorization = (userCode) => {
	const access_token = `${FACEBOOK_APP_ID}|${FACEBOOK_CLIENT_ID}`;
	return Axios.post(`https://graph.facebook.com/v2.6/device/login_status`, { access_token, code: userCode });
}

export const shareWithFriendsLinkWithRedirectUri = (redirect) => {
	redirect = window.location.origin + redirect;
	GameWrapper.openUrlOnDefaultBrowser(`https://www.facebook.com/v3.2/dialog/share?client_id=${FACEBOOK_APP_ID}&href=www.antstream.com&display=popup&redirect_uri=${redirect}`);
};

export const getFacebookUserData = (accessToken) => {
	const subj = new Subject();
	fetch(`https://graph.facebook.com/me/?access_token=${accessToken}&fields=name,id,email`)
		.then(function (response) {
			return response.json();
		})
		.then(function(data) {
			subj.next(data);
			subj.complete();
		});
	return subj;
};

export const getFBImageUrl = (id) => {
	if (window.config.REACT_APP_FACEBOOK_DISABLED === 'true') {
		return defaultAvatar
	}

	if (id && id.slice(0, 4) !== 'http') {
		return `https://graph.facebook.com/${id}/picture?width=500&height=500&access_token=${FACEBOOK_APP_ID_PROD}|${FACEBOOK_CLIENT_ID_PROD}`;
	} else {//keep the old avatar urls as it
		return id;
	}
};
