import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmojiExcellent from '../../../assets/images/global/EmojiExcellent.svg';
import EmojiGood from '../../../assets/images/global/EmojiGood.svg';
import EmojiOK from '../../../assets/images/global/EmojiOK.svg';
import EmojiPoor from '../../../assets/images/global/EmojiPoor.svg';
import './test-connection-item.component.less';

export const TEST_EMOJI_TYPES = {
	POOR:"POOR",
	OK:"OK",
	GOOD:"GOOD",
	EXCELLENT:"EXCELLENT",
};

export const TEST_EMOJI_IMAGES = {
	POOR:EmojiPoor,
	OK:EmojiOK,
	GOOD:EmojiGood,
	EXCELLENT:EmojiExcellent,
};

class TestConnectionItemComponent extends Component {
	constructor(props){
		super(props);

		this.state = {
			imgIcon:TEST_EMOJI_IMAGES[TEST_EMOJI_TYPES.OK]
		};
	}

	onAnimationEnd = (event) => {
		event.preventDefault();
		this.setState({imgIcon:TEST_EMOJI_IMAGES[this.props.resultObject.emojiType]});
	};

	render() {
		return (
			<div className="test-connection-item">
				<div className="left-container">
					{this.props.resultObject.name}
				</div>
				<div className={`right-container`}>
					<div
						className={`bar ${this.props.resultObject.emojiType} ${!this.props.resultObject.withBar?'withoutBar':''}`}
						onAnimationEnd={this.onAnimationEnd}
					>
					</div>
					<div className="emoji">
						<img
							alt={this.props.resultObject.emojiType}
							src={this.props.resultObject.withBar ? this.state.imgIcon : TEST_EMOJI_IMAGES[this.props.resultObject.emojiType]}
						/>
					</div>
					<div className={`dots-container ${this.props.resultObject.emojiType} ${!this.props.resultObject.withBar?'withoutBar':''}`}>
						||||||||||||
					</div>
				</div>
			</div>
		);
	}

}

const mapStateToProps = (state) => {
	return {};
};

export const TestConnectionItem = connect(mapStateToProps,null)(TestConnectionItemComponent);
