import React from 'react';
import './medals.component.less';
import { connect } from 'react-redux';

const DailyChallengesMedalsComponent = ({ configuration }) => {
    function renderMedal(pointKey, pointValue) {
        return (
            <div key={pointKey} className='daily-challenges-medals__item'>
                <div className={`daily-challenges-medals__medal-icon daily-challenges-medals__medal-icon--${pointKey}`}></div>
                <div className='daily-challenges-medals__points'>{pointValue}pts</div>
            </div>
        );
    }

    function renderMedals() {
        if (!configuration || !configuration?.points) return null;

        return Object.keys(configuration.points)
            .map(pointKey => ({pointKey, pointValue: configuration.points[pointKey] }))
            .sort((a,b) => b.pointValue - a.pointValue)
            .map(point => renderMedal(point.pointKey, point.pointValue))
        ;
    }

    return (
        <div className='daily-challenges-medals'>
            <span className='daily-challenges-medals__title'>
                Weekly Medal Leaderboard
            </span>
            <div className="daily-challenges-medals__box">
                {renderMedals()}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        configuration: state.dailyChallenges.configuration
    };
};

const DailyChallengesMedals = connect(mapStateToProps)(DailyChallengesMedalsComponent);
export default DailyChallengesMedals;
