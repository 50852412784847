import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { getOtherUserFavouriteGames } from '../../../entities/entities.selectors';
import ListContainer, {LIST_TYPES} from '../../common/list/list-container.component';
import { PARENT_ENTRANCE} from '../../../assets/lib/inputmanager';

import './user-details-favourites.component.less';
import GameInfoPanel from '../../common/game-info-panel/game-info-panel.component';
import {SetSelectedGameInfoAction} from '../../game-details/game-details.actions';
import {externalFocusableComponent} from "../../game-details/info/game-details-info.component"
import {isFreeTierV2FeatureFlagEnabled} from "../../../assets/lib/utils";
import {getIsPostAuthFetched, getUserIsSubscribed} from "../../../app.selectors";

const REFRESH_PREVIEW_DELAY = 400;

class UserDetailsFavouritesComponent extends Component {
	constructor() {
		super();
		this.state = {};
		this.onTileClicked = this.onTileClicked.bind(this);
		this.onChange = this.onChange.bind(this);
		this.selectedGameId = null;
	}

	componentWillUnmount() {
		this.selectedGameId = null;
		clearTimeout(this.infoTimerId);
		this.props.dispatch(SetSelectedGameInfoAction(null));
	}
	componentDidMount() {
		if(this.props.items && this.props.items[0]) {
			this.props.dispatch(SetSelectedGameInfoAction(this.props.items[0]._id));
		}
	}

	isNeedToShowSubscriptionsComparisonPopup() {
		const { isFreeTierV2FeatureEnabled, isPostAuthFetched, isUserSubscribed } = this.props;

		return isFreeTierV2FeatureEnabled
			&& isPostAuthFetched
			&& !isUserSubscribed;
	}

	onChange = (gameId) => {
		this.selectedGameId = gameId;
		clearTimeout(this.infoTimerId);
		this.infoTimerId = setTimeout(() => {
			this.props.dispatch(SetSelectedGameInfoAction(gameId));
		}, REFRESH_PREVIEW_DELAY);
	};

	onTileClicked = (gameId, listId, hasChallenges) => {
		if (this.isNeedToShowSubscriptionsComparisonPopup() && hasChallenges) {
			navigateToLocation(ROUTES.GAME_CHALLENGES, {id: gameId, focusElement: 'initialChallenge'});
			return;
		}

		if(this.selectedGameId === gameId) {
			navigateToLocation(ROUTES.GAME_INFO, {id: gameId, focusElement: externalFocusableComponent.PLAY_BUTTON});
		} else {
			this.selectedGameId = gameId;
			this.props.dispatch(SetSelectedGameInfoAction(gameId));
		}
	}

	render() {
		if (!this.props.items || this.props.items.length === 0) return null;
		return (
			<div className="user-details-favourites-component">
				<div className="top-container">
					<GameInfoPanel/>
				</div>
				<ListContainer
					lists={[ {
						'id':'User Details Favourites',
						'data':this.props.items,
						type: LIST_TYPES.HORIZONTAL,
						parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL
					} ]}
					onTileFocus={this.onChange}
					onTileClick={this.onTileClicked}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		items: getOtherUserFavouriteGames(state),
	};
};

export const UserDetailsFavourites = connect(mapStateToProps)(UserDetailsFavouritesComponent);

