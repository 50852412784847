import deviceInfo, {isDeviceChrome, isDeviceFirefox, isDeviceSafari, getBrowserData, STORE_TYPE_SAMSUNG_TV} from './deviceInfo'
import Elastic, {LOG_LEVEL} from './elastic.lib';
import { readRestUserData } from '../../assets/lib/local-storage';
import { getSetupVariables } from './game-wrapper';
import axios from 'axios';

const browserData = getBrowserData()

class AntRTCTelemetry {
  constructor (
      sessionID,
      peerConnection,
      remoteVideo,
      sendWSMessage,
      webrtcVersion = '',
      intervalInMs = 10000,
      gameId,
      serverIp
  ) {
    this.webrtcVersion = webrtcVersion;
    this.sessionID = sessionID;
    this.gameId = gameId;
    this.serverIp = serverIp;
    this.peerConnection = peerConnection;
    this.remoteVideo = remoteVideo;
    this.intervalInMs = intervalInMs;
    if (window.config.REACT_APP_ENV === 'dev' || window.config.REACT_APP_ENV === 'stg') {
        this.intervalInMs = 5000;
    }
    this.browser = browserData.browser;
    if(deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV) {
        this.browser = "SamsungTizenBrowser";
    }
    this.os = browserData.operatingSystem;
    this.avsServerVersion = "";
    this.currentPing = 0;
    this.lastReportTimestamp = 0;
    this.lastInboundRTPReport = null;
    this.sendWSMessage = sendWSMessage;
    this.lastServerReport = null;
  }

  setAVSVersion = (version) => {
    this.avsServerVersion = version;
  }

  setLastServerReport = (report) => {
    this.lastServerReport = report;
  }

  reportPing = (value) => {
    this.currentPing = value;
  };

  emit = () => {
    this.peerConnection.getStats(null).then(stats => {
        this.setMetricsToElastic(stats);
    });
  };

  sendMetricsToAzure = metricsData => {
    if (this.webrtcVersion === 'v1') return;

    /**
     * replaces dots with underscores
     * e.g. client.server.qosNumbers.serverLatencyV1 => client_server_qosNumbers_serverLatencyV1
     * */
    const snakeCasedData = {};
    Object.keys(metricsData).forEach(key => {
      let formattedKey = key;
      if (key.includes('.')) {
        formattedKey = key.replaceAll('.', '_');
      }
      snakeCasedData[formattedKey] = metricsData[key]; 
    });

    const variables = getSetupVariables();
    if (!variables) {
      console.error('sendMetricsToAzure error: variables are not defined.');
      return;
    }

    const azureMetricsUrl = variables.metricEndpoint;
    const azureMetricsSas = variables.metricSAS;

    axios.post(azureMetricsUrl, {
      database: 'client_stats',
      table: 'webrtc_client',
      tags: {
        uiClient: 'as-client-react-ui',
        webrtcVersion: this.webrtcVersion,
      },
      metric: snakeCasedData
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': azureMetricsSas
      }
    }).catch(err => {
      console.log(`sendMetricsToAzure error: ${err}`);
    });
  }

  setMetricsToElastic = (stats) => {

    const { userId } = readRestUserData();

    const metricsEvent = {
        kind: "metrics",
        metricsType: "webrtcClient",
        message: "emit",
        browser: this.browser,
        os: this.os,
        sessionID: this.sessionID,
        userID: userId,
        avsServerVersion: this.avsServerVersion,

        platform: 'UIv1',
        gameID: this.gameId,
        serverIP: this.serverIp,
        timestamp: new Date().toISOString()
    };

    const statsMessageForServer = {
        msgType: "webrtcClientStatsReport",
        sessionID: this.sessionID,
        userID: userId,
        browser: this.browser,
        os: this.os,
        data: {
          timestamp: new Date().getTime(),
          inbound_video_rtp: {},
          candidate_pair: {},
        },
      };

    const currentTimestamp = window.performance.now();
    const elapsed_ms = currentTimestamp - this.lastReportTimestamp;
    const secondsElapsed = elapsed_ms / 1000;
    
    stats.forEach(report => {
      if (report['type'] === 'inbound-rtp' && report['kind'] === 'video') {
        // console.log("Report: ", report);
        statsMessageForServer.data.inbound_video_rtp = report;

        const fps = report['framesPerSecond'] ? report['framesPerSecond'] : 0;
        const height = report['frameHeight'] ? report['frameHeight'] : 0;
        const width = report['frameWidth'] ? report['frameWidth'] : 0;
        const framesReceived = report['framesReceived'];
        const framesDecoded = report['framesDecoded'];
        const framesDropped = report['framesDropped'];
        const totalBytesReceived = report['bytesReceived'];
        const packetsLost = report['packetsLost'] ? report['packetsLost'] : 0;
        const packetsReceived = report['packetsReceived'] ? report['packetsReceived'] : 0;
        const jitter = report['jitter'] ? report['jitter']: 0;
        const qpSum = report['qpSum'] ? report['qpSum'] : 0;
        const processingDelay = report['totalProcessingDelay'] ? report['totalProcessingDelay'] : 0;
        const totalDecodeTime = report['totalDecodeTime'] ? report['totalDecodeTime'] : 0;
        const totalJitterBufferDelay = report['jitterBufferDelay'] ? report['jitterBufferDelay'] : 0;
        const totalJitterBufferEmitCount = report['jitterBufferEmittedCount'] ? report['jitterBufferEmittedCount'] : 0;
        const totalFreezeCount = report["freezeCount"] ? report["freezeCount"] : 0;
        const totalFreezeDuration = report["totalFreezesDuration"] ? report["totalFreezesDuration"] : 0;

        const lastFramesReceived = this.lastInboundRTPReport ? this.lastInboundRTPReport['framesReceived'] : 0;
        const currentFramesReceived = framesReceived - lastFramesReceived;
        const lastFramesDropped = this.lastInboundRTPReport ? this.lastInboundRTPReport['framesDropped'] : 0;
        const currentFramesDropped = framesDropped - lastFramesDropped;
        const framesPercentDropped = (currentFramesDropped/currentFramesReceived) * 100;

        const lastTotalPacketsRcv = this.lastInboundRTPReport ? this.lastInboundRTPReport['packetsReceived'] : 0;
        const lastPacketsLost = this.lastInboundRTPReport ? this.lastInboundRTPReport['packetsLost'] : 0;
        const instantLoss = ((packetsLost - lastPacketsLost) /(packetsReceived - lastTotalPacketsRcv))*100;

        const lastDecodeTime = this.lastInboundRTPReport ? this.lastInboundRTPReport['totalDecodeTime'] : 0;
        const newDecodingTime = totalDecodeTime - lastDecodeTime;     
        const lastframesDecoded = this.lastInboundRTPReport ? this.lastInboundRTPReport['framesDecoded'] : 0;
        const newFramesDecoded = framesDecoded - lastframesDecoded;

        const lastProcessingDelay = this.lastInboundRTPReport ? this.lastInboundRTPReport['totalProcessingDelay'] : 0;
        const newProcessingDelay = processingDelay - lastProcessingDelay;
        const perFrameProcessingDelay = newProcessingDelay * 1000/ newFramesDecoded;
        const avgDecodingDelay = newDecodingTime * 1000/ newFramesDecoded;

        const lastJitterDelay = this.lastInboundRTPReport ? this.lastInboundRTPReport['jitterBufferDelay'] : 0;
        const newJitterDelay = totalJitterBufferDelay - lastJitterDelay;
        const lastJitterEmitCount =this.lastInboundRTPReport ? this.lastInboundRTPReport['jitterBufferEmittedCount'] : 0;
        const newJitterEmitCount = totalJitterBufferEmitCount - lastJitterEmitCount;

        const JitterDelay_ms = newJitterDelay * 1000/ newJitterEmitCount;

        const lastBytesRcv = this.lastInboundRTPReport ? this.lastInboundRTPReport['bytesReceived'] : 0;
        const bytesReceived = totalBytesReceived - lastBytesRcv;
        const currentKbps = ((bytesReceived  * 8) / 1000) / secondsElapsed;

        const lastTotalFreezeDuration = this.lastInboundRTPReport ? this.lastInboundRTPReport['totalFreezesDuration'] : 0;
        const lastTotalFreezeCount = this.lastInboundRTPReport ? this.lastInboundRTPReport['freezeCount'] : 0;

        const numFreezes = totalFreezeCount - lastTotalFreezeCount;
        const freezeDuration = totalFreezeDuration - lastTotalFreezeDuration;
        const freezePercent = ((freezeDuration * 1000) / elapsed_ms) * 100;
        //const sessionfreezePercent = lastTotalFreezeCount;
        //const sessionTotalFreezes = report["freezeCount"];
        const lastQpSum = this.lastInboundRTPReport ? this.lastInboundRTPReport['qpSum'] : 0;
        const currentAvgFrameQp = (qpSum - lastQpSum) / newFramesDecoded;

        metricsEvent['client.video.frameHeight'] = height;
        metricsEvent['client.video.frameWidth'] = width;
        metricsEvent['client.video.totalPixels'] = width * height;
        metricsEvent['client.video.fps'] = fps;
        metricsEvent['client.video.processingDelay'] = perFrameProcessingDelay;
        metricsEvent['client.video.decodingDelay'] = avgDecodingDelay;
        metricsEvent['client.video.jitterDelay'] = JitterDelay_ms;
        metricsEvent['client.video.kbps'] = currentKbps;
        metricsEvent['client.video.avgFrameQp'] = currentAvgFrameQp;
        metricsEvent['client.video.freezeCount'] = numFreezes;
        metricsEvent['client.video.freezePercent'] = freezePercent;
        metricsEvent['client.video.framesDropped'] = currentFramesDropped;
        metricsEvent['client.video.framesDropPercent'] = framesPercentDropped;    
        metricsEvent['client.network.jitter'] = jitter * 1000;
        metricsEvent['client.network.packetLoss'] = instantLoss;
        metricsEvent['client.video.codec'] = this.videoCodecName;

        // metricsEvent['client.sessionResume.video.freezePercent'] = sessionfreezePercent;
        // metricsEvent['client.sessionResume.video.totalFreezes'] = sessionTotalFreezes;
        // metricsEvent['client.sessionResume.video.avgFreezeDuration'] = currentTotalFreezeDuration /sessionTotalFreezes;

        this.lastReportTimestamp = currentTimestamp;
        this.lastInboundRTPReport = report;

        if (isDeviceSafari) {
            metricsEvent['client.network.jitter'] = report['jitter'];
        } else {
            metricsEvent['client.network.jitter'] = report['jitter'] * 1000;
        }
      }
      else if (report['type'] === 'candidate-pair' && report['state'] === 'succeeded' && report['writable'] === true) {

        statsMessageForServer.data.candidate_pair = report;

        if(isDeviceFirefox) {
            metricsEvent['client.network.rtt'] = this.currentPing;
        }
        else {
            if(report['currentRoundTripTime']) {
                const rtt = report['currentRoundTripTime'];
                metricsEvent['client.network.rtt'] = rtt * 1000;
            }
        }
      }
    });

    if (this.lastServerReport !== null) {
        metricsEvent['client.server.qosNumbers.bitrateQuality'] = this.lastServerReport?.AVSQoSNumbers?.bitrateQuality;
        metricsEvent['client.server.qosNumbers.framerateQuality'] = this.lastServerReport?.AVSQoSNumbers?.framerateQuality;
        metricsEvent['client.server.qosNumbers.serverLatencyV1'] = this.lastServerReport?.AVSQoSNumbers?.serverLatencyV1;
        metricsEvent['client.server.qosNumbers.loopLatencyV1'] = this.lastServerReport?.AVSQoSNumbers?.loopLatencyV1;
    }

    // if webrtcv1 - send to elastic
    // if webrtcv2 - send to azure
    if (this.webrtcVersion === 'v1') {
      Elastic.logEvent(LOG_LEVEL.INFO, metricsEvent);
    } else {
      this.sendMetricsToAzure(metricsEvent);
    }

    if (this.sendWSMessage) {
        this.sendWSMessage(statsMessageForServer);
      }
  };

  start = () => {
    if(!this.intervalID) {
        this.intervalID = window.setInterval(this.emit, this.intervalInMs);
    }  
    this.emit();    
  };

  stop = () => {
    window.clearInterval(this.intervalID);
  };
}

export {
  AntRTCTelemetry,
}
