import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getSelectedGiantSlayerChallenge, getSelectedGiantSlayerChallengeGame} from '../../game-details/game-details.selector';
import SlideOutInAnimation from '../../animations/slide-out-in-animation.compontent';
import './giant-slayer-info-panel.component.less';
import { isLowSpecDevice }  from '../../../app.helpers'
import { Icon } from '../../common/icon/icon.component';
import CountdownShortTime from '../countdown-short-time/countdown-short-time.component';
import Avatar from '../avatar/avatar.component';
import { getGiants } from '../../challenge/challenge.selectors';
import { getChallengeById } from '../../../entities/entities.selectors';
import SlayerBar from '../slayer-bar/slayer-bar.component';
import { withBadWordMask } from "../../../assets/lib/utils";
import deviceInfo from '../../../assets/lib/deviceInfo';
import { InView } from "react-intersection-observer";
import TileLoading from '../../../assets/images/global/tile_loading.png';

class GiantSlayerInfoPanel extends Component {
	constructor(props) {
		super(props);
		this.onSlideOut = this.onSlideOut.bind(this);
		this.onSlideIn = this.onSlideIn.bind(this);
		this.getContentDiv = this.getContentDiv.bind(this);
		this.startAnimation = this.startAnimation.bind(this);

		this.items = [];

		this.state = {
			animate:false,
			imageClass:'',
			currentGame:null,
			currentGiantSlayerChallenge:null
		};

	}

	componentDidUpdate(prevProps) {
		if (this.props.giantSlayerChallenge && (!prevProps.giantSlayerChallenge || this.props.giantSlayerChallenge._id !== prevProps.giantSlayerChallenge._id)) {
			this.addItemToQueue(this.props.giantSlayerChallenge);
		}

		if(!this.state.animate && this.items.length>0) {
			this.startAnimation();
		}

		// update currentGame if it was loaded later
		if (!prevProps.selectedGame && this.props.selectedGame && !this.state.currentGame) {
			this.setState({ currentGame: this.props.selectedGame });
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.items?.length !== nextProps.items?.length
			|| this.state.animate !== nextProps.animate
			|| this.state.imageClass !== nextProps.imageClass
			|| this.state.currentGiantSlayerChallenge?.numberSlayers !== nextProps.currentGiantSlayerChallenge?.numberSlayers
			|| this.state.currentGiantSlayerChallenge?.giantScore !== nextProps.currentGiantSlayerChallenge?.giantScore
			|| !this.state.currentGiantSlayerChallenge
		;
	}

	addItemToQueue = (game) => {
		if (this.items.length > 1) {
			this.items = [game];
		} else {
			this.items.push(game);
		}

		/** if animation is disabled - execute onSlideOut method one more time in order to make sure this.items has already been added **/
		if (isLowSpecDevice()) {
			this.onSlideOut();
		}
	};

	startAnimation() {
		this.setState({
			animate:true,
			imageClass:'hidden',
		});
	}

	onSlideOut() {
		const gsItem = this.items.shift();
		if(gsItem) {
			if(!this.state.currentGiantSlayerChallenge || this.state.currentGiantSlayerChallenge._id !== gsItem._id) {
				this.setState({
					currentGiantSlayerChallenge: gsItem,
					currentGame: this.props.selectedGame
				});
			}
			else this.setState({imageClass:''});
		}
	}

	onSlideIn() {
		this.setState({
			animate:false
		});
	}

	showDefaultImage = (error) => {
		error.target.src = TileLoading;
	}

	getContentDiv = () => {
		const { giantUserData, challengeObject } = this.props;
		const { currentGame, currentGiantSlayerChallenge } = this.state;
		if (!currentGiantSlayerChallenge || !giantUserData) return null;

		// if no currentGame - show default image for now, we will load the game later
		const { image_uri, genre_image_uri } = currentGame || { image_uri: '', genre_image_uri: '' };

		const genreIconUri = genre_image_uri?.replace('.svg', '-notext-01.png');
		const genreText = genre_image_uri?.split('/').slice(-1)[0].split('.svg')[0];

		let endTimeSec = 0;
		if (currentGiantSlayerChallenge.endTime) {
			endTimeSec = Math.round(currentGiantSlayerChallenge.endTime / 1000);
		}

		return (
			<InView triggerOnce> 
			{({ ref, inView }) => (
			<div ref={ref} className="content-main">
				 {inView == true &&
					<>
				<div className="gs-panel-left">
					<div className={`title-block t-${challengeObject.tier}`}>
						<div className="giant-slayer-icon"></div>
						<div className="title-text">Giant Slayer Challenge</div>
						<div className="giant-slayer-description">Rally together to bring down the giant. Beat the giant’s score for an effective hit!</div>
					</div>

					{(endTimeSec !== 0) && <CountdownShortTime endTimeStamp={endTimeSec}/>}

					<div className="challenge-details">
						<img src={image_uri} onError={this.showDefaultImage} alt="" className="game-image" />
						<div className="challenge-details-right">
							<div className="challenge-details-text">
								<div className="challenge-title">{challengeObject.title}</div>
								<div className="challenge-text">{challengeObject.text}</div>
							</div>

							<div className="challenge-details-infos">
								<div className={`difficulty t-${challengeObject.tier}`}>
									<span className="one"></span>
									<span className="two"></span>
									<span className="three"></span>
									<span className="four"></span>
									<span className="five"></span>
								</div>
								<div className={`iconChallengeType type-${challengeObject.type}`}>
								</div>
								<Icon
									iconUrl={genreIconUri}
									iconAltText={genreText}
								/>
								<div className="prize">
									<div className="prize-label">Prize</div>
									<div className="prize-value">{currentGiantSlayerChallenge.prize}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="vs-block">
					<div className="slayers-text">
						{`${currentGiantSlayerChallenge.numberSlayers} slayer${currentGiantSlayerChallenge.numberSlayers !== 1 ? 's' : ''}`}
					</div>
					<div className={`vs-icon ${!deviceInfo.isRunningOnNativeClientV2() ? 'clientV1' : ''}`}></div>
					<div className="giant-text">{withBadWordMask(giantUserData.displayName)}</div>
				</div>

				<Avatar userData={giantUserData} />
				<div className="giant-score-flag">
					<div className="score-title">SCORE</div>
					<div className="giant-score-text">{currentGiantSlayerChallenge.giantScore}</div>
					<div className="score-label">{challengeObject.score_label}</div>
				</div>
				<div className="giant-illus"></div>

				<SlayerBar
					currentSlayerWinFraction={currentGiantSlayerChallenge.currentSlayerWinFraction}
					targetSlayerWinFraction={currentGiantSlayerChallenge.targetSlayerWinFraction}
				/>
				</>
	}
			</div>
			 )}
			 </InView>
		);
	} ;

	render() {
		return (
			<SlideOutInAnimation
				className="giant-slayer-info-panel-component"
				onSlideOut={this.onSlideOut}
				onSlideIn={this.onSlideIn}
				animate = {this.state.animate}
				isSkipAnimate={isLowSpecDevice()}
			>
				{this.getContentDiv()}
			</SlideOutInAnimation>
		);
	}
}

const mapStateToProps = (state) => {
	const giantSlayerChallenge = getSelectedGiantSlayerChallenge(state);
	const giants = getGiants(state);

	let giantUserData = null;
	let challengeObject = null;
	if (giantSlayerChallenge) {
		giantUserData = giants.find(giant => giant._id === giantSlayerChallenge.giantId);
		challengeObject = getChallengeById(state, giantSlayerChallenge.challengeId);
	}

	return {
		giantSlayerChallenge,
		selectedGame: getSelectedGiantSlayerChallengeGame(state),
		giantUserData,
		challengeObject,
	};
};

export default connect(mapStateToProps)(GiantSlayerInfoPanel);
