import React, { Component } from 'react';
import './avatar-with-circle.component.less';
import {Avatar} from '../avatar/avatar.component';
import {isLowSpecDevice} from '../../../app.helpers';
import {ProgressCircle} from '../progress-circle/progress-circle.component';
import deviceInfo from "../../../assets/lib/deviceInfo";

class AvatarWithCircleComponent extends Component {
	renderProgressCircle() {
		const { progressCircleCssClass, expPercentage } = this.props;

		if (deviceInfo.isRunningOnNativeClientV2()) {
			return <ProgressCircle expPercentage={expPercentage} progressCircleCssClass={progressCircleCssClass} />;
		}

		return (
			<svg className="avatar-svg">
				<circle className="circle-border" cx="0" cy="0" r="53%"/>
				<circle className="circle-bg" cx="0" cy="0" r="49.8%"/>
				<circle className={`circle-stroke ${!isLowSpecDevice() ? 'circle-stroke-animate' : ''} `} cx="0" cy="0" r="46%" strokeDasharray={`${47 * 2 * Math.PI}%`} strokeDashoffset={`${47 * 2 * Math.PI * expPercentage}%`}/>
			</svg>
		);
	}

	render() {
		const {userData, handleClicked, onComponentRef, selected, expAnimationElement} = this.props;

		return (
			<div className={`avatar-with-circle ${selected ? 'selected' : ''} ${handleClicked ? 'clickable' : ''}`} 
				ref={onComponentRef}
				onClick={() => {
					handleClicked && handleClicked()}
				}
			>
				{this.renderProgressCircle()}
				<Avatar userData={userData} />
				{!isLowSpecDevice() && expAnimationElement ? expAnimationElement() : null}
			</div>
		);
	}
}

export const AvatarWithCircle = AvatarWithCircleComponent;
