import React, { Component } from 'react';
import './selection-button.component.less';
import {SelectionButtonOption} from './selection-button-option.component';
import inputmanager from '../../../assets/lib/inputmanager';

class SelectionButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedKey: props.selected,
			focusedKey:null
		};
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (newProps.selected) {
			this.setState({selectedKey: newProps.selected});
		}
	}

	onOptionFocus = (element, focus, key) => {
		this.setState({focusedKey:key});
		if(focus) {
			this.setState({focusedKey:key});
			if(element) element.focus();
		} else {
			if(this.state.focusedKey===key) {
				this.setState({focusedKey:null});
			}
		}
	};

	getValue = () => {
		return this.state.selectedKey;
	};

	changeSelection = (value) => {
		this.setState({selectedKey: value});
		inputmanager.setCurrentChildById(this.props.childId+"-"+value, this.props.parentId);
		this.props.onChange && this.props.onChange(value, this);
	};

	renderButton = (value) => {
		const selected = value === this.state.selectedKey ? 'selected' : '';
		return (
			<SelectionButtonOption
				parentId={this.props.parentId}
				childId={this.props.childId}
				layer={this.props.layer}
				key={value}
				value={value}
				onFocus={this.onOptionFocus}
				className={`link ${selected} ${this.state.focusedKey===value?'focused':''}`}
				onClick={() => this.changeSelection(value)}
			/>
		);
	};

	render() {
		return (
			<div className={'selection-button'}>
				{this.props.values.map( value => this.renderButton(value) )}
			</div>
		);
	}
};

export { SelectionButton };
