import {ERROR_TYPES} from "./ErrorTypes";
import {NOTIFICATION_TYPES} from "./NotificationTypes";
import {TRANSLATION_TYPES} from "./TranslationTypes";

export const getTranslationTemplate = (messageType) => {
    switch (messageType) {
        case NOTIFICATION_TYPES.PODIUM_UPDATE:
            return {
                title: "PODIUM STEAL!",
                summary: "{{senderDisplayName}} knocked you off. {{eventTitle}} {{eventType}}",
            }
        case NOTIFICATION_TYPES.SOCIAL_UPDATE:
            return {
                title: "LEADERBOARD OVERTAKE!",
                summary: "{{senderDisplayName}} has just surpassed you. {{eventName}} {{eventType}}",
            }
        case ERROR_TYPES.TOURNAMENT_PLAY_ERROR:
            return  {
                message: "The tournament is not available!"
            }
        case ERROR_TYPES.CHALLENGE_ACCEPT_ERROR:
            return  {
                message: "This challenge is not on Antstream anymore!"
            }
        case TRANSLATION_TYPES.NUDGE:
            return {
                title: "Don't forget!",
                summary: "{{displayName}} has sent you a challenge request"
            }
        case ERROR_TYPES.INSUFFICIENT_GEMS:
        case ERROR_TYPES.NOT_ENOUGH_GEMS:
            return  {
                title: "Sorry not enough gems",
                message: "You have insufficient gems to unlock this challenge!"
            }
        case ERROR_TYPES.NO_RESPONSE_FROM_ASL:
            return  {
                message: "no response from ASL"
            }
        case ERROR_TYPES.NO_SESSION_RESPONSE_FROM_ASL:
            return  {
                message: "There was no session response from the Game Server!"
            }
        case ERROR_TYPES.CANNOT_FOLLOW_YOURSELF:
            return {
                message: "You cannot follow yourself"
            }
        case ERROR_TYPES.USER_NOT_EXIST:
            return {
                message: "User does not exist"
            }
        case ERROR_TYPES.TOKEN_IS_INCORRECT:
            return {
                message: "Token is incorrect"
            }
        case ERROR_TYPES.INVALID_PASS_OR_TOKEN:
            return {
                message: "Invalid pass and token"
            }
        case ERROR_TYPES.DISPLAY_NAME_NOT_AVAILABLE:
            return {
                message: "The display name is not available."
            }
        default:
            return {
                message: messageType
            }
    }
}