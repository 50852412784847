import React from 'react';
import './avatar.component.less';
import { getAvatarUrl } from '../../../entities/entities.selectors';
import founderIcon from '../../../assets/images/generic-icons/Founder.svg';
import vipIcon from '../../../assets/images/generic-icons/VIP.svg';
import { useSelector } from 'react-redux';
import { InView } from "react-intersection-observer";
import {isLowSpecDevice} from '../../../app.helpers';
import deviceInfo from '../../../assets/lib/deviceInfo';

export const AVATAR_TYPES = {
	NONE: null,
	TWITCH: 'TWITCH',
	VIP: 'vip',
	FOUNDER: 'founder'
};

const getAvatarTypeIcon = (avatarType) => {
	if (AVATAR_TYPES.VIP === avatarType) return vipIcon
	else if (AVATAR_TYPES.FOUNDER === avatarType) return founderIcon
	return;
}

const shouldDisplayAvatar = (inView) => {
	if (deviceInfo.isSafeAreaTurnedOn()) return true;

	return inView;
}

export const Avatar = ({ selected, currentSelectedAvatar, userData }) => {
	const { displayName, avatarType, isOnline, _id, profile_image_id } = userData;

	const avatarUrl = useSelector((state) => getAvatarUrl(state, profile_image_id))
	const avatarTypeIcon = getAvatarTypeIcon(avatarType)
	const [inView, setInView] = React.useState(false);

	return (
		<InView onChange={setInView} triggerOnce={ !isLowSpecDevice() }>
			{({ ref, inView }) => (
				<div ref={ref} key={_id} className={`avatar-container ${selected ? 'avatar-container--selected' : ''} ${currentSelectedAvatar ? 'avatar-currentSelectedAvatar-container' : ''}`}>
					{shouldDisplayAvatar(inView) && <>
						<div className="avatar-image-container">
							<img key={avatarUrl} src={avatarUrl} className={`user-image ${avatarUrl && avatarUrl.substr(-3, 3) === 'png' ? 'pixelated' : ''}`} alt={displayName || ''} />
							{currentSelectedAvatar && <div className="avatar-current-selection-text-container">
								<div className="avatar-current-selection-text">
									CONFIRM
								</div>
							</div>}
						</div>
						<div className={`notification ${isOnline ? 'online' : 'offline'} ${avatarType === AVATAR_TYPES.TWITCH ? 'twitch-color' : ''}`} />
						{avatarTypeIcon &&
							<div className="avatar-icon" >
								<img className="avatar-icon-image" src={inView ? avatarTypeIcon : ""} alt={avatarType || ''} />
							</div>
						}
					</>}
				</div>
			)}
		</InView>
	);
}
export default Avatar;
