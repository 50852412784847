import React, {Component} from 'react';
import './player-profile.component.less';
import gemIcon from '../../../assets/images/global/icon-gem.png';
import rankIcon from '../../../assets/images/global/icon-rank.png';
import StarIcon from '../../../assets/images/generic-icons/star.png';
import StarIconSelected from '../../../assets/images/generic-icons/star_selected.png';
import StarIconActive from '../../../assets/images/generic-icons/star_active.png';
import StarIconActiveSelected from '../../../assets/images/generic-icons/star_active_selected.png';
import {AvatarWithCircle} from '../avatar/avatar-with-circle.component';
import inputManager from '../../../assets/lib/inputmanager';
import { connect } from 'react-redux';
import {getLoggedUser} from '../../../app.selectors';
import {FollowUserAction, UnfollowUserAction} from '../../../entities/users/users.actions';

class PlayerProfileComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			profileButtonSelected: false,
			starSelected: false,
		};
	}

	onToggleFollow = () => {
		const {userData} = this.props;
		if (userData.youFollow) {
			this.props.dispatch(UnfollowUserAction(userData._id));
		} else if (!userData.youFollow) {
			this.props.dispatch(FollowUserAction(userData._id));
		}
	};

	onComponentRef = (element) => {
		inputManager.onChildRef(element, "profileButton", this.onProfileButtonFocus);
	};

	onProfileButtonFocus = (focus) => {
		if(this.props.isMyUser){
			this.setState({profileButtonSelected: focus});
		}else{
			this.setState({starSelected: focus});
		}
	};

	getStarIcon = () => {
		const {userData} = this.props;

		if(userData.youFollow){
			if(this.state.starSelected){
				return StarIconActiveSelected;
			}else{
				return StarIconActive;
			}
		} else {
			if(this.state.starSelected){
				return StarIconSelected;
			}else{
				return StarIcon;
			}
		}
	};

	onComponentClicked = () => {
		if(this.props.isMyUser){
			if(this.props.onEditProfile)this.props.onEditProfile();
		}else{
			if (this.props.isFollowUserPending) return;
			this.onToggleFollow();
		}
	};

	renderStar = () => {
		if(!this.props.isMyUser){
			return (
				<div className="star-container">
					<img className="star-icon" alt="star-icon" src={this.getStarIcon()}/>
				</div>
			);
		}else{
			return null;
		}
	};

	render() {
		const {_id, Rank, expPercentage, currencies, currentRankTotalExp, currentExp} = this.props.userData;
		return (
			<div
				className="player-profile-container"
				ref={this.onComponentRef}
				onClick={this.onComponentClicked}
				key={_id}
			>
				<div className="gem-container">
					<span className="gem-text">{currencies.GEMS}</span>
					<img className="gem-icon" alt="gem-icon" src={gemIcon}/>
				</div>
				<div className="rank-container">
					<div className="rank-text-container">
						<div className="exp-text-line-1">{currentExp}/</div>
						<div className="exp-text-line-2">{currentRankTotalExp} <span>XP</span></div>
						<div className="rank-text">{Rank}</div>
					</div>
					<img className="rank-icon" alt="rank-icon" src={rankIcon}/>
				</div>
				<AvatarWithCircle
					progressCircleCssClass="player-profile-progress-circle"
					selected={this.state.profileButtonSelected}
					expPercentage={expPercentage}
					userData={this.props.userData}
				/>
				{this.renderStar()}
			</div>
		);
	}
}

const mapStateToProps = (state,ownProps) => {
	const myUser = getLoggedUser(state);
	const isMyUser = ownProps.userData && myUser && ownProps.userData._id === myUser._id;

	return {
		isMyUser
	};
};

PlayerProfileComponent = connect(mapStateToProps)(PlayerProfileComponent);
export {PlayerProfileComponent};