import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Leaderboard, LEADERBOARD_TYPES } from "../../leaderboard/leaderboard.component";
import {getCurrentTournamentData, getIsPlayerJoined} from "../tournaments.selector";
import './tournaments-leaderboard.component.less';
import {getChallengeById} from "../../../entities/entities.selectors";
import {RunGameAction} from "../../game-details/game-details.actions";
import {navigateToLocation, ROUTES} from "../../../app.router";
import { APPLE_IOS_SKU, PREMIUM_POPUP_HEADER_TEXT_3, TOURNAMENT_STATUS_TYPE } from '../../../constants';
import {getIsInterimScoreEnabled, getWebviewState} from "../../../app.selectors";
import {addPopup} from "../../popup/popup.component";
import XboxCrossplayOnlyPopup from "../../popup/xbox-crossplay-only-popup/xbox-crossplay-only-popup.component";
import {
	isFreemiumAppleIosUser,
	isFreemiumV4UserNotSubscribed,
	isXboxCrossplayEnabled,
	isXboxUser
} from '../../../assets/lib/utils';
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import { startNativePayment } from '../../../assets/lib/game-wrapper';
import { SetWebviewState } from '../../../app.actions';

class TournamentsLeaderboardComponent extends Component {
	componentDidMount() {
		// if for some reason (in-game-hud reset) webview was not set to visible - set it now
		if (!this.props.isWebViewVisible) {
			this.props.dispatch(SetWebviewState({visible: true}));
		}
	}

	onClickPlay = () => {
		if (this.props.isFreemiumAppleIos) {
			startNativePayment(APPLE_IOS_SKU);
			return;
		}

		if (this.props.isFreemiumV4User) {
			const subscriptionPopup = <SubscriptionComparisonsPopup
				leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_3}
				pageId='tournaments_leaderboard_1'
			/>;
			addPopup(subscriptionPopup);
			return;
		}

		if (this.props.isXboxCrossplayTurnedOff) {
			this.showXboxPopup();
			return;
		}

		const {tournamentData, challengeData, dispatch} = this.props;
		const gameId = challengeData.gameId;
		const challengeId = challengeData._id;
		const tournamentId = tournamentData._id;

		dispatch(RunGameAction({
			gameId,
			challengeId,
			tournamentId,
			challengeStyle: this.props.isInterimScoreEnabled?'tournament':'intro'
		}));
		navigateToLocation(ROUTES.HOW_TO_PLAY, { id: gameId, challengeId, tournamentId });
	}

	showXboxPopup = () => {
		addPopup(<XboxCrossplayOnlyPopup />);
	}

	render() {
		const { challengeData, tournamentData , isPlayerJoined} = this.props;

		if (!tournamentData) return null;
		return (
			<div className="tournaments-leaderboard-component">
				<Leaderboard
					tournamentData={tournamentData}
					challengeData={challengeData}
					onClickPlay={this.onClickPlay}
					playButtonVisible={tournamentData.status===TOURNAMENT_STATUS_TYPE.STARTED && isPlayerJoined}
					leaderboardType={LEADERBOARD_TYPES.TOURNAMENT_LEADERBOARD}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const tournamentData = getCurrentTournamentData(state);
	const challengeData = tournamentData ? getChallengeById(state,tournamentData.challengeId) : {};
	const isPlayerJoined = getIsPlayerJoined(state);
	const isInterimScoreEnabled = getIsInterimScoreEnabled(state);
	const isXboxCrossplayTurnedOff = isXboxUser(state) && !isXboxCrossplayEnabled(state);
	const isWebViewVisible = getWebviewState(state).visible;

	return {
		isWebViewVisible,
		isFreemiumAppleIos: isFreemiumAppleIosUser(state),
		isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
		isXboxCrossplayTurnedOff,
		isInterimScoreEnabled,
		isPlayerJoined,
		challengeData,
		tournamentData
	};
};

export const TournamentsLeaderboard = connect(mapStateToProps)(TournamentsLeaderboardComponent);
