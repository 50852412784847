import LocalStorage from './local-storage';

export default class Controls {
  constructor(
    canvas,
    network,
    gameProfile,
    inputSetup,
    fixedJoysticks,
    videoOutputTarget
  ) {
    this.bRunning = false;
    this.canvas = canvas;
    this.videoOutputTarget =videoOutputTarget;
    this.context = canvas.getContext("2d");
    this.fixedJoysticks = fixedJoysticks;
    this.touchUpdated = false;
    this.network = network;

    this.gameProfile = gameProfile;
    this.inputSetup = inputSetup;
    this.layoutId = "";
    this.marginSize = 22;

    this.buttonSize = 8;
    this.buttonBorderSize = 2;
    this.buttonTouchSize = 12;

    this.buttons = [];
    this.virtualJoysticks = [];

    this.mouseX = 0;
    this.mouseY = 0;
    this.mouseButtons = 0;

    this.pointerLockFailed = null;

    if (inputSetup.num_virtual_joysticks == 0) {
      this.addPointerControlListeners();
    }

    // No on screen controls if using desktop device
    if(!this.isMobileOrTablet()) {
      return;
    }

    this.fixedJoysticksPositions = {
      left: { x: 125, y: 125 }, // y is calculated from the bottom
      right: { x: 125, y: 125 } // right x is calculated from the right
    };

    const RED = "#FF0000";
    const GREEN = "#00FF00";
    const BLUE = "#0000FF";
    const YELLOW = "#FFFF00";
    const LIGHT_BLUE = "#00FFFF";
    const PURPLE = "#800080";
    const PINK = "#FF00FF";
    const ORANGE = "#FFA500";
    const DARK_GREEN = "#008000";
    const WHITE_TRANSPARENT = "rgba(255, 255, 255, 0.5)";

    this.buttonLayouts = {
      "game-controls-diamond": {
        "fire1Button": { id: 1, pos: { x: -2.0, y: -1.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: RED    },
        "fire2Button": { id: 2, pos: { x: -1.0, y: -2.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: BLUE   },
        "fire3Button": { id: 3, pos: { x: -3.0, y: -2.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: GREEN  },
        "fire4Button": { id: 4, pos: { x: -2.0, y: -3.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: YELLOW },
      },
      "game-controls-side-right": {
        "fire1Button": { id: 1, pos: { x: -2.0, y: -1.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: RED    },
        "fire2Button": { id: 2, pos: { x: -1.0, y: -2.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: BLUE   },
        "fire3Button": { id: 3, pos: { x: -3.0, y: -2.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: GREEN  },
        "fire4Button": { id: 4, pos: { x: -2.0, y: -3.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: YELLOW },
      },
      "game-controls-slant-middle": {
        "fire1Button": { id: 1, pos: { x: -3.0, y: -3.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: RED        },
        "fire2Button": { id: 2, pos: { x: -2.0, y: -3.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: GREEN      },
        "fire6Button": { id: 6, pos: { x: -1.0, y: -4.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: BLUE       },
        "fire3Button": { id: 3, pos: { x: -3.0, y: -4.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: YELLOW     },
        "fire4Button": { id: 4, pos: { x: -2.0, y: -5.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: LIGHT_BLUE },
        "fire5Button": { id: 5, pos: { x: -1.0, y: -5.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: PURPLE     },
      },
      "game-controls-slant-low": {
        "fire1Button": { id: 1, pos: { x: -3.0, y: -1.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: RED        },
        "fire2Button": { id: 2, pos: { x: -2.0, y: -1.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: GREEN      },
        "fire6Button": { id: 6, pos: { x: -1.0, y: -2.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: BLUE       },
        "fire3Button": { id: 3, pos: { x: -3.0, y: -2.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: YELLOW     },
        "fire4Button": { id: 4, pos: { x: -2.0, y: -3.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: LIGHT_BLUE },
        "fire5Button": { id: 5, pos: { x: -1.0, y: -3.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: PURPLE     },
      },
      "game-controls-pinball": {
        "fire3Button": { id: 3, pos: { x:  1.5, y: -1.0 }, anchor: "ANCHOR_BOTTOM_LEFT",  scale: 2, color: YELLOW     },
        "fire2Button": { id: 2, pos: { x: -1.5, y: -1.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 2, color: GREEN      },
        "fire4Button": { id: 4, pos: { x:  1.5, y: -2.5 }, anchor: "ANCHOR_BOTTOM_LEFT",  scale: 1, color: LIGHT_BLUE },
        "fire1Button": { id: 1, pos: { x: -1.5, y: -2.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: RED        },
      },
      "game-controls-nine": {
        "fire1Button": { id: 1, pos: { x: -4.0, y: -1.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: RED        },
        "fire2Button": { id: 2, pos: { x: -3.0, y: -1.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: GREEN      },
        "fire3Button": { id: 3, pos: { x: -2.0, y: -2.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: BLUE       },
        "fire4Button": { id: 4, pos: { x: -1.0, y: -2.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: YELLOW     },
        "fire5Button": { id: 5, pos: { x: -4.0, y: -3.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: LIGHT_BLUE },
        "fire6Button": { id: 6, pos: { x: -3.0, y: -3.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: PURPLE     },
        "fire7Button": { id: 7, pos: { x: -2.0, y: -4.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: PINK       },
        "fire8Button": { id: 8, pos: { x: -1.0, y: -4.5 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: ORANGE     },
        "fire9Button": { id: 9, pos: { x: -1.0, y: -6.0 }, anchor: "ANCHOR_BOTTOM_RIGHT", scale: 1, color: DARK_GREEN },
      },
      "all": {
      },
    }

    this.allTouchButtons = {
      "fire1Button":  { code: 0x81 },
      "fire2Button":  { code: 0x82 },
      "fire3Button":  { code: 0x83 },
      "fire4Button":  { code: 0x84 },
      "fire5Button":  { code: 0x85 },
      "fire6Button":  { code: 0x86 },
      "fire7Button":  { code: 0x87 },
      "fire8Button":  { code: 0x88 },
      "fire9Button":  { code: 0x91 },
      "fire10Button": { code: 0x92 },
      "btnStart":     { code: 0x00, pos: { x: -1.0, y: 1.0 }, anchor: "ANCHOR_TOP_RIGHT", scale: 1, color: "#FFFFFF",  touchId: null },
      "btnCoin":      { code: 0x01, pos: { x: -2.0, y: 1.0 }, anchor: "ANCHOR_TOP_RIGHT", scale: 1, color: "#808080", touchId: null },
    };

    try {
      if (this.gameProfile == null) {
        // Add no extra buttons
      }
      else {
        this.layoutId = "game-controls-nine";
        if (this.gameProfile.controls.generic) {
          this.layoutId = this.gameProfile.controls.generic.css;
        }
        if (this.gameProfile.controls.specific) {
          this.layoutId = this.gameProfile.controls.specific.css;
        }
        const gameProfileButtons = this.gameProfile.controls.buttons;
        var layout = this.buttonLayouts[this.layoutId];
        for(var btnKey in this.allTouchButtons) {
          if (gameProfileButtons.hasOwnProperty(btnKey)) {
            var btn = this.allTouchButtons[btnKey];
            var profile = gameProfileButtons[btnKey];
            var btnLayout = layout[btnKey];
            btn.touchId = null;
            btn.pos = btnLayout.pos;
            btn.anchor = btnLayout.anchor;
            btn.scale = btnLayout.scale;
            btn.color = btnLayout.color;
            // See appendix 1 for mapbutton
            //https://docs.google.com/document/d/1hUyCp3i_0oQBfcxJU-Dhb5Eh8DwWJX90u9hJn5g1HnQ/edit
            if (profile.mapbutton) {
              if (profile.mapbutton == 17) {
                btn.code = 0x91;
              }
              if (profile.mapbutton == 18) {
                btn.code = 0x92;
              }
              else {
                console.log("ERROR: unhandled mapbutton:" + profile.mapbutton);
              }
            }
            if (profile.colour) {
              btn.color = "#" + profile.colour;
            }
            if (profile.offsetHorizontal) {
              btn.pos.x = profile.offsetHorizontal;
            }
            if (profile.offsetVertical) {
              btn.pos.y = -profile.offsetVertical;
            }
            this.buttons.push(btn);
          }
        };
      }
    }
    catch (e) {
      console.log("gameProfile member not found.");
      console.log(e);
    }

    if (this.layoutId == "game-controls-pinball") {
      this.inputSetup.num_virtual_joysticks = 0;
    }

    for (var i = 0; i < this.inputSetup.num_virtual_joysticks; i++) {
      this.virtualJoysticks.push({
        touchId: null,
        start: null,
        position: {}
      });
    }

    this.joyThrowUnits = 10;
    this.joyStickSize = this.buttonSize * 1.5;
    this.joyStickColor = WHITE_TRANSPARENT;
    this.joyThrowActivateMarginPercent = 75;

    this.touches = {};
  }

  isMobileOrTablet = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    }
    return false;
  }

  init = () => {
    console.log("[Control] init");
    this.bRunning = true;
    this.addEventListeners();
    this.update();
  }

  setFixedJoysticks = flag => {
    this.fixedJoysticks = flag;
  }

  remove = () => {
    console.log("[Control] remove");
    this.bRunning = false;
    this.removePointerControlListeners();
    this.removeEventListeners();
    this.canvas = null;
  }

  update = () => {
    if (this.canvas) {
      this.controlsUpdate();
      this.controlsRender();
      if(this.bRunning) {
        window.requestAnimationFrame(this.update);
      }      
    }
  }

  addEventListeners = () => {
    if (this.canvas) {
      this.canvas.addEventListener("touchstart", this.handleTouchStart, false);
      this.canvas.addEventListener("touchend", this.handleTouchEnd, false);
      this.canvas.addEventListener("touchcancel", this.handleTouchCancel, false);
      this.canvas.addEventListener("touchmove", this.handleTouchMove, false);
      window.addEventListener('resize', this.controlsRender, false);
    }
  }

  removeEventListeners = () => {
    if (this.canvas) {
      this.canvas.removeEventListener("touchstart", this.handleTouchStart, false);
      this.canvas.removeEventListener("touchend", this.handleTouchEnd, false);
      this.canvas.removeEventListener("touchcancel", this.handleTouchCancel, false);
      this.canvas.removeEventListener("touchmove", this.handleTouchMove, false);
      window.removeEventListener('resize', this.controlsRender, false);
    }
  }

  getDevicePixelRatio = () => {
    if (window.screen.systemXDPI !== undefined
      && window.screen.logicalXDPI !== undefined
      && window.screen.systemXDPI > window.screen.logicalXDPI) {
      return window.screen.systemXDPI / window.screen.logicalXDPI;
    } else if (window.devicePixelRatio !== undefined) {
      return window.devicePixelRatio;
    }
    return 1;
  };

  getAnchor = (label) => {
    const canvasWidth = this.canvas.width = window.innerWidth - 1;
    const canvasHeight = this.canvas.height = window.innerHeight - 1;
    if (label == "ANCHOR_BOTTOM_LEFT") {
      return { x: 0, y: canvasHeight };
    }
    if (label == "ANCHOR_BOTTOM_RIGHT") {
      return { x: canvasWidth, y: canvasHeight };
    }
    if (label == "ANCHOR_TOP_LEFT") {
      return { x: 0, y: 0 };
    }
    if (label == "ANCHOR_TOP_RIGHT") {
      return { x: canvasWidth, y: 0 };
    }
  };

  controlsReposition = () => {
    const pixelRatio = this.getDevicePixelRatio();
    const margin = this.marginSize * pixelRatio;

    this.buttons.forEach(btn => {
      const anchor = this.getAnchor(btn.anchor);
      btn.x = anchor.x + (btn.pos.x * margin);
      btn.y = anchor.y + (btn.pos.y * margin);
    });
  }

  controlsUpdate = () => {
    const pixelRatio = this.getDevicePixelRatio();

    // Handle joystick logic
    for (var idx = 0; idx < this.virtualJoysticks.length; idx++) {
      var joy = this.virtualJoysticks[idx];
      if (joy.touchId != null) {
        const px = this.touches[joy.touchId].pageX;
        const py = this.touches[joy.touchId].pageY;

        let sx = joy.start.pageX;
        let sy = joy.start.pageY;
        if (this.fixedJoysticks) {
          sx = idx === 0 ? this.fixedJoysticksPositions.left.x : window.innerWidth - this.fixedJoysticksPositions.right.x;
          sy = window.innerHeight - (idx === 0 ? this.fixedJoysticksPositions.left.y : this.fixedJoysticksPositions.right.y);
        }

        const dx = px - sx;
        const dy = py - sy;
        const dd = dx * dx + dy * dy;

        joy.position.x = px;
        joy.position.y = py;

        const tu = this.joyThrowUnits * pixelRatio;
        const d = Math.sqrt(Math.max(dd, 1.0));
        const nx = dx / d;
        const ny = dy / d;

        // Constrain position
        if (d > tu) {
          const ud = d - tu;
          joy.position.x -= nx * ud;
          joy.position.y -= ny * ud;
        }

        const magnitude = Math.sqrt(dd);
        let capped_x = 0;
        let capped_y = 0;
        if (magnitude > 0) {
          capped_x = dx / magnitude;
          capped_y = dy / magnitude;
        }
        if (d < tu) {
          capped_x *= d / tu;
          capped_y *= d / tu;
        }

        if(this.touchUpdated === true) {
            this.network.sendVirtualJoystickInput(idx, 1, capped_x, capped_y);
            this.touchUpdated = false;
        }

      }
      else {
        // Had to set a small delta for the X axis, for some reason
        // this prevents a crash in CGLib when using controls
        // packet history.
        if(this.touchUpdated === true) {
            this.network.sendVirtualJoystickInput(idx, 0, 0, 0);
            this.touchUpdated = false;
        }

      }
    }
  }

  touchCopy = ({ identifier, pageX, pageY }) => {
    return { identifier, pageX, pageY };
  }

  touchById = (idToFind) => {
    if (this.touches && this.touches.hasOwnProperty(idToFind)) {
      return this.touches[idToFind];
    }

    return null;
  }

  touchUpdate = (t, begin) => {
    this.controlsReposition();
    const pixelRatio = this.getDevicePixelRatio();
    const normX = t.pageX / window.innerWidth;
    const normY = t.pageY / window.innerHeight;

    // Detect if touch was for joystick
    const joyCount = this.virtualJoysticks.length;
    for (var idx = 0; idx < joyCount; idx++) {
      var joy = this.virtualJoysticks[idx];
      if (joy.touchId == null) {

        var isTouchInJVArea = false;
        isTouchInJVArea |= joyCount == 1 && (normX < 0.5 || this.layoutId === "");
        isTouchInJVArea |= joyCount == 2 && normX < 0.5 && idx == 0;
        isTouchInJVArea |= joyCount == 2 && normX > 0.5 && idx == 1;
        isTouchInJVArea &= normY > 0.2;

        if (isTouchInJVArea && begin) {
          joy.touchId = t.identifier;
          joy.start = this.touchCopy(t);
        }
      }
      else if (joy.touchId != null) {
        if (!this.touches.hasOwnProperty(joy.touchId)) {
          joy.touchId = null;
          joy.start = null;
        }
      }
    }

    // Check if touch was for button
    this.buttons.forEach(button => {
      if (button.touchId == null
        && begin
        && this.touches
        && this.touches.hasOwnProperty(t.identifier)) {
        const px = this.touches[t.identifier].pageX;
        const py = this.touches[t.identifier].pageY;
        const sx = button.x;
        const sy = button.y;
        const dx = px - sx;
        const dy = py - sy;
        const dd = dx * dx + dy * dy;
        const ts = this.buttonTouchSize * button.scale * pixelRatio;
        if (dd < ts * ts) {
          button.touchId = t.identifier;
          this.network.sendSpecialButtonInput(0, button.code, 1);
        }
      }
      else if (button.touchId != null) {
        if (!this.touches.hasOwnProperty(button.touchId)) {
          button.touchId = null;
          this.network.sendSpecialButtonInput(0, button.code, 0);
        }
      }
    })

    this.touchUpdated = true;
  }

  handleTouchStart = (e) => {
    e.preventDefault();
    const changedTouches = e.changedTouches;
    for (var i = 0; i < changedTouches.length; i++) {
      const t = changedTouches[i];
      if (this.touches) {
        this.touches[t.identifier] = this.touchCopy(t);
        this.touchUpdate(t, true);
      }
    }
  }

  handleTouchMove = (e) => {
    e.preventDefault();
    const changedTouches = e.changedTouches;
    for (var i = 0; i < changedTouches.length; i++) {
      const existingTouch = this.touchById(changedTouches[i].identifier);
      if (existingTouch != null) {
        const t = changedTouches[i];
        this.touches[t.identifier] = this.touchCopy(t);
        this.touchUpdate(t, false);
      }
    }
  }

  handleTouchEnd = (e) => {
    e.preventDefault();
    const changedTouches = e.changedTouches;
    for (var i = 0; i < changedTouches.length; i++) {
      const t = changedTouches[i];
      const existingTouch = this.touchById(t.identifier);
      if (existingTouch != null) {
        delete this.touches[t.identifier];
        this.touchUpdate(t, false);
      }
    }
  }

  handleTouchCancel = (e) => {
    e.preventDefault();
    const changedTouches = e.changedTouches;
    for (var i = 0; i < changedTouches.length; i++) {
      const t = changedTouches[i];
      const existingTouch = this.touchById(t.identifier);
      if (existingTouch != null) {
        delete this.touches[t.identifier];
        this.touchUpdate(t, false);
      }
    }
  }

  drawButton = (color, size, x, y) => {
    const pixelRatio = this.getDevicePixelRatio();
    const radius = size * pixelRatio;

    this.context.beginPath();
    this.context.arc(x, y, radius, 0, 2 * Math.PI, false);
    this.context.fillStyle = color;
    this.context.fill();
    this.context.lineWidth = this.buttonBorderSize * pixelRatio;
    this.context.strokeStyle = 'black';
    this.context.stroke();
  }

  drawJoystick = (startX, startY, x, y) => {
    const pixelRatio = this.getDevicePixelRatio();
    const radius = this.joyStickSize * pixelRatio;

    // draw joystick base
    this.context.beginPath();
    this.context.arc(startX, startY, radius * 2, 0, 2 * Math.PI, false);
    this.context.lineWidth = this.buttonBorderSize * pixelRatio;
    this.context.strokeStyle = this.joyStickColor;
    this.context.stroke();

    // draw joystick moving part
    this.context.beginPath();
    this.context.arc(x, y, radius, 0, 2 * Math.PI, false);
    this.context.fillStyle = this.joyStickColor;
    this.context.fill();
  }

  controlsRender = () => {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;

    this.controlsReposition();
    //this.controlsUpdate();

    this.context.clearRect(
      0, 0,
      this.canvas.width,
      this.canvas.height
    );

    this.buttons.forEach((btn) => {
      const size = this.buttonSize * btn.scale * (btn.touchId != null ? 1.1 : 1);
      this.drawButton(btn.color, size, btn.x, btn.y);
    })

    for (var idx = 0; idx < this.virtualJoysticks.length; idx++) {
      var joy = this.virtualJoysticks[idx];
      if (joy.touchId != null) {

        if (this.fixedJoysticks) {
          return this.drawJoystick(
            idx === 0 ? this.fixedJoysticksPositions.left.x : window.innerWidth - this.fixedJoysticksPositions.right.x,
            window.innerHeight - (idx === 0 ? this.fixedJoysticksPositions.left.y : this.fixedJoysticksPositions.right.y),
            joy.position.x,
            joy.position.y
          );
        }

        this.drawJoystick(
          joy.start.pageX,
          joy.start.pageY,
          joy.position.x,
          joy.position.y
        );
      }
    }
  }

   addPointerControlListeners = () => {
    this.pointerControlListeners.forEach(({ event, handler }) => {
      this.canvas.addEventListener(event, handler);
    })
  }

  removePointerControlListeners = () => {
    if (!this.canvas) return;

    this.pointerControlListeners.forEach(({ event, handler }) => {
      this.canvas.removeEventListener(event, handler);
    })
  }

  getElementCSSSize = (el) => {
    var cs = getComputedStyle(el);
    var w = parseInt(cs.getPropertyValue("width"), 10);
    var h = parseInt(cs.getPropertyValue("height"), 10);
    return {width: w, height: h}
  }

  getMouseLocation = (x, y) => {
    const size = this.getElementCSSSize(this.videoOutputTarget);

    return {
      x: x / size.width,
      y: y / size.height
    }
  }

  getTouchLocation = (x, y) => {
    const size = this.getElementCSSSize(this.videoOutputTarget);
    var rect = this.videoOutputTarget.getBoundingClientRect();

    return {
      x: (x - rect.left) / size.width,
      y: (y - rect.top) / size.height
    }
  }

  remapMouseButtons(button) {
    if (button === 0)
      return 0;
    if (button === 1)
      return 2;
    if (button === 2)
      return 1;
    return 0;
  }

  pointerControlListeners = [
    {
      event: 'click',
      handler: (e) => {
        this.pointerLockFailed = false;
        this.canvas.requestPointerLock().catch(() => {
          /** Fix for issue: "The user has exited the lock before this request was completed" **/
          /** If request failed - try on next click **/
          this.pointerLockFailed = true;
        });
        if (this.pointerLockFailed) return;

        if (document.pointerLockElement) {
          return;
        }
        this.mouseX = e.pageX;
        this.mouseY = e.pageY;
        const location = this.getMouseLocation(this.mouseX, this.mouseY);
        this.network.sendPointerInput(
          0, 1, 0, true,
          location.x,
          location.y,
          this.mouseButtons
        );
      }
    },
    {
      event: 'mousemove',
      handler: (e) => {
        // we don't do anything if the pointer is not locked
        if (!document.pointerLockElement) {
          return;
        }

        const sensibility = 1;

        this.mouseX += e.movementX * sensibility;
        this.mouseY += e.movementY * sensibility;

        const canvasSize = this.getElementCSSSize(this.videoOutputTarget);
        const location = this.getMouseLocation(this.mouseX, this.mouseY);
        this.network.sendPointerInput(
          0, 1, 0, true,
          location.x,
          location.y,
          this.mouseButtons
        );
      }
    },
    {
      event: 'mousedown',
      handler: (e) => {
        const button = this.remapMouseButtons(e.button);
        const buttonFlag = 1 << button;
        const location = this.getMouseLocation(this.mouseX, this.mouseY);
        this.network.sendPointerInput(
          0, 1, 1, true,
          location.x,
          location.y,
          buttonFlag
        );
        this.mouseButtons |= 1 << button;
      }
    },
    {
      event: 'mouseup',
      handler: (e) => {
        const button = this.remapMouseButtons(e.button);
        const buttonFlag = 1 << button;
        const location = this.getMouseLocation(this.mouseX, this.mouseY);
        this.network.sendPointerInput(
          0, 1, -1, true,
          location.x,
          location.y,
          buttonFlag
        );
        this.mouseButtons &= ~(1 << button);
      }
    },
    {
      event: 'touchstart',
      handler: (e) => {
        for (var i = 0; i < e.changedTouches.length; i++) {
          const t = e.changedTouches[i];
          var location = this.getTouchLocation(t.pageX, t.pageY);
          this.network.sendPointerInput(
            t.identifier, 0, 1, true,
            location.x,
            location.y,
            0
          );
        }
        e.preventDefault();
      }
    },
    {
      event: 'touchend',
      handler: (e) => {
        for (var i = 0; i < e.changedTouches.length; i++) {
          const t = e.changedTouches[i];
          var location = this.getTouchLocation(t.pageX, t.pageY);
          this.network.sendPointerInput(
            t.identifier, 0, -1, true,
            location.x,
            location.y,
            0
          );
        }
        e.preventDefault();
      }
    },
    {
      event: 'touchcancel',
      handler: (e) => {
        for (var i = 0; i < e.changedTouches.length; i++) {
          const t = e.changedTouches[i];
          var location = this.getTouchLocation(t.pageX, t.pageY);
          this.network.sendPointerInput(
            t.identifier, 0, -1, true,
            location.x,
            location.y,
            0
          );
        }
        e.preventDefault();
      }
    },
    {
      event: 'touchmove',
      handler: (e) => {
        for (var i = 0; i < e.changedTouches.length; i++) {
          const t = e.changedTouches[i];
          var location = this.getTouchLocation(t.pageX, t.pageY);
          this.network.sendPointerInput(
            t.identifier, 0, 0, true,
            location.x,
            location.y,
            0
          );
        }
        e.preventDefault();
      }
    },
    {
      event: 'contextmenu',
      handler: (e) => {
        e.preventDefault();
      }
    }
  ]
}
