import React from 'react'
import { openUrlOnDefaultBrowser } from '../../../../../assets/lib/game-wrapper'
import { Button } from '../../../button/common-button.component'
import './share-modal-button.component.less'
import { isDeviceSafari } from '../../../../../assets/lib/deviceInfo';

export const ShareModalButton = ({ icon, url, ariaLabel, alt, childId, parentId, layer=2}) => (
    <Button 
        aria-label={ariaLabel}
        childId={childId}
        className="ShareModalButton no-background"
        parentId={parentId}
        onClick={() => isDeviceSafari ? window.open(url) : openUrlOnDefaultBrowser(url)}
        rawButtonClick={isDeviceSafari}
        layer={layer}
    >
        <div className="ShareModalButton-container">
            <img className="share-source-image" src={icon} alt={alt}/>
        </div>
    </Button>
)

export default ShareModalButton;