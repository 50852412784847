import React, { Component } from 'react';
import { connect } from 'react-redux';
import './tournaments-participants.component.less';
import ListContainer, {LIST_TYPES} from '../../common/list/list-container.component';
import {FetchTournamentsPlayers} from '../tournaments.actions';
import {getCurrentTournamentDetailId, getFollowedPlayers, getPlayers} from '../tournaments.selector';
import {navigateToLocation, ROUTES} from '../../../app.router';
import inputManager from '../../../assets/lib/inputmanager';
import TournamentPlayerComponent from './tournament-player.component';

let prevTile = null;
class TournamentsParticipantsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lists : null
		};
	}

	componentDidMount() {
		this.props.dispatch(FetchTournamentsPlayers(this.props.tournamentId, this.prepareLists));
	}

	prepareLists = () => {
		const { players, followedPlayers } = this.props;
		const lists = [];

		lists.push({
			type: LIST_TYPES.HORIZONTAL,
			id:"followedPlayers",
			title:"Friends",
			data:followedPlayers,
			renderer: props => <TournamentPlayerComponent {...props} isFriends />
		});

		lists.push({
			type: LIST_TYPES.GRID,
			id:"players",
			title:"Latest Participants",
			data:players,
			renderer: props => <TournamentPlayerComponent {...props} />
		});

		this.setState({lists});
		this.checkPrevSelectedTile()
	};

	checkPrevSelectedTile = () =>{
		if(prevTile) {
			inputManager.setCurrentChildById(prevTile.childId, prevTile.parentId);
			prevTile = null;
		}
	};

	onSelect = (tileId, listId) => {
		prevTile = {
			parentId:listId,
			childId:tileId
		};

		navigateToLocation(ROUTES.USER_PROFILE, {id:tileId});
	};

	render() {
		return (
			<div className="tournaments-participants">
				<ListContainer
					lists={this.state.lists}
					onTileClick={this.onSelect}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const players = getPlayers(state);
	const followedPlayers = getFollowedPlayers(state);
	const tournamentId = getCurrentTournamentDetailId(state);

	return {
		tournamentId,
		players,
		followedPlayers
	};
};

export const TournamentsParticipants = connect(mapStateToProps)(TournamentsParticipantsComponent);
