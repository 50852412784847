// Packages
import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';

// Components
import { Button } from '../../common/button/common-button.component';
import ShopButton from '../../common/button/shop-button.component';
import * as Popup from '../popup.component';
import { getPaymentData, getPaymentPlans, getUserIsSubscribed } from '../../../app.selectors';
import { Loader } from '../../common/loader/loader.component';
import * as LoginActions from '../../login/login.actions';
import { store as ReduxStore } from '../../../configureStore';
import { getCurrency } from '../../common/payment-box/payment-box.component';

// Assets
import InputManager from '../../../assets/lib/inputmanager';
import shopIcon from '../../../assets/images/shop/shop-white-glow.png';
import * as Utils from '../../../assets/lib/utils';
import * as GameWrapper from '../../../assets/lib/game-wrapper';
import DeviceInfo, { STORE_TYPE_EPIC, OS_TYPE_OSX } from '../../../assets/lib/deviceInfo';
import * as EpicGames from '../../../assets/lib/epic-games.lib';

// CSS
import './shop-popup.component.less';

const navLayer = 2;
const shopLauncherParentId = 'launcherButtons'
const shopButtonsParentId = 'shopButtons';
const ONE_YEAR_SUBSCRIPTION_SKU = "8c34327be61b4c3783566492e13e6d58";
const EPIC_SUBSCRIPTION_SKU = "9b751945c64c46ce8d05500913fd0c2f";
const FREE_PACK_SKU = "d3c8cb59f587465294721ffb5d0a904c";

const ShopPopupComponent = () => {
    const dispatch = useDispatch();
    const [ isLoading, setIsLoading ] = useState(true);
    const isSubscribed = useSelector((state) => getUserIsSubscribed(state));
    const paymentPlans = useSelector((state) => getPaymentPlans(state));
    const paymentData = useSelector((state) => getPaymentData(state));
    const expireDate = Utils.getDateStringWithEpoch(paymentData.paymentDue);
    var premiumSubscriptionPlan = null;

    // find base price and base gems according to received data
    let basePrice = 0;
    let baseGems = 0;
    if (paymentPlans) {
        // sort by cheapest first
        paymentPlans.sort((a,b) => a.price - b.price); 
        const basePlan = paymentPlans[0];
        basePrice = basePlan.price;
        baseGems = EpicGames.SKU_DATA[basePlan.sku] && EpicGames.SKU_DATA[basePlan.sku].gems;

        // Get premium subscription item
        premiumSubscriptionPlan = paymentPlans.find(plan => plan.sku === ONE_YEAR_SUBSCRIPTION_SKU);
    }

    useEffect(() => {
        GameWrapper.getStoreCatalogue();
		InputManager.setBackFunction(() => Popup.closePopup());
        return () => InputManager.setBackFunction(null);
    }, []);

    useEffect(() => {
        // Because we have no changes OR callback on paymentPlans if they don't exist
        // Set a timeout to remove the loading after one second to avoid seeing the flicker
        setTimeout(() => {
            setIsLoading(false);
            const paymentPlans = getPaymentPlans(ReduxStore.getState());
            if (paymentPlans) {
                InputManager.setCurrentChildById("ShopPopupComponent-button-0", shopButtonsParentId);
            } else {
                InputManager.setCurrentChildById("LauncherPopup-storeButton", shopLauncherParentId);
            }
        }, 1000);
    }, [paymentPlans]);

    /**
     * Redirects to the Epic launcher store if its on a Mac since our launcher cant open the overlay.
     * Otherwise does the purchase with the normal overlay and closes the popup.
     * 
     * @param {string} selectedSku - the sku to purchase
     */
    const onClickShopButton = (selectedSku) => {
        if (DeviceInfo.osType === OS_TYPE_OSX) {
            return GameWrapper.openUrlOnDefaultBrowser(EpicGames.getLauncherProductUrl(selectedSku));
        }

        dispatch(LoginActions.SendSelectedSKU(selectedSku, "EpicStorePurchase"));
        Popup.closePopup();
    }

    /**
     * We need to use a setTimeout here to fake the loading so the user knows something is happening.
     * This is not ideal but because of how our store is setup, it would take too much time that
     * we currently don't have to get this live to actually do this the correct way.
     * There are checks and popups in place in case anything goes wrong OR on sucessful purchases so
     * in reality this loading serves no purpose other than user responsiveness.
     */
    const onClickRedeemButton = () => {
        setIsLoading(true);

        dispatch(LoginActions.OnNativePaymentResponse({
            store: STORE_TYPE_EPIC,
            response: {}
        }));

        setTimeout(() => {
            Popup.closePopup();
            setIsLoading(false);
        }, 1500);
    }

    if (isLoading) {
        return (
            <Loader loading={true}/>
        );
    }

    if (!paymentPlans) {
        GameWrapper.openUrlOnDefaultBrowser(EpicGames.LAUNCHER_ALL_DLC_URL);

        return (
            <div className="shop-popup-component">
                <div className="title-text">
                    Gem Shop
                    <img className="shop-icon" src={shopIcon} alt="shop icon" />
                </div>

                <div className="shop-content-text">
                    Please use the Epic Launcher to purchase, and then click Redeem Purchases.
                </div>

                <div className="bottom-container">
                    <Button
                        parentId={shopLauncherParentId}
                        className="secondary"
                        onClick={() => Popup.closePopup()}
                        childId="LauncherPopup-doneButton"
                        layer={navLayer}
                    >
                        Close
                    </Button>
                    <Button
                        parentId={shopLauncherParentId}
                        className="secondary epic-store-redeem"
                        onClick={onClickRedeemButton}
                        childId="LauncherPopup-redeemButton"
                        layer={navLayer}
                    >
                        Redeem Purchases
                    </Button>
                    <Button
                        parentId={shopLauncherParentId}
                        className="primary"
                        onClick={() => GameWrapper.openUrlOnDefaultBrowser(EpicGames.LAUNCHER_ALL_DLC_URL)}
                        childId="LauncherPopup-storeButton"
                        layer={navLayer}
                    >
                        Open Store
                    </Button>
                </div>
            </div>
        );
    }

    const filteredPaymentPlans = paymentPlans.filter((catalogueItem) => catalogueItem.sku!==ONE_YEAR_SUBSCRIPTION_SKU && catalogueItem.sku !== EPIC_SUBSCRIPTION_SKU && catalogueItem.sku!==FREE_PACK_SKU && !catalogueItem.unavailable);

    return (
        <div className="shop-popup-component">
            <div className="title-text">
                Gem Shop
                <img className="shop-icon" src={shopIcon} alt="shop icon" />
            </div>

            <div className="shop-content">
                {filteredPaymentPlans.map((catalogueItem, index) =>
                    <ShopButton
                        catalogueItem={catalogueItem}
                        basePrice={basePrice}
                        baseGems={baseGems}
                        onClick={() => onClickShopButton(catalogueItem.sku)}
                        parentId={shopButtonsParentId}
                        childId={`ShopPopupComponent-button-${index}`}
                        key={`ShopPopupComponent-button-${index}`}
                        layer={navLayer}
                    />
                )}
                
                <Button
                    className="premium-shop-button"
                    onClick={() => onClickShopButton(premiumSubscriptionPlan.sku)}
                    layer={navLayer}
                    disabled={isSubscribed}
                    parentId={shopButtonsParentId}
                    childId="ShopPopupComponent-buttonPremium"
                >
                    <div className="button-title">
                        Premium Subscription
                    </div>
                    <div className="button-illus">
                    </div>
                    <div className="button-illus-1year">
                    </div>
                    <ul>
                        <li>Unlimited access</li>
                        <li>Higher rewards</li>
                        <li>Exclusive content</li>
                        <li>Higher daily gems</li>
                        <li>+6,000 gems bonus</li>
                    </ul>
                    <div className="button-cost">
                        <div className={`button-cost-inner`}>
                            {isSubscribed &&
                                <div>
                                    <div className="subscribed-text">
                                        SUBSCRIBED
                                    </div>
                                    <div className="expire-date">
                                        {'Ends: ' + expireDate}
                                    </div>
                                </div>
                            }
                            {!isSubscribed && premiumSubscriptionPlan && 
                                `${getCurrency(premiumSubscriptionPlan.currencyString)}${premiumSubscriptionPlan.price}`}
                            </div>
                        </div>
                    </Button>
                
            </div>

            <div className="bottom-container">
                <Button
                    className="secondary epic-store-redeem"
                    onClick={onClickRedeemButton}
                    childId="ShopPopup-redeemButton"
                    layer={navLayer}
                >
                    Redeem Purchases
                </Button>
                <Button
                    className="secondary"
                    onClick={() => Popup.closePopup()}
                    childId="ShopPopup-doneButton"
                    layer={navLayer}
                >
                    Done
                </Button>
            </div>
        </div>
    )
}

const ShopPopup = connect()(ShopPopupComponent);
export default ShopPopup;
