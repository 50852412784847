import {antstreamAPIService, antstreamService} from '../../app.reducer';
import {challengeHistoryObjectNormalizer} from '../entities.normaliser';
import {UpdateChallengesData} from '../challenges/challenges.actions';
import {MESSAGE_TYPES} from '../../assets/lib/MessageTypes';
import {getAssetsUrlWithFolder} from '../../app.selectors';
import {appendAssetsUrlToTournament} from "../../components/tournaments/tournaments.actions";
import {FOLDER_TYPES} from "../../assets/lib/FolderTypes";
import {readRestUserData} from "../../assets/lib/local-storage";
import React from "react";
import {ROUTES} from "../../app.router";
import {handleNoAuthTokenIssue} from "../../assets/lib/utils";

export const FETCH_MESSAGES = 'Fetch Messages Action';
export const FetchMessagesAction = (onFetchComplete) => {
	return (dispatch, getState) => {
		dispatch({ type: FETCH_MESSAGES });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(FETCH_MESSAGES);

			const params = {
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.messages.inboxList(params).then(({ data }) => {
				if (!data || !data.data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);

				const {challenges, messages, messageCount} = data.data;

				/**
				 * Not all challenge records located in 'challenges'.
				 * Some of them (challenges) located in 'details.contentObject'.
				 * In this case we need to get them from there for UpdateChallengesData() action. 
				 **/
				const podiumChallenges = messages
					.filter(m => m?.type === MESSAGE_TYPES.PODIUM_UPDATE
						&& m.details?.podiumUpdateType === 'CHALLENGE')
					.map(m => m.details?.contentObject);
				if (podiumChallenges.length) {
					podiumChallenges.forEach(pc => {
						const alreadyExists = challenges.find(c => c._id === pc._id);
						if (alreadyExists) return;

						challenges.push(pc);
					});
				}

				dispatch(UpdateChallengesData(challenges));
				dispatch(FetchMessagesActionSuccess({messages})); // dispatch messages to store and return normalisedMessages

				const isInboxPage = getState().routing.currentRoute.path === ROUTES.INBOX.path;
				if (isInboxPage && messageCount > 0) {
					dispatch(FetchMessageCountActionSuccess(0));
				} else {
					dispatch(FetchMessageCountActionSuccess(messageCount));
				}

				if (onFetchComplete) onFetchComplete();
			}).catch(catchErr => {
				dispatch(FetchMessagesActionFail(catchErr));
			});

	};
};

export const READ_MESSAGES_ACTION = 'READ_MESSAGES_ACTION';
export const ReadMessagesAction = () => {
	return (dispatch) => {
		dispatch({ type: READ_MESSAGES_ACTION });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(READ_MESSAGES_ACTION);

			const params = { headers: { Authorization: 'Bearer ' + authToken } };

			antstreamAPIService.messages.readCreate(params).then(({ data }) => {
				if (!data || !data.result) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);

				dispatch(FetchMessageCountActionSuccess(0));
			}).catch(catchErr => {
				console.error('Failed to read messages: ', catchErr);
			});

	};
};

export const FETCH_MESSAGES_SUCCESS = 'Fetch Messages Success';
const FetchMessagesActionSuccess = ({messages}) => {
	return (dispatch,getState) => {
		const normalizedMessages = [];
		messages.forEach((message) => {
			const messageClass = message["@class"];
			const normalizedMessage = {
				messageId:message.messageId,
				type:message.type,
				"@class":messageClass
			};
			if (messageClass === ".ChallengeIssuedMessage" || messageClass === ".ChallengeLostMessage" || messageClass === ".ChallengeWonMessage" || messageClass === ".ChallengeDrawnMessage") {
				if (messageClass === ".ChallengeIssuedMessage") {
					normalizedMessage.challengeMessageData = challengeHistoryObjectNormalizer(getState(),message.challengeMessageData);
				} else {
					normalizedMessage.challengeMessageData = challengeHistoryObjectNormalizer(getState(),message.challengeHistoryObject,true);
				}

				if(normalizedMessage.challengeMessageData) normalizedMessages.push(normalizedMessage);
			} else if (messageClass === ".ScriptMessage") {
				if (message.type === MESSAGE_TYPES.TOURNAMENT_ENDED ||
					message.type === MESSAGE_TYPES.TOURNAMENT_STARTED ||
					message.type === MESSAGE_TYPES.TOURNAMENT_ONE_DAY
				) {
					message.result.tournamentObject = appendAssetsUrlToTournament(message.result.tournamentObject, getAssetsUrlWithFolder(getState(), FOLDER_TYPES.TOURNAMENTS));
					normalizedMessage.result = message.result;
					normalizedMessages.push(message);
				} else if (message.type === MESSAGE_TYPES.NEW_FOLLOWER) {
					normalizedMessages.push(message);
				} else if (message.type === MESSAGE_TYPES.PODIUM_UPDATE ||
						   message.type === MESSAGE_TYPES.SOCIAL_UPDATE ) {
					normalizedMessages.push(message);
				}
			}
		});
		dispatch({ type: FETCH_MESSAGES_SUCCESS,  messages: normalizedMessages });
		return normalizedMessages
	};
};

export const FETCH_MESSAGES_COUNT_SUCCESS = 'FETCH_MESSAGES_COUNT_SUCCESS';
export const FetchMessageCountActionSuccess = (messageCount) => ({
	type: FETCH_MESSAGES_COUNT_SUCCESS,
	messageCount
});

export const ON_TOURNAMENT_ENDED_MESSAGE = 'ON_TOURNAMENT_ENDED_MESSAGE';
export const onTournamentEndedMessage = (result) => ({
	type: ON_TOURNAMENT_ENDED_MESSAGE,
	result
});

export const FETCH_MESSAGES_FAIL = 'Fetch Messages Fail';
const FetchMessagesActionFail = (payload) => ({
	type: FETCH_MESSAGES_FAIL,
	payload
});
