
export const ADD_DELAYED_POPUP_ACTION = 'ADD_DELAYED_POPUP_ACTION';
export const AddDelayedPopupAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: ADD_DELAYED_POPUP_ACTION, payload });
	};
};

export const CLEAR_DELAYED_POPUPS_ACTION = 'CLEAR_DELAYED_POPUPS_ACTION';
export const ClearDelayedPopupAction = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_DELAYED_POPUPS_ACTION });
	};
};

export const POPUP_VISIBILITY_ACTION = 'POPUP_VISIBILITY_ACTION';
export const PopupVisibilityAction = (isHidden) => {
	return (dispatch) => {
		dispatch({ type: POPUP_VISIBILITY_ACTION, isHidden });
	};
};