
export const getSettings = state  => state.settings;
export const getQualitySetting = state  => state.settings.quality_setting;
export const getControlEditing = state => state.settings.control_editing;
export const getShowLagGraph = state => state.settings.show_lag_graph;
export const getHeadlessNative = state => state.settings.headless_native;
export const getShowControllerDebug = state => state.settings.show_controller_debug;
export const getLocalGameServer = state => state.settings.local_game_server;
export const getRegions = state => state.settings.regions;
export const getCurrentRegionObject = state => state.settings.currentRegionObject;
export const getNoVblank = state => state.settings.no_vblank;
export const getUseSmoothing = state => state.settings.use_smoothing;
export const getUseRumble = state => state.settings.use_rumble;
export const getConnectionTestResults = state => state.settings.connectionTestResults;
export const getTestSessionRequested = state => state.settings.testSessionRequested;
export const getTestSessionCreated = state => state.settings.testSessionCreated;
export const getBandwidthTestResults = state => state.settings.bandwidthTestResults;
export const getConnectionTesterPopupSeen = state => state.settings.connectionTesterPopupSeen;
export const getConnectionTesterSupported = state => state.settings.connectionTesterSupported;
export const getLanguage = state => state.settings.language;
export const getControlPreferencesDpad = state => state.settings.controlPreferencesDpad;
export const getControlPreferencesRumble = state => state.settings.controlPreferencesRumble;
export const getControlPreferencesTransparency = state => state.settings.controlPreferencesTransparency;
export const getControlPreferencesVolume = state => state.settings.controlPreferencesVolume;
