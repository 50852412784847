import React, {Component} from 'react';
import './tournament-message.component.less';
import {MESSAGE_TYPES} from '../../../assets/lib/MessageTypes';
import { navigateToLocation, ROUTES } from '../../../app.router';
import {connect} from "react-redux";

class TournamentMessageComponent extends Component {
	constructor() {
		super();
		this.onItemClick=this.onItemClick.bind(this);
	}

	renderEndedMessage = () => {
		const {tournamentObject, leaderBoardObject, gemPrize} = this.props.item.result;
		return (
			<div className="tournament-message" onClick={this.onItemClick}>
				<img className="banner" src={tournamentObject.messageImage} alt={tournamentObject.title}/>
				<div className="content">
					<span>Position: {leaderBoardObject ? leaderBoardObject.rank : "N/A"}</span>
					<span>
						Won {gemPrize} Gems
					</span>
				</div>
			</div>
		);
	};

	renderStartMessages = () => {
		const {tournamentObject} = this.props.item.result;
		return (
			<div className="tournament-message" onClick={this.onItemClick}>
				<img className="banner" src={tournamentObject.messageImage} alt={tournamentObject.title}/>
				<div className="content">
					<span>{tournamentObject.title}</span>
				</div>
			</div>
		);
	};

	onItemClick = (messageId) => {
		navigateToLocation(ROUTES.TOURNAMENTS_INFO, {id:this.props.item.result.tournamentObject._id});
	};
	
	render() {
		if (this.props.item.type === MESSAGE_TYPES.TOURNAMENT_ENDED) {
			return this.renderEndedMessage();
		} else {
			return this.renderStartMessages();
		}
	}
}

export const TournamentMessage = connect()(TournamentMessageComponent);
