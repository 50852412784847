import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar } from '../../common/avatar/avatar.component';
import './message-list-item.component.less';
import { getAllBadgesById } from '../../../entities/entities.selectors';
import { getLoggedUser, getAssetsUrlWithFolder } from '../../../app.selectors';
import { MESSAGE_TYPES } from '../../../assets/lib/MessageTypes';
import tournamentsLogo from '../../../assets/images/global/tournaments-banner-icon.svg';
import { FOLDER_TYPES } from '../../../assets/lib/FolderTypes';
import { isLowSpecDevice } from '../../../app.helpers';

class MessageListItemComponent extends Component {
	formatData = (message) => {
		if (message.type === MESSAGE_TYPES.TOURNAMENT_ENDED) {
			return {
				badge: tournamentsLogo,
				messageType: message.type,
				title: "Tournament Ended"
			};
		} else if (message.type === MESSAGE_TYPES.TOURNAMENT_STARTED) {
			return {
				badge: tournamentsLogo,
				messageType: message.type,
				title: "New Tournament Available!"
			};
		} else if (message.type === MESSAGE_TYPES.TOURNAMENT_ONE_DAY) {
			return {
				badge: tournamentsLogo,
				messageType: message.type,
				title: "Tournament Starting in 1 day"
			};
		} else if (message.type === MESSAGE_TYPES.NEW_FOLLOWER) {
			return {
				opponent: message.follower,
				messageType: message.type,
				title: "You have a new follower!"
			};
		}
		else if (message.type === MESSAGE_TYPES.PODIUM_UPDATE || message.type === MESSAGE_TYPES.SOCIAL_UPDATE) {
			let gameTile = null;
			let title = message.type === MESSAGE_TYPES.PODIUM_UPDATE ? "Podium Overtake!" : "Leaderboard Overtake!";

			if ((message.details.podiumUpdateType === "CHALLENGE") ||
				(message.details.socialUpdateType === "CHALLENGE")) 
			{
				if (message.details.contentObject.img_src.includes('http')) {
					gameTile = message.details.contentObject.img_src;
				} else {
					gameTile = this.props.challengeAssets + message.details.contentObject.img_src;
				}
			}
			else if ((message.details.podiumUpdateType === "TOURNAMENT") ||
					 (message.details.socialUpdateType === "TOURNAMENT")) 
			{
				gameTile = this.props.tournamentAssets + message.details.contentObject.detailImage;

			}
			else {
				gameTile = this.props.gameAssets + message.details.contentObject.image_uri;
			}
			return {
				badge: gameTile,
				messageType: message.type,
				title: title
			};
		}
		const { opponent, challengeTitle, challengeState, challengeType, challengeId } = message.challengeMessageData;
		const type = message["@class"];

		switch (type) {
			case '.ChallengeIssuedMessage':
				return {
					opponent: opponent,
					title: challengeTitle
				};
			case '.ChallengeLostMessage':
			case '.ChallengeWonMessage':
			case '.ChallengeDrawnMessage':
				const badgeObj = this.props.badges[challengeType];
				const badge = badgeObj.outcomes[challengeState];
				return {
					badge: badge,
					title: "Challenge Complete"
				};
			default:
				return {
					title: type
				};
		}
	};

	renderItem = () => {
		const { title, opponent, badge } = this.formatData(this.props.item);
		return (
			<div className="item-inner">
				<div className="image-container">
					{opponent && <Avatar userData={opponent} />}
					{badge && <img className="icon" src={badge} alt="" />}
				</div>
				<span className="text">{title}</span>
			</div>
		);
	};

	onSelect = () => {
		this.props.onSelect && this.props.onSelect(this.props.value);
	};

	render() {
		const animateAllowed = !isLowSpecDevice();

		return (
			<div tabIndex="-1" className="inbox-list-item">
				{this.renderItem()}
				<div className={`pulsing-border ${animateAllowed ? 'pulsing-border--animate' : ''}`}></div>
				<div className="triangle">
					<div className={`triangle-pulsing-border ${animateAllowed ? 'triangle-pulsing-border--animate' : ''}`}></div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		badges: getAllBadgesById(state),
		userId: getLoggedUser(state)._id,
		gameAssets: getAssetsUrlWithFolder(state, FOLDER_TYPES.BROCHURE_CONTENT_ASSETS),
		challengeAssets: getAssetsUrlWithFolder(state, FOLDER_TYPES.CHALLENGE_GAMES),
		tournamentAssets: getAssetsUrlWithFolder(state, FOLDER_TYPES.TOURNAMENTS)
	};
};
export const MessageListItem = connect(mapStateToProps)(MessageListItemComponent);