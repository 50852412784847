import * as ACTIONS from './challenge.actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
	UNLOCK_CHALLENGE,
	UNLOCK_CHALLENGE_FAIL,
	UNLOCK_CHALLENGE_SUCCESS
} from "../../entities/challenges/challenges.actions";
import { paramsToObject } from '../../app.helpers';

const initialState = {
	loading: false,
	opponents: [],
	selectedChallengeHistory: {},
	giantSlayerChallenges: [],
	selectedGiantSlayerChallengeId: null,
	giants: [],
	retryCost: 0,
	unlockChallengePending: false,
	abandonedGiantSlayerChallengeId: null,
};

export const challengeReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACTIONS.SET_CURRENT_CHALLENGE_ID:
	case LOCATION_CHANGE:
		const query = action.payload.query
			|| paramsToObject(action.payload.location.search);
		const {id, challengeId} = query;
		return {
			...state,
			gameId: id,
			challengeId: challengeId,
		};
	case ACTIONS.FETCH_OPPONENTS:
		return {
			...state,
			loading: true,
			opponents: []
		};
	case ACTIONS.FETCH_OPPONENTS_SUCCESS:
		return {
			...state,
			loading: false,
			opponents: action.payload
		};
	case ACTIONS.FETCH_OPPONENTS_ERROR:
		return {
			...state,
			loading: false,
			opponents: []
		};
	case ACTIONS.SET_CHALLENGE_RESULT:
		return {
			...state,
			loading: false,
			challengeHistoryObject: action.challengeHistoryObject
		};
	case ACTIONS.SET_SELECTED_CHALLENGE_HISTORY:
		return {
			...state,
			selectedChallengeHistory: action.historyItem
		};

	// No need to reset giantSlayerChallenges state every time we're making new request,
	// because it breaks a game auto-selection when we get back from the game info page to the homepage.
	// Note: we're resetting giantSlayerChallenges list in order to fix the issue when user can quickly play the same gs challenge 2 times.
	// But there is another solution for this - MARK_GIANT_SLAYER_CHALLENGE_AS_SLAYER.
	// case ACTIONS.GET_GIANT_SLAYER_CHALLENGES_PENDING:
	// 	return {
	// 		...state,
	// 		giantSlayerChallenges: []
	// 	};
	case ACTIONS.MARK_GIANT_SLAYER_CHALLENGE_AS_SLAYER: {
		return {
			...state,
			giantSlayerChallenges: state.giantSlayerChallenges.map(gsc => {
				if (gsc.giantSlayerChallengeId !== action.payload) return gsc;
				if (gsc.role !== 'eligible') return gsc;

				return {
					...gsc,
					role: 'slayer'
				};
			})
		};
	}
	case ACTIONS.GET_GIANT_SLAYER_CHALLENGES:
		if (state.abandonedGiantSlayerChallengeId) {
			const giantSlayerChallenges = action.payload.map(gsc => {
				if (gsc.giantSlayerChallengeId === state.abandonedGiantSlayerChallengeId) {
					return {
						...gsc,
						role: 'eligible'
					};
				}
				return gsc;
			});
			return {
				...state,
				abandonedGiantSlayerChallengeId: null,
				giantSlayerChallenges 
			};
		}
		return {
			...state,
			giantSlayerChallenges: action.payload
		};
	case ACTIONS.SET_SELECTED_GIANT_SLAYER_CHALLENGE_ID:
		return {
			...state,
			selectedGiantSlayerChallengeId: action.payload
		};
	case ACTIONS.SET_GIANTS:
		return {
			...state,
			giants: action.payload
		};
	case ACTIONS.SET_RETRY_COST:
		return {
			...state,
			retryCost: action.retryCost
		};
	case UNLOCK_CHALLENGE:
		return {
			...state,
			unlockChallengePending: true
		};
	case UNLOCK_CHALLENGE_SUCCESS:
	case UNLOCK_CHALLENGE_FAIL:
		return {
			...state,
			unlockChallengePending: false
		};
	case ACTIONS.ABANDON_GIANT_SLAYER_CHALLENGE:
		if (action.payload) {
			return {
				...state,
				abandonedGiantSlayerChallengeId: action.payload
			};
		}
		return state;
	default:
		return state;
	}
};
