import React from 'react';
import { PulsarComponent } from "../../common/pulsar/pulsar.component";
import leftArrow from "../../../assets/images/generic-icons/tournament_grouping_arrow_left.svg";
import tournamentsLogo from '../../../assets/images/global/tournaments-banner-icon.svg';
import './tournaments-list-item.component.less';
import Countdown from "../info/countdown.component";
import { TOURNAMENT_OBJECT_TYPE } from "../tournamentConstants";
import { InView } from "react-intersection-observer";
import {isLowSpecDevice} from '../../../app.helpers';

const TournamentsListItem = ({ tournamentObject, selected }) => {
	const { title, banner, startDate, endDate, type } = tournamentObject;

	const [inView, setInView] = React.useState(false);

	return (
		<div className={`tournaments-list-item-component ${selected ? 'selected' : ''}`}>

			{type === TOURNAMENT_OBJECT_TYPE.GROUP && 
			<p className="group-title">{title} <img src={leftArrow} alt="left-arrow" /></p>}
			<InView onChange={setInView}  >
				{({ ref, inView }) => (			
					<div  ref={ref} className="content">
						<img className="banner" src={inView ? banner: ""} alt={title} />
						{inView && startDate && endDate && <Countdown startTimeStamp={startDate} endTimeStamp={endDate} />}
						{inView == true && <>
							<div className="details">
								<img className="icon" src={tournamentsLogo} alt="Logo" />
								<span className="title">{title}</span>
							</div>
						</>}
						<PulsarComponent />
					</div>)}
			</InView>

		</div>
	);
};

export default TournamentsListItem;