import React, { Component } from 'react';
import './tab-menu-item.component.less';
import inputManager from '../../../assets/lib/inputmanager';
import AudioManager, {TYPE_VERTICAL_LIST_ROW_CHANGE} from '../../../app.audio';

export const TEXT_SIZE = {
	LARGE: 'large-text',
	MEDIUM: 'medium-text',
	SMALL: 'small-text'
}

class TabMenuItemComponent extends Component {
	constructor() {
		super();
		this.state = {selected:false};
	}

	onCustomFocus = (focus/*, nextElement*/) => {
		this.setState({selected:focus});
		if(focus) this.props.onSelect && this.props.onSelect(this.props.item);
	};

	onTabItemClicked = (isPlayAudio) => {
		if (!this.state.selected && isPlayAudio) {
			AudioManager.playAudio(TYPE_VERTICAL_LIST_ROW_CHANGE);
		}
		inputManager.setCurrentChildById(this.props.parentId+'-'+this.props.item.title, this.props.parentId);
		this.props.handleClick && this.props.handleClick(this.props.item);
	};

	onTileRef = (element) => {
		inputManager.onChildRef(element, this.props.parentId+'-'+this.props.item.title, this.onCustomFocus, this.props.parentId);
	};

	render() {
		const {
			item,
			currentRoute,
			isActive,
			isPlayAudio = true,
			textSize = TEXT_SIZE.LARGE,
		} = this.props;

		const active = (isActive) || (currentRoute && currentRoute === item.route)  ? 'active' : '';
		return (
			<div
				ref={this.onTileRef}
				onClick={() => this.onTabItemClicked(isPlayAudio)}
				className={`tab-menu-item-component ${active} ${this.state.selected?'selected':''} tab-menu-item-component-${textSize}`}
			>
				<span className="text-container">{item.title}</span>
			</div>
		);
	}
}

export default TabMenuItemComponent;
