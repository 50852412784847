import React from "react";
import { navigateToLocation, ROUTES } from "../../app.router";

import PodiumItem from "./podiumItem";

function PodiumComponent({firstUser, podiumImageSource, onPodiumContainerRef}) {
    
	function onPodiumUserClicked(leaderBoardItem) {
        const id = leaderBoardItem._id;
        navigateToLocation(ROUTES.USER_PROFILE, { id });
	}
    
    function renderPodiumItems() {
		const items = [];

		firstUser.forEach((leaderBoardItem) => {
			items.push(
				<PodiumItem
					key={leaderBoardItem.rank}
					leaderBoardItem={leaderBoardItem}
					onPodiumUserClicked={onPodiumUserClicked}
				/>
			);
		});

		return items;
	}

    return (
        <div ref={onPodiumContainerRef} className="podium-container">
            <img alt="podium-bg" src={podiumImageSource} />
            {firstUser.length > 0 && renderPodiumItems()}
        </div>
    );
}

export default PodiumComponent;