import { readRestUserData } from '../../assets/lib/local-storage';
import { antstreamAPIService } from '../../app.reducer';
import { asviUpdatedAction } from '../games/games.actions';
import { getCurrentGameDetails } from '../../components/game-details/game-details.selector';
import { ShowNotificationAction } from '../../components/notifications/notifications.actions';
import { handleNoAuthTokenIssue, swapObjectKeyValues } from '../../assets/lib/utils';

export const GET_CONTROLLER_MAPPINGS_ACTION = 'GET_CONTROLLER_MAPPINGS_ACTION';
export const GET_CONTROLLER_MAPPINGS_ACTION_SUCCESS = 'GET_CONTROLLER_MAPPINGS_ACTION_SUCCESS';
export const GET_CONTROLLER_MAPPINGS_ACTION_ERROR = 'GET_CONTROLLER_MAPPINGS_ACTION_ERROR';
export const GetControllerMappingsAction = (gameId, onComplete) => dispatch => {
	dispatch({ type: GET_CONTROLLER_MAPPINGS_ACTION });
	const { authToken } = readRestUserData() || {};
	if (!authToken) handleNoAuthTokenIssue(GET_CONTROLLER_MAPPINGS_ACTION);

	antstreamAPIService.v2.settingsControllerMappingsList({ gameId },{
		headers: { Authorization: 'Bearer ' + authToken }
	}).then(({ data }) => {
		if (data.error) throw new Error(data.error);

		const response = JSON.parse(data.body);
		const adapted = adaptMappingForServerAndFromServer(response?.data);

		dispatch({ type: GET_CONTROLLER_MAPPINGS_ACTION_SUCCESS, payload: adapted });
		if (onComplete) onComplete();
	}).catch(settingsControllerMappingsListErr => {
		dispatch({ type: GET_CONTROLLER_MAPPINGS_ACTION_ERROR });
		console.error('Error on getting mappings: ' + settingsControllerMappingsListErr.message);
	});
};

/**
 * we need to send mapping in swapped key/values format, because that's how game server works.
 * "if I assign X to jump (which is normally A), then source is X and target is A, because the user mapper will need to transform X into A"
 **/
export function adaptMappingForServerAndFromServer(mapping) {
	if (!mapping) return;

	return {
		...mapping,
		gamepad: {
			...mapping.gamepad,
			mappings: swapObjectKeyValues(mapping.gamepad.mappings)
		}
	};
}

export const PATCH_CONTROLLER_MAPPINGS_ACTION = 'PATCH_CONTROLLER_MAPPINGS_ACTION';
export const PATCH_CONTROLLER_MAPPINGS_ACTION_SUCCESS = 'PATCH_CONTROLLER_MAPPINGS_ACTION_SUCCESS';
export const PATCH_CONTROLLER_MAPPINGS_ACTION_ERROR = 'PATCH_CONTROLLER_MAPPINGS_ACTION_ERROR';
export const PatchControllerMappingsAction = (gameId, mapping, sessionId) => dispatch => {
	if (!mapping) return;

	dispatch({ type: PATCH_CONTROLLER_MAPPINGS_ACTION });
	const { authToken } = readRestUserData() || {};
	if (!authToken) handleNoAuthTokenIssue(PATCH_CONTROLLER_MAPPINGS_ACTION);

	antstreamAPIService.v2.settingsControllerMappingsPartialUpdate({ gameId, sessionId },{
		headers: { Authorization: 'Bearer ' + authToken },
		body: JSON.stringify(adaptMappingForServerAndFromServer(mapping))
	}).then(({ data }) => {
		if (data.error) throw new Error(data.error);
		if (data.statusCode !== 200) throw new Error(JSON.parse(data.body).error);

		const response = data.data;

		dispatch(ShowNotificationAction('Mapping Saved'));
		dispatch({ type: PATCH_CONTROLLER_MAPPINGS_ACTION_SUCCESS, payload: response });
	}).catch(settingsControllerMappingsPartialUpdate => {
		dispatch({ type: PATCH_CONTROLLER_MAPPINGS_ACTION_ERROR });
		dispatch(ShowNotificationAction('Failed to update mapping. Please try later.'));
		console.error('Error on setting mappings: ' + settingsControllerMappingsPartialUpdate.message);
	});
};

export const ResetControllerMappingsAction = (gameId, sessionId) => (dispatch, getState) => {
	const gameDetails = getCurrentGameDetails(getState());
	const mapping = getDefaultGamepadMapping(gameDetails.DEFAULT_asvi_actions);

	dispatch(PatchControllerMappingsAction(gameId, mapping, sessionId));
	dispatch({type: CONTROLLER_MAPPINGS_SWAP_BUTTONS_ACTION, payload: {gameId, mapping}});
	dispatch(asviUpdatedAction(gameId, gameDetails.DEFAULT_asvi_actions));
};

function getDefaultGamepadMapping(asvi) {
	const m = {
		gamepad: {
			mappings: {},
			axisInversions: {
				'Gamepad_LeftX': false,
				'Gamepad_LeftY': false,
				'Gamepad_RightX': false,
				'Gamepad_RightY': false
			}
		}
	};

	Object.keys(asvi).forEach(key => {
		const actionKey = asvi[key]?.gamepad?.[0];
		if (!actionKey) return;
		m.gamepad.mappings[actionKey] = actionKey;
	});

	Object.keys(asvi).forEach(key => {
		const actionKey = asvi[key]?.gamepad?.[1];
		if (!actionKey) return;
		m.gamepad.mappings[actionKey] = actionKey;
	});

	return m;
}

export const ControllerMappingChangeAxis = (gameId, c) => (dispatch, getState) => {
	let mapping = getState().entities.mapping.byId[gameId];
	if (!mapping) {
		const gameDetalis = getCurrentGameDetails(getState());
		mapping = getDefaultGamepadMapping(gameDetalis.asvi_actions);
	}

	const nextMapping = JSON.parse(JSON.stringify(mapping));

	nextMapping.gamepad.axisInversions[c.id] = !c.inverted;
	dispatch({type: CONTROLLER_MAPPINGS_SWAP_BUTTONS_ACTION, payload: {gameId, mapping: nextMapping}});
	return nextMapping;
};

export const CONTROLLER_MAPPINGS_SWAP_BUTTONS_ACTION = 'CONTROLLER_MAPPINGS_SWAP_BUTTONS_ACTION';
export const ControllerMappingSwapButtons = (gameId, changeTo, focusedElement) => (dispatch, getState) => {
	const state = getState();
	let mapping = getState().entities.mapping.byId[gameId];

	const gameDetalis = getCurrentGameDetails(state);

	if (!mapping?.gamepad?.mappings || !Object.keys(mapping?.gamepad?.mappings).length) {
		mapping = getDefaultGamepadMapping(gameDetalis.asvi_actions);
	}

	let nextMapping = {...mapping};
	// const isSwap = changeTo.isSwap;
	console.log('nextMapping: ', nextMapping);

	const applied = applyMapping2(gameDetalis.asvi_actions, changeTo, focusedElement);
	const nextM = getModifiedGamepadMapping(gameDetalis.DEFAULT_asvi_actions, applied, mapping);

	dispatch({type: CONTROLLER_MAPPINGS_SWAP_BUTTONS_ACTION, payload: {gameId, mapping: nextM}});
	dispatch(asviUpdatedAction(gameId, applied));
};

function getModifiedGamepadMapping(prevAsvi, nextAsvi, mapping) {
	const axis = mapping?.gamepad?.axisInversions;

	const m = {
		gamepad: {
			mappings: {},
			axisInversions: axis || {}
		}
	};

	Object.keys(prevAsvi).forEach((key, i) => {
		const prevActionGamepadKey = prevAsvi[key].gamepad?.[0];
		if (!prevActionGamepadKey) return;
		const nextActionGamepadKey = nextAsvi[key].gamepad?.[0];

		m.gamepad.mappings[prevActionGamepadKey] = nextActionGamepadKey;
	});

	Object.keys(prevAsvi).forEach((key, i) => {
		const prevActionGamepadKey = prevAsvi[key].gamepad?.[1];
		if (!prevActionGamepadKey) return;
		const nextActionGamepadKey = nextAsvi[key].gamepad?.[1];

		m.gamepad.mappings[prevActionGamepadKey] = nextActionGamepadKey;
	});

	return m;
}

function applyMapping2(asvi_actions, changeTo, focusedElement) {
	const changeFromKey = focusedElement.id;
	const changeToKey = changeTo.value;

	const asvi = JSON.parse(JSON.stringify(asvi_actions));
	if (!asvi) return asvi_actions;

	Object.keys(asvi).forEach(key => {
		const action = asvi[key];
		if (!action.gamepad) return;

		const firstKey = action.gamepad[0];
		const secondKey = action.gamepad[1];
		if (firstKey === changeFromKey || secondKey === changeFromKey) {
		// if (firstKey === changeFromKey) {
			console.log('RETURN 2');
			action.gamepad = action.gamepad.map(gKey => {
				if (gKey === changeFromKey) return changeToKey;
				return gKey;
			})
		}
		if (firstKey === changeToKey || secondKey === changeToKey) {
		// if (firstKey === changeToKey) {
			console.log('RETURN 3');
			action.gamepad = action.gamepad.map(gKey => {
				if (gKey === changeToKey) return changeFromKey;
				return gKey;
			})
		}
	});

	return asvi;
}
