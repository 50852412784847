import React from "react";
import {getNthText} from "../../../assets/lib/utils";

import leaderboardGlobalIcon from '../../../assets/images/global/leaderboard-global-icon.svg';
import './leaderboard-position.component.less';

function LeaderboardPositionComponent({rank}) {
    return (
        <div className="leaderboard-position-component">
            <img className="icon" src={leaderboardGlobalIcon} alt="Leaderboard Position Icon"/>
            <div className="position-container">
                <span className="rank-text">{rank}</span>
                <span className="nth-text">{getNthText(rank)}</span>
            </div>
        </div>
    )
}

export default LeaderboardPositionComponent;