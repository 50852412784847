import { createSelector } from 'reselect';
import { getAllAvatarsById, getAllAvatarsIds } from '../../entities/entities.selectors';

export const getLoading = state => state.login.loading;
export const getLoginError = state => state.login.error;
export const getWaysunToken = state => state.login.waysunOrderToken;
export const getWaysunUserId = state => state.login.waysunUserId;
export const getWaysunAntstreamToken = state => state.login.waysunAntstreamtoken;
export const getEpicToken = state => state.login.epicToken;

export const getAvatars = createSelector(
	getAllAvatarsById,
	getAllAvatarsIds,
	(byId, ids) => {
		if (!byId || !ids) return [];
		return ids.map(id => byId[id]);
	}
);
