import React from 'react';
import './slayer-bar.component.less';

const SlayerBar = React.forwardRef(({ currentSlayerWinFraction, targetSlayerWinFraction }, ref) => {

    // always display a minimum (5%) of slayer portion or giant portion even of the real value is 0% or 100%
    const forDisplayCurrentSlayerWinFraction = Math.min(Math.max(currentSlayerWinFraction, 0.05), 0.95);

    const slayerPercentage = forDisplayCurrentSlayerWinFraction * 100;
    const giantPercentage = 100 - slayerPercentage;
    const targetPercentage = targetSlayerWinFraction * 100;
    
    return (
        <div className="slayer-bar" ref={ref}>
            <div className="slayer-portion" style={{width: slayerPercentage + '%'}}>
                <div className="slayers-army"></div>
            </div>
            <div className="giant-portion" style={{width: giantPercentage + '%'}}></div>
            <div className="target-flag" style={{left: `calc(${targetPercentage}% - 1.25em`}}></div>
        </div>
    );
});

export default SlayerBar;
