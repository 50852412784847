import * as ACTIONS from './games.actions';
import { LOGOUT_USER_ACTION } from '../../components/login/login.actions';
import {gameNormalizer} from '../entities.normaliser';

const initialState = {
	byId:{},
	allIds:[],
	lists:[],
	featuredContent:[],
	featuredTournaments:[],
	isOskVisible: false,
	homepageGamesFetching: false,
	homepageGamesFetched: false,
	isFetchGameDetailedPending: false,
};

export const gamesReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACTIONS.UPDATE_ALL_GAMES_DATA:
		const normalizedData = gameNormalizer(action.games);
		return {
			...state,
			byId: {
				...state.byId,
				...normalizedData.entities.games
			},
			allIds: [
				...state.allIds,
				...normalizedData.result.filter(id=>!state.allIds.includes(id))
			],
		};
	case ACTIONS.FETCH_HOMEPAGE_GAMES_ACTION: {
		return {
			...state,
			homepageGamesFetching: true
		};
	}
	case ACTIONS.FETCH_HOMEPAGE_GAMES_ACTION_SUCCESS:
		const homepageData = action.payload;
		return {
			...state,
			homepageGamesFetched: true,
			homepageGamesFetching: false,
			featuredContent: homepageData.featuredContent,
			featuredTournaments: homepageData.featuredTournaments,
			lists: homepageData.lists,
		};
	case ACTIONS.FETCH_HOMEPAGE_GAMES_ACTION_ERROR:
		return {
			...state,
			homepageGamesFetching: false,
			homepageGamesFetched: false
		};
	case ACTIONS.ADD_GAME_TO_PLAY_AGAIN_LIST:
		const gameId = action.gameId;
		const lists = state.lists;
		const playAgainList = lists.find(list=>list._id==="Play Again");
		if(!playAgainList)return state;

		playAgainList.games = playAgainList.games.filter(id=>id!==gameId);
		playAgainList.games.unshift(gameId);

		return {
			...state,
			lists,
		};
	case ACTIONS.FETCH_GAME_DETAILED_ACTION: {
		return {
			...state,
			isFetchGameDetailedPending: true
		};
	}
	case ACTIONS.FETCH_GAME_DETAILED_ACTION_ERROR: {
		return {
			...state,
			isFetchGameDetailedPending: false
		};
	}
	case ACTIONS.ASVI_UPDATED_ACTION:
		return {
			...state,
			byId: {
				...state.byId,
				[action.gameId]: {
					...state.byId[action.gameId],
					asvi_actions: action.asvi
				}
			},
		};
	case ACTIONS.FETCH_GAME_DETAILED_ACTION_SUCCESS:
		const gameDetailed = {
			...action.gameDetailed,
			isDetailed:true
		};

		return {
			...state,
			isFetchGameDetailedPending: false,
			byId: {
				...state.byId,
				[gameDetailed._id]:gameDetailed
			},
		};
	case LOGOUT_USER_ACTION :
		return initialState;

	default:
		return state;
	}
};
