import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getChallengeImages, getCurrentGameDetailsId } from '../game-details.selector';
import { getAssetsUrlWithFolder } from '../../../app.selectors';
import { HowToPlayMapping } from '../../how-to-play/mapping/how-to-play-mapping.component';
import { Button } from '../../common/button/common-button.component';
import {
	ControllerMappingChangeAxis, ControllerMappingSwapButtons,
	PatchControllerMappingsAction,
	ResetControllerMappingsAction
} from '../../../entities/mapping/mapping.actions';
import { getContentByType, getCurrentGame } from '../../how-to-play/how-to-play.selector';
import './game-details-mapping.component.less';
import { HowToPlayContent } from '../../how-to-play';
import { getCurrentChallenge } from '../../challenge/challenge.selectors';
import { FOLDER_TYPES } from '../../../assets/lib/FolderTypes';
import {Loader} from "../../common/loader/loader.component";
import { getGamepadBgImage, getGamepadImage, getGamepadName } from '../../../assets/lib/utils';

class GameDetailsMappingComponent extends Component {
	isEmptyObject = (obj) => {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	};

	handleResetRemapButton = () => {
		this.props.dispatch(ResetControllerMappingsAction(this.props.gameId));
	};

	handleDoneRemapButton = () => {
		this.props.dispatch(PatchControllerMappingsAction(this.props.gameId, this.props.mapping));
	};

	renderRemapButtons = () => {
		return (
			<div className="buttons-container">
				<Button
					className="secondary"
					onClick={this.handleResetRemapButton}
					childId="resetRemapButton"
				>
					Reset
				</Button>
				<Button
					className="secondary"
					onClick={this.handleDoneRemapButton}
					childId="doneRemapButton"
				>
					Done
				</Button>
			</div>
		);
	}

	onMappingAxisChanged = (c) => {
		this.props.dispatch(ControllerMappingChangeAxis(this.props.gameId, c));
	}

	onMappingDropDownChanged = (changeTo, focusedElement) => {
		this.props.dispatch(ControllerMappingSwapButtons(this.props.gameId, changeTo, focusedElement));
	};

	getHowToPlayContent = () => {
		const { howToPlayImages, preLoadFolder } = this.props;

		return {
			showRemapButton: true,
			backgroundImage: getGamepadBgImage(),
			asvi_actions: this.props.currentGame?.asvi_actions,
			inputTypes: ['gamepad'],
			name: getGamepadName(),
			image: getGamepadImage(howToPlayImages, preLoadFolder)
		};
	};

	render() {
		if (this.props.patchMappingPending) {
			return <Loader loading={true}/>;
		}

		const howToPlayContent = this.getHowToPlayContent();

		return (
			<div className='game-details-mapping-component'>
				<HowToPlayMapping
					mapping={this.props.mapping}
					asvi_actions={this.props.currentGame?.asvi_actions}
					onDropDownChanged={this.onMappingDropDownChanged}
					onAxisChanged={this.onMappingAxisChanged}
					handleDoneRemapButton={this.handleDoneRemapButton}
					handleResetRemapButton={this.handleResetRemapButton}
				>
					<HowToPlayContent {...howToPlayContent} />
				</HowToPlayMapping>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const gameId = getCurrentGameDetailsId(state);
	const howToPlayImages = getChallengeImages(state);
	const currentChallenge = getCurrentChallenge(state);
	const currentChallengeId = currentChallenge && currentChallenge._id;
	const currentIdHowToPlayImages = howToPlayImages && currentChallengeId && howToPlayImages[currentChallengeId];
	const preLoadFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.PRE_LOAD);

	return {
		preLoadFolder,
		howToPlayImages: currentIdHowToPlayImages || getContentByType(state),
		currentGame: getCurrentGame(state),
		mapping: gameId ? state.entities.mapping.byId[gameId] : null,
		patchMappingPending: state.entities.mapping.patchMappingPending,
		gameId
	};
};

export const GameDetailsMapping = connect(mapStateToProps)(GameDetailsMappingComponent);
