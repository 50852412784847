import React, {useEffect, useRef, useState} from 'react';
import './info.component.less';
import { connect } from 'react-redux';
import { Button } from '../../common/button/common-button.component';
import {
    getCurrentDailyChallengePending,
    getDailyChallenge,
    getDailyChallengePlayReward
} from '../daily-challenges.selectors';
import { RunGameAction } from '../../game-details/game-details.actions';
import { animateOnFirstDailyChallengePlayAction, playDailyChallengeAction } from '../daily-challenges.actions';
import { navigateToLocation, ROUTES } from '../../../app.router';
import { addAnimationToStack } from '../../user-header/user-header.component';
import { TYPE_GEM } from '../../animations/gem.animation';
import { generateUUID, isLowSpecDevice } from '../../../app.helpers';
import { nextGemsUpdateId } from '../../../app.actions';
import { getLoggedUser } from '../../../app.selectors';
import FontSizeByLength from '../../common/font-size-by-length/font-size-by-length.component';
import { isFreemiumAppleIosUser, isFreemiumV4UserNotSubscribed } from '../../../assets/lib/utils';
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import { addPopup } from '../../popup/popup.component';
import { APPLE_IOS_SKU, PREMIUM_POPUP_HEADER_TEXT_3 } from '../../../constants';
import { startNativePayment } from '../../../assets/lib/game-wrapper';

const TYPE_ANIMATION_CONTAINER = 'gemCreditPopup';

const DailyChallengesInfoComponent = ({ dispatch, loggedUser, currentChallenge, playRewardAmount, ...props }) => {
    const gemsImgRef = useRef(null);
    const [playClicked, setPlayClicked] = useState(false);
    const [gemsRewardVisible, setGemsRewardVisible] = useState(false);

    useEffect(() => {
        if (props.needAnimateOnFirstDailyChallengePlay && props.alreadyPlayed) {
            setGemsRewardVisible(true);
            setTimeout(() => {
                startAnimation({
                    gemsUpdateId: nextGemsUpdateId(),
                    amount: loggedUser?.currencies?.GEMS + playRewardAmount
                });
                dispatch(animateOnFirstDailyChallengePlayAction(false));
                setTimeout(() => {
                    setGemsRewardVisible(false);
                }, 2000)
            }, 2000)
        } else if (props.alreadyPlayed && gemsRewardVisible) {
            setGemsRewardVisible(false);
        } else if (!props.alreadyPlayed && !gemsRewardVisible) {
            setGemsRewardVisible(true);
        }
    }, [props.alreadyPlayed]);

    function textEllipsis(text, symbolsLimit) {
        if (typeof text !== 'string') return '';

        if (text.length <= symbolsLimit) return text;

        return text.slice(0, symbolsLimit) + '...';
    }

    function onHeaderAnimationStart() {
        console.log('onHeaderAnimationStart');
    }

    function startAnimation(gemsObject) {
        if (isLowSpecDevice()) return null;
        if (!playRewardAmount) return null;
        if (!gemsImgRef?.current) return null;

        const gemRect = gemsImgRef.current.getBoundingClientRect();
        const startPoint = {
            x: gemRect.left,
            y: gemRect.top,
        };

        addAnimationToStack (
            TYPE_GEM,
            playRewardAmount,
            startPoint,
            TYPE_ANIMATION_CONTAINER,
            {
                gemsObject,
                animationStartCallback:() => onHeaderAnimationStart(TYPE_GEM)
            },
            generateUUID()
        );
    }

    function onPlayClicked() {
        if (props.isFreemiumAppleIos) {
            startNativePayment(APPLE_IOS_SKU);
            return;
        }

        if (props.isFreemiumV4User) {
            const subscriptionPopup = <SubscriptionComparisonsPopup
                leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_3}
                pageId='homepage_1'
            />;
            addPopup(subscriptionPopup);
            return;
        }

        if (playClicked) return;
        setPlayClicked(true);

        if (!props.alreadyPlayed) {
            dispatch(animateOnFirstDailyChallengePlayAction(true));
        }

        dispatch(RunGameAction({
            gameId: currentChallenge.gameId,
            challengeId: currentChallenge._id,
            dailyChallengeEventId: props.dailyChallengeEventId,
            challengeStyle: 'intro',
        }));
        dispatch(playDailyChallengeAction(true));
        navigateToLocation(ROUTES.HOW_TO_PLAY, {
            id: currentChallenge.gameId,
            challengeId: currentChallenge._id,
            dailyChallengeEventId: props.dailyChallengeEventId
        });
    }

    function renderChallengeInfoGemsPrize() {
        // The play prize should only be visible if the player hasn’t participated in the challenge yet.
        if (props.isCurrentChallengePending || !gemsRewardVisible) return null;

        if (!playRewardAmount) return null;

        return (
            <div className='daily-challenges-info__gems-prize'>
                <div className='daily-challenges-info__gems-text'>Get</div>
                <div className='daily-challenges-info__gems-count'>{playRewardAmount}</div>
            </div>
        );
    }

    function renderAnimationElement() {
        return <div ref={gemsImgRef} className='daily-challenges-info__animationElement'>anim</div>;
    }

    function renderChallengeInfo() {
        if (!currentChallenge) return null;

        const cutText = textEllipsis(currentChallenge.description, 50);

        return (
            <>
                <Button
                    stopBounceAnimation
                    childId='dailyChallengesPlayButton'
                    onFocus={() => props.handlePlayButtonFocus && props.handlePlayButtonFocus()}
                    onClick={onPlayClicked}
                    className='daily-challenges-info__tile'>
                    <div className='daily-challenges-info__image-box'>
                        <img className='daily-challenges-info__img' src={currentChallenge.img_src} alt=""/>
                        <div className='daily-challenges-info__play-circle'>
                            <span className='triangle'></span>
                        </div>    
                    </div>
                </Button>
                <FontSizeByLength changePerChar={2} startAtChar={9} stopAtChar={40} className='daily-challenges-info__title'>
                    {currentChallenge.title || ''}
                </FontSizeByLength>
                <FontSizeByLength changePerChar={1} startAtChar={40} stopAtChar={80} className='daily-challenges-info__text'>
                    {currentChallenge.description || ''}
                </FontSizeByLength>
                {renderChallengeInfoGemsPrize()}
                {renderAnimationElement()}
            </>
        );
    }

    return (
        <div className='daily-challenges-info'>
            {/*<CountAnimation startValue={0} endValue={10} duration={2.5}/>*/}
            {renderChallengeInfo()}
        </div>
    );
};

const mapStateToProps = (state) => {
    const currentChallenge = getDailyChallenge(state);
    const alreadyPlayed = currentChallenge?._id &&
        !!state.dailyChallenges.dailyChallengeActivity?.currentEventHistory?.challenges?.[currentChallenge?._id];

    return {
        isFreemiumAppleIos: isFreemiumAppleIosUser(state),
        isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
        loggedUser: getLoggedUser(state),
        dailyChallengeEventId: state.dailyChallenges.dailyChallengeEventId,
        // alreadyPlayed: state.dailyChallenges.alreadyPlayed,
        alreadyPlayed,
        activity: state.dailyChallenges.dailyChallengeActivity,
        playRewardAmount: getDailyChallengePlayReward(state),
        needAnimateOnFirstDailyChallengePlay: state.dailyChallenges.needAnimateOnFirstDailyChallengePlay,
        isCurrentChallengePending: getCurrentDailyChallengePending(state),
        currentChallenge,
    };
};

const DailyChallengesInfo = connect(mapStateToProps)(DailyChallengesInfoComponent);
export default DailyChallengesInfo;
