import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { getCurrentUserDetailsId } from '../user-details.selectors';
import {
    getMyUserFavouriteGames,
    getOtherUserDetails,
    getOtherUserFavouriteGames,
    getOtherUserRecentGames
} from '../../../entities/entities.selectors';
import ListContainer, {LIST_TYPES} from '../../common/list/list-container.component';
import inputManager, { PARENT_ENTRANCE} from '../../../assets/lib/inputmanager';
import UserProfileStats from '../../user-profile/user-profile-stats.component';
import {getTournamentHistory} from "../../tournaments/tournaments.selector";
import TileTournamentHistory from "../../common/tiles/tile-tournament-history.component";
import {externalFocusableComponent} from "../../game-details/info/game-details-info.component"
import {externalFocusableComponent as externalFocusableLeaderboardComponent} from "../../leaderboard/leaderboard.component";
import { getIsPostAuthFetched } from '../../../app.selectors';
import {isFreeTierV2FeatureFlagEnabled, isXboxUser} from '../../../assets/lib/utils';

import './other-profile-info.component.less';
import {XboxGamerCardButton} from '../../common/xbox-gamer-card-button/xbox-gamer-card-button.component';


class UserDetailsProfileComponent extends Component {
    constructor(props){
        super(props);

        this.state = {
            lists:[],
        };

        this.recentlyPlayedTitle = 'Recently Played';
        this.tournamentHistoryTitle = 'Tournament History';
    }

    componentDidMount(){
        this.onListsFetched();
        inputManager.setCurrentChildById("back","navBar");
    }

    onSelectGame = (itemId, listIndex, hasChallenges) => {
        if (this.props.isFreeTierV2FeatureEnabled && this.props.isPostAuthFetched && !this.props.isUserSubscribed && hasChallenges) {
            navigateToLocation(ROUTES.GAME_CHALLENGES, {id: itemId, focusElement: 'initialChallenge'});
            return;
        }

        if (listIndex===this.tournamentHistoryTitle) {
            const selectedTournamentItem = this.props.tournamentHistory.find(item=>item._id===itemId);
            if (selectedTournamentItem && selectedTournamentItem.tournamentObject) {
                navigateToLocation(ROUTES.TOURNAMENTS_LEADERBOARD, {id:selectedTournamentItem.tournamentObject._id, focusElement: externalFocusableLeaderboardComponent.PLAY_BUTTON});
                return;
            }
        }
        navigateToLocation(ROUTES.GAME_INFO, {id: itemId, focusElement: externalFocusableComponent.PLAY_BUTTON});
    };

    onListsFetched = () => {
        const lists = [];
        const {recentGames,favouritedGames, myUserFavouritedGames, tournamentHistory} = this.props;

        const defaultValuesForList = {
            type: LIST_TYPES.HORIZONTAL,
            renderer: (props) => {
                if(props.item.tournamentObject){
                    return <TileTournamentHistory {...props} />;
                }
                return null;
            },
            parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL
        }
    
        if (tournamentHistory.length > 0) {
            lists.push({
                'id': this.tournamentHistoryTitle,
                'title': this.tournamentHistoryTitle,
                'data': tournamentHistory,
                ...defaultValuesForList
            });
        }

        if (recentGames.length > 0) {
            lists.push({
                'id': this.recentlyPlayedTitle,
                'title': this.recentlyPlayedTitle,
                'data': recentGames,
                ...defaultValuesForList
            });
        }

        if (favouritedGames.length > 0) {
            if(myUserFavouritedGames.length > 0){//favourited by both user
                const gamesBothLoved = [];

                favouritedGames.forEach(gameObject=>{
                    const found = myUserFavouritedGames.find((myGameObject)=>{
                        return myGameObject._id===gameObject._id;
                    });
                    if(found){
                        gamesBothLoved.push(gameObject);
                    }
                });

                if(gamesBothLoved.length>0){
                    lists.push({
                        'id':'Games You Both Love',
                        'title': 'Games You Both Love',
                        'data': gamesBothLoved,
                        ...defaultValuesForList
                    });
                }
            }

            lists.push({
                'id':'Favourites',
                'title': 'Favourites',
                'data': favouritedGames,
                ...defaultValuesForList
            });
        }

        this.setState({lists});
    };

    getXuidFromDetails = (details) => {
        return details
            && details.externalIds
            && details.externalIds['XBL']
            && details.externalIds['XBL'].xuid;
    };

    render() {
        const {details} = this.props;
        if(!details)return null;
        const xuid = this.getXuidFromDetails(details);

        return (
            <div className="other-profile-info">
                {this.props.amIXboxUser && this.props.isThisXboxUser && <XboxGamerCardButton xboxUserId={xuid} />}

                <div className="top-container">
                    <UserProfileStats
                        isFollowUserPending={this.props.isFollowUserPending}
                        details={details}
                        onEditProfile={this.onEditProfile}
                    />
                </div>
                { this.state.lists && this.state.lists.length > 0 && 
                    <ListContainer
                        lists={this.state.lists}
                        onTileClick={this.onSelectGame}
                    />
                }
            </div>
        );            
    }
}

const mapStateToProps = (state) => {
    const details = getOtherUserDetails(state);
    const isThisXboxUser = details.externalAuthentications
        && !!details.externalAuthentications['XBL'];

    return {
        amIXboxUser: isXboxUser(state),
        isThisXboxUser,
        isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
        isPostAuthFetched: getIsPostAuthFetched(state),
        isFollowUserPending: state.entities && state.entities.users && state.entities.users.isFollowUserPending,
        currentId: getCurrentUserDetailsId(state),
        details,
        recentGames: getOtherUserRecentGames(state),
        favouritedGames: getOtherUserFavouriteGames(state),
        myUserFavouritedGames: getMyUserFavouriteGames(state),
        tournamentHistory: getTournamentHistory(state),
    };
};

export const UserDetailsProfile = connect(mapStateToProps)(UserDetailsProfileComponent);

