export const TOURNAMENT_OBJECT_TYPE = {
	GROUP: "GROUP",
	GROUP_MEMBER: "GROUP_MEMBER",
	SINGLE: "SINGLE",
};

export const TOURNAMENT_LEADERBOARD_TYPE = {
	TWITCH: "TWITCH",
	KICKSTARTER: "KICKSTARTER",
	FILTER_WITH_ACCESS: "FILTER_WITH_ACCESS"
};