import React, { Component } from 'react';
import './result-waiting.component.less';
import {Loader} from "../common/loader/loader.component";
import {Button} from "../common/button/common-button.component";
import {navigateToLocation, ROUTES} from "../../app.router";
import inputManager from "../../assets/lib/inputmanager";
import {connect} from "react-redux";
import {getRunningSessionId} from "../../app.selectors";
import {getChallengeResultAction} from "../challenge/challenge.actions";

const attemptDelaysInMiliseconds = [ 100, 1000, 3000, 3000];
class ResultWaitingComponent extends Component {
	constructor(props) {
		super(props);

		this.requestTimer = null;
		this.state={
			skipButtonEnabled:false
		};

		this.attemptCount = 0;
	}

	componentDidMount() {
		inputManager.setCurrentChildById("ResultWaiting-skipButton");
		inputManager.setBackFunction(()=>{});
		this.getChallengeResult();
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null);
		this.cancelTimer();
	}

	getChallengeResult = () => {
		if(this.attemptCount < attemptDelaysInMiliseconds.length){
			this.requestTimer = setTimeout(() => {
				this.props.dispatch(getChallengeResultAction(this.props.sessionId, this.onGetChallengeResultSuccess, this.onGetChallengeResultFail));
			}, attemptDelaysInMiliseconds[this.attemptCount]);
		} else {
			this.enableSkipButton();
		}
		this.attemptCount++;
	};

	onGetChallengeResultSuccess = () => {
		this.cancelTimer();
	};

	onGetChallengeResultFail = () => {
		this.getChallengeResult();
	};

	cancelTimer = () => {
		if(this.requestTimer){
			clearTimeout(this.requestTimer);
			this.requestTimer = null;
		}
	};

	enableSkipButton = () => {
		this.skipTimer = null;
		this.setState({skipButtonEnabled:true});
	};

	onSkipClicked = () => {
		navigateToLocation(ROUTES.HOMEPAGE);
		document.location.reload();
	};

	render() {
		return (
			<section className="result-waiting-component">
				<Loader loading={true}/>
				<div className="warning-label">'Retrieving Results Data'</div>
				<Button
					className={`primary two-line skip-button ${this.state.skipButtonEnabled?'visible':''}`}
					onClick={this.onSkipClicked}
					childId="ResultWaiting-skipButton"
					disabled={!this.state.skipButtonEnabled}
				>
					Skip
				</Button>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		sessionId: getRunningSessionId(state),
	};
};

export default connect(mapStateToProps)(ResultWaitingComponent);
