import React, { useEffect, useRef, useState } from 'react';
import TileGiantSlayer from '../tiles/tile-giant-slayer.component';
import './giant-slayer-random-panel.component.less';
import deviceInfo from '../../../assets/lib/deviceInfo';

const itemWidth = 9.3;

const GiantSlayerRandomPanel = ({ isVisible, randomGiantSlayerChallengeId, giantSlayerChallenges }) => {

    const [animationFinished, setAnimationFinished] = useState(false);

    const stripRef = useRef();
    const stripInnerRef = useRef();
    const stripSingleRef = useRef();
    const stripSingleInnerRef = useRef();
    const animationIsRunningRef = useRef(true);
    const requestRef = useRef();

    const randomIndexRef = useRef(-1);
    const slowDownRef = useRef(false);
    const groupSizeRef = useRef(0);
    const speedRef = useRef(0.1);
    const stripInnerWithRef = useRef(0);
    const stripSingleInnerWithRef = useRef(0);
    const eligibleGiantSlayerChallengesRef = useRef();

    let stripX = 0;
    const [stripItems, setStripItems] = useState([]);
    const [stripSingleItems, setStripSingleItems] = useState([]);

    useEffect(() => {
        return () => {
            cancelAnimationFrame(requestRef.current);
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            // start animation
            onAnimationFrame();
        } else {
            cancelAnimationFrame(requestRef.current);
        }
    }, [isVisible]);

    useEffect(() => {
        eligibleGiantSlayerChallengesRef.current = giantSlayerChallenges.filter((item) => item.role === 'eligible');
        groupSizeRef.current = eligibleGiantSlayerChallengesRef.current.length;

        // calculate the width of the inner strips
        stripInnerWithRef.current = groupSizeRef.current * 5 * itemWidth;
        stripSingleInnerWithRef.current = groupSizeRef.current * 2 * itemWidth;

        // Populate the strip items
        populateStripItems();
        populateStripSingleItems();
    }, [giantSlayerChallenges]);

    useEffect(() => {
        if (randomGiantSlayerChallengeId !== null) {
            // find index of element to land on when the animation slows down to a complete stop 
            randomIndexRef.current = eligibleGiantSlayerChallengesRef.current.findIndex((item) => item.giantSlayerChallengeId === randomGiantSlayerChallengeId);
            // starts slowing down the animation
            slowDownRef.current = true;
        }
    }, [randomGiantSlayerChallengeId]);


    const onAnimationFrame = () => {
        if (slowDownRef.current && speedRef.current > 0.02) {
            // slows down scrolling speed, but remain above a certain speed
            speedRef.current = speedRef.current * 0.98;
        }
        stripX -= speedRef.current;
        if (stripX < -groupSizeRef.current) {
            // loop back to 0 when all tiles have scrolled
            stripX += groupSizeRef.current;
        }

        const decimal = stripX - Math.floor(stripX); // prevent stopping abruptly mid-tile
        if (speedRef.current < 0.04 && Math.ceil(stripX) === -randomIndexRef.current && decimal > 0.8) {
            // stop the scrolling animation on the correct tile (correct randomIndexRef)
            stripX = Math.ceil(stripX);
            animationIsRunningRef.current = false;
            setAnimationFinished(true);
        }
        if (stripInnerRef.current) {
            // update the strip position in "long" carousel
            stripInnerRef.current.style.transform = `translate(${stripX * itemWidth}em, 0em)`;
            stripInnerRef.current.style.width = `${stripInnerWithRef.current}em`;
        }
        if (stripSingleInnerRef.current) {
            // update the strip position in "short" carousel
            stripSingleInnerRef.current.style.transform = `translate(${stripX * itemWidth}em, 0em)`;
            stripSingleInnerRef.current.style.width = `${stripSingleInnerWithRef.current}em`;
        }
        if (animationIsRunningRef.current) {
            requestRef.current = window.requestAnimationFrame(onAnimationFrame);
        }
    }

    // add tiles to the "long" carousel
    const populateStripItems = () => {
        let items = [];
        for (var i = 0; i < groupSizeRef.current * 5; i++) {
            const itemIndex = i % groupSizeRef.current;
            items.push(<TileGiantSlayer item={eligibleGiantSlayerChallengesRef.current[itemIndex]} key={i} />);
        }
        setStripItems(items);
    }

    // add tiles to the "short" carousel
    const populateStripSingleItems = () => {
        let items = [];
        for (var i = 0; i < groupSizeRef.current * 2; i++) {
            const itemIndex = i % groupSizeRef.current;
            items.push(<TileGiantSlayer item={eligibleGiantSlayerChallengesRef.current[itemIndex]} key={i} />);
        }
        setStripSingleItems(items);
    }
    
    return (
        <div className={`giant-slayer-random-panel-component ${animationFinished ? 'animation-finished' : ''}`}>
            <div className="title-block">
                <div className="random-icon"></div>
                <div className="giant-slayer-icon"></div>
                <div className="title-text">Random Giant Slayer Challenge</div>
                <div className="giant-slayer-description">Take a chance on a random battle for double the reward</div>
            </div>
            <div className="gradient-block"></div>
            <div className="strip" ref={stripRef}>
                <div className="stripInner" ref={stripInnerRef}>
                    {stripItems}
                </div>
            </div>
            <div className={`stripSingleBackground ${!deviceInfo.isRunningOnNativeClientV2() ? 'clientV1' : ''}`}></div>
            <div className="stripSingleOuter">
                <div className="stripSingle" ref={stripSingleRef}>
                    <div className="stripSingleInner" ref={stripSingleInnerRef}>
                        {stripSingleItems}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GiantSlayerRandomPanel;
