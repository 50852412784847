import { createSelector } from 'reselect';
import {getFBImageUrl} from '../assets/lib/facebook.lib';
import {getAssetsUrlWithFolder, isFreeTierBannerNeeded} from '../app.selectors';
import {FOLDER_TYPES} from '../assets/lib/FolderTypes';
import defaultAvatar from '../assets/images/global/DefaultAvatar.png';
import { FEATURED_BANNER_TYPES } from '../constants';
import deviceInfo from "../assets/lib/deviceInfo";

export const getGameListsIds = state => state.entities.games.lists || [];
export const getAllGamesById = state => state.entities.games.byId;
export const getAllGameIds = state => state.entities.games.allIds;
export const getGameById = (state,gameId) => state.entities.games.byId[gameId];
export const getChallengeById = (state,challengeId) => state.entities.challenges.byId[challengeId];
export const getChallengesById = state => state.entities.challenges.byId;
export const getAllChallengeIds = state => state.entities.challenges.allIds;
export const getGiantSlayerChallengeById = (state,giantSlayerChallengeId) => state.entities.giantSlayerChallenges.byId[giantSlayerChallengeId];
export const getFeaturedContent = state => state.entities.games.featuredContent;
export const getFeaturedTournaments = state => state.entities.games.featuredTournaments;
export const getAllAvatarsById = state => state.entities.avatars.byId;
export const getAvatarById =  (state,type) => state.entities.avatars.byId[type];
export const getAllAvatarsIds = state => state.entities.avatars.allIds;
export const getAllLogosById = state => state.entities.logos.byId;
export const getAllMessagesById = state => state.entities.messages.byId;
export const getAllMessagesIds = state => state.entities.messages.allIds;
export const getAllBadgesById = state => state.entities.badges.byId;
export const getMyUserDetails = state => state.entities.users.myUserDetails;
export const getMyUserFollowers = state => state.entities.users.myUserFollowersList;
export const getMyUserAchievements = state => state.entities.users.myUserAchievements;
export const getMyUserFollowedUsers = state => state.entities.users.myUserFollowedList;
export const getMyUserFavouriteGameIds = state => state.entities.users.myUserFavouritedGames;
const getMyUserRecentGameIds = state => state.entities.users.myUserRecentlyPlayedGame;
export const getGetMappingPending = state => state.entities.mapping.getMappingPending;
export const getCurrentGameMapping = (state, gameId) => {
	if (!gameId) return;
	return state.entities.mapping.byId[gameId];
}
export const getOskSetupInputRawByGameId = (state, gameId) => gameId ? state.entities.games.oskInputSetupRawDataByGameId[gameId] : null;

export const getOtherUserDetails = state => state.entities.users.otherUserDetails;
export const getOtherUserFavouriteGameIds =  state => state.entities.users.otherUserFavouritedGames;
const getOtherUserRecentGameIds = state => state.entities.users.otherUserRecentlyPlayedGame;

export const getFeaturedBanners = createSelector(
	getFeaturedContent,
	isFreeTierBannerNeeded,
	(contents, isFreeTierBannerNeeded) => {
		const filteredBanners = isFreeTierBannerNeeded ? 
			contents
		: contents.filter(({type}) => {
			return type !== FEATURED_BANNER_TYPES.FREE_TIER
		})
		const sortedBanners = filteredBanners.sort((a,b) => {
			if(a.order && b.order) return a.order - b.order
			if(!a.order) return 1 // if missing a sort order return b first
			else return -1
		})
		return sortedBanners;
	}
);

export const getMyUserRecentGames = createSelector(
	getAllGamesById,
	getMyUserRecentGameIds,
	(gamesById, ids) => {
		if (!gamesById || !ids) return [];
		return ids.map(id => gamesById[id]).filter(item => !!item);
	}
);

export const getOtherUserRecentGames = createSelector(
	getAllGamesById,
	getOtherUserRecentGameIds,
	(gamesById, ids) => {
		if (!gamesById || !ids) return [];
		return ids.map(id => gamesById[id]).filter(item => !!item);
	}
);

export const getMyUserFavouriteGames = createSelector(
	getAllGamesById,
	getMyUserFavouriteGameIds,
	(gamesById, ids) => {
		if (!gamesById || !ids) return [];
		return ids.map(id => gamesById[id]).filter(item => !!item);
	}
);

export const getOtherUserFavouriteGames = createSelector(
	getAllGamesById,
	getOtherUserFavouriteGameIds,
	(gamesById, ids) => {
		if (!gamesById || !ids) return [];
		return ids.map(id => gamesById[id]).filter(item => !!item);
	}
);

export const getGameLists = createSelector(
	getAllGamesById,
	getGameListsIds,
	(gamesById, lists) => {		
		if (!gamesById || !lists) return [];


		return lists.map( (list) => {
			return {
				...list,
				games: list.games.map(id => gamesById[id])
			};
		});
	}
);

export const getAvatarUrl = (state,type) => {
	let avatarObject = null;
	
	if (state.entities.avatars && state.entities.avatars.byId) {
		avatarObject = getAvatarById(state, type);
	}

	let avatarUrl = avatarObject ? getAssetsUrlWithFolder(state,FOLDER_TYPES.AVATARS) + avatarObject.url : getFBImageUrl(type);

	/**
	 * The application need to display a default avatar to users on PlayStation platforms in place of a PC user's Facebook picture.
	 **/
	if (!avatarObject && deviceInfo.isPlayStationPlatform()) {
		const defaultAvatarId = getAllAvatarsIds(state)[0];
		avatarObject = getAvatarById(state, defaultAvatarId);
		avatarUrl = getAssetsUrlWithFolder(state,FOLDER_TYPES.AVATARS) + avatarObject.url;
	}

	return avatarUrl || defaultAvatar;
};
