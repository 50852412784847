/* eslint-disable no-console */
import WebsocketAPI from './websocketAPI';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { get } from 'lodash';
import Elastic from './elastic.lib';
import deviceInfo, { PLATFORM_TYPE_WEB } from './deviceInfo';
import KeepAlive from './KeepAlive';
import { readRestUserData, readUserData } from './local-storage.js';
import { antstreamAPIService } from '../../app.reducer';
import { messageNativeClientWebsocketCreds } from './game-wrapper';
import { handleNoAuthTokenIssue } from './utils';

export let paymentUrl = '';
export let AntstreamInstance = null;

class Antstream {
    constructor() {
        AntstreamInstance = this;

        this.message$ = new BehaviorSubject([]);
        this.websocketAPImessage$ = new BehaviorSubject([]);
        this.gamepadKeyPressedMessage$ = new Subject();
        this.sessionRequestId = null;

        paymentUrl = window.config.REACT_APP_PAYMENT_URL;
    }

    nextMessage(message) {
        this.message$.next(message);
    }

    socketDisconnect() {
        if (this.websocketAPI) {
            this.websocketAPI.close();
        }
    }

    socketConnect() {
        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue('socketConnect');

        this.websocketAPI = new WebsocketAPI();

        const wsUrl = `${window.config.REACT_APP_ANTSTREAM_WS_API_BASE_URL}/${window.config.REACT_APP_ANTSTREAM_WS_API_STAGE}`;

        if (deviceInfo.isRunningOnNativeClientV2()) {
            // if running on Client V2 websocket connection will be handled on Native side. We just need to pass ws creds and listen to 'showNotification' native messages
            messageNativeClientWebsocketCreds(encodeURIComponent(wsUrl), authToken);
        } else {
            this.websocketAPI.init(`${wsUrl}?token=${authToken}`, {
                key: '',
                onMessage: (message) => {
                    this.websocketAPImessage$.next(message);
                }
            });
        }
    }

    gsSocketReconnect() {
        // this prevents the issue with using previous token (this.authToken) for new session when user logout and login under another account
        this.gameSparks.clearAuthTokenAndSession();

        this.gameSparks.reconnectConnection();
    }

    getMessage$ = () => {
        return this.message$.asObservable();
    };

    getWebsocketAPImessage$ = () => {
        return this.websocketAPImessage$.asObservable();
    };

    getGamepadKeyPressedMessage$ = () => {
        return this.gamepadKeyPressedMessage$.asObservable();
    };

    getPaymentUrl = () => {
        let url = paymentUrl;

        const storageData = readRestUserData();
        return `${url}?authToken=${storageData ? storageData.authToken : ''}`;
    };

    // @method getUserLogin
    // @params username, password, token
    // @description Handles a user login request
    getUserLogin(userName, password, token, onResponse) {
        const request = { userName, password };
        this.gameSparks.sendWithData('AuthenticationRequest', request, (resp) => {
            if (!resp.error) {
                KeepAlive.userLoggedIn();
            }

            // in case of monolithic build set assetsURL to empty
            // so that instead of requesting CDN for assets, assets will be fetched
            // from the live UI site itself
            if (window.config.REACT_APP_MONO_BUILD === 'true') {
                resp.scriptData.config.assetsUrl = ''
            }

            onResponse(resp);
        });
    }

    getAccountDetails(onResponse) {
        const { userId } = readUserData()
        var req = {
            playerId: userId
        }
        this.gameSparks.sendWithData('AccountDetailsRequest', req, onResponse)
    }

    waysunInitialisation(token) {
        const subj = new Subject();
        const payload = {
            scriptData: {
                action: 'WaysunRegistration',
                token,
            }
        };
        // THIS REQUEST IS HANDLED VIA A WAYSUN CALLBACK INSTEAD DONE BY THE PROXY ALONG WITH TOKEN VALIDATION
        // DOES NOT HIT GAMESPARKS DIRECTLY
        this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
            subj.next(resp);
            subj.complete();
        });
        return subj.asObservable();
    }

    waysunIframePayment(orderToken) {
        const subj = new Subject();
        const payload = {
            orderToken: orderToken,
        };

        this.logEventRequest('set_waysun_iframe_payment_success', payload, (resp) => {
            subj.complete();
        });
        return subj.asObservable();
    }

    loginWithFacebook(accessToken) {
        const subj = new Subject();
        const payload = {
            accessToken: accessToken,
            code: null,
            doNotLinkToCurrentPlayer: false,
            errorOnSwitch: false,
            segments: {},
            switchIfPossible: false,
            syncDisplayName: false,
        };
        this.gameSparks.sendWithData('FacebookConnectRequest', payload, (resp) => {
            if (!resp.error) {
                KeepAlive.userLoggedIn();
            }

            subj.next(resp);
            subj.complete();
        });
        return subj.asObservable();
    }

    changeUserDetails(userName) {
        const subj = new Subject();
        const payload = {
            userName
        };
        this.gameSparks.sendWithData('ChangeUserDetailsRequest', payload, (resp) => {
            subj.next(resp);
            subj.complete();
        });
        return subj.asObservable();
    }

    fetchPostAuthenticationData(promoCode, platformType = PLATFORM_TYPE_WEB) {
        const subj = new Subject();
        const payload = {
            promoCode,
            isAntstreamApp: true,
            platformType
        };
        if (navigator && navigator.userAgent) {
            payload.userAgent = navigator.userAgent;
        }
        this.logEventRequest('post_authentication', payload, (response) => {
            if (response.error) subj.error(response.message);
            else {
                subj.next(response.scriptData);
            }
            subj.complete();
        });
        return subj.asObservable();
    }

    getGameDetailed(gameId) {
        const subj = new Subject();
        this.logEventRequest('query_games', {
            action: "getGameDetailed",
            gameId
        }, (response) => {
            if (response.error) subj.error(response.message);
            else subj.next(response.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getHomepageGames() {
        const subj = new Subject();
        this.logEventRequest('query_games', { action: 'getHomepage' }, (resp) => {
            if (resp.error) subj.error(resp.message);
            else {
                subj.next(resp.scriptData.homepageData);
            }
            subj.complete();
        });
        return subj.asObservable();
    }

    getUserChallenges() {
        const subj = new Subject();
        const payload = { action: 'getUserChallenges' };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData.challenges);
            subj.complete();
        });
        return subj.asObservable();
    }

    getChallengeResult(sessionId) {
        const subj = new Subject();
        const payload = { action: 'getCachedChallengeResult', sessionId };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData.challengeResult);
            subj.complete();
        });
        return subj.asObservable();
    }

    getGameChallenges(gameId) {
        const subj = new Subject();
        const payload = { game_uuid: gameId, action: 'get_challenges' };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData.challenges);
            subj.complete();
        });
        return subj.asObservable();
    }

    getGameChallengeHistory(gameId) {
        const subj = new Subject();
        const payload = { game_uuid: gameId, action: 'get_challenges_history' };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getGameLeaderboard(listType, gameId, challengeId, tournamentId, offset, accessType) {
        const subj = new Subject();
        const payload = {
            listType,
            gameId,
            challengeId,
            offset,
            accessType,
            action: tournamentId ? 'get_custom_leaderboard' : 'get_leaderboard'
        };
        this.logEventRequest('query_leaderboards', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData.leaderboard);
            subj.complete();
        });
        return subj.asObservable();
    }

    // id is gameID or ChallengeId - TODO ID is not needed at the moment as we return everything
    getGameChallengeHowToPlay(id) {
        const subj = new Subject();
        const payload = { id };
        this.logEventRequest('query_override_keymaps', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else {
                subj.next(resp.scriptData.keybindingImages);
            }
            subj.complete();
        });
        return subj.asObservable();
    }

    getCurrentPosition(gameId, challengeId, tournamentId) {
        const subj = new Subject();
        const payload = {
            gameId,
            challengeId,
            tournamentId,
            action: 'get_current_position'
        };
        this.logEventRequest('query_leaderboards', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData.currentPosition);
            subj.complete();
        });
        return subj.asObservable();
    }

    fetchMarketingLeaderBoard(leaderBoardId, offset) {
        const subj = new Subject();
        const payload = {
            leaderBoardId,
            offset,
            action: 'getLeaderBoard'
        };
        this.logEventRequest('marketing_event', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData.leaderBoard);
            subj.complete();
        });
        return subj.asObservable();
    }

    fetchMarketingEventData() {
        const subj = new Subject();
        const payload = {
            action: 'fetchEventData'
        };
        this.logEventRequest('marketing_event', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    setMarketingEventData(email, displayName, avatar) {
        const subj = new Subject();
        const payload = {
            email,
            displayName,
            avatar,
            action: 'setEventData'
        };
        this.logEventRequest('marketing_event', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    fetchMarketingCurrentPosition(leaderBoardId, email) {
        const subj = new Subject();
        const payload = {
            action: 'getLeaderBoardCurrentPosition',
            leaderBoardId,
            email,
        };
        this.logEventRequest('marketing_event', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getUserDetails(userId) {
        const subj = new Subject();
        const payload = { user_uuid: userId, action: 'profile_details' };
        this.logEventRequest('query_users', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    setUserEmail(email) {
        const subj = new Subject();
        const payload = {
            action: 'change_email',
            newEmail: email,
        };
        this.logEventRequest('query_settings', payload, (resp) => {
            if (resp.error) subj.error(resp.error);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    connectionTesterPopupSeen() {
        const subj = new Subject();
        const payload = {
            action: 'connection_tester_popup_seen',
        };
        this.logEventRequest('query_settings', payload, (resp) => {
            if (resp.error) subj.error(resp.error);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getUserRegion() {
        const subj = new Subject();
        this.logEventRequest('get_user_regions', null, (resp) => {
            if (resp.error) subj.error(resp.message);
            else {
                subj.next(resp.scriptData);
            }
            subj.complete();
        });
        return subj.asObservable();
    }

    setUserRegion(region) {
        const subj = new Subject();
        const payload = {
            UserRegion: region,
        };
        this.logEventRequest('update_user_region', payload, (resp) => {
            if (resp.error) subj.error(resp.error);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    setDisplayName(displayName) {
        const subj = new Subject();
        const payload = {
            action: 'change_display_name',
            newDisplayName: displayName,
        };
        this.logEventRequest('query_settings', payload, (resp) => {
            if (resp.error) subj.error(resp.error);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    setNudge(userid) {
        const subj = new Subject();
        const pl = {
            recepientId: userid
        };
        this.logEventRequest('nudge_player', pl, (resp) => {
            if (resp.error) subj.error(resp.error);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    setDebugSettings(payload) {
        const subj = new Subject();

        this.logEventRequest('set_debug_settings', payload, (resp) => {
            subj.next(resp.scriptData.debug);
            subj.complete();
        });
        return subj.asObservable();
    }

    getDebugSettings(payload) {
        const subj = new Subject();

        this.logEventRequest('get_debug_settings', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.next(resp.scriptData.debug);
            subj.complete();
        });
        return subj.asObservable();
    }

    generateRequestId(id, challengeId = null, localSessionObject = null, tournamentId = null, challengeInstanceId = null) {
        return Elastic.logSessionRequest({ id, challengeId, tournamentId, challengeInstanceId, localSessionObject });
    }

    getGameAddress(requestId, id, challengeId, localSessionObject, region, slot, challengeStyle, tournamentId, challengeInstanceId, giantSlayerChallengeId, hardwareEncodingType) {
        // Check if the hardware encoding is avaliable to users
        const subj = new Subject();
        const payload = {
            action: 'run',
            requestId: requestId,
            gameId: id,
            ...(hardwareEncodingType) && { colorProfile: hardwareEncodingType },
            ...(challengeId) && { challengeId: challengeId },
            ...(tournamentId) && { tournamentId: tournamentId },
            ...(challengeInstanceId) && { challengeInstanceId: challengeInstanceId },
            ...(localSessionObject) && { localSessionObject: localSessionObject },
            ...(region) && { region: region },
            ...(challengeStyle) && { challengeStyle: challengeStyle },
            ...(giantSlayerChallengeId) && { giantSlayerChallengeId: giantSlayerChallengeId },
            ...(deviceInfo.platformType) && { platform: deviceInfo.platformType },
            ...((slot === 0) || slot) && { slot: slot },
        };

        this.sessionRequestId = this.logEventRequest('query_games', payload, (resp) => {
            Elastic.logSessionResponse(resp);
            if ((this.sessionRequestId === resp.requestId) && !resp.error && resp.scriptData) {
                subj.next({
                    runGameResult: resp.scriptData.runGameResult,
                    requestId
                });
            } else {
                subj.error(resp.error ? resp.error.type : resp);
            }
            subj.complete();
        });
        return subj.asObservable();
    }

    sendChallengeRequest(gameId, challengeId, opponentId) {
        const subj = new Subject();
        const payload = {
            action: 'set_challenge_request',
            game_uuid: gameId,
            challenge_id: challengeId,
            opponent_uuid: opponentId
        };

        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) {
                subj.error(resp.error.type);
            } else {
                subj.next(resp.scriptData.response);
            }
            subj.complete();
        });
        return subj.asObservable();
    }

    acceptChallengeRequest(gameId, challengeId, challengeInstanceId) {
        const subj = new Subject();
        const payload = {
            action: 'accept_challenge',
            game_uuid: gameId,
            challenge_instance_id: challengeInstanceId,
            challenge_id: challengeId
        };

        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) {
                subj.error(resp.error.type);
            } else {
                subj.next(resp.scriptData.response);
            }
            subj.complete();
        });
        return subj.asObservable();
    }

    createGiantSlayerChallenge(requestId, challengeId) {
        const subj = new Subject();
        const payload = {
            action: 'giantSlayerCreate',
            requestId,
            challengeId,
        };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    abandonGiantSlayerChallenge() {
        const subj = new Subject();
        const payload = {
            action: 'giantSlayerAbandonIncomplete',
        };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    giantSlayerTargetAttemptsDone(giantSlayerChallengeId) {
        const subj = new Subject();
        const payload = {
            action: 'targetAttemptsDone',
            giantSlayerChallengeId,
        };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    giantSlayerJoinRequest(giantSlayerChallengeId) {
        const subj = new Subject();
        const payload = {
            action: 'joinRequest',
            giantSlayerChallengeId,
        };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    giantSlayerRandomJoinRequest() {
        const subj = new Subject();
        const payload = {
            action: 'randomJoinRequest',
        };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getGiantSlayerCreationDetails(challengeIds) {
        const subj = new Subject();
        const payload = {
            action: 'getCreationDetails',
            challengeIds,
        };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getGiantSlayerChallenges() {
        const subj = new Subject();
        const payload = {
            action: 'getChallenges',
            parameters: {
                "state": ["live", "created", "completed"],
                "role": ["eligible", "giant", "slayer"]
            }
        };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    abortGameSession(sessionId) {
        const subj = new Subject();
        const payload = {
            session_uuid: sessionId
        };
        this.logEventRequest('abort_session', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    getSimilarGames(id) {
        const subj = new Subject();
        const payload = { gameId: id, action: 'getSimilar' };
        this.logEventRequest('query_games', payload, (resp) => {
            subj.next(resp.scriptData.games);
            subj.complete();
        });
        return subj.asObservable();
    }

    getGamesBySearch(query) {
        const subj = new Subject();
        const payload = {};
        if (!query) {
            payload.action = 'fetchAllGames';
        } else {
            payload.searchTerm = query;
        }

        this.logEventRequest('query_search', payload, (resp) => {
            subj.next(resp.scriptData.searchResults);
            subj.complete();
        });
        return subj.asObservable();
    }

    fetchRecommendations() {
        const subj = new Subject();
        const payload = { action: 'getRecommendations' };
        this.logEventRequest('query_search', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.next(resp.scriptData.recommendations);
            subj.complete();
        });
        return subj.asObservable();
    }

    getGamePlatforms(gameId) {
        const subj = new Subject();
        const payload = { gameId, action: 'getMatchingPlatforms' };
        this.logEventRequest('query_games', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.next(resp.scriptData.matchingPlatforms);
            subj.complete();
        });
        return subj.asObservable();
    }

    followGame(gameId) {
        const subj = new Subject();
        const payload = { gameId, action: 'setFavourite' };
        this.logEventRequest('query_games', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    unfollowGame(gameId) {
        const subj = new Subject();
        const payload = { gameId, action: 'removeFavourite' };
        this.logEventRequest('query_games', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    signupNewUser(email, password, displayName, origin, attributionData) {
        const subj = new Subject();
        const payload = {
            userName: email,
            displayName: displayName,
            password: password,

            scriptData: {
                action: 'SimpleRegistration',
                origin,
                attributionData,
            }
        };
        this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else {
                subj.next(resp);
            }
            subj.complete();
        });
        return subj.asObservable();
    }

    finishRegistrationNewUser(avatarType, displayName, userId) {
        const subj = new Subject();
        const payload = {
            displayName: '', // here to get gamesparks to let the request through
            password: '',
            userName: '',
            scriptData: {
                action: 'FinishRegistration',
                displayName: displayName,
                avatarType: avatarType,
                userId,
            }
        };
        this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
            const { error, message } = resp.error.result;
            if (error) subj.error(resp);
            else {
                subj.next(message);
            }
            subj.complete();
        });
        return subj.asObservable();
    }


    // not used, we are using the signup call to check displayName
    checkDisplayName(displayName) {
        const subj = new Subject();
        const payload = {
            displayName: '',
            password: '',
            userName: '',
            scriptData: {
                action: 'CheckUserName',
                displayName,
            }
        };

        this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
            const result = resp.error.result;
            if (result.error) subj.error({ success: false, error: result.message });
            else subj.next({ displayName: result.message });
            subj.complete();
        });
        return subj.asObservable();
    }

    checkEmail(email) {
        const subj = new Subject();
        const payload = {
            displayName: '',
            password: '',
            userName: '',
            scriptData: {
                action: 'CheckEmail',
                email,
            }
        };
        this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
            const result = resp.error.result;
            if (result.error) subj.error({ success: false, error: result.message });
            else subj.next({ email: result.message });
            subj.complete();
        });
        return subj.asObservable();
    }

    passwordResetRequest(email) {
        const subj = new Subject();
        const payload = {
            displayName: '',
            password: '',
            userName: '',
            scriptData: {
                action: 'PasswordRecovery',
                email,
            }
        };
        this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
            // because of special circumstances the error key is always present even in case of success
            if (!resp.error.Verification) subj.error({ success: false, error: resp.error.message });
            else subj.next({ success: true, message: resp.error.Verification });
            subj.complete();
        });
        return subj.asObservable();
    }

    resetPassword(token, email, password) {
        const subj = new Subject();
        const payload = {
            displayName: '',
            password: '',
            userName: '',
            scriptData: {
                action: 'ResetPassword',
                token,
                password,
                email,
            }
        };
        this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
            if (!resp.error.ResetPassword) subj.error({ success: false, error: resp.error.message });
            else subj.next({ success: true, message: resp.error.ResetPassword });
            subj.complete();
        });
        return subj.asObservable();
    }

    setUserAvatar(url) {
        const subj = new Subject();
        const payload = {
            avatarUrl: url,
        };
        this.logEventRequest('set_base_avatar', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(resp);
            subj.complete();
        });
        return subj.asObservable();
    }

    setFreeTiershown() {
        const subj = new Subject();
        this.logEventRequest('query_payment', { action: 'setFreeTierPopupShown' }, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next();
            subj.complete();
        });
        return subj.asObservable();
    }

    forceSubscriptionRenew() {
        const subj = new Subject();
        this.logEventRequest('query_payment', { action: 'resetSubscriptionAnchor' }, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next();
            subj.complete();
        });
        return subj.asObservable();
    }

    redeemEpicGamesEntitlements({ epicGamesInterval = false } = {}) {
        const subj = new Subject();

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue('redeemEpicGamesEntitlements');

            const params = {
                body: {
                    response: null,
                    isIntervalCheck: epicGamesInterval
                },
                headers: { Authorization: 'Bearer ' + authToken }
            };

            antstreamAPIService.eos.redeemEntitlementsCreate(params).then(async ({ data }) => {
                if (!data) throw new Error('Something went wrong');
                if (data.error) {
                    // if something went wrong - it can be because of token expiration, so retry should help to resolve this issue
                    const retry = await antstreamAPIService.eos.redeemEntitlementsCreate(params);
                    if (retry?.data && !retry.data.error) {
                        subj.next(retry.data);
                    } else {
                        subj.next(retry.data);
                        throw new Error(retry.data.error);
                    }
                } else {
                    subj.next(data);
                }
            }).catch(catchErr => {
                console.error('Failed to redeem epic games entitlements: ', catchErr.message);
            });

        return subj.asObservable();
    }

    addVideoGems(eventType) {
        const subj = new Subject();
        this.logEventRequest('query_users', { action: 'userVideoWatch', eventType }, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next();
            subj.complete();
        });
        return subj.asObservable();
    }

    getPaymentData() {
        const subj = new Subject();
        this.logEventRequest('query_payment', { action: 'getCard' }, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData.paymentData', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    setMicrosoftStoreId(storeId) {
        const subj = new Subject();
        this.logEventRequest('query_payment', { action: 'setMicrosoftStoreId', storeIdKey: storeId }, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData.paymentData', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    getStripePlans() {
        const subj = new Subject();
        this.logEventRequest('query_payment', { action: 'getStripePlans' }, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData.stripePlans', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    getTruePlans() {
        const subj = new Subject();
        this.logEventRequest('query_payment', { action: 'getPlans', paymentTypes: ["trueTv"] }, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData.recurringPaymentPlans', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    getTruePaymentSources() {
        const subj = new Subject();
        this.logEventRequest('true_event', { action: 'getPaymentSources' }, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData.paymentSources', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    createTrueSubscription(sourceId, sku) {
        const subj = new Subject();
        const payload = {
            action: "createSubscription",
            sourceId,
            sku
        };
        this.logEventRequest('true_event', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    verifyTrueTvSource(sourceId) {
        const subj = new Subject();
        const payload = {
            action: "verifyPaymentSource",
            sourceId
        };
        this.logEventRequest('true_event', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    cancelTrueTvSubscription() {
        const subj = new Subject();
        const payload = {
            action: "cancelSubscription"
        };
        this.logEventRequest('true_event', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    followUser(id) {
        const subj = new Subject();
        const payload = { followed_uuid: id, action: 'follow' };
        this.logEventRequest('query_users', payload, (resp) => {
            // console.log(resp);
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    unfollowUser(id) {
        const subj = new Subject();
        const payload = { followed_uuid: id, action: 'unfollow' };
        this.logEventRequest('query_users', payload, (resp) => {
            // console.log(resp);
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    getLatestUsers(searchTerm = "", isChallengeOpponentsSearch, challengeId) {
        const subj = new Subject();
        const payload = {
            action: 'latest',
            isChallengeOpponentsSearch,
            challengeId,
            searchTerm
        };
        this.logEventRequest('query_users', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData.users', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    getMessages() {
        const subj = new Subject();
        const payload = { action: 'get_messages' };
        this.logEventRequest('query_messages', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    readMessages() {
        const subj = new Subject();
        const payload = { action: 'read_messages' };
        this.logEventRequest('query_messages', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData', null));
            subj.complete();
        });
        return subj.asObservable();
    }

    validateDeepLink(params) {
        const subj = new Subject();
        const payload = {
            action: 'validateDeepLink',
            params: {
                ...params
            }
        };
        this.logEventRequest('query_messages', payload, (resp) => {
            subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    unlockChallenge(gameId, challengeId) {
        const subj = new Subject();
        const payload = {
            action: 'unlock_challenge',
            game_uuid: gameId,
            challenge_id: challengeId,
        };
        this.logEventRequest('query_challenges', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getAchievements() {
        const subj = new Subject();
        const payload = {
            action: 'get_achievements',
        };
        this.logEventRequest('query_achievements', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    syncGems() {
        const subj = new Subject();
        const payload = { action: 'syncGems' };
        this.logEventRequest('query_users', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData', null));  // Doesn't return any value. Update will come via a message
            subj.complete();
        });
        return subj.asObservable();
    }

    setTutorials(payload) {
        const subj = new Subject();

        this.logEventRequest('set_tutorials', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next(get(resp, 'scriptData.allTutorials', []));
            subj.complete();
        });
        return subj.asObservable();
    }

    setContentRead(content) {
        const subj = new Subject();
        const payload = {
            action: 'set_content_read',
            id: content.id
        };
        this.logEventRequest('query_comms', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.complete();
        });
    }

    fetchCommsMessages(userId) {
        const subj = new Subject();
        const payload = {
            action: 'get_new_content_message',
            userId
        };
        this.logEventRequest('query_comms', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.complete();
        });
    }

    addUpvote(id) {
        const subj = new Subject();
        const payload = {
            action: 'add',
            game_uuid: id
        };
        this.logEventRequest('query_upvotes', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    removeUpvote(id) {
        const subj = new Subject();
        const payload = {
            action: 'remove',
            game_uuid: id
        };
        this.logEventRequest('query_upvotes', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getUpvote(id) {
        const subj = new Subject();
        const payload = {
            action: 'get',
            game_uuid: id
        };
        this.logEventRequest('query_upvotes', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    playEvent(sessionId) {
        const subj = new Subject();
        const payload = {
            sessionId
        };
        this.logEventRequest('play_event', payload, (resp) => {
            if (resp.error) subj.error(resp);
            else subj.next();
            subj.complete();
        });
        return subj.asObservable();
    }

    sendNativePaymentResponse(data) {
        const subj = new Subject();
        this.logEventRequest('native_payment_response', data, (resp) => {
            if (resp.error) subj.error(resp);
            else if (resp.scriptData) subj.next(resp.scriptData.data);
            subj.complete();
        });
        return subj.asObservable();
    }

    logEventRequest(eventKey, data, onResponse) {
        const request = { eventKey };
        if (data) request.data = data;
        return this.gameSparks.sendWithData('LogEventRequest', request, onResponse);
    }

    logOut() {
        const subj = new Subject();
        this.logEventRequest('log_out', null, (resp) => {
            if (!resp.error) {
                KeepAlive.userLoggedOut();
            }

            subj.next(resp);
            subj.complete();
        });
        return subj.asObservable();
    }

    updateLastEventTime() {
        this.logEventRequest('update_last_event_time', null, null);
    }

    getTournamentsList(group) {
        const subj = new Subject();
        const payload = {
            action: 'getList',
            group: group ? group : null
        };
        this.logEventRequest('query_tournament', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData.tournaments);
            subj.complete();
        });
        return subj.asObservable();
    }

    getTournamentDetail(tournamentId) {
        const subj = new Subject();
        const payload = {
            action: 'getTournamentData',
            tournamentId
        };
        this.logEventRequest('query_tournament', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    addPlayerToTournament(tournamentId, isPopup) {
        const subj = new Subject();
        const payload = {
            action: 'addPlayer',
            tournamentId,
            isPopup
        };
        this.logEventRequest('query_tournament', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    setIsTournamentInfoPopupSeen(tournamentId) {
        const subj = new Subject();
        const payload = {
            action: 'setIsTournamentInfoPopupSeen',
            tournamentId
        };
        this.logEventRequest('query_tournament', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp);
            subj.complete();
        });
        return subj.asObservable();
    }

    checkTournamentInfoPopups() {
        const subj = new Subject();
        const payload = {
            action: 'checkTournamentInfoPopups'
        };
        this.logEventRequest('query_tournament', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getTournamentsPlayers(tournamentId) {
        const subj = new Subject();
        const payload = {
            action: 'getPlayers',
            tournamentId,
        };
        this.logEventRequest('query_tournament', payload, (resp) => {
            if (resp.error) subj.error(resp.message);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    setPushId(data) {
        const subj = new Subject();
        const payload = {
            action: 'register',
            pushId: data.id,
            deviceOS: deviceInfo.osType
        };
        this.logEventRequest('query_push_registration', payload, (resp) => {
            if (resp.error) subj.error(resp);
            subj.complete();
        });
    }

    setUserLanguage(language) {
        const subj = new Subject();
        const payload = {
            UserLanguage: language,
        };
        this.logEventRequest('update_user_language', payload, (resp) => {
            if (resp.error) subj.error(resp.error);
            else subj.next(resp.scriptData);
            subj.complete();
        });
        return subj.asObservable();
    }

    getGameSparksInstance() {
        return this.gameSparks
    }
}

export default Antstream;
