import * as ACTIONS from './search.actions';
import {LOGOUT_USER_ACTION} from '../login/login.actions';
import {FETCH_ALL_GAMES_SUCCESS, SET_SEARCH_QUERY_ACTION} from './search.actions';
import {FETCH_RECOMMENDATIONS_SUCCESS} from './search.actions';

const initialState = {
	loading: false,
	gameResults: [],
	relatedGameResults: [],
	platformsResults: [],
	publisherResults: [],
	genresResults: [],
	userResults: [],
	searchQuery: '',
	allGamesFetched: false,
	gameListsByFirstLetter: [],
	recommendations: null,
};

export const searchReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACTIONS.FETCH_ALL_GAMES:
	case ACTIONS.FETCH_SEARCH_RESULT:
		return {
			...state,
			loading:true,
		};
	case ACTIONS.FETCH_SEARCH_RESULT_SUCCESS:
		const users = action.payload.users;
		const games = action.payload.games;
		const relatedGameResults = action.payload.relatedGames;
		const platforms = action.payload.platforms;
		const publishers = action.payload.publishers;
		const years = action.payload.years;
		const gamesWithChallenges = action.payload.gamesWithChallenges;
		const genres = action.payload.genres;
		return {
			...state,
			searchQuery: action.payload.query,
			gameResults: games ? games : [] ,
			relatedGameResults: relatedGameResults ? relatedGameResults : [] ,
			userResults: users ? users : [] ,
			platformsResults: platforms ? platforms : [] ,
			publisherResults: publishers ? publishers : [] ,
			yearsResults: years ? years : [] ,
			gamesWithChallenges: gamesWithChallenges ? gamesWithChallenges : [] ,
			genresResults: genres ? genres : [] ,
			loading:false,
		};
	case FETCH_ALL_GAMES_SUCCESS:
		const gamesListsByFirstLetter = {};

		action.games.forEach(game => {
			const firstChar = game.title.charAt(0);
			let title;

			if(isNaN(firstChar)){
				title = firstChar.toUpperCase();
			}else{
				title = '0-9';
			}

			if(!gamesListsByFirstLetter[title]) {
				gamesListsByFirstLetter[title] = {
					title,
					games:[game._id]
				};
			}else {
				gamesListsByFirstLetter[title].games.push(game._id);
			}
		});

		const gameListsByFirstLetter = Object.values(gamesListsByFirstLetter);
		gameListsByFirstLetter.sort((a, b) => {return a.title<b.title?-1:1});

		return {
			...state,
			loading:false,
			allGamesFetched:true,
			gameListsByFirstLetter,
		};
	case ACTIONS.FETCH_ALL_GAMES_ERROR: {
		return {
			...state,
			loading:false,
			allGamesFetched:true
		};
	}
	case FETCH_RECOMMENDATIONS_SUCCESS:
		return {
			...state,
			recommendations:action.recommendations,
		};
	case LOGOUT_USER_ACTION:
		return initialState;
	case SET_SEARCH_QUERY_ACTION:
		return {
			...state,
			searchQuery:action.query,
		};
	default:
		return state;
	}
};
