import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from '../../common/input/input.component';
import { Button } from '../../common/button/common-button.component';
import { SelectionButton } from '../../common/selection-button/selection-button.component';
import inputManager from '../../../assets/lib/inputmanager';
import { SetDebugSettingsAction } from '../settings.actions';
import {causeCrash, getPlatformLoginInfo} from '../../../assets/lib/game-wrapper';
import '../settings.component.less';
import { getLoggedUser } from '../../../app.selectors';
import featureInfo, { FEATURES } from '../../../assets/lib/featureInfo';
import { addPopup } from '../../popup/popup.component';
import GenericPopup from '../../popup/generic-popup/generic-popup.component';
import {antstreamService} from '../../../app.reducer';


class SettingsDebugComponent extends Component {
    constructor() {
        super();
        this.state = {};
    }

    accessStringToValue = (text) => {
        const accessArray = text.split(/[, ;]+/);
        if (accessArray.length <= 1) {
            return text;
        }
        return accessArray;
    }

    accessValueToString = (text) => {
        return text.toString();
    }    


    onSave = () => {
        if (!featureInfo.isSupported(FEATURES.DEBUG_SETTINGS)) {
            addPopup(<GenericPopup
                title="Something went wrong"
                okButtonLabel="Got it!"
                message="Editing debug settings not allowed"
              />)
            return;
        }

        const access = this.refs.access.getValue();
        const gameControls = this.refs.gameControls.getValue();
        const showLagGraph = this.refs.showLagGraph.getValue();
        const headlessNative = this.refs.headlessNative.getValue();
        const showControllerDebug = this.refs.showControllerDebug.getValue();
        const qualitySetting = this.refs.qualitySetting.getValue();
        const localGameServer = this.refs.localGameServer.getValue();
        const noVblank = this.refs.noVblank.getValue();
        const useSmoothing = this.refs.useSmoothing.getValue();
        const useRumble = this.refs.useRumble.getValue();
        const uiURL = this.refs.serverhost.getValue();
        const debug = {
            'access': this.accessStringToValue(access ? access : this.props.access),
            'control_editing': gameControls,
            'show_lag_graph': showLagGraph,
            'headless_native': headlessNative,
            'show_controller_debug': showControllerDebug,
            'no_vblank': noVblank,
            'use_smoothing': useSmoothing,
            'use_rumble': useRumble,
            'quality_setting': qualitySetting,
            'consoleOpen': this.refs.console.getValue() === 'On',
            'fpsPanelOpen': this.refs.fpsPanel.getValue() === 'On',
            'local_game_server': localGameServer ? localGameServer : this.props.localGameServer,
            'uiURL': uiURL ? uiURL : this.props.uiURL
        };
        
        this.props.dispatch(SetDebugSettingsAction(debug));
    };

    onNewUrl = () => {
        let thisUrl = this.refs.serverhost.getValue();
        if(thisUrl === window.location.hostname) {
            thisUrl = '';
        }
        window.location.href = thisUrl;
    };

    onCauseCrash = () => {
        causeCrash();
    };

    onForceSubscriptionRenew = () => {
         antstreamService.forceSubscriptionRenew();
    };

    onPlatformLogin = () => {
        getPlatformLoginInfo();
    }

    onSettingButtonsContainerRef = (element) => {
        inputManager.onParentRef(element,"settingButtonsContainer");
    };    

    render() {
        return (
            <div className="settings-debug">
                <ul className="settings-list">
                    <li>
                        <p>User Access</p>
                        <Input ref="access"  defaultValue={this.accessValueToString(this.props.access)} childId="userAccessInput"/> 
                    </li>
                    <li>
                        <p>Services Environment</p>
                        <div>{window.config.REACT_APP_ENV}</div>
                    </li>
                    <li>
                        <p>Allow in-game control editing</p>
                        <SelectionButton ref="gameControls" values={ [ 'true', 'false' ] } selected={this.props.gameControls} childId="gameControlsSelection"/>
                    </li>
                    <li>
                        <p>Show lag and debug info</p>
                        <SelectionButton ref="showLagGraph" values={ [ 'true', 'false' ] } selected={this.props.showLagGraph} childId="showLagGraphSelection"/>
                    </li>            
                    <li>
                        <p>Show controller debug inputs</p>
                        <SelectionButton ref="showControllerDebug" values={ [ 'true', 'false' ] } selected={this.props.showControllerDebug} childId="showControllerDebugSelection"/>
                    </li>    
                    <li>
                        <p>Headless Native</p>
                        <SelectionButton ref="headlessNative" values={ [ 'true', 'false' ] } selected={this.props.headlessNative} childId="headlessNativeSelection"/>
                    </li>                        
                    <li>
                        <p>Disable Vertical Blank for Lower Lag (vblank)</p>
                        <SelectionButton ref="noVblank" values={ [ 'true', 'false' ] } selected={this.props.noVblank} childId="noVblankSelection"/>
                    </li>
                    <li>
                        <p>Enable Smoother Graphics (slight lag increase)</p>
                        <SelectionButton ref="useSmoothing" values={ [ 'true', 'false' ] } selected={this.props.useSmoothing} childId="useSmoothingSelection"/>
                    </li>    
                    <li>
                        <p>Enable Android Touchpad Rumble</p>
                        <SelectionButton ref="useRumble" values={ [ 'true', 'false' ] } selected={this.props.useRumble} childId="useRumbleSelection"/>
                    </li>                                                            
                    <li>
                        <p>Quality Settings</p>
                         <SelectionButton ref="qualitySetting" values={ [ 1, 0 ] } selected={this.props.qualitySetting} childId="qualitySettingSelection"/>
                    </li>
                    <li>
                        <p>Game Server</p>
                         <Input ref="localGameServer" defaultValue={this.props.localGameServer} childId="localGameServerInput"/>
                    </li>
                    <li>
                        <p>Open Console</p>
                         <SelectionButton ref="console" values={ [ 'On', 'Off' ] } selected={this.props.consoleOpen} childId="consoleSelection"/>
                    </li>
                    <li>
                        <p>Open FPS Panel</p>
                        <SelectionButton ref="fpsPanel" values={ [ 'On', 'Off' ] }  selected={this.props.fpsPanelOpen} childId="fpsPanelSelection"/>
                    </li>
                    <li>
                        <p>Screen Avaiable</p>
                        <div>{window.screen.availWidth} x {window.screen.availHeight}</div>
                    </li>
                    <li>
                        <p>React Server Host</p>
                        <Input ref="serverhost" defaultValue= {this.props.uiURL} childId="serverHostInput"/>
                    </li>
                    
                </ul>
                <div
                    className="buttons-container"
                    ref={this.onSettingButtonsContainerRef}
                >
                    <Button className="secondary" onClick={this.onPlatformLogin} parentId="settingButtonsContainer" childId="XboxKButton">Platform Login</Button>                   
                    <Button className="secondary" onClick={this.onCauseCrash} parentId="settingButtonsContainer" childId="crashButton">Cause Crash</Button>
                    <Button className="secondary" onClick={this.onForceSubscriptionRenew} parentId="settingButtonsContainer" childId="renewSubsButton">Renew Subscription</Button>                    
                    <Button className="secondary" parentId="settingButtonsContainer" childId="resetButton">Reset</Button>
                    <Button className="primary" onClick={this.onSave} parentId="settingButtonsContainer" childId="saveSettingsButton">Save settings</Button>
                    <Button className="primary" onClick={this.onNewUrl} parentId="settingButtonsContainer" childId="reconnectButton">Reconnect</Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const user = getLoggedUser(state);
    const debugEnabled = (user ? user.debugEnabled : false);

    return { 
        debugEnabled,
        access: state.settings.access || 'subscriber',
        gameControls: state.settings.control_editing || 'false',
        showLagGraph: state.settings.show_lag_graph || 'false',
        headlessNative: state.settings.headless_native || 'false',
        showControllerDebug: state.settings.show_controller_debug || 'false',
        noVblank: state.settings.no_vblank || 'true',
        useSmoothing: state.settings.use_smoothing || 'false',
        useRumble: state.settings.use_rumble || 'false',
        qualitySetting: state.settings.quality_setting || 0,
        localGameServer : state.settings.local_game_server || 'ASL',
        consoleOpen: state.settings.consoleOpen ? 'On' : 'Off',
        fpsPanelOpen: state.settings.fpsPanelOpen ? 'On' : 'Off',
        uiURL: state.settings.uiURL ? state.settings.uiURL : window.location.hostname,
    };
};

export const SettingsDebug = connect(mapStateToProps)(SettingsDebugComponent);
