import { fromJS } from 'immutable';
import * as ACTIONS from './login.actions';

const initialState = fromJS({
	error: null,
	loading: false,
	logged: false,
	loggedUser: null,
	userFields: {},
	emailAccepted: false,
	nativePaymentOnHomepage: false,
	checkDisplayNamePending: false,
	lastTimeAccountAlreadySubscribedShown: null,
	displayName: null,
	waysunToken: null,
});

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACTIONS.REQUEST_PASSWORD_RESET_ACTION:	
	case ACTIONS.RESET_PASSWORD_ACTION:	
	case ACTIONS.SIGNUP_USER_ACTION:
		return { 
			...state,
			loading: true,
			error: null
		};
	case ACTIONS.TOGGLE_NATIVE_PAYMENT_ON_HOMEPAGE: {
		return {
			...state,
			nativePaymentOnHomepage: action.payload
		};
	}
	case ACTIONS.LAST_TIME_ACC_ALREADY_SUBSCRIBED_ACTION: {
		return {
			...state,
			lastTimeAccountAlreadySubscribedShown: action.payload
		};
	}
	case ACTIONS.SIGNUP_USER_ACTION_SUCCESS:
		return { 
			...state,
			loading: false,
			logged: true,
			userFields: {},
			error: null
		};
	case ACTIONS.REQUEST_PASSWORD_RESET_ACTION_FAIL:	
	case ACTIONS.RESET_PASSWORD_ACTION_FAIL:	
	case ACTIONS.SIGNUP_USER_ACTION_FAIL:
		return {
			...state,
			loading: false,
			error: action.payload && action.payload.error
		};
	case ACTIONS.LOGIN_USER_ACTION:
		return { 
			...state,
			loading: true,
			error: null
		};
	case ACTIONS.LOGOUT_USER_ACTION:
		return {
			...state,
			logged: false,
			loading: false,
			error: null,
			userFields: {}
		};
	case ACTIONS.LOGIN_USER_ACTION_SUCCESS:
		return { 
			...state,
			loading: false,
			logged: true,
			userFields: {}
		};
	case ACTIONS.LOGIN_USER_ACTION_FAIL:
		return { 
			...state,
			loading: false,
			logged: false,
			error: action.payload.DETAILS
		};
	case ACTIONS.SET_SIGNUP_FIELD_ACTION:
		return {
			...state,
			userFields: {...state.userFields,...action.payload}
		};
	case ACTIONS.CHECK_EMAIL_ACTION_SUCCESS:
		return {
			...state,
			error: null,
			emailAccepted: true,
		};

	case ACTIONS.CHECK_EMAIL_ACTION_CLEAR:
		return {
			...state,
			error: null,
			emailAccepted: false,
		};

	case ACTIONS.CHECK_EMAIL_ACTION_FAIL:
		return {
			...state,
			error: action.payload.error,
			emailAccepted: false,
		};

	case ACTIONS.CHECK_DISPLAY_NAME_ACTION: {
		return {
			...state,
			checkDisplayNamePending: true
		};
	}

	case ACTIONS.CHECK_GAMER_TAG_ACTION_SUCCESS:
		return {
			...state,
			error: null,
			checkDisplayNamePending: false,
			displayName: action.payload.displayName,
		};

	case ACTIONS.CHECK_GAMER_TAG_ACTION_CLEAR:
		return {
			...state,
			error: null,
			displayName: null,
		};

	case ACTIONS.CHECK_GAMER_TAG_ACTION_FAIL:
		return {
			...state,
			checkDisplayNamePending: false,
			error: action.payload.error,
			displayName: null,
		};
	case ACTIONS.REQUEST_PASSWORD_RESET_ACTION_SUCCESS:
	case ACTIONS.RESET_PASSWORD_ACTION_SUCCESS:
		return {
			...state,
			loading: false,
			error: null
		};
	case ACTIONS.WAYSUN_INITIALISATION_ACTION:
		return {
			...state,
			waysunAntstreamtoken: action.waysunAntstreamtoken,
		};
	case ACTIONS.REQUEST_WAYSUN_TOKEN_ACTION:
		return {
			...state,
			waysunUserId: action.userId
		};
	case ACTIONS.REQUEST_WAYSUN_TOKEN_ACTION_SUCCESS:
		return {
			...state,
			waysunOrderToken: action.waysunOrderToken
		};
	case ACTIONS.WAYSUN_USED_ORDER_TOKEN:
		const {waysunOrderToken, ...rest} = state
		return {
			...rest,
		};
	default:
		return state;
	}
}; 
