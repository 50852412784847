import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOpponents } from '../challenge/challenge.selectors';
import { FetchOpponentsAction } from '../challenge/challenge.actions';
import ListContainer, { LIST_TYPES } from '../common/list/list-container.component';

import { Input } from '../common/input/input.component';
import './player-search.component.less';
import inputmanager, { PARENT_ENTRANCE } from '../../assets/lib/inputmanager';
import { Loader } from '../common/loader/loader.component';

class PlayerSearchComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			results: []
		};
	}

	componentDidMount() {
		this.props.dispatch(FetchOpponentsAction("",this.onSearchResult ,this.props.isChallengeOpponentsSearch, this.props.challengeId));
	}

	componentDidUpdate(prevProps) {
		if (this.props.opponents !== prevProps.opponents) {
			this.setState({
				results: this.createResultsLists(this.props.opponents)
			});
		}
	}

	filterOpponents(opponents, tag) {
		return opponents.filter(opponent => opponent.tags && opponent.tags.includes(tag));
	}

	createResultsLists(opponents) {
		if (!opponents || opponents.length < 1) return;
		const { itemRenderer } = this.props;
		const defaultValuesForList = {
			type: LIST_TYPES.GRID,
			renderer: itemRenderer,
			parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL
		}
		const latestChallenged = { 
			id: 'Recently Challenged',
			title: "Recently Challenged",
			data: this.filterOpponents(opponents, 'challenged'),
			...defaultValuesForList
		};
		const followingList = {
			id: 'Following',
			title: "Following",
			data: this.filterOpponents(opponents, 'followed'),
			...defaultValuesForList
		};
		const followersList = {
			id: 'Followers',
			title: "Followers",
			data: this.filterOpponents(opponents, 'follower'),
			...defaultValuesForList

		};
		const latestList = { 
			id: 'Latest',
			title: "Latest",
			data: this.filterOpponents(opponents, 'latest'),
			...defaultValuesForList
		};

		const lists = [];
		if(latestChallenged.data.length>0)lists.push(latestChallenged);
		if(followingList.data.length>0)lists.push(followingList);
		if(followersList.data.length>0)lists.push(followersList);
		if(latestList.data.length>0)lists.push(latestList);
		return lists;
	}

	onSearchResult = () => {
		inputmanager.refreshBoundingRects();
	};

	onItemSelected = (id,title) => {
		if (this.props.onItemSelected) this.props.onItemSelected(id, title);
	};

	handleSearchChange = (value) => {
		const query = value;

		if(this.searchTimer)clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this.searchTimer = null;
			this.props.dispatch(FetchOpponentsAction(query,this.onSearchResult ,this.props.isChallengeOpponentsSearch, this.props.challengeId));
		},500);
	};

	render() {
		const { results } = this.state;
		const { itemRenderer, loading } = this.props;

		return (
			<section className="player-search" >
				<div className="top-container">
					<div className="search-input-container">
						<Input
							input={{onChange: (e)=>this.handleSearchChange(e.target.value)}}
							onValueChange={this.handleSearchChange}
							containerClass="novalidate"
							placeholder="Search..."
							childId = "playerSearchInput"
						/>
					</div>
				</div>
				{loading && <Loader loading={true}/>}
				{!loading && results && results.length > 0 &&
					<div className="tile-lists-container">
						<ListContainer
							lists={results}
							renderer={itemRenderer}
							onTileClick={this.onItemSelected}
						/>
					</div>
				}
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	const opponents = getOpponents(state);

	return {
		loading: state.challenge.loading,
		opponents,
	};
};

export const PlayerSearch = connect(mapStateToProps)(PlayerSearchComponent);
