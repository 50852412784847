import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUnreadMessageCount} from '../../app.selectors';
import {SetScrollTopAction} from '../homepage/homepage.actions';
import { ROUTES, navigateToLocation } from '../../app.router';
import inputManager from '../../assets/lib/inputmanager';
import { NavigationItem } from './navigation-item.component';
import './navigation.component.less';
import {openQuitPopup} from '../../app.actions';
import deviceInfo, {PLATFORM_TYPE_ATARI} from "../../assets/lib/deviceInfo";
import {setSearchQueryAction} from "../search/search.actions";
import {updateSearch} from "../search/search.component";
import { openUrlOnDefaultBrowser, startNativePayment } from '../../assets/lib/game-wrapper';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import { isFeatureDailyChallengesEnabled, isXboxUser } from '../../assets/lib/utils';
import { antstreamService } from '../../app.reducer';
import { getPopupVisibility } from '../popup/popup.selectors';

const parentId = 'navBar';
class NavigationComponent extends Component {
	constructor(props) {
		super(props);
		this.unsubscribeKeyPressedMessage$ = null;
	}

	componentDidMount() {
		if( this.props.currentRoute.path===ROUTES.HOMEPAGE.path ) {
			inputManager.setCurrentChildById("home", parentId);
		}

		this.subscribeToGamepadEvents();
	}

	componentWillUnmount() {
		this.unsubscribeFromGamepadEvents();
	}

	createNavigationButtons = () => {
		const {unreadMessages} = this.props;

		const tournamentsOrDailyChallenges = isFeatureDailyChallengesEnabled() ? (
			<NavigationItem
				key="dailyChallenges"
				parentId={parentId}
				itemType="dailyChallenges"
				section="daily-challenges"
				onClick={() => this.navigateToLoc(ROUTES.DAILY_CHALLENGES)}
			/>
		) : (
			<NavigationItem
				key="tournaments"
				parentId={parentId}
				itemType="tournaments"
				section="tournaments"
				onClick={() => this.navigateToLoc(ROUTES.TOURNAMENTS_LIST)}
			/>
		);

		const buttons = [
			<NavigationItem
				key="home"
				parentId={parentId}
				itemType="home"
				section="homepage"
				onClick={(isScrollTopActive) => {
					if(isScrollTopActive) {
						this.props.dispatch(SetScrollTopAction(true));
					} else {
						this.navigateToLoc(ROUTES.HOMEPAGE);
					}
				}}
			/>,
			tournamentsOrDailyChallenges,
			<NavigationItem
				key="achievements"
				parentId={parentId}
				itemType="achievements"
				section={deviceInfo.isPlayStationPlatform() ? "trophies" : "achievements"}
				onClick={() => {
					this.navigateToLoc(ROUTES.ACHIEVEMENTS);
				}}
			/>,
			<NavigationItem
				key="message"
				className={`${unreadMessages ? 'unread-message' : ''}`}
				parentId={parentId}
				itemType="message"
				section="inbox"
				onClick={() => {
					this.navigateToLoc(ROUTES.INBOX);
				}}
			/>,
		];

		const safeAreaHorizontalOn = (deviceInfo.safeAreaHorizontal && deviceInfo.safeAreaHorizontal !== 1);

		if(deviceInfo.platformType === PLATFORM_TYPE_ATARI) {
			buttons.push(
				<NavigationItem
					key="atari"
					parentId={parentId}
					itemType="atari"
					onClick={() => {
						this.navigateToLoc(ROUTES.SEARCH);
						if (updateSearch) {
							updateSearch('atari');
						} else {
							this.props.dispatch(setSearchQueryAction('atari'));
						}
					}}
				/>
			);
		} else if (featureInfo.isSupported(FEATURES.DISCORD) && !this.props.isXboxUser && !deviceInfo.isPlayStationPlatform() && !safeAreaHorizontalOn) {
			buttons.push(
				<NavigationItem
					key="community"
					parentId={parentId}
					itemType="community"
					section="community"
					onClick={() => {
						openUrlOnDefaultBrowser("https://discord.gg/antstream");
					}}
				/>
			);
		}
		if (deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) {
			buttons.push(
				<NavigationItem
					key="consoleLibrary"
					parentId={parentId}
					itemType={deviceInfo.isXboxPlatform()?"xboxLibrary":"playstationLibrary"}
					section="search"
					onClick={() => {
						this.navigateToLoc(ROUTES.SEARCH);
					}}
				/>
			);
		} else {
			buttons.push(
				<NavigationItem
					key="library"
					parentId={parentId}
					itemType="library"
					section="search"
					onClick={() => {
						this.navigateToLoc(ROUTES.SEARCH);
					}}
				/>
			);
		}

		if (deviceInfo.isPlayStationPlatform()) {
			buttons.push(
				<NavigationItem
					key='storeCart'
					parentId={parentId}
					itemType='storeCart'
					section='storeCart'
					onClick={startNativePayment}
				>
					<span className='storeCartImg'/>
				</NavigationItem>
			);
		}

		return buttons;
	}

	navigateToLoc = (route) => {
		navigateToLocation(route);
	};

	onNavigationRef = (element) =>{
		inputManager.onParentRef(element,parentId,true);
	};

	subscribeToGamepadEvents = () => {
		if (!this.props.isXboxUser && !deviceInfo.isPlayStationPlatform()) return;
		if (!deviceInfo.isXboxPlatform() && !deviceInfo.isPlayStationPlatform()) return;

		this.unsubscribeKeyPressedMessage$ = antstreamService
			.getGamepadKeyPressedMessage$()
			.subscribe(this.handleGamepadEvent);
	};

	unsubscribeFromGamepadEvents = () => {
		if (this.unsubscribeKeyPressedMessage$) {
			this.unsubscribeKeyPressedMessage$.unsubscribe();
		}
	};

	handleGamepadEvent = (msg) => {
		if (msg === 'yButton') {
			if (!this.props.isPopupClosed) return;
			this.navigateToLoc(ROUTES.SEARCH);
			inputManager.setCurrentChildById('consoleLibrary', parentId);
		}
		if (msg === 'xButton') {
			if (deviceInfo.isPlayStationPlatform()) {
				startNativePayment();
			}
		}
	};

	render() {
		return (
			<div
				className="navigation"
				ref={this.onNavigationRef}
			>
				{<NavigationItem
					parentId={parentId}
					itemType="back"
					disabled={!this.props.canGoBack}
					onClick={() => {
						if(this.props.canGoBack) {
							inputManager.onPressButtonB();
						} else {
							openQuitPopup();
						}
						}}
					/>}
				{this.createNavigationButtons()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const canGoBack = state.routing.canGoBack;

	return {
		isPopupClosed: getPopupVisibility(state),
		isXboxUser: isXboxUser(state),
		unreadMessages: getUnreadMessageCount(state),
		currentRoute: state.routing.currentRoute,
		canGoBack
	};
};

export const Navigation = connect(mapStateToProps)(NavigationComponent);
