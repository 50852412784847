import { UPDATE_ALL_CHALLENGES_DATA } from './challenges.actions';
import {challengesNormalizer} from '../entities.normaliser';
import {isLeaderBoardEnabledFlagErased} from '../../assets/lib/utils';

const initialState = {
	byId:{},
	allIds:[],
};

export const challengesReducer = (state = initialState, action) => {
switch (action.type) {
	case UPDATE_ALL_CHALLENGES_DATA:
		const normalizedData = challengesNormalizer(action.challenges);

		// compare existing challenges in order to prevent overriding isLeaderBoardEnabled flag
		const newChallengesIds = (normalizedData.entities?.challenges) ? Object.keys(normalizedData.entities?.challenges): [];
		const alreadyExistingChallengesIds = newChallengesIds.filter(id => state.allIds.includes(id));
		alreadyExistingChallengesIds.forEach(id => {
			if (isLeaderBoardEnabledFlagErased(state.byId[id], normalizedData.entities.challenges[id])) {
				normalizedData.entities.challenges[id].isLeaderBoardEnabled = true;
			}
		});

		return {
			...state,
			byId: {
				...state.byId,
				...normalizedData.entities.challenges
			},
			allIds: [
				...state.allIds,
				...normalizedData.result.filter(id=>!state.allIds.includes(id))
			],
		};
	default:
		return state;
	}
};
