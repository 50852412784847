import React,{Component} from 'react';
import { connect } from 'react-redux';

import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import inputManager from '../../../assets/lib/inputmanager';
import './gem-credit-popup.component.less';
import gemImage from '../../../assets/images/global/gems.png';
import {
	addAnimationToStack,
	cancelHeaderAnimations,
	updateGemAnimationData
} from '../../user-header/user-header.component';
import {TYPE_GEM} from '../../animations/gem.animation';
import {generateUUID, isLowSpecDevice} from '../../../app.helpers';
import CountAnimation from '../../animations/count.animation';

const TYPE_ANIMATION_CONTAINER = 'gemCreditPopup';
const navLayer=2;

class GemCreditPopup extends Component {
	constructor(props){
		super(props);

		this.containerId = generateUUID();

		this.state = {
			gemsValue: 0
		};
	}

	componentDidMount() {
		inputManager.setCurrentChildById("GemCreditPopupComponent-okButton");

		this.delayTimer = setTimeout(()=> {
			this.delayTimer = null;
			if(isLowSpecDevice()) { //without animation.
				updateGemAnimationData(this.props.gemsObject);
				this.setState({gemsValue:this.props.value});
			} else {
				this.startAnimation(this.props.gemsObject);
			}
		}, 1000);

		inputManager.setBackFunction(()=>{this.onOkButtonClicked()},navLayer);
	}

	componentWillUnmount() {
		this.didUnmounted=true;
		cancelHeaderAnimations(TYPE_ANIMATION_CONTAINER,this.containerId);
		if(this.delayTimer) { //if popup closed before timer end, it should update header immediately
			clearTimeout(this.delayTimer);
			this.delayTimer=null;
			updateGemAnimationData(this.props.gemsObject);
		}

		inputManager.setBackFunction(null,navLayer);
	}

	onOkButtonClicked = () => {
		this.props.handleClick && this.props.handleClick() 
		closePopup();
	};

	startAnimation(gemsObject) {
		let value = this.props.value;
		console.log('value: ', value)
		if(value>20)value=20;

		if(!this.gemRef)return null;
		const gemRect = this.gemRef.getBoundingClientRect();
		const startPoint = {
			x:gemRect.left,
			y:gemRect.top,
		};

		addAnimationToStack (
			TYPE_GEM,
			value,
			startPoint,
			TYPE_ANIMATION_CONTAINER,
			{
				gemsObject,
				animationStartCallback:() => this.onHeaderAnimationStart(TYPE_GEM)
			},
			this.containerId
		);
	}

	onHeaderAnimationStart = (type) => {
		if(this.didUnmounted)return;
		this.props.onAnimationStart && this.props.onAnimationStart()
		if(type === TYPE_GEM) {
			this.setState({gemsValue:this.props.value});
		}
	};

	render() {
		return (
			<div className="gem-credit-popup-component">
				<div className="title-text">
					{this.props.title}
				</div>
				<div className="message-text">
					{this.props.message}
				</div>
				<div className="gem-credit-popup-component-gem-container">
					<img ref={element => this.gemRef = element} className="daily-gems-img" src={gemImage} alt="gem"/>
					<h3 className={`daily-gems-value ${this.state.gemsValue > 0 ? 'animation' : ''}`}><CountAnimation startValue={0} endValue={this.state.gemsValue} duration={1.5}/></h3>
				</div>
				<div className="buttons-container">
					<Button
						className="primary"
						onClick={this.onOkButtonClicked}
						childId="GemCreditPopupComponent-okButton"
						layer={navLayer}
					>
						{this.props.buttonText}
					</Button>
				</div>
			</div>
		);
	}

}

export default connect()(GemCreditPopup);