import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {SetTutorialId} from '../tutorial/tutorial.actions.js';
import {getMessages, getSelectedMessage, getIsLoading, getMessagesUpdateId} from './inbox.selector';
import { SetSelectedMessageId } from './inbox.actions';
import { getIsPostAuthFetched, getLoggedUser, getPreviousRoute, getUserIsSubscribed } from '../../app.selectors';
import {
	FetchMessagesAction,
	ReadMessagesAction
} from '../../entities/messages/messages.actions';
import { Loader } from '../common/loader/loader.component';
import { MessageMPCIssued } from './message-mpc-issued/message-mpc-issued.component';
import { MessageMPCResult } from './message-mpc-result/message-mpc-result.component';
import VerticalList from '../common/vertical-list/vertical-list.component';
import { MessageListItem } from './message-list/message-list-item.component';
import inputManager from '../../assets/lib/inputmanager';
import { navigateToLocation, ROUTES } from '../../app.router';
import './inbox.component.less';
import {SET_SELECTED_CHALLENGE_HISTORY} from '../challenge/challenge.actions';
import {MESSAGE_TYPES} from '../../assets/lib/MessageTypes';
import {TournamentMessage} from './tournament/tournament-message.component';
import {FollowerMessage} from './follower/follower-message.component';
import {LeaderboardMessage} from './leaderboard/leaderboard-message.component';
import {isFreeTierV2FeatureFlagEnabled} from "../../assets/lib/utils";
import {setSelectedListIndex, setSelectedTileId} from "../../app.actions";

const PAGE_ID = 'messages_1';

class InboxComponent extends Component {
	constructor() {
		super();
		this.onDataFetched=this.onDataFetched.bind(this);
		this.onItemSelect=this.onItemSelect.bind(this);
		this.onItemClick=this.onItemClick.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(FetchMessagesAction(this.onDataFetched, false));
		this.props.dispatch(SetTutorialId(PAGE_ID));
		this.props.dispatch(ReadMessagesAction());
	}

	componentDidUpdate(prevProps) {
		if(this.props.messagesUpdateId !== prevProps.messagesUpdateId){
			this.onDataFetched();
		}
	}

	onDataFetched() {
		inputManager.refreshBoundingRects("messages");
	}

	componentDidMount(){
		inputManager.setBackFunction(()=>{navigateToLocation(ROUTES.HOMEPAGE)});
		
		// do not reset previous selected message if user came from /mp-challenge-status page 
		if (this.props.selectedMessage.messageId
			&& this.props.previousRoute
			&& this.props.previousRoute.path === ROUTES.MPC_STATUS.path) {
			this.props.dispatch(setSelectedTileId(this.props.selectedMessage.messageId));
			this.props.dispatch(setSelectedListIndex('messages'));
		} else {
			this.props.dispatch(SetSelectedMessageId('-1'));
		}

		if (!inputManager.currentChild) {
			inputManager.setCurrentChildById('message', 'navBar');
		}
	}

	componentWillUnmount() {
		// this.props.dispatch(SetSelectedMessageId('-1')); // we will reset it in componentDidMount if needed
		inputManager.setBackFunction(null);
	}

	onItemSelect = (messageId) => {
		this.props.dispatch(SetSelectedMessageId(messageId));
	};

	onItemClick = (messageId) => {
		if(this.props.selectedMessage && this.props.selectedMessage.messageId===messageId) {
			const type = this.props.selectedMessage['@class'];
			if (type && (
				type ==='.ChallengeIssuedMessage' ||
				type ==='.ChallengeDrawnMessage' ||
				type ==='.ChallengeWonMessage' ||
				type ==='.ChallengeLostMessage'
			)){
				this.props.dispatch({ type: SET_SELECTED_CHALLENGE_HISTORY, historyItem:this.props.selectedMessage.challengeMessageData });
				navigateToLocation(ROUTES.MPC_STATUS);
			} else if (
				this.props.selectedMessage.type === MESSAGE_TYPES.TOURNAMENT_ENDED ||
				this.props.selectedMessage.type === MESSAGE_TYPES.TOURNAMENT_STARTED ||
				this.props.selectedMessage.type === MESSAGE_TYPES.TOURNAMENT_ONE_DAY
			){
				navigateToLocation(ROUTES.TOURNAMENTS_INFO, {id:this.props.selectedMessage.result.tournamentObject._id});
			} else if (
				this.props.selectedMessage.type === MESSAGE_TYPES.NEW_FOLLOWER
			){
				navigateToLocation(ROUTES.USER_PROFILE, {id:this.props.selectedMessage.follower._id});
			} else if (
				this.props.selectedMessage.type === MESSAGE_TYPES.PODIUM_UPDATE ||
				this.props.selectedMessage.type === MESSAGE_TYPES.SOCIAL_UPDATE
			) {
				const { details } = this.props.selectedMessage;

				if ((details.podiumUpdateType === "CHALLENGE") ||
					(details.socialUpdateType === "CHALLENGE")) {
					navigateToLocation(ROUTES.GAME_CHALLENGE_LEADERBOARD, {
						id: details.contentObject.gameId,
						challengeId: details.contentObject._id,
						focusElement: "playButton"
					});
				}
				else if ((details.podiumUpdateType === "TOURNAMENT") ||
					(details.socialUpdateType === "TOURNAMENT")) {
					navigateToLocation(ROUTES.TOURNAMENTS_LEADERBOARD, {
						id: details.contentObject._id,
						focusElement: "playButton"
					});
				}
				else {
					navigateToLocation(ROUTES.GAME_LEADERBOARD, {
						id: details.contentObject._id,
						focusElement: "playButton"
					});
				}
			}
		} else {
			this.onItemSelect(messageId);
		}
	};

	renderMessage() {
		const { selectedMessage, isFreeTierUserV2 } = this.props;

		const type = get(selectedMessage, '@class', '');
		// const summary = get(selectedMessage, 'summary', '');
		switch(type) {
		case '.ChallengeIssuedMessage':
			return <MessageMPCIssued item={selectedMessage} />;
		case '.ChallengeDrawnMessage':
		case '.ChallengeWonMessage':
		case '.ChallengeLostMessage':
			return <MessageMPCResult item={selectedMessage} isFreeTierUserV2={isFreeTierUserV2} />;
		case '.ScriptMessage':
			if(
				selectedMessage.type===MESSAGE_TYPES.TOURNAMENT_ENDED ||
				selectedMessage.type===MESSAGE_TYPES.TOURNAMENT_STARTED ||
				selectedMessage.type===MESSAGE_TYPES.TOURNAMENT_ONE_DAY
			){
				return <TournamentMessage item={selectedMessage} />;
			}
			else if (selectedMessage.type === MESSAGE_TYPES.NEW_FOLLOWER) {
				return <FollowerMessage item={selectedMessage} />;
			}else if (selectedMessage.type === MESSAGE_TYPES.PODIUM_UPDATE || 
				      selectedMessage.type === MESSAGE_TYPES.SOCIAL_UPDATE) {
				return <LeaderboardMessage item={selectedMessage}/>;
			}else if(
				selectedMessage.type===MESSAGE_TYPES.GIANT_SLAYER_TAUNT ||
				selectedMessage.type===MESSAGE_TYPES.GIANT_SLAYER_RECRUIT ||
				selectedMessage.type===MESSAGE_TYPES.GIANT_SLAYER_ABANDONED ||
				selectedMessage.type===MESSAGE_TYPES.GIANT_SLAYER_ENDED
			){
				// TO DO
				return null;
			}
			break;
		default:
			return null;
		}
	}

	renderItems = ({item}) => {
		return <MessageListItem item={item} />;
	};

	render() {
		return (
			<section className="inbox-component">
				<h1>Messages</h1>
				{!this.props.messages.length ? <p>Your inbox is empty.</p> : ''}
				<div className="inbox-container">
					<VerticalList
						title="messages"
						ref="list"
						idAttribute="messageId"
						className="message-list"
						items={this.props.messages}
						renderer={this.renderItems}
						onChange={this.onItemSelect}
						onTileClick={this.onItemClick}
					/>
					<div className="inbox-message">
						<Loader loading={this.props.isLoading} />
						{this.renderMessage()}
					</div>
				</div>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	const messages = getMessages(state);
	const selectedMessage = getSelectedMessage(state);
	const messagesUpdateId = getMessagesUpdateId(state);

	const isFreeTierUserV2 = isFreeTierV2FeatureFlagEnabled(state)
		&& getIsPostAuthFetched(state)
		&& !getUserIsSubscribed(state);

	return {
		isFreeTierUserV2: isFreeTierUserV2,
		messages,
		selectedMessage,
		messagesUpdateId,
		previousRoute: getPreviousRoute(state),
		hasSelectedMessage: get(selectedMessage, '@class', false),
		isLoading: getIsLoading(state),
		loggedUser: getLoggedUser(state),
	};
};

export const Inbox = connect(mapStateToProps)(InboxComponent);
