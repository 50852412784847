/* eslint-disable no-console */
import React  from 'react';
import deviceInfo, {isDeviceIphone} from './deviceInfo';
import GenericPopup from '../../components/popup/generic-popup/generic-popup.component';
import {addPopup} from '../../components/popup/popup.component';
import {navigateToLocation, ROUTES} from '../../app.router';
import { store } from '../../configureStore';
import {SetGameQuit} from '../../components/game-details/game-details.actions';
import {getRunningSessionId} from '../../app.selectors';
import {SetInternetConnectionStatusAction, SetUserOnlineAction} from '../../app.actions';
import Elastic, {LOG_LEVEL} from './elastic.lib';

class WebsocketAPI {
	init (webSocketUrl, options) {
		this.options = options;
		this.pendingRequests = {};
		this.requestCounter = 0;
		options.url = webSocketUrl + options.key;
		this.connect(options.url);
		this.reconnectTimer = this.reconnectionTimerHandler()

		// if offline - show reconnection pop-up and start connection retry
		window.addEventListener('offline', () => {
			store.dispatch(SetUserOnlineAction(false));
			this.onWebSocketError();
		});
	}

	// Timeout to handle the user losing connection and trying to auto reconnect
	// Cancel the interval when the websocket is opened again
	// Try and reconnect when it is closed
	reconnectionTimerHandler(){
		const reconnectConnection = this.reconnectConnection.bind(this)
		return {
			startReconnection: function () {
				this.timeoutID = setInterval(reconnectConnection, 3000); // Future Improvement is to make this exponentional with a counter
			},
			isReconnecting: function() {
				return typeof this.timeoutID === 'number'
			},
			cancel: function() {
				window.clearInterval(this.timeoutID);
				this.timeoutID = undefined; // remove the id so we can know when multiple different sucessful reconnections and disconects happen
			}
		};
	}

	close () {
		if (this.webSocket != null) {
			if (this.keepAliveTimerId) {
				clearInterval(this.keepAliveTimerId);
			}
			this.webSocket.onopen = null;
			this.webSocket.onclose = null;
			this.webSocket.onerror = null;
			this.webSocket.onmessage = null;
			this.webSocket.close();
			this.webSocket = null;
		}
	}

	connect (socketUrl) {
		try {
			this.webSocket = new WebSocket(socketUrl);
			this.webSocket.onopen = this.onWebSocketOpen.bind(this);
			this.webSocket.onclose = this.onWebSocketClose.bind(this);
			this.webSocket.onerror = this.onWebSocketError.bind(this);
			this.webSocket.onmessage = this.onWebSocketMessage.bind(this);
		} catch(e) {
			Elastic.logDirect(LOG_LEVEL.ERROR,'Error on new websocket connect: ' + e.message);
		}
	}

	reconnect (url) {
		this.close();
		this.connect(url);
	}

	onWebSocketOpen (event) {
		this.reconnectTimer && this.reconnectTimer.isReconnecting() && this.reconnectTimer.cancel(); // clear any existing reconnection attempts

		store.dispatch(SetUserOnlineAction(true));

		this.startKeepAliveTimer();
	}

	onWebSocketClose(/*event*/) {
		store.dispatch(SetUserOnlineAction(false));

		if (this.webSocket.readyState === 3) {
			if (this.reconnectTimer && !this.reconnectTimer.isReconnecting()) { // can fire multiple times so don't let it if already reconnecting
				this.reconnectTimer.startReconnection(); // Start trying to reconnect

				// AWS connection duration for WebSocket API is limited to 2 hours
				// it means that the connection is closed each 2 hours
				// no need to show reconnect popup if new connection is opened
				setTimeout(() => {
					if (this.isWebsocketOpen()) return;
					if(!deviceInfo.isDeviceIphone()) this.showReconnectPopup();  // don't show popup on IOS
				}, 3000);
			}
		}
	}

	onWebSocketError (/*event*/) {
		if (this.reconnectTimer && !this.reconnectTimer.isReconnecting()) { // can fire multiple times so don't let it if already reconnecting
			this.reconnectTimer.startReconnection(); // Start trying to reconnect
			if(!deviceInfo.isDeviceIphone()) this.showReconnectPopup();  // don't show popup on IOS
		}
	}

	startKeepAliveTimer = () => {
		if(this.keepAliveTimerId)clearInterval(this.keepAliveTimerId);
		this.keepAliveTimerId = setInterval(()=>{
			if(this.webSocket.readyState  !== 0) {
				this.webSocket.send(JSON.stringify({action: 'message'}));
			}
		},
		(1000 * 60 * 9) // 9 minutes - because AWS ws timeout is 10 minutes
		);
	};

	onWebSocketMessage(message) {
		let result;
		try {
			result = JSON.parse(message.data);
		} catch (e) {
			return;
		}

		if (this.options && this.options.onMessage) {
			this.options.onMessage(result);
		}

		if (result && result?.message === 'Internal server error') {
			this.onWebSocketError();
			return;
		}

		const resultType = result['@class'];
		if (resultType && resultType.match(/Response$/)) {
			if (result.requestId) {
				const requestId = result.requestId;
				if (this.pendingRequests[requestId]) {
					this.pendingRequests[requestId](result);
					delete this.pendingRequests[requestId];
				}
			}
		}
	}

	reconnectConnection() {
		if (this.options.url) {
			try {
				this.reconnect(this.options.url);
			}
			catch (e) {
				navigateToLocation(ROUTES.HOMEPAGE);
			}
		}
		else {
			navigateToLocation(ROUTES.HOMEPAGE);
		}
	}

	// Websocket is either opening or open
	isWebsocketOpen () {
		return this.webSocket.readyState === 0 || this.webSocket.readyState === 1
	}

	showReconnectPopup () {
		if(this.isPopupActive) return;
		this.isPopupActive = true;
		const isWaysunDevice = deviceInfo.isWaysunDevice();
		const popupContent = !isWaysunDevice ? {
			okButtonLabel: "Got it!",
			title: "There was a problem with your connection",
			message: "Returning to the Homepage",
		} : {
			okButtonLabel: "Reload",
			title: "Connection error",
			message: "There was a problem with your connection. Returning to the Homepage",
		}
		addPopup(<GenericPopup
			{...popupContent}
			onOkClicked={()=>{
				const currentPath = store.getState().routing.currentRoute.path;
				if(currentPath === ROUTES.IN_GAME_MENU.path || currentPath.includes(ROUTES.HOW_TO_PLAY.path)){
					const sessionId = getRunningSessionId(store.getState());
					store.dispatch(SetGameQuit(sessionId));
				}
				if(isWaysunDevice) { window.location.reload(); } // if we lose connection restart the webapp to get the proxy to reinitalise all the needed requets to populate websockets information
				navigateToLocation(ROUTES.HOMEPAGE);
				this.isPopupActive = false;

				if (deviceInfo.isRunningOnNativeClientV2()) {
					store.dispatch(SetUserOnlineAction(false));
					store.dispatch(SetInternetConnectionStatusAction(false));
				}
			}}
		/>, {}, {force: true});
	};
}

export default WebsocketAPI;
