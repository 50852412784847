import { combineReducers } from 'redux';
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import Antstream from './assets/lib/Antstream.js';
import { Api as GeneratedAPI } from './assets/lib/generatedApi';
import entitiesReducer from './entities/entities.reducer';
import { loginReducer } from './components/login/login.reducer';
import { settingsReducer } from './components/settings/settings.reducer';
import { searchReducer } from './components/search/search.reducer';
import { homepageReducer } from './components/homepage/homepage.reducer';
import { gameDetailsReducer } from './components/game-details/game-details.reducer';
import { userDetailsReducer } from './components/user-details/user-details.reducer';
import { myProfileReducer } from './components/my-profile/my-profile.reducer';
import {tutorialReducer} from './components/tutorial/tutorial.reducer';
import { challengeReducer } from './components/challenge/challenge.reducer';
import { inboxReducer } from './components/inbox/inbox.reducer';
import { notificationsReducer } from './components/notifications/notifications.reducer';
import {
	SET_WEBVIEW_STATE,
	APP_INIT_SUCCESS_ACTION,
	SAVE_ROUTE,
	SET_GEMS_ACTION,
	SET_EXP_ACTION,
	SET_SELECTED_LIST_INDEX,
	SET_SELECTED_TILE_ID,
	SET_CONFIG,
	ON_WEBVIEW_SIZE_CHANGE,
	DEVICE_INFO_RECEIVED_ACTION,
	SET_AS_TOUCH_DEVICE,
	SET_CURRENT_REGION,
	SET_FREE_TIER_INFO_SHOWN,
	SET_PAYMENT_PAST_DUE_POPUP_SHOWN,
	SET_USER_ONLINE,
	GET_IP_STACK_OBJECT_SUCCESS,
	ALLOW_GEM_POPUP_ACTION,
	SUBSCRIPTIONS_POPUP_COUNT_INCREMENT, SET_INTERNET_CONNECTION_STATUS
} from './app.actions';
import {
	LOGIN_USER_ACTION,
	LOGIN_USER_ACTION_SUCCESS,
	USER_FULLY_REGISTERED_SUCCESS,
	SET_USER_AVATAR_ACTION,
	FETCH_PAYMENT_DATA_ACTION_SUCCESS,
	LOGOUT_USER_ACTION,
	FETCH_POST_AUTHENTICATION_ACTION_SUCCESS,
	FETCH_PAYMENT_PLANS_SUCCESS,
	CHANGE_USER_DETAILS_SUCCESS,
	FETCH_PAYMENT_SOURCES_SUCCESS,
	FETCH_POST_AUTHENTICATION_ACTION,
	FETCH_POST_AUTHENTICATION_ACTION_FAILED,
	FETCH_MICROSOFT_PRODUCTS_ACTION,
	FETCH_MICROSOFT_PRODUCTS_ACTION_SUCCESS,
	FETCH_MICROSOFT_PRODUCTS_ACTION_ERROR,
	XBOX_SUBSCRIPTION_ACTION_PENDING,
	XBOX_SUBSCRIPTION_ACTION_FINISHED
} from './components/login/login.actions';
import { SET_DISPLAY_NAME_ACTION_SUCCESS } from './components/settings/settings.actions';
import { FOLLOW_GAME_ACTION_SUCCESS, UNFOLLOW_GAME_ACTION_SUCCESS, RUN_GAME_SUCCESS, UPDATE_FREE_TIER_INFORMATION, UPDATE_FREE_TIER_POPUP_STATUS, GENERATE_REQUEST_ID } from './components/game-details/game-details.actions';
import { matchRoute, ROUTES } from './app.router';
import { freeTierDataformating, paramsToObject } from './app.helpers';
import {FETCH_MESSAGES_COUNT_SUCCESS} from './entities/messages/messages.actions';
import {FETCH_UNLOCK_CHALLENGES_SUCCESS} from './entities/challenges/challenges.actions';
import {FAVOURITED_GAMES_ACTION_SUCCESS} from './entities/games/games.actions';
import {tournamentsReducer} from "./components/tournaments/tournaments.reducer";
import {popupReducer} from "./components/popup/popup.reducer";
import deviceInfo, {OS_TYPE_ANDROID} from "./assets/lib/deviceInfo";
import { dailyChallengesReducer } from './components/daily-challenges/daily-challenges.reducer';

export const antstreamService = new Antstream();
export const antstreamAPIService = new GeneratedAPI();

const appInitState = {
	internetConnected: true,
	loading: false,
	initialised: false,
	webviewState: {visible: true},
	runningSessionId: null,
    latestRequestId: null,
	currentRegionCode: null,
	loggedUser:null,
	paymentData:null,
	paymentPlans:null,
	subscriptionPlan:null,
	messageCount:0,
	unlockedChallenges:[],
	unlockedChallengesType:null,
	webViewDimensions:null,
	favouritedGames:[],
	themeClass: null,
	sessionObject: null,
	freeTierInformation: null,
	isFreeTierPopupShown: null,
	isPaymentPastDuePopupShown: null,
	gemPlayCosts: null,
	allowGemPopup: false,
	isPostAuthenticationRequestPending: false,
	isPostAuthenticationRequestFetched: false,
	isXboxSubscriptionPopupLoading: false,
	microsoftSubscriptionProducts: [],
	ipStackObject: null,
	subscriptionsPopupDisplayCount: 0,
};

const appReducer = (state = appInitState, action) => {
	switch (action.type) {
	case SET_WEBVIEW_STATE:
		return {
			...state,
			webviewState: {...state.webviewState, ...action.payload}
		};
	case ON_WEBVIEW_SIZE_CHANGE:
		return {
			...state,
			webViewDimensions: {
				width:action.payload.x,
				height:action.payload.y
			}
		};
	case APP_INIT_SUCCESS_ACTION:
		return {
			...state,
			loading: false,
			initialised: true
		};
	case LOGIN_USER_ACTION:
		return {
			...state,
			loggedUser: null
		};
	case LOGOUT_USER_ACTION:
		return {
			...state,
			loggedUser: null,
			freeTierInformation: null,
			isFreeTierPopupShown: null,
		};
	case LOGIN_USER_ACTION_SUCCESS:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				...action.payload,
			}
		};
	case USER_FULLY_REGISTERED_SUCCESS:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				...action.payload,
				isNotFullyRegistered:false,
			}
		};
	case CHANGE_USER_DETAILS_SUCCESS:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				userName: action.userName,
			}
		};
	case FETCH_POST_AUTHENTICATION_ACTION: {
		return {
			...state,
			isPostAuthenticationRequestPending: true, 
			isPostAuthenticationRequestFetched: false
		};
	}
	case FETCH_POST_AUTHENTICATION_ACTION_FAILED: {
		return {
			...state,
			isPostAuthenticationRequestPending: false,
			isPostAuthenticationRequestFetched: false
		};
	}
	case FETCH_POST_AUTHENTICATION_ACTION_SUCCESS:
		const { registrationRegionId, registrationCountry, paymentData, subscriptionPlan, freeTierObject, isFreeTierPopupShown, gemPlayCosts, freemiumV2User, freemiumV4User, freemiumV4AccessAllowed } = action.response;
		const newState = {
			...state,
			registrationRegionId,
			registrationCountry,
			freemiumV2User,
			freemiumV4User,
			freemiumV4AccessAllowed,
			subscriptionPlan,
			paymentData,
			gemPlayCosts,
			isPostAuthenticationRequestPending: false,
			isPostAuthenticationRequestFetched: true
		};

		if(!freeTierObject) {
			return newState;
		} else {
			return {
				...newState,
				isFreeTierPopupShown,
				freeTierInformation: freeTierDataformating(freeTierObject),
				isPostAuthenticationRequestPending: false,
				isPostAuthenticationRequestFetched: true
			};
		}	
	case UPDATE_FREE_TIER_INFORMATION:
		return {
			...state,
			freeTierInformation: freeTierDataformating(action.payload),
		};
	case SET_FREE_TIER_INFO_SHOWN:
		return {
			...state,
			isFreeTierPopupShown: true,
			};
	case SET_PAYMENT_PAST_DUE_POPUP_SHOWN:
		return {
			...state,
			isPaymentPastDuePopupShown: true,
			};
	case FETCH_PAYMENT_DATA_ACTION_SUCCESS:
		if (!action.payload) return state;
		// If user free tier and then user subscribes removes free tier information from state
		const newPaymentData = (action.payload.paymentData?action.payload.paymentData:action.payload);
		const newGemPlayCosts = (action.payload.gemPlayCosts?action.payload.gemPlayCosts:null);
		var payload = {
			paymentData: { ...state.paymentData, ...newPaymentData },
			gemPlayCosts: newGemPlayCosts ? newGemPlayCosts : state.gemPlayCosts
		};

		if(newPaymentData && newPaymentData.subscription){
			payload.freeTierInformation= null;
		}
			
		return {
			...state,
			...payload
		};
	case FETCH_PAYMENT_PLANS_SUCCESS:
		return {
			...state,
			paymentPlans: action.payload
		};
	case FETCH_PAYMENT_SOURCES_SUCCESS:
		return { 
			...state,
			paymentSources: action.payload
		};
	case SET_USER_AVATAR_ACTION:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				profile_image_id: action.payload
			}
		};
	case RUN_GAME_SUCCESS:
		return {
			...state,
			runningSessionId: action.sessionId,
			runningServerIP: action.serverIP,
			runningServerPort: action.serverPort,
			webSocketServerPort: action.webrtcPort,
			currentRegionCode: action.region,
			runningSessionObject: action.sessionObject,
			runningGameConfig: action.gameConfig,
			runningChallengeStyle: action.challengeStyle
		};
    case GENERATE_REQUEST_ID:
        return {
			...state,
            latestRequestId: action.payload,
        };

	case FOLLOW_GAME_ACTION_SUCCESS:
		const favouritedGamesToPush = [ ...state.favouritedGames ];
		if (state.favouritedGames.indexOf(action.payload) === -1) {
			favouritedGamesToPush.push(action.payload);
		}
		return {
			...state,
			favouritedGames: favouritedGamesToPush,
		};
	case UNFOLLOW_GAME_ACTION_SUCCESS:
		const favouritedGamesToSplice = [ ...state.favouritedGames ];
		const index = state.favouritedGames.indexOf(action.payload);
		favouritedGamesToSplice.splice(index, 1);
		return {
			...state,
			favouritedGames: favouritedGamesToSplice,
		};
	case SET_DISPLAY_NAME_ACTION_SUCCESS:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				displayName: action.payload.displayName
			}
		};
	case SET_GEMS_ACTION:
		if(!state.loggedUser) return state;
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				currencies: { ...state.loggedUser.currencies, GEMS: action.gems }
			}
		};
	case SET_EXP_ACTION:
		if(!state.loggedUser) return state;
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				currencies: { ...state.loggedUser.currencies, EXP: action.expObject.exp },
				Rank:action.expObject.rank,
				expPercentage:action.expObject.expPercentage,
			}
		};
	case FETCH_MESSAGES_COUNT_SUCCESS:
		return {
			...state,
			messageCount: action.messageCount
		};
	case FETCH_UNLOCK_CHALLENGES_SUCCESS:
		return {
			...state,
			unlockedChallenges: action.unlockedChallenges,
			unlockedChallengesType: action.unlockedChallengesType
		};
	case FAVOURITED_GAMES_ACTION_SUCCESS:
		return {
			...state,
			favouritedGames: action.favouritedGames
		};
	case DEVICE_INFO_RECEIVED_ACTION:
		return {
			...state,
			themeClass: action.deviceInfo.platformType
		};
	case ALLOW_GEM_POPUP_ACTION:
		return {
			...state,
			allowGemPopup: action.value,
		};
	case SET_INTERNET_CONNECTION_STATUS:
		return {
			...state,
			internetConnected: action.payload
		};
	case SET_USER_ONLINE:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				isOnline: action.payload,
			},
		};
	case GET_IP_STACK_OBJECT_SUCCESS:
		return {
			...state,
			ipStackObject: action.payload
		};
	case SUBSCRIPTIONS_POPUP_COUNT_INCREMENT: {
		return {
			...state,
			subscriptionsPopupDisplayCount: state.subscriptionsPopupDisplayCount + 1,
		};
	}
	case FETCH_MICROSOFT_PRODUCTS_ACTION: {
		return {
			...state,
			isXboxSubscriptionPopupLoading: true
		};
	}
	case FETCH_MICROSOFT_PRODUCTS_ACTION_SUCCESS: {
		return {
			...state,
			microsoftSubscriptionProducts: action.payload,
			isXboxSubscriptionPopupLoading: false
		};
	}
	case FETCH_MICROSOFT_PRODUCTS_ACTION_ERROR: {
		return {
			...state,
			isXboxSubscriptionPopupLoading: false
		};
	}
	case XBOX_SUBSCRIPTION_ACTION_FINISHED: {
		return {
			...state,
			isXboxSubscriptionPopupLoading: false
		};
	}
	case XBOX_SUBSCRIPTION_ACTION_PENDING: {
		return {
			...state,
			isXboxSubscriptionPopupLoading: true
		};
	}
	default:
		return state;
	}
};

const routingInitialState = {
	search: {},
	hash: {},
	locationBeforeTransitions: {},
	currentRoute: null,
	sessionData: {},
};

function routerReducer(state = routingInitialState, { type, payload } = {}) {
	if (type === LOCATION_CHANGE) {
		const payloadLocation = payload.location;

		const currentRoute = matchRoute(payloadLocation.pathname);
		const previousRoute = state.currentRoute;
		const search = paramsToObject(payloadLocation.search);
		const hash = paramsToObject(payloadLocation.hash);
		const canGoBack = currentRoute !== ROUTES.HOMEPAGE ||
			(currentRoute === ROUTES.HOMEPAGE && deviceInfo.osType === OS_TYPE_ANDROID);

		return {
			...state,
			locationBeforeTransitions: {...payloadLocation, query: paramsToObject(payloadLocation.search)},
			currentRoute,
			search: {...search},
			hash: {...state.hash, ...hash},
			canGoBack,
			previousRoute,
		};
	}else if (type === SAVE_ROUTE) {
		return {
			...state,
			savedRoute: {...payload}
		};
	}else if (type === LOGOUT_USER_ACTION) {
		return {
			...state,
			search: {},
			hash: {},
		};
	}

	return state;
}


const navigationReducerInitialState = {
	selectedListIndex:null,
	selectedTileId:null,
};

const navigationReducer = (state = navigationReducerInitialState, action) => {
	switch (action.type) {
	case SET_SELECTED_LIST_INDEX:
		return {
			...state,
			selectedListIndex:action.index,
		};
	case SET_SELECTED_TILE_ID:
		return {
			...state,
			selectedTileId:action.tileId,
		};
	default:
		return state;
	}
};

const configInitialState = {
	assetsUrl:'',
	versions:[],
	folderTypes:null,
	isInterimScoreEnabled:false,
	isTouchDevice:false,
	currentRegion:'',
};

const configReducer = (state = configInitialState, action) => {
	switch (action.type) {
		case SET_CONFIG:
			return {
				...state,
				assetsUrl:action.config.assetsUrl,
				folderTypes:action.config.folderTypes,
				versions:action.config.versions,
                remotes:action.config.remotes,
				isInterimScoreEnabled:action.config.isInterimScoreEnabled,
			};
		case SET_CURRENT_REGION:
			return {
				...state,
				currentRegion:action.region,
			};
		case SET_AS_TOUCH_DEVICE:
			return {
				...state,
				isTouchDevice:true,
			};
		default:
			return state;
	}
};


const mainReducer = (history) => combineReducers({
	router: connectRouter(history),
	form: reduxFormReducer,
	app: appReducer,
	routing: routerReducer,
	entities: entitiesReducer,
	login: loginReducer,
	homepage: homepageReducer,
	gameDetails: gameDetailsReducer,
	userDetails: userDetailsReducer,
	settings: settingsReducer,
	myProfile: myProfileReducer,
	challenge: challengeReducer,
	inbox: inboxReducer,
	tutorial: tutorialReducer,
	notifications: notificationsReducer,
	search: searchReducer,
	navigation: navigationReducer,
	config: configReducer,
	tournaments: tournamentsReducer,
	dailyChallenges: dailyChallengesReducer,
	popup: popupReducer
});

export default (history) => (state, action) => {
	if (action.type === LOGOUT_USER_ACTION) {
		state = undefined; // will set all reducers state to initial
	}

	return mainReducer(history)(state, action);
};
