// Packages
import React,{Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { ButtonFacebook } from '../common/button/common-button-with-facebook-icon.component';
import { Loader } from '../common/loader/loader.component';
import * as Popup from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import * as Router from '../../app.router';

// Assets
import * as GameWrapper from '../../assets/lib/game-wrapper'
import * as Facebook from '../../assets/lib/facebook.lib';
import DeviceInfo, { OS_TYPE_ANDROID } from "../../assets/lib/deviceInfo";
import { AUTH_TYPE } from '../../constants';
import { ROUTES } from '../../app.router';

// CSS
import './facebook-login.component.less';

class FacebookLoginComponent extends Component {
    constructor() {
        super();
        this.state = { loadingDeviceCode: false, authorizationInterval: null }
    }

	componentWillUnmount() {
        clearInterval(this.state.authorizationInterval);
	}

    /**
	 * Attempts to log the user in with Facebook OAuth.
	 * If button is disabled, stops.
	 * 
	 * If the user is on Android device, Facebook has deprecated Login through WebViews.
	 * Read: https://developers.facebook.com/docs/facebook-login/android/deprecating-webviews
	 * Alternative temporarily was to use Device Login.
	 * Facebook also enforces a 5 second minimum interval on the device authorization request.
	 */
    login = () => {
		if (this.props.disabled) {
			this.props.onClickDisabled && this.props.onClickDisabled();
			return;
		}

		if (DeviceInfo.osType === OS_TYPE_ANDROID) {
			this.setState({ loadingDeviceCode: true });
			Facebook.loginWithDeviceCode().then(response => {
				this.showFacebookDeviceLoginPopup(response.data.user_code);
	
				const authorizationInterval = setInterval(() => {
					Facebook.requestDeviceAuthorization(response.data.code).then(response => {
						if (response.data && response.data.access_token) {
							clearInterval(this.state.authorizationInterval);

							Router.navigateToLocation(Router.ROUTES.AUTH_ACCESS_TOKEN, {
								auth_type: AUTH_TYPE.FACEBOOK,
								access_token: response.data.access_token,
							});

							Popup.closePopup();
						}
					}).catch(error => {
						// TODO: Error logging?
						this.showFacebookErrorPopup();
						clearInterval(this.state.authorizationInterval);
					});
				}, 5000)
				this.setState({ authorizationInterval });
			}).catch(error => {
				// TODO: Error Logging?
				this.showFacebookErrorPopup();
			})
			return;
		}

		GameWrapper.releaseControllers();
		GameWrapper.setBackOverride();
		Facebook.login(ROUTES.AUTH_ACCESS_TOKEN, false);
	};

	/**
	 * Displays an error popup to try again, and sets the loading to false.
	 */
	showFacebookErrorPopup = () => {
		Popup.addPopup(<GenericPopup
			title="Login with Facebook"
			message="Something went wrong, please try again. If the problem persists, please contact support."
		/>, {}, true);
		this.setState({ loadingDeviceCode: false });
	}

	/**
	 * Displays the device code popup for the user to enter in facebook, and sets the loading to false.
	 * On close, clears the authorization interval.
	 * @param {string} userCode - the decide code, e.g. A1NWZ9
	 */
	showFacebookDeviceLoginPopup = (userCode) => {
		Popup.addPopup(<GenericPopup
			title="Login with Facebook"
			messageSize="medium-text"
			okButtonLabel="Retry"
			onOkClicked={() => clearInterval(this.state.authorizationInterval)}
			message={
				<>
					<p>Please click the code below, or visit facebook.com/device on your smartphone or computer and enter:</p>
					<p>
						<a className="facebook-device-code" onClick={() => GameWrapper.openUrlOnDefaultBrowser(`https://www.facebook.com/device?user_code=${userCode}`)}>
							{userCode}
						</a>
					</p>
				</>
			}
		/>, {}, true);
		this.setState({ loadingDeviceCode: false });
	}

	render() {
        const { loadingDeviceCode } = this.state;

        if (loadingDeviceCode) {
            return (
                <div className="">
					<Loader loading={true}/>
				</div>
            );
        }

		return (
			<ButtonFacebook onClick={this.login} childId="loginWithFacebookButton">
                Continue with Facebook
            </ButtonFacebook>
		);
	}
};

FacebookLoginComponent.propTypes = {
	disabled: PropTypes.bool,
	onClickDisabled:PropTypes.func,
};

export default connect()(FacebookLoginComponent);
