/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import { handleGlobalRequestError } from './utils';

export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
})(ContentType || (ContentType = {}));
export class HttpClient {
    constructor(apiConfig = {}) {
        this.baseUrl = `${window.config.REACT_APP_ANTSTREAM_API_BASE_URL}/${window.config.REACT_APP_ANTSTREAM_API_STAGE}`;
        this.securityData = null;
        this.abortControllers = new Map();
        this.customFetch = (...fetchParams) => fetch(...fetchParams);
        this.baseApiParams = {
            credentials: "same-origin",
            headers: {},
            redirect: "follow",
            referrerPolicy: "no-referrer",
        };
        this.setSecurityData = (data) => {
            this.securityData = data;
        };
        this.contentFormatters = {
            [ContentType.Json]: (input) => input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
            [ContentType.FormData]: (input) => Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(key, property instanceof Blob
                    ? property
                    : typeof property === "object" && property !== null
                        ? JSON.stringify(property)
                        : `${property}`);
                return formData;
            }, new FormData()),
            [ContentType.UrlEncoded]: (input) => this.toQueryString(input),
        };
        this.createAbortSignal = (cancelToken) => {
            if (this.abortControllers.has(cancelToken)) {
                const abortController = this.abortControllers.get(cancelToken);
                if (abortController) {
                    return abortController.signal;
                }
                return void 0;
            }
            const abortController = new AbortController();
            this.abortControllers.set(cancelToken, abortController);
            return abortController.signal;
        };
        this.abortRequest = (cancelToken) => {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                abortController.abort();
                this.abortControllers.delete(cancelToken);
            }
        };
        this.request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
            const secureParams = ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
                {};
            const requestParams = this.mergeRequestParams(params, secureParams);
            const queryString = query && this.toQueryString(query);
            const payloadFormatter = this.contentFormatters[type || ContentType.Json];
            const responseFormat = format || requestParams.format;
            return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
                ...requestParams,
                headers: {
                    ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
                    ...(requestParams.headers || {}),
                },
                signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
                body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
            }).then(async (response) => {
                const r = response;
                r.data = null;
                r.error = null;
                const data = !responseFormat
                    ? r
                    : await response[responseFormat]()
                        .then((data) => {
                        if (r.ok) {
                            r.data = data;
                        }
                        else {
                            r.error = data;
                        }
                        return r;
                    })
                        .catch((e) => {
                        r.error = e;
                        return r;
                    });
                if (cancelToken) {
                    this.abortControllers.delete(cancelToken);
                }
                if (!response.ok)
                    throw data;
                return data;
            }).catch(handleGlobalRequestError);
        };
        Object.assign(this, apiConfig);
    }
    encodeQueryParam(key, value) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
    }
    addQueryParam(query, key) {
        return this.encodeQueryParam(key, query[key]);
    }
    addArrayQueryParam(query, key) {
        const value = query[key];
        return value.map((v) => this.encodeQueryParam(key, v)).join("&");
    }
    toQueryString(rawQuery) {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
        return keys
            .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
            .join("&");
    }
    addQueryParams(rawQuery) {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : "";
    }
    mergeRequestParams(params1, params2) {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }
}
/**
 * @title ant-stream-dev
 * @version 1
 * @baseUrl https://kpebvfhizk.execute-api.eu-west-2.amazonaws.com/dev
 */
export class Api extends HttpClient {
    constructor() {
        super(...arguments);
        this.accountDetails = {
            /**
             * No description
             *
             * @name AccountDetailsList
             * @request GET:/account-details
             * @secure
             */
            accountDetailsList: (params = {}) => this.request({
                path: `/account-details`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsAccountDetails
             * @request OPTIONS:/account-details
             */
            optionsAccountDetails: (params = {}) => this.request({
                path: `/account-details`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.achievements = {
            /**
             * No description
             *
             * @name GetAchievements
             * @request GET:/achievements/all
             * @secure
             */
            getAchievements: (params = {}) => this.request({
                path: `/achievements/all`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsAchievements
             * @request OPTIONS:/achievements/all
             */
            optionsAchievements: (params = {}) => this.request({
                path: `/achievements/all`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.avatars = {
            /**
             * No description
             *
             * @name AvatarsList
             * @request GET:/avatars
             * @secure
             */
            avatarsList: (params = {}) => this.request({
                path: `/avatars`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsAvatars
             * @request OPTIONS:/avatars
             */
            optionsAvatars: (params = {}) => this.request({
                path: `/avatars`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.awardGift = {
            /**
             * No description
             *
             * @name AwardGiftCreate
             * @request POST:/award-gift
             * @secure
             */
            awardGiftCreate: (params = {}) => this.request({
                path: `/award-gift`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsAwardGift
             * @request OPTIONS:/award-gift
             */
            optionsAwardGift: (params = {}) => this.request({
                path: `/award-gift`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.challenge = {
            /**
             * No description
             *
             * @name ChallengeList
             * @request GET:/challenge
             * @secure
             */
            challengeList: (query, params = {}) => this.request({
                path: `/challenge`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge
             * @request OPTIONS:/challenge
             */
            optionsChallenge: (params = {}) => this.request({
                path: `/challenge`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AcceptCreate
             * @request POST:/challenge/accept
             * @secure
             */
            acceptCreate: (params = {}) => this.request({
                path: `/challenge/accept`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge2
             * @request OPTIONS:/challenge/accept
             * @originalName optionsChallenge
             * @duplicate
             */
            optionsChallenge2: (params = {}) => this.request({
                path: `/challenge/accept`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name GetHistoryList
             * @request GET:/challenge/getHistory
             * @secure
             */
            getHistoryList: (params = {}) => this.request({
                path: `/challenge/getHistory`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name GetHistoryCreate
             * @request POST:/challenge/getHistory
             * @secure
             */
            getHistoryCreate: (params = {}) => this.request({
                path: `/challenge/getHistory`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge3
             * @request OPTIONS:/challenge/getHistory
             * @originalName optionsChallenge
             * @duplicate
             */
            optionsChallenge3: (params = {}) => this.request({
                path: `/challenge/getHistory`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name GetUserHistoryList
             * @request GET:/challenge/getUserHistory
             * @secure
             */
            getUserHistoryList: (params = {}) => this.request({
                path: `/challenge/getUserHistory`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge4
             * @request OPTIONS:/challenge/getUserHistory
             * @originalName optionsChallenge
             * @duplicate
             */
            optionsChallenge4: (params = {}) => this.request({
                path: `/challenge/getUserHistory`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ProcessRandomCreate
             * @request POST:/challenge/process-random
             * @secure
             */
            processRandomCreate: (params = {}) => this.request({
                path: `/challenge/process-random`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge5
             * @request OPTIONS:/challenge/process-random
             * @originalName optionsChallenge
             * @duplicate
             */
            optionsChallenge5: (params = {}) => this.request({
                path: `/challenge/process-random`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ResultList
             * @request GET:/challenge/result
             * @secure
             */
            resultList: (query, params = {}) => this.request({
                path: `/challenge/result`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge6
             * @request OPTIONS:/challenge/result
             * @originalName optionsChallenge
             * @duplicate
             */
            optionsChallenge6: (params = {}) => this.request({
                path: `/challenge/result`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SendCreate
             * @request POST:/challenge/send
             * @secure
             */
            sendCreate: (params = {}) => this.request({
                path: `/challenge/send`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge7
             * @request OPTIONS:/challenge/send
             * @originalName optionsChallenge
             * @duplicate
             */
            optionsChallenge7: (params = {}) => this.request({
                path: `/challenge/send`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SubmitscoreCreate
             * @request POST:/challenge/submitscore
             */
            submitscoreCreate: (params = {}) => this.request({
                path: `/challenge/submitscore`,
                method: "POST",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge8
             * @request OPTIONS:/challenge/submitscore
             * @originalName optionsChallenge
             * @duplicate
             */
            optionsChallenge8: (params = {}) => this.request({
                path: `/challenge/submitscore`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name UnlockCreate
             * @request POST:/challenge/unlock
             * @secure
             */
            unlockCreate: (params = {}) => this.request({
                path: `/challenge/unlock`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenge9
             * @request OPTIONS:/challenge/unlock
             * @originalName optionsChallenge
             * @duplicate
             */
            optionsChallenge9: (params = {}) => this.request({
                path: `/challenge/unlock`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.challenges = {
            /**
             * No description
             *
             * @name ChallengesList
             * @request GET:/challenges
             * @secure
             */
            challengesList: (params = {}) => this.request({
                path: `/challenges`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenges
             * @request OPTIONS:/challenges
             */
            optionsChallenges: (params = {}) => this.request({
                path: `/challenges`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name PostChallenges
             * @request POST:/challenges/add
             * @secure
             */
            postChallenges: (params = {}) => this.request({
                path: `/challenges/add`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenges2
             * @request OPTIONS:/challenges/add
             * @originalName optionsChallenges
             * @duplicate
             */
            optionsChallenges2: (params = {}) => this.request({
                path: `/challenges/add`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ByGameList
             * @request GET:/challenges/by-game
             * @secure
             */
            byGameList: (query, params = {}) => this.request({
                path: `/challenges/by-game`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenges3
             * @request OPTIONS:/challenges/by-game
             * @originalName optionsChallenges
             * @duplicate
             */
            optionsChallenges3: (params = {}) => this.request({
                path: `/challenges/by-game`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name UpdateUpdate
             * @request PUT:/challenges/update
             * @secure
             */
            updateUpdate: (params = {}) => this.request({
                path: `/challenges/update`,
                method: "PUT",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsChallenges4
             * @request OPTIONS:/challenges/update
             * @originalName optionsChallenges
             * @duplicate
             */
            optionsChallenges4: (params = {}) => this.request({
                path: `/challenges/update`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.eos = {
            /**
             * No description
             *
             * @name RedeemEntitlementsCreate
             * @request POST:/eos/redeem-entitlements
             * @secure
             */
            redeemEntitlementsCreate: (params = {}) => this.request({
                path: `/eos/redeem-entitlements`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsEos
             * @request OPTIONS:/eos/redeem-entitlements
             */
            optionsEos: (params = {}) => this.request({
                path: `/eos/redeem-entitlements`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.event = {
            /**
             * No description
             *
             * @name PlayCreate
             * @request POST:/event/play
             * @secure
             */
            playCreate: (params = {}) => this.request({
                path: `/event/play`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsEvent
             * @request OPTIONS:/event/play
             */
            optionsEvent: (params = {}) => this.request({
                path: `/event/play`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.fortumo = {
            /**
             * No description
             *
             * @name ConfigCreate
             * @request POST:/fortumo/config
             * @secure
             */
            configCreate: (params = {}) => this.request({
                path: `/fortumo/config`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsFortumo
             * @request OPTIONS:/fortumo/config
             */
            optionsFortumo: (params = {}) => this.request({
                path: `/fortumo/config`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.game = {
            /**
             * No description
             *
             * @name GameList
             * @request GET:/game
             * @secure
             */
            gameList: (query, params = {}) => this.request({
                path: `/game`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGame
             * @request OPTIONS:/game
             */
            optionsGame: (params = {}) => this.request({
                path: `/game`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AbortCreate
             * @request POST:/game/abort
             * @secure
             */
            abortCreate: (params = {}) => this.request({
                path: `/game/abort`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGame2
             * @request OPTIONS:/game/abort
             * @originalName optionsGame
             * @duplicate
             */
            optionsGame2: (params = {}) => this.request({
                path: `/game/abort`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ExecuteCreate
             * @request POST:/game/execute
             * @secure
             */
            executeCreate: (params = {}) => this.request({
                path: `/game/execute`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGame3
             * @request OPTIONS:/game/execute
             * @originalName optionsGame
             * @duplicate
             */
            optionsGame3: (params = {}) => this.request({
                path: `/game/execute`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SaveStateCreate
             * @request POST:/game/save-state
             */
            saveStateCreate: (params = {}) => this.request({
                path: `/game/save-state`,
                method: "POST",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGame4
             * @request OPTIONS:/game/save-state
             * @originalName optionsGame
             * @duplicate
             */
            optionsGame4: (params = {}) => this.request({
                path: `/game/save-state`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.games = {
            /**
             * No description
             *
             * @name GamesList
             * @request GET:/games
             * @secure
             */
            gamesList: (params = {}) => this.request({
                path: `/games`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames
             * @request OPTIONS:/games
             */
            optionsGames: (params = {}) => this.request({
                path: `/games`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name PostGames
             * @request POST:/games/add
             * @secure
             */
            postGames: (AddGameRequestBody, params = {}) => this.request({
                path: `/games/add`,
                method: "POST",
                body: AddGameRequestBody,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames2
             * @request OPTIONS:/games/add
             * @originalName optionsGames
             * @duplicate
             */
            optionsGames2: (params = {}) => this.request({
                path: `/games/add`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name FollowCreate
             * @request POST:/games/follow
             * @secure
             */
            followCreate: (params = {}) => this.request({
                path: `/games/follow`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames3
             * @request OPTIONS:/games/follow
             * @originalName optionsGames
             * @duplicate
             */
            optionsGames3: (params = {}) => this.request({
                path: `/games/follow`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name MatchingPlatformList
             * @request GET:/games/matching-platform
             * @secure
             */
            matchingPlatformList: (query, params = {}) => this.request({
                path: `/games/matching-platform`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames4
             * @request OPTIONS:/games/matching-platform
             * @originalName optionsGames
             * @duplicate
             */
            optionsGames4: (params = {}) => this.request({
                path: `/games/matching-platform`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SearchList
             * @request GET:/games/search
             * @secure
             */
            searchList: (query, params = {}) => this.request({
                path: `/games/search`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SearchCreate
             * @request POST:/games/search
             * @secure
             */
            searchCreate: (params = {}) => this.request({
                path: `/games/search`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames5
             * @request OPTIONS:/games/search
             * @originalName optionsGames
             * @duplicate
             */
            optionsGames5: (params = {}) => this.request({
                path: `/games/search`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SearchRecommendationsList
             * @request GET:/games/search-recommendations
             * @secure
             */
            searchRecommendationsList: (params = {}) => this.request({
                path: `/games/search-recommendations`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames6
             * @request OPTIONS:/games/search-recommendations
             * @originalName optionsGames
             * @duplicate
             */
            optionsGames6: (params = {}) => this.request({
                path: `/games/search-recommendations`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SimilarList
             * @request GET:/games/similar
             * @secure
             */
            similarList: (query, params = {}) => this.request({
                path: `/games/similar`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames7
             * @request OPTIONS:/games/similar
             * @originalName optionsGames
             * @duplicate
             */
            optionsGames7: (params = {}) => this.request({
                path: `/games/similar`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name UnfollowCreate
             * @request POST:/games/unfollow
             * @secure
             */
            unfollowCreate: (params = {}) => this.request({
                path: `/games/unfollow`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames8
             * @request OPTIONS:/games/unfollow
             * @originalName optionsGames
             * @duplicate
             */
            optionsGames8: (params = {}) => this.request({
                path: `/games/unfollow`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name UpdateUpdate
             * @request PUT:/games/update
             * @secure
             */
            updateUpdate: (params = {}) => this.request({
                path: `/games/update`,
                method: "PUT",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGames9
             * @request OPTIONS:/games/update
             * @originalName optionsGames
             * @duplicate
             */
            optionsGames9: (params = {}) => this.request({
                path: `/games/update`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.giantSlayer = {
            /**
             * No description
             *
             * @name OptionsGiantSlayer
             * @request OPTIONS:/giant-slayer
             */
            optionsGiantSlayer: (params = {}) => this.request({
                path: `/giant-slayer`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AbandonCreate
             * @request POST:/giant-slayer/abandon
             * @secure
             */
            abandonCreate: (params = {}) => this.request({
                path: `/giant-slayer/abandon`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGiantSlayer2
             * @request OPTIONS:/giant-slayer/abandon
             * @originalName optionsGiantSlayer
             * @duplicate
             */
            optionsGiantSlayer2: (params = {}) => this.request({
                path: `/giant-slayer/abandon`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name CompleteCreate
             * @request POST:/giant-slayer/complete
             * @secure
             */
            completeCreate: (params = {}) => this.request({
                path: `/giant-slayer/complete`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGiantSlayer3
             * @request OPTIONS:/giant-slayer/complete
             * @originalName optionsGiantSlayer
             * @duplicate
             */
            optionsGiantSlayer3: (params = {}) => this.request({
                path: `/giant-slayer/complete`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name CreateCreate
             * @request POST:/giant-slayer/create
             * @secure
             */
            createCreate: (params = {}) => this.request({
                path: `/giant-slayer/create`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGiantSlayer4
             * @request OPTIONS:/giant-slayer/create
             * @originalName optionsGiantSlayer
             * @duplicate
             */
            optionsGiantSlayer4: (params = {}) => this.request({
                path: `/giant-slayer/create`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name DetailsCreate
             * @request POST:/giant-slayer/details
             * @secure
             */
            detailsCreate: (params = {}) => this.request({
                path: `/giant-slayer/details`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGiantSlayer5
             * @request OPTIONS:/giant-slayer/details
             * @originalName optionsGiantSlayer
             * @duplicate
             */
            optionsGiantSlayer5: (params = {}) => this.request({
                path: `/giant-slayer/details`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name GetAllList
             * @request GET:/giant-slayer/get-all
             * @secure
             */
            getAllList: (params = {}) => this.request({
                path: `/giant-slayer/get-all`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsGiantSlayer6
             * @request OPTIONS:/giant-slayer/get-all
             * @originalName optionsGiantSlayer
             * @duplicate
             */
            optionsGiantSlayer6: (params = {}) => this.request({
                path: `/giant-slayer/get-all`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.homepageGames = {
            /**
             * No description
             *
             * @name HomepageGamesList
             * @request GET:/homepage-games
             * @secure
             */
            homepageGamesList: (params = {}) => this.request({
                path: `/homepage-games`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsHomepageGames
             * @request OPTIONS:/homepage-games
             */
            optionsHomepageGames: (params = {}) => this.request({
                path: `/homepage-games`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.leaderboard = {
            /**
             * No description
             *
             * @name PostLeaderboard
             * @request POST:/leaderboard/get
             * @secure
             */
            postLeaderboard: (params = {}) => this.request({
                path: `/leaderboard/get`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsLeaderboard
             * @request OPTIONS:/leaderboard/get
             */
            optionsLeaderboard: (params = {}) => this.request({
                path: `/leaderboard/get`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name RankCreate
             * @request POST:/leaderboard/rank
             * @secure
             */
            rankCreate: (params = {}) => this.request({
                path: `/leaderboard/rank`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsLeaderboard2
             * @request OPTIONS:/leaderboard/rank
             * @originalName optionsLeaderboard
             * @duplicate
             */
            optionsLeaderboard2: (params = {}) => this.request({
                path: `/leaderboard/rank`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.login = {
            /**
             * No description
             *
             * @name LoginCreate
             * @request POST:/login
             */
            loginCreate: (LoginRequestBody, params = {}) => this.request({
                path: `/login`,
                method: "POST",
                body: LoginRequestBody,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsLogin
             * @request OPTIONS:/login
             */
            optionsLogin: (params = {}) => this.request({
                path: `/login`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.logout = {
            /**
             * No description
             *
             * @name LogoutCreate
             * @request POST:/logout
             * @secure
             */
            logoutCreate: (LogoutRequestBody, params = {}) => this.request({
                path: `/logout`,
                method: "POST",
                body: LogoutRequestBody,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsLogout
             * @request OPTIONS:/logout
             */
            optionsLogout: (params = {}) => this.request({
                path: `/logout`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.messages = {
            /**
             * No description
             *
             * @name AlertReadCreate
             * @request POST:/messages/alert-read
             * @secure
             */
            alertReadCreate: (params = {}) => this.request({
                path: `/messages/alert-read`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsMessages
             * @request OPTIONS:/messages/alert-read
             */
            optionsMessages: (params = {}) => this.request({
                path: `/messages/alert-read`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name InboxList
             * @request GET:/messages/inbox
             * @secure
             */
            inboxList: (params = {}) => this.request({
                path: `/messages/inbox`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsMessages2
             * @request OPTIONS:/messages/inbox
             * @originalName optionsMessages
             * @duplicate
             */
            optionsMessages2: (params = {}) => this.request({
                path: `/messages/inbox`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name NewContentList
             * @request GET:/messages/new-content
             * @secure
             */
            newContentList: (params = {}) => this.request({
                path: `/messages/new-content`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsMessages3
             * @request OPTIONS:/messages/new-content
             * @originalName optionsMessages
             * @duplicate
             */
            optionsMessages3: (params = {}) => this.request({
                path: `/messages/new-content`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ReadCreate
             * @request POST:/messages/read
             * @secure
             */
            readCreate: (params = {}) => this.request({
                path: `/messages/read`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsMessages4
             * @request OPTIONS:/messages/read
             * @originalName optionsMessages
             * @duplicate
             */
            optionsMessages4: (params = {}) => this.request({
                path: `/messages/read`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.notifications = {
            /**
             * No description
             *
             * @name SetPushIdCreate
             * @request POST:/notifications/set-push-id
             * @secure
             */
            setPushIdCreate: (params = {}) => this.request({
                path: `/notifications/set-push-id`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsNotifications
             * @request OPTIONS:/notifications/set-push-id
             */
            optionsNotifications: (params = {}) => this.request({
                path: `/notifications/set-push-id`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.passwordRequest = {
            /**
             * No description
             *
             * @name PasswordRequestCreate
             * @request POST:/password-request
             */
            passwordRequestCreate: (params = {}) => this.request({
                path: `/password-request`,
                method: "POST",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPasswordRequest
             * @request OPTIONS:/password-request
             */
            optionsPasswordRequest: (params = {}) => this.request({
                path: `/password-request`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.payment = {
            /**
             * No description
             *
             * @name CheckStatusCreate
             * @request POST:/payment/check-status
             * @secure
             */
            checkStatusCreate: (params = {}) => this.request({
                path: `/payment/check-status`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPayment
             * @request OPTIONS:/payment/check-status
             */
            optionsPayment: (params = {}) => this.request({
                path: `/payment/check-status`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name DataList
             * @request GET:/payment/data
             * @secure
             */
            dataList: (params = {}) => this.request({
                path: `/payment/data`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPayment2
             * @request OPTIONS:/payment/data
             * @originalName optionsPayment
             * @duplicate
             */
            optionsPayment2: (params = {}) => this.request({
                path: `/payment/data`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name NativeResponseCreate
             * @request POST:/payment/native-response
             * @secure
             */
            nativeResponseCreate: (params = {}) => this.request({
                path: `/payment/native-response`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPayment3
             * @request OPTIONS:/payment/native-response
             * @originalName optionsPayment
             * @duplicate
             */
            optionsPayment3: (params = {}) => this.request({
                path: `/payment/native-response`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name PaypalCheckoutCreate
             * @request POST:/payment/paypal-checkout
             * @secure
             */
            paypalCheckoutCreate: (params = {}) => this.request({
                path: `/payment/paypal-checkout`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPayment4
             * @request OPTIONS:/payment/paypal-checkout
             * @originalName optionsPayment
             * @duplicate
             */
            optionsPayment4: (params = {}) => this.request({
                path: `/payment/paypal-checkout`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name PlansList
             * @request GET:/payment/plans
             * @secure
             */
            plansList: (query, params = {}) => this.request({
                path: `/payment/plans`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPayment5
             * @request OPTIONS:/payment/plans
             * @originalName optionsPayment
             * @duplicate
             */
            optionsPayment5: (params = {}) => this.request({
                path: `/payment/plans`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.player = {
            /**
             * No description
             *
             * @name AvatarUpdate
             * @request PUT:/player/avatar
             * @secure
             */
            avatarUpdate: (PlayerUpdateAvatarRequest, params = {}) => this.request({
                path: `/player/avatar`,
                method: "PUT",
                body: PlayerUpdateAvatarRequest,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer
             * @request OPTIONS:/player/avatar
             */
            optionsPlayer: (params = {}) => this.request({
                path: `/player/avatar`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name FollowCreate
             * @request POST:/player/follow
             * @secure
             */
            followCreate: (params = {}) => this.request({
                path: `/player/follow`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer2
             * @request OPTIONS:/player/follow
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer2: (params = {}) => this.request({
                path: `/player/follow`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name FromExternalUpdate
             * @request PUT:/player/from-external
             * @secure
             */
            fromExternalUpdate: (PlayerUpdateNameRequest, params = {}) => this.request({
                path: `/player/from-external`,
                method: "PUT",
                body: PlayerUpdateNameRequest,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer3
             * @request OPTIONS:/player/from-external
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer3: (params = {}) => this.request({
                path: `/player/from-external`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name LanguageCreate
             * @request POST:/player/language
             * @secure
             */
            languageCreate: (params = {}) => this.request({
                path: `/player/language`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer4
             * @request OPTIONS:/player/language
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer4: (params = {}) => this.request({
                path: `/player/language`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name NameUpdate
             * @request PUT:/player/name
             * @secure
             */
            nameUpdate: (PlayerUpdateNameRequest, params = {}) => this.request({
                path: `/player/name`,
                method: "PUT",
                body: PlayerUpdateNameRequest,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer5
             * @request OPTIONS:/player/name
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer5: (params = {}) => this.request({
                path: `/player/name`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name NudgeCreate
             * @request POST:/player/nudge
             * @secure
             */
            nudgeCreate: (params = {}) => this.request({
                path: `/player/nudge`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer6
             * @request OPTIONS:/player/nudge
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer6: (params = {}) => this.request({
                path: `/player/nudge`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name RegionUpdate
             * @request PUT:/player/region
             * @secure
             */
            regionUpdate: (PlayerUpdateRegionRequest, params = {}) => this.request({
                path: `/player/region`,
                method: "PUT",
                body: PlayerUpdateRegionRequest,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer7
             * @request OPTIONS:/player/region
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer7: (params = {}) => this.request({
                path: `/player/region`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SearchCreate
             * @request POST:/player/search
             * @secure
             */
            searchCreate: (params = {}) => this.request({
                path: `/player/search`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer8
             * @request OPTIONS:/player/search
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer8: (params = {}) => this.request({
                path: `/player/search`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name UnfollowCreate
             * @request POST:/player/unfollow
             * @secure
             */
            unfollowCreate: (params = {}) => this.request({
                path: `/player/unfollow`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer9
             * @request OPTIONS:/player/unfollow
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer9: (params = {}) => this.request({
                path: `/player/unfollow`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name DebugSettingsCreate
             * @request POST:/player/debug-settings
             * @secure
             */
            debugSettingsCreate: (params = {}) => this.request({
                path: `/player/debug-settings`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPlayer10
             * @request OPTIONS:/player/debug-settings
             * @originalName optionsPlayer
             * @duplicate
             */
            optionsPlayer10: (params = {}) => this.request({
                path: `/player/debug-settings`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.postLogin = {
            /**
             * No description
             *
             * @name PostLoginCreate
             * @request POST:/post-login
             * @secure
             */
            postLoginCreate: (params = {}) => this.request({
                path: `/post-login`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPostLogin
             * @request OPTIONS:/post-login
             */
            optionsPostLogin: (params = {}) => this.request({
                path: `/post-login`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.profileDetails = {
            /**
             * No description
             *
             * @name ProfileDetailsList
             * @request GET:/profile-details
             * @secure
             */
            profileDetailsList: (query, params = {}) => this.request({
                path: `/profile-details`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsProfileDetails
             * @request OPTIONS:/profile-details
             */
            optionsProfileDetails: (params = {}) => this.request({
                path: `/profile-details`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.promoCode = {
            /**
             * No description
             *
             * @name PostPromoCode
             * @request POST:/promo-code/use
             * @secure
             */
            postPromoCode: (params = {}) => this.request({
                path: `/promo-code/use`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsPromoCode
             * @request OPTIONS:/promo-code/use
             */
            optionsPromoCode: (params = {}) => this.request({
                path: `/promo-code/use`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.queryCollection = {
            /**
             * No description
             *
             * @name QueryCollectionCreate
             * @request POST:/query-collection
             * @secure
             */
            queryCollectionCreate: (params = {}) => this.request({
                path: `/query-collection`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsQueryCollection
             * @request OPTIONS:/query-collection
             */
            optionsQueryCollection: (params = {}) => this.request({
                path: `/query-collection`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.regions = {
            /**
             * No description
             *
             * @name RegionsList
             * @request GET:/regions
             * @secure
             */
            regionsList: (params = {}) => this.request({
                path: `/regions`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsRegions
             * @request OPTIONS:/regions
             */
            optionsRegions: (params = {}) => this.request({
                path: `/regions`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.register = {
            /**
             * No description
             *
             * @name RegisterCreate
             * @request POST:/register
             */
            registerCreate: (RegisterRequestBody, params = {}) => this.request({
                path: `/register`,
                method: "POST",
                body: RegisterRequestBody,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsRegister
             * @request OPTIONS:/register
             */
            optionsRegister: (params = {}) => this.request({
                path: `/register`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.reloadGameData = {
            /**
             * No description
             *
             * @name ReloadGameDataCreate
             * @request POST:/reload-game-data
             * @secure
             */
            reloadGameDataCreate: (params = {}) => this.request({
                path: `/reload-game-data`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsReloadGameData
             * @request OPTIONS:/reload-game-data
             */
            optionsReloadGameData: (params = {}) => this.request({
                path: `/reload-game-data`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.resetPassword = {
            /**
             * No description
             *
             * @name ResetPasswordCreate
             * @request POST:/reset-password
             */
            resetPasswordCreate: (ResetPasswordBody, params = {}) => this.request({
                path: `/reset-password`,
                method: "POST",
                body: ResetPasswordBody,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsResetPassword
             * @request OPTIONS:/reset-password
             */
            optionsResetPassword: (params = {}) => this.request({
                path: `/reset-password`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.stripe = {
            /**
             * No description
             *
             * @name CancelSubscriptionCreate
             * @request POST:/stripe/cancel-subscription
             * @secure
             */
            cancelSubscriptionCreate: (params = {}) => this.request({
                path: `/stripe/cancel-subscription`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsStripe
             * @request OPTIONS:/stripe/cancel-subscription
             */
            optionsStripe: (params = {}) => this.request({
                path: `/stripe/cancel-subscription`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name CreateCardCreate
             * @request POST:/stripe/create-card
             * @secure
             */
            createCardCreate: (params = {}) => this.request({
                path: `/stripe/create-card`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsStripe2
             * @request OPTIONS:/stripe/create-card
             * @originalName optionsStripe
             * @duplicate
             */
            optionsStripe2: (params = {}) => this.request({
                path: `/stripe/create-card`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ReplaceCardCreate
             * @request POST:/stripe/replace-card
             * @secure
             */
            replaceCardCreate: (params = {}) => this.request({
                path: `/stripe/replace-card`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsStripe3
             * @request OPTIONS:/stripe/replace-card
             * @originalName optionsStripe
             * @duplicate
             */
            optionsStripe3: (params = {}) => this.request({
                path: `/stripe/replace-card`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name UpdateCardStatusCreate
             * @request POST:/stripe/update-card-status
             * @secure
             */
            updateCardStatusCreate: (params = {}) => this.request({
                path: `/stripe/update-card-status`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsStripe4
             * @request OPTIONS:/stripe/update-card-status
             * @originalName optionsStripe
             * @duplicate
             */
            optionsStripe4: (params = {}) => this.request({
                path: `/stripe/update-card-status`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name WebhooksCreate
             * @request POST:/stripe/webhooks
             */
            webhooksCreate: (params = {}) => this.request({
                path: `/stripe/webhooks`,
                method: "POST",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsStripe5
             * @request OPTIONS:/stripe/webhooks
             * @originalName optionsStripe
             * @duplicate
             */
            optionsStripe5: (params = {}) => this.request({
                path: `/stripe/webhooks`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.test = {
            /**
             * No description
             *
             * @name TestCreate
             * @request POST:/test
             * @secure
             */
            testCreate: (params = {}) => this.request({
                path: `/test`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTest
             * @request OPTIONS:/test
             */
            optionsTest: (params = {}) => this.request({
                path: `/test`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.tournament = {
            /**
             * No description
             *
             * @name TournamentList
             * @request GET:/tournament
             * @secure
             */
            tournamentList: (query, params = {}) => this.request({
                path: `/tournament`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTournament
             * @request OPTIONS:/tournament
             */
            optionsTournament: (params = {}) => this.request({
                path: `/tournament`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name GetInfoPopupsList
             * @request GET:/tournament/getInfoPopups
             * @secure
             */
            getInfoPopupsList: (params = {}) => this.request({
                path: `/tournament/getInfoPopups`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTournament2
             * @request OPTIONS:/tournament/getInfoPopups
             * @originalName optionsTournament
             * @duplicate
             */
            optionsTournament2: (params = {}) => this.request({
                path: `/tournament/getInfoPopups`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ParticipantsList
             * @request GET:/tournament/participants
             * @secure
             */
            participantsList: (query, params = {}) => this.request({
                path: `/tournament/participants`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ParticipantsCreate
             * @request POST:/tournament/participants
             * @secure
             */
            participantsCreate: (params = {}) => this.request({
                path: `/tournament/participants`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTournament3
             * @request OPTIONS:/tournament/participants
             * @originalName optionsTournament
             * @duplicate
             */
            optionsTournament3: (params = {}) => this.request({
                path: `/tournament/participants`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name PopupSeenCreate
             * @request POST:/tournament/popup-seen
             * @secure
             */
            popupSeenCreate: (params = {}) => this.request({
                path: `/tournament/popup-seen`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTournament4
             * @request OPTIONS:/tournament/popup-seen
             * @originalName optionsTournament
             * @duplicate
             */
            optionsTournament4: (params = {}) => this.request({
                path: `/tournament/popup-seen`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.tournaments = {
            /**
             * No description
             *
             * @name TournamentsList
             * @request GET:/tournaments
             * @secure
             */
            tournamentsList: (params = {}) => this.request({
                path: `/tournaments`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTournaments
             * @request OPTIONS:/tournaments
             */
            optionsTournaments: (params = {}) => this.request({
                path: `/tournaments`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name PostTournaments
             * @request POST:/tournaments/add
             * @secure
             */
            postTournaments: (params = {}) => this.request({
                path: `/tournaments/add`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTournaments2
             * @request OPTIONS:/tournaments/add
             * @originalName optionsTournaments
             * @duplicate
             */
            optionsTournaments2: (params = {}) => this.request({
                path: `/tournaments/add`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ByTypeList
             * @request GET:/tournaments/by-type
             * @secure
             */
            byTypeList: (query, params = {}) => this.request({
                path: `/tournaments/by-type`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTournaments3
             * @request OPTIONS:/tournaments/by-type
             * @originalName optionsTournaments
             * @duplicate
             */
            optionsTournaments3: (params = {}) => this.request({
                path: `/tournaments/by-type`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name UpdateUpdate
             * @request PUT:/tournaments/update
             * @secure
             */
            updateUpdate: (params = {}) => this.request({
                path: `/tournaments/update`,
                method: "PUT",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTournaments4
             * @request OPTIONS:/tournaments/update
             * @originalName optionsTournaments
             * @duplicate
             */
            optionsTournaments4: (params = {}) => this.request({
                path: `/tournaments/update`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.tutorials = {
            /**
             * No description
             *
             * @name PostTutorials
             * @request POST:/tutorials/set
             * @secure
             */
            postTutorials: (params = {}) => this.request({
                path: `/tutorials/set`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsTutorials
             * @request OPTIONS:/tutorials/set
             */
            optionsTutorials: (params = {}) => this.request({
                path: `/tutorials/set`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.uiTooling = {
            /**
             * No description
             *
             * @name UiToolingList
             * @request GET:/ui-tooling
             * @secure
             */
            uiToolingList: (params = {}) => this.request({
                path: `/ui-tooling`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling
             * @request OPTIONS:/ui-tooling
             */
            optionsUiTooling: (params = {}) => this.request({
                path: `/ui-tooling`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AchievementsAddCreate
             * @request POST:/ui-tooling/achievements/add
             * @secure
             */
            achievementsAddCreate: (params = {}) => this.request({
                path: `/ui-tooling/achievements/add`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling2
             * @request OPTIONS:/ui-tooling/achievements/add
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling2: (params = {}) => this.request({
                path: `/ui-tooling/achievements/add`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AchievementsAllList
             * @request GET:/ui-tooling/achievements/all
             * @secure
             */
            achievementsAllList: (params = {}) => this.request({
                path: `/ui-tooling/achievements/all`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling3
             * @request OPTIONS:/ui-tooling/achievements/all
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling3: (params = {}) => this.request({
                path: `/ui-tooling/achievements/all`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AchievementsDeleteCreate
             * @request POST:/ui-tooling/achievements/delete
             * @secure
             */
            achievementsDeleteCreate: (params = {}) => this.request({
                path: `/ui-tooling/achievements/delete`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling4
             * @request OPTIONS:/ui-tooling/achievements/delete
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling4: (params = {}) => this.request({
                path: `/ui-tooling/achievements/delete`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AchievementsUpdateUpdate
             * @request PUT:/ui-tooling/achievements/update
             * @secure
             */
            achievementsUpdateUpdate: (params = {}) => this.request({
                path: `/ui-tooling/achievements/update`,
                method: "PUT",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling5
             * @request OPTIONS:/ui-tooling/achievements/update
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling5: (params = {}) => this.request({
                path: `/ui-tooling/achievements/update`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AlertsAddCreate
             * @request POST:/ui-tooling/alerts/add
             * @secure
             */
            alertsAddCreate: (params = {}) => this.request({
                path: `/ui-tooling/alerts/add`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling6
             * @request OPTIONS:/ui-tooling/alerts/add
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling6: (params = {}) => this.request({
                path: `/ui-tooling/alerts/add`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AlertsAllList
             * @request GET:/ui-tooling/alerts/all
             * @secure
             */
            alertsAllList: (params = {}) => this.request({
                path: `/ui-tooling/alerts/all`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling7
             * @request OPTIONS:/ui-tooling/alerts/all
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling7: (params = {}) => this.request({
                path: `/ui-tooling/alerts/all`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AlertsDeleteCreate
             * @request POST:/ui-tooling/alerts/delete
             * @secure
             */
            alertsDeleteCreate: (params = {}) => this.request({
                path: `/ui-tooling/alerts/delete`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling8
             * @request OPTIONS:/ui-tooling/alerts/delete
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling8: (params = {}) => this.request({
                path: `/ui-tooling/alerts/delete`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name AlertsUpdateUpdate
             * @request PUT:/ui-tooling/alerts/update
             * @secure
             */
            alertsUpdateUpdate: (params = {}) => this.request({
                path: `/ui-tooling/alerts/update`,
                method: "PUT",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling9
             * @request OPTIONS:/ui-tooling/alerts/update
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling9: (params = {}) => this.request({
                path: `/ui-tooling/alerts/update`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name BannersListList
             * @request GET:/ui-tooling/banners/list
             * @secure
             */
            bannersListList: (params = {}) => this.request({
                path: `/ui-tooling/banners/list`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling10
             * @request OPTIONS:/ui-tooling/banners/list
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling10: (params = {}) => this.request({
                path: `/ui-tooling/banners/list`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name BannersRemoveList
             * @request GET:/ui-tooling/banners/remove
             * @secure
             */
            bannersRemoveList: (query, params = {}) => this.request({
                path: `/ui-tooling/banners/remove`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling11
             * @request OPTIONS:/ui-tooling/banners/remove
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling11: (params = {}) => this.request({
                path: `/ui-tooling/banners/remove`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name BannersUpdateCreate
             * @request POST:/ui-tooling/banners/update
             * @secure
             */
            bannersUpdateCreate: (params = {}) => this.request({
                path: `/ui-tooling/banners/update`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling12
             * @request OPTIONS:/ui-tooling/banners/update
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling12: (params = {}) => this.request({
                path: `/ui-tooling/banners/update`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name BannersUpdateOrderCreate
             * @request POST:/ui-tooling/banners/update-order
             * @secure
             */
            bannersUpdateOrderCreate: (params = {}) => this.request({
                path: `/ui-tooling/banners/update-order`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling13
             * @request OPTIONS:/ui-tooling/banners/update-order
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling13: (params = {}) => this.request({
                path: `/ui-tooling/banners/update-order`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name BannersUploadCreate
             * @request POST:/ui-tooling/banners/upload
             * @secure
             */
            bannersUploadCreate: (params = {}) => this.request({
                path: `/ui-tooling/banners/upload`,
                method: "POST",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling14
             * @request OPTIONS:/ui-tooling/banners/upload
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling14: (params = {}) => this.request({
                path: `/ui-tooling/banners/upload`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name TournamentDeleteUpdate
             * @request PUT:/ui-tooling/tournament/delete
             * @secure
             */
            tournamentDeleteUpdate: (params = {}) => this.request({
                path: `/ui-tooling/tournament/delete`,
                method: "PUT",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling15
             * @request OPTIONS:/ui-tooling/tournament/delete
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling15: (params = {}) => this.request({
                path: `/ui-tooling/tournament/delete`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name TournamentUpdateUpdate
             * @request PUT:/ui-tooling/tournament/update
             * @secure
             */
            tournamentUpdateUpdate: (params = {}) => this.request({
                path: `/ui-tooling/tournament/update`,
                method: "PUT",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling16
             * @request OPTIONS:/ui-tooling/tournament/update
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling16: (params = {}) => this.request({
                path: `/ui-tooling/tournament/update`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name TournamentsList
             * @request GET:/ui-tooling/tournaments
             * @secure
             */
            tournamentsList: (params = {}) => this.request({
                path: `/ui-tooling/tournaments`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsUiTooling17
             * @request OPTIONS:/ui-tooling/tournaments
             * @originalName optionsUiTooling
             * @duplicate
             */
            optionsUiTooling17: (params = {}) => this.request({
                path: `/ui-tooling/tournaments`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.v2 = {
            /**
             * No description
             *
             * @name DailyChallengePartyDetail
             * @request GET:/v2/daily-challenge/{dailyChallengeEventId}/party
             */
            dailyChallengePartyDetail: (dailyChallengeEventId, params = {}) => this.request({
                path: `/v2/daily-challenge/${dailyChallengeEventId}/party`,
                method: "GET",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name DailyChallengePartyUpdate
             * @request PUT:/v2/daily-challenge/{dailyChallengeEventId}/party
             */
            dailyChallengePartyUpdate: (dailyChallengeEventId, params = {}) => this.request({
                path: `/v2/daily-challenge/${dailyChallengeEventId}/party`,
                method: "PUT",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsV2
             * @request OPTIONS:/v2/daily-challenge/{dailyChallengeEventId}/party
             */
            optionsV2: (dailyChallengeEventId, params = {}) => this.request({
                path: `/v2/daily-challenge/${dailyChallengeEventId}/party`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name DailyChallengeDetail
             * @request GET:/v2/daily-challenge/{dailyChallengeEventId}
             */
            dailyChallengeDetail: (dailyChallengeEventId, params = {}) => this.request({
                path: `/v2/daily-challenge/${dailyChallengeEventId}`,
                method: "GET",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsV22
             * @request OPTIONS:/v2/daily-challenge/{dailyChallengeEventId}
             * @originalName optionsV2
             * @duplicate
             */
            optionsV22: (dailyChallengeEventId, params = {}) => this.request({
                path: `/v2/daily-challenge/${dailyChallengeEventId}`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name DailyChallengesActivityList
             * @request GET:/v2/daily-challenges/activity
             */
            dailyChallengesActivityList: (params = {}) => this.request({
                path: `/v2/daily-challenges/activity`,
                method: "GET",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsV23
             * @request OPTIONS:/v2/daily-challenges/activity
             * @originalName optionsV2
             * @duplicate
             */
            optionsV23: (params = {}) => this.request({
                path: `/v2/daily-challenges/activity`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name DailyChallengesConfigurationList
             * @request GET:/v2/daily-challenges/configuration
             */
            dailyChallengesConfigurationList: (params = {}) => this.request({
                path: `/v2/daily-challenges/configuration`,
                method: "GET",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsV24
             * @request OPTIONS:/v2/daily-challenges/configuration
             * @originalName optionsV2
             * @duplicate
             */
            optionsV24: (params = {}) => this.request({
                path: `/v2/daily-challenges/configuration`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ChallengeDetail
             * @request GET:/v2/challenge/{id}
             */
            challengeDetail: (id, params = {}) => this.request({
                path: `/v2/challenge/${id}`,
                method: "GET",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsV25
             * @request OPTIONS:/v2/challenge/{id}
             * @originalName optionsV2
             * @duplicate
             */
            optionsV25: (id, params = {}) => this.request({
                path: `/v2/challenge/${id}`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ChallengesList
             * @request GET:/v2/challenges
             */
            challengesList: (query, params = {}) => this.request({
                path: `/v2/challenges`,
                method: "GET",
                query: query,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ChallengesCategoriesList
             * @request GET:/v2/challenges/categories
             * @secure
             */
            challengesCategoriesList: (params = {}) => this.request({
                path: `/v2/challenges/categories`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name ChallengesMostPopularList
             * @request GET:/v2/challenges/most-popular
             * @secure
             */
            challengesMostPopularList: (query, params = {}) => this.request({
                path: `/v2/challenges/most-popular`,
                method: "GET",
                query: query,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name PaymentsMicrosoftProductsList
             * @request GET:/v2/payments/microsoft/products
             * @secure
             */
            paymentsMicrosoftProductsList: (params = {}) => this.request({
                path: `/v2/payments/microsoft/products`,
                method: "GET",
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsV26
             * @request OPTIONS:/v2/payments/microsoft/products
             * @originalName optionsV2
             * @duplicate
             */
            optionsV26: (params = {}) => this.request({
                path: `/v2/payments/microsoft/products`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SettingsControllerMappingsList
             * @request GET:/v2/settings/controller-mappings
             */
            settingsControllerMappingsList: (query, params = {}) => this.request({
                path: `/v2/settings/controller-mappings`,
                method: "GET",
                query: query,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
            /**
             * No description
             *
             * @name SettingsControllerMappingsPartialUpdate
             * @request PATCH:/v2/settings/controller-mappings
             */
            settingsControllerMappingsPartialUpdate: (query, params = {}) => this.request({
                path: `/v2/settings/controller-mappings`,
                method: "PATCH",
                query: query,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
        this.verifyEmail = {
            /**
             * No description
             *
             * @name VerifyEmailList
             * @request GET:/verify-email
             */
            verifyEmailList: (query, params = {}) => this.request({
                path: `/verify-email`,
                method: "GET",
                query: query,
                type: ContentType.Json,
                ...params,
            }),
            /**
             * No description
             *
             * @name OptionsVerifyEmail
             * @request OPTIONS:/verify-email
             */
            optionsVerifyEmail: (params = {}) => this.request({
                path: `/verify-email`,
                method: "OPTIONS",
                type: ContentType.Json,
                format: "json",
                ...params,
            }),
        };
    }
}
