import React, { Component } from 'react';

import './podium.component.less';
import podium from '../../../assets/images/webrtc-game/podium.png';
import rankUp from '../../../assets/images/webrtc-game/rank-going-up.png';
import rankDown from '../../../assets/images/webrtc-game/rank-going-down.png';

class AvatarWithPodium extends Component {
  renderRank = () => {
    if (this.props.isBetterRank) {
      return (
        <img
          alt="better rank"
          className="rank-element"
          src={ rankUp } />
      )
    }

    return (
      <img
        alt="lower rank"
        className="rank-element"
        src={ rankDown } />
    )
  }

  render() {
    const rank = this.props.rank === -1 ? '' : this.props.rank;
    return (
      <div className="in-game-menu-podium">
        <div className="rank-elements">
          <img alt="" className="rank-element podium" src={ podium } />
          <span className="rank-element">
            <span className="rank-position">
              {rank}
            </span>
          </span>

          { this.renderRank() }
        </div>
      </div>
    )
  }
}

export default AvatarWithPodium;
