// Packages
import { makeAutoObservable } from 'mobx';

// Libs
import { CG_DATA_DISPLAY_TYPES } from '../../constants';

class InGameHUDStore {
  scores = [];

  constructor() {
    makeAutoObservable(this);
  }
  
  addScore = (score) => {
    if (!score.index) return;

    const index = score.index - 1;
    let defaultValue = '';
    let isTime = false;

    switch (score.display_type) {
      case CG_DATA_DISPLAY_TYPES.CG_DATA_DISPLAY_TYPE_STRING:
        defaultValue = "";
        break;
      case CG_DATA_DISPLAY_TYPES.CG_DATA_DISPLAY_TYPE_TIME_A:
      case CG_DATA_DISPLAY_TYPES.CG_DATA_DISPLAY_TYPE_TIME_D:
        defaultValue = "00:00";
        isTime = true;
        break;
      case CG_DATA_DISPLAY_TYPES.CG_DATA_DISPLAY_TYPE_INT:
        defaultValue = 0;
        break;
      default:
        defaultValue = '';
    }

    if (this.scores[index]) {
      this.scores[index].title = score?.title;
      this.scores[index].value = defaultValue;
    } else {
      this.scores[index] = {
        title: score?.title,
        value: defaultValue
      }
    }

    if (isTime) {
      this.scores[index].isTime = true;
    }
  };

  updateScore = (score) => {
    if (!score.index) return;

    const index = score.index - 1;
    let value = score.value;

    if (this.scores[index] && this.scores[index].isTime) {
      value = this.convertMsToString(score.value);
    }

    if (this.scores[index]) {
      this.scores[index].value = value;
    } else {
      this.scores[index] = {
        value: value
      }
    }
  }

  resetScores = () => {
    this.scores = [];
  }

  convertMsToString = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    const minutes = (Math.floor(totalSeconds / 60)).toString().padStart(2, '0');
    return `${minutes}:${seconds}`
  }
}

export default new InGameHUDStore();