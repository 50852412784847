import 'rxjs/add/operator/map';
import React, { Component } from 'react';
import SVGAnimation from '../../assets/lib/react-bodymovin.lib';
import rankup from '../../assets/animations/rankup.json';
import AudioManager, {TYPE_LEVEL_UP} from '../../app.audio';
import {isLowSpecDevice} from '../../app.helpers';

import './rankup.component.less';

const DURATION = 7300;
export let updateRankAnimationData = () => {};

const rankupAnimation = {
	loop: false,
	autoplay: true,
	prerender: true,
	animationData: rankup,
	rendererSettings: { className: 'rank-up-animation' }
};

class RankupComponent extends Component {
	constructor() {
		super();
		this.state = {
			rank:null,
			oldRank:null,
		};
		updateRankAnimationData = this.updateRankAnimationData;
	}

	updateRankAnimationData = (oldRank,rank) => {
			AudioManager.playAudio(TYPE_LEVEL_UP);
			clearTimeout(this.rankTimeout);
			this.setState({
				rank,
				oldRank,
			});
			this.rankTimeout = setTimeout(() => {
				this.setState({rank: null});
			}, DURATION);
	};

	render() {
		const {oldRank,rank} = this.state;

		if (!rank) return null;
		return (
			<div className="rank-up animate">
				{!isLowSpecDevice() && <SVGAnimation options={rankupAnimation} /> }
				<span className="number">
					<span className="old-rank">{oldRank}</span>
					<span className="new-rank">{rank}</span>
				</span>
				<div className="rankup-message">
					<h1 className="title">'Rank Up'</h1>
					<h2 className="new-rank-text">{`Rank ${rank}`}</h2>
				</div>
			</div>
		);
	}
}

export default RankupComponent;
