import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { navigateToLocation, ROUTES } from '../../../app.router';

import './notification.component.less';
import Avatar from '../../common/avatar/avatar.component';

class Notification extends Component {
	handleClick = () => {
		if (this.props.item.notificationType === 'message') {
			navigateToLocation(ROUTES.INBOX);
		}
	};

	render() {
		const { item } = this.props;
		const summary = get(item, 'summary', '');
		const title = get(item, 'title', '');
		const className = get(item, 'className', '');
		const userData = get(item, 'userData', '');

		return (
			<div className={`notification challenge-notification ${className ? className : ''} ${userData ? 'with-avatar' : ''}`} onClick={this.handleClick}>
				<h2>{title}</h2>
				{summary}
				{userData && <Avatar userData={userData} /> }
			</div>
		);
	}
}

Notification = connect()(Notification);
export { Notification };
