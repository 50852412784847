import * as ACTIONS from './homepage.actions';

const initialState = {
	scrollTopActive: false,
};

export const homepageReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACTIONS.SCROLL_TOP_ACTION :
		return {
			...state,
			scrollTopActive: action.payload,
		};

	default:
		return state;
	}
};
