import React from 'react';

import { Button } from './common-button.component';
import './common-button-with-gem.component.less';
import gemImage from '../../../assets/images/global/gems.png';

const ButtonWithGem = ({className, children, cost, prefix = '', ...rest}) => {
	if (cost > 0) {
		return (
			<Button
				{...rest}
				className={`${className} with-gem`}
			>
				<div>
					{children}
				</div>
				{cost && 
				<>
					<div className="gem-image-container">
						<img className="gem-image" src={gemImage} alt="gem"/>
					</div>
					<div>
						{prefix}{cost}
					</div>
				</>}
			</Button>
		);
	} else {
		return (
			<Button
				{...rest}
				className={`${className} with-gem`}
			>
				<div>
					{children}
				</div>
			</Button>
		);
	}
};

export { ButtonWithGem };
