import deviceInfo, {PLATFORM_TYPE_ATARI} from "./deviceInfo";
import {updateNativeLocalStorage} from "./game-wrapper";

const KEY_USER_DATA = 'userData';
const KEY_REST_USER_DATA = 'restUserData'; // AWS API Gateway data
const KEY_FACEBOOK_USER_DATA = 'fbUserData';
const KEY_EPIC_GAMES_ACCESS_TOKEN = 'egAccessToken';
const KEY_EPIC_GAMES_REFRESH_TOKEN = 'egRefreshToken';
const KEY_DEEP_LINK_STORAGE = 'deepLinkStorage';
const KEY_PAYMENT_PAST_DUE_POPUP_TIMESTAMP = 'paymentPastDuePopupTimestamp';
const KEY_ERROR_LOGS = 'asErrorLogs'

let nativeLocalStorage = {};

const useNativeLocalStorage = () => {
	/*
	We had issues with CEF's local storage on Atari, where it wouldn't be
	flushed to disk when the app exits and could lose the most recent updates.
	It seems like a CEF bug, since changes were made to make sure it was being
	properly shut down, so we had to come up with a workaround which is having
	a NIH local storage for the Atari.
	*/
	return deviceInfo.platformType === PLATFORM_TYPE_ATARI
		|| deviceInfo.webview === 'ultralight';
}

export const initNativeLocalStorage = (contents) => {
	if (!contents) return;

	const str = atob(contents);
	console.log('initNativeLocalStorage str: ' + str);
	nativeLocalStorage = JSON.parse(str);
}

const read = (key) => { 
	if (useNativeLocalStorage()) {
		return nativeLocalStorage[key];
	} 
	
	if (window.localStorage) {
		const data = localStorage.getItem(key);
		if(data) {
			try {
				return JSON.parse(data);
			} catch (e) {
				console.warn("Could not parse data for key " + key + ", so clearing entry in the local storage");
				remove(key);
			}	   
		}
	}

	return null;
};

const replace = (key, data) => {
	if (useNativeLocalStorage()) {
		nativeLocalStorage[key] = data;
		updateNativeLocalStorage(JSON.stringify(nativeLocalStorage));
		return true;
	} 

	if (window.localStorage) {
		localStorage.setItem(key, JSON.stringify(data));
		return true;
	}

	return false;
};

const combine = (key, data) => {
	if (data === null) {		
		return true;  // Combining something with null gives the original, so fine.
	}

	if (typeof(data) !== "object") {
		console.warn("Passed data (" + JSON.stringify(data) + ") was not an object, and I can only combine object data. Not done.");
		return false;
	}

	const existingData = read(key);
	const joinedData = {...existingData, ...data}
	return replace(key, joinedData);
}

const remove = (key) => {
	if(useNativeLocalStorage()) {
		delete nativeLocalStorage[key];
		updateNativeLocalStorage(JSON.stringify(nativeLocalStorage));
	} else if (window.localStorage) {
		localStorage.removeItem(key);
	}
};

export const clearAll = (id) => {
	console.log('clearAll event: ' + id);
	if (useNativeLocalStorage()) {
		nativeLocalStorage = {};
		updateNativeLocalStorage(JSON.stringify(nativeLocalStorage));
	} else if (window.localStorage) {
		localStorage.clear();
	}
};

export const readUserData = () => {
	const data = read(KEY_USER_DATA);
	if(typeof(data) === "object")  {  // This includes null, which is valid 
		return data;
	}

	console.warn("User data was not an object, so clearing entry in the local storage");
	remove(KEY_USER_DATA);

	return null;
};

export const readRestUserData = () => {
	const data = read(KEY_REST_USER_DATA);
	if(typeof(data) === "object")  {  // This includes null, which is valid
		return data;
	}

	// console.warn("User data was not an object, so clearing entry in the local storage");
	console.warn(`Rest User data was not an object, so clearing "${KEY_REST_USER_DATA}" in the local storage`);
	console.log('Rest User data: ' + data);
	remove(KEY_REST_USER_DATA);

	return null;
};

export const writeUserData = (userData) => {
	if (typeof(userData) !== "object") {
		console.warn("Userdata expected to be an object - not setting");
		return false;
	}
	return combine(KEY_USER_DATA,userData); 
};

export const writeRestUserData = (userData) => {
	if (typeof(userData) !== "object") {
		console.warn("Userdata expected to be an object - not setting");
		return false;
	}
	return combine(KEY_REST_USER_DATA,userData);
};

export const readFacebookUserData = () => {
	const token = read(KEY_FACEBOOK_USER_DATA);
	if (token === null || typeof(token) === 'string') {
		return token;
	} 

	console.warn("FB data was not a string, so clearing entry in the local storage");
	remove(KEY_FACEBOOK_USER_DATA);
	return null;
};

export const readEpicGamesAccessToken = () => {
	const token = read(KEY_EPIC_GAMES_ACCESS_TOKEN);
	if (token === null || typeof(token) === 'string') {
		return token;
	}

	remove(KEY_EPIC_GAMES_ACCESS_TOKEN)
	return null;
};

export const readEpicGamesRefreshToken = () => {
	const token = read(KEY_EPIC_GAMES_REFRESH_TOKEN);
	if (token === null || typeof(token) === 'string') {
		return token;
	}

	remove(KEY_EPIC_GAMES_REFRESH_TOKEN)
	return null;
};

export const writeFacebookUserData = (token) => {
	return token && typeof(token) === 'string' ? replace(KEY_FACEBOOK_USER_DATA, token) : false;
};

export const writeEpicGamesAccessToken = (token) => {
	return token && typeof(token) === 'string' ? replace(KEY_EPIC_GAMES_ACCESS_TOKEN, token) : false;
};

export const writeEpicGamesRefreshToken = (token) => {
	return token && typeof(token) === 'string' ? replace(KEY_EPIC_GAMES_REFRESH_TOKEN, token) : false;
};

export const writeOskData = (data) => {
	return replace('oskData', data);
};

export const readOskData = () => {
	return read('oskData');
};

export const readDeepLinkStorage = () => {
	const deepLink = read(KEY_DEEP_LINK_STORAGE);
	if (deepLink === null) {
		return "";
	} 

	if (typeof(deepLink) !== 'string') {
		console.warn("Deeplink storage was not a string/null, so clearing the entry in the local storage");
		remove(KEY_DEEP_LINK_STORAGE);
		return "";
	} 

	return deepLink;
};

export const writeDeepLinkStorage = (deepLink) => { 
	if(typeof(deepLink) !== 'string') {
		console.warn("writeDeepLinkStorage expects a string, and this isn't one"); 
		return false;
	} 

	replace(KEY_DEEP_LINK_STORAGE, deepLink);
	return true;
};

export const removeDeepLinkStorage = () => {
	 remove(KEY_DEEP_LINK_STORAGE);
};

export const readPaymentPastDuePopupTimestamp = () => {
	const token = read(KEY_PAYMENT_PAST_DUE_POPUP_TIMESTAMP);
	if (token === null || typeof(token) === 'number') {
		return token;
	}

	return null;
};

export const writePaymentPastDuePopupTimestamp = (timestamp) => {
	return timestamp && typeof(timestamp) === 'number' ? replace(KEY_PAYMENT_PAST_DUE_POPUP_TIMESTAMP, timestamp) : false;
};

export const readErrorLogs = () => {
	const data = read(KEY_ERROR_LOGS);
	if(typeof(data) === "object")  {  // This includes null, which is valid 
		return data;
	}

	console.warn("ErrorLogs data was not an object, so clearing entry in the local storage");
	remove(KEY_ERROR_LOGS);
	return null;
};

export const writeErrorLogs = (errorLogs) => {
	if (typeof(errorLogs) !== "object" || !Array.isArray(errorLogs)) {
		console.warn("ErrorLogs expected to be an array  - not setting");
		return false;
	}
	return replace(KEY_ERROR_LOGS, errorLogs); 
};

export const writeMyLocationData = (data) => {
	return replace('myLocationData', data);
};

export const readMyLocationData = () => {
	return read('myLocationData');
};
