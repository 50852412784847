import React from 'react';
import './user-profile-stats.component.less';
import CountAnimation from '../animations/count.animation';
import {PlayerProfileComponent} from '../common/player-profile/player-profile.component';
import {GenreIcon} from '../common/genre-icon/genre-icon-component';
import deviceInfo from "../../assets/lib/deviceInfo";

const UserProfileStats = ({ details, onEditProfile, isFollowUserPending }) => {

	if(!details || !details.currencies) return null;

	return (
		<div className="user-profile-stats">
			<PlayerProfileComponent
				isFollowUserPending={isFollowUserPending}
				userData={details}
				onEditProfile={onEditProfile}
			/>
			<div className="stats-container">
				{details.topGenre && details.topGenre.length>0 &&
				<div className="top-genre">
					<div className="title">Top 3 Genres</div>
					<div className="genres">
						{
							details.topGenre.map(genreObject=>
								<GenreIcon
									key={genreObject.genre}
									genre={genreObject.genre}
									className="icon"
								/>
							)
						}
					</div>
				</div>
				}
				<div className="achievement">
					<div className="title">{deviceInfo.isPlayStationPlatform() ? 'Trophies' : 'Achievements'}</div>
					<div className="number">
						<CountAnimation
							startValue={0}
							endValue={details.achievements || 0}
						/>
						/{25}
					</div>
				</div>
				<div className="challenges-won">
					<div className="title">Duels Won/Lost</div>
					<div className="number">
						<CountAnimation
							startValue={0}
							endValue={details.challengesWon || 0}
						/>
						/{details.challengesLost || 0}
					</div>
				</div>
				<div className="games-played-container">
					<div className="title">Games Played</div>
					<div className="number">
						<CountAnimation
							startValue={0}
							endValue={details.gamesPlayed || 0}
						/>
						/{details.allGamesCount || 0}
					</div>
				</div>
			</div>
		</div>
	);
};

UserProfileStats.defaultProps = {
	onEditProfile: null,
};

export default UserProfileStats;
