import React, {Component} from 'react';
import { throttle } from 'lodash';
import { PulsarComponent } from '../pulsar/pulsar.component';
import './common-button.component.less';
import inputmanager from '../../../assets/lib/inputmanager';
import AudioManager, {TYPE_VERTICAL_LIST_ROW_CHANGE} from '../../../app.audio';

// Components
import FeatureToggle, { FEATURES } from '../feature-toggle/feature-toggle.component';

// Assets
import { STORE_TYPE_EPIC }from '../../../assets/lib/deviceInfo';

export const BUTTON_STYLES = {
	'PRIMARY': 'primary',
	'SECONDARY': 'secondary',
}
class Button extends Component {
	constructor(props) {
		super(props);

		this.refButton = null;
		this.state = {
			selected:false,
			bounceAnimationActive:false
		};
	}

	componentDidUpdate(prevProps) {
		// console.log("prevProps",prevProps,"this.props",this.props);
		if(!this.props.notSelectable !== !prevProps.notSelectable){
			inputmanager.setChildActive(this.props.childId, !this.props.notSelectable);
		}
	}

	onCustomFocus = (focus/*, nextElement*/) => {
		this.setState({selected:focus});
		if(focus && this.refButton) {
			this.refButton.focus();
		}
		if(this.props.onFocus)this.props.onFocus(focus);
	};

	onButtonClicked = (event) => {
		if(this.props.notSelectable)return;
		AudioManager.playAudio(TYPE_VERTICAL_LIST_ROW_CHANGE);
		inputmanager.setCurrentChildById(this.props.childId,this.props.parentId);
		if(this.props.onClick && this.props.withEventObject)this.props.onClick(event);
		if(this.props.stopBounceAnimation && this.props.onClick)this.props.onClick(event);
		if(!this.props.stopBounceAnimation) this.setState({bounceAnimationActive:true});

	};

	onButtonClickedThrottled = throttle(this.onButtonClicked, this.props.throttleMs, {trailing: false});

	onAnimationEnd = () => {
		this.setState({bounceAnimationActive:false});
		if(this.props.onClick && !this.props.withEventObject)this.props.onClick();
	};

	onButtonRef = (element) => {
		this.refButton = element;
		if(this.props.childId){
			inputmanager.onChildRef(element,this.props.childId, this.onCustomFocus, this.props.parentId, this.props.layer);
			inputmanager.setChildActive(this.props.childId, !this.props.notSelectable);
		}
	};

	render() {
		let moreStyles = '';
		moreStyles += this.props.className;
		moreStyles += this.props.disabled || this.props.notSelectable ? ' disabled' : '';

		return (
			<button
				className={`common-button ${moreStyles} ${this.state.selected?'selected':''} ${this.state.bounceAnimationActive?'bounce-animation':''}`}
				type={this.props.type || 'button'}
				disabled={this.props.disabled || this.props.notSelectable}
				onClick={this.props.rawButtonClick
					? this.props.onClick
					: this.props.throttleMs
						? this.onButtonClickedThrottled
						: this.onButtonClicked}
				ref={this.onButtonRef}
				onAnimationEnd={this.onAnimationEnd}
			>
				<FeatureToggle feature={FEATURES.PREMIUM_SAVE_ONLY} platforms={[STORE_TYPE_EPIC]}>
					{this.props.premiumBenefitsText &&
						<div className="premium-benefits">
							<div className="premium-benefits-text">
								{this.props.premiumBenefitsText}
							</div>
						</div>
					}
				</FeatureToggle>
				<div className="content">
					{this.props.children}
				</div>
				<PulsarComponent borderWithRadius borderRound={this.props.borderRound}/>
			</button>
		);
	}
}

export { Button };
