import React, { useEffect, useCallback, useState } from 'react';
import inputmanager from '../../assets/lib/inputmanager';
import './ingame-osk.component.less';

import Keyboard from 'react-simple-keyboard';
import keyNavigation from "simple-keyboard-key-navigation";
import 'react-simple-keyboard/build/css/index.css';
import deviceInfo from "../../assets/lib/deviceInfo";


const keyData = {
    'F1': { code: 'F1', keyCode: 0x80 },
    'F2': { code: 'F2', keyCode: 0x81 },
    'F3': { code: 'F3', keyCode: 0x82 },
    'F4': { code: 'F4', keyCode: 0x83 },
    'F5': { code: 'F5', keyCode: 0x84 },
    'F6': { code: 'F6', keyCode: 0x85 },
    'F7': { code: 'F7', keyCode: 0x86 },
    'F8': { code: 'F8', keyCode: 0x87 },
    'F9': { code: 'F9', keyCode: 0x88 },
    'F10': { code: 'F10', keyCode: 0x89 },
    'F11': { code: 'F11', keyCode: 0x8A },
    'F12': { code: 'F12', keyCode: 0x8B },

    '0': { code: 'Digit0', keyCode: 0x30 },
    '1': { code: 'Digit1', keyCode: 0x31 },
    '2': { code: 'Digit2', keyCode: 0x32 },
    '3': { code: 'Digit3', keyCode: 0x33 },
    '4': { code: 'Digit4', keyCode: 0x34 },
    '5': { code: 'Digit5', keyCode: 0x35 },
    '6': { code: 'Digit6', keyCode: 0x36 },
    '7': { code: 'Digit7', keyCode: 0x37 },
    '8': { code: 'Digit8', keyCode: 0x38 },
    '9': { code: 'Digit9', keyCode: 0x39 },

    'a': { code: 'KeyA', keyCode: 0x41 },
    'b': { code: 'KeyB', keyCode: 0x42 },
    'c': { code: 'KeyC', keyCode: 0x43 },
    'd': { code: 'KeyD', keyCode: 0x44 },
    'e': { code: 'KeyE', keyCode: 0x45 },
    'f': { code: 'KeyF', keyCode: 0x46 },
    'g': { code: 'KeyG', keyCode: 0x47 },
    'h': { code: 'KeyH', keyCode: 0x48 },
    'i': { code: 'KeyI', keyCode: 0x49 },
    'j': { code: 'KeyJ', keyCode: 0x4A },
    'k': { code: 'KeyK', keyCode: 0x4B },
    'l': { code: 'KeyL', keyCode: 0x4C },
    'm': { code: 'KeyM', keyCode: 0x4D },
    'n': { code: 'KeyN', keyCode: 0x4E },
    'o': { code: 'KeyO', keyCode: 0x4F },
    'p': { code: 'KeyP', keyCode: 0x50 },
    'q': { code: 'KeyQ', keyCode: 0x51 },
    'r': { code: 'KeyR', keyCode: 0x52 },
    's': { code: 'KeyS', keyCode: 0x53 },
    't': { code: 'KeyT', keyCode: 0x54 },
    'u': { code: 'KeyU', keyCode: 0x55 },
    'v': { code: 'KeyV', keyCode: 0x56 },
    'w': { code: 'KeyW', keyCode: 0x57 },
    'x': { code: 'KeyX', keyCode: 0x58 },
    'y': { code: 'KeyY', keyCode: 0x59 },
    'z': { code: 'KeyZ', keyCode: 0x5A },
    
    '{enter}': { code: 'Enter', keyCode: 0x0D },
    '{bksp}': { code: 'Backspace', keyCode: 0x08 },
    '{space}': { code: 'Space', keyCode: 0x20 },
}

const defaultKeyboardLayout = [
	'F1 F2 F3 F4 F5 F6 F7 F8 F9 F10 F11 F12',
	'` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
	'{tab} q w e r t y u i o p [ ] \\',
	'{lock} a s d f g h j k l ; \' {enter}',
	'{shift} z x c v b n m , . / {shift}',
	'.com @ {space}'
];

const keyboardDisplay = {
    '{bksp}': '<',
    '{enter}': 'enter',
    '{space}': 'space',
};

export const InGameOSK = ({ onOSKKeyDown, onOSKKeyUp, inputSetupRaw }) => {

    const [keyboardLayout, setKeyboardLayout] = useState({
        'default': defaultKeyboardLayout
    });

    let keyboardRef = null;

    const onKeyboardRef = useCallback(keyboard => {
        keyboardRef = keyboard;
    }, []);

    useEffect(() => {
        setupKeyboardLayout();
        inputmanager.emitEvent = true;
        window.addEventListener('input_manager_click', onInputManagerClick);
        window.addEventListener('gamepad_button_3', onGamepadButton3);
        window.addEventListener('left', onArrowLeft);
        window.addEventListener('right', onArrowRight);
        window.addEventListener('up', onArrowUp);
        window.addEventListener('down', onArrowDown);
        if (deviceInfo.isRunningOnNativeClientV2()) {
            window.addEventListener('keydown', onKeydown);
        }
        return () => {
            inputmanager.emitEvent = false;
            window.removeEventListener('input_manager_click', onInputManagerClick);
            window.removeEventListener('gamepad_button_3', onGamepadButton3);
            window.removeEventListener('left', onArrowLeft);
            window.removeEventListener('right', onArrowRight);
            window.removeEventListener('up', onArrowUp);
            window.removeEventListener('down', onArrowDown);
            if (deviceInfo.isRunningOnNativeClientV2()) {
                window.removeEventListener('keydown', onKeydown);
            }
        };
    }, []);


    const onInputManagerClick = () => {
        keyboardRef.modules.keyNavigation.press();
    }

    const onGamepadButton3 = () => {
        onKeyPress('{bksp}');
    }
    const onKeydown = (e) => {
        switch (e.key) {
            case 'ArrowUp': {
                onArrowUp();
                break;
            }
            case 'ArrowDown': {
                onArrowDown();
                break;
            }
            case 'ArrowLeft': {
                onArrowLeft();
                break;
            }
            case 'ArrowRight': {
                onArrowRight();
                break;
            }
            case 'Enter': {
                keyboardRef.modules.keyNavigation.press();
                break;
            }
            default: {
                break;
            }
        }
    }
    const onArrowLeft = () => {
        keyboardRef.modules.keyNavigation.left();
    }
    const onArrowRight = () => {
        keyboardRef.modules.keyNavigation.right();
    }
    const onArrowUp = () => {
        keyboardRef.modules.keyNavigation.up();
    }
    const onArrowDown = () => {
        keyboardRef.modules.keyNavigation.down();
    }

    const onKeyPress = (button) => {
        const data = keyData[button];
        if (!data) { return; }

        onOSKKeyDown(data.code);
        setTimeout(() => {
            onOSKKeyUp(data.code);
        }, 100);
    }

    const setupKeyboardLayout = () => {
        // make a list of enabled key codes based on vk_enabled data (uint8 * 32)
        const binaryArray = inputSetupRaw.vk_enabled.map((rowValue) => Number(rowValue).toString(2).padStart(8, '0'));
        const keyCodes = [];
        binaryArray.map((rowValue, rowIndex) => {
            const reverted = rowValue.split('').reverse();
            reverted.map((bitValue, bitIndex) => {
                if (bitValue === '1') {
                    keyCodes.push(rowIndex * 8 + bitIndex);
                }
            })
        });

        // filter keyboard layout based on enabled key codes
        let filteredKeyboardLayout = defaultKeyboardLayout.map(keyboardRow => {
            const rowEntries = keyboardRow.split(' ');
            const filteredRowEntries = rowEntries.filter(item => keyData[item] && keyCodes.includes(keyData[item].keyCode));
            return filteredRowEntries.join(' ');
        });

        // remove empty rows
        filteredKeyboardLayout = filteredKeyboardLayout.filter(keyboardRow => keyboardRow.length > 0)

        setKeyboardLayout({...keyboardLayout, 'default': filteredKeyboardLayout});
    }

    return (
        <div className="osk">
            <div className="osk-inner">
                <div className="osk-background">
                    <div className="hg-button" data-skbtn="F1"></div>
                    <div className="hg-button" data-skbtn="F2"></div>
                    <div className="hg-button" data-skbtn="F3"></div>
                    <div className="hg-button" data-skbtn="F4"></div>
                    <div className="hg-button" data-skbtn="F5"></div>
                    <div className="hg-button" data-skbtn="F6"></div>
                    <div className="hg-button" data-skbtn="F7"></div>
                    <div className="hg-button" data-skbtn="F8"></div>
                    <div className="hg-button" data-skbtn="F9"></div>
                    <div className="hg-button" data-skbtn="F10"></div>
                    <div className="hg-button" data-skbtn="F11"></div>
                    <div className="hg-button" data-skbtn="F12"></div>

                    <div className="hg-button" data-skbtn="1"></div>
                    <div className="hg-button" data-skbtn="2"></div>
                    <div className="hg-button" data-skbtn="3"></div>
                    <div className="hg-button" data-skbtn="4"></div>
                    <div className="hg-button" data-skbtn="5"></div>
                    <div className="hg-button" data-skbtn="6"></div>
                    <div className="hg-button" data-skbtn="7"></div>
                    <div className="hg-button" data-skbtn="8"></div>
                    <div className="hg-button" data-skbtn="9"></div>
                    <div className="hg-button" data-skbtn="0"></div>
                    <div className="hg-button" data-skbtn="{bksp}"></div>

                    <div className="hg-button" data-skbtn="q"></div>
                    <div className="hg-button" data-skbtn="w"></div>
                    <div className="hg-button" data-skbtn="e"></div>
                    <div className="hg-button" data-skbtn="r"></div>
                    <div className="hg-button" data-skbtn="t"></div>
                    <div className="hg-button" data-skbtn="y"></div>
                    <div className="hg-button" data-skbtn="u"></div>
                    <div className="hg-button" data-skbtn="i"></div>
                    <div className="hg-button" data-skbtn="o"></div>
                    <div className="hg-button" data-skbtn="p"></div>

                    <div className="hg-button" data-skbtn="a"></div>
                    <div className="hg-button" data-skbtn="s"></div>
                    <div className="hg-button" data-skbtn="d"></div>
                    <div className="hg-button" data-skbtn="f"></div>
                    <div className="hg-button" data-skbtn="g"></div>
                    <div className="hg-button" data-skbtn="h"></div>
                    <div className="hg-button" data-skbtn="j"></div>
                    <div className="hg-button" data-skbtn="k"></div>
                    <div className="hg-button" data-skbtn="l"></div>
                    <div className="hg-button" data-skbtn="{enter}"></div>

                    <div className="hg-button" data-skbtn="z"></div>
                    <div className="hg-button" data-skbtn="x"></div>
                    <div className="hg-button" data-skbtn="c"></div>
                    <div className="hg-button" data-skbtn="v"></div>
                    <div className="hg-button" data-skbtn="b"></div>
                    <div className="hg-button" data-skbtn="n"></div>
                    <div className="hg-button" data-skbtn="m"></div>

                    <div className="hg-button" data-skbtn="{space}"></div>
                </div>
                <Keyboard
                    keyboardRef={onKeyboardRef}
                    onKeyPress={onKeyPress}
                    modules={[keyNavigation]}
                    enableKeyNavigation={true}
                    layout={keyboardLayout}
                    display={keyboardDisplay}
                />
            </div>
        </div>
    )

}
export default InGameOSK;
