import React from 'react';
import gemIcon from '../../../assets/images/global/icon-gem.png';
import { Button } from '../button/common-button.component';
import shopIcon from '../../../assets/images/shop/shop-pink.svg';
import showVideoPlusIcon from '../../../assets/images/generic-icons/show-video-plus.svg';

import './player-profile-gems-component.less';
import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import deviceInfo, { STORE_TYPE_EPIC } from '../../../assets/lib/deviceInfo';
import { startNativePayment } from '../../../assets/lib/game-wrapper';


export const PlayerProfileGems = ({renderGemValueWithCountAnimation, gems, gemAnimationElement, showVideoPlus, handleVideoPlusClick, handleShopButtonClick, onGemIconRef, parentId, navLayer, handleStoreIconClicked, isShowStoreIcon}) => {
    const isClickthroughSupported = featureInfo.isSupported(FEATURES.VIDEO_ADS)

    const isEpicStore = (deviceInfo.storeType === STORE_TYPE_EPIC);

    return (
        <div className={`player-profile-gems-container ${(isEpicStore || isShowStoreIcon) ? 'gems-shop-button' : ''}`}>
            {!isShowStoreIcon && showVideoPlus && isClickthroughSupported && !isEpicStore &&
                <Button
                    aria-label="Show Ads"
                    childId="show-ads-button"
                    parentId={parentId}
                    layer={navLayer}
                    className="gem-video-button no-background"
                    onClick={() => handleVideoPlusClick && handleVideoPlusClick()}
                    borderRound="true"
                >
                    <img className="gem-video-icon" src={showVideoPlusIcon} alt=""/>
                </Button>
            }
            {isShowStoreIcon &&
                <Button
                    aria-label="Store icon"
                    childId="header-shop-button"
                    parentId={parentId}
                    layer={navLayer}
                    className="header-shop-button"
                    onClick={() => {
                        if (handleStoreIconClicked) {
                            handleStoreIconClicked();
                        }
                    }}
                >
                    <img className="shop-icon" src={shopIcon} alt="" />
                </Button>
            }
            {isEpicStore &&
                <Button
                    aria-label="Show Ads"
                    childId="header-shop-button"
                    parentId={parentId}
                    layer={navLayer}
                    className="header-shop-button"
                    onClick={() => handleShopButtonClick && handleShopButtonClick()}
                >
                    <img className="shop-icon" src={shopIcon} alt="" />
                </Button>
            }
            <span className="gem-text">{renderGemValueWithCountAnimation? renderGemValueWithCountAnimation() : gems}</span>
            <div className="gem-animation">
                <img className="gem-icon" alt="gem-icon" src={gemIcon} ref={onGemIconRef}/>
                {gemAnimationElement ? gemAnimationElement() : null}
            </div>
        </div>
    )
}
export default PlayerProfileGems
