// Packages
import React, {Component} from 'react';
import { connect } from 'react-redux';

// Components
import * as Actions from '../login/login.actions';
import { Loader } from '../common/loader/loader.component';
import * as Router from '../../app.router';

// Assets
import * as GameWrapper from '../../assets/lib/game-wrapper';
import * as LocalStorage from '../../assets/lib/local-storage';
import { AUTH_TYPE, PLAY_STATION_WEBRTC_REDIRECT_URI } from '../../constants';
import { ROUTES } from '../../app.router';
import { getIsAppInitialised } from '../../app.selectors';

class AccessTokenComponent extends Component {
	componentDidMount() {
        const { accessToken, authType, isLoggedIn } = this.props;

        GameWrapper.grabControllers();
        GameWrapper.clearBackOverride();

        if (accessToken && this.props.currentRoutePath === ROUTES.PLAYSTATION_AUTH.path) {
            if (!this.props.isInitialised) return; // avoid duplicate requests
            this.props.dispatch(Actions.LoginPlayStationAction(accessToken, PLAY_STATION_WEBRTC_REDIRECT_URI));
            return;
        }

        if (accessToken && this.props.currentRoutePath === ROUTES.XBOX_AUTH_ACCESS_TOKEN.path) {
            this.props.dispatch(Actions.LoginXboxAction(accessToken));
            return;
        }

        if (accessToken && authType === AUTH_TYPE.EPIC_GAMES) {
            this.props.dispatch(Actions.LoginEpicGamesAction(accessToken));
            return;
        }
        
        if (accessToken && authType === AUTH_TYPE.FACEBOOK) {
			this.props.dispatch(Actions.LoginFacebookAction(accessToken));
			LocalStorage.writeFacebookUserData(accessToken);
            return;
        }

        Router.navigateToLocation(Router.ROUTES.HOMEPAGE)
	}

	render () {
		return (
            <div className="loader-games">
                <Loader loading={true}/>
            </div>
        );
	}
}

const mapStateToProps = (state) => {
	return {
        accessToken: state.routing.search.access_token || state.routing.hash.access_token || state.routing.search.code || state.routing.hash.code,
        authType: state.routing.search.auth_type || state.routing.hash.auth_type,
        currentRoutePath: state.routing
            && state.routing.currentRoute
            && state.routing.currentRoute.path,
        isLoggedIn: state.login.logged,
        isInitialised: getIsAppInitialised(state),
	};
};

AccessTokenComponent = connect(mapStateToProps)(AccessTokenComponent);

export default AccessTokenComponent;
