import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getChallengesLeft,
    getFreeTierChallengesInformation,
    getGemPlayChallengeCost,
    getGemPlayChallengeCostV2,
    getGemPlayGiantSlayerRandomCost,
    getIsPostAuthFetched,
    getLoggedUser, getUserIsSubscribed,
    isFreeTier
} from '../../../app.selectors';
import { get } from 'lodash';
import { RunGameAction } from '../../game-details/game-details.actions';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { UpdateMyMenuSelection } from '../../game-details/game-details.actions';
import { getCurrentChallenge, getGiantSlayerChallenges } from '../../challenge/challenge.selectors';
import { CreateGiantSlayerChallengeAction, GetGiantSlayerChallengesAction, GetGiantSlayerCreationDetailsAction } from '../../challenge/challenge.actions';
import { getCurrentGameDetails } from '../../game-details/game-details.selector';
import { Button } from '../../common/button/common-button.component';
import {ButtonWithGem} from '../../common/button/common-button-with-gem.component';
import { closePopup } from '../popup.component';
import './game-session-popup.component.less';
import inputManager from '../../../assets/lib/inputmanager';
import { unlimitedPlaysKey } from '../../../constants';
import { generateUUID } from '../../../app.helpers';
import featureInfo, { FEATURES } from '../../../assets/lib/featureInfo';
import { ChallengeButton } from '../../common/button/challenge-button.component';
import AutoscrollBox from '../../common/autoscroll-box/autoscroll-box.component';
import { isFreeTierV2FeatureFlagEnabled } from '../../../assets/lib/utils';

const navLayer=2;

class GameSessionPopupComponent extends Component {
    constructor() {
        super();
        this.isPlaySoloClicked = false;
        this.giantSlayerChallengeId = null;

        this.state = {
            giantSlayerCreationDetails: null,
            giantSlayerSelected: false,
        };
    }

    componentDidMount() {
        inputManager.setCurrentChildById("GameSessionPopupComponent-playButton");
        inputManager.setBackFunction(()=>{this.onCloseClicked()},navLayer);

        if (featureInfo.isSupported(FEATURES.GIANT_SLAYER)) {
            this.props.dispatch(GetGiantSlayerCreationDetailsAction([this.props.challengeId],
                (challenges) => {
                    this.setState({
                        giantSlayerCreationDetails: challenges.find(challenge => challenge.challengeId === this.props.challengeId)
                    });
                }
            ));
        }
    }

    componentWillUnmount() {
        this.props.dispatch(UpdateMyMenuSelection({title: 'none'}));

        if(this.isPlaySoloClicked) {
            const {challengeId, id} = this.props;
            this.props.dispatch(RunGameAction({
                gameId:id,
                challengeId,
                challengeStyle: 'intro',
            }));
            navigateToLocation(ROUTES.HOW_TO_PLAY, {id: id, challengeId});
        }

        if (featureInfo.isSupported(FEATURES.GIANT_SLAYER) && this.giantSlayerChallengeId) {
            const {challengeId, id} = this.props;
            this.props.dispatch(RunGameAction({
                gameId:id,
                challengeId,
                challengeStyle: 'intro',
                giantSlayerChallengeId: this.giantSlayerChallengeId,
                role: 'giant',                
            }));
            navigateToLocation(ROUTES.HOW_TO_PLAY, {
                id: id,
                challengeId,
                giantSlayerChallengeId: this.giantSlayerChallengeId
            });
        }
        inputManager.setBackFunction(null,navLayer);
    }

    startChallenge = () => {
        const {challengeId} = this.props;
        const id = this.props.game._id;
        navigateToLocation(ROUTES.MPC_OPPONENTS, {id,challengeId});
        closePopup();
    };

    singlePlayer = () => {
        if(this.isPlaySoloClicked)return;
        this.isPlaySoloClicked=true;

        closePopup();
    };

    startGiantSlayer = () => {
        this.setState({giantSlayerSelected: true});
        const requestId = generateUUID();
        const challengeId = this.props.challengeId;
        this.props.dispatch(CreateGiantSlayerChallengeAction(requestId, challengeId, this.onGiantSlayerChallengeCreated, this.onGiantSlayerChallengeCreateFail));
    };

    onGiantSlayerChallengeCreated = (giantSlayerChallengeId) => {
        if (this.giantSlayerChallengeId) {
            this.setState({giantSlayerSelected: false});
            return;
        }

        this.giantSlayerChallengeId = giantSlayerChallengeId;
        this.props.dispatch(GetGiantSlayerChallengesAction());
        closePopup();
        setTimeout(() => {
            this.setState({giantSlayerSelected: false});
        }, 1000);
    }

    onGiantSlayerChallengeCreateFail = () => {
        closePopup();
        setTimeout(() => {
            this.setState({giantSlayerSelected: false});
        }, 1000);
    }

    onCloseClicked = () => {
        closePopup();
    };

    render() {
        const isGiantSlayerPending = this.state.giantSlayerSelected;
        const userGems = get(this.props.user, 'currencies.GEMS', 0);
        const { challenge, isUserFreeTier, challengesLeftValue, gemPlayGameCost, isFreeTierUserV2 } = this.props;
        const playButtonText = 'Solo';
        const freeTierAdditionalText = isUserFreeTier ? `(${challengesLeftValue})` : '';
        const fullPlayButtonText = isUserFreeTier ? `${playButtonText} ${freeTierAdditionalText}` : playButtonText;

        const giantCountNext = this.props.giantSlayerChallengesAsGiant.length + 1;

        let giantCost = this.props.gemPlayGiantSlayerRandomCost;
        let giantDisabled = false;
        let giantLimit = null;
        let giantPrizeText = null;

        if (this.state.giantSlayerCreationDetails) {
            giantCost = this.state.giantSlayerCreationDetails.giantCost;
            giantDisabled = this.state.giantSlayerCreationDetails.isGiantEligible === false || giantCost > userGems;
            giantLimit = this.state.giantSlayerCreationDetails.giantLimit;
            giantPrizeText = `${this.state.giantSlayerCreationDetails.prize}-${this.state.giantSlayerCreationDetails.maxPrize}`;
        }

        const boxStyle = (featureInfo.isSupported(FEATURES.GIANT_SLAYER) ? "game-session-three-button-popup-component" : "game-session-two-button-popup-component");

        if (!challenge) {
            return;
        }

        return (
            <div className={boxStyle}>
                { featureInfo.isSupported(FEATURES.GIANT_SLAYER) &&
                    <div>
                        <div className="title-text">
                            3 ways to jam!
                        </div>

                        <div className="challenge-button-list">
                            <ChallengeButton
                                className="challenge-solo"
                                onClick={this.singlePlayer}
                                title="Solo"
                                cost={gemPlayGameCost}
                                disabled={isGiantSlayerPending || gemPlayGameCost>userGems}
                                childId="GameSessionPopupComponent-playButton"
                                layer={navLayer}
                            />
                            <ChallengeButton
                                className="challenge-duel"
                                onClick={this.startChallenge}
                                title="Duel"
                                cost={challenge.costs ? challenge.costs.playCost : 0}
                                disabled={isGiantSlayerPending || (challenge.costs ? challenge.costs.playCost>userGems : false)}
                                childId="GameSessionPopupComponent-challengeButton"
                                layer={navLayer}
                                isFreeTierUserV2={isFreeTierUserV2}
                                prizeText={(challenge.costs && challenge.costs.playCost <= userGems) ? challenge.costs.playCost * 2 : null} // prize is double the wager
                            />
                            <ChallengeButton
                                className="challenge-giant-slayer"
                                onClick={this.startGiantSlayer}
                                title="Giant Slayer"
                                cost={giantCost}
                                disabled={isGiantSlayerPending || giantDisabled}
                                childId="GameSessionPopupComponent-giantSlayerButton"
                                layer={navLayer}
                                giantCountNext={giantCountNext}
                                giantLimit={giantLimit}
                                isFreeTierUserV2={isFreeTierUserV2}
                                prizeText={(giantDisabled) ? null : giantPrizeText}
                            />
                        </div>
                        <div className="challenge-button-descriptions">
                            <div className="description">
                                    Go it<br />alone
                            </div>
                            <div className="description">
                                    Challenge<br />a friend
                            </div>
                            <div className="description">
                                    Battle the<br />community
                            </div>
                        </div>
                    </div>
                }
                { !featureInfo.isSupported(FEATURES.GIANT_SLAYER) && 
                    <>
                        <h2 className="title">
                            {challenge.title}
                        </h2>
                        <div className="info">
                            <AutoscrollBox>
                                {challenge.text}
                            </AutoscrollBox>
                        </div>
                        <div className="selection-buttons-container">
                            <ButtonWithGem
                                className="primary"
                                onClick={this.singlePlayer}
                                childId="GameSessionPopupComponent-playButton"
                                layer={navLayer}
                                cost={gemPlayGameCost}
                                disabled={gemPlayGameCost > userGems}
                            >
                                {fullPlayButtonText}
                            </ButtonWithGem>
                            <ButtonWithGem
                                className="primary two-line"
                                onClick={this.startChallenge}
                                cost = {challenge.costs ? challenge.costs.playCost : 0}
                                disabled={challenge.costs ? challenge.costs.playCost>userGems : false}
                                childId="GameSessionPopupComponent-challengeButton"
                                layer={navLayer}
                            >
                                    Duel
                                {` ${freeTierAdditionalText}`}
                            </ButtonWithGem>
                        </div>
                    </>
                }
                <div className="bottom-container">
                    <Button
                        className="secondary"
                        onClick={this.onCloseClicked}
                        childId="GameSessionPopupComponent-cancelButton"
                        layer={navLayer}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const isUserFreeTier = isFreeTier(state) && getFreeTierChallengesInformation(state).limit !== unlimitedPlaysKey

    const giantSlayerChallengesAsGiant = getGiantSlayerChallenges(state).filter(item => item.state === 'live' && item.role === 'giant');

    const isFreeTierUserV2 = isFreeTierV2FeatureFlagEnabled(state)
        && getIsPostAuthFetched(state)
        && !getUserIsSubscribed(state);

    return {
        isFreeTierUserV2,
        isUserFreeTier,
        gemPlayGiantSlayerRandomCost: getGemPlayGiantSlayerRandomCost(state),
        challengesLeftValue: isUserFreeTier ? getChallengesLeft(state) : 0,
        user: getLoggedUser(state),
        challenge: getCurrentChallenge(state),
        game: getCurrentGameDetails(state), 
        gemPlayGameCost: isFreeTierUserV2 ? getGemPlayChallengeCostV2(state) : getGemPlayChallengeCost(state),
        giantSlayerChallengesAsGiant
    };
};

export default connect(mapStateToProps)(GameSessionPopupComponent);
