import React, { Component } from 'react';
import inputManager from '../../../assets/lib/inputmanager';
import './hero-carousel.component.less';
import {PulsarComponent} from '../pulsar/pulsar.component';
import InformationBanner from './information-banner/information-banner.component'
import HeroBanner from './hero-banner/hero-banner.component';
import FreeTierBanner from './free-tier-banner/free-tier-banner.component';
import { FEATURED_BANNER_TYPES } from '../../../constants';
import {analyticsEvent} from "../../../assets/lib/antstreamAnalytics";
import deviceInfo from '../../../assets/lib/deviceInfo';


const parentId = 'carouselParent';

class HeroCarousel extends Component {
	constructor(props) {
		super(props);
		this.index = 0;
		this.targetIndex = null;
		this.mouseStartX = 0;
		this.isMouseDown = false;

		this.state = {
			containerLeft:0,
			withAnimation:true,
			selected:false,
			selectedIndex:false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.items && this.props.items.length > 1) {
			setTimeout(()=> {
				if(this.targetIndex) {
					this.index = this.targetIndex;
					this.targetIndex=null;
					this.setContainerPosition(this.index,true);
				}
			}, 50);

			if(this.props.isActive!==prevProps.isActive || this.state.selected!==prevState.selected){
				if(this.props.isActive){
					this.startCarouselTimer();
				}else {
					this.stopCarouselTimer();
				}
			}
		}
	}

	componentDidMount(){
		if(this.props.items && this.props.items.length > 1) { this.startCarouselTimer(); }
		this.props.onMount && this.props.onMount()
	}

	componentWillUnmount(){
		this.onBlur();
		if(this.props.items && this.props.items.length > 1) { this.stopCarouselTimer(); };
	}

	startCarouselTimer = () => {
		this.stopCarouselTimer();
		this.carouselTimer = setTimeout(()=>{
			this.onArrowRight();
		},10000);
	};

	stopCarouselTimer = () => {
		if(this.carouselTimer){
			clearTimeout(this.carouselTimer);
		}
	};

	onCustomFocus = (focus) => {
		if(this.state.selected!==focus) {
			this.setState({selected:focus});
			if(focus) {
				this.onFocus();
			}else {
				this.onBlur();
			}
		}
	};

	onArrowRight = () => {
		if(this.props.items && this.props.items.length > 1) {
			const lengthWithDummy = this.props.items.length+1;
			if(this.index === lengthWithDummy-1) {
				this.targetIndex = 1;
				this.index = 0;
				this.setContainerPosition(this.index,false);
			} else {
				this.index =(this.index+1)%lengthWithDummy;
				this.setContainerPosition(this.index,true);
			}
			this.startCarouselTimer();
		}
	};

	onArrowLeft = () => {
		if(this.props.items && this.props.items.length > 1) {
			const lengthWithDummy = this.props.items.length+1;
			if(this.index === 0) {
				this.targetIndex = lengthWithDummy-2;
				this.index = lengthWithDummy-1;
				this.setContainerPosition(this.index,false);
			} else {
				this.index = (this.index+lengthWithDummy-1)%lengthWithDummy;
				this.setContainerPosition(this.index,true);
			}
			this.startCarouselTimer();
		}
	};

	getBannerWidth = () => {
		if (deviceInfo.isSafeAreaTurnedOn()) {
			return 113;
		}
		return 100;
	}

	setContainerPosition = (index,withAnimation) => {
		this.setState({
			containerLeft:index*- this.getBannerWidth(),
			withAnimation,
			selectedIndex:index
		});
	};

	setSelectedItem = (index) => {
		this.index = index;
		this.setContainerPosition(this.index,true);
		this.startCarouselTimer();
	};

	onFocus = () => {
		if (this.props.items.length) {
			setTimeout(() => {
				inputManager.emitEvent=true;
				window.addEventListener('left', this.onArrowLeft);
				window.addEventListener('right', this.onArrowRight);
				this.props.onFocus && this.props.onFocus();
			});
		}
	};

	onBlur = () => {
		inputManager.emitEvent=false;
		this.props.onBlur && this.props.onBlur();
		window.removeEventListener('left', this.onArrowLeft);
		window.removeEventListener('right', this.onArrowRight);
	};

	onMouseDown = (event) => {
		const clientX = event.changedTouches && event.changedTouches[0] ? event.changedTouches[0].clientX : event.clientX;
		this.isMouseDown = true;
		this.mouseStartX = clientX;
		if(this.props.items && this.props.items.length > 1) { this.startCarouselTimer();}
	};

	onMouseMove = (event) => {
		if(this.isMouseDown){
			const clientX = event.changedTouches && event.changedTouches[0] ? event.changedTouches[0].clientX : event.clientX;
			if(this.clientWidth){
				const positionByViewWidth = (this.index * -100) + (((clientX - this.mouseStartX) / this.clientWidth)  * 100);
				this.setState({
					containerLeft: positionByViewWidth,
					withAnimation: false
				});
			}
		}
	};

	onMouseUp = (event) => {
		const clientX = event.changedTouches && event.changedTouches[0] ? event.changedTouches[0].clientX : event.clientX;
		this.checkCurrentXPosition(clientX);
		this.isMouseDown = false;
	};

	onMouseLeave = (event) => {
		const clientX = event.changedTouches && event.changedTouches[0] ? event.changedTouches[0].clientX : event.clientX;
		this.checkCurrentXPosition(clientX);
		this.isMouseDown = false;
	};

	checkCurrentXPosition = (mouseX) => {
		if(this.isMouseDown && this.clientWidth){
			const diff = mouseX - this.mouseStartX;
			const percentage = Math.abs(diff) / this.clientWidth;

			if(percentage > 0.15) {
				if(diff<0){
					if(this.index<this.props.items.length-1)this.onArrowRight();
					else this.setContainerPosition(this.index,true);
				}else {
					if(this.index>0)this.onArrowLeft();
					else this.setContainerPosition(this.index,true);
				}
			} else {
				this.setContainerPosition(this.index,true);
			}
		}
	};

	onIndicatorClicked = (index) => {
		this.setSelectedItem(index);
	};

	onComponentRefChanged = (element) => {
		inputManager.onParentRef(element,parentId);
	};


	onChildRefChanged = (element) => {
		inputManager.onChildRef(element, "carousel", this.onCustomFocus, parentId);
		if(element) {
			this.clientWidth = element.clientWidth;
		}
	};

	render() {
		if(!this.props.items.length) return null;
		const heroBannerWidth = this.getBannerWidth() + 'vw';
		const selectedStyle = this.state.selected ? 'selected' : '';
		const extendedCarouselItems = [
			...this.props.items, 
			...[this.props.items[0]]
		]
		return (
			<div
				className="hero-carousel-component"
				ref={this.onComponentRefChanged}
			>
				<div
					onMouseDown={this.onMouseDown}
					onMouseMove={this.onMouseMove}
					onMouseUp={this.onMouseUp}
					onMouseLeave={this.onMouseLeave}
					onTouchStart={this.onMouseDown}
					onTouchMove={this.onMouseMove}
					onTouchEnd={this.onMouseUp}
					onTouchCancel={this.onMouseLeave}
					onClick={(event) => {
						const hasX = !this.mouseStartX || !event.clientX || (this.mouseStartX && Math.abs(this.mouseStartX - event.clientX) < 10)
						const selectedItem = this.props.items && this.props.items[this.index % this.props.items.length];

						analyticsEvent({
							category: "Navigation",
							action: selectedItem.type + " banner click",
							label: selectedItem.gameId ? selectedItem.gameId : selectedItem._id,
						});
						this.props.onClick(selectedItem, hasX)
					}}

					ref={this.onChildRefChanged}
					className={`frame ${selectedStyle}`}
				>
					<div
						className={`tile-container ${this.state.withAnimation && 'tile-container--transition'}`}
						style={{transform:`translateX(${this.state.containerLeft}vw)`}}
					>	
						{ extendedCarouselItems && extendedCarouselItems.map((item, index) => {
							const key = `${item.key || item.id || item.title || index}${extendedCarouselItems.length===index+1 ? '-dummy': ''}`
							return (
								<HeroBanner key={key} isClickable={true} width={heroBannerWidth}>
									{item.type === FEATURED_BANNER_TYPES.FREE_TIER && <FreeTierBanner/>}
									{item.type !== FEATURED_BANNER_TYPES.FREE_TIER && 
										<InformationBanner 
											imageSrc={item.featured_banner_image || item.src}
											imageAlt={item.title}
											bannerType={item.type}
											startDate={item.startDate}
											endDate={item.endDate}
										/>
									}
								</HeroBanner>
							)
						})}
						
					</div>
					<PulsarComponent/>
				</div>
				{this.props.items && this.props.items.length > 1 && <div className="indicator-container">
					{
						this.props.items.map((item,index)=>
						<div
							key={`indicator-${index}`}
							className={`indicator-item ${this.state.selectedIndex%this.props.items.length===index?'selected':''}`}
							onClick={(event)=>{
								event.preventDefault();
								this.onIndicatorClicked(index)
							}}
						/>
					)}
				</div>}
			</div>
		);
	}
}

export default HeroCarousel;
