
// Filter console on 'UNIT_TEST' to see results clearly
export const CHECK = (condition, message) => {
	if (!!condition === false) {   
		console.error(" FAIL : UNIT_TEST :  " + message);
	} else {
		console.warn("PASS : UNIT_TEST : " + message);
	}
}

let s_haveRunTests    = false;
let s_registeredTests = new Array();

export const registerUnitTest = (functionToRun) => {
    s_registeredTests.push(functionToRun);
}

export const runUnitTests = () => { 
	if (!s_haveRunTests) {
        console.error("Running " + s_registeredTests.length + " unit tests");
		s_haveRunTests = true;
        s_registeredTests.forEach(element => element());
	}
};

