import React, { Component } from 'react';
import inputManager from '../../../assets/lib/inputmanager';
import './range-slider.component.less';

const parentId = 'range-slider';

class RangeSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false,
            value: (props.value !== undefined) ? props.value : 50,
            step: (props.step !== undefined) ? props.step : 10,
            min: (props.min !== undefined) ? props.min : 0,
            max: (props.max !== undefined) ? props.max : 100,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    onCustomFocus = (focus) => {
        if(this.state.selected!==focus) {
            this.setState({selected:focus});
            if(focus) {
                this.onFocus();
            }else {
                this.onBlur();
            }
        }
    };

    onFocus = () => {
        inputManager.emitEvent=true;
        window.addEventListener('left', this.onArrowLeft);
        window.addEventListener('right', this.onArrowRight);
        this.props.onFocus && this.props.onFocus();
    };
    
    onBlur = () => {
        inputManager.emitEvent=false;
        this.props.onBlur && this.props.onBlur();
        window.removeEventListener('left', this.onArrowLeft);
        window.removeEventListener('right', this.onArrowRight);
    };
    
    onArrowRight = () => {
        if (this.state.value < this.state.max) {
            this.props.handleChange && this.props.handleChange(this.state.value + this.state.step);
        }
    };

    onArrowLeft = () => {
        if (this.state.value > this.state.min) {
            this.props.handleChange && this.props.handleChange(this.state.value - this.state.step);
        }
    };

    onMouseDown = (event) => {
        this.isMouseDown = true;
        this.updateSlider(event);
    };

    onMouseMove = (event) => {
        if (!this.isMouseDown) {
            return;
        }
        this.updateSlider(event);
    };

    onMouseUp = (event) => {
        this.isMouseDown = false;
    };

    onMouseLeave = (event) => {
        this.isMouseDown = false;
    };
    
    updateSlider = (event) => {
        const clientX = event.changedTouches && event.changedTouches[0] ? event.changedTouches[0].clientX : event.clientX;
        const currentTargetRect = event.currentTarget.getBoundingClientRect();
        const normalizedValue = (clientX - currentTargetRect.left) / currentTargetRect.width;
        if (normalizedValue < 0 || normalizedValue > 1) {
            return;
        }
        const range = this.state.max - this.state.min;
        const stepCount = range / this.state.step;
        const normalizedValueStepRounded = Math.round(normalizedValue * stepCount) / stepCount;

        const value = this.state.min + (normalizedValueStepRounded * range);

        if (value === this.state.value) {
            return;
        }

        this.props.handleChange && this.props.handleChange(value);
    }
    

    onComponentRefChanged = (element) => {
        inputManager.onParentRef(element, parentId);
    };

    onChildRefChanged = (element) => {
        inputManager.onChildRef(element, "range-slider-inner", this.onCustomFocus, parentId);
    };
    
    render() {

        const selectedStyle = this.state.selected ? 'selected' : '';

        return (
            <div
                className="range-slider-component"
                ref={this.onComponentRefChanged}
            >
                <div className="min">{ this.state.min }</div>
                <div
                    className={`range-slider-inner ${selectedStyle}`}
                    ref={this.onChildRefChanged}
                    onMouseDown={this.onMouseDown}
                    onMouseMove={this.onMouseMove}
                    onMouseUp={this.onMouseUp}
                    onMouseLeave={this.onMouseLeave}
                    onTouchStart={this.onMouseDown}
                    onTouchMove={this.onMouseMove}
                    onTouchEnd={this.onMouseUp}
                    onTouchCancel={this.onMouseLeave}
                >
                    <div className="range-slider-background">
                        <div
                            className="range-slider-fill"
                            style={{width: `${(this.state.value / this.state.max) * 100}%`}}
                        >
                        </div>
                        <div
                            className="thumb"
                            style={{left: `${(this.state.value / this.state.max) * 100}%`}}
                        >
                        </div>
                    </div>
                </div>
                <div className="max">{ this.state.max }</div>
            </div>
        )
    }
}

export default RangeSlider;