import React from 'react';
import {Avatar, AVATAR_TYPES} from '../../common/avatar/avatar.component';
import './tournament-player.component.less';
import rankIcon from '../../../assets/images/global/icon-rank.png';
import {withBadWordMask} from "../../../assets/lib/utils";

const TournamentPlayerComponent = ({item, selected, isFriends}) => {
	return (
		<div
			className={`tournament-player-component ${
					selected ? 'selected' : ''
				} ${
					isFriends ? 'friends' : ''
				} ${
					item.avatarType === AVATAR_TYPES.TWITCH ? 'twitch' : ''
				}`}>
			<div className="user-details">
				<Avatar userData={item}/>
				<div className="rank-container">
					<span className="rank-text">{item.Rank}</span>
					<img className="rank-icon" alt="rank-icon" src={rankIcon}/>
				</div>
				<div className="username">
					<span>{withBadWordMask(item.displayName)}</span>
				</div>
			</div>
		</div>
	);
};

export default TournamentPlayerComponent;
