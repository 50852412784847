import React from 'react';
import './tile-game.component.less';
import fallbackImg from '../../../assets/images/global/game-tile-fallback.png';
import { PulsarComponent } from '../pulsar/pulsar.component';
import platformImages from '../../../assets/images/platform-icons';
import { useInView } from 'react-intersection-observer';
import { MORE_BUTTON_ID } from '../../../constants';

const TileGame = ({ item, handleImageLoaded, selected, listLength, position, outerInViewUsed = false, outerInViewStatus = false }) => {
	if (!item) {
		return null;
	}
	let ref = null;
	let shouldRenderImage = false;

	const [showtext, setShowText] = React.useState(true);
	const [elementLoaded, setElementLoaded] = React.useState(false);

	if (outerInViewUsed) {
		shouldRenderImage = outerInViewStatus;
	} else {
		const innerInView = useInView({
			threshold: 0,
			triggerOnce: true,
			rootMargin: "200px 0px"
		});
		shouldRenderImage = innerInView.inView;
		ref = innerInView.ref;
	}

	const hasGameImg = !!item.image_uri;
	const imgSrc = hasGameImg ? item.image_uri : fallbackImg;
	const platformClass = item.hasChallenges ? 'challenges' : item.platform && item.platform.toLowerCase().replace(/\s/g, "");
	const selectedClass = selected ? (item._id === MORE_BUTTON_ID ? 'selected-nogame' : 'selected') : '';
	const tileClass = item._id === MORE_BUTTON_ID ? 'tile__search_shortcut' : '';


	function toggleImageLoaded(event){
		setShowText(false);
		setElementLoaded(true);
		if(handleImageLoaded){
			handleImageLoaded(event);
		}
	}

	return (
		<div ref={ref} className={`tile-game ${tileClass} ${selectedClass}`}>
			{shouldRenderImage === true &&
				<>
					{!hasGameImg || showtext && <div className="game-title">{item.title}</div>}

					<div class={`fade-in-div ${elementLoaded ? 'loaded': ''}`}>
						<div className="tile__img">
							<img
								className="game-image"
								src={imgSrc}
								alt={item.title}
								onLoad={toggleImageLoaded}
								draggable="false" // prevents browser's "ghost image" on click and drag
							/>
						</div>

						{(platformClass && !showtext) && <div className="tile__platform">
							<img
								className={`platform-image ${platformClass}`}
								alt={item.title}
								src={platformImages[platformClass]}
								draggable="false" // prevents browser's "ghost image" on click and drag
							/>
						</div>}
					</div>
					<PulsarComponent />
				</>
			}
		</div>
	);
};

export default TileGame;