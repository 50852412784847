import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../../common/avatar/avatar.component';
import SlayerBar from '../../common/slayer-bar/slayer-bar.component';
import { getChallengeHistoryObject, getGiants, getRetryCost, getSelectedChallengeHistory } from '../challenge.selectors';
import './giant-slayer-status.component.less';
import gsap from 'gsap';
import { getIsNoHeader, getIsPostAuthFetched, getLoggedUser, getSubscriptionsPopupDisplayCount, getUserIsSubscribed } from '../../../app.selectors';
import { navigateBack, navigateToLocation, ROUTES } from '../../../app.router';
import {
    AbandonGiantSlayerChallengeAction,
    GiantSlayerTargetAttemptsDoneAction,
    MarkGiantSlayerChallengeAsSlayer
} from '../challenge.actions';
import { RunGameAction } from '../../game-details/game-details.actions';
import { ButtonWithGem } from '../../common/button/common-button-with-gem.component';
import { Button } from '../../common/button/common-button.component';
import inputmanager from '../../../assets/lib/inputmanager';
import AudioManager, {
    TYPE_GIANT_SLAYER_HIT_FAIL,
    TYPE_GIANT_SLAYER_HIT_GOOD,
    TYPE_GIANT_SLAYER_LOSING,
    TYPE_GIANT_SLAYER_PROGRESS_BAR,
    TYPE_GIANT_SLAYER_RESULTS,
    TYPE_GIANT_SLAYER_WINNING,
    TYPE_WHOOSH
} from '../../../app.audio';
import { addPopup } from '../../popup/popup.component';
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import { isFreeTierV2FeatureFlagEnabled, SUBSCRIPTIONS_POPUP_DISPLAY_COUNT_MAX, withBadWordMask } from '../../../assets/lib/utils';
import { incrementSubscriptionsPopupCount } from '../../../app.actions';
import deviceInfo, { STORE_TYPE_APPLE_IOS } from '../../../assets/lib/deviceInfo';
import { PREMIUM_POPUP_HEADER_TEXT_2 } from '../../../constants';

const PAGE_ID = 'giant_slayer_status_1';

const GiantSlayerStatus = () => {
    const [slayerScoreText, setSlayerScoreText] = useState(0);
    const [giantScoreText, setGiantScoreText] = useState(0);

    const dispatch = useDispatch();
    const noHeader = useSelector(state => getIsNoHeader(state));
    const loggedUser = useSelector((state) => getLoggedUser(state));

    const currentRoute = useSelector((state) => state.routing.currentRoute);
    const isGameSessionResult = (currentRoute.path === ROUTES.GIANT_SLAYER_RESULT.path);
    const challengeHistoryObject = isGameSessionResult ? useSelector((state) => getChallengeHistoryObject(state)) : useSelector((state) => getSelectedChallengeHistory(state));

    const giants = useSelector((state) => getGiants(state));
    const retryCost = useSelector((state) => getRetryCost(state));

    const subscriptionsPopupDisplayCount = useSelector((state) => getSubscriptionsPopupDisplayCount(state));
    const isFreeTierV2FeatureEnabled = useSelector((state) => isFreeTierV2FeatureFlagEnabled(state));
    const isPostAuthFetched = useSelector((state) => getIsPostAuthFetched(state));
    const isUserSubscribed = useSelector((state) => getUserIsSubscribed(state));
    const previousRoute = useSelector((state) => state.routing.previousRoute && state.routing.previousRoute.path);

    console.log('challengeHistoryObject', challengeHistoryObject);

    const giantSlayerDetails = challengeHistoryObject.giantSlayerDetails;

    const slayersBlockRef = useRef();
    const giantBlockRef = useRef();
    const slayerIllusRef = useRef();
    const giantIllusRef = useRef();
    const vsIconBigRef = useRef();
    const slayerScoreFlagRef = useRef();
    const slayerScoreFlagContentRef = useRef();
    const giantScoreFlagRef = useRef();
    const giantScoreFlagContentRef = useRef();
    const slayerBarRef = useRef();
    const vsBlockRef = useRef();
    const scoreOutcomeTextRef = useRef();
    const outcomeTextRef = useRef();

    const isSlayer = (giantSlayerDetails?.role === 'slayer');
    const isFail = challengeHistoryObject.isFail;

    const scoreSlayer = isSlayer ? isGameSessionResult ? Number(challengeHistoryObject.score) : Number(challengeHistoryObject.me.score) : 0;
    const scoreGiant = (giantSlayerDetails?.state === 'created') ? Number(challengeHistoryObject.score) : Number(giantSlayerDetails?.giantScore);

    // always display a minimum (5%) of slayer portion or giant portion even of the real value is 0% or 100%
    const forDisplayCurrentSlayerWinFraction = Math.min(Math.max(giantSlayerDetails?.currentSlayerWinFraction || 0, 0.05), 0.95);

    const slayerPercentage = forDisplayCurrentSlayerWinFraction * 100;
    const giantPercentage = 100 - slayerPercentage;
    const slayersWinning = giantSlayerDetails?.currentSlayerWinFraction > giantSlayerDetails?.targetSlayerWinFraction;


    const scoreOutcomeText = isSlayer ?
        giantSlayerDetails?.beatGiant ? 'Good hit!' : 'Failed hit!' :
        '';

    const outcomeText = (giantSlayerDetails?.state === 'completed') ?
        slayersWinning ? 'Slayers won!' : 'Giant won!' :
        slayersWinning ? 'Slayers winning!' : 'Giant winning!';

    const scoreObjectAnim = {
        scoreSlayer: 0,
        scoreGiant: 0,
    }

    const instanceVariables = useRef({
        abandonOnUnmount: true,
        isRetry: false
    });

    const userGems = loggedUser.currencies.GEMS;

    const onRetry = () => {
        const {challengeId, gameId, giantSlayerChallengeId} = challengeHistoryObject;
        instanceVariables.current.abandonOnUnmount = false;
        instanceVariables.current.isRetry = true;

        dispatch(RunGameAction({
            gameId,
            challengeId,
            challengeStyle: 'intro',
            giantSlayerChallengeId,
            role: 'giant',
        }));
        navigateToLocation(ROUTES.HOW_TO_PLAY, {
            id: gameId,
            challengeId,
            giantSlayerChallengeId
        });
    }

    const onCommit = () => {
        const { giantSlayerChallengeId, isFail } = challengeHistoryObject;
        if (isFail) {
            instanceVariables.current.abandonOnUnmount = true;
            navigateToLocation(ROUTES.HOMEPAGE, { focusElement: 'giantSlayer' });
        } else {
            instanceVariables.current.abandonOnUnmount = false;
            dispatch(GiantSlayerTargetAttemptsDoneAction(giantSlayerChallengeId, () => {
                navigateToLocation(ROUTES.HOMEPAGE, { focusElement: 'giantSlayer' });
            }));
        }
    }

    const onDone = () => {
        instanceVariables.current.abandonOnUnmount = false;
        dispatch(MarkGiantSlayerChallengeAsSlayer(challengeHistoryObject?.giantSlayerChallengeId));
        navigateToLocation(ROUTES.HOMEPAGE, { focusElement: 'giantSlayer' });
    }

    const onExitClicked = () => {
        navigateBack();
    }

    const updateHandler = () => {
        // show decimals during animation only when the result score has decimals, otherwise round value for integers
        setSlayerScoreText(Number.isInteger(scoreSlayer) ? Math.round(scoreObjectAnim.scoreSlayer) : Math.round(scoreObjectAnim.scoreSlayer * 1000) / 1000);
        setGiantScoreText(Number.isInteger(scoreGiant) ? Math.round(scoreObjectAnim.scoreGiant) : Math.round(scoreObjectAnim.scoreGiant * 1000) / 1000);
    };

    const showSubscriptionsComparisonPremiumPopup = () => {
        if (isFreeTierV2FeatureEnabled
            && isPostAuthFetched
            && !isUserSubscribed
            && previousRoute === ROUTES.RESULTS_WAITING.path
            // show subscriptions popup only 2 times during app session (for challenge (solo, duel, giant) results pages only)
            && (SUBSCRIPTIONS_POPUP_DISPLAY_COUNT_MAX > subscriptionsPopupDisplayCount)
            && (deviceInfo.storeType !== STORE_TYPE_APPLE_IOS)
        ) {
            dispatch(incrementSubscriptionsPopupCount());
            addPopup(<SubscriptionComparisonsPopup leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_2} price={'£1'} pageId={PAGE_ID}/>);
        }
    }

    // wait until DOM has been rendered to start animations
    useEffect(() => {
        showSubscriptionsComparisonPremiumPopup();

        if (isGameSessionResult && !isSlayer) {
            inputmanager.setCurrentChildById('commitButton');
        } else if (isGameSessionResult && isSlayer) {
            inputmanager.setCurrentChildById('doneButton');
        } else {
            inputmanager.setCurrentChildById('exitButton');
        }

        const tl = gsap.timeline();
        tl.call(() => {
            AudioManager.playAudio(TYPE_GIANT_SLAYER_RESULTS);
        });

        // hide unwanted informations
        if (!isSlayer) {
            tl.set(slayerScoreFlagRef.current, {opacity: 0});
            tl.set(scoreOutcomeTextRef.current, {opacity: 0});
        }
        if (isGameSessionResult && !isSlayer) {
            tl.set(slayerBarRef.current, {opacity: 0});
            tl.set(vsBlockRef.current, {opacity: 0});
            tl.set(outcomeTextRef.current, {opacity: 0});
        }

        // VS icon appears
        tl.fromTo(vsIconBigRef.current, 0.3, {scale: 10, opacity: 0}, {scale: 1, opacity: 1, delay: 0.5, ease: 'ease.easeIn'});

        // avatars move to the sides
        tl.to(slayersBlockRef.current, 0.5, {left: "5em", delay: 0.5, ease: 'ease.easeOut'}, 'moveAvatars');
        tl.to(giantBlockRef.current, 0.5, {right: "5em", delay: 0.5, ease: 'ease.easeOut'}, 'moveAvatars');

        // score flags appear
        if (isSlayer) {
            tl.fromTo(slayerScoreFlagRef.current, 0.6, {scale: 0, opacity: 0}, {scale: 1, opacity: 1, ease: 'ease.easeOut'}, 'scoreFlagsAppear');
        }
        tl.fromTo(giantScoreFlagRef.current, 0.6, {scale: 0, opacity: 0}, {scale: 1, opacity: 1, ease: 'ease.easeOut'}, 'scoreFlagsAppear');

        // score numbers go up
        tl.to(scoreObjectAnim, 1, {scoreSlayer: scoreSlayer, scoreGiant: (typeof scoreGiant === 'number' ? scoreGiant : Number(challengeHistoryObject.score)), onUpdate:updateHandler, ease:'ease.easeOut'});

        if (!isSlayer && isFail) {
            giantScoreFlagContentRef.current.innerText = "Failed";
        }

        if (isSlayer && isFail) {
            slayerScoreFlagContentRef.current.innerText = "Failed";
        }

        if (!isGameSessionResult || isSlayer) {

            if (isSlayer) {
                // glow on winner flag (individual score)
                const winnerFlag = giantSlayerDetails?.beatGiant ? slayerScoreFlagRef.current : giantScoreFlagRef.current;
                const winnerFlagClass = giantSlayerDetails?.beatGiant ? 'slayer-score-flag' : 'giant-score-flag';
                if (!deviceInfo.isRunningOnNativeClientV2()) {
                    tl.set(winnerFlag, {className: winnerFlagClass + ' glow'});
                }

                // score outcome text grows
                tl.call(() => {
                    AudioManager.playAudio(TYPE_WHOOSH);
                });
                tl.fromTo(scoreOutcomeTextRef.current, 0.3, {scale: 10, opacity: 0}, {scale: 1, opacity: 1, ease: 'ease.easeIn'});
                tl.call(() => {
                    AudioManager.playAudio(giantSlayerDetails?.beatGiant ? TYPE_GIANT_SLAYER_HIT_GOOD : TYPE_GIANT_SLAYER_HIT_FAIL);
                });
            }

            // VS icon disappears
            tl.to(vsIconBigRef.current, 1, {opacity: 0, delay: 1.5, ease: 'ease.easeIn'}, 'scoreFlagsDisappear');

            if (isSlayer) {
                // score flags move to the sides
                tl.to(slayerScoreFlagRef.current, 0.3, {left: "1em", delay: 1.5, ease: 'ease.easeOut'}, 'scoreFlagsDisappear');

                // score outcome shrinks
                tl.to(scoreOutcomeTextRef.current, 0.5, {scale: 0, opacity: 0, delay: 1.5, ease: 'ease.easeOut'}, 'scoreFlagsDisappear');
            }
            tl.to(giantScoreFlagRef.current, 0.3, {right: "1em", delay: 1.5, ease: 'ease.easeOut'}, 'scoreFlagsDisappear');

            // slayer bar appears
            tl.call(() => {
                AudioManager.playAudio(TYPE_WHOOSH);
            });
            tl.fromTo(slayerBarRef.current, 0.3, {scale: 10, opacity: 0}, {scale: 1.5, opacity: 1, ease: 'ease.easeIn'}, 'slayerBarAppears');

            // vs block appears
            tl.fromTo(vsBlockRef.current, 0.3, {scale: 10, opacity: 0}, {scale: 1, opacity: 1, ease: 'ease.easeIn'}, 'slayerBarAppears');

            // slayer bar growing
            tl.call(() => {
                AudioManager.playAudio(TYPE_GIANT_SLAYER_PROGRESS_BAR);
            });
            tl.fromTo('.slayer-portion', 3, {width: '5%'}, {width: `${slayerPercentage}%`, ease: 'ease.easeOut'}, 'slayerBarGrows');
            tl.fromTo('.giant-portion', 3, {width: '95%'}, {width: `${giantPercentage}%`, ease: 'ease.easeOut'}, 'slayerBarGrows');
            tl.call(() => {
                AudioManager.playAudio(TYPE_WHOOSH);
            });

            // glow on winner illus (winning side)
            const winnerIllus = (slayerPercentage > giantPercentage) ? slayerIllusRef.current : giantIllusRef.current;
            const winnerIllusClass = (slayerPercentage > giantPercentage) ? 'slayer-illus' : 'giant-illus';
            if (!deviceInfo.isRunningOnNativeClientV2()) {
                tl.set(winnerIllus, {className: winnerIllusClass + ' glow'});
            }

            // outcome text grows
            if (deviceInfo.isRunningOnNativeClientV2()) {
                tl.fromTo(outcomeTextRef.current, 0.3, {opacity: 0}, {opacity: 1, ease: 'ease.easeIn'});
            } else {
                tl.fromTo(outcomeTextRef.current, 0.3, {scale: 10, opacity: 0}, {scale: 1, opacity: 1, ease: 'ease.easeIn'});
            }
            tl.call(() => {
                const isWinning = (isSlayer && slayersWinning) || (!isSlayer && !slayersWinning);
                AudioManager.playAudio(isWinning ? TYPE_GIANT_SLAYER_WINNING : TYPE_GIANT_SLAYER_LOSING);
            });

        }

        return () => {
            tl.kill();

            // if user clicks Retry - do not complete gs challenge
            if (instanceVariables.current.isRetry) return;

            const { isFail } = challengeHistoryObject;
            // if user doesn't click Submit button - complete gs challenge automatically
            if (isGameSessionResult && !isSlayer && !isFail) {
                onCommit();
            } else if (isGameSessionResult && isSlayer) {
                onDone();
            } else if (instanceVariables.current.abandonOnUnmount) {
                dispatch(AbandonGiantSlayerChallengeAction());
            }
        }
    }, []);

    let giantUserData = null;
    if (challengeHistoryObject?.giantSlayerDetails) {
        giantUserData = giants.find(giant => giant._id === challengeHistoryObject.giantSlayerDetails.giantId);
    }

    if (!challengeHistoryObject || !Object.keys(challengeHistoryObject).length) return null;

    return (
        <div className={`giant-slayer-status ${noHeader ? 'noHeader' : ''}`}>
        <div className="giant-slayer-status-inner">

            <div className="intro-text">
                <h1>Giant Slayer Results</h1>
                <p>
                    {!isSlayer && `Take on the community with your mighty score. You can retry, but it’ll cost you double each time. The slayers will try to take you down via a ${Math.round(giantSlayerDetails.targetSlayerWinFraction * 100)}% win rate.`}
                    {isSlayer && 'Rally together to bring down the giant! Hit hard and surpass the win indicator collectively. You can retry, but it’ll cost you double each time.'}
                </p>
            </div>

            <div className="slayers-block" ref={slayersBlockRef}>
                {isSlayer && <Avatar userData={loggedUser} />}
                <div className="slayer-illus" ref={slayerIllusRef}></div>
            </div>
            <div className="giant-block" ref={giantBlockRef}>
                {giantUserData && <Avatar userData={giantUserData}/>}
                <div className="giant-illus" ref={giantIllusRef}></div>
            </div>

            <div className="vs-icon-big" ref={vsIconBigRef}></div>

            <div className="slayer-score-flag" ref={slayerScoreFlagRef}>
                <div ref={slayerScoreFlagContentRef}>
                    <div className="score-title">SCORE</div>
                    <div className="slayer-score-text">{slayerScoreText}</div>
                    <div className="score-label">{challengeHistoryObject.scoreLabel}</div>
                </div>
            </div>
            <div className="giant-score-flag" ref={giantScoreFlagRef}>
                <div ref={giantScoreFlagContentRef}>
                    <div className="score-title">SCORE</div>
                    <div className="giant-score-text">{giantScoreText}</div>
                    <div className="score-label">{challengeHistoryObject.scoreLabel}</div>
                </div>
            </div>

            <SlayerBar
                ref={slayerBarRef}
                currentSlayerWinFraction={giantSlayerDetails.currentSlayerWinFraction}
                targetSlayerWinFraction={giantSlayerDetails.targetSlayerWinFraction}
            />

            <div className="vs-block" ref={vsBlockRef}>
                <div className="slayers-text">
                    {`${giantSlayerDetails.numberSlayers || 0} slayer${(giantSlayerDetails.numberSlayers !== 1) ? 's' : ''}`}
                </div>
                <div className="vs-icon"></div>
                <div className="giant-text">{withBadWordMask(giantUserData?.displayName)}</div>
            </div>

            <div className={`score-outcome-text ${!deviceInfo.isRunningOnNativeClientV2() ? 'clientV1' : ''}`} ref={scoreOutcomeTextRef}>
                <div className='text-inner'>
                    {scoreOutcomeText}
                </div>
            </div>

            <div className={`outcome-text ${!deviceInfo.isRunningOnNativeClientV2() ? 'clientV1' : ''}`} ref={outcomeTextRef}>
                <div className='text-inner'>
                    {outcomeText}
                </div>
            </div>

            <div className="buttons-container">
                {isGameSessionResult && <ButtonWithGem
                    className="primary"
                    onClick={onRetry}
                    childId="retryButton"
                    cost={retryCost}
                    disabled={!retryCost || retryCost>userGems}
                    notSelectable={!retryCost || retryCost>userGems}
                >
                    {'Retry'}
                </ButtonWithGem>}
                {isGameSessionResult && !isSlayer && <Button
                    className="primary"
                    onClick={onCommit}
                    childId="commitButton"
                >
                    {isFail ? 'Abandon' : 'Submit'}
                </Button>}
                {isGameSessionResult && isSlayer && <Button
                    className="primary"
                    onClick={onDone}
                    childId="doneButton"
                >
                    Submit
                </Button>}
                {!isGameSessionResult && <Button
                    className="primary"
                    onClick={onExitClicked}
                    childId="exitButton"
                >
                    Exit
                </Button>}
            </div>

        </div>
        </div>
    );
};

export default GiantSlayerStatus;
