import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
	emojiValidator,
	maxLengthValidator,
	minLengthValidator,
	requiredValidator, specCharactersValidator, trimValidator
} from '../../common/input/input.validator';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { Button } from '../../common/button/common-button.component';
import { Input } from '../../common/input/input.component';
import {
	checkDisplayNameActionClear,
	CheckDisplayNameAction,
	SetSignupFieldAction,
	SignupUserAction
} from '../login.actions';
import inputManager from '../../../assets/lib/inputmanager';
import '../login-global.less';
import {checkWebViewDimensions} from '../../../assets/lib/utils';
import {getWebViewDimensions} from '../../../app.selectors';
import {SaveRoute} from "../../../app.actions";

const maxValue20 = maxLengthValidator(20);
const minValue2 = minLengthValidator(2);
const formId = "gamertagForm";
class SignupGamertagComponent extends Component {
	constructor(props) {
		super(props);

		this.displayNameApproved = false;
	}

	componentDidMount() {
		inputManager.setCurrentChildById("displayNameInput");
	}

	componentWillUnmount( ) {
		this.props.dispatch(checkDisplayNameActionClear());
	}

	onCheckDisplayNameResponse = (success) => {
		if(!success) return;

			const {displayName} = this.submittedValues;
			const {isRegularRegistration = true, externalSponsorTournamentLinkUsed, externalSponsorTournamentId} = this.props;
			this.displayNameApproved = true;
			if(isRegularRegistration){
				// if used external link for sponsored tournaments - do not navigate to check email page - navigate to the sponsored tournament right after success registration instead
				if (externalSponsorTournamentLinkUsed && externalSponsorTournamentId) {
					this.props.dispatch(SaveRoute(ROUTES.TOURNAMENTS_INFO, {}, {id: externalSponsorTournamentId, sponsor: true}));
				} else {
					this.props.dispatch(SaveRoute(ROUTES.SIGNUP_AUTHENTICATION));
				}
				this.props.dispatch(SetSignupFieldAction({displayName}));
				this.props.dispatch(SignupUserAction());
			} else {
				this.props.handleValidDisplayName && this.props.handleValidDisplayName(displayName);
			}
	};

	checkDisplayName = (values) => {
		if(this.displayNameApproved) return;

		const {displayName} = values;
		this.submittedValues = values
		this.props.dispatch(CheckDisplayNameAction(displayName,this.onCheckDisplayNameResponse));
	};

	back = () => {
		const {isRegularRegistration = true} = this.props;
		if(isRegularRegistration) {
			navigateToLocation(ROUTES.LOGIN);
		} else {
			this.props.handleBackButton && this.props.handleBackButton();
		}
	};

	render () {
		return(
			<div className={`login-wrapper ${checkWebViewDimensions(this.props.viewDimensions)}`}>
				<section className="login-section login-section--left">
					<h1>Display Name</h1>
					<p>
						Your display name is how you'll be known throughout Antstream.
						It's how other users will see you. Get a great name before it’s gone!
					</p>
					<form>
						<Field
							name="displayName"
							component={Input}
							formId={formId}
							label="Display Name"
							validate={[minValue2, maxValue20, requiredValidator, emojiValidator, trimValidator, specCharactersValidator]}
							childId="displayNameInput"
						/>
						{this.props.error && <div className="remote-validation-error">{this.props.error}</div>}
					</form>
					<Button
						className="primary right-button"
						disabled={!this.props.valid || this.props.loading || this.props.checkDisplayNamePending}
						childId="nextButton"
						onClick={this.props.handleSubmit(this.checkDisplayName)}
					>
						Next
					</Button>
					<Button
						className="secondary right-button"
						disabled={this.props.disableBack}
						onClick={this.back}
						childId="backButton"
					>
						Back
					</Button>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const viewDimensions = getWebViewDimensions(state);

	return {
		...state.login,
		viewDimensions,
		checkDisplayNamePending: state.login.checkDisplayNamePending,
		externalSponsorTournamentLinkUsed: state.tournaments.externalSponsorTournamentLinkUsed,
		externalSponsorTournamentId: state.tournaments.externalSponsorTournamentId
	};
};

SignupGamertagComponent = connect(mapStateToProps)(SignupGamertagComponent);

export const SignupGamertag = reduxForm({
	form: formId,
})(SignupGamertagComponent);

