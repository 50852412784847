import * as ACTIONS from './tutorial.actions';

const initialState = {
	shownTutorialsIds: [],
	allTutorials:[],
};

export const tutorialReducer = (state = initialState, action) => {
	switch(action.type) { 
		case ACTIONS.SHOW_TUTORIAL_ACTION:
			return {
				...state,
				shownTutorialsIds: [...state.shownTutorialsIds, action.payload]
			};
		case ACTIONS.GET_TUTORIALS_COMPLETED_ACTION_SUCCESS:
			return {
				...state,
				allTutorials: action.payload
			};
		case ACTIONS.TUTORIAL_COMPLETE_ACTION_SUCCESS:
			return {
				...state,
				allTutorials:action.payload
			};
		default:
			return state;
	}
};
