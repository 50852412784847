const targetApplicationId = 'bfmkc2G8Fv.bgservice';
const remoteMessagePortName = 'BG_SERVICE_PORT';
const localMessagePortName = 'FOREGROUND_APP_PORT';
// port for receiving messages
let localMsgPort = null;

export const sendMessageToBgService = (msg) => {
    try {
        const { messageport } = require('tizen-common-web');

        if (!localMsgPort) {
            localMsgPort = messageport.requestTrustedLocalMessagePort(localMessagePortName);
        }

        const remoteMsgPort = requestRemotePort();
        if (remoteMsgPort) {
            // sends message using remote port and indicates the local port for eventual reply message
            remoteMsgPort.sendMessage(msg, localMsgPort);
            console.log('Tizen msg sent');
        } else {
            console.log('Tizen failed to send msg', remoteMsgPort);
        }
    } catch (error) {
        console.error('Tizen Error occured while sending message: ' + error);
    }
};

export const requestRemotePort = () => {
    // get the port for sending messages
    try {
        const { messageport } = require('tizen-common-web');

        // const remoteMsgPort = messageport.requestRemoteMessagePort(
        const remoteMsgPort = messageport.requestTrustedRemoteMessagePort(
            targetApplicationId,
            remoteMessagePortName
        );
        console.log('tizen remoteMsgPort: ', remoteMsgPort);
        return remoteMsgPort;
    } catch (error) {
        console.error('tizen requestRemotePort error: ', error.message);
        return null;
    }
};

export const launchBgService = (accessToken) => {
    const { application, ApplicationControl, ApplicationControlData } = require('tizen-common-web');
    // block ENTER key to avoid multiple executions of background service
    //App.KeyHandler.disableKeyHandler();
    const remoteMsgPort = requestRemotePort();

    application.launchAppControl(
        new ApplicationControl('http://tizen.org/appcontrol/operation/pick',
            null,
            null,
            null,
            [new ApplicationControlData('accessToken', [accessToken])]),
        targetApplicationId,
        function () {
            //App.KeyHandler.enableKeyHandler();
            console.log('Launching background service success');
        },
        function (e) {
            //App.KeyHandler.enableKeyHandler();
            console.log('Launching background service [' + targetApplicationId + '] failed: ' + e.message);
        }
    );
}