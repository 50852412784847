import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {selectedListIndex, selectedTileId} from '../../../app.selectors';
import { connect } from 'react-redux';

import './vertical-list.component.css';
import inputmanager from '../../../assets/lib/inputmanager';

class VerticalListTile extends Component {
	constructor() {
		super();
		this.state = {
			loaded: false
		};
		this.onClick = this.onClick.bind(this);
	}

	componentDidMount() {
		this.props.onListGenerateComplete && this.props.onListGenerateComplete();
	}

	onCustomFocus = (focus,nextElement) => {
		const reactDom = ReactDOM.findDOMNode(this);
		if(focus) {
			this.props.onSelect && this.props.onSelect(this.props.item, this.props.index);
			if(reactDom) {
				reactDom.focus();
			}
		}else {
			this.props.onTileBlur && this.props.onTileBlur(this.props.item, nextElement);
			if(reactDom) {
				reactDom.blur();
			}
		}
	};

	onClick() {
		this.props.onClick && this.props.onClick(this.props.item, this.props.index);
	}

	renderContent(props) {
		if (this.props.renderer) {
			return this.props.renderer(props);
		}
		return <img className="tile__img" src={props.item.image_uri} alt={props.item.title} onLoad={this.handleImageLoaded} />;
	}

	onTileRef = (element) => {
		const key = this.props.listIndex.toString() + this.props.item[this.props.idAttribute];
		inputmanager.onChildRef(element,key,this.onCustomFocus,this.props.parentId);
	};

	render() {
		const selectedStyle = this.props.selected ? 'selected' : '';
		return (
			<div
				ref={this.onTileRef}
				tabIndex={0}
				className={`tile ${selectedStyle}`}
				onClick={this.onClick}
				style={{...this.props.style}}
			>
				<div className="tile-inner"> 
					{this.renderContent(this.props)}
				</div>
			</div>
		);
	}
}

VerticalListTile.propTypes = {
	item: PropTypes.object.isRequired,
	onSelect: PropTypes.func,
	onClick: PropTypes.func
};

const mapStateToProps = (state,ownProps) => {
	const selected = selectedListIndex(state)===ownProps.listIndex && selectedTileId(state)===ownProps.item[ownProps.idAttribute];

	return {
		selected
	};
};

export {VerticalListTile};
export default connect(mapStateToProps)(VerticalListTile);
