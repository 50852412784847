import React, {useEffect} from 'react';
import gemIcon from '../../../assets/images/global/gems.png';
import './tile-challenge.component.less';
import {PulsarComponent} from '../pulsar/pulsar.component';
import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import inputmanager from '../../../assets/lib/inputmanager';
import { useSelector } from 'react-redux';
import { getUserIsSubscribed } from '../../../app.selectors';
import fallbackImg from '../../../assets/images/global/game-tile-fallback.png';
import { useInView } from 'react-intersection-observer';

// Components
import FeatureToggle, { FEATURES as FEATURE_TOGGLES} from '../feature-toggle/feature-toggle.component';

// Assets
import { STORE_TYPE_EPIC } from '../../../assets/lib/deviceInfo';
import { MOST_POPULAR_CHALLENGES_LIST_ID } from '../../daily-challenges/daily-challenges.actions';

const TileChallenge = ({ item, animate, selected, animatingChallengeId, handleClick, ...props }) => {
	const isSubscribed = useSelector((state) => getUserIsSubscribed(state));

	let ref = null;
	let useLazyLoading = false;
	let shouldRenderLazyContent = false;
	const { outerInViewUsed = false, outerInViewStatus = false } = props;
	if (outerInViewUsed) {
		useLazyLoading = true;
		shouldRenderLazyContent = outerInViewStatus;
	} else if (props.innerInViewUsed) {
		const innerInView = useInView({
			threshold: 0,
			triggerOnce: true
		});
		useLazyLoading = true;
		shouldRenderLazyContent = innerInView.inView;
		ref = innerInView.ref;
	}

	const lockstyle = item.unlocked ? 'unlocked' : 'locked';
	const animateClass = animate && animatingChallengeId===item._id ? 'animate' : '';
	const withBordersClass = item.withBorders ? 'with-borders' : '';
	const selectedClass = selected ? 'selected' : '';
	const trendingClass = props.listId === MOST_POPULAR_CHALLENGES_LIST_ID ? `trending ${item.rank > 9 ? 'trending--double' : ''}` : ''; 

	const showPremiumBenefits = !isSubscribed;
	
	/**
	 * cleanup tile challenge only on unmount - it should prevent a bug when inputManager.emitEvent=false, but it must be 'true'
	 **/
	let canCleanUp = true;

	useEffect(() => {
		if(selected) {
			inputmanager.emitEvent=true;
			window.addEventListener('input_manager_click', handleClick)
			canCleanUp = true;
		} else {
			if (canCleanUp) {
				canCleanUp = false;
			}
		}

		return () => {
			if (canCleanUp) {
				inputmanager.emitEvent = false;
				window.removeEventListener('input_manager_click', handleClick);
				canCleanUp = false;
			}
		};
	});

	function renderMainImage() {
		if (useLazyLoading && !shouldRenderLazyContent) return null;

		return (
			<div className="img-bg">
				<img src={item.img_src} alt="" />
			</div>
		);
	}

	function renderGameImage() {
		if (useLazyLoading && !shouldRenderLazyContent) return null;

		// Experimental test using Cloudflare CDN to supply images that are closer to the size we need
		if ((window.config.REACT_APP_ENV == 'live') || (window.config.REACT_APP_ENV == 'stg')) {
			const url = new URL(item.gameImgSrc);
			let imageTransform = url.protocol + "//" + url.host + "/cdn-cgi/image/width=110" + url.pathname;
			return <img src={imageTransform} alt={item.gameTitle} className='tile-challenge-game__img' />;
		}
		else
		{
			return <img src={item.gameImgSrc} alt={item.gameTitle} className='tile-challenge-game__img'/>;
		}
	}

	function renderContent() {
		if (useLazyLoading && !shouldRenderLazyContent) return null;

		return (
			<div className="content">
				<p className="title unskew">{item.title}</p>
				<div className={`difficulty t-${item.tier}`}>
					<span className="one"></span>
					<span className="two"></span>
					<span className="three"></span>
					<span className="four"></span>
					<span className="five"></span>
				</div>
			</div>
		);
	}

	function renderUnlock() {
		if (props.noUnlock) return null;
		if (useLazyLoading && !shouldRenderLazyContent) return null;

		return (
			<div className="unlock unskew">
				<FeatureToggle feature={FEATURE_TOGGLES.PREMIUM_SAVE_ONLY} platforms={[STORE_TYPE_EPIC]}>
					{showPremiumBenefits && !item.unlocked &&
					<div className="premium-benefits">
						<div className="premium-benefits-text">
							Free-to-unlock on Premium
						</div>
					</div>
					}
				</FeatureToggle>
				<div className="padlock"></div>
				<div className="gems">{item.costs && item.costs.unlockCost}<img src={gemIcon} alt="" /></div>
			</div>
		);
	}

	return (
		<div ref={ref} className={`tile-challenge-component-skew ${trendingClass}`} onClick={handleClick}>
			{trendingClass &&
				<div className={`trending-index ${item.rank > 9 ? 'trending-index--double' : ''}`}>
					<div className='trending-index-text'>
						{item.rank}
					</div>
				</div>
			}
			<div className={`tile-challenge-component skew gradient-top-border ${withBordersClass} ${selectedClass} t-${item.tier} ${lockstyle} ${animateClass}`}>
				<div className="tile-challenge-animation-container">
					<PulsarComponent borderWithRadius />
				</div>
				{renderMainImage()}
				{renderUnlock()}
				{renderContent()}
				{featureInfo.isSupported(FEATURES.GAMEPLAY) && <div className="play-icon unskew">
					<div className="inner"></div>
				</div>}
			</div>
			{item.gameImgSrc &&
				<div className={`tile-challenge-game t-${item.tier} ${selectedClass}`}>
					{renderGameImage()}
				</div>
			}
			{item.gameImgSrc && selected && <div className={`tile-challenge-game-border`}></div>}
		</div>
	);
};

function shouldComponentUpdate(props, nextProps) {
	if (!props.useShouldComponentUpdate) return false;

	if (props.selected !== nextProps.selected) {
		return false;
	}

	return true;
}

export default React.memo(TileChallenge, shouldComponentUpdate);
