import React from 'react';
import './tile-tournament-history.component.less';
import {PulsarComponent} from '../pulsar/pulsar.component';
import {getBadgeIconSrc, getNthText} from "../../../assets/lib/utils";
import Countdown from "../../tournaments/info/countdown.component";
import nonPoduimIcon from "../../../assets/images/generic-icons/M-tournament_trophy-generic-non_podium.png";
import {isLowSpecDevice} from "../../../app.helpers";


const TileTournamentHistory = ({ item, selected }) => {
	if (!item) return null;
	const { tournamentObject, tournamentPlayer} = item;

	let badgeIcon = null;
	let rankText = '';
	if(tournamentPlayer){
		if(tournamentPlayer.resultObject && tournamentPlayer.resultObject.leaderBoardObject){
			badgeIcon = getBadgeIconSrc(tournamentPlayer.resultObject.leaderBoardObject.rank);
			rankText = tournamentPlayer.resultObject.leaderBoardObject.rank+getNthText(tournamentPlayer.resultObject.leaderBoardObject.rank);
		}
		if(!badgeIcon){
			badgeIcon = nonPoduimIcon;
		}
	}

	// this will disable transition and transform for tournament tiles because it generates some artifacts on client V2
	const lowSpecClass = isLowSpecDevice() ? 'tile-tournament-history-component--lowspec' : '';

	return (
		<div className={`tile-tournament-history-component ${selected?'selected':''} ${lowSpecClass}`}>
				<div className="background-container">
					<img src={tournamentObject.smallBanner} alt="background"/>
					{/*<img src={testImg} alt="background"/>*/}
				</div>
				<Countdown startTimeStamp={tournamentObject.startDate} endTimeStamp={tournamentObject.endDate}/>
				<div className={`content-container`}>
					{
						badgeIcon ? <div className="badge-image-container">
							<img className="badge-img" src={badgeIcon} alt="badge"/>
							{
								badgeIcon === nonPoduimIcon && <div className="position">
									{
										rankText
									}
								</div>
							}
						</div> : <div className="badge-image-container"/>
					}
					<div className="tournament-title">
						{tournamentObject.title}
					</div>
				</div>
				<PulsarComponent borderWithRadius />
		</div>
	);
};

export default TileTournamentHistory;
