import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, find } from 'lodash';
import { UpdateMyMenuSelection } from '../game-details.actions';
import {
    getIsUserInGermany,
    getLoggedUser,
    getUserIsSubscribed,
    isFreeTier,
    getGamesLeft,
    getRoutingSearch,
    getFreeTierGamesInformation,
    getIsPostAuthFetched,
    getGemPlayGameCost
} from '../../../app.selectors';
import {
    getCurrentGameDetails,
    getCurrentGamePlatforms,
    getIsCurrentGameFavourite,
    getIsUpvoted,
    getTotalVotes,
    getRemainingVotes,
    getIsFollowGamePending
} from '../game-details.selector';
import {
    FetchGamePlatformsAction,
    FollowGameAction,
    UnfollowGameAction,
    AddUpvoteGameAction,
    RemoveUpvoteGameAction,
    GetUpvoteGameAction
} from '../game-details.actions';
import { ButtonFavourite } from '../../common/button/common-button-favourite.component';
import { ButtonUpvote } from '../../common/button/common-button-with-upvote.component';
import { Icon } from '../../common/icon/icon.component';
import DirectionalButton from '../../common/button/directional-button.component';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { RunGameAction } from '../game-details.actions';
import {SetTutorialId} from '../../tutorial/tutorial.actions.js';
import inputManager from '../../../assets/lib/inputmanager';
import multiplayerIcon from '../../../assets/images/global/multiplayer.svg';
import highScoreIcon from '../../../assets/images/global/leaderboard_global.png';
import AudioManager, {TYPE_GAMEDETAIL_PLAY_BUTTON} from '../../../app.audio';
import './game-details-info.component.less';
import SaveButton from '../../common/button/save-button.component';
import { SetGTMEvent, TYPE_GAME_VIEW } from '../../../gtm.actions';
import featureInfo, {FEATURES} from '../../../assets/lib/featureInfo';
import { getGermanyAgeIcon, startPaymentProcess } from '../../../app.actions';
import ShareButton from '../../common/share-button/share-button.component';
import Helmet from '../../common/helmet/helmet.component';
import {DEEP_LINK_TARGET, DEEP_LINK_PARAMETER, generateDeepLinkURL} from '../../../assets/lib/deepLinkGenerator'
import {
    APPLE_IOS_SKU,
    GAME_TYPE,
    PREMIUM_POPUP_HEADER_TEXT_1,
    TAB_MENU_PARENT_ID,
    unlimitedPlaysKey
} from '../../../constants';
import { ButtonWithGem } from '../../common/button/common-button-with-gem.component';
import throttle from 'lodash/throttle';

// Assets
import deviceInfo, { STORE_TYPE_EPIC } from '../../../assets/lib/deviceInfo';
import { addPopup } from '../../popup/popup.component';
import SubscriptionComparisonsPopup from '../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component';
import {
    isFreemiumAppleIosUser,
    isFreemiumV4UserNotSubscribed,
    isFreeTierV2FeatureFlagEnabled,
    isXboxUser
} from '../../../assets/lib/utils';
import { startNativePayment } from '../../../assets/lib/game-wrapper';

const PAGE_ID = 'game_info_1';

export const externalFocusableComponent = {
    PLAY_BUTTON: 'playButton'
} 

// Note this isn't recommended as a reliable solution and is just a basic way of designed to dynamically shrink text to fit
const getFontSize = (charactersLength, startingFontSize, maxCharacterPerLine, smallestAcceptableFontSize = 0.1, incrementsDown = 0.1, depth = 0) => {
    if(charactersLength <= maxCharacterPerLine) {
        // if we now fit we should calculate the size and return it
        // TODO the amount we decrease is an exponential effect so should do a calc on shrinking it down
        const amountToDecreaseBy = incrementsDown * depth;
        const newFontSize = startingFontSize - amountToDecreaseBy;

        if(newFontSize <= smallestAcceptableFontSize) return smallestAcceptableFontSize;
        return parseFloat(newFontSize).toFixed(2);
    }
    
    return getFontSize(charactersLength - maxCharacterPerLine, startingFontSize - incrementsDown, maxCharacterPerLine, smallestAcceptableFontSize, incrementsDown, depth =+ 1);

}
class GameDetailsInfoComponent extends Component {
    constructor() {
        super();
        this.state = { imageToShow : 'one' };
        this.imagesToShow = [ 'one', 'two', 'three' ];
        this.UpvoteFetched = false;
    }

    componentDidMount() {
        const { dispatch, item } = this.props;

        dispatch(UpdateMyMenuSelection({title: 'game-info'}));
        dispatch(FetchGamePlatformsAction(item._id));
        dispatch(SetTutorialId(PAGE_ID));
        dispatch(SetGTMEvent(TYPE_GAME_VIEW, {'gameName': item.title}));

        let i=0;
        this.interval = setInterval(() => {
            const currentImage = this.imagesToShow[i];
            this.setState({ imageToShow: currentImage});
            i++;
            if (i>=this.imagesToShow.length) i=0;
        } , 5000);

        const { focusElement } = this.props.currentParameters
        focusElement && inputManager.setCurrentChildById(focusElement)

        if (!focusElement) {
            inputManager.setCurrentChildById(externalFocusableComponent.PLAY_BUTTON);
        }

        // always choose Info tab button when navigating Bottom=>Top or Top=>Bottom
        this.setAsPrioritizedNavigationItem(`${TAB_MENU_PARENT_ID}-Info`);

        if (deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) { // additional refresh rects to have consistent behaviour 
            setTimeout(() => {
                inputManager.refreshBoundingRects();
            }, 1000);
        }
    }

    componentDidUpdate() {
        if (!inputManager.currentChild) {
            inputManager.setCurrentChildById(externalFocusableComponent.PLAY_BUTTON);
        }
    }

    componentWillUnmount() {
          clearInterval(this.interval);
    }

    setAsPrioritizedNavigationItem = (childId) => {
        const tabsParent = inputManager.getParent(TAB_MENU_PARENT_ID);
        if (tabsParent && !tabsParent.currentChild) {
            inputManager.setCurrentChildOfParent(TAB_MENU_PARENT_ID, childId);
        }
    }

    showFreeTierV2UserPopup = () => {
        addPopup(<SubscriptionComparisonsPopup
            customClassName={'subscription-comparisons-popup-1300'}
            leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_1}
            pageId={PAGE_ID}
        />);
    }

    prepareGame = () => {
        const isNotAllowed = this.shouldBlockExecution();
        if (isNotAllowed) return;

        const id = this.props.item._id;

        AudioManager.playAudio(TYPE_GAMEDETAIL_PLAY_BUTTON);
        if (!this.props.item?.tags?.includes("gpudemo")) {
            this.props.dispatch(RunGameAction({
                gameId: id,
            }));
        }
        navigateToLocation(ROUTES.HOW_TO_PLAY, { id, gameType: GAME_TYPE.BASIC });
    };

    favouriteOn = () => {
        const isNotAllowed = this.shouldBlockExecution();
        if (isNotAllowed) return;

        const id = this.props.item._id;
        this.props.dispatch(FollowGameAction(id));
    };

    favouriteOff = () => {
        const isNotAllowed = this.shouldBlockExecution();
        if (isNotAllowed) return;

        const id = this.props.item._id;
        this.props.dispatch(UnfollowGameAction(id));
    };

    toggleUpvote = () => {
        const id = this.props.item._id;
        if (this.props.isUpvoted) {
            this.props.dispatch(RemoveUpvoteGameAction(id));
        } else {
            this.props.dispatch(AddUpvoteGameAction(id));
        }
    };

    changePlatform = (val) => {
        const isNotAllowed = this.shouldBlockExecution();
        if (isNotAllowed) return;

        const platformGame = find(this.props.platforms, {'platform': val});
        navigateToLocation(ROUTES.GAME_INFO, {id: platformGame._id});
    };

    shouldDisplayShareButton = () => {
        if (this.props.isXboxPlayer) return false;
        if (deviceInfo.isPlayStationPlatform()) return false;

        return true;
    };

    renderPlay = () => {
        const { isUserFreeTier, gamesLeftValue, item, gemPlayGameCost, isFreemiumV4User, isFreemiumAppleIos } = this.props
        const playButtonText = "Play";
        let fullPlayButtonText = isUserFreeTier ? `${playButtonText} (${gamesLeftValue})` : playButtonText;

        const showPremiumBenefits = !this.props.isSubscribed;
        const isFreemiumV2User = !this.props.isSubscribed && this.props.isFreeTierV2FeatureEnabled && this.props.isPostAuthFetched;

        if (isFreemiumV2User || isFreemiumV4User || isFreemiumAppleIos) {
            fullPlayButtonText = 'Play on Premium';
        }

        return (
            <div className={`buttons-container ${showPremiumBenefits ? 'show-premium-benefits' : ''}`}>
                <ButtonWithGem
                    className={`primary two-line ${(isFreemiumV2User || isFreemiumV4User || isFreemiumAppleIos) ? 'freemium-v2-benefits' : ''}`}
                    onClick={this.prepareGame}
                    cost={(isFreemiumV2User || isFreemiumV4User || isFreemiumAppleIos) ? null : gemPlayGameCost}
                    childId = "playButton"
                    premiumBenefitsText={showPremiumBenefits ? 'Free to play on Premium' : null}
                >
                    {fullPlayButtonText}
                </ButtonWithGem>
                <ButtonFavourite
                    stopBounceAnimation={deviceInfo.isPlayStationPlatform()}
                    disabled={deviceInfo.isPlayStationPlatform() ? false : this.props.isFollowGamePending}
                    selected={this.props.isFavourite}
                    throttleMs={1500}
                    onClick={this.props.isFavourite ? this.favouriteOff : this.favouriteOn}
                    childId = "favouriteButton"
                />

                {this.shouldDisplayShareButton() &&
                    <ShareButton
                        text={`Remember ${item.title}? See if you can beat my score!`}
                        deepLink={generateDeepLinkURL(DEEP_LINK_TARGET.GAME_INFO, {[DEEP_LINK_PARAMETER.GAME_ID]: item._id})}
                    />}
            </div>
        );
    };


    renderUpvote = () => {
        const { isUpvoted, totalVotes } = this.props;

        const totalText = totalVotes === 1 ? 'vote' : 'votes';

        return (
            <div className="buttons-container">
                <ButtonUpvote
                    className={`${isUpvoted ? 'primary' :  'secondary'}`}
                    onClick={this.toggleUpvote}
                    childId = "upVoteButton"
                >
                    Upvote
                </ButtonUpvote>
                <p className="total-votes">{`${totalVotes} ${totalText}!`}</p>
            </div>
        );
    };

    shouldBlockExecution = () => {
        if (deviceInfo.isPlayStationPlatform()) return false;

        const { isPostAuthFetched, isSubscribed, isFreeTierV2FeatureEnabled } = this.props;

        if (this.props.isFreemiumAppleIos) {
            startNativePayment(APPLE_IOS_SKU);
            return true;
        }

        if (this.props.isFreemiumV4User) {
            startPaymentProcess(false);
            return true;
        }

        if (isFreeTierV2FeatureEnabled) {
            if (!isPostAuthFetched) return true;
            if (!isSubscribed) {
               this.showFreeTierV2UserPopup();
                return true;
            }
        }

        return false;
    }

    onSaveButtonClicked = (selectedSaveStateData) => {
        const isNotAllowed = this.shouldBlockExecution();
        if (isNotAllowed) return;

        const id = this.props.item._id;
        this.props.dispatch(RunGameAction({
            gameId: id,
            slot:selectedSaveStateData.slot,
            region:selectedSaveStateData.region,
        }));
        navigateToLocation(ROUTES.HOW_TO_PLAY, { id, gameType: GAME_TYPE.BASIC });
    };

    renderSaveButtons = () => {
        const {savedStates} = this.props.item;
        const buttonsArray = [];

        // slot 0: 'Continue'
        // slot 1: 'Slot 1'
        // slot 2: 'Slot 2'
        // slot 3: 'Slot 3'
        // "non premium" users cannot load or save
        for(let slot=0;slot<4;slot++) {
            const data = savedStates ? savedStates.find(savedStateData=>savedStateData.slot===slot) : null;
            const showPremiumBenefits = !this.props.isSubscribed;
            buttonsArray.push(
                <SaveButton
                    data={data}
                    key={slot}
                    slot={slot}
                    onClick={this.onSaveButtonClicked}
                    notSelectable={!data || showPremiumBenefits && deviceInfo.storeType === STORE_TYPE_EPIC}
                    disabled={!data}
                    premiumBenefitsText={showPremiumBenefits ? 'Only on Premium' : null}
                    isInGameDetailPage
                    childId={'saveButton'+slot}
                />
            );
        }

        return buttonsArray;
    };

    render() {
        const publisherLogo = get(this.props, 'item.publisherLogo.url');
        const {backCatalogue,comingSoon, hasMultiplayer, hasHighScore, item={}, currentPlatformIndex, platformNames, isSubscribed}  = this.props;
        
        const germanyAgeIcon = this.props.isUserInGermany ? getGermanyAgeIcon(this.props.item) : null;
        const {_id, year_image_uri, genre_image_uri, legal_notices, elevator_pitch, tags, studio, featured_image_uri, screenshot_1, screenshot_2} = item;
        const year2Digits = year_image_uri?.slice(-6, -4);
        const genreIconUri = genre_image_uri?.replace('.svg', '-notext-01.png');
        const genreText = genre_image_uri?.split('/').slice(-1)[0].split('.svg')[0];
        const legalInfoSize = legal_notices && getFontSize(legal_notices.length, 0.8, 100, 0.2);
        return (
            <div className="game-details-info-component">
                <Helmet
                    image={featured_image_uri}
                />
                <div className="content-left">
                    <div className="content-top">
                        <p className="description">
                            {elevator_pitch.toString()}
                        </p>
                        {!backCatalogue && !comingSoon && featureInfo.isSupported(FEATURES.GAMEPLAY) && this.renderPlay()}
                        {backCatalogue && this.renderUpvote()}
                    </div>
                    {
                        (tags.includes("savestates") && featureInfo.isSupported(FEATURES.SAVE_STATES)) ? (
                            <div className="content-middle">
                                <div className="save-buttons-container">
                                    {this.renderSaveButtons()}
                                </div>
                            </div> ) : null
                    }
                    <div className="content-bottom">
                        <div className="buttons-container">
                            <DirectionalButton
                                className={platformNames.length<2?'secondary':'primary'}
                                notSelectable = {platformNames.length<2}
                                values={platformNames}
                                onChange={this.changePlatform}
                                selectedValueIndex={currentPlatformIndex}
                                childId = "platformButton"
                            />
                        </div>
                        <div className="logo-year-genre">
                            <Icon
                                text="year"
                                largeText={year2Digits}
                            />
                            <Icon
                                text="genre"
                                iconUrl={genreIconUri}
                                iconAltText={genreText}
                            />
                            {publisherLogo && <img className="publisher" src={publisherLogo} alt={studio} />}
                            {!publisherLogo && <p className="publisherText">{studio}</p>}
                            {hasMultiplayer &&
                            <Icon
                                text="multiplayer"
                                iconUrl={multiplayerIcon}
                                iconAltText="multiplayer"
                            />
                            }
                            {hasHighScore && <img className="highScoreIcon" src={highScoreIcon} alt="highScoreIcon" />}
                            {germanyAgeIcon && <img className="germanyAgeIcon" src={germanyAgeIcon} alt="germanyAgeIcon"/>}
                        </div>
                    </div>
                </div>
                <div className={`image-container ${this.state.imageToShow} ${legal_notices ? 'hasLegalNotices' : ''}`}>
                    <img className="one" src={featured_image_uri} alt="cover" />
                    <img className="two" src={screenshot_1} alt="screenshot 1" />
                    <img className="three" src={screenshot_2} alt="screenshot 2" />
                    {legal_notices && legalInfoSize &&
                        <p className="legal-notices" style={{fontSize: `${legalInfoSize}em`}}> {legal_notices}</p>
                    } 
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const platforms = getCurrentGamePlatforms(state);
    const platformNames = platforms.map(p => p.platform);
    const item = getCurrentGameDetails(state);
    const isSubscribed = getUserIsSubscribed(state);

    var tags = get(item, 'tags', []);
    if (!Array.isArray(tags)) {
        tags = [];
    }

    const backCatalogue  = tags.includes('bc') || tags.includes('bcg');
    const comingSoon     = tags.includes('cs');
    const hasMultiplayer = tags.includes('multiplayer2player2pads');
    const hasHighScore   = tags.includes('leaderboard');
    const isUserFreeTier = isFreeTier(state) && getFreeTierGamesInformation(state).limit !== unlimitedPlaysKey

    return {
        isXboxPlayer: isXboxUser(state),
        isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
        isFreemiumAppleIos: isFreemiumAppleIosUser(state),
        isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
        isPostAuthFetched: getIsPostAuthFetched(state),
        item,
        platforms,
        platformNames,
        isUserFreeTier,
        gamesLeftValue: isUserFreeTier ? getGamesLeft(state) : 0,
        isFollowGamePending: getIsFollowGamePending(state),
        isFavourite: getIsCurrentGameFavourite(state),
        currentPlatformIndex: platforms.findIndex((platform)=>platform._id===item._id),
        isUpvoted: getIsUpvoted(state),
        totalVotes: getTotalVotes(state),
        remainingVotes: getRemainingVotes(state),
        user: getLoggedUser(state),
        isSubscribed,
        backCatalogue,
        comingSoon,
        hasMultiplayer,
        hasHighScore,
        isUserInGermany: getIsUserInGermany(state),
        currentParameters: getRoutingSearch(state),
        gemPlayGameCost: getGemPlayGameCost(state)
    };
};

export const GameDetailsInfo = connect(mapStateToProps)(GameDetailsInfoComponent);
