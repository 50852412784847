import { createSelector } from 'reselect';
import { getAllMessagesIds, getAllMessagesById } from '../../entities/entities.selectors';
export const getSelectedMessageId = state => state.inbox.selectedMessageId;
export const getIsLoading = state => state.inbox.loading;
export const getMessagesUpdateId = state => state.inbox.messagesUpdateId;

export const getMessages = createSelector(
	getAllMessagesById,
	getAllMessagesIds,
	(byId, ids) => {
		if (!byId || !ids) return [];
		return ids.map(id => byId[id]);
	}
);

export const getSelectedMessage = createSelector(
	getAllMessagesById,
	getSelectedMessageId,
	(usersById, id) => {
		if (!usersById || !id) return {};
		return usersById[id] || {};
	}
);

