import React from 'react'
import {getTrueAuthCredentials} from "../../assets/lib/RestAPI";
import {FetchPaymentDataActionSuccess, LoginUserAction} from "../login/login.actions";
import {getUsersLocationData} from "../../assets/lib/utils";
import {antstreamService} from "../../app.reducer";
import {addPopup} from "../popup/popup.component";
import GenericPopup from "../popup/generic-popup/generic-popup.component";

export const trueAuthenticationLogout = () => {
	const path = `${window.config.REACT_APP_TRUE_API_URL}/signout?client_id=${window.config.REACT_APP_TRUE_CLIENT_ID}&return_to=${window.config.REACT_APP_URL}`;
	window.location = path;
}

export const FETCH_TRUE_AUTH_CREDENTIALS_ACTION = 'FETCH_TRUE_AUTH_CREDENTIALS_ACTION';
export const fetchTrueAuthCredentialsAction = (authCode, deviceId, deviceModel) => {
	return (dispatch) => {
		dispatch({type: FETCH_TRUE_AUTH_CREDENTIALS_ACTION});
		getUsersLocationData().then((response) => {
			const {latitude, longitude, ip} = response;
			getTrueAuthCredentials(authCode, deviceId, deviceModel, ip, latitude, longitude).then(({userName, password, promoCode}) => {
				dispatch(fetchTrueAuthCredentialsActionSuccess(userName, password));
				dispatch(LoginUserAction({email: userName, password, promoCode}));
			}).catch((error) => {
				dispatch(fetchTrueAuthCredentialsActionFail(error));
			})
		}).catch((error)=> {
			console.log("getUsersLocationDataError: ", error);
		});
	};
};

export const FETCH_TRUE_AUTH_CREDENTIALS_ACTION_SUCCESS = 'FETCH_TRUE_AUTH_CREDENTIALS_ACTION_SUCCESS';
export const fetchTrueAuthCredentialsActionSuccess = (userName, password) => {
	return (dispatch) => {
		const payload = {
			userName,
			password
		}
		dispatch({type: FETCH_TRUE_AUTH_CREDENTIALS_ACTION_SUCCESS, payload});
	};
};

export const FETCH_TRUE_AUTH_CREDENTIALS_ACTION_FAIL = 'FETCH_TRUE_AUTH_CREDENTIALS_ACTION_FAIL';
export const fetchTrueAuthCredentialsActionFail = (error) => {
	return (dispatch) => {
		dispatch({type: FETCH_TRUE_AUTH_CREDENTIALS_ACTION_FAIL, error});
	};
};


export const TRUE_CREATE_SUBSCRIPTION_ACTION = 'TRUE_CREATE_SUBSCRIPTION_ACTION';
export const createSubscription = (sourceId, sku) => {
	return (dispatch) => {
		dispatch({type: TRUE_CREATE_SUBSCRIPTION_ACTION, payload:{sourceId, sku}});
		antstreamService
			.createTrueSubscription(sourceId, sku)
			.subscribe(
				(response) => {
					const isSuccessfullPayment = response.status.subscriptionInformation === true || response.status.subscriptionInformation === 'SUCCESSFUL'
					if(isSuccessfullPayment) { 
						dispatch(createSubscriptionSuccess(response));
					} else {
						addPopup(<GenericPopup
							okButtonLabel="Failed"
							title="Subscription Failed"
							message="Something went wrong. Please come back later."
						/>);
					}
				},
				(error) => {
					dispatch(createSubscriptionFail(error));
				}
			);
	};
};

export const TRUE_CREATE_SUBSCRIPTION_ACTION_SUCCESS = 'TRUE_CREATE_SUBSCRIPTION_ACTION_SUCCESS';
const createSubscriptionSuccess = (response) => {
	return (dispatch) => {
		dispatch({type: TRUE_CREATE_SUBSCRIPTION_ACTION_SUCCESS, response});
		dispatch(FetchPaymentDataActionSuccess(response.paymentData));
		addPopup(<GenericPopup
			okButtonLabel="Ok"
			title="Subscription Success"
			message="A Subscription has been created."
		/>);
	};
};

export const TRUE_CREATE_SUBSCRIPTION_ACTION_FAIL = 'TRUE_CREATE_SUBSCRIPTION_ACTION_FAIL';
const createSubscriptionFail = (error) => {
	return (dispatch) => {
		dispatch({type: TRUE_CREATE_SUBSCRIPTION_ACTION_FAIL, error});
		addPopup(<GenericPopup
			okButtonLabel="Failed"
			title="Subscription Failed"
			message="Something went wrong. Please come back later."
		/>);
	};
};

export const TRUE_VERIFY_SOURCE_ACTION = 'TRUE_VERIFY_SOURCE_ACTION';
export const verifySource = (sourceId, sku) => {
	return (dispatch) => {
		dispatch({type: TRUE_VERIFY_SOURCE_ACTION, payload:{sourceId}});
		antstreamService
			.verifyTrueTvSource(sourceId)
			.subscribe(
				(response) => {
					dispatch(verifySourceSuccess(response, sourceId, sku));
				},
				(error) => {
					dispatch(verifySourceFail(error));
				}
			);
	};
};

export const TRUE_VERIFY_SOURCE_ACTION_SUCCESS = 'TRUE_VERIFY_SOURCE_ACTION_SUCCESS';
const verifySourceSuccess = (response, sourceId, sku) => {
	return (dispatch) => {
		dispatch({type: TRUE_VERIFY_SOURCE_ACTION_SUCCESS, response});
		dispatch(createSubscription(sourceId, sku));
	};
};

export const TRUE_VERIFY_SOURCE_ACTION_FAIL = 'TRUE_VERIFY_SOURCE_ACTION_FAIL';
const verifySourceFail = (error) => {
	return (dispatch) => {
		dispatch({type: TRUE_VERIFY_SOURCE_ACTION_FAIL, error});
	};
};



export const TRUE_CANCEL_SUBSCRIPTION_ACTION = 'TRUE_CANCEL_SUBSCRIPTION_ACTION';
export const cancelSubscription = () => {
	return (dispatch) => {
		dispatch({type: TRUE_CANCEL_SUBSCRIPTION_ACTION});
		antstreamService
			.cancelTrueTvSubscription()
			.subscribe(
				(response) => {
					dispatch(cancelSubscriptionSuccess(response));
				},
				(error) => {
					dispatch(cancelSubscriptionFail(error));
				}
			);
	};
};

export const TRUE_CANCEL_SUBSCRIPTION_ACTION_SUCCESS = 'TRUE_CANCEL_SUBSCRIPTION_ACTION_SUCCESS';
const cancelSubscriptionSuccess = (response) => {
	return (dispatch) => {
		dispatch({type: TRUE_CANCEL_SUBSCRIPTION_ACTION_SUCCESS, response});
		response.paymentData && dispatch(FetchPaymentDataActionSuccess(response.paymentData));
		addPopup(<GenericPopup
			okButtonLabel="Ok"
			title="Subscription Cancelled"
			message="Your subscription has been cancelled!"
		/>);
	};
};

export const TRUE_CANCEL_SUBSCRIPTION_ACTION_FAIL = 'TRUE_CANCEL_SUBSCRIPTION_ACTION_FAIL';
const cancelSubscriptionFail = (error) => {
	return (dispatch) => {
		dispatch({type: TRUE_CANCEL_SUBSCRIPTION_ACTION_FAIL, error});
	};
};
