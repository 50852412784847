import {
	FetchGameChallengesAction,
	RunGameAction,
} from '../game-details/game-details.actions';
import { navigateToLocation, ROUTES } from '../../app.router';
import { getAssetsUrlWithFolder, getFreeTierTournamentsInformation, getGemPlayTournamentsCost, getIsInterimScoreEnabled, getTournamentsLeft, isFreeTier} from '../../app.selectors';
import {antstreamAPIService, antstreamService} from '../../app.reducer';
import {getChallengesHistory, getIssuedChallenges} from '../my-profile/my-profile.selectors';
import {challengeHistoryObjectNormalizer} from '../../entities/entities.normaliser';
import TournamentResultPopup, {TOURNAMENT_RESULT_TYPES} from '../popup/tournament-result-popup/tournament-result-popup.compontent';
import {addPopup} from '../popup/popup.component';
import React from 'react';
import {appendAssetsUrlToTournament} from "../tournaments/tournaments.actions";
import {externalFocusableComponent} from "../leaderboard/leaderboard.component";
import {FOLDER_TYPES} from "../../assets/lib/FolderTypes";
import {UpdateChallengesData} from "../../entities/challenges/challenges.actions";
import { unlimitedPlaysKey } from '../../constants';
import {readRestUserData} from "../../assets/lib/local-storage";
import GenericPopup from "../popup/generic-popup/generic-popup.component";
import {generateUUID} from "../../app.helpers";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {webSocketErrorHandling} from "../../assets/lib/ErrorTypes";
import {getChallengeById} from '../../entities/entities.selectors';
import {getLoggedUserAccess} from '../../app.selectors';
import {handleNoAuthTokenIssue} from '../../assets/lib/utils';

export const FETCH_OPPONENTS = 'Fetch Opponents Action';
export const FETCH_OPPONENTS_ERROR = 'Fetch Opponents Action Error';
export const FetchOpponentsAction = (searchTerm, onComplete, isChallengeOpponentsSearch, challengeId) => {
	return (dispatch) => {
		dispatch({ type: FETCH_OPPONENTS });

		const { authToken, userId } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(FETCH_OPPONENTS);

		const params = {
			headers: { Authorization: 'Bearer ' + authToken },
			body: {
				operation: 'searchPlayers',
				data: {
					userId,
					isChallengeOpponentsSearch,
					challengeId,
					searchTerm,
				}
			}
		};

		antstreamAPIService.player.searchCreate(params)
			.then(({ data }) => {
				if (!data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);

				dispatch(FetchOpponentsSuccessAction(data.body.list));
				if (onComplete) onComplete();
			})
			.catch(catchErr => {
				dispatch({ type: FETCH_OPPONENTS_ERROR });
				console.error('Failed to fetch opponents list: ' + catchErr.message);
			});
	};
};

export const FETCH_OPPONENTS_SUCCESS = 'Fetch Opponents Success';
const FetchOpponentsSuccessAction = (payload) => ({
	type: FETCH_OPPONENTS_SUCCESS,
	payload
});

export const SET_CURRENT_CHALLENGE_ID = 'Set Current Challenge Id';
export const setCurrentChallengeId = (gameId, challengeId) => ({
	type: SET_CURRENT_CHALLENGE_ID,
	payload:{
		query:{
			id:gameId,
			challengeId
		}
	}
});

export const SEND_CHALLENGE_REQUEST = 'Send Challenge Request Action';
export const SendChallengeRequestAction = (gameId, challengeId, opponentId) => {
	return (dispatch) => {
		dispatch({ type: SEND_CHALLENGE_REQUEST });
			const requestId = generateUUID();

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(SEND_CHALLENGE_REQUEST);

			const params = {
				body: {
					game_uuid: gameId,
					challenge_id: challengeId,
					opponent_uuid: opponentId,
					requestId: requestId 
				},
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.challenge.sendCreate(params)
				.then(({ data }) => {
					if (!data || data.result !== 'OK') throw new Error('Something went wrong');
					if (data.error) throw new Error(data.error);

					// wait for a response from websocket
					const unsubscribeMessages$ = new Subject();
					antstreamService.getWebsocketAPImessage$()
						.pipe(takeUntil(unsubscribeMessages$))
						.subscribe((resp) => {
							if (resp && resp.requestId === requestId) {
								unsubscribeMessages$.next();
								unsubscribeMessages$.complete();

								const parsedResp = JSON.parse(resp.scriptData.data);
								if (parsedResp.error) {
									dispatch(SendChallengeRequestFailAction(parsedResp.error));
								} else {
									dispatch(SendChallengeRequestSuccessAction(gameId, challengeId, parsedResp));
								}
							}
						});
				})
				.catch(catchErr => {
					dispatch(SendChallengeRequestFailAction(catchErr));
				});
	};
};

export const SEND_CHALLENGE_REQUEST_SUCCESS = 'Send Challenge Request Success';
const SendChallengeRequestSuccessAction = (gameId, challengeId, response) => {
	return (dispatch) => {
		const {success, challengeInstanceId} = response;
		dispatch({ type: SEND_CHALLENGE_REQUEST_SUCCESS, response });
		if(success){
			dispatch(RunGameAction({
				gameId: gameId,
				challengeId,
				challengeInstanceId,
				challengeStyle: 'intro',
			}));
		}
	};
};

export const SEND_CHALLENGE_REQUEST_FAIL = 'Send Challenge Request Fail';
const SendChallengeRequestFailAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: SEND_CHALLENGE_REQUEST_FAIL, payload });
	};
};

export const ACCEPT_CHALLENGE_REQUEST = 'Accept Challenge Request Action';
export const AcceptChallengeRequestAction = (challengeInstanceId, gameId, challengeId) => {
	return (dispatch) => {
		dispatch({ type: ACCEPT_CHALLENGE_REQUEST });
			const requestId = generateUUID();
			const {authToken} = readRestUserData();
			const params = {
				body: {
					game_uuid: gameId,
					challenge_id: challengeId,
					challenge_instance_id: challengeInstanceId,
					requestId: requestId
				},
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.challenge.acceptCreate(params)
				.then(({ data }) => {
					if (!data || data.result !== 'OK') throw new Error('Something went wrong');
					if (data.error) throw new Error(data.error);

					// wait for a response from websocket
					const unsubscribeMessages$ = new Subject();
					antstreamService.getWebsocketAPImessage$()
						.pipe(takeUntil(unsubscribeMessages$))
						.subscribe((resp) => {
							if (resp && resp.requestId === requestId) {
								unsubscribeMessages$.next();
								unsubscribeMessages$.complete();

								const parsedResp = JSON.parse(resp.scriptData.data);
								if (parsedResp.error) {
									dispatch(AcceptChallengeRequestFailAction(parsedResp.error));
								} else {
									dispatch(AcceptChallengeRequestSuccessAction(gameId, challengeId, challengeInstanceId,{success: true}));
								}
							}
						});
				})
				.catch(catchErr => {
					dispatch(AcceptChallengeRequestFailAction(catchErr));
				});
	};
};
export const ACCEPT_CHALLENGE_REQUEST_SUCCESS = 'Accept Challenge Request Success';
const AcceptChallengeRequestSuccessAction = (gameId, challengeId, challengeInstanceId, response) => {
	return (dispatch) => {
		const {success} = response;
		dispatch({ type: ACCEPT_CHALLENGE_REQUEST_SUCCESS, payload:{challengeInstanceId,response} });
		if(success){
			dispatch(RunGameAction({
				gameId: gameId,
				challengeId,
				challengeInstanceId,
				challengeStyle: 'intro',
			}));
		}
	};
};

export const ACCEPT_CHALLENGE_REQUEST_FAIL = 'Accept Challenge Request Fail';
const AcceptChallengeRequestFailAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: ACCEPT_CHALLENGE_REQUEST_FAIL, payload });
	};
};

export const SET_CHALLENGE_RESULT = 'SET_CHALLENGE_RESULT';
export const SET_RETRY_COST = 'SET_RETRY_COST';
export const SetChallengeResult = (challengeHistoryObject, tournamentId, tournamentResultObject, retryCost) => {
	return (dispatch,getState) => {
		const {challengeId, gameId} = challengeHistoryObject;
		dispatch({ type: SET_CHALLENGE_RESULT, challengeHistoryObject });
		dispatch({ type: SET_RETRY_COST, retryCost });
		const state = getState();
		const currentRoute = state.routing.currentRoute;
		const isUserFreeTier = isFreeTier(state) && getFreeTierTournamentsInformation(state).limit !== unlimitedPlaysKey;
		const getTournamentsAmountsLeft = isUserFreeTier ? getTournamentsLeft(state) : 0
		if (tournamentId && (currentRoute.path === ROUTES.IN_GAME_MENU.path || currentRoute.path === ROUTES.IN_GAME_HUD.path || currentRoute.path === ROUTES.RESULTS_WAITING.path)) {
			navigateToLocation(ROUTES.TOURNAMENTS_LEADERBOARD, {id: tournamentId, focusElement: externalFocusableComponent.PLAY_BUTTON});
			tournamentResultObject.resultType = TOURNAMENT_RESULT_TYPES.SESSION_RESULT_RANK;
			tournamentResultObject.challengeHistoryObject = challengeHistoryObject;
			tournamentResultObject.tournamentData = appendAssetsUrlToTournament(tournamentResultObject.tournamentData,getAssetsUrlWithFolder(getState(),FOLDER_TYPES.TOURNAMENTS));
			tournamentResultObject.userAccess = getLoggedUserAccess(state);

			if(tournamentResultObject.leaderBoardObject){
				addPopup(<TournamentResultPopup
					isUserFreeTier={isUserFreeTier}
					gemPlayTournamentCost={getGemPlayTournamentsCost(state)}
					getTournamentsLeft={getTournamentsAmountsLeft}
					resultObject={tournamentResultObject}
					onRetryClicked={() => {
						dispatch(PlayTournamentChallenge(gameId, challengeId, tournamentId));
					}}
					isFail={challengeHistoryObject?.isFail}
				/>, {
					/** do not show it during game playing - show it after game is finished **/
					doNotShowInInternalPopupsOnlyMode: true
				});
			}
		} else if (challengeHistoryObject.giantSlayerChallengeId) {
			// if it is already on the GIANT_SLAYER_RESULT page - ignore,
			// because it can re-render the state of that page
			if (currentRoute.path === ROUTES.GIANT_SLAYER_RESULT.path) {
				return;
			}

			navigateToLocation(ROUTES.GIANT_SLAYER_RESULT, {
				id: gameId,
				challengeId,
				giantSlayerChallengeId: challengeHistoryObject.giantSlayerChallengeId,
			});
		} else {
			navigateToLocation(ROUTES.GAME_REWARDS, {id: gameId, challengeId});
		}
	};
};

export const PLAY_TOURNAMENT_CHALLENGE = 'PLAY_TOURNAMENT_CHALLENGE';
export const PlayTournamentChallenge = (gameId, challengeId, tournamentId) => {
	return (dispatch,getState) => {
		const isInterimScoreEnabled = getIsInterimScoreEnabled(getState());

		dispatch({ type: PLAY_TOURNAMENT_CHALLENGE });
		dispatch(RunGameAction({
			gameId,
			challengeId,
			tournamentId,
			challengeStyle: isInterimScoreEnabled?'tournament':'intro'
		}));
		navigateToLocation(ROUTES.HOW_TO_PLAY, { id: gameId, challengeId });
	};
};

export const SET_SELECTED_CHALLENGE_HISTORY = 'SET_SELECTED_CHALLENGE_HISTORY';
export const SetSelectedChallengeHistory = (historyItem) => {
	return (dispatch) => {
		dispatch({ type: SET_SELECTED_CHALLENGE_HISTORY, historyItem });
		if (historyItem?.giantSlayerDetails) {
			navigateToLocation(ROUTES.GIANT_SLAYER_STATUS);
		} else if (historyItem?.challengeState === 'solo') {
			const {challengeId, gameId} = historyItem;
			dispatch(RunGameAction({
				gameId,
				challengeId,
				challengeStyle: 'intro',
			}));
			navigateToLocation(ROUTES.HOW_TO_PLAY, {id: gameId, challengeId});
		} else {
			navigateToLocation(ROUTES.MPC_STATUS);
		}
	};
};

export const CREATE_GIANT_SLAYER_CHALLENGE = 'Create Giant Slayer Challenge';
export const CreateGiantSlayerChallengeAction = (requestId, challengeId, onSuccess, onFail) => {
	return (dispatch, getState) => {
		dispatch({ type: CREATE_GIANT_SLAYER_CHALLENGE });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(CREATE_GIANT_SLAYER_CHALLENGE);

			const params = {
				body: { requestId, challengeId },
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.giantSlayer.createCreate(params)
				.then(({ data }) => {
					if (!data) throw new Error('Something went wrong');
					if (data.error) {
						webSocketErrorHandling(data.error, dispatch, getState);
						return;
					}
					onSuccess(data.data.giantSlayerChallengeId);
				})
				.catch(catchErr => {
					onFail(catchErr.message);
				});
	};
};

export const ABANDON_GIANT_SLAYER_CHALLENGE = 'Abandon Giant Slayer Challenge';
export const AbandonGiantSlayerChallengeAction = (abandonedChallengeId) => {
	return (dispatch) => {
		dispatch({ type: ABANDON_GIANT_SLAYER_CHALLENGE, payload: abandonedChallengeId });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(ABANDON_GIANT_SLAYER_CHALLENGE);

			const params = {headers: { Authorization: 'Bearer ' + authToken }};
			antstreamAPIService.giantSlayer.abandonCreate(params).then(({ data }) => {
				if (data.error) throw new Error(data.error);
				if (!data || data.result !== 'OK') throw new Error('Something went wrong');
			}).catch(gameErr => {
				// addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
			   	console.error('Failed to abandon challenges: ' + gameErr.message);
			});
	};
};

export const MARK_GIANT_SLAYER_CHALLENGE_AS_SLAYER = 'MARK_GIANT_SLAYER_CHALLENGE_AS_SLAYER';
export const MarkGiantSlayerChallengeAsSlayer = (giantSlayerChallengeId) => ({
	type: MARK_GIANT_SLAYER_CHALLENGE_AS_SLAYER, payload: giantSlayerChallengeId
});

export const GIANT_SLAYER_TARGET_ATTEMPTS_DONE = 'Giant Slayer Target Attempts Done';
export const GiantSlayerTargetAttemptsDoneAction = (giantSlayerChallengeId, onSuccess) => {
	return (dispatch) => {
		dispatch({ type: GIANT_SLAYER_TARGET_ATTEMPTS_DONE });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(GIANT_SLAYER_TARGET_ATTEMPTS_DONE);

			const params = {
				body: { giantSlayerChallengeId },
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.giantSlayer.completeCreate(params)
				.then(({ data }) => {
					if (data.error) throw new Error(data.error);
					if (!data || data.result !== 'OK') throw new Error('Something went wrong');

					onSuccess(true);
				})
				.catch(catchErr => {
					addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
					console.error('Failed to complete challenge: ' + catchErr.message);
				});
	};
};

export const GIANT_SLAYER_JOIN_REQUEST = 'Giant Slayer Join Request';
export const GiantSlayerJoinRequestAction = (giantSlayerChallengeId, onSuccess) => {
	return (dispatch) => {
		dispatch({ type: GIANT_SLAYER_JOIN_REQUEST });
		antstreamService
			.giantSlayerJoinRequest(giantSlayerChallengeId)
			.subscribe(
				(resp) => {
					onSuccess(resp);
				}
			);
	};
};

export const GIANT_SLAYER_RANDOM_JOIN_REQUEST = 'Giant Slayer Random Join Request';
export const GiantSlayerRandomJoinRequestAction = (onSuccess) => {
	return (dispatch) => {
		dispatch({ type: GIANT_SLAYER_RANDOM_JOIN_REQUEST });
		const {authToken} = readRestUserData();
		antstreamAPIService.challenge.processRandomCreate({
			headers: {'Authorization': "Bearer " + authToken}
		})
			.then(
				(resp) => {
					onSuccess(resp.data.data.result);
				}
			);
	};
};

export const GET_GIANT_SLAYER_CREATION_DETAILS = 'Get Giant Slayer Creation Details';
export const GetGiantSlayerCreationDetailsAction = (challengeIds, onSuccess) => {
	return (dispatch) => {
		dispatch({ type: GET_GIANT_SLAYER_CREATION_DETAILS });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(GET_GIANT_SLAYER_CREATION_DETAILS);

			const params = {
				body: { challengeIds },
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.giantSlayer.detailsCreate(params)
				.then(({ data }) => {
					if (!data) throw new Error('Something went wrong');
					if (data.error) throw new Error(data.error);
					onSuccess(data.data.challenges);
				})
				.catch(catchErr => {
					addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
					console.error('Failed to get GS details: ' + catchErr.message);
				});
	};
};

export const GET_GIANT_SLAYER_CHALLENGES_PENDING = 'Get Giant Slayer Challenges Pending';
export const GET_GIANT_SLAYER_CHALLENGES = 'Get Giant Slayer Challenges';
export const SET_GIANTS = 'Set Giants';
export const GetGiantSlayerChallengesAction = () => {
	return (dispatch, getState) => {
		dispatch({type: GET_GIANT_SLAYER_CHALLENGES_PENDING});

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(GET_GIANT_SLAYER_CHALLENGES_PENDING);

			const params = {
				headers: { Authorization: 'Bearer ' + authToken }
			};
			antstreamAPIService.giantSlayer.getAllList(params)
				.then(async ({ data }) => {
					if (!data) throw new Error('Something went wrong');
					if (data.error) throw new Error(data.error);
					const resp = data.data;

					const state = getState();

					const gameIds = [];

					resp.challenges.forEach(challenge => {
						challenge._id = challenge.giantSlayerChallengeId;
						if (gameIds.includes(challenge.gameId)) return;

						// if this challenge already exists in the store - no need to fetch it all the time
						if (getChallengeById(state, challenge.challengeId)) return;

						gameIds.push(challenge.gameId);
					});


					if (!state.login.logged) {
						return;
					}


					// wait until all challenges that we need for giant slayer list are fetched
					// this is important in order to avoid navigation issue with the giant-slayer list
					let waitForGamesFetchedCount = 0;
					await new Promise((resolve) => {
						if (!gameIds.length) {
							resolve();
						} else {
							gameIds.forEach(gameId => {
								waitForGamesFetchedCount++;
								dispatch(FetchGameChallengesAction(gameId, () => {
									waitForGamesFetchedCount--;
									if (waitForGamesFetchedCount <= 0) {
										resolve();
									}
								}));
							});

							if (waitForGamesFetchedCount <= 0) {
								resolve();
							}
						}
					});

					// simple debug gs challenges list change by shuffling. FOR DEBUGGING ONLY!
					// resp.challenges = resp.challenges
					// 	.map(value => ({ value, sort: Math.random() }))
					// 	.sort((a, b) => a.sort - b.sort)
					// 	.map(({ value }) => value);

					dispatch({type: GET_GIANT_SLAYER_CHALLENGES, payload: resp.challenges});
					dispatch({type: SET_GIANTS, payload: resp.giants});
				})
				.catch(catchErr => {
					// addPopup(<GenericPopup okButtonLabel="Got it!" title="Something went wrong"/>);
					console.error('Failed to get GS challenges list: ' + catchErr.message);
				});
	};
};

export const findChallengeHistoryObject = (itemId) => {
	return (dispatch,getState) => {
		let historyItem;
		const issuedChallenges = getIssuedChallenges(getState());
		historyItem = issuedChallenges.find((item)=>item._id===itemId);
		if(!historyItem) {
			const challengesHistory = getChallengesHistory(getState());
			historyItem = challengesHistory.find((item)=>item._id===itemId);
		}
		return historyItem;
	};
};

export const createMultiplayerChallengeHistoryObject = (gameId, challengeId, gameTitle, me, opponent) => {
	return (dispatch, getState) => {
		const challengeHistoryObject = {
			_id:{$oid:"temporaryId"},
			userId:me._id,
			sessionId: null,
			gameId,
			challengeId,
			challengeInstanceId: null,
			gameTitle,
			isFail: null,
			score: null,
			medals: null,
			isMultiPlayer: true,
			multiPlayerResult: {
				playerObjects:[me,opponent],
				winner: null,
				wagers: null,
				isCompleted: false,
				componentType: 'newChallengeState',
			}
		};
		return challengeHistoryObjectNormalizer(getState(),challengeHistoryObject,true);
	};
};

export const GET_CHALLENGE_RESULT = 'GET_CHALLENGE_RESULT';
export const getChallengeResultAction = (sessionId, onSuccess, onFail) => {
	return (dispatch) => {
		dispatch({ type: GET_CHALLENGE_RESULT, sessionId });

			const {authToken} = readRestUserData();
			antstreamAPIService.challenge.resultList({ sessionId },
				{headers: { Authorization: 'Bearer ' + authToken }}
			)
				.then(({ data }) => {
					if (data.error) throw new Error(data.error);
					const { challengeResult } = data.data;

					dispatch(getChallengeResultActionSuccess(challengeResult.data));
					onSuccess();
				})
				.catch(gameErr => {
					onFail(sessionId);
				});
	};
};

const getChallengeResultActionSuccess = (challengeResultData) => {
	return (dispatch, getState) => {
		const {challengeHistoryObject, challengeObject, tournamentId, tournamentResultObject, retryCost} = challengeResultData;
		dispatch(UpdateChallengesData([challengeObject]));
		if(challengeHistoryObject.isMultiPlayer) {
			challengeHistoryObject.multiPlayerResult.componentType = 'resultState';
			const normalizedChallengeHistoryObject = challengeHistoryObjectNormalizer(getState(),challengeHistoryObject,true);
			dispatch(SetSelectedChallengeHistory(normalizedChallengeHistoryObject));
		} else {
			dispatch(SetChallengeResult(
				challengeHistoryObject,
				tournamentId,
				tournamentResultObject,
				retryCost
			));
		}
	};
};

export const SET_SELECTED_GIANT_SLAYER_CHALLENGE_ID = 'Set Selected Giant Slayer Challenge Id';
export const SetSelectedGiantSlayerChallengeIdAction = (giantSlayerChallengeId) => ({
    type: SET_SELECTED_GIANT_SLAYER_CHALLENGE_ID, payload: giantSlayerChallengeId
});
