import React from 'react';

// Components
import { Button } from './common-button.component';

// CSS
import './common-button-with-epic-games-icon.component.less';

// Assets
import whiteLogo from '../../../assets/images/global/epic-games-logo-white.png';
import blackLogo from '../../../assets/images/global/epic-games-logo-black.png'

const ButtonEpicGames = (props) => {
	return (
		<Button
			{...props}
			className={`${props.className} with-epic-games ${props.inverted ? 'inverted' : ''}`}
		>
			<img className="epic-games-image" src={props.inverted ? blackLogo : whiteLogo} alt="epic games"/>
			<div className="text-container">
				{props.children}
			</div>
		</Button>
	);
};

export default ButtonEpicGames;
