import { ROUTES, navigateToLocation, getRouteForDeepLink } from '../../app.router'
import { store } from '../../configureStore';
import Elastic, {LOG_LEVEL} from '../../assets/lib/elastic.lib';
import { closePopup } from '../../components/popup/popup.component';

// The prefix used for generated deep links
const DEEP_LINK_BASE = 'https://appstart.antstream.com/index.html';

// If there's an error, we generate a link to this location.
const DEEP_LINK_DEFAULT = DEEP_LINK_BASE;   //redirect to homepage/app start

export const DEEP_LINK_TARGET = {
    HOMEPAGE:               'homepage',
    TRUE_DASHBOARD:         'true_dashboard',
    GAME_INFO:              'game',                     //Game Info, highlight on ‘play’
    CHALLENGE_INFO:         'challenge',                //Game info challenges tab, this challenge selected. Highlight on ‘play’
    TOURNAMENT_INFO:        'tournament',               //Tournament info page. Highlight on ‘join’ or ‘play’
    GAME_LEADERBOARD:       'game_leaderboard',         //Game ‘Scores’ tab, highlight on ‘play’
    CHALLENGE_LEADERBOARD:  'challenge_leaderboard',    //Challenge leaderboard, default view, highlight on ‘play’
    TOURNAMENT_LEADERBOARD: 'tournament_leaderboard'    //Tournament leaderboard, default view, highlight on ‘join’ or ‘play’
};

// Names used for the parameters in deep links
export const DEEP_LINK_PARAMETER = {
    GAME_ID:       'gameid',
    CHALLENGE_ID:  'challengeid',
    TOURNAMENT_ID: 'tournamentid',
    SUB_PAGE:      'subpage',
    PARTNER:       'partner'
};

// Values acceptable for the 'subpage' parameter
export const DEEP_LINK_SUB_PAGE_INFO        = 'info';
export const DEEP_LINK_SUB_PAGE_LEADERBOARD = 'leaderboard';

const DEEP_LINK_SUB_PAGES = [ DEEP_LINK_SUB_PAGE_INFO, DEEP_LINK_SUB_PAGE_LEADERBOARD ];
const DEFAULT_SUBPAGE = DEEP_LINK_SUB_PAGE_INFO;

// Which parameters are required for each deep link target? Which should be added?
const DEEP_LINK_REQUIRED = {
    [DEEP_LINK_TARGET.HOMEPAGE]: {
        requiredParams: [],
        addParams: {}
    },
    [DEEP_LINK_TARGET.TRUE_DASHBOARD]: {
        requiredParams: [],
        addParams: {}
    },
    [DEEP_LINK_TARGET.GAME_INFO]: {
        requiredParams: [DEEP_LINK_PARAMETER.GAME_ID],
        addParams: { [DEEP_LINK_PARAMETER.SUB_PAGE]: DEEP_LINK_SUB_PAGE_INFO }
    },
    [DEEP_LINK_TARGET.CHALLENGE_INFO]: {
        requiredParams: [DEEP_LINK_PARAMETER.GAME_ID,DEEP_LINK_PARAMETER.CHALLENGE_ID],
        addParams: { [DEEP_LINK_PARAMETER.SUB_PAGE]: DEEP_LINK_SUB_PAGE_INFO }
    },
    [DEEP_LINK_TARGET.TOURNAMENT_INFO]: {
        requiredParams: [DEEP_LINK_PARAMETER.TOURNAMENT_ID],
        addParams: { [DEEP_LINK_PARAMETER.SUB_PAGE]: DEEP_LINK_SUB_PAGE_INFO }
    },
    [DEEP_LINK_TARGET.GAME_LEADERBOARD]: {
        requiredParams: [DEEP_LINK_PARAMETER.GAME_ID],
        addParams: { [DEEP_LINK_PARAMETER.SUB_PAGE]: DEEP_LINK_SUB_PAGE_LEADERBOARD }
    },
    [DEEP_LINK_TARGET.CHALLENGE_LEADERBOARD]: {
        requiredParams: [DEEP_LINK_PARAMETER.GAME_ID,DEEP_LINK_PARAMETER.CHALLENGE_ID],
        addParams: { [DEEP_LINK_PARAMETER.SUB_PAGE]: DEEP_LINK_SUB_PAGE_LEADERBOARD }
    },
    [DEEP_LINK_TARGET.TOURNAMENT_LEADERBOARD]: {
        requiredParams: [DEEP_LINK_PARAMETER.TOURNAMENT_ID],
        addParams: { [DEEP_LINK_PARAMETER.SUB_PAGE]: DEEP_LINK_SUB_PAGE_LEADERBOARD }
    },
};

const formatDeepLinkComponent = ( parameter, value ) => {
    if (parameter !== DEEP_LINK_PARAMETER.CHALLENGE_ID) {
        return encodeURIComponent(value);
    }

    // ChallengeID needs to have the gameID removed from it.
    const StartOfChallengeValue = value.indexOf("Tier-");
    if (StartOfChallengeValue < 0) {
        console.warn("formatParameter: Challenge parameter didn't contain challenge Tier : " + value);
        return encodeURIComponent(value);
    }

    return encodeURIComponent(value.substring(StartOfChallengeValue));
}

export const generateDeepLinkURL = (target,params) => {

    //Special case of the True dashboard
    if (target === DEEP_LINK_TARGET.TRUE_DASHBOARD) {
        return 'antstream://startup?partner=true';
    }

    //Ensure it's a target for which we have known requirements
    const deepLinkRequirements=DEEP_LINK_REQUIRED[target];
    if(!deepLinkRequirements) {
        console.log(`Cannot generate deep link for unknown target ${target}`)
        return DEEP_LINK_DEFAULT;
    }

    if(deepLinkRequirements.requiredParams.length > Object.keys(params).length) {
        console.log(`Insufficient required parameters for deep link target ${target}`)
        return DEEP_LINK_DEFAULT;
    }

    //Create URI
    let uri=DEEP_LINK_BASE;
    let nextconnector='?';

    //Check for any required parameters
    for(const requirement of deepLinkRequirements.requiredParams) {
        if(!params[requirement]) {
            console.log(`Deep link target ${target} is missing parameter ${requirement}`)
            return DEEP_LINK_DEFAULT;
        }

        uri+=nextconnector+requirement+'='+ formatDeepLinkComponent(requirement,params[requirement]);

        nextconnector='&';
    }

    //Add any which should go in automatically
    for(const addition in deepLinkRequirements.addParams) {
        uri+=nextconnector+addition+'='+ formatDeepLinkComponent(addition, deepLinkRequirements.addParams[addition]);
        nextconnector='&';
    }

    // Just double-check it's actually valid!
    if (validateDeepLinkURL(uri) === null) {
        console.error("generateDeepLinkURL : Somehow I've managed to generate an invalid deep link : " + uri);
    }
    return uri;
};

//console.log(generateDeepLinkURL(DEEP_LINK_TARGET.TRUE_DASHBOARD,{}))
//console.log(generateDeepLinkURL(DEEP_LINK_TARGET.HOMEPAGE,{}))
//console.log(generateDeepLinkURL(DEEP_LINK_TARGET.GAME_INFO,{[DEEP_LINK_PARAMETER.GAME_ID]:'some uuid'}))
//console.log(generateDeepLinkURL(DEEP_LINK_TARGET.CHALLENGE_INFO,{[DEEP_LINK_PARAMETER.GAME_ID]:'some uuid',[DEEP_LINK_PARAMETER.CHALLENGE_ID]:'chall-ID'}))
//console.log(generateDeepLinkURL(DEEP_LINK_TARGET.CHALLENGE_LEADERBOARD,{[DEEP_LINK_PARAMETER.GAME_ID]:'some uuid',[DEEP_LINK_PARAMETER.CHALLENGE_ID]:'chall-ID'}))

const isValidUUID = (uuid) => {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    return pattern.test(uuid);
}

const isValidChallengeID = (uuid) => {
    const pattern = /^Tier-[0-9]+-Challenge-[0-9]+$/i
    return pattern.test(uuid);
}

const isValidTournamentID = (uuid) => {
    const pattern = /^[0-9a-f]{24}$/i
    return pattern.test(uuid);
}


export const validateDeepLinkURL = (url) => {
    if (!url) {
        return null;
    }
    
    // Remove spurious '/' from the end. Fix this properly when you figure out where
    // its coming from 
    if (url.lastIndexOf('/') === url.length-1) {
        url = url.slice(0, -1);
    }    

    // Technically a URL needs a ? before the parameters. Our schemes don't do that, so
    // make sure one's there
    const justParameters = extractLinkParameters(url);    
    const parsed = new URL("antstream://?" + justParameters);
    let validatedFields = {};

    // Extract the 'id' parameter
    const id = parsed.searchParams.get('id');
    if (id) {
        if (!isValidUUID(id)) {
            console.warn(`'id' is not a valid UUID in deep link ${url}`);
            return null;
        }
        validatedFields.id = id;
    }

    // Get the partner in the URL
    const partner = parsed.searchParams.get('partner');
    if (partner) {
        validatedFields.partner = partner;
    }

    // Extract the 'gameid' parameter
    const gameid = parsed.searchParams.get('gameid');
    if (gameid) {
        if (!isValidUUID(gameid)) {
            console.warn(`'gameid' is not a valid UUID in deep link ${url}`);
            return null;
        }
        validatedFields.gameid = gameid;
    }

    // Extract the 'challengeid' parameter
    const challengeid = parsed.searchParams.get('challengeid');
    if (challengeid) {
        if (!isValidChallengeID(challengeid)) {
            console.warn(`'challengeid' is not a valid UUID in deep link ${url}`);
            return null;
        }
        validatedFields.challengeid = gameid + challengeid;
    }

    // Extract the 'tournamentid' parameter
    const tournamentid = parsed.searchParams.get('tournamentid');
    if (tournamentid) {
        if (!isValidTournamentID(tournamentid)) {
            console.warn(`'tournamentid' is not a valid UUID in deep link ${url}`);
            return null;
        }
        validatedFields.tournamentid = tournamentid;
    }    

    // Remember (optional) subpage
    const subpage = parsed.searchParams.get('subpage');
    if (subpage) {
        if (DEEP_LINK_SUB_PAGES.indexOf(subpage) === -1) {
            console.warn(`Navigation attempt to invalid subpage in deep link ${url}`)
            return null;
        }
        validatedFields.subpage = subpage;
    } else {
        validatedFields.subpage = DEFAULT_SUBPAGE;
    }

    // The partner is mandatory if there's no 'id' in the URL
    if (!id && !partner && !gameid && !tournamentid ) {
        console.warn(`'id' and 'partner' or game/tournament id all missing in deep link ${url}`)
        return null;
    }

    // You can't have a tournament, challenge AND a game. Get real.
    if (gameid && tournamentid && challengeid) {
        console.warn(`'Kitchen sink provision of parameters in deep link ${url}`)
        return null;
    }

    return validatedFields;
}


const indexBeyondEnd = (searchString, subString) => {
    const start = searchString.lastIndexOf(subString);
    if (start < 0) {
        return start;
    }
    return start + subString.length;
}

const extractLinkParameters = (deeplink) => {
    if (!deeplink) {
        return "";
    }

    let start = indexBeyondEnd(deeplink, 'antstream://');
    if (start === -1) start = indexBeyondEnd(deeplink, 'as-arcade://');
    if (start === -1) start = indexBeyondEnd(deeplink, 'appstart.antstream.com/index.html');
    if (start === -1) start = indexBeyondEnd(deeplink, 'appstartdev.antstream.com/index.html');
    if (start === -1) {
        return "";
    }

    if (deeplink[start] === '?') {
        start++;
    }

    return deeplink.slice(start);
};

const canDeeplinkFromCurrentPage = () => {

    const currentRoute = store.getState().routing.currentRoute;
    return !(  currentRoute.path === ROUTES.HOW_TO_PLAY_KEYBOARD.path
            || currentRoute.path === ROUTES.HOW_TO_PLAY_GAMEPAD.path
            || currentRoute.path === ROUTES.HOW_TO_PLAY_TOUCH.path
            || currentRoute.path === ROUTES.HOW_TO_PLAY_FIRETV.path
            || currentRoute.path === ROUTES.HOW_TO_PLAY_REMOTE.path
            || currentRoute.path === ROUTES.HOW_TO_PLAY_INSTRUCTIONS.path
            || currentRoute.path === ROUTES.TEST_CONNECTION.path
            || currentRoute.path === ROUTES.RESULTS_WAITING.path
            || currentRoute.path === ROUTES.IN_GAME_MENU.path
            || currentRoute.path === ROUTES.WAYSUN_PAYMENT.path
            || currentRoute.path === ROUTES.TRUE_AUTH.path
            || currentRoute.path === ROUTES.SIGNUP.path
            || currentRoute.path === ROUTES.SIGNUP.path
            || currentRoute.path === ROUTES.SIGNUP_PASSWORD.path
            || currentRoute.path === ROUTES.SIGNUP_LINK.path
            || currentRoute.path === ROUTES.SIGNUP_GAMERTAG.path
            || currentRoute.path === ROUTES.SIGNUP_AVATAR.path);
}


export const tryDecodeDeepLinkAndNavigate = (deepLinkTokenURL) => {
    // Some pages will cause a right mess of controllers in the wrong state and games half-set up if we navigate
    // from them.
    if (!canDeeplinkFromCurrentPage()) {
        Elastic.logDirect(LOG_LEVEL.WARN, "Deeplink: I can't navigate safely from this page");
        return;
    }

    // validate
    const params = validateDeepLinkURL(deepLinkTokenURL);
    if (!params) {
        Elastic.logDirect(LOG_LEVEL.WARN, "Deeplink: Invalid deeplink passed in: " + deepLinkTokenURL + " : " + JSON.stringify(params));
        navigateToLocation(ROUTES.HOMEPAGE);
        return;
    }

    // removed this unnecessary validation - if gameId/tournamentId is not valid we will know about this when trying to load the game / tournament on the game-info / tournament-info pages, and will redirect user to the homepage
    // Route (if gamesparks is happy it's valid)

    if (closePopup) {
        closePopup();
    }

    const routeDetails = getRouteForDeepLink(JSON.stringify(params));
    Elastic.logDirect(LOG_LEVEL.INFO, "Deeplink accepted. Route is " + JSON.stringify(routeDetails));
    navigateToLocation(routeDetails.route, routeDetails.params);
} 
