import React, { Component } from 'react';
import SVGAnimation from '../../../assets/lib/react-bodymovin.lib';
import loader from '../../../assets/animations/loader.json';
import './loader.component.less';

const loaderAnimation = {
	loop: true,
	autoplay: true,
	prerender: true,
	animationData: loader,
	rendererSettings: { className: 'loader' }
};

class Loader extends Component {
	constructor(props) {
		super(props);
		this.state = {loading: false};
	}
	
	render() {
		if (!this.props.loading) return null;
		return (
			<div className="loader-container">
				 {this.props.loading && <SVGAnimation options={loaderAnimation} />}
			</div>
		);
	}
};

export { Loader };
