import { LOCATION_CHANGE } from 'connected-react-router';
import { paramsToObject } from '../../app.helpers';

const initialState = {
	currentId: null,
};

export const userDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
	case LOCATION_CHANGE:
		const {id} = paramsToObject(action.payload.location.search);
		return { 
			...state,
			currentId: id,
		};

	default:
		return state;
	}
};
