import {createSelector} from 'reselect';
import {getAllGamesById} from '../../entities/entities.selectors';

export const getSearchQuery = state => state.search.searchQuery;
export const getSearchGameResultIds = state => state.search.gameResults;
export const getSearchRelatedGameResults = state => state.search.relatedGameResults;
export const getSearchPlatformsResults = state => state.search.platformsResults;
export const getSearchPublisherResults = state => state.search.publisherResults;
export const getSearchYearResults = state => state.search.yearsResults;
export const getSearchGamesWithChallengeResults = state => state.search.gamesWithChallenges;
export const getSearchGenresResults = state => state.search.genresResults;
export const getSearchUserResults = state => state.search.userResults;
export const getSearchIsLoading = state => state.search.loading;
export const getAllGamesFetched = state => state.search.allGamesFetched;
export const getGameListsByFirstLetter = state => state.search.gameListsByFirstLetter;
export const getRecommendations = state => state.search.recommendations;

export const getSearchGameResults = createSelector(
	getAllGamesById,
	getSearchGameResultIds,
	(gamesById, ids) => {
		if (!gamesById || !ids) return [];
		return ids.map(id => gamesById[id]);
	}
);

export const getSearchRelatedGames = createSelector(
	getAllGamesById,
	getSearchRelatedGameResults,
	(gamesById, ids) => {
		if (!gamesById || !ids) return [];
		return ids.map(id => gamesById[id]);
	}
);

export const getSearchPlatforms = createSelector(
	getAllGamesById,
	getSearchPlatformsResults,
	(gamesById, platforms) => {
		if (!gamesById || !platforms) return [];
		return platforms.filter(Boolean).map(platform => ({
			title: platform.title,
			games: platform.games.map(id=>gamesById[id]),
		}));
	}
);

export const getSearchPublishers = createSelector(
	getAllGamesById,
	getSearchPublisherResults,
	(gamesById, publishers) => {
		if (!gamesById || !publishers) return [];
		return publishers.filter(Boolean).map(publisher => ({
			title: publisher.title,
			games: publisher.games.map(id=>gamesById[id]),
		}));
	}
);

export const getSearchYears = createSelector(
	getAllGamesById,
	getSearchYearResults,
	(gamesById, publishers) => {
		if (!gamesById || !publishers) return [];
		return publishers.filter(Boolean).map(publisher => ({
			title: publisher.title,
			games: publisher.games.map(id=>gamesById[id]),
		}));
	}
);

export const getSearchGamesWithChallenge = createSelector(
	getAllGamesById,
	getSearchGamesWithChallengeResults,
	(gamesById, gamesWithChallenges) => {
		if (!gamesById || !gamesWithChallenges) return [];
		return gamesWithChallenges.filter(Boolean).map(list => ({
			title: list.title,
			games: list.games.map(id=>gamesById[id]),
		}));
	}
);

export const getSearchGenres = createSelector(
	getAllGamesById,
	getSearchGenresResults,
	(gamesById, genres) => {
		if (!gamesById || !genres) return [];
		return genres.map(genre => ({
			title: genre.title,
			games: genre.games.map(id=>gamesById[id]),
		}));
	}
);

export const getAllGames = createSelector(
	getAllGamesById,
	getGameListsByFirstLetter,
	(gamesById, lists) => {
		if (!gamesById || !lists) return [];
		return lists.map(list => ({
			title: list.title,
			games: list.games.map(id=>gamesById[id]),
		}));
	}
);
