import React, { Component } from 'react';
import './settings-health-safety.component.less';
import {Button} from "../../common/button/common-button.component";

class SettingsHealthSafetyComponent extends Component {
	render() {
		return (
			<div className="settings-health-safety">
				<Button className='fake-focusable' childId='0'></Button>
				<div className="mb-1">
					Always play in a well-lit environment. Take regular breaks, 15 minutes every hour. Discontinue playing immediately if you experience dizziness, nausea, fatigue, or a headache.
				</div>
				<Button className='fake-focusable' childId='1'></Button>
				<div>
					Some people who are sensitive to flashing or flickering lights or geometric shapes and patterns may have an undetected epileptic condition and may experience epileptic seizures when watching television, monitors, or playing video games. Consult your doctor before playing video games if you have an epileptic condition.
				</div>
				<Button className='fake-focusable' childId='2'></Button>
			</div>
		);
	}
}

export default SettingsHealthSafetyComponent;
