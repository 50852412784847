import { createSelector } from 'reselect';
import { getChallengesById } from '../../entities/entities.selectors';

export const getUnlockChallengePending = state => state.challenge.unlockChallengePending;
export const getOpponents = state => state.challenge.opponents;
export const getChallengeId = state => state.challenge.challengeId;
export const getChallengeHistoryObject = state => state.challenge.challengeHistoryObject;
export const getSelectedChallengeHistory = state => state.challenge.selectedChallengeHistory;
export const getGiantSlayerChallenges = state => state.challenge.giantSlayerChallenges;
export const getGiants = state => state.challenge.giants;
export const getRetryCost = state => state.challenge.retryCost;

export const getCurrentChallenge = createSelector(
	getChallengesById,
	getChallengeId,
	(byId, challengeId) => {
		if (!byId || !challengeId) return {};
		return byId[challengeId] || {};
	}
);
