import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Components
import { ROUTES } from '../../../app.router';
import * as Router from '../../../app.router';
import { Button } from '../../common/button/common-button.component';
import { Input } from '../../common/input/input.component';
import { requiredValidator, emailValidator, trimValidator } from '../../common/input/input.validator';
import { SetSignupFieldAction, CheckEmailAction, CheckEmailClearAction } from '../login.actions';
import { LoginFacebookAction } from '../login.actions';
import { getWebViewDimensions } from '../../../app.selectors';
import { addPopup } from '../../popup/popup.component';
import GenericPopup from '../../popup/generic-popup/generic-popup.component';
import FacebookLoginComponent from '../../../components/facebook-login/facebook-login.component';
import EpicGamesLoginComponent from '../../../components/epic-games-login/epic-games-login.component';

// Assets
import InputManager from '../../../assets/lib/inputmanager';
import * as LocalStorage from '../../../assets/lib/local-storage';
import { checkWebViewDimensions } from '../../../assets/lib/utils';
import DeviceInfo, {OS_TYPE_IOS, PLATFORM_TYPE_ATARI, STORE_TYPE_SAMSUNG_TV} from "../../../assets/lib/deviceInfo";
import * as GameWrapper from '../../../assets/lib/game-wrapper';
import FeatureInfo, { FEATURES } from '../../../assets/lib/featureInfo';

// CSS
import '../login-global.less';

const formId = "signupForm";

class SignupComponent extends Component {
	constructor(props){
		super(props);

		this.state = {
			checkboxFocused:false,
			checkboxChecked:false
		};

		this.checked = false;
	}

	componentDidMount() {
		GameWrapper.grabControllers();
		GameWrapper.clearBackOverride();
		const { accessToken } = this.props;
		if (accessToken) {
			this.props.dispatch(LoginFacebookAction(accessToken));
			LocalStorage.writeFacebookUserData(accessToken);
		}
		InputManager.setCurrentChildById("emailInput");
		this.props.dispatch(CheckEmailClearAction());
	}

	componentWillUnmount() {
		this.props.dispatch(CheckEmailClearAction());
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.emailAccepted && !this.props.emailAccepted) {
			this.onNextClick();
		}
	}

	onNextClick = () => {
		Router.navigateToLocation(ROUTES.SIGNUP_PASSWORD);
	};

	saveForm = (values) => {
		if(this.isPrivacyPolicyChecked()) {
			this.props.dispatch(CheckEmailAction(values.email.toLowerCase()));
			this.props.dispatch(SetSignupFieldAction({email: values.email.toLowerCase()}));
		}
	};

	isPrivacyPolicyChecked = () => {
		if(this.checked) return true;
		addPopup(<GenericPopup
			okButtonLabel="Got it!"
			title="Warning"
			message="Please accept the privacy policy to continue."
		/>, {}, {force: true});
		return false;
	};

	back = () => {
		Router.navigateToLocation(ROUTES.LOGIN);
	};

	onCheckBoxClicked = (event) => {
		event.preventDefault();
		this.checked=!this.checked;
		this.setState({checkboxChecked: this.checked});
	};

	onCheckBoxFocused = (focus) => {
		this.setState({checkboxFocused: focus});
	};

	onCheckboxContainerRef = (element) => {
		InputManager.onChildRef(element,"gdprCheckBox",this.onCheckBoxFocused);
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13) {
			this.props.handleSubmit(this.saveForm)();
		}
	};

	loginWithXbox = () => {
		const redirect = window.location.origin + ROUTES.XBOX_AUTH_ACCESS_TOKEN.path;
		const url = `https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize?client_id=ad6709cb-616e-4bc5-b22c-00866b5ce3c4&response_type=code&prompt=select_account&scope=xboxlive.signin&xboxlive.offline_access&redirect_uri=${redirect}`;

		GameWrapper.openUrl(url);
	};

	render () {
		return(
			<div className={`login-wrapper ${checkWebViewDimensions(this.props.viewDimensions)}`}>
				<section className="login-section">
					<form>
						<Field
							onKeyDown={this.onKeyDown}
							name="email"
							type="email"
							component={Input}
							formId={formId}
							label="Email"
							validate={[ requiredValidator, emailValidator, trimValidator ]}
							childId="emailInput"
						/>
						{this.props.error && <div className="remote-validation-error">{this.props.error}</div>}

						<div className="privacy_policy_container">
							<div
								ref={this.onCheckboxContainerRef}
								className={`checkbox-container ${this.state.checkboxFocused?'focused':''} ${this.state.checkboxChecked?'checked':''}`}
								onClick={this.onCheckBoxClicked}
							/>
							<div className="info-container">
								I accept the privacy policy, and consent to being contacted by Antstream with updates. View privacy policy on <span className="antstream-link" >www.antstream.com/privacy</span>
							</div>
						</div>
					</form>
					<div className="buttons-container">
						<Button
							className="secondary small-button"
							onClick={this.back}
							childId="backButton"
						>
							Back
						</Button>
						<Button
							className="primary small-button"
							notSelectable={!this.props.valid}
							disabled={!this.props.valid}
							childId="nextButton"
							onClick={this.props.handleSubmit(this.saveForm)}
						>
							Next
						</Button>
					</div>
					
					{DeviceInfo.osType !== OS_TYPE_IOS && DeviceInfo.platformType !== PLATFORM_TYPE_ATARI && DeviceInfo.storeType != STORE_TYPE_SAMSUNG_TV && (
						<>
							<p className="separator-with-text">
								<span className="line"></span>
								<span className="text">or</span>
								<span className="line"></span>
							</p>
							<FacebookLoginComponent disabled={!this.checked} onClickDisabled={this.isPrivacyPolicyChecked} />
							{FeatureInfo.isSupported(FEATURES.EPIC_GAMES_LOGIN) && 
								<EpicGamesLoginComponent disabled={!this.checked} onClickDisabled={this.isPrivacyPolicyChecked} />
							}
							{FeatureInfo.isSupported(FEATURES.XBOX_LOGIN) && <Button childId="loginWithXboxButton" onClick={this.loginWithXbox} className='common-button with-xbox'>
								<div className="content">
									<div className="text-container">
										Continue with Xbox
									</div>
								</div>
							</Button>}
						</>
					)}
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const viewDimensions = getWebViewDimensions(state);

	return {
		...state.login,
		viewDimensions,
		accessToken: state.routing.search.access_token || state.routing.hash.access_token
	};
};

SignupComponent = connect(mapStateToProps)(SignupComponent);

export const Signup = reduxForm({
	form: formId,
})(SignupComponent);
