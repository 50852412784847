import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropDown from '../common/drop-down/drop-down.component';
import {getRegions} from '../settings/settings.selector';
import {GetUserRegionAction, SetUserRegionAction} from '../settings/settings.actions';
import inputManager from '../../assets/lib/inputmanager';

let lastSelected = null;

class RegionsDropdownComponent extends Component {

	componentDidMount() {
		this.props.dispatch(GetUserRegionAction(this.onRegionFetched));
	}

	onRegionFetched = () => {
		inputManager.refreshBoundingRects();
	};

	onDropDownChanged = (item, index) => {
		var regions = this.props.regions.allRegions;
		var found = regions && regions.find(function(region) { return region.name === item.value; });
		var id = found ? found.id : '00000000-0000-0000-0000-000000000000';

		if (found && found.name && found.name.includes('AUTO')) {
			const displayName = this.getDropdownEntry(found.name).name;
			found = {
				...found,
				name: displayName
			};
		}

		lastSelected=found;
		this.props.dispatch(SetUserRegionAction(id, found));
	};

	getDropdownEntry = (regionName) => {
		let displayName = '';
		if (regionName.includes('AUTO')) {
			const regionString = regionName.split(/[()]+/)[1];
			displayName = `AUTO (${regionString.trim()})`;
		} else {
			displayName = regionName;
		}
		return {
			name: displayName,
			value: regionName,
		}
	}

	render() {
		if(!this.props.regions || this.props.regions.regionLocked) return null;

		var regions;

		if (this.props.regions.allRegions) {
			regions = this.props.regions.allRegions.sort(function(r1, r2) { return r1.name.localeCompare(r2.name); });
		} else {
			regions = [];
		}

		this.regionNames = regions.map((region) => {
			return this.getDropdownEntry(region.name)
		});

		var myRegionName = (this.props.regions.myRegion && this.props.regions.myRegion.name) ? this.getDropdownEntry(this.props.regions.myRegion.name) : null;
		if(!myRegionName && lastSelected)
		{
			myRegionName=lastSelected.name;
		}

		return (
			<DropDown
				layer={this.props.layer}
				childId="regionDropdown"
				menuItems = {this.regionNames}
				defaultItem = {myRegionName}
				onChange={this.onDropDownChanged}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		regions: getRegions(state),
	};
};

export const RegionsDropdown = connect(mapStateToProps)(RegionsDropdownComponent);
