import React, {Component} from 'react';
import {connect} from "react-redux";
import {Leaderboard, LEADERBOARD_TYPES} from "../../leaderboard/leaderboard.component";
import {
	getCurrentGameDetailsId
} from "../game-details.selector";
import {RunGameAction} from "../game-details.actions";
import {navigateToLocation, ROUTES} from "../../../app.router";
import {
	isFreemiumAppleIosUser,
	isFreemiumV4UserNotSubscribed,
	isFreeTierV2FeatureFlagEnabled
} from '../../../assets/lib/utils';
import {getIsPostAuthFetched, getUserIsSubscribed} from "../../../app.selectors";
import {addPopup} from "../../popup/popup.component";
import SubscriptionComparisonsPopup from "../../popup/subscription-comparisons-popup/subscription-comparisons-popup.component";
import { APPLE_IOS_SKU, GAME_TYPE, PREMIUM_POPUP_HEADER_TEXT_1 } from '../../../constants';
import { startNativePayment } from '../../../assets/lib/game-wrapper';

const PAGE_ID = 'game_scores_1';

class GameDetailsLeaderboardComponent extends Component {
	onClickPlay = () => {
		if (this.showSubscriptionsComparisonPremiumPopup()) return;

		this.props.dispatch(RunGameAction({
			gameId: this.props.gameId,
		}));
		navigateToLocation(ROUTES.HOW_TO_PLAY, { id: this.props.gameId, gameType: GAME_TYPE.BASIC });
	}

	showSubscriptionsComparisonPremiumPopup() {
		const { isFreeTierV2FeatureEnabled, isPostAuthFetched, isUserSubscribed, isFreemiumV4User } = this.props;

		if (this.props.isFreemiumAppleIos) {
			startNativePayment(APPLE_IOS_SKU);
			return;
		}

		if (isFreemiumV4User ||
			(isFreeTierV2FeatureEnabled
				&& isPostAuthFetched
				&& !isUserSubscribed)) {
			addPopup(<SubscriptionComparisonsPopup customClassName={'subscription-comparisons-popup-1300'} leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_1} price={'£1'} pageId={PAGE_ID}/>);
			return true;
		}

		return false;
	}

	render() {
		return (
			<Leaderboard
				pageId={PAGE_ID}
				onClickPlay={this.onClickPlay}
				playButtonVisible
				leaderboardType={LEADERBOARD_TYPES.GAME_LEADERBOARD}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	const gameId = getCurrentGameDetailsId(state);

	return {
		isFreemiumAppleIos: isFreemiumAppleIosUser(state),
		isFreemiumV4User: isFreemiumV4UserNotSubscribed(state),
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state),
		isPostAuthFetched: getIsPostAuthFetched(state),
		isUserSubscribed: getUserIsSubscribed(state),
		gameId
	};
};

export const GameDetailsLeaderboard = connect(mapStateToProps)(GameDetailsLeaderboardComponent);