
export const PacketTypes = {
  CG_PACKET_INVALID: 0,              // Invalid packet
  CG_PACKET_ID: 1,                   // First packet sent on any link, identifying the peer
  CG_PACKET_VIDEO_SETUP: 2,          // Video setup information e.g. description of compression used
  CG_PACKET_VIDEO: 3,                // Video update
  CG_PACKET_AUDIO_SETUP: 4,          // Audio setup information e.g. description of compression used
  CG_PACKET_AUDIO: 5,                // Sound update
  CG_PACKET_INPUT_SETUP: 6,          // Control scheme setup for player input [type of controls etc.]
  CG_PACKET_INPUT: 7,                // Input from player(s)
  CG_PACKET_PING_REQ: 8,             // Ping request
  CG_PACKET_PING_REPLY: 9,           // Ping response
  CG_PACKET_PACKET_LOST: 10,         // Packet has been lost; please send recovery data (DO NOT REUSE THIS NUMBER - DEPRECATED)
  CG_PACKET_HELLO_RTP: 11,           // Attempt to send a message via RTP to peer
  CG_PACKET_GAME_EVENT: 12,          // A game event, e.g. sent from Lua
  CG_PACKET_PAUSE_CONTROL: 13,       // Pause/unpause the stream
  CG_PACKET_DATA_DISPLAY_SETUP: 14,  // Set up for the HUD
  CG_PACKET_CLIENTSTATS: 15,         // Client statistics data sent to server
  CG_PACKET_DATA_DISPLAY_UPDATE: 16, // New data for the HUD
  CG_PACKET_FRAME_REPORT: 17,        // Signal whether recent frames have been OK or not
  CG_PACKET_INPUT_HISTORY: 18,       // A group of CG_PACKET_INPUT payloads, each with a sequential number
  CG_PACKET_SAVE: 19,                // Trigger a save
  CG_PACKET_PAUSE_CONFIRM: 20,       // Report current server pause state
  CG_PACKET_FRAME_DEBUG_INFO: 21,    // Frame debug info
  CG_PACKET_LOCAL_PLAYER_SLOT: 22,   // Local player slot has changed (version >=2.2.0)
  CG_PACKET_SAVE_RESPONSE: 23,       // Response to a save packet
  CG_PACKET_INPUT_MAPPING: 24,       // Input mapping string segment (version >=3.0.0)
  CG_PACKET_INPUT_RAW: 25,           // Raw input to be passed into mapper (version >=3.0.0)
  CG_PACKET_INPUT_SETUP_RAW: 26,     // Setup sent to client for raw input (version >=3.0.0)
};

export const PacketTypeString = {
    CG_PACKET_INVALID: "CG_PACKET_INVALID",         // Invalid packet
    CG_PACKET_ID: "CG_PACKET_ID",                   // First packet sent on any link, identifying the peer
    CG_PACKET_VIDEO_SETUP: "CG_PACKET_VIDEO_SETUP", // Video setup information e.g. description of compression used
    CG_PACKET_VIDEO: "CG_PACKET_VIDEO",                // Video update
    CG_PACKET_AUDIO_SETUP: "CG_PACKET_AUDIO_SETUP",          // Audio setup information e.g. description of compression used
    CG_PACKET_AUDIO: "CG_PACKET_AUDIO",                // Sound update
    CG_PACKET_INPUT_SETUP: "CG_PACKET_INPUT_SETUP",          // Control scheme setup for player input [type of controls etc.]
    CG_PACKET_INPUT: "CG_PACKET_INPUT",                // Input from player(s)
    CG_PACKET_PING_REQ: "CG_PACKET_PING_REQ",             // Ping request
    CG_PACKET_PING_REPLY: "CG_PACKET_PING_REPLY",           // Ping response
    CG_PACKET_PACKET_LOST: "CG_PACKET_PACKET_LOST",         // Packet has been lost; please send recovery data (DO NOT REUSE THIS NUMBER - DEPRECATED)
    CG_PACKET_HELLO_RTP: "CG_PACKET_HELLO_RTP",           // Attempt to send a message via RTP to peer
    CG_PACKET_GAME_EVENT: "CG_PACKET_GAME_EVENT",          // A game event, e.g. sent from Lua
    CG_PACKET_PAUSE_CONTROL: "CG_PACKET_PAUSE_CONTROL",       // Pause/unpause the stream
    CG_PACKET_DATA_DISPLAY_SETUP: "CG_PACKET_DATA_DISPLAY_SETUP",  // Set up for the HUD
    CG_PACKET_CLIENTSTATS: "CG_PACKET_CLIENTSTATS",         // Client statistics data sent to server
    CG_PACKET_DATA_DISPLAY_UPDATE: "CG_PACKET_DATA_DISPLAY_UPDATE", // New data for the HUD
    CG_PACKET_FRAME_REPORT: "CG_PACKET_FRAME_REPORT",        // Signal whether recent frames have been OK or not
    CG_PACKET_INPUT_HISTORY: "CG_PACKET_INPUT_HISTORY",       // A group of CG_PACKET_INPUT payloads, each with a sequential number
    CG_PACKET_SAVE: "CG_PACKET_SAVE",                // Trigger a save
    CG_PACKET_PAUSE_CONFIRM: "CG_PACKET_PAUSE_CONFIRM",       // Report current server pause state
    CG_PACKET_FRAME_DEBUG_INFO: "CG_PACKET_FRAME_DEBUG_INFO",    // Frame debug info
    CG_PACKET_LOCAL_PLAYER_SLOT: "CG_PACKET_LOCAL_PLAYER_SLOT",   // Local player slot has changed (version >=2.2.0)
    CG_PACKET_SAVE_RESPONSE: "CG_PACKET_SAVE_RESPONSE",       // Response to a save packet
    CG_PACKET_INPUT_MAPPING: "CG_PACKET_INPUT_MAPPING",       // Input mapping string segment (version >=3.0.0)
    CG_PACKET_INPUT_RAW: "CG_PACKET_INPUT_RAW",           // Raw input to be passed into mapper (version >=3.0.0)
    CG_PACKET_INPUT_SETUP_RAW: "CG_PACKET_INPUT_SETUP_RAW",     // Setup sent to client for raw input (version >=3.0.0)
  };

export const eCGControlTypes = {
  // Bitwise constants for types of input to expect - low byte is reserved as non-bitwise value to indicate styles
  CGS_BIT_JOY0:        0x0001,   // Joystick 0 used }
  CGS_BIT_JOY1:        0x0002,   // Joystick 1 used } used at all, even if not all buttons / directions
  CGS_BIT_JOY2:        0x0004,   // Joystick 2 used }

  // Note: only first three analogues can be relative at the moment. 'Relative' is usually for
  //        mouse, trackball or paddle controls so three should be plenty.
  //       This number of analogue controls should cover a PS3 controller (2x L/R sticks + L2/R2)
  CGS_BIT_ANA0_ABS:    0x0010,   // Analogue 0 absolute
  CGS_BIT_ANA0_REL:    0x0020,   // Analogue 0 relative
  CGS_BIT_ANA1_ABS:    0x0040,   // Analogue 1 absolute
  CGS_BIT_ANA1_REL:    0x0080,   // Analogue 1 relative
  CGS_BIT_ANA2_ABS:    0x0100,   // Analogue 2 absolute
  CGS_BIT_ANA2_REL:    0x0200,   // Analogue 2 relative [future-proofing, might not be needed]
  CGS_BIT_ANA3_ABS:    0x0400,   // Analogue 3 absolute
  CGS_BIT_ANA4_ABS:    0x0800,   // Analogue 4 absolute
  CGS_BIT_ANA5_ABS:    0x1000,   // Analogue 5 absolute

  CGS_BIT_KEYBOARD:    0x4000    // General keyboard data
};

export const eCGControlStyles = {
  CGS_1_JOYSTICK:      0x01,     // Single joystick
  CGS_2_JOYSTICKS:     0x02,     // Dual joysticks, shared fire button(s)
  CGS_LIGHTGUN:        0x03,     // Lightgun game, uses analogue X/Y absolute positions
  CGS_TRACKBALL:       0x04,     // Trackball input, using e.g. mouse X/Y relative movements
  CGS_PADDLE:          0x05,     // Single (horizontal) paddle game e.g. arkanoid, warlords
  CGS_TESTAPP:         0x06,     // Input test application, send all data possible. Used by local test server.
  CGS_KBD:             0x07,     // Keyboard only
  CGS_KBD_1J:          0x08,     // Keyboard and single joystick (e.g. Spectrum joystick game)
  CGS_KBD_2J:          0x09,     // Keyboard and two joysticks (e.g. Spectrum I/F2, Amiga 2player game)
  CGS_KBD_MS:          0x0a,     // Keyboard and mouse only (e.g. Amiga desktop)
  CGS_KBD_MS_1J:       0x0b,     // Keyboard, mouse and single joystick (e.g. Amiga single player joystick game)
};

export const eCGControlFlags = {
  CGF_NONE:            0,        // No flags set
  CGF_NOMOUSETRACK:    1,        // Don't send mouse tracking movements, only send when button pressed / released
  CGF_LOCKCURSOR:      2,        // Lock cursor to window
  CGF_SHARED_KEYBOARD: 4,        // Single keyboard shared between all players. When set, read keyboard only from player 0.
  //Added 13/1/2020
  CGF_ANALOGUE_AS_ABSOLUTE: 8,   // Treat incoming analogue updates as absolute
  CGF_ANALOGUE_AS_RELATIVE: 16,  // Treat incoming analogue updates as relative
  //Added 13/5/2020
  CGF_ANALOGUE_HOST_SPECS: 32  // Require host to specify which outgoing analogue type it wants for each axis
};

export const eCGControlCursors = {
  CGCUR_DEFAULT:       0,    // No special cursor requirements
  CGCUR_NONE:          1,    // Hide cursor completely
  CGCUR_CROSSHAIR:     2     // Show crosshair instead of cursor
};

export const InputSetupRawFlags = {
    VKEYBOARD_SHIFT_STICKY: 0x0001, // Virtual keyboard shift is sticky (togglable)
    LOCK_CURSOR_TO_WINDOW: 0x0002, // Lock cursor to window (from eCGControlFlags)
    SEND_INITIAL_START: 0x0004, // Should send initial start
    BACK_BUTTON_IS_COIN: 0x0008, // the Back button (sometimes referred to as “Select”; 
                                 // the button next to Start) should behave as any other button, 
                                 // and be passed through to the mapper as the special button coin.
}

export const KeyMapping = {
  'KeyA':           0x41,
  'KeyB':           0x42,
  'KeyC':           0x43,
  'KeyD':           0x44,
  'KeyE':           0x45,
  'KeyF':           0x46,
  'KeyG':           0x47,
  'KeyH':           0x48,
  'KeyI':           0x49,
  'KeyJ':           0x4A,
  'KeyK':           0x4B,
  'KeyL':           0x4C,
  'KeyM':           0x4D,
  'KeyN':           0x4E,
  'KeyO':           0x4F,
  'KeyP':           0x50,
  'KeyQ':           0x51,
  'KeyR':           0x52,
  'KeyS':           0x53,
  'KeyT':           0x54,
  'KeyU':           0x55,
  'KeyV':           0x56,
  'KeyW':           0x57,
  'KeyX':           0x58,
  'KeyY':           0x59,
  'KeyZ':           0x5A,
  'F1':             0x80,
  'F2':             0x81,
  'F3':             0x82,
  'F4':             0x83,
  'F5':             0x84,
  'F6':             0x85,
  'F7':             0x86,
  'F8':             0x87,
  'F9':             0x88,
  'F10':            0x89,
  'F11':            0x8A,
  'F12':            0x8B,
  'Escape':         0x1B,
  'ControlLeft':    0x90,
  'ControlRight':   0x91,
  'AltLeft':        0x92,
  'AltRight':       0x93,
  'ShiftLeft':      0x8E,
  'ShiftRight':     0x8F,
  'ArrowLeft':      0x94,
  'ArrowRight':     0x95,
  'ArrowUp':        0x96,
  'ArrowDown':      0x97,
  'Digit0':         0x30,
  'Digit1':         0x31,
  'Digit2':         0x32,
  'Digit3':         0x33,
  'Digit4':         0x34,
  'Digit5':         0x35,
  'Digit6':         0x36,
  'Digit7':         0x37,
  'Digit8':         0x38,
  'Digit9':         0x39,
  'Minus':          0x2D,
  'Comma':          0x2C,
  'Period':         0x2e,
  'BracketLeft':    0x5B,
  'IntlBackslash':  0x5C,
  'Backslash':      0x5C,
  'BracketRight':   0x5D,
  'Delete':         0x7F,
  'Backspace':      0x08,
  'Tab':            0x09,
  'Space':          0x20,
  'CapsLock':       0x8D,
  'Enter':          0x0D,
};

export const XINPUT_BUTTON = {
    XINPUT_BUTTON_A: 0x00001000,      // button_a
    XINPUT_BUTTON_B: 0x00002000,      // button_b
    XINPUT_BUTTON_X: 0x00004000,      // button_x
    XINPUT_BUTTON_Y: 0x00008000,      // button_y
    XINPUT_BUTTON_LEFT_SHOULDER: 0x00000100,  // left_shoulder
    XINPUT_BUTTON_RIGHT_SHOULDER: 0x00000200, // right_shoulder
    XINPUT_BUTTON_BACK: 0x00000020,   // back
    XINPUT_BUTTON_START: 0x00000010,  // start
    XINPUT_BUTTON_LEFT_THUMB: 0x00000040,     // left_thumb
    XINPUT_BUTTON_RIGHT_THUMB: 0x00000080,    // right_thumb
    XINPUT_BUTTON_DPAD_UP: 0x00000001,        // dpad_up
    XINPUT_BUTTON_DPAD_DOWN: 0x00000002,      // dpad_down
    XINPUT_BUTTON_DPAD_LEFT: 0x00000004,      // dpad_left
    XINPUT_BUTTON_DPAD_RIGHT: 0x00000008,     // dpad_right
    XINPUT_BUTTON_META: 0x00010000,           // meta (placeholder, adjust as needed)
};

export const ControlPadMapping = {
    0:  XINPUT_BUTTON.XINPUT_BUTTON_A,
    1:  XINPUT_BUTTON.XINPUT_BUTTON_B,
    2:  XINPUT_BUTTON.XINPUT_BUTTON_X,
    3:  XINPUT_BUTTON.XINPUT_BUTTON_Y,
    4:  XINPUT_BUTTON.XINPUT_BUTTON_LEFT_SHOULDER,
    5:  XINPUT_BUTTON.XINPUT_BUTTON_RIGHT_SHOULDER,
    8:  XINPUT_BUTTON.XINPUT_BUTTON_BACK,
    9:  XINPUT_BUTTON.XINPUT_BUTTON_START,
    10: XINPUT_BUTTON.XINPUT_BUTTON_LEFT_THUMB,
    11: XINPUT_BUTTON.XINPUT_BUTTON_RIGHT_THUMB,
    12: XINPUT_BUTTON.XINPUT_BUTTON_DPAD_UP,
    13: XINPUT_BUTTON.XINPUT_BUTTON_DPAD_DOWN,
    14: XINPUT_BUTTON.XINPUT_BUTTON_DPAD_LEFT,
    15: XINPUT_BUTTON.XINPUT_BUTTON_DPAD_RIGHT,
    18: XINPUT_BUTTON.XINPUT_BUTTON_META,
};

export const InputRawType = {
  MODECHANGE:             0,
  SINGLEKEY:              1,
  POINTER:                2,
  SPECIALBUTTON:          3,
  CONTROLLER_UPDATE:      4,
  VIRTUALJOYSTICK:        5,
  CONTROLLER_UNMAPPED:    6,
  MULTIKEY:               7,
  RESETMAPPER:            8,
};

export const MAX_RAW_CONTROLS_HISTORY_BYTES = 900;
export const MAX_INPUT_MAPPING_BYTES = 900;
