import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from './app.router';
import { Splash } from './components/splash/splash.component';
import { 
    Login,
    LoginCredentials, PasswordResetRequest, ResetPassword,
    Signup,
    SignupAuthentication,
    SignupAvatar,
    SignupGamertag,
    SignupPassword
} from './components/login';
import { SignupLink } from './components/login/signup-link/signup-link.component';
import { SignupFinish } from './components/login/signup/signup-finish-registration.component';
import AuthAccessToken from './components/auth/access-token.component';
import { Homepage } from './components/homepage/homepage.component';
import DailyChallenges from './components/daily-challenges/daily-challenges.component';
import { Inbox } from './components/inbox/inbox.component';
import { Search } from './components/search/search.component';
import FreeTierInformation from './components/free-tier-information/free-tier-information.component';
import { Achievements } from './components/achievements/achievements.component';
import { GameDetailsRewards, MPCOpponents, MPCStatus } from './components/challenge';
import GiantSlayerStatus from './components/challenge/giant-slayer-status/giant-slayer-status.component';
import { PaymentSelection } from './components/payment-selection/payment-selection.component';
import { GameDetails, GameDetailsChallengeLeaderBoard } from './components/game-details';
import { HowToPlay, HowToPlayContent, HowToPlayInstructions } from './components/how-to-play';
import { UserDetails } from './components/user-details';
import { Settings } from './components/settings';
import { TournamentsList } from './components/tournaments/list/tournaments-list.component';
import { Tournaments } from './components/tournaments/tournaments.component';
import { MyProfile } from './components/my-profile';
import { ChangeAvatar } from './components/change-avatar/change-avatar.component';
import InGameComponent from './components/ingame-menu/in-game-menu.component';
import WebRTCGameComponent from './components/webrtc-game/webrtc-game.component';
import InGameHud from './components/in-game-hud/in-game-hud.component';
import ResultsWaitingComponent from './components/result-waiting/result-waiting.component';
import { TestConnection } from './components/settings/test-connection/test-connection.component';
import { history } from './configureStore';
import { getLoggedUser } from './app.selectors';

export default function () {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path={ROUTES.SPLASH.path} component={Splash} />
                <Route exact path={ROUTES.LOGIN.path} component={Login} />
                <Route exact path={ROUTES.LOGIN_CREDENTIALS.path} component={LoginCredentials} />
                <Route exact path={ROUTES.SIGNUP_LINK.path} component={SignupLink} />
                <Route exact path={ROUTES.SIGNUP.path} component={Signup} />
                <Route exact path={ROUTES.SIGNUP_PASSWORD.path} component={SignupPassword} />
                <Route exact path={ROUTES.SIGNUP_GAMERTAG.path} component={SignupGamertag} />
                <Route exact path={ROUTES.SIGNUP_AUTHENTICATION.path} component={SignupAuthentication} />
                <Route exact path={ROUTES.AUTH_ACCESS_TOKEN.path} component={AuthAccessToken} />
                <Route exact path={ROUTES.XBOX_AUTH_ACCESS_TOKEN.path} component={AuthAccessToken} />
                <Route exact path={ROUTES.PLAYSTATION_AUTH.path} component={AuthAccessToken} />
                <Route exact path={ROUTES.RESET_PASSWORD.path} component={ResetPassword} />
                <Route exact path={ROUTES.PASSWORD_RESET_REQUEST.path} component={PasswordResetRequest} />
                <PrivateRoute exact path={ROUTES.SIGNUP_COMPLETION.path} component={SignupFinish} />
                <PrivateRoute exact path={ROUTES.SIGNUP_AVATAR.path} component={SignupAvatar} />
                <PrivateRoute exact path={ROUTES.HOMEPAGE.path} component={Homepage} />
                <PrivateRoute exact path={ROUTES.DAILY_CHALLENGES.path} component={DailyChallenges} />
                <PrivateRoute exact path={ROUTES.INBOX.path} component={Inbox} />
                <PrivateRoute exact path={ROUTES.SEARCH.path} component={Search} />
                <PrivateRoute exact path={ROUTES.FREE_TIER_INFORMATION.path} component={FreeTierInformation} />
                <PrivateRoute path={ROUTES.ACHIEVEMENTS.path} component={Achievements} />
                <PrivateRoute path={ROUTES.MPC_OPPONENTS.path} component={MPCOpponents} />
                <PrivateRoute path={ROUTES.GAME_REWARDS.path} component={GameDetailsRewards} />
                <PrivateRoute path={ROUTES.GIANT_SLAYER_RESULT.path} component={GiantSlayerStatus} />
                <PrivateRoute path={ROUTES.PAYMENT_SELECTION.path} component={PaymentSelection} />
                <PrivateRoute path={ROUTES.GAME_CHALLENGE_LEADERBOARD.path} component={GameDetailsChallengeLeaderBoard} />
                <PrivateRoute path={ROUTES.GAME.path} component={GameDetails} />
                <PrivateRoute path={ROUTES.HOW_TO_PLAY.path} component={HowToPlay} />
                <PrivateRoute path={ROUTES.HOW_TO_PLAY_GAMEPAD.path} component={HowToPlayContent} />
                <PrivateRoute path={ROUTES.HOW_TO_PLAY_KEYBOARD.path} component={HowToPlayContent} />
                <PrivateRoute path={ROUTES.HOW_TO_PLAY_TOUCH.path} component={HowToPlayContent} />
                <PrivateRoute path={ROUTES.HOW_TO_PLAY_FIRETV.path} component={HowToPlayContent} />
                <PrivateRoute path={ROUTES.HOW_TO_PLAY_REMOTE.path} component={HowToPlayContent} />
                <PrivateRoute path={ROUTES.HOW_TO_PLAY_INSTRUCTIONS.path} component={HowToPlayInstructions} />
                <PrivateRoute path={ROUTES.USER.path} component={UserDetails} />
                <PrivateRoute path={ROUTES.SETTINGS.path} component={Settings} />
                <PrivateRoute exact path={ROUTES.TOURNAMENTS_LIST.path} component={TournamentsList} />
                <PrivateRoute path={ROUTES.TOURNAMENTS.path} component={Tournaments} />
                <PrivateRoute path={ROUTES.MY_PROFILE.path} component={MyProfile} />
                <PrivateRoute exact path={ROUTES.MPC_STATUS.path} component={MPCStatus} />
                <PrivateRoute exact path={ROUTES.GIANT_SLAYER_STATUS.path} component={GiantSlayerStatus} />
                <PrivateRoute exact path={ROUTES.CHANGE_AVATAR.path} component={ChangeAvatar} />
                <PrivateRoute exact path={ROUTES.IN_GAME_MENU.path} component={InGameComponent} />
                <PrivateRoute exact path={ROUTES.WEBRTC_GAME.path} component={WebRTCGameComponent} />
                <PrivateRoute exact path={ROUTES.IN_GAME_HUD.path} component={InGameHud} />
                <PrivateRoute exact path={ROUTES.RESULTS_WAITING.path} component={ResultsWaitingComponent} />
                <PrivateRoute exact path={ROUTES.TEST_CONNECTION.path} component={TestConnection} />
            </Switch>
        </Router>
    );
}

function PrivateRoute({ component: Component, ...rest }) {
    const loggedUser = useSelector(state => getLoggedUser(state));
    return (
        <Route {...rest} render={(props) => (
            loggedUser ? <Component {...props} /> : <Splash/>
        )} />
    );
}
