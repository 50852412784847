import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import './tile-achievement.component.less';
import ProgressProvider from '../circular-progress-bar/progress-provider/progress-provider.component'

import {PulsarComponent} from '../pulsar/pulsar.component';
import { isLowSpecDevice }  from '../../../app.helpers'
import {ProgressCircle} from "../progress-circle/progress-circle.component";
import deviceInfo from '../../../assets/lib/deviceInfo';

const TileAchievements = (props) => {
	const { item, selected, isXboxPlayer } = props;
	const completed = item.unlocked ? 'completed' : 'percentage';
	const description = item.unlocked ? item.description_earned : item.description_unearned;
	let progress = 0;
	if (!item.unlocked) {
		progress = Math.min(parseInt(item.player_score / item.target_score * 100, 10), 100);
	}

	const isLowSpecCircle = isXboxPlayer || deviceInfo.isPlayStationPlatform();

	return (
		<div className={`tile-achievement-component tier-${item.tier} ${completed} ${selected?'selected':''}`}>
			<div className="top">
				<div className="progress">
					<div className="bg-img">
						{item.img_src && <img src={item.img_src} alt="" draggable="false" />}
					</div>
					<div className="progress-outer">
						<div className="circle">
							{isLowSpecCircle
								? <ProgressCircle progressCircleCssClass='tile-achievement-progress-circle' expPercentage={progress/100} startFromDeg={0} isAchievementsPage={true} />
								: (<ProgressProvider valueStart={!isLowSpecDevice() ? 0 : progress} valueEnd={progress}>
									{value => <CircularProgressbar value={value} strokeWidth={5}/>}
								</ProgressProvider>)}
							<span className="number">{progress}%</span>
						</div>
					</div>
				</div>
				<p className="achievement-title">{item.title}</p>
			</div>
			<div className="bottom">
				<div className="top-line">
					<div className="line left"></div>
					<div className="line right"></div>
				</div>
				<div className="description"><p>{description}</p></div>
				<div className="bottom-line">
					<div className="corner left"><span></span></div>
					<div className="middle-line"></div>
					<div className="corner right"><span></span></div>
				</div>
			</div>
			<PulsarComponent/>
		</div>
	);
};

export default TileAchievements;
