import React, { Component } from 'react';
import { connect } from 'react-redux';
import {ROUTES, navigateToLocation, getCurrentLocation, navigateBack, getLastHistoryLocation} from '../../app.router';
import { GAME_STATE, GAME_TYPE } from '../../constants';
import { TabMenu } from '../common/tab-menu/tab-menu.component';
import { Button } from '../common/button/common-button.component';
import {
	getCurrentGameDetails,
	getCurrentGameDetailsId,
	getGameState,
	getChallengeImages,
	requestAlreadyFailed,
	requestAlreadyCancelled,
} from '../game-details/game-details.selector';
import {getCurrentChallenge, getGiantSlayerChallenges} from '../challenge/challenge.selectors';
import {addGameToPlayAgainList, FetchGameDetailed} from '../../entities/games/games.actions';
import {
	UnpauseGameAction,
	SetGameQuit,
	CancelledRequestAction,
	HideHowToPlay,
} from '../game-details/game-details.actions';
import { AbandonGiantSlayerChallengeAction, GetGiantSlayerCreationDetailsAction } from '../challenge/challenge.actions';
import { getCurrentGame, getContentByType } from './how-to-play.selector';
import {
	getAssetsUrlWithFolder,
	getRunningSessionId,
	getRunningServerIP,
	getRunningServerPort,
	getLatestRequestId,
	getLoggedUserAccess,
	getRunningGameConfigObject,
	getRunningGameChallengeStyle,
	getRunningWebsocketServerPort
} from '../../app.selectors';
import inputManager from '../../assets/lib/inputmanager';
import SVGAnimation from '../../assets/lib/react-bodymovin.lib';
import threeDotJson from '../../assets/animations/threeDot.json';
import AudioManager, {TYPE_FINAL_PLAY_BUTTON, TYPE_GIANT_SLAYER} from '../../app.audio';
import {addPopup} from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import {SetGTMEvent, TYPE_GAME_HOW_TO_PLAY, TYPE_GAME_PLAY_STARTED} from '../../gtm.actions';
import deviceInfo, {PLATFORM_TYPE_FIRE_TV} from '../../assets/lib/deviceInfo';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import { cancelledRequestEvent, checkMultiplayerGameForXbox, configureInterface } from '../../assets/lib/game-wrapper';
import { readRestUserData, readUserData } from '../../assets/lib/local-storage';
import { defaultValues as defaulControlValues } from '../ingame-menu/control-menu/control-menu.component';
import './how-to-play.component.less';
import { externalFocusableComponent } from "../game-details/info/game-details-info.component";

import challengeAccepted from '../../assets/images/how-to-play/challenge-accepted.png'
import controlKeyboardBg from '../../assets/images/how-to-play/controls-keypad-bg.svg';
import controlFireTvBg from '../../assets/images/how-to-play/controls-fire-tv.png';
import controlRemoteBg from '../../assets/images/how-to-play/controls-remote.png';

import {FOLDER_TYPES} from "../../assets/lib/FolderTypes";
import { userAccessAllowed, isLowSpecDevice, paramsToObject } from '../../app.helpers';
import HowToPlayContent from "./content/how-to-play-content.component";
import { HowToPlayInstructions } from "./instructions/how-to-play-instructions.component";
import {
	getGamepadBgImage,
	getGamepadImage,
	getGamepadName,
	isFeatureControllerMappingEnabled
} from '../../assets/lib/utils';
import { Loader } from '../common/loader/loader.component';
import { getDailyChallenge } from '../daily-challenges/daily-challenges.selectors';
import { HowToPlayMapping } from './mapping/how-to-play-mapping.component';
import {
	ControllerMappingChangeAxis,
	ControllerMappingSwapButtons,
	PatchControllerMappingsAction,
	ResetControllerMappingsAction
} from '../../entities/mapping/mapping.actions';
import Elastic, { LOG_LEVEL } from '../../assets/lib/elastic.lib';

const threeDotAnimation = {
	loop: true,
	autoplay: true,
	prerender: true,
	animationData: threeDotJson,
	rendererSettings: { className: 'three-dot' }
};

class HowToPlayComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contentType: props.currentParameters.contentType,
			loading: true,
			gameHadError: false,
			dispatchedRunAction: false,
			configuredUserData: false,
			showPlay:true,
			showRemapScreen: false,
			challengeDetails: undefined
		};

		this.menu = [];
		this.accessForbidden = false;
		this.handleQuitGameWithDelayTimer = null;

		this.dummy_asvi={
              "action_0": {
                "label": "Add Credit + Start",
                "description": "Add Credit + Start (used for arcade games)",
                "input_cluster": 1,
                "key": [
                  "Key_Enter"
                ],
                "gamepad": [
                  "Gamepad_Menu"
                ],
                "touch": [
                  "Touch_Start"
                ],
                "firetv": [
                  "FireTV_Start"
                ]
              },
              "action_1": {
                "label": "",
                "description": "Move Up",
                "input_cluster": 1,
                "gamepad": [
                  "Gamepad_DPadUp",
                  "Gamepad_LeftThumbstickUp"
                ],
                "key": [
                  "Key_Up"
                ],
                "vj": [
                  "VJ_Up"
                ]
              },
              "action_2": {
                "label": "",
                "description": "Move Down",
                "input_cluster": 1,
                "gamepad": [
                  "Gamepad_DPadDown",
                  "Gamepad_LeftThumbstickDown"
                ],
                "key": [
                  "Key_Down"
                ],
                "vj": [
                  "VJ_Down"
                ],
                "firetv": [
                  "FireTV_Down"
                ]
              },
              "action_3": {
                "label": "Movement",
                "description": "",
                "input_cluster": 1,
                "gamepad": [
                  "Gamepad_DPadLeft",
                  "Gamepad_LeftThumbstickLeft"
                ],
                "key": [
                  "Key_Left"
                ],
                "vj": [
                  "VJ_Left"
                ],
                "firetv": [
                  "FireTV_Left"
                ]
              },
              "action_4": {
                "label": "",
                "description": "",
                "input_cluster": 1,
                "gamepad": [
                  "Gamepad_DPadRight",
                  "Gamepad_LeftThumbstickRight"
                ],
                "key": [
                  "Key_Right"
                ],
                "vj": [
                  "VJ_Right"
                ],
                "firetv": [
                  "FireTV_Right"
                ]
              }
          };
	}

	componentDidMount() {
		const {
			dispatch,
			item,
			currentId,
			currentParameters
		} = this.props;

		this.MENU_TYPES = {
			FIRE_TV: {
				title: "Fire Tv Controller",
				contentName: 'firetv'
			},
			REMOTE: {
				title: 'Remote',
				contentName: 'remote'
			},
			GAMEPAD: {
				title: deviceInfo.isPlayStationPlatform() ? 'Wireless Controller' : 'Controller',
				contentName: 'gamepad'
			},
			KEYBOARD: {
				title: 'Keyboard',
				contentName: 'keyboard'
			},
			TOUCH: {
				title: 'Touch',
				contentName: 'touch'
			},
			INSTRUCTIONS: {
				title: 'Instructions',
				contentName: 'instructions'
			},
			NEOLEGEND: {
				title: 'Neo Legend',
				contentName: 'neolegend'
			}
		}
		dispatch(FetchGameDetailed(currentId));
		// dispatch(GetControllerMappingsAction(currentId));
		if (featureInfo.isSupported(FEATURES.GIANT_SLAYER) && this.props?.currentParameters?.challengeId) {
			dispatch(GetGiantSlayerCreationDetailsAction(this.props?.currentParameters?.challengeId,
				(challenges) => void this.setState({ challengeDetails: challenges?.length && challenges[0] })
			));
		}

		this.menu = this.generateMenu(item)

		if(navigator.userAgent.match(/ AFT/gi)) {
			// On the FireTV You get gamepad instructions in all cases as calling getGamepads() causes loss of control mapping
			// Note that you ONLY get fireTV as the platform if you have no other controller connected, otherwise you may
			// well get the other controller.

			if(item && item.tags.includes('firetv remote')) {
				this.onMenuSelect(this.MENU_TYPES.FIRE_TV);
			}else {
				this.onMenuSelect(this.MENU_TYPES.GAMEPAD);
			}
		}
		else
		{
			const gamePads =  navigator.getGamepads ? navigator.getGamepads() : navigator.webkitGetGamepads ? navigator.webkitGetGamepads : [];
			this.setInitalControlHowToPlay(gamePads, navigator, item)
		}

		inputManager.setCurrentChildById("quitButton");
		inputManager.setBackFunction(this.customBackFunction);

		if (item && dispatch) {
			dispatch(SetGTMEvent(TYPE_GAME_HOW_TO_PLAY,{'gameName': item.title}));
		}

		const isGiantSlayer = this.props.currentParameters && this.props.currentParameters.giantSlayerChallengeId;

		if (isGiantSlayer) {
			AudioManager.playAudio(TYPE_GIANT_SLAYER);
		}

		if (!deviceInfo.isRunningOnNative() && window.config.REACT_APP_ENV === 'live' && !featureInfo.isSupported(FEATURES.WEBRTC)) {
			this.setState({ showPlay: false });
		}

		this.displaySecondControllerWarningIfNeeded();
	}

	displaySecondControllerWarningIfNeeded = () => {
		if (!deviceInfo.isXboxPlatform() || !deviceInfo.isPlayStationPlatform()) return;

		const { item, location } = this.props;

		const hasMultiplayer = Array.isArray(item?.tags)
			? item.tags.includes('multiplayer2player2pads')
			: false;
		if (!hasMultiplayer) return;
		if (!location) return;

		const multiplayerGameTypes = [
			GAME_TYPE.BASIC,
			GAME_TYPE.DUEL_CHALLENGE
		];
		const queryParams = paramsToObject(location.search);
		if (!queryParams || !queryParams?.gameType) return;
		if (!multiplayerGameTypes.includes(queryParams.gameType)) return;

		checkMultiplayerGameForXbox();
	};

	componentDidUpdate(prevProps) {
		if (this.props.currentRoute?.path === ROUTES.HOMEPAGE.path) return;

		if(this.props.gameIsError && !this.state.gameHadError) {
			this.setState({ gameHadError: true });
			this.quitGame();
			addPopup(<GenericPopup
				okButtonLabel="Got it!"
				title={"Please try again"}
                message={"There was an issue with your game session. If the issue persists please go to our FAQ at https://aarca.de/support"}
			/>);
			return;
		}

		if (!deviceInfo.isRunningOnNative() && this.props.item && this.state.loading && (window.config.REACT_APP_ENV !== 'live' || featureInfo.isSupported(FEATURES.WEBRTC))) {
			if (!featureInfo.isSupported(FEATURES.CONTROL_OPTIONS) && !this.state.configuredUserData) {
				this.setState({ configuredUserData: true });
				const userData = readUserData() || {};
				const {controlPreferences = defaulControlValues} = userData;
				controlPreferences && configureInterface(controlPreferences);
			}

			if (!this.props.gameIsLoading && !this.props.gameIsIdle) {
				this.setState({ loading: false }, this.onStateSetted);
			}
		} else if (!this.props.gameIsLoading && !this.props.gameIsIdle && this.state.loading) {
			if (featureInfo.isSupported(FEATURES.CONTROL_OPTIONS)) {
				const userData = readUserData() || {};
				const {controlPreferences = defaulControlValues} = userData;
				controlPreferences && configureInterface(controlPreferences);
			}
			this.setState({ loading: false }, this.onStateSetted);
		}

 		if (this.props.item?.access && this.props.item !== prevProps.item) {
			if (!this.accessForbidden && !userAccessAllowed(this.props.item.access, this.props.userAccess)) {
				this.accessForbidden = true;
				addPopup(<GenericPopup
					title="Access denied"
					okButtonLabel="Got it!"
					message="You do not have permission to view this game"
					onOkClicked={() => {
						navigateToLocation(ROUTES.HOMEPAGE);
					}}
					/>);
			}
		}
		if(!inputManager.currentChild)
		{
			inputManager.setCurrentChildById("quitButton");
		}

		if (this.state.loading && this.props.item?.tags?.includes("gpudemo"))
		{
			this.setState({ loading: false }, this.onStateSetted);
		}
	}

	componentWillUnmount() {
		inputManager.setBackFunction(null);
		if (this.handleQuitGameWithDelayTimer) {
			clearTimeout(this.handleQuitGameWithDelayTimer);
		}
	}

	customBackFunction = () => {
		if (this.props.gameIsPaused) {
			return this.backToMenu();
		}

		this.handleQuitGameWithDelay();
	}

	handleQuitGameWithDelay = () => {
		// if start game is still loading and user wants to quit - wait until it's loaded, then quit
		// in other way it can bring unexpected behaviour if user immediately clicks Play after quitting and previous game is not loaded yet
		if (this.state.loading && !this.props.gameIsPaused) {
			if (deviceInfo.isPlayStationPlatform()) {
				this.quitGame();
			} else {
				this.handleQuitGameWithDelayTimer = setTimeout(() => {
					this.quitGame();
				}, 5000); // quit automatically if something went wrong
			}

			this.setState({ waitForGameLoadedBeforeQuit: true });
			return;
		}

		this.quitGame();
	}

	generateMenu = (item) => {
		const keyboardArray = (deviceInfo.platformType !== PLATFORM_TYPE_FIRE_TV && !deviceInfo.isDeviceIphone()) ? [this.MENU_TYPES.KEYBOARD] : []
		const neoLegendControlArray = deviceInfo.hasNeoLegendActive() && item && item.asvi_actions ? [this.MENU_TYPES.NEOLEGEND] : [];

		if (deviceInfo.hasNeoLegendActive()) {
			const menu = [...neoLegendControlArray, ...keyboardArray, this.MENU_TYPES.INSTRUCTIONS];
			return menu.map((element, index) => ({...element,index}))
		}

		const fireTvControlArray = item?.tags?.includes('firetv remote') && navigator.userAgent.match(/ AFT/gi) ? [this.MENU_TYPES.FIRE_TV] : []
		const remoteControlArray =  deviceInfo.isWaysunDevice()?[this.MENU_TYPES.REMOTE]:[];
		let menu; 

		if (deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) {
			menu = [
				this.MENU_TYPES.GAMEPAD,
				this.MENU_TYPES.INSTRUCTIONS
			];
		} else {
			menu = [
				...neoLegendControlArray,
				this.MENU_TYPES.GAMEPAD,
				...keyboardArray,
				this.MENU_TYPES.TOUCH,
				...fireTvControlArray,
				...remoteControlArray,
				this.MENU_TYPES.INSTRUCTIONS
			];
		}
		const indexedMenu = menu.map((element, index) => ({...element,index}))
		return indexedMenu
	}

	setInitalControlHowToPlay = (gamePads, navigator, item) => {
		if (deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) {
			this.onMenuSelect(this.MENU_TYPES.GAMEPAD);
		} else if (deviceInfo.hasNeoLegendActive() && item && item.asvi_actions) {
			this.onMenuSelect(this.MENU_TYPES.NEOLEGEND);
		} else if (gamePads.length > 0 && gamePads[0] !== null) {
			this.onMenuSelect(this.MENU_TYPES.GAMEPAD);
		}  else if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)===true) {
			this.onMenuSelect(this.MENU_TYPES.TOUCH);
		} else if (/(android)/i.test(navigator.userAgent)) {
			this.onMenuSelect(this.MENU_TYPES.TOUCH);
		} else {
			this.onMenuSelect(this.MENU_TYPES.KEYBOARD);
		}
	}

	onMenuSelect = (item) => {
		this.setState({
			challengeId:this.props.currentParameters.challengeId,
			contentType: item.contentName,
			giantSlayerChallengeId:this.props.currentParameters.giantSlayerChallengeId,
		});

	};

	playGame = () => {
		const { dispatch, item } = this.props;
		AudioManager.playAudio(TYPE_FINAL_PLAY_BUTTON);
			dispatch(UnpauseGameAction());
		dispatch(SetGTMEvent(TYPE_GAME_PLAY_STARTED, { 'gameName': item.title }));
		dispatch(addGameToPlayAgainList(this.props.currentId));

		if (deviceInfo.isRunningOnNative()) {
			if (deviceInfo.isRunningOnNativeClientV2()) {
				navigateToLocation(ROUTES.IN_GAME_HUD, {
					id: this.props.currentId,
					challengeId: this.props.currentParameters.challengeId
				});
			} else {
				navigateToLocation(ROUTES.IN_GAME_MENU, {
					id: this.props.currentId,
					challengeId: this.props.currentParameters.challengeId
				});
			}
		} else if (window.config.REACT_APP_ENV !== 'live' || featureInfo.isSupported(FEATURES.WEBRTC)) { // WebRTC
			const params = {
				id: this.props.currentId,
				challengeId: this.props.currentParameters.challengeId,
				serverIP: this.props.serverIP,
				sessionId: this.props.sessionId,
				serverPort: this.props.serverPort,
				input_map: encodeURIComponent(this.props.gameConfig?.input_map),
				game_profile: encodeURIComponent(this.props.gameConfig?.game_profile),
				challengeStyle: this.props.challengeStyle
			};
			if (this.props.webSocketServerPort) {
				params.webSocketServerPort = this.props.webSocketServerPort;
			}
			navigateToLocation(ROUTES.WEBRTC_GAME, params);
		}
	};

	sendDebugLog = (data) => {
		if (!['dev', 'stg'].includes(window.config.REACT_APP_ENV)) return 
		if (!deviceInfo.isXboxPlatform()) return;

		const userData = readRestUserData() || {};
		Elastic.logDirect(LOG_LEVEL.INFO, JSON.stringify({
			message: 'CANCEL_PLAYSTATION',
			userId: userData?.userId,
			email: userData?.email,
			...data
		}), true);
	};
	
	quitGame = () => {
		const { sessionId, currentId, requestId, location } = this.props;

		let debugLog = {sessionId, currentId, requestId, location};

		// abandon challenge immediately after quit, to make sure we show this challenge in the list on the homepage
		if (this.props.currentParameters && this.props.currentParameters.giantSlayerChallengeId) {
			this.props.dispatch(AbandonGiantSlayerChallengeAction(this.props.currentParameters.giantSlayerChallengeId));
		}

		const messageId = location && paramsToObject(location.search)?.messageId;
		this.props.dispatch(SetGameQuit(sessionId, messageId));
        if (!this.props.sessionId) {
			debugLog.noSessionId = true;
            this.props.dispatch(CancelledRequestAction(requestId));  // Report cancelled if the session ID has not yet returned
        } else if (deviceInfo.isPlayStationPlatform() || deviceInfo.isRunningOnNativeClientV2()) {
			cancelledRequestEvent(requestId);
		}

		// Our router attempts to move to the same page again but without an ID
		if (!getLastHistoryLocation() || getLastHistoryLocation() === '/how-to-play/:id') {
			const query = location && paramsToObject(location.search);
			if (query?.tournamentId) {
				return navigateToLocation(ROUTES.TOURNAMENTS_INFO, {id: query?.tournamentId});
			}
			if (query?.dailyChallengeEventId) {
				return navigateToLocation(ROUTES.DAILY_CHALLENGES);
			}
			debugLog.query = query;
			this.sendDebugLog(debugLog);
			return navigateToLocation(ROUTES.GAME_INFO, {id: currentId, focusElement: externalFocusableComponent.PLAY_BUTTON});
		}

		this.sendDebugLog(debugLog);

		navigateBack();
	};

	backToMenu = () => {
		this.props.dispatch(HideHowToPlay());
	};

	onStateSetted = () => {
		if (this.state.waitForGameLoadedBeforeQuit) {
			this.quitGame();
			return;
		}
		inputManager.setCurrentChildById("playButton");
	};

	isRunningOnNativeClientV2 = () => {
		return deviceInfo.isRunningOnNativeClientV2();
	};

	renderPlayButtonContent = () => {
		if (!this.state.loading) {
			return 'Play';
		}

		if (isLowSpecDevice()) {
				return '...';
		}

		return <SVGAnimation options={threeDotAnimation} />;
	};

	handleRemapClick = () => {
		this.setState({showRemapScreen: true}, () => {
			inputManager.setCurrentChildById("resetRemapButton");
		});
	};

	shouldDisplayRemapControllerImage = () => {
		if (deviceInfo.isPlayStationPlatform()) return null;

		return true;
	};

	renderRemapButton = (howToPlayContent) => {
		if (!howToPlayContent?.showRemapButton) return null;

		return (
			<Button
				className="secondary remap-controller-button"
				onClick={this.handleRemapClick}
				childId="remapButton"
			>
				<span>Remap</span>
				{this.shouldDisplayRemapControllerImage()
					&& <span className='remap-controller-button-image'/>}
			</Button>
		);
	};

	renderGameStartState = (howToPlayContent) => {
		if (this.state.showRemapScreen) return null;

		return (
			<div className="how-to-play-bottom">
				{this.state.showPlay &&<div className="state-text-container">
					{this.state.loading?'Loading...':'Press Play to Start - Hold Menu button to exit.'}
				</div>}
				<div className="buttons-container">
					{this.renderRemapButton(howToPlayContent)}
					<Button
						className="secondary"
						onClick={this.handleQuitGameWithDelay}
						childId="quitButton"
					>
						Quit
					</Button>
					{this.state.showPlay && <Button
						className="primary"
						onClick={this.playGame}
						disabled={this.state.loading}
						childId="playButton"
					>
						{this.renderPlayButtonContent()}
					</Button>}
				</div>
			</div>
		);
	};

	renderIngameMenuState = (howToPlayContent) => {
		if (this.state.showRemapScreen) return null;

		return (
			<div className="buttons-container">
				{this.renderRemapButton(howToPlayContent)}
				<Button
					className="secondary"
					onClick={this.backToMenu}
					childId="quitButton"
				>
					Back to menu
				</Button>
			</div>
		);
	};

	handleResetRemapButton = () => {
		this.props.dispatch(ResetControllerMappingsAction(this.props.currentId, this.props.sessionId));
		this.setState({showRemapScreen: false});
	};

	handleDoneRemapButton = () => {
		this.props.dispatch(PatchControllerMappingsAction(
			this.props.currentId,
			this.props.mapping,
			this.props.sessionId
		));
		this.setState({showRemapScreen: false});
	};

	renderRemapButtons = () => {
		return (
			<div className="buttons-container">
				<Button
					className="secondary"
					onClick={this.handleResetRemapButton}
					childId="resetRemapButton"
				>
					Reset
				</Button>
				<Button
					className="secondary"
					onClick={this.handleDoneRemapButton}
					childId="doneRemapButton"
				>
					Done
				</Button>
			</div>
		);
	}

	isEmptyObject = (obj) => {
		return Object.keys(obj).length === 0 && obj.constructor === Object
	}

	renderHeader = () => {
		if (this.state.showRemapScreen) {
			return (
				<h1 className='remap-controller-header'>
					<span>Define Controls</span>
					{this.shouldDisplayRemapControllerImage()
						&& <span className='remap-controller-header-image'/>}
				</h1>
			);
		}

		return <h1>How to play</h1>;
	}

	renderChallengeHeader = () => {
		const {
			challengeInformation,
			currentParameters,
			giantSlayerChallenges
		} = this.props;

		let giantSlayerDetails = null;
		if (currentParameters.giantSlayerChallengeId) {
			giantSlayerDetails = giantSlayerChallenges.find(challenge => challenge.giantSlayerChallengeId === currentParameters.giantSlayerChallengeId);

			// until we are waiting for /giant-slayer/get-all response we can assume that this is giant
			// and we can show giant logo and red vignette
			if (!giantSlayerDetails) {
				giantSlayerDetails = {role: 'giant'}
			}
		}

		const isGiant = giantSlayerDetails && giantSlayerDetails.role === 'giant';
		const isSlayer = giantSlayerDetails && giantSlayerDetails.role !== 'giant';

		return (
			<div className="challenge-only">
				{!giantSlayerDetails &&
					<img className={deviceInfo.isRunningOnNativeClientV2() ? '' : 'challenge-accepted'} src={challengeAccepted} alt="Challenge Accepted" />
				}
				{giantSlayerDetails &&
					<div className={`giant-slayer-animation ${deviceInfo.isRunningOnNativeClientV2() ? '' : 'puff-in'}`}>
						<div className="giant-slayer-text">
							Giant Slayer
						</div>
						<div className={`${isGiant ? 'giant-illus' : ''} ${isSlayer ? 'slayer-illus' : ''}`}>
						</div>
					</div>
				}
				{giantSlayerDetails && <div className="giant-slayer-information">
					<p className="giant-slayer-text">
						{isGiant && this.state?.challengeDetails?.targetSlayerWinFraction && `Take on the community with this 24hr challenge. ${Math.round(this.state?.challengeDetails?.targetSlayerWinFraction * 100)}% of slayers must beat your score to win.`}
						{isSlayer && "24 hours to stop the giant! Get your attack in and contribute to a collective win. The more of you, the better the slaying odds."}
					</p>
				</div>}
				{currentParameters && <div className="challenge-information">
					{challengeInformation.challengeTitle && <h2 className="challenge-title">
						{challengeInformation.challengeTitle}
					</h2>}
					{challengeInformation.challengeText && <p className="challenge-text">
						{challengeInformation.challengeText}
					</p>}
				</div>}
			</div>
		);
	};

	onMappingAxisChanged = (c) => {
		this.props.dispatch(ControllerMappingChangeAxis(this.props.currentId, c));
	}

	onMappingDropDownChanged = (changeTo, focusedElement) => {
		this.props.dispatch(ControllerMappingSwapButtons(this.props.currentId, changeTo, focusedElement));
	};

	render() {
		const {
			currentGame,
			currentParameters,
			howToPlayImages,
			item,
			preLoadFolder,
			mapping,
			giantSlayerDetails
		} = this.props
		const { showRemapScreen } = this.state;

		if (!item) return null;
		if (!howToPlayImages) return null;

		let asvi_actions = null;

		// Check that asvi_actions exists and is not empty
		if (currentGame && currentGame.asvi_actions &&
			Object.keys(currentGame.asvi_actions).length > 0
		) {
			asvi_actions = this.props.currentGame.asvi_actions;
		}

		// ClientV2 crashes if using older SVG's. Show a simple dummy diagram while these are being converted 
		// Better than this than risk crashes
		if(!asvi_actions && deviceInfo.isRunningOnNativeClientV2()) 
		{
			asvi_actions=JSON.parse(JSON.stringify(this.dummy_asvi));
		}

		let touchLayout = '';

		if (currentGame && currentGame.game_profile) {
			let touchLayoutGeneric = null;
			let touchLayoutSpecific = null;

			const game_profile = JSON.parse(this.props.currentGame.game_profile);
			touchLayoutGeneric = game_profile.controls.generic.css;
			touchLayoutSpecific = game_profile.controls.specific? game_profile.controls.specific.css:null;

			touchLayout = touchLayoutSpecific?touchLayoutSpecific:touchLayoutGeneric;
		}

		const HOW_TO_PLAY_CONTENT = {
			 firetv: {
				backgroundImage: controlFireTvBg,
				asvi_actions: asvi_actions,
				inputTypes: ['firetv'],
				name: 'firetv',
				image: !this.isEmptyObject(howToPlayImages) && preLoadFolder+(howToPlayImages['firetv'] || howToPlayImages['control-firetv'])
			},
			remote: {
				backgroundImage: controlRemoteBg,
				asvi_actions: asvi_actions,
				inputTypes: ['gamepad'],
				name: 'remote',
				image: !this.isEmptyObject(howToPlayImages) && preLoadFolder+(howToPlayImages['remote'] || howToPlayImages['control-remote'])
			}, 
			gamepad: {
			 	showRemapButton: isFeatureControllerMappingEnabled() && this.props.gameIsPaused,
				backgroundImage: getGamepadBgImage(),
				asvi_actions: asvi_actions,
				inputTypes: ['gamepad'],
				name: getGamepadName(),
				image: getGamepadImage(howToPlayImages, preLoadFolder)
			} ,
			keyboard: {
				backgroundImage: controlKeyboardBg,
				asvi_actions: asvi_actions,
				inputTypes: ['key'],
				name: 'keyboard',
				image: !this.isEmptyObject(howToPlayImages) && preLoadFolder+(howToPlayImages['keyboard'] || howToPlayImages['control-keyboard'])
			},
			touch: {
				asvi_actions: asvi_actions,
				inputTypes: ['touch', 'vj', 'vj2'],
				touchLayout: touchLayout,
				name: 'touch',
				image: !this.isEmptyObject(howToPlayImages) && preLoadFolder+(howToPlayImages['touch'] || howToPlayImages['control-touch'])
			},
			neolegend: {
				asvi_actions: asvi_actions,
				inputTypes: ['gamepad'],
				name: 'neolegend',
				image: !this.isEmptyObject(howToPlayImages) && preLoadFolder+(howToPlayImages['neolegend'] || howToPlayImages['control-neolegend'])
			} 
		};

		const contentType = this.state.contentType;
		const isHowToPlayContent = contentType && contentType !== 'instructions';
		const howToPlayContent = isHowToPlayContent ? HOW_TO_PLAY_CONTENT[contentType] : {};
		const selectedItem = this.menu.find((item) => item.contentName === this.state.contentType)
		const selectedIndex = selectedItem ? selectedItem.index : 0;

		const isChallenge = currentParameters && !!currentParameters.challengeId
		const isRemote = howToPlayContent.name === 'firetv' || howToPlayContent.name === 'remote';
		const isNeoLegendIncompatible = item?.tags?.indexOf("Neo Legends Stick Support") < 0;

		const isGiant = giantSlayerDetails && giantSlayerDetails.role === 'giant';
		const isSlayer = giantSlayerDetails && giantSlayerDetails.role !== 'giant';

		if (this.state.waitForGameLoadedBeforeQuit || this.props.patchMappingPending) {
			return <Loader loading={true}/>;
		}

		return (
			<>
				<div className={isLowSpecDevice()?"bg-full-screen-lowspec":"bg-full-screen"}>
					{!isChallenge && <img src={item.featured_image_uri} alt="" />}
				</div>
				<section className={`how-to-play ${isGiant ? 'as-giant' : ''} ${isSlayer ? 'as-slayer' : ''} ${this.props.gameIsPaused ? 'ingame-native' : ''}`}>
					<div className="content-with-bg-full-screen">
						{this.renderHeader()}
						{showRemapScreen &&
							<HowToPlayMapping
								focusOnFirstControl={true}
								mapping={mapping}
								asvi_actions={asvi_actions}
								handleResetRemapButton={this.handleResetRemapButton}
								handleDoneRemapButton={this.handleDoneRemapButton}
								onDropDownChanged={this.onMappingDropDownChanged}
								onAxisChanged={this.onMappingAxisChanged}
							>
								{isHowToPlayContent
									? <HowToPlayContent {...howToPlayContent} />
									: <HowToPlayInstructions />
								}
							</HowToPlayMapping>
						}
						{isChallenge && !showRemapScreen && this.renderChallengeHeader()}

						{!showRemapScreen &&
							<TabMenu
								items={this.menu}
								onSelect={this.onMenuSelect}
								selectedIndex={selectedIndex}>
								<div className={`how-to-play-content-container ${isChallenge ? 'how-to-play-content-container--challenge' : ''}`}>
									{isHowToPlayContent
										? <HowToPlayContent {...howToPlayContent} />
										: <HowToPlayInstructions/>
									}
								</div>
							</TabMenu>}

						{this.props.gameIsPaused
							? this.renderIngameMenuState(howToPlayContent)
							: this.renderGameStartState(howToPlayContent)
						}

						{isRemote &&
							<div className='how-to-play-remote-warning'>
								It is advised to use a gamepad for the best experience playing this game
							</div>
						}

						{deviceInfo.hasNeoLegendActive() && isNeoLegendIncompatible &&
							<div className="how-to-play-remote-warning">
								It is advised to use a gamepad for the best experience playing this game
							</div>
						}
					</div>
				</section>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const gameState = getGameState(state);
	const userAccess = getLoggedUserAccess(state);
	const currentChallenge = getCurrentChallenge(state);
	const howToPlayImages = getChallengeImages(state); // TODO refactor once the call only returns one challenge game/set not all
	const currentChallengeId = currentChallenge && currentChallenge._id;
	const currentIdHowToPlayImages = howToPlayImages && currentChallengeId && howToPlayImages[currentChallengeId];
	const preLoadFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.PRE_LOAD);
	const item = getCurrentGameDetails(state);
	const currentId = getCurrentGameDetailsId(state);

	let challengeInformation = {};
	if (state.dailyChallenges?.playDailyChallenge) {
		const dailyChallenge = getDailyChallenge(state);
		challengeInformation = {
			challengeTitle: dailyChallenge?.title,
			challengeText: dailyChallenge?.description
		};
	} else if (currentChallenge) {
		challengeInformation = {
			challengeTitle: currentChallenge.title,
			challengeText: currentChallenge.text
		};
	} else {
		challengeInformation = {};
	}

	const requestId = getLatestRequestId(state);
	return {
		requestAlreadyFailed: requestAlreadyFailed(state, requestId),
		requestAlreadyCancelled: requestAlreadyCancelled(state, requestId),
		currentRoute: state.routing.currentRoute,
		item,
		userAccess,
		currentId,
		howToPlayImages: currentIdHowToPlayImages || getContentByType(state),
		gameIsIdle: gameState === GAME_STATE.IDLE || gameState === GAME_STATE.FINISHED,
		gameIsReady: gameState === GAME_STATE.READY,
		gameIsLoading: gameState === GAME_STATE.LOADING,
		gameIsError : gameState === GAME_STATE.ERROR,
		gameIsPaused: gameState === GAME_STATE.PAUSED,
		currentGame: getCurrentGame(state),
		currentParameters: getCurrentLocation(state).query,
		sessionId: getRunningSessionId(state),
		serverIP: getRunningServerIP(state),
		serverPort: getRunningServerPort(state),
		webSocketServerPort: getRunningWebsocketServerPort(state),
        requestId,
		gameConfig: getRunningGameConfigObject(state),
		challengeStyle: getRunningGameChallengeStyle(state),
		challengeInformation,
		preLoadFolder,
		giantSlayerChallenges: getGiantSlayerChallenges(state),
		patchMappingPending: state.entities.mapping.patchMappingPending,
		mapping: state.entities.mapping.byId[currentId]
	};
};

export const HowToPlay = connect(mapStateToProps)(HowToPlayComponent);
