import { combineReducers } from 'redux';

import { gamesReducer } from './games/games.reducer';
import { usersReducer } from './users/users.reducer';
import { avatarsReducer } from './avatars/avatars.reducer';
import { logosReducer } from './logos/logos.reducer';
import { messagesReducer } from './messages/messages.reducer';
import { challengesReducer } from './challenges/challenges.reducer';
import { badgesReducer } from './badges/badges.reducer';
import { mappingReducer } from './mapping/mapping.reducer';

export default combineReducers({
	games: gamesReducer,
	avatars: avatarsReducer,
	users: usersReducer,
	logos: logosReducer,
	messages: messagesReducer,
	challenges: challengesReducer,
	mapping: mappingReducer,
	badges: badgesReducer
});
