// import TimelineMax from 'gsap/TimelineMax';
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import './autoscroll-box.component.less';

const AutoscrollBox = (props) => {

    const outerRef = useRef();
    const innerRef = useRef();

    useEffect(() => {
        const maxScroll = innerRef.current.clientHeight - outerRef.current.clientHeight;

        // scroll duration according to content length
        const duration = 1 + maxScroll * 0.02;

        const tl = gsap.timeline({
            repeat: -1, // repeats infinitely
            yoyo: true,
            delay: 1, // 1s pause at start
            repeatDelay: 1 // 1s pause at end
        });

        if (maxScroll > 0) {
            tl.fromTo(outerRef.current, duration,
                {
                    scrollTo: { y: 0 }
                },
                {
                    scrollTo: { y: maxScroll },
                    ease: 'power1.easeInOut'
                }
            );
        }

        return () => {
            tl.kill();
        }
    }, [props.children]);
    
    
    return (
        <div className="autoscroll-box" ref={outerRef}>
            <div className="autoscroll-box-inner" ref={innerRef}>
                {props.children}
            </div>
        </div>
    );
}

export default AutoscrollBox;
