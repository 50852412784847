import React from 'react';
import i18next from "i18next";
import {useTranslation, withTranslation} from "react-i18next";

import i18n, {TRANS_NAMESPACES} from "../../../assets/lib/i18n";

export const withTranslationWrapper = (WrappedComponent) => {
	return (props) => {
		const {t, ...otherProps} = props;

		const translate = (key, ns = TRANS_NAMESPACES.UI, options= undefined) => {
			//Options can be a default value to render
			return t(`${ns}:::${key}`, options);
		}

		return (
			<WrappedComponent {...otherProps} t={t} translate={translate} />
		);
	};
};

//translate HOC
export const withATranslation = (ns = TRANS_NAMESPACES.UI, options= undefined) => {
	return (component) => withTranslation(ns, options)(withTranslationWrapper(component));
}

//translate hook
export const useATranslation = (ns = TRANS_NAMESPACES.UI, options= undefined) => {
	const {t, i18n} = useTranslation(ns, options);

	const translate = (key, ns = TRANS_NAMESPACES.UI, options= undefined) => {
		return t(`${ns}:::${key}`, options);
	}

	return {translate, i18n}
}

//Pure js translate function
export const translate = (key, ns = TRANS_NAMESPACES.UI, options= undefined) => {
	return i18next.t(`${ns}:::${key}`, options)
}

export const changeLanguage = (language) => {
	i18n.changeLanguage(language);
}