import deviceInfo, {
  isDeviceChrome,
  isDeviceFirefox,
  isDeviceSafari,
  getBrowserData,
  STORE_TYPE_SAMSUNG_TV,
} from "./deviceInfo";
import Elastic, { LOG_LEVEL } from "./elastic.lib";

export default class Perf {
  constructor(sessionID, canvas, peerConnection, legacy) {
    this.sessionID = sessionID;
    this.canvas = canvas;
    this.context = null;
    if (this.canvas) {
      this.context = canvas.getContext("2d");
      this.chartHeight = canvas.height;
    }

    this.peerConnection = peerConnection;
    this.currentPing = 0;
    this.values = {};
    this.lastPacketsLost = 0;
    this.lastTotalPacketsRcv = 0;
    this.lasbytesReceived = 0;
    this.lastBytesReceivedTimestamp = 0;
    this.legacy = legacy;
    this.videoCodecId = "";
    this.videoCodecFound = false;
    this.videoCodecName = "";
    this.registerValue("Codec", "");
    this.registerValue("Res", "");
    this.registerValue("Mbps", "");
    this.registerValue("FPS", "");
    this.registerValue("RTT", " ms");
    this.registerValue("Decode", " ms");
    this.registerValue("CDelay", " ms");
    this.registerValue("JitDelay", " ms");
    if (isDeviceSafari) {
      this.registerValue("jitter", " s");
    } else {
      this.registerValue("jitter", " ms");
    }
    this.registerValue("Loss", " %");
    //this.registerValue('LossAvg', ' %');

    const browserData = getBrowserData();
    this.browser = browserData.browser;
    this.os = browserData.operatingSystem;
    this.avsServerVersion = "opalAVS";
  }

  init = () => {
    if (this.canvas) {
      this.canvas.height *= Object.keys(this.values).length;
    }
    this.update();
  };

  deInit = () => {};

  setAVSVersion = (version) => {
    this.avsServerVersion = version;
  };

  remove = () => {
    this.canvas = null;
  };

  registerValue = (id, units) => {
    this.values[id] = {
      list: [],
      last: 0,
      units: units,
    };
  };

  updateValue = (id, value) => {
    this.values[id].list.push(value);
    this.values[id].last = value;
  };

  reportPing = (value) => {
    this.currentPing = value;
  };

  updateStats = () => {

    try {
      this.peerConnection.getStats(null).then((stats) => {
        const currentTimestamp = window.performance.now();
        const secondsElapsed =
          (currentTimestamp - this.lastBytesReceivedTimestamp) / 1000;
        stats.forEach((report) => {
          // console.log('Report: ' , report);
          if (report["type"] === "inbound-rtp" && report["kind"] === "video") {
            //console.log('Report: ' , report);

            if (!this.videoCodecFound) {
              this.videoCodecId = report["codecId"];
            }

            const fps = report["framesPerSecond"];
            const height = report["frameHeight"];
            const width = report["frameWidth"];
            const packetsLost = report["packetsLost"];
            const jitter = report["jitter"];
            const totalFramesDecoded = report["framesDecoded"];
            const bytesReceived = report["bytesReceived"];
            const packetsReceived = report["packetsReceived"];
            const instantLoss =
              ((packetsLost - this.lastPacketsLost) /
                (packetsReceived - this.lastTotalPacketsRcv)) *
              100;
            // const packetsLostAvg = ((packetsLost/packetsReceived)*100).toFixed(2);
            const avgClientDelay =
              (report["totalProcessingDelay"] * 1000) / totalFramesDecoded;
            const avgDecodingDelay =
              (report["totalDecodeTime"] * 1000) / totalFramesDecoded;
            const currentMbps =
              ((bytesReceived - this.lasbytesReceived) * 8) /
              1000000 /
              secondsElapsed;
            const JitterDelay_ms =
              (report["jitterBufferDelay"] /
                report["jitterBufferEmittedCount"]) *
              1000;

            this.updateValue("Res", width + " x " + height);
            this.updateValue("FPS", fps);
            this.updateValue("Mbps", parseFloat(currentMbps).toFixed(2));
            this.updateValue("CDelay", parseFloat(avgClientDelay).toFixed(2));
            this.updateValue("JitDelay", parseFloat(JitterDelay_ms).toFixed(2));
            this.updateValue("Decode", parseFloat(avgDecodingDelay).toFixed(2));
            this.updateValue("Loss", parseFloat(instantLoss).toFixed(2));
            // this.updateValue('LossAvg', parseFloat(packetsLostAvg).toFixed(2));

            this.lastPacketsLost = packetsLost;
            this.lastTotalPacketsRcv = packetsReceived;
            this.lasbytesReceived = bytesReceived;
            this.lastBytesReceivedTimestamp = currentTimestamp;

            if (isDeviceSafari) {
              this.updateValue("jitter", parseFloat(jitter).toFixed(2));
            } else {
              this.updateValue("jitter", parseFloat(jitter * 1000).toFixed(2));
            }
          } else if (
            !this.videoCodecFound &&
            report["type"] === "codec" &&
            report["id"] === this.videoCodecId
          ) {
            this.videoCodecName = report["mimeType"].replace(/^video\//, "");
            if (this.videoCodecName !== "") {
              if (this.legacy) {
                this.videoCodecName = "* " + this.videoCodecName;
              }
              this.updateValue("Codec", this.videoCodecName);
              this.videoCodecFound = true;
            }
          } else if (
            report["type"] === "candidate-pair" &&
            report["state"] === "succeeded" &&
            report["writable"] === true
          ) {
            if (isDeviceFirefox) {
              this.updateValue("RTT", this.currentPing);
            } else {
              if (report["currentRoundTripTime"]) {
                const rtt = report["currentRoundTripTime"];
                this.updateValue("RTT", rtt * 1000);
              }
            }
          }
        });

      });
    } catch (e) {
      console.log("Error getting WebRTC Stats.");
    }
  };

  update = () => {
    if (!this.canvas || this.unmounted) return;
    this.render();
  };

  drawData = (id, offset) => {
    const numHorizBars = 30;
    const fixedScale = 100;
    const width = this.canvas.width;
    const height = this.chartHeight;
    const horizBarSize = width / numHorizBars;
    const offsetY = offset * this.chartHeight;

    const bgGrad = this.context.createLinearGradient(
      0,
      offsetY,
      0,
      height + offsetY
    );
    // Adding transparency using rgba
    bgGrad.addColorStop(0, "rgba(30, 144, 255, 0.1)"); // DodgerBlue with 50% transparency
    bgGrad.addColorStop(1, "rgba(0, 0, 255, 0.1)"); // Blue with 50% transparency
    this.context.fillStyle = bgGrad;
    this.context.fillRect(0, offsetY, width, height + offsetY);

    const barGrad = this.context.createLinearGradient(
      0,
      offsetY,
      0,
      height + offsetY
    );
    // Adding transparency using rgba
    barGrad.addColorStop(0, "rgba(255, 0, 0, 1.0)"); // Red with 50% transparency
    barGrad.addColorStop(1, "rgba(0, 128, 0, 1.0)"); // Green with 50% transparency

    var list = this.values[id].list;
    var last = this.values[id].last;
    var units = this.values[id].units;

    for (var i0 = list.length - 1, i1 = 0; i0 >= 0; i0--, i1++) {
      const scale = list[i0] / fixedScale;
      const barOriginX = width - horizBarSize * (i1 + 1);
      const barOriginY = height - scale * height;
      this.context.fillStyle = barGrad;
      this.context.fillRect(
        barOriginX - 1,
        barOriginY + offsetY,
        horizBarSize + 1,
        this.chartHeight - barOriginY
      );
    }

    this.context.fillStyle = "white";
    this.context.font = "18px 'Lucida Console', monospace";
    this.context.fillText(id + ": " + last + units, 10, 30 + offsetY);
  };

  render = () => {
    if (!this.canvas) return;

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    Object.keys(this.values).forEach((key, offset) => {
      this.drawData(key, offset);
    });

    window.requestAnimationFrame(this.render);
  };
}
