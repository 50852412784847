import React, { Component } from 'react';
import Tile from './tile.component';
import inputManager from '../../../assets/lib/inputmanager';
import {getNavigationData} from '../../../assets/lib/utils';
import { LEADERBOARD_LIST_ID } from '../../../constants';

class List extends Component {
	constructor(props){
		super(props);

		this.itemNavigations = {};
	}

	componentDidMount() {
		window.addEventListener('resize', this.refreshNavigationData);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.refreshNavigationData);
	}

	shouldRefreshRects = (prevProps) => {
		const prevListWasNotEmpty = prevProps?.list?.data?.length > 0;
		const nextListIsNotEmpty = this.props?.list?.data?.length > 0;
		const isListLengthChanged = prevProps?.list?.data?.length !== this.props?.list?.data?.length;

		return prevListWasNotEmpty && nextListIsNotEmpty && isListLengthChanged;
	}

	componentDidUpdate(prevProps){
		if (this.props.list?.shouldForceRefreshData) {
			this.refreshNavigationData();
			inputManager.refreshBoundingRects(this.props.list.id);
			return;
		}

		if(prevProps.newDataLoading && !this.props.newDataLoading) {//new data loaded
			this.refreshNavigationData();
		} else if (this.isLeaderboardsListDataChanged(prevProps)) {
			this.refreshNavigationData();
		}

		if (this.shouldRefreshRects(prevProps)) {
			inputManager.refreshBoundingRects(this.props.list.id);
		}
	}

	shouldComponentUpdate(nextProps) {
		if (this.props.list.shouldComponentForceUpdate) return true;

		return this.props.list.data.length !== nextProps.list.data.length // differentLength 
			|| this.props.outerInViewStatus !== nextProps.outerInViewStatus 
			|| this.props.outerInViewUsed !== nextProps.outerInViewUsed 
			|| this.props.list.data[0]?._id !== nextProps.list.data[0]?._id; // differentFirstElement 
	}

	refreshNavigationData=()=>{
		Object.values(this.itemNavigations).forEach((itemObject) => {
			itemObject.navigationData = getNavigationData(itemObject.element);
		});
	};

	isLeaderboardsListDataChanged = (prevProps) => {
		return this.props.list?.id === LEADERBOARD_LIST_ID
			&& this.props.list?.data?.length !== prevProps.list?.data?.length;
	};

	onTileRef = (tileId ,element) => {
		if(element) {
			this.itemNavigations[tileId] = {
				element,
				navigationData: getNavigationData(element)
			};
		} else {
			delete this.itemNavigations[tileId];
		}
	};

	onTileFocus = (tileId, listId, index) => {
		if(this.props.onTileFocus) this.props.onTileFocus(tileId,listId, this.itemNavigations[tileId].navigationData, index);
	};

	onTileBlur = (tileId, listId) => {
		if(this.props.onTileBlur) this.props.onTileBlur(tileId,listId, this.itemNavigations[tileId].navigationData);
	};

	onHorizontalScroll = (listId, event) => {
		event.preventDefault();
		event.stopPropagation();
		const {listNavigations} = this.props;

		listNavigations[listId].scrollPos = event.target.scrollLeft;

		inputManager.setParentScrollPos(listId, 0,event.target.scrollLeft);
	};

	onListRef = (element) => {
		const {list} = this.props;
		inputManager.onParentRef(element,list.id, list.keepCurrentChild, list.parentEntrance);
		this.props.onListRef(list, element);
	};

	onListWrapperRef = (element) => {
		const {list} = this.props;
		this.props.onListWrapperRef(list, element);
	};

	onMouseDown = (event) => {
		if (this.props.onMouseDown) {
			this.props.onMouseDown(event);
		}
	};

	onMouseMove = (event) => {
		if (this.props.onMouseMove) {
			this.props.onMouseMove(event);
		}
	};

	onMouseUp = (event) => {
		if (this.props.onMouseUp) {
			this.props.onMouseUp(event);
		}
	};

	onMouseLeave = (event) => {
		if (this.props.onMouseLeave) {
			this.props.onMouseLeave(event);
		}
	};

	render() {
		const {list, onTileClick} = this.props;

		const listIdWithoutSpace = list.id.replace(/\s+/g, '-');

		return (
			<div 
				className={`list-object list-${listIdWithoutSpace}`}
				ref={this.onListWrapperRef}
			>
				{list.title && <h2 className={`list-title list-${listIdWithoutSpace}`}> {list.title}</h2>}
				<div
					ref={this.onListRef}
					className={`list ${list.type}`}
					onScroll={(event)=>this.onHorizontalScroll(list.id,event)}
					onMouseDown={this.onMouseDown}
					onMouseMove={this.onMouseMove}
					onMouseUp={this.onMouseUp}
					onMouseLeave={this.onMouseLeave}
				>
					<div
						className={`list-inner`}>
						{list.data.map((item, index)=>{
							if(item && list.id) {
								return (
									<Tile
										key={list.id.toString() + item._id}
										id={item._id}
										item={item}
										listId={list.id}
										renderer={list.renderer}
										onTileFocus={this.onTileFocus}
										onTileBlur={this.onTileBlur}
										onTileClick={onTileClick}
										onTileRef={this.onTileRef}
										index={index}
										shouldComponentForceUpdate={list.shouldComponentForceUpdate}
										listLength={list.data.length}
										outerInViewUsed={this.props.outerInViewUsed}
										outerInViewStatus={this.props.outerInViewStatus}
									/>
								)
							}
							return null
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default List;
