import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabMenu } from '../common/tab-menu/tab-menu.component';
import { ROUTES, navigateToLocation } from '../../app.router';
import inputManager from '../../assets/lib/inputmanager';

import './tournaments.component.less';
import {
	getCurrentTournamentData,
	getCurrentTournamentDetailId
} from "./tournaments.selector";
import {FetchTournamentsDetailAction, ResetTournamentsDetailAction} from "./tournaments.actions";
import { getLoggedUserAccess } from '../../app.selectors';
import { addPopup } from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import { userAccessAllowed } from '../../app.helpers';
import {isLowSpecDevice} from '../../app.helpers';
import { Route } from 'react-router-dom';
import { TournamentsInfo } from './info/tournaments-info.component';
import { TournamentsParticipants } from './participants/tournaments-participants.component';
import { TournamentsLeaderboard } from './leaderboard/tournaments-leaderboard.component';

class TournamentsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.menuSelect = this.onMenuSelect.bind(this);
		this.accessForbidden = false;
	}

	componentDidMount(){
		const {dispatch, tournamentId} = this.props;
		if (tournamentId) {
			dispatch(FetchTournamentsDetailAction(tournamentId, true, true));
		}

		if (!inputManager.currentChild) { // if no focus - focus on tournaments
			inputManager.setCurrentChildById("tournaments", "navBar");
		}
	}

	componentDidUpdate(prevProps) {
		const {dispatch, tournamentId} = this.props;
 		if (this.props.tournamentData && this.props.tournamentData !== prevProps.tournamentData) {
			if (!this.accessForbidden && !userAccessAllowed(this.props.tournamentData.access, this.props.userAccess)) {
				this.accessForbidden = true;
				addPopup(<GenericPopup
						title="Access denied"
						okButtonLabel="Got it!"
						message="You do not have permission to view this game"
						onOkClicked={() => {
							navigateToLocation(ROUTES.HOMEPAGE);
						}}
					/>);
			}
		}

 		// if need to display leaderboard tab - refresh rects to avoid navigation issue
		if (prevProps.tournamentData?.startDate !== this.props.tournamentData?.startDate && this.isLeaderboardTabVisible()) {
			inputManager.refreshBoundingRects('tab-menu');
		}

		if (!prevProps.tournamentId && tournamentId) {
			dispatch(FetchTournamentsDetailAction(tournamentId, true, true));
		}
	}

	componentWillUnmount(){
		const {dispatch} = this.props;
		dispatch(ResetTournamentsDetailAction());
		inputManager.setBackFunction(null);
	}

	onMenuSelect(menuItem) {
		const {tournamentId} = this.props;
		navigateToLocation(menuItem.route, {id: tournamentId});
	}

	isLeaderboardTabVisible = () => {
		const now = new Date();
		const secondsSinceEpoch = Math.round(now.getTime() / 1000);

		return this.props.tournamentData?.startDate < secondsSinceEpoch;
	}

	render() {
		let menu = [
			{title: "Info", route: ROUTES.TOURNAMENTS_INFO, index:0},
			{title: "Participants", route: ROUTES.TOURNAMENTS_PARTICIPANTS, index:1}
		];

		// Only show leaderboard if tournament in progress
		if (this.props.tournamentData && this.isLeaderboardTabVisible()) {
			menu.push({title: "Leaderboard", route: ROUTES.TOURNAMENTS_LEADERBOARD, index:2});
		}

		return (
			<section className="tournaments-component">
				{this.props.tournamentData && this.props.tournamentData.detailImage && <div className={isLowSpecDevice()?"bg-full-screen-lowspec":"bg-full-screen"}>
					<img src={this.props.tournamentData? this.props.tournamentData.detailImage:''} alt=""/>
				</div>}
				<h1>{this.props.tournamentData? this.props.tournamentData.title : ''}</h1>
				<TabMenu items={menu} onSelect={this.menuSelect} >
					<>
						<Route path={ROUTES.TOURNAMENTS_INFO.path} component={TournamentsInfo} />
						<Route path={ROUTES.TOURNAMENTS_PARTICIPANTS.path} component={TournamentsParticipants} />
						<Route path={ROUTES.TOURNAMENTS_LEADERBOARD.path} component={TournamentsLeaderboard} />
					</>
				</TabMenu>
		 	</section>
		);
	}
}

const mapStateToProps = (state) => {
	const tournamentData = getCurrentTournamentData(state);
	const tournamentId = getCurrentTournamentDetailId(state);
	const userAccess = getLoggedUserAccess(state);

	return {
		tournamentData,
		tournamentId,
		userAccess,
	}
};

export const Tournaments = connect(mapStateToProps)(TournamentsComponent);