import React, {Component} from 'react';

import gemIcon from '../../../assets/images/global/gems.png';

import './reward-item.component.less';
import CountAnimation from '../../animations/count.animation';

export const ITEM_TYPE_ACHIEVEMENTS = 'achievement';
export const ITEM_TYPE_MEDAL = 'medal';
export const ITEM_TYPE_GEM = 'gem';
export const ITEM_TYPE_EXP = 'exp';


class RewardItem extends Component {
	constructor(props) {
		super(props);

		this.renderGemItem = this.renderGemItem.bind(this);
	}

	renderItemWithImage() {
		const { imgSrc, itemType } = this.props;

		return (
			<div className={`content withImage ${itemType!==ITEM_TYPE_MEDAL?'with-border':''}`}>
				<img src={imgSrc} alt="rewardIcon"/>
			</div>
		);
	}

	renderExpItem() {
		const { iconRefFunction, expCount } = this.props;
		if(!this.prevExpCount)this.prevExpCount=expCount;

		return(
			<div className="content exp">
				<div
					ref={iconRefFunction}
					className="exp-text"
				>
					XP
				</div>
				<div className="exp-value">
					<CountAnimation startValue={this.prevExpCount} endValue={expCount} duration={1.5}/>
				</div>
			</div>
		);
	}

	renderGemItem() {
		const { iconRefFunction, gemCount } = this.props;
		if(!this.prevGemCount)this.prevGemCount=gemCount;

		return(
			<div className="content gem">
				<img ref={iconRefFunction} src={gemIcon} alt="gemIcon"/>
				<div className="gem-value">
					<CountAnimation startValue={this.prevGemCount} endValue={gemCount} duration={1.5}/>
				</div>
			</div>
		);
	}

	renderContent() {
		const {itemType} = this.props;
		if(itemType===ITEM_TYPE_GEM)return this.renderGemItem();
		else if(itemType===ITEM_TYPE_EXP)return this.renderExpItem();
		else return this.renderItemWithImage();
	}

	render() {
		return (
			<div className={'reward-item-component'}>
				{this.renderContent()}
			</div>
		);
	}
};

export default RewardItem;
