import React, { useEffect, useState } from 'react';
import './pwa-popup-android.component.less';
import deviceInfo from '../../assets/lib/deviceInfo';

const LOCAL_STORAGE_KEY = "pwa_popup_android_display";

const PWAPopupAndroid = () => {
    const [popupClicked, setPopupClicked] = useState(false);
    const [waitForNative, setWaitForNative] = useState(true);
    const isAndroid = /(android)/i.test(navigator.userAgent);

    useEffect(() => {
        /**
         * Wait for 2 seconds from the app start in order to make sure that the Native side has been already sent 'init' message.
         * This should prevent this popup blink when running on Native.
         **/
        setTimeout(() => {
            setWaitForNative(false);
        }, 2000);
    }, []);

    if (waitForNative) return false;

    if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
        // the page is being run as a PWA
        return false;
    }

    if (!isAndroid || deviceInfo.isRunningOnNative() || deviceInfo.isRunningOnNativeClientV2()) {
        return false;
    }

    const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
    const lastDisplayTimestamp = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (lastDisplayTimestamp && Date.now() - lastDisplayTimestamp < sevenDaysInMs) {
        // popup already displayed this week
        return false;
    }

    const onOverlayClicked = () => {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, Date.now());
        setPopupClicked(true);
    }

    return (
        <>
            {!popupClicked &&
                <div className="pwa-popup-android-overlay" onClick={onOverlayClicked}>
                    <div className="pwa-popup-android">
                        <div className="pwa-popup-android-content">
                            <div className="left">
                                <img className="appIcon" src="icon.svg" />
                            </div>
                            <div className="right">
                                <div>To launch Antstream app from your Home Screen</div>
                                <div>1- Click on browser menu <span className="chrome-menu-icon"></span></div>
                                <div>2- Select 'Add to Home Screen'</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default PWAPopupAndroid;
