import { push, goBack, replace } from 'connected-react-router';
import { store } from './configureStore';
import {SaveRoute, LoginEpicGamesAction} from './app.actions';
import {LoginFacebookAction, LoginUserAction} from './components/login/login.actions';
import { getIsAppInitialised, getLoggedUser } from './app.selectors';
import { GetAccountDetailsAction } from './components/login/login.actions';
import { isLowSpecDevice, objectToParams } from './app.helpers';
import * as LocalStorage from './assets/lib/local-storage';
import KeepAlive from './assets/lib/KeepAlive';
import deviceInfo, {STORE_TYPE_EPIC} from "./assets/lib/deviceInfo";
import featureInfo, {FEATURES} from "./assets/lib/featureInfo";
import {getPlatformLoginInfo} from "./assets/lib/game-wrapper";
import { getWaysunAntstreamToken } from "./components/login/login.selectors";
import {analyticsPageView} from "./assets/lib/antstreamAnalytics";
import { DEEP_LINK_SUB_PAGE_INFO, DEEP_LINK_SUB_PAGE_LEADERBOARD } from './assets/lib/deepLinkGenerator';
import { PLAY_STATION_AUTH_PATH } from './constants';

import {externalFocusableComponent as externalFocusableGameDetailsComponent} from './components/game-details/info/game-details-info.component';
import {externalFocusableComponent as externalFocusableLeaderboardComponent} from './components/leaderboard/leaderboard.component'

export const getCurrentLocation = () => store.getState().routing.locationBeforeTransitions;
let lastHistoryLocation = '';

// XXX We are using this as a fake selector, there should be a better way to implement router control
// in the redux architecture, but redux-router doesn't have out-of-the-box solutions
const guardRouting = (route, params= {}, search= {}) => {
    // for any access, we check first that the app was initialised
    // if it is not the case, we save the route and do the initialisation
    const isInitialised = getIsAppInitialised(store.getState());
    if (route.init && !isInitialised) {
        store.dispatch(SaveRoute(route, params, search));
        return ROUTES.SPLASH;

    }

    const isLogged = store.getState().login.logged;
    if (route.login && !isLogged) {
        console.log("not logged on: " + route.path);
        if (deviceInfo.isWaysunDevice()) {
            const waysunToken = getWaysunAntstreamToken(store.getState());
            if(!waysunToken){
                getPlatformLoginInfo();
            }
            return null;
        }

        // If we are an Epic store device, try the automatic login
        if ((deviceInfo.storeType === STORE_TYPE_EPIC) && deviceInfo.isRunningOnNative()) {
            getPlatformLoginInfo();
            return null;
        }

        /** if playstation we should not use old accessToken from local storage, we should wait for platformLogin message instead**/
        if (deviceInfo.isPlayStationPlatform()) {
            store.dispatch(SaveRoute(route, params, search));
            return ROUTES.LOGIN;
        }

        let userData = null;
            userData = LocalStorage.readRestUserData();

        if (userData) {
            store.dispatch(SaveRoute(route, params, search));
            store.dispatch(GetAccountDetailsAction());

                // for new backend it's important to wait until accountdetails will be resolved, then it will be redirected to saved route
                return ROUTES.SPLASH;
        }

        if(deviceInfo.isTrueTvDevice()) {
            return ROUTES.TRUE_AUTH;
        }

        const facebookToken = LocalStorage.readFacebookUserData();
        if (facebookToken) {
            store.dispatch(SaveRoute(route, params, search));
            store.dispatch(LoginFacebookAction(facebookToken,true));
            if(route===ROUTES.HOMEPAGE) {
                return ROUTES.HOMEPAGE;
            }
            return null;
        }

        store.dispatch(SaveRoute(route, params, search));
        return ROUTES.LOGIN;
    } 
    
    if (route === ROUTES.SETTINGS_DEBUG && !featureInfo.isSupported(FEATURES.DEBUG_SETTINGS)) {
        return ROUTES.HOMEPAGE;
    }

    return route;
};

const composeRoutes = () => {

	const { init, login, noHeader, noHistory } = {init: true, login: true, noHeader: true, noHistory: true };
	return {
		SPLASH: 				{path: '/', noHistory, noHeader},
		LOGIN: 					{path: '/login', init, noHeader, noHistory},
		LOGIN_CREDENTIALS:		{path: '/login/credentials', init, noHeader, noHistory},
		SIGNUP:					{path: '/signup', init, noHeader, noHistory},
		SIGNUP_LINK:			{path: '/signup/link', init, noHeader, noHistory},
		SIGNUP_PASSWORD: 		{path: '/signup/password', init, noHeader, noHistory},
		SIGNUP_GAMERTAG: 		{path: '/signup/gamertag', init, noHeader, noHistory},
		SIGNUP_AVATAR: 			{path: '/signup/avatar', init, login, noHeader, noHistory},
		SIGNUP_AUTHENTICATION: 	{path: '/signup/authentication', init, noHeader, noHistory},
		SIGNUP_COMPLETION:		{path: '/signup-completion', init, login, noHeader, noHistory},
		AUTH_ACCESS_TOKEN:      {path: '/auth/token', init, noHeader, noHistory},
		XBOX_AUTH_ACCESS_TOKEN: {path: '/xbl/oath_success', init, noHeader, noHistory},
		PLAYSTATION_AUTH:       {path: PLAY_STATION_AUTH_PATH, init, noHeader, noHistory},
		RESET_PASSWORD:			{path: '/login/reset-password', init, noHeader, noHistory},
		PASSWORD_RESET_REQUEST: {path: '/login/request-password-reset', init, noHeader},
		FREE_TIER_INFORMATION:  {path: '/free-tier-information', init, login, noHeader, noHistory},
		HOMEPAGE: 				{path: '/homepage', init, login},
		DAILY_CHALLENGES: 		{path: '/daily-challenges', init, login},
		SEARCH: 				{path: '/search', init, login},
		GAME: 					{path: '/game-details/:id/', init, login},
		GAME_INFO: 				{path: '/game-details/:id/info', init, login},
		GAME_CHALLENGES:		{path: '/game-details/:id/challenges', init, login},
		GAME_SIMILAR:			{path: '/game-details/:id/similar', init, login},
		GAME_LEADERBOARD:		{path: '/game-details/:id/leaderboard', init, login},
		GAME_MAPPING:	        {path: '/game-details/:id/mapping', init, login},
		GAME_FOLLOWERS:			{path: '/game-details/:id/followers', init, login},
		GAME_CHALLENGE_LEADERBOARD:			{path: '/game-details/:id/challenge-leaderboard', init, login},
		GAME_REWARDS:			{path: '/challenge/:id/rewards/:challengeId', init, login, noHistory},
		GIANT_SLAYER_RESULT:	{path: '/challenge/:id/giant-slayer-result/:challengeId', init, login, noHistory, noHeader},
		MPC_OPPONENTS: 			{path: '/challenge/:id/opponents/:challengeId', init, login, noHistory},
		PAYMENT_SELECTION: 		{path: '/payment-selection/', init, login, noHeader},
		HOW_TO_PLAY: 			{path: '/how-to-play/:id', init, login, noHistory, noHeader},
		HOW_TO_PLAY_GAMEPAD: 	{path: '/how-to-play/:id/gamepad', init, login, noHistory, noHeader},
		HOW_TO_PLAY_KEYBOARD: 	{path: '/how-to-play/:id/keyboard', init, login, noHistory, noHeader},
		HOW_TO_PLAY_TOUCH: 		{path: '/how-to-play/:id/touch', init, login, noHistory, noHeader},
		HOW_TO_PLAY_FIRETV: 	{path: '/how-to-play/:id/firetvremote', init, login, noHistory, noHeader},
		HOW_TO_PLAY_REMOTE: 	{path: '/how-to-play/:id/remote', init, login, noHistory, noHeader},
		HOW_TO_PLAY_INSTRUCTIONS: {path: '/how-to-play/:id/instructions', init, login, noHistory, noHeader},
		USER: 					{path: '/user-details/:id/', init, login},
		USER_PROFILE:			{path: '/user-details/:id/profile', init, login},
		USER_FAVOURITES:		{path: '/user-details/:id/favourites', init, login},
		USER_SETTINGS:			{path: '/user-details/:id/settings', init, login},
		MY_PROFILE: 			{path: '/my-profile', init, login},
		MY_PROFILE_INFO:		{path: '/my-profile/info', init, login},
		MY_PROFILE_SOCIAL:		{path: '/my-profile/social', init, login},
		MY_PROFILE_FAVOURITES:	{path: '/my-profile/favourites', init, login},
		MY_PROFILE_CHALLENGES:	{path: '/my-profile/challenges', init, login},
		MPC_STATUS:				{path: '/mp-challenge-status', init, login},
		GIANT_SLAYER_STATUS:	{path: '/gs-challenge-status', init, login},
		ACHIEVEMENTS:			{path: '/achievements', init, login},
		INBOX:					{path: '/inbox', init, login},
		CHANGE_AVATAR: 			{path: '/change-avatar/:id', init, login},
		RESULTS_WAITING: 		{path: '/results-waiting', init, login, noHistory, noHeader},

        IN_GAME_MENU: {
            path: '/ingame-menu',
            init, login, noHistory, noHeader
        },

		SETTINGS: 				{path: '/settings', init, login},
		SETTINGS_ACCOUNT: 		{path: '/settings/account', init, login},
		SETTINGS_SUPPORT: 		{path: '/settings/support', init, login},
		SETTINGS_HELPFUL_INFO: 		{path: '/settings/helpful-info', init, login},
		SETTINGS_PRIVACY_POLICY:    {path: '/settings/privacy-policy', init, login},
		SETTINGS_TERMS_OF_USE: 		{path: '/settings/terms-of-use', init, login},
		SETTINGS_HEALTH_SAFETY: 	{path: '/settings/health-safety', init, login},
		SETTINGS_DEBUG : 		{path: '/settings/debug', init, login},
		TEST_CONNECTION: 		{path: '/test-connection', init, login, noHeader},
		EVENT_FROM: 	{path: '/event-form', init, login, noHeader},
		TOURNAMENTS: 			{path: '/tournaments', init, login},
		TOURNAMENTS_LIST: 			{path: '/tournaments/list', init, login},
		TOURNAMENTS_INFO: 		{path: '/tournaments/info', init, login},
		TOURNAMENTS_PARTICIPANTS: 		{path: '/tournaments/participants', init, login},
		TOURNAMENTS_LEADERBOARD: 		{path: '/tournaments/leaderboard', init, login},
		WAYSUN_PAYMENT: 		{path: '/waysun/payment', init, noHeader},
		WAYSUN_PAYMENT_SUCCESS: 		{path: '/waysun/payment/success', noHeader},
        WAYSUN_PAYMENT_FAILED: 		{path: '/waysun/payment/fail', noHeader},
        WEBRTC_GAME: {
            path: '/webrtc-game',
            init,
            login,
            noHistory,
            noHeader
        },
        IN_GAME_HUD: { path: '/in-game-hud', init, login, noHistory, noHeader},
        TRUE_AUTH: 			{path: '/true/auth', login, init, noHeader},
        TRUE_PAYMENT_SELECTION: 		{path: '/true/payment/selection', login, init, noHeader},
	};
};

const replaceParams = (path, params = {}) => {
    Object.keys(params).forEach((k) => {
        path = path.replace(`:${k}`, params[k]);
    });
    return path;
};

export const matchRoute = (pathname, routeList = ROUTES) => {
    for (const routename in routeList) {
        if (routeList.hasOwnProperty(routename)) {
            const route = routeList[routename];
            if (matchingPaths(pathname, route.path)) return route;
        }
    }
};

// XXX Matches url segments, please replace with something from a library if found
const matchingPaths = (a, b) => {
    const aslices = a.split('/').filter(x => !!x);
    const bslices = b.split('/').filter(x => !!x);
    if (aslices.length !== bslices.length) return false;
    if (aslices.join() === bslices.join()) return true;
    for (let i = 0; i < aslices.length; i++) {
        const aseg = aslices[i];
        const bseg = bslices[i];
        if (aseg === bseg) continue;
        if (aseg[0] === ':' || bseg[0] === ':') continue;
        return false;
    }
    return true;
};

const filterSavedRouter = (savedRoute) => {
    switch (savedRoute.route.path) {
        case ROUTES.WEBRTC_GAME.path:
        case ROUTES.GAME_REWARDS.path:
        case ROUTES.GIANT_SLAYER_RESULT.path:
        case ROUTES.MPC_OPPONENTS.path:
        case ROUTES.MPC_STATUS.path:
        case ROUTES.GIANT_SLAYER_STATUS.path:
            savedRoute.route = ROUTES.HOMEPAGE;
            savedRoute.params = {};
            savedRoute.search = {};
            break;
        default:
            break;
    }

    return savedRoute;
};

export const navigateToLocation = (route, params={}, search) => {
    KeepAlive.userInteracted();
    if (typeof route === 'string') {
        let matchedRoute = matchRoute(route, ROUTES);
        if(!matchedRoute) {
            matchedRoute = ROUTES.HOMEPAGE;
        }
        route = matchedRoute;
    }
    const selectedRoute = guardRouting(route, params, search);


    if(!selectedRoute)return;

    setBackground(selectedRoute.path);

    const pathname = replaceParams(selectedRoute.path, params);
    if (typeof search !== 'string') search = objectToParams({...search, ...params});
    const locationObj = { pathname, search, hash: params.hash, original: route };

    analyticsPageView(pathname);

    if( lastHistoryLocation.includes(ROUTES.HOW_TO_PLAY.path)
        || lastHistoryLocation.includes(ROUTES.RESULTS_WAITING.path)
        || lastHistoryLocation.includes(ROUTES.IN_GAME_MENU.path)
        || lastHistoryLocation.includes(ROUTES.WEBRTC_GAME.path)
        || lastHistoryLocation.includes(ROUTES.IN_GAME_HUD.path)
        || lastHistoryLocation.includes(ROUTES.MPC_STATUS.path)
        || lastHistoryLocation.includes(ROUTES.GIANT_SLAYER_STATUS.path)
        || lastHistoryLocation.includes(ROUTES.MPC_OPPONENTS.path)
    ) {
        store.dispatch(replace(locationObj));
    } else {
        store.dispatch(push(locationObj));
    }
    lastHistoryLocation = locationObj.original.path;
};

export const navigateToSavedLocation = () => {
    const state = store.getState()
    let savedRoute = state.routing.savedRoute;

    store.dispatch(SaveRoute(null));
    if (savedRoute && savedRoute.route) {
        savedRoute = filterSavedRouter(savedRoute);
        navigateToLocation(savedRoute.route, savedRoute.params, savedRoute.search);
    } else {
        navigateToLocation(ROUTES.HOMEPAGE);
    }
};

export const getLastHistoryLocation = () => {
    return lastHistoryLocation;
}

export const navigateBack = () => {
    const state = store.getState();

    if (state.routing.previousRoute && state.routing.previousRoute.path !== "/") {
        setBackground(state.routing.previousRoute.path);
        lastHistoryLocation = '';
        store.dispatch(goBack());
    }
    else { // We don't know where to go back to, so default to the homepage
        navigateToLocation(ROUTES.HOMEPAGE);
    }
};

const setBackground = (path) => {
    const mainElement = document.querySelector('.main');
    if (mainElement) {
        switch (path) {
            case ROUTES.HOMEPAGE.path:
            case ROUTES.DAILY_CHALLENGES.path:
            case ROUTES.TOURNAMENTS_LIST.path:
            case ROUTES.ACHIEVEMENTS.path:
            case ROUTES.INBOX.path:
            case ROUTES.SEARCH.path:
            case ROUTES.MY_PROFILE_INFO.path:
            case ROUTES.MY_PROFILE_CHALLENGES.path:
            case ROUTES.MY_PROFILE_SOCIAL.path:
            case ROUTES.MY_PROFILE_FAVOURITES.path:
                mainElement.classList.remove('auth-flow-background');
                mainElement.classList.add('special-background');
                
                break;
            case ROUTES.LOGIN.path:
            case ROUTES.LOGIN_CREDENTIALS.path:
            case ROUTES.SIGNUP.path:
            case ROUTES.SIGNUP_PASSWORD.path:
            case ROUTES.SIGNUP_GAMERTAG.path:
                if (!isLowSpecDevice()) {
                    mainElement.classList.add('auth-flow-background');
                }
                break;
            default:
                mainElement.classList.remove('special-background');
                mainElement.classList.remove('auth-flow-background');
        }
    }
}

export const ROUTES = composeRoutes();

const DEEP_LINK_ROUTE_DEFAULT = {
    route: ROUTES.HOMEPAGE,
    params: { }
}

export const getRouteForDeepLink = (deeplinkJSON) => {
    try {
        let resp = JSON.parse(deeplinkJSON);
        const {challengeid, subpage, gameid, tournamentid} = resp;

        if(gameid) {
            if(challengeid) {
                //a challenge deep link
                if(subpage===DEEP_LINK_SUB_PAGE_INFO) {
                    //challenge info
                    return {
                        route: ROUTES.GAME_CHALLENGES,
                        params: {
                            id: gameid,
                            challengeId: challengeid,
                            focusElement: challengeid
                        }
                    };
                } else if(subpage===DEEP_LINK_SUB_PAGE_LEADERBOARD) {
                    //challenge leaderboard
                    return {
                        route: ROUTES.GAME_CHALLENGE_LEADERBOARD,
                        params: {
                            id: gameid,
                            challengeId: challengeid,
                            focusElement: externalFocusableLeaderboardComponent.PLAY_BUTTON
                        }
                    };
                }
            } else {
                //a game deep link
                if(subpage===DEEP_LINK_SUB_PAGE_INFO) {
                    //game leaderboard
                    return {
                        route: ROUTES.GAME_INFO,
                        params: {
                            id: gameid,
                            focusElement: externalFocusableGameDetailsComponent.PLAY_BUTTON
                        }
                    }
                } else if(subpage===DEEP_LINK_SUB_PAGE_LEADERBOARD) {
                    //game info
                    return {
                        route: ROUTES.GAME_LEADERBOARD,
                        params: {
                            id: gameid,
                            focusElement: externalFocusableLeaderboardComponent.PLAY_BUTTON
                        }
                    };
                }
            }
        } else if(tournamentid) {
            //a tournament deep link
            if(subpage===DEEP_LINK_SUB_PAGE_INFO) {
                //tournament info
                return {
                    route: ROUTES.TOURNAMENTS_INFO,
                    params: {
                        id: tournamentid
                    }
                }
            } else if(subpage===DEEP_LINK_SUB_PAGE_LEADERBOARD) {
                //tournament leaderboard
                return {
                    route: ROUTES.TOURNAMENTS_LEADERBOARD,
                    params: {
                        id: tournamentid,
                        focusElement: externalFocusableLeaderboardComponent.PLAY_BUTTON
                    }
                }
            }
        }
    } catch(e) {
        console.warn('JSON Parse ERROR in link ', e);
    }

    return DEEP_LINK_ROUTE_DEFAULT;
};
