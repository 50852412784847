import React from 'react';
import './xbox-crossplay-only-popup.component.less';
import xboxCrossplayEnabledIconBig from "../../../assets/images/generic-icons/xbox-crossplay-enabled-icon-big.png";
import GenericPopup from "../generic-popup/generic-popup.component";

const XboxCrossplayOnlyPopup = () => {

	return (
		<GenericPopup
			primaryButtonClassName='xbox-crossplay-only-popup__btn'
			okButtonLabel='Got it!'
			genericClassName='xbox-crossplay-only-popup-container'
			title="Cross Play Only Feature"
			message={
				<div className='xbox-crossplay-only-popup'>
					<div className='xbox-crossplay-only-popup__col'>
						<img className="xbox-crossplay-only-popup__img" src={xboxCrossplayEnabledIconBig} alt="Slayers" />
					</div>
					<div className='xbox-crossplay-only-popup__col'>
						<p className='xbox-crossplay-only-popup__paragraph'>
							Please enable cross play to compete in global Antstream Arcade tournaments.
						</p>
						<p className='xbox-crossplay-only-popup__paragraph'>
							This can be found within your Xbox settings.
						</p>
					</div>
				</div>
			}
		/>
	);
};

export default XboxCrossplayOnlyPopup;
