import React, { useState } from 'react';
import inputManager, { PARENT_ENTRANCE } from '../../assets/lib/inputmanager';
import './select-avatar.component.less';
import {Avatar} from '../common/avatar/avatar.component';
import ListContainer, { LIST_TYPES } from '../common/list/list-container.component';
import { Button } from '../common/button/common-button.component';
import { navigateToLocation, ROUTES } from '../../app.router';

const defaultValuesForList = {
	type: LIST_TYPES.GRID,
	parentEntrance: PARENT_ENTRANCE.BOTH
}

const parentListNamePrefix = 'row_';
const perRow = 6 // items per row   
export const SelectAvatarComponent = ({avatarList, handleSelect, initalAvatar = null, showSkipButton = false}) => {
	if(!avatarList || avatarList.length===0) return null;
	const filteredAvatarList = avatarList.filter(ava => ava.isHidden !== true);

	const [selectedId, setSelectedId] = useState(initalAvatar);

	const onListGenerateComplete = () => {
		// focus the first avatar in the first row (list)
		inputManager.setCurrentChildById(filteredAvatarList[0]._id, `${parentListNamePrefix}0`);
	};

	const onTileClick = (tileId) => {
		if(selectedId === tileId) {
			handleSelect && handleSelect(tileId)
		}
		setSelectedId(tileId)
	};

	const handleSkipClick = () => {
		handleSelect && handleSelect(filteredAvatarList[0]._id)
	};

	const avatarListRows = filteredAvatarList
		.reduce((accum, item, index) => { 
			const chunkIndex = Math.floor(index / perRow)
				if(!accum[chunkIndex]) {
					accum[chunkIndex] = [] // start a new chunk
				}
				accum[chunkIndex].push(item)
				return accum
			}, []
		).map((avatarListRow, index) => {
			return {
				id: `${parentListNamePrefix}${index}`,
				data: avatarListRow,
				...defaultValuesForList,
				shouldComponentForceUpdate: true,
				renderer: (props) => (
					<Avatar
						{...props}
						userData={{profile_image_id: props.item.type}}
						currentSelectedAvatar={selectedId === props.id}
					/>
				)
			}
		})

	return(
		<div className="select-avatar-component" >
			<div className="select-avatar-component-header">
				<h1>Avatar</h1>
				<div className='select-avatar-box'>
					<p>Select your avatar.</p>
					{showSkipButton && <Button
						className="secondary select-avatar-skip-button"
						childId="Skip-avatar-selecting"
						onClick={handleSkipClick}
					>Skip</Button>}
				</div>
			</div>
			<div className="avatar-list-container">
				<ListContainer
					lists={avatarListRows}
					onTileClick={onTileClick}
					onListGenerateComplete={onListGenerateComplete}
				/>
			</div>
		</div>
	);
}

export default SelectAvatarComponent;