import React, {useEffect} from 'react';
import { useSelector } from 'react-redux'

import {startPaymentProcess} from '../../app.actions';
import { navigateBack } from '../../app.router';

import { Button } from '../common/button/common-button.component';

import gameIcon from '../../assets/images/generic-icons/game-icon.svg';
import challengesIcon from '../../assets/images/generic-icons/challenges-icon.svg';
import tournamentsIcon from '../../assets/images/generic-icons/tournaments-icon.svg';
import antstreamLogo from '../../assets/images/global/antstream_logo_latest.svg';

import './free-tier-information.component.less';
import inputManager from '../../assets/lib/inputmanager';
import { getFreeTierChallengesInformation, getFreeTierGamesInformation, getFreeTierTournamentsInformation, getRoutingSearch } from '../../app.selectors';

export const externalFocusableComponent = {
	SUBSCRIBE_BUTTON: 'subscribeButton',
	BACK_BUTTON: 'backButton',
}

export const FreeTierInformation = () => {
    const {focusElement} = useSelector(state => getRoutingSearch(state))
    const challengesInformation =  useSelector(state => getFreeTierChallengesInformation(state))
    const tournamentsInformation =  useSelector(state => getFreeTierTournamentsInformation(state))
    const gamesInformation =  useSelector(state => getFreeTierGamesInformation(state))

    useEffect(() => {
        focusElement && inputManager.setCurrentChildById(focusElement)
      },[]);
    
    const freeTierElementsArray = [
        {
            icon: gameIcon,
            alt: 'Games Icon',
            value: gamesInformation && `${gamesInformation.limit} Games`
        },
        {
            icon: challengesIcon,
            alt: 'Challenges Icon',
            value: challengesInformation && `${challengesInformation.limit} Challenges`
        },
        {
            icon: tournamentsIcon,
            alt: 'Tournaments Icon',
            value: tournamentsInformation && `${tournamentsInformation.limit} Tournament Sessions`
        },
    ]

    return (
        <div className="free-tier-information">
            <img
                className="free-tier-information-logo"
                src={antstreamLogo}
                alt="Antstream Logo"
            />
            <h1 className="free-tier-information-header">You are on a limited subscription</h1>
            <div className="free-tier-information-content">
                <p className="free-tier-information-text"> This means you still have access to use Antstream, but you can only play a set amount of game sessions. Over a 24 hour period, you now have access to:</p>
                <div className="free-tier-information-icon-groups">
                    {freeTierElementsArray.map(({icon, alt, value}) => (
                        <div key={alt|| icon}className='free-tier-information-icon-group'>
                            {icon && 
                                <img 
                                    className='free-tier-information-icon-group-icon'
                                    src={icon}
                                    alt={alt}
                                />    
                            }
                            { value && 
                                <p className={`free-tier-information-icon-group-text`}>
                                    {value}
                                </p>
                            }
                        </div>
                        )
                    )}
                </div>
                <p className="free-tier-information-text" >
                Check your remaining sessions at any point by going to Settings. If you run out of sessions, you’ll need to wait for your energy to refill. If you want to get back to unlimited gaming, just update your subscription in Settings.
                </p>
                <div className="free-tier-information-button-group">
                    <Button
                        key="subscribeButton"
                        className="free-tier-information-button primary"
                        childId='subscribeButton'
                        onClick={() => {
                            startPaymentProcess(false)
                        }}>
                        I want full access
                    </Button>
                    <Button 
                        key="backButton"
                        className="free-tier-information-button primary"
                        onClick={() => navigateBack()}
                        childId='backButton'
                    > 
                            Got it!
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default FreeTierInformation;
