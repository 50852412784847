import React, { useEffect, useState, useRef } from 'react';
import './daily-challenges.component.less';
import { connect } from 'react-redux';
import rocketIcon from '../../assets/images/daily-challenges/rocket-icon-thick-stroke.png';
import {
    getChallengesByCategory,
    getCurrentDailyChallenge,
    getDailyChallengeConfiguration,
    playDailyChallengeAction
} from './daily-challenges.actions';
import DailyChallengesLeaderboard from './leaderboard/daily-challenges-leaderboard.component';
import DailyChallengesHistory from './history/history.component';
import DailyChallengesInfo from './info/info.component';
import { getGameLists } from '../../entities/entities.selectors';
import ListContainer, { LIST_TYPES } from '../common/list/list-container.component';
import inputManager, { PARENT_ENTRANCE } from '../../assets/lib/inputmanager';
import { navigateToLocation, ROUTES } from '../../app.router';
import {
    getChallengesByCategoryLists,
    getChallengesByCategoryListsFetchedAt,
    getIsChallengesByCategoryFetched
} from './daily-challenges.selectors';
import ChallengeTile from '../common/tiles/tile-challenge.component';
import { setSelectedListIndex, setSelectedTileId } from '../../app.actions';
import { getPreviousRoute } from '../../app.selectors';
import deviceInfo from '../../assets/lib/deviceInfo';

const REFRESH_PREVIEW_DELAY = 200;
const VIEW_MODE_INFO = 'info-view';
const VIEW_MODE_CAROUSEL = 'carousel-view';
let prevTile = null;

const DailyChallengesComponent = ({ dispatch, isChallengesByCategoryFetched, challengesByCategoryLists, ...props }) => {
    const [viewMode, setViewMode] = useState(VIEW_MODE_CAROUSEL);
    const dcMainSection = useRef(null);
    const dcMainContainer = useRef(null);
    const [topContainerHidden, setTopContainerHidden] = useState(false);
    const [scrollTop, setScrollToTop] = useState(false);
    let infoTimerId = null;
    let clickTimer = null;
    let topContainerHiddenPrivate = false;
    let gamesList = useRef(null);

    useEffect(() => {
        dispatch(getCurrentDailyChallenge());
        dispatch(getDailyChallengeConfiguration());
        dispatch(playDailyChallengeAction(false));

        if (!isChallengesByCategoryFetched || isChallengesListExpired()) {
            dispatch(getChallengesByCategory());
        }

        defaultFocus();

        return () => {
            dispatch(setSelectedListIndex(null));
            dispatch(setSelectedTileId(null));
        }
    }, []);

    /**
     * expired if last time fetched more than 1 day ago
     **/
    function isChallengesListExpired() {
        const { challengesByCategoryListsFetchedAt } = props;
        if (!challengesByCategoryListsFetchedAt) return true;

        const lastFetchedAt = new Date(challengesByCategoryListsFetchedAt);
        lastFetchedAt.setDate(lastFetchedAt.getDate() + 1);

        return lastFetchedAt.getTime() < new Date().getTime();
    }

    function isMainSectionScrolled() {
        if (!dcMainContainer?.current) return false;

        const mainSectionRects = dcMainSection?.current?.getBoundingClientRect();
        const mainPaddingTop = parseInt(window.getComputedStyle(dcMainContainer?.current).paddingTop, 10);
        const headerHeight = document.getElementById('header')?.clientHeight;

        let appContainerPaddingTop = 0;
        if (deviceInfo.safeAreaVertical && deviceInfo.safeAreaVertical !== 1) {
            appContainerPaddingTop = parseInt(window.getComputedStyle(document.getElementById('app-main-container'))?.paddingTop, 10)
        }

        // Detect if main daily challenge section is on top by comparing headerHeight with Main section position +- 1px
        const isMainSectionOnTop = Math.abs(Math.round(mainSectionRects?.y) - (mainPaddingTop + headerHeight + appContainerPaddingTop)) <= 1;
        return !isMainSectionOnTop;
    }

    function onTileFocus(itemId, listIndex) {
        const delayTimeout = REFRESH_PREVIEW_DELAY;
        clearTimeout(infoTimerId);

        infoTimerId = setTimeout(() => {
            if(!topContainerHidden) {
                if (viewMode !== VIEW_MODE_INFO) {
                    setViewMode(VIEW_MODE_INFO);
                }

                if (deviceInfo.isSafeAreaTurnedOn()) {
                    hideTopContainer();
                } else if (!isMainSectionScrolled()) {
                    hideTopContainer();
                }
            }
        }, delayTimeout);
    }

    function showTopContainer(viewMode) {
        topContainerHiddenPrivate = false;
        setViewMode(viewMode);
        setTopContainerHidden(false);
    }
    function hideTopContainer() {
        if (topContainerHidden) return;
        topContainerHiddenPrivate = true;
        setTopContainerHidden(true)
    }

    function onListScroll(event) {
        const className = event.target.className;
        if (className === 'list-container') {
            if (topContainerHidden && gamesList?.current?.children[0].scrollTop === 0) {
                showTopContainer(VIEW_MODE_CAROUSEL);
            } else if (!clickTimer) {
                // hideTopContainer();
            }
        }
    }

    function onGameTileClicked(gameId, challengeId, itemId, listIndex) {
        prevTile = { itemId: itemId, listIndex: listIndex };

        navigateToLocation(ROUTES.GAME_CHALLENGES, {
            id: gameId,
            focusElement: challengeId,
            challengeId,
            prevPage: ROUTES.DAILY_CHALLENGES.path
        });
    }

    /**
     * used to set previously selected tile
     **/
    function onListGenerateComplete() {
        const isPreviousRouteGameDetailPage = props.previousRoute.path.includes(ROUTES.GAME.path);
        if (prevTile && isPreviousRouteGameDetailPage) {
            setTimeout(() => {
                inputManager.setCurrentChildById(prevTile.itemId, prevTile.listIndex);
                prevTile = null;
            }, 10);
        }
    }

    function defaultFocus() {
        if (!inputManager.currentChild) {
            setTimeout(() => {
                inputManager.setCurrentChildById('dailyChallenges', 'navBar');
            }, 500);
        }
    }

    function renderGamesList() {
        if (!isChallengesByCategoryFetched) return null;
        if (!challengesByCategoryLists) return null;

        const keys = Object.keys(challengesByCategoryLists);
        if (!keys?.length) return null;

        const lists = [];
        keys.forEach((categoryId, index) => {
            /**
             * @type {{
             *     challengeType: string,
             *     list: Array<{
             *     _id: string,
             *     game: {
             *         _id: string,
             *         title: string,
             *         description: string,
             *         coverImage: string,
             *     }
             *     challenge: {
             *         _id: string,
             *         title: string,
             *         coverImage: string,
             *         type: string,
             *         gameId: string
             *     }
             * }>
             * }}
             **/
            const categoryChallengesList = challengesByCategoryLists[categoryId];

            lists.push({
                id: categoryChallengesList.challengeType,
                title: categoryChallengesList.challengeType.split('_').join(' '),
                data: categoryChallengesList.list,
                type: LIST_TYPES.HORIZONTAL,
                parentEntrance: PARENT_ENTRANCE.ONLY_VERTICAL,
                renderer: (props) => {
                    return (
                        <div className="game-details-challenge-wrapper">
                            <ChallengeTile
                                {...props}
                                childId={props.id}
                                useShouldComponentUpdate={true}
                                innerInViewUsed={true}
                                noUnlock={true}
                                handleClick={() => {
                                    if (props?.selected) {
                                        onGameTileClicked(
                                            props?.item?.game?._id,
                                            props?.item?.challenge?._id,
                                            props?.id,
                                            props?.listId
                                        );
                                    }
                                }}
                            />
                        </div>
                    )
                }
            });
        });

        return (
            <ListContainer
                lists={lists}
                onTileFocus={onTileFocus}
                onListGenerateComplete={onListGenerateComplete}
                scrollToTop={scrollTop}
            />
        );
    }

    function scrollToTopOfChallengesList() {
        if (scrollTop) {
            setScrollToTop(false);
        }
        setScrollToTop(true);
    }

    function handlePlayButtonFocus() {
        if (!topContainerHidden) return;
        showTopContainer();
        scrollToTopOfChallengesList();
    }

    return (
        <div ref={dcMainContainer} className={`daily-challenges ${topContainerHidden ? 'no-overflow' : ''}`}>
            <h1 className='daily-challenges__title'>
                <img src={rocketIcon} alt='rocket' className='daily-challenges__title-icon'/>
                <span>Daily Challenge</span>
            </h1>
            <div ref={dcMainSection} className={`daily-challenges__main-container ${topContainerHidden ? 'hidden' : ''}`}>
                <DailyChallengesInfo handlePlayButtonFocus={handlePlayButtonFocus} />
                <DailyChallengesLeaderboard />
                <DailyChallengesHistory />
            </div>
            <div
                className={`daily-challenges__for-you-container ${topContainerHidden ? 'top-container-hidden' : ''}`}
                ref={gamesList}
                onScroll={onListScroll}
            >
                {renderGamesList()}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        previousRoute: getPreviousRoute(state),
        isChallengesByCategoryFetched: getIsChallengesByCategoryFetched(state),
        challengesByCategoryLists: getChallengesByCategoryLists(state),
        challengesByCategoryListsFetchedAt: getChallengesByCategoryListsFetchedAt(state),
        gameLists: getGameLists(state)
    };
};

const DailyChallenges = connect(mapStateToProps)(DailyChallengesComponent);
export default DailyChallenges;
