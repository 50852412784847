import React from "react";

import { getRemainingTime } from "../../../assets/lib/utils";

import "./countdown-short-time.component.less";
import { format as dateFormat, formatDistanceToNowStrict } from 'date-fns'
import enLocale from 'date-fns/locale/en-US'

function CountdownShortTime({ endTimeStamp, isDailyChallenge }) {
    let countdownStatus = null;
    let countdownDefault = null;

    const currentDate = new Date();
    const endDate = new Date(0);
    endDate.setUTCSeconds(endTimeStamp);

    let countdownTextStatus = '';
    let countdownTextTitle = '';
    let countdownTextSubtitle = '';

    if (endDate <= currentDate) {
        // finished
        countdownStatus = 'countdownEnded';
        countdownTextStatus = '';
        countdownTextTitle = 'Ended';
        countdownTextSubtitle = dateFormat(endDate,'do MMM',{locale:enLocale});
    } else {
        // in-progress
        if (getRemainingTime(endDate).hours > 0) {
            // more than 1 hour left
            countdownStatus = 'countdownEndsFarFuture';
            countdownTextStatus = 'Ends in';
            countdownTextTitle = formatDistanceToNowStrict(endDate, { addSuffix: false, locale: enLocale});
            countdownTextSubtitle = '';
        } else {
            // less than 1 hour left
            countdownStatus = 'countdownEndsNearFuture';
            countdownTextStatus = 'Ends in';
            countdownTextTitle = formatDistanceToNowStrict(endDate, { addSuffix: false, locale: enLocale});
            countdownTextSubtitle = '';
        }

        if (isDailyChallenge) { // it will split "5" and "days" to multiple lines
            const numberAndText = countdownTextTitle.split(' ');
            countdownTextTitle = numberAndText[0];
            countdownTextSubtitle = numberAndText[1];
        }
    }

    const classes = `countdown-component ${countdownStatus}`;

    return (
        <div className={classes}>
            <div className="status">{countdownTextStatus}</div>
            <div className="countdown-title">{countdownTextTitle}</div>
            <div className="sub-title">{countdownTextSubtitle}</div>
        </div>
    );
}

export default CountdownShortTime;
