import React, { Component } from 'react';
import './list-container.component.less';
import { getNavigationData, splitArrayByChunks } from '../../../assets/lib/utils';
// import TweenLite, {Power4} from 'gsap/TweenLite';
// import TimelineLite from 'gsap/TimelineLite';
import gsap from 'gsap';
import List from './list.component';
import throttle from 'lodash/throttle';
import { isLowSpecDevice } from '../../../app.helpers';
import { InView } from 'react-intersection-observer';

export const LIST_TYPES = {
	HORIZONTAL : "horizontal",
	VERTICAL : "vertical",
	GRID : "grid",
};

export const SCROLL_EDGE_TYPES = {
	START : "START",
	END : "END",
};

export const SCROLL_DIRECTION_TYPES = {
	UP : "UP",
	DOWN : "DOWN",
};

export let checkScrollPosition = null;
export let getDurationForScrollPosition = null;

class ListContainer extends Component  {
	constructor(props) {
		super(props);

		this.listNavigations = {};
		this.listWrapperNavigations = {};
		this.verticalScrollPosition = 0;
		this.verticalCurrentScrollPosition = 0;
		this.listContainerRef = null;
		this.listContainerInnerRef = null;
		this.listContainerNavigation = null;

		// to scroll lists horizontally with mouse dragging
		this.initialScrollLeft = 0;
		this.mouseDownX = 0;
		this.isMousePressed = false;
		this.isMouseDragging = false;
	}

	componentDidMount() {
		if (this.props.onScrollEdge) {
			this.throttledOnScrollEdge = throttle(this.props.onScrollEdge, 600);
		}

		if(this.props.onListGenerateComplete && this.props.lists && this.props.lists.length > 0) {
			this.props.onListGenerateComplete && this.props.onListGenerateComplete(); // LEGACY DO NOT USE IN FUTURE
		}

		window.addEventListener('resize', this.refreshNavigationData);

		checkScrollPosition = this.checkScrollPosition;
		getDurationForScrollPosition = this.getDurationForScrollPosition;
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.refreshNavigationData);
	}

	componentDidUpdate(prevProps){
		if(prevProps.newDataLoading && !this.props.newDataLoading) {//new data loaded
			this.refreshNavigationData();
		}

		if(!prevProps.scrollToTop && this.props.scrollToTop) {
			this.autoScroll(0, undefined, this.onComplete,1,0);
		}
	}

	onScrollToTopComplete = () => {
		this.verticalCurrentScrollPosition = 0
		this.verticalScrollPosition = 0
	}

	autoScroll = (scrollTo, scrollSpeed, onComplete, customDuration, initalDelay = 3) => {
		const duration = customDuration || (scrollTo / scrollSpeed);

		const timeline = gsap.timeline({delay: initalDelay});
		timeline.to(this.listContainerRef, duration, {scrollTo:{y:scrollTo}});
		timeline.to(this.listContainerRef, 0.1, {scrollTo:{y:0}, delay: 1, onComplete});
	};

	refreshNavigationData = () => {
		Object.values(this.listNavigations).forEach((listObject) => {
			listObject.navigationData = getNavigationData(listObject.element);
		});

		Object.values(this.listWrapperNavigations).forEach((listWrapperObject) => {
			listWrapperObject.navigationData = getNavigationData(listWrapperObject.element);
		});

		this.listContainerNavigation = getNavigationData(this.listContainerRef);

		if(this.verticalCurrentScrollPosition===0 && this.listContainerInnerRef) {
			this.listContainerRef.scrollTop = this.listContainerInnerRef.clientHeight-this.lastScrollLength;
		}
	};

	onTileFocus = (tileId, listId , tileNavigationData, index) => {
		this.checkScrollPosition(listId,tileNavigationData, tileId, index);
		if(this.props.onTileFocus) this.props.onTileFocus(tileId,listId);
	};

	onTileBlur = (tileId, listId) => {
		if(this.props.onTileBlur) this.props.onTileBlur(tileId,listId);
	};

	onTileClick = (tileId, listId, hasChallenges) => {
		if (this.isMouseDragging) {
			return;
		}
		if (this.props.onTileClick) this.props.onTileClick(tileId, listId, hasChallenges);
	};

	getDurationForScrollPosition = (listId) => {
		if (isLowSpecDevice() && listId === 'giantSlayer') { // fix for: Scrolling up to giant slayer automatically pulls the Featured banner into view. It does a weird, unnecessary scroll-up and scroll-down thing which is very disorientating
			return 0;
		}

		return 0.2;
	};

	checkScrollPosition = (listId , tileNavigationData, tileId, index) => {
		const listNavigation = this.listNavigations[listId];
		const listNavigationData = listNavigation.navigationData;

		const listWrapperNavigation = this.listWrapperNavigations[listId];
		const listWrapperNavigationData= listWrapperNavigation.navigationData;

		const newVerticalScrollPosition = this.calculateVerticalScroll(listNavigationData, tileNavigationData, this.verticalScrollPosition, listWrapperNavigationData);

		const tl = gsap.timeline();

		if(newVerticalScrollPosition !== this.verticalScrollPosition) {
			this.verticalScrollPosition = newVerticalScrollPosition;
			tl.to(this.listContainerRef, this.getDurationForScrollPosition(listId), {scrollTo:{y:this.verticalScrollPosition}, ease:'power4.easeOut'});
		}

		if(listNavigation.type === LIST_TYPES.HORIZONTAL) {
			const newHorizontalScrollPos = this.calculateHorizontalScroll(listNavigationData, tileNavigationData, listNavigation.scrollPos);

			if(newHorizontalScrollPos !== listNavigation.scrollPos) {
				listNavigation.scrollPos = newHorizontalScrollPos;

				let nextPosition = listNavigation.scrollPos;
				if (index === 0 && listNavigationData?.pointTopLeft?.y && (listNavigationData.pointTopLeft.y > 0)) {
					nextPosition = listNavigation.scrollPos - listNavigationData.pointTopLeft.y;
				}

				tl.to(listNavigation.listRef, 0.1, {scrollTo:{x:nextPosition}});
			}
		}
	};

	calculateVerticalScroll = (listNavigationData, tileNavigationData, verticalScrollPosition, listWrapperNavigation) => {
		if(this.props.isFocusItemListTop){
			return listWrapperNavigation.pointTopLeft.y
		}
		if(tileNavigationData.pointTopLeft.y===0 && listNavigationData.pointTopLeft.y<100) return 0;// if the tile is on the first line scroll above title

		const verticalTilePosStart = listNavigationData.pointTopLeft.y + tileNavigationData.pointTopLeft.y;
		const verticalTilePosEnd = listNavigationData.pointTopLeft.y + tileNavigationData.pointBottomRight.y;

		if(verticalScrollPosition > verticalTilePosStart) {
			return verticalTilePosStart;
		} else if (verticalScrollPosition+this.listContainerNavigation.height < verticalTilePosEnd) {
			return verticalTilePosEnd - this.listContainerNavigation.height;
		}
		return verticalScrollPosition;
	};

	calculateHorizontalScroll = (listNavigationData, tileNavigationData, horizontalScrollPosition) => {
		const tilePosStart = listNavigationData.pointTopLeft.x + tileNavigationData.pointTopLeft.x;
		const tilePosEnd = listNavigationData.pointTopLeft.x + tileNavigationData.pointBottomRight.x;

		if(horizontalScrollPosition > tilePosStart) {
			return tilePosStart;
		} else if (horizontalScrollPosition+this.listContainerNavigation.width < tilePosEnd) {
			return tilePosEnd - this.listContainerNavigation.width +10;// Add padding at the end so the edge of the selected tile does not get cut off
		}
		return horizontalScrollPosition;
	};

	onListRef = (list, element) => {
		if(element) {
			if(!this.listNavigations[list.id])this.listNavigations[list.id] = {};
			const listNavigation = this.listNavigations[list.id];
			listNavigation.element = element;
			listNavigation.type = list.type;
			listNavigation.navigationData = getNavigationData(element);
			listNavigation.listRef = element;
			listNavigation.scrollPos = 0;
		}else{
			delete this.listNavigations[list.id];
		}
	};

	//This is being used to get the entire container size including the title so as to scroll and include title
	onListWrapper = (list, element) => {
		if(element) {
			if(!this.listWrapperNavigations[list.id])this.listWrapperNavigations[list.id] = {};
			const listWrapperNavigation = this.listWrapperNavigations[list.id];
			listWrapperNavigation.element = element;
			listWrapperNavigation.navigationData = getNavigationData(element);
		}else{
			delete this.listWrapperNavigations[list.id];
		}
	};

	onListContainerRef = (element) => {
		if(element) {
			this.listContainerRef = element;
			this.listContainerNavigation = getNavigationData(element);
		} else {
			this.verticalScrollPosition = 0;
			this.listContainerRef = null;
			this.listContainerNavigation = null;
		}
	};

	onListContainerInnerRef = (element) => {
		this.listContainerInnerRef = element;
	};

	onVerticalScroll = (event) => {
		event.preventDefault();

		if(!this.listContainerInnerRef)return null;

		const scrollPos = event.target.scrollTop;
		//https://developer.mozilla.org/en-US/docs/Web/API/Element/clientHeight
		//clientHeight property will round the value to nearest integer. We need a value lower than
		//event.target.scrollTop to cover sub-pixels
		const scrollLength = Math.floor(this.listContainerInnerRef.getBoundingClientRect().height);

		this.verticalScrollPosition = scrollPos;
		this.lastScrollLength = scrollLength;

		if(this.props.setLastScrollDirection) {
			if(this.verticalCurrentScrollPosition<scrollPos) this.props.setLastScrollDirection(SCROLL_DIRECTION_TYPES.DOWN);
			else if(this.verticalCurrentScrollPosition>scrollPos) this.props.setLastScrollDirection(SCROLL_DIRECTION_TYPES.UP);
			this.verticalCurrentScrollPosition = scrollPos;
		}

		if(this.throttledOnScrollEdge){
			if(scrollPos === 0){
				this.throttledOnScrollEdge(SCROLL_EDGE_TYPES.START);
			}else if(scrollPos >= scrollLength-this.listContainerNavigation.height){
				this.throttledOnScrollEdge(SCROLL_EDGE_TYPES.END);
			}
		}
	};

	onMouseDown = (listId, event) => {
		this.initialScrollLeft = this.listNavigations[listId].element.scrollLeft;
		this.mouseDownX = event.clientX;
		this.isMousePressed = true;
	};

	onMouseMove = (listId, event) => {
		if (this.isMousePressed) {
			const diffX = this.mouseDownX - event.clientX;
			this.listNavigations[listId].element.scrollLeft = this.initialScrollLeft + diffX;
			if (Math.abs(diffX) > 10) {
				// tolerate some margin, otherwise normal click would be cancelled because of a few pixels drag
				this.isMouseDragging = true;
			}
		}
	};

	onMouseUp = (event) => {
		this.isMousePressed = false;
		setTimeout(() => {
			this.isMouseDragging = false;
		}, 10);
	};

	onMouseLeave = (event) => {
		this.isMousePressed = false;
		setTimeout(() => {
			this.isMouseDragging = false;
		}, 10);
	};

	renderList = (list, outerInViewUsed, outerInViewStatus) => {
		return (
			<List
				key={list.id}
				list={list}
				listNavigations={this.listNavigations}
				onTileClick={this.onTileClick}
				onTileFocus={this.onTileFocus}
				onTileBlur={this.onTileBlur}
				onListRef={this.onListRef}
				onListWrapperRef={this.onListWrapper}
				newDataLoading={this.props.newDataLoading}

				onMouseDown={(event) => {
					this.onMouseDown(list.id, event)
				}}
				onMouseMove={(event) => {
					this.onMouseMove(list.id, event)
				}}
				onMouseUp={this.onMouseUp}
				onMouseLeave={this.onMouseLeave}

				outerInViewUsed={outerInViewUsed}
				outerInViewStatus={outerInViewStatus}
			/>
		);
	};

	renderLists = (lists) => {
		if (this.props.isLazyLoading) {
			return this.renderListsWithLazyLoading(lists);
		}

		const reactElements = [];

		lists.forEach((list)=> {
			if(list.data?.length>0){
				reactElements.push(this.renderList(list, false, false));
			}
		});

		return reactElements;
	};

	renderListsWithLazyLoading = (lists) => {
		const chunkSize = this.props.lazyLoadingChunkSize || 1;
		const chunkedLists = splitArrayByChunks(lists.filter(l => !!l?.data?.length), chunkSize); 
		const reactElements = [];

		chunkedLists.forEach(lists => {
			reactElements.push(
				<InView key={lists[0]?.id} threshold={0} triggerOnce={true}>
					{({ inView, ref }) => (
						<div ref={ref}>
							{lists.map(list => this.renderList(list, true, inView))}
						</div>
					)}
				</InView>
			);
		});

		return reactElements;
	};

	render() {
		const { lists } = this.props;

		return (
			<div
				className="list-container"
				ref={this.onListContainerRef}
				onScroll={this.onVerticalScroll}
			>
				<div
					className="list-container-inner"
					ref={this.onListContainerInnerRef}
				>	
					{lists && lists.length>0 ? this.renderLists(lists) : null}
				</div>
			</div>
		);
	}
}

export default ListContainer;
