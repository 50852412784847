import * as ACTIONS from './my-profile.actions';
import {generateUUID} from '../../app.helpers';

const initialState = {
	issuedChallenges:[],
	challengesHistory:[],
	activeMenu : {},
	challengesHistoryUpdateId: -1,
};

export const myProfileReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACTIONS.FETCH_USER_CHALLENGES_SUCCESS:
		const {issuedChallenges,challengesHistory} = action.payload;
		const challengesHistoryUpdateId = generateUUID();

		return {
			...state,
			issuedChallenges,
			challengesHistory,
			challengesHistoryUpdateId
		};
	case ACTIONS.UPDATE_MY_MENU_SELECTION: 
		return {
			...state,
			activeMenu: action.payload
		};

	default:
		return state;
	}
};
