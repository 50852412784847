import React, { Component } from 'react';
import './progress-circle.component.less';

class ProgressCircle extends Component {
	render() {
		const {expPercentage, progressCircleCssClass, startFromDeg = 240, isAchievementsPage} = this.props;
		const percentage = Math.floor(expPercentage * 100);
		const rotate1 = isAchievementsPage
			? 0 // start from 12 hours (achievements)
			: startFromDeg - (percentage * (360 / 100)); // start from 8 hours and rotate in reverse (avatar)
		const rotate2 = (percentage * (360 / 100));

		return (
			<div className={`progress-circle-container ${progressCircleCssClass || ''}`} style={{ transform: `rotate(${rotate1}deg)` }}>
				<svg className="avatar-svg">
					<circle className="circle-border" cx="0" cy="0" r="53%" />
				</svg>
				<div className={`progress-circle ${percentage > 50 ? 'over50' : ''}`}>
					<div className="left-half-clipper">
						<div className="first50-bar"></div>
						<div className="value-bar" style={{ transform: `rotate(${rotate2}deg)` }}></div>
					</div>
				</div>
			</div>
		);
	}
}

export { ProgressCircle };
