import React, { Component } from 'react';
import './leaderboard-message.component.less';
import { Avatar } from "../../common/avatar/avatar.component";
import { navigateToLocation, ROUTES } from "../../../app.router";
import { connect } from "react-redux";
import { getAssetsUrlWithFolder } from '../../../app.selectors';
import { FOLDER_TYPES } from '../../../assets/lib/FolderTypes';
import { MESSAGE_TYPES } from '../../../assets/lib/MessageTypes';
import {withBadWordMask} from "../../../assets/lib/utils";
import { FetchGameDetailed } from '../../../entities/games/games.actions';
import { Loader } from '../../common/loader/loader.component';

class LeaderboardMessageComponent extends Component {
	componentDidMount() {
		const { details } = this.props.item;
		const gameId = details.contentObject.gameId || details.contentObject._id;

		if (this.isChallengeMessage() && !this.props.gameIds[gameId]) {
			this.props.dispatch(FetchGameDetailed(gameId));
		}
	}

	isTournamentMessage = () => {
		const { details } = this.props.item;

		return (details.podiumUpdateType === "TOURNAMENT")
			|| (details.socialUpdateType === "TOURNAMENT");
	}

	isChallengeMessage = () => {
		const { details } = this.props.item;

		return (details.podiumUpdateType === "CHALLENGE")
			|| (details.socialUpdateType === "CHALLENGE");
	}

	getMessageDetails = () => {
        const { details } = this.props.item;
        this.details = details;
        const gameId = details.contentObject.gameId || details.contentObject._id;
        this.gameDetails = this.props.gameIds[gameId];

        // if game not found - fetch it
        if (this.isChallengeMessage() && !this.gameDetails) {
			return;
		}

        this.heading = details.type === MESSAGE_TYPES.PODIUM_UPDATE ? 'Podium Overtake!' : 'Leaderboard Overtake!';
        this.isChallenge = false;
        this.isTournament = false;

		if (this.isChallengeMessage()) {
			this.isChallenge = true;
			this.title = "the challenge: " + this.gameDetails.title + " (" + this.details.contentObject.title + ").";
			this.gameTile = this.props.challengeAssetsFolder + this.details.contentObject.img_src;
		}
		else if (this.isTournamentMessage()) {
			this.isTournament = true;
			this.title = "the tournament: " + this.details.contentObject.title + ".";
			this.gameTile = this.props.tournamentsAssetsFolder + this.details.contentObject.detailImage;
		}
		else {
			this.title = this.details.contentObject.title;
			this.gameTile = this.props.gameAssetsFolder + this.details.contentObject.image_uri;

		}
	}

	onItemClick = () => {
		if (this.isChallenge) {
			navigateToLocation(ROUTES.GAME_CHALLENGE_LEADERBOARD, {
				id: this.details.contentObject.gameId,
				challengeId: this.details.contentObject._id,
				focusElement: "playButton"
			});
		}
		else if (this.isTournament) {
			navigateToLocation(ROUTES.TOURNAMENTS_LEADERBOARD, {
				id: this.details.contentObject._id,
				focusElement: "playButton"
			});
		}
		else {
			navigateToLocation(ROUTES.GAME_LEADERBOARD, {
				id: this.details.contentObject._id,
				focusElement: "playButton"
			});
		}
	}

	renderMessage = () => {
		this.getMessageDetails();
		return (
			<div className="leaderboard-message" onClick={this.onItemClick}>
				<div className="message-inner">

					<div className="left-content">
						<h2>{this.heading}</h2>
						<div className="message-inner2">
							<div className="left-content2">
								<Avatar userData={this.details.senderUserObject} />
							</div>
							<div className="right-content2">
								<p><span className="display-name">{withBadWordMask(this.details.senderUserObject.displayName)}</span>{` has beaten your score in ${this.title}`}</p>
							</div>
						</div>
					</div>
					<div className="right-content">
						<img src={this.gameTile} alt="" />
					</div>
				</div>
			</div>
		);
	};

	render() {
		if (this.props.isFetchGameDetailedPending) {
			return <Loader loading={true} />;
		}
		return this.renderMessage();
	}
}

const mapStateToProps = (state) => {
	const gameIds = state.entities.games.byId;
	const gameAssetsFolder = getAssetsUrlWithFolder(state, FOLDER_TYPES.BROCHURE_CONTENT_ASSETS);
	const challengeAssetsFolder = getAssetsUrlWithFolder(state, FOLDER_TYPES.CHALLENGE_GAMES);
	const tournamentsAssetsFolder = getAssetsUrlWithFolder(state, FOLDER_TYPES.TOURNAMENTS);

	return {
		isFetchGameDetailedPending: state.entities.games.isFetchGameDetailedPending,
		gameIds,
		gameAssetsFolder,
		challengeAssetsFolder,
		tournamentsAssetsFolder
	};
};

export const LeaderboardMessage = connect(mapStateToProps)(LeaderboardMessageComponent);
