import ReactGA from 'react-ga4';
import featureInfo, {FEATURES} from './featureInfo';

let isInitialised = false;

// This class seems harmless to use even if the Google site is blocked.
// We could implement our own analytics safely server side, or through 
// the logging mechanism though. Google don't have to involve themselves 
// in the process at all.

const initialise = () => {
    if (isInitialised) {
        return;
    }

    const gaID = window.config.REACT_APP_GOOGLE_ANALYTICS_ID;
    if (!gaID) {
        return;
    }

    ReactGA.initialize(gaID, {
        debug: false,
        titleCase: false
    });

    isInitialised = true;
}

export const analyticsPageView = (pathname) => {
    let pathElements = pathname.split('/');
    if (featureInfo.isSupported(FEATURES.GOOGLE_ANALYTICS)) {
        initialise();
        ReactGA.send({ hitType: 'pageview', page: pathname });
    }
};

export const analyticsEvent = (eventObj) => {
    if (featureInfo.isSupported(FEATURES.GOOGLE_ANALYTICS)) {
        initialise();
        ReactGA.event(eventObj);
    }
}
