import {normalize, schema} from 'normalizr';
import {getChallengeById} from './entities.selectors';
import {getAssetsUrlWithFolder, getLoggedUser} from '../app.selectors';
import {FOLDER_TYPES} from '../assets/lib/FolderTypes';

export const normalizeChallengeHistoryObjects = (state, challengeResultObjects, isHistoryObject) => {
    const resultObjects = [];

    challengeResultObjects.forEach((resultObject) => {
        const normalizedObject = challengeHistoryObjectNormalizer(state,resultObject,isHistoryObject);
        if(normalizedObject)resultObjects.push(normalizedObject);
    });

    return resultObjects;
};

export const challengeHistoryObjectNormalizer = (state, resultObject, isHistoryObject) => {
    const loggedUserObject = getLoggedUser(state);
    const challengesMedalsFolder = getAssetsUrlWithFolder(state,FOLDER_TYPES.CHALLENGE_MEDALS);
    let challengeInstanceId, challengeId, me,opponent,isMultiPlayer, winner, _id, challengeState, medal, componentType, blocked;

    if (isHistoryObject) {
        blocked = resultObject?.blocked ?? false; 
        challengeInstanceId = resultObject.challengeInstanceId;
        challengeId = resultObject.challengeId;
        isMultiPlayer = resultObject.isMultiPlayer;
        _id = resultObject._id.$oid || resultObject._id;
        medal = resultObject.medals && resultObject.medals.length>0 ? resultObject.medals[0] : null;
        if(medal)
        {
             medal.img_src = challengesMedalsFolder + medal.img_src.replace('.svg', '.png');
        }

        if(isMultiPlayer) {
            if(!resultObject.multiPlayerResult)
            {
                return null;
            }

            winner = resultObject.multiPlayerResult.winner;
            componentType = resultObject.multiPlayerResult.componentType;//'newChallengeState' or 'resultState'
            challengeState = winner ? winner === loggedUserObject._id ? 'win' : 'loss' : resultObject.multiPlayerResult.isCompleted ? 'draw' : 'waiting';
            resultObject.multiPlayerResult.playerObjects.forEach((player)=>{
                if(player._id === loggedUserObject._id) {
                    me = player;
                } else {
                    opponent = player;
                }
            })
        } else {
            challengeState = 'solo';
            winner = null;
            me = {
                displayName: loggedUserObject.displayName,
                profile_image_id: loggedUserObject.profile_image_id,
                avatarType: loggedUserObject.avatarType,
                score: resultObject.isFail ? 'FAIL' : resultObject.score,
                medal: resultObject.medals && resultObject.medals.length>0 ? resultObject.medals[0].img_src : null,
            };
            opponent = null;
            
        }
    } else {
        _id = resultObject.challengeInstanceId;
        challengeInstanceId = resultObject.challengeInstanceId;
        challengeState = 'issued';
        winner = null;
        isMultiPlayer = true;
        challengeId = resultObject.challengeObject._id;
        medal = null;

        resultObject.playerObjects.forEach((player) => {
            if(player._id === loggedUserObject._id) {
                me = player;
            } else {
                opponent = player;
            }
        });
    }

    const challengeObject = getChallengeById(state, challengeId);
    
    if(challengeObject){
        const { wager,  replay = null, challengeObject: resultChallengeObject = {} } = resultObject
        const midChallenge = wager || (resultChallengeObject && resultChallengeObject.costs && resultChallengeObject.costs.playCost)
        const replayCost = replay || (resultChallengeObject && resultChallengeObject.costs && resultChallengeObject.costs.playCost)
        const genericCosts = challengeObject.costs && challengeObject.costs.playCost
        const value = midChallenge || genericCosts
        const giantSlayerChallengeId = resultObject.giantSlayerChallengeId || null;
        const giantSlayerDetails = resultObject.giantSlayerDetails || null;

        return {
            _id,
            challengeInstanceId,
            challengeId,
            gameId: challengeObject.gameId,
            gameTitle: resultObject.gameTitle,
            challengeTitle: challengeObject.title,
            challengeDescription: challengeObject.text,
            challengeType: challengeObject.type,
            challengeImage: challengeObject.img_src,
            scoreLabel: challengeObject.score_label,
            me,
            opponent,
            isMultiPlayer,
            medal,
            winner,
            challengeState,
            componentType,
            wager: value,
            replay: replayCost,
            giantSlayerChallengeId,
            giantSlayerDetails,
            blocked
        };
    }else {
        return null;
    }
};

export const gameNormalizer = (preNormalData) => {
    const gameSchema = new schema.Entity('games', undefined, {idAttribute: '_id'});
    return normalize(preNormalData, [gameSchema]);
};

export const challengesNormalizer = (preNormalData) => {
    const challengeSchema = new schema.Entity('challenges', undefined, {idAttribute: '_id'});
    return normalize(preNormalData, [challengeSchema]);
};

export const messageNormalizer = (prenormal) => {
    const messageSchema = new schema.Entity('messages', undefined, { idAttribute: 'messageId' });
    return normalize(prenormal, [ messageSchema ]);
};
