import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { getAvatars } from '../login.selectors';
import {SetUserAvatarAction, UserFullyRegisteredRequestAction} from '../login.actions';
import SelectAvatarComponent from '../../select-avatar/select-avatar.component';
import '../login-global.less';
import {getLoggedUser} from "../../../app.selectors";

export const SignupAvatar = ({handleSelect, isRegularRegistration = true, displayNameProp}) => {
	const avatarList = useSelector((state) => getAvatars(state));
	if(!avatarList) return null;
	const dispatch = useDispatch();
	const {displayName} = useSelector((state) => getLoggedUser(state));

	const onNext = (selectedId) => {
		dispatch(SetUserAvatarAction(selectedId));
		if(isRegularRegistration){
			dispatch(UserFullyRegisteredRequestAction(selectedId, displayName));
			navigateToLocation(ROUTES.HOMEPAGE);
		} else {
			if (handleSelect) {
				handleSelect(selectedId);
			} else {
				navigateToLocation(ROUTES.HOMEPAGE);
				dispatch(UserFullyRegisteredRequestAction(selectedId, displayNameProp));
			}
		}
	};

	return(
		<div className="login-wrapper">
			<div className="login-section--avatar">
				<SelectAvatarComponent
					avatarList = {avatarList}
					handleSelect = {onNext}
					showSkipButton
				/>
			</div>
		</div>
	);
}
export default SignupAvatar;