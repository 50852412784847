import React, { Component } from 'react';
import {SET_SELECTED_CHALLENGE_HISTORY} from '../../challenge/challenge.actions';
import { navigateToLocation, ROUTES } from '../../../app.router';
import {connect} from 'react-redux';
import './message-mpc-issued.component.less';
import {withBadWordMask} from "../../../assets/lib/utils";
import { Avatar } from '../../common/avatar/avatar.component';
import { Button } from '../../common/button/common-button.component';

class MessageMPCIssuedComponent extends Component {
	constructor() {
		super();
		this.onItemClick=this.onItemClick.bind(this);
	}

	onItemClick = (messageId) => {
		this.props.dispatch({ type: SET_SELECTED_CHALLENGE_HISTORY, historyItem:this.props.item.challengeMessageData });
		navigateToLocation(ROUTES.MPC_STATUS, {messageId: this.props.item.messageId});	};

	onAvatarClick = (ev) => {
		ev.stopPropagation();
		const { opponent } = this.props.item.challengeMessageData;
		navigateToLocation(ROUTES.USER_PROFILE, {id: opponent._id}, `?id=${opponent._id}`);
	}

	render() {
		const {challengeImage, challengeTitle, challengeDescription, opponent, gameTitle, challengeId} = this.props.item.challengeMessageData;
		const opponentDisplayName = withBadWordMask(opponent.displayName);

		return (
			<div className="message-mpc-issued" onClick={this.onItemClick}>
				<div className="message-inner">
					<div className="left-content">
						<div className="title-box">
							<Button
								className="primary"
								parentId="messages"
								childId="opponentAvatarButton"
								onClick={this.onAvatarClick}
								stopBounceAnimation={true}
							>
								<div className="image-container">
									{opponent && <Avatar userData={opponent} />}
								</div>
							</Button>
							<h2>{challengeTitle}</h2>
						</div>
						<div><b>{opponentDisplayName}</b> just sent you a <b>{gameTitle}</b> challenge</div>
						<div>{challengeDescription}</div>
					</div>
					<div className="right-content">
						<img src={challengeImage} alt="" />
					</div>
				</div>
			</div>
		);
	}


}

export const MessageMPCIssued = connect()(MessageMPCIssuedComponent);
