import React, {Component} from 'react';
import {connect} from 'react-redux';
import gsap from "gsap";
import {ROUTES, navigateToLocation, navigateBack} from '../../app.router';
import {
	getCurrentGameDetails,
	getIsSaving,
	getIsSavingSuccessful,
	getLastSessionObject
} from '../game-details/game-details.selector';
import {
	PauseGameAction,
	UnpauseGameAction,
	SetGameQuit,
	SetGameFinished,
	SaveRequestedAction,
	SaveTimeoutAction,
	ShowHowToPlay,
	FetchCurrentPosition,
	SetRetryEnabled,
	SetLoadGameSlotAction,
	ToggleOskVisibility
} from '../game-details/game-details.actions';
import {
	getRunningSessionId,
	getCurrentRegionCode,
	getWebviewState,
	getIsInterimScoreEnabled, getRunningSessionObject, getUserIsSubscribed
} from '../../app.selectors';
import {addPopup, closePopup} from '../popup/popup.component';
import QuitGamePopup from '../../components/popup/quit-game-popup/quit-game-popup.component';
import {Button} from '../common/button/common-button.component';
import inputManager from '../../assets/lib/inputmanager';
import {
	saveState,
	updateTournamentPosition,
	quitApp,
	messageNativeClientOnOSKKeyEvent,
	messageVirtualKeyboard
} from '../../assets/lib/game-wrapper';
import SaveButton from '../common/button/save-button.component';
import GenericPopup, {TYPE_HAS_NO_BUTTONS, TYPE_HAS_TWO_BUTTONS} from '../popup/generic-popup/generic-popup.component';
import {getChallengeId} from '../challenge/challenge.selectors';
import featureInfo, {FEATURES} from '../../assets/lib/featureInfo';
import {getTournamentShortCode, isXboxUser} from "../../assets/lib/utils";
import {externalFocusableComponent} from "../leaderboard/leaderboard.component";
import {ControlMenu} from "./control-menu/control-menu.component";
import { HowToPlay } from '../how-to-play';
import {get, includes} from 'lodash';

// Assets
import deviceInfo, { STORE_TYPE_EPIC } from '../../assets/lib/deviceInfo';

import './in-game-menu.component.less';
import InGameOSK from "../ingame-osk/ingame-osk.component";
import InGameHeader from "../ingame-header/in-game-header.component";
import { readOskData, writeOskData } from '../../assets/lib/local-storage';
import { getPopupVisibility } from '../popup/popup.selectors';

class InGameComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOSKActive: false,
			saveContainerVisible: false,
			loadContainerVisible: false,
			inControlMenu: false
		};
		this.saveTimer = null;
		this.currentPositionTimer = null;

		this.onContinueClicked = this.onContinueClicked.bind(this);
		this.onHowToPlayClicked = this.onHowToPlayClicked.bind(this);
		this.onQuitClicked = this.onQuitClicked.bind(this);
		this.onExitClicked = this.onExitClicked.bind(this);
		this.onPopupOkClicked = this.onPopupOkClicked.bind(this);
		this.onRetryClicked = this.onRetryClicked.bind(this);
	}

	componentDidMount() {
		document.getElementById('root').classList.add('background_hidden');
		inputManager.setBackFunction(() => {
			this.onContinueClicked();
		});

		this.startCurrentPositionTimer();

		if (this.props.isWebViewVisible) {
			this.showMenu('continueButton');
		}

		if (this.isRunningOnNativeClientV2()) {
			const oskData = readOskData();
			if (oskData) {
				this.setState({oskData});
			}
		}
	}

	componentWillUnmount() {
		this.componentUnmounted = true;

		this.closeSavingPopup();
		document.getElementById('root').classList.remove('background_hidden');
		inputManager.setBackFunction(null);

		if (this.successfulTimer) {
			clearTimeout(this.successfulTimer);
			this.successfulTimer = null;
			closePopup();
		}

		this.clearCurrentPositionTimer();

		if (this.isRunningOnNativeClientV2()) {
			writeOskData(null);
			this.toggleOSKVisibilityOnNativeClientV2(false);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isSaving && !this.props.isSaving) {
			this.closeSavingPopup();
			if (this.props.isSavingSuccessful) {
				this.showSavedSuccessfulPopup();
			}
			this.saveMessageSent = false;
		}

		if (!prevProps.isWebViewVisible && this.props.isWebViewVisible) {
			this.showMenu('continueButton');
		} else if(prevProps.isWebViewVisible && !this.props.isWebViewVisible) {
			this.hideMenu();
		}

		if (prevProps.showHowToPlay && !this.props.showHowToPlay) {
			this.showMenu('howToPlayButton');
			inputManager.setBackFunction(() => {
				this.onQuitClicked()
			});
		}
	}

	showMenu(selectedButton) {
		// TweenMax.staggerFromTo(".common-button", 0.05, { y : -100, scale:1.3, opacity: 0}, { y : 0, scale:1, opacity: 1, delay: 0.3}, 0.05, () => {
		// 	!this.componentUnmounted && inputManager.setCurrentChildById(selectedButton);
		// });
		document.querySelector(".common-button").style = { y : 0, scale:1, opacity: 1, delay: 0.3};
		!this.componentUnmounted && inputManager.setCurrentChildById(selectedButton);
		// gsap.to(".common-button", 0.05, { y : -100, scale:1.3, opacity: 0}, { y : 0, scale:1, opacity: 1, delay: 0.3}, 0.05, () => {
		// 	!this.componentUnmounted && inputManager.setCurrentChildById(selectedButton);
		// });
	}

	hideMenu() {
		const button = document.querySelector(".common-button");
		if (button) {
			button.style = {opacity: 0};
		}
		// gsap.to(".common-button", 0, { opacity: 0});
	}

	clearCurrentPositionTimer = () => {
		if (this.currentPositionTimer) {
			clearTimeout(this.currentPositionTimer);
			this.currentPositionTimer = null;
		}
	};

	startCurrentPositionTimer = () => {
		if(!this.props.isInterimScoreEnabled) return;

		/** WebRTC has another one position timer in webrtc-game.component.js **/
		if (!deviceInfo.isRunningOnNative()) return;

		if (this.props.lastSessionObject && this.props.lastSessionObject.tournamentId && !this.componentUnmounted) {
			this.props.dispatch(FetchCurrentPosition(
				getTournamentShortCode(this.props.lastSessionObject.tournamentId,this.props.lastSessionObject.challengeId),
				this.props.lastSessionObject.challengeId,
				this.props.lastSessionObject.tournamentId,
				this.onCurrentPositionFetched
			));
		}
	};

	onCurrentPositionFetched = (response) => {
		updateTournamentPosition(response);
		this.clearCurrentPositionTimer();
		this.currentPositionTimer = setTimeout(this.startCurrentPositionTimer, 5000);
	};

	onContinueClicked() {
		inputManager.setBackSound(false);
		this.props.dispatch(UnpauseGameAction());
	}

	onRetryClicked() {
		addPopup(
			<GenericPopup
				message="Are you sure you want to retry?"
				onOkClicked={()=>{
					const {dispatch, sessionId} = this.props;
					dispatch(SetRetryEnabled(true));
					dispatch(SetGameQuit(sessionId));
					dispatch(SetGameFinished());
				}}
				dismissOnBackFunction
				buttonType={TYPE_HAS_TWO_BUTTONS}
			/>
		);
	}

	onHowToPlayClicked() {
		this.props.dispatch(PauseGameAction());
		this.props.dispatch(ShowHowToPlay());
	}

	onQuitClicked() {
		const message = <span>Are you sure you want to leave <br/> this game session?</span>
		addPopup(
			<QuitGamePopup
				message={message}
				onOkClicked={this.onPopupOkClicked}
				onCancelClicked={this.onPopupCancelClicked}
			/>
		);
	}

	onExitClicked() {
		quitApp();
	}

	onPopupOkClicked() {
		const {challengeId} = this.props;

		if (challengeId) {
			navigateToLocation(ROUTES.RESULTS_WAITING);
		} else {
			navigateBack();
		}
		this.props.dispatch(SetGameQuit(this.props.sessionId));
	}

	onPopupCancelClicked = () => {
		inputManager.setBackFunction(() => {
			this.onQuitClicked();
		});
	}

	onButtonFocus = (focus) => {
		if (focus) {
			this.setState({saveContainerVisible: false, loadContainerVisible: false});
		}
	};

	onSaveButtonFocus = (focus) => {
		if (focus) {
			this.setState({saveContainerVisible: true});
		}
	};

	onSaveButtonClicked = () => {
		this.setState({saveContainerVisible: !this.state.saveContainerVisible});
	};

	onLoadButtonClicked = () => {
		this.setState({loadContainerVisible: !this.state.loadContainerVisible});
	};

	onLoadButtonFocus = (focus) => {
		if (focus) {
			this.setState({loadContainerVisible: true});
		}
	};

	onSaveButtonItemClicked = (saveStateData, slot) => {
		if (saveStateData) {
			addPopup(<GenericPopup
				buttonType={TYPE_HAS_TWO_BUTTONS}
				okButtonLabel="Yes"
				cancelButtonLabel="No"
				title={`Overwrite Save Slot ${slot}`}
				onOkClicked={() => {
					this.startSaveStateProcess(slot);
				}}
				onCancelClicked={() => true}
			/>);
		} else {
			this.startSaveStateProcess(slot);
		}
	};

	onLoadButtonItemClicked = (saveStateData, slot) => {
		if (saveStateData) {
			addPopup(<GenericPopup
				buttonType={TYPE_HAS_TWO_BUTTONS}
				okButtonLabel="Yes"
				cancelButtonLabel="No"
				title={`Load Slot ${slot}?`}
				onOkClicked={() => {
					this.startLoadStateProcess(saveStateData, slot);
				}}
			/>);
		}
	};

	startSaveStateProcess = (slot) => {
		if (this.props.isSaving) return;

    	const { region, sessionId } = this.props;

		if (this.saveMessageSent) {
			return;
		}

		if (this.props.webrtc) {
			this.props.webrtc.sendSave({
				sessionId,
				slot,
				region,
			});
		} else {
			saveState(slot, region);
		}

		this.saveMessageSent = true;
		this.props.dispatch(SaveRequestedAction());

		addPopup(<GenericPopup
			title={`Saving in Slot ${slot}`}
			message="Please wait..."
		/>);

		this.saveTimer = setTimeout(() => {
			addPopup(<GenericPopup
				okButtonLabel="Got it!"
				title="Oops! Error saving"
				message="Please try saving again."
			/>);
			this.props.dispatch(SaveTimeoutAction());
		}, 30000);
	};

	startLoadStateProcess = (saveStateData, slot) => {
		const {dispatch, sessionId} = this.props;
		dispatch(SetLoadGameSlotAction(slot, saveStateData.region));
		navigateBack();
		dispatch(SetGameQuit(sessionId));
	};

	closeSavingPopup = () => {
		if (this.saveTimer) {
			if (this.props.isSaving) this.props.dispatch(SaveTimeoutAction());
			clearTimeout(this.saveTimer);
			closePopup();
			this.saveTimer = null;
		}
	};

	handleOSKKeyEvent = (code, pressed) => {
		messageNativeClientOnOSKKeyEvent(code, !!pressed);
	};

	toggleOSKVisibilityOnNativeClientV2 = (isVisible) => {
		this.props.dispatch(ToggleOskVisibility(isVisible));
		messageVirtualKeyboard(isVisible);
	};

	isRunningOnNativeClientV2 = () => {
		return deviceInfo.isRunningOnNativeClientV2();
	};

	showSavedSuccessfulPopup = () => {
		addPopup(<GenericPopup
			title="Successful Save!"
		/>);

		this.successfulTimer = setTimeout(() => {
			this.successfulTimer = null;
			closePopup();
		}, 1000);
	};

	renderSaveButtons = () => {
		if (!this.props.currentGameDetails) return null;
		const {savedStates} = this.props.currentGameDetails;
		const buttonsArray = [];

		let nextEmptySlot = 1;

		for (let slot = 1; slot < 4; slot++) {
			const data = savedStates ? savedStates.find(savedStateData => savedStateData.slot === slot) : null;
			const showPremiumBenefits = !this.props.isSubscribed;
			if (data) nextEmptySlot = slot + 1;
			buttonsArray.push(
				<SaveButton
					data={data}
					key={slot}
					slot={slot}
					onClick={this.onSaveButtonItemClicked}
					childId={"saveButton" + slot}
					disabled={(!data && slot > nextEmptySlot) || this.props.isSaving || this.props.isPopupVisible}
					premiumBenefitsText={showPremiumBenefits ? "Only on Premium" : null}
					notSelectable={!this.state.saveContainerVisible || slot > nextEmptySlot || showPremiumBenefits && deviceInfo.storeType === STORE_TYPE_EPIC}
				/>
			);
		}

		return buttonsArray;
	};

	renderLoadButtons = () => {
		if (!this.props.currentGameDetails) return null;
		const {savedStates} = this.props.currentGameDetails;
		const buttonsArray = [];

		// slot 0: 'Continue'
		// slot 1: 'Slot 1'
		// slot 2: 'Slot 2'
		// slot 3: 'Slot 3'
		// "non premium" users cannot load or save
		for (let slot = 0; slot < 4; slot++) {
			const data = savedStates ? savedStates.find(savedStateData => savedStateData.slot === slot) : null;
			const showPremiumBenefits = !this.props.isSubscribed;
			buttonsArray.push(
				<SaveButton
					data={data}
					key={slot}
					slot={slot}
					onClick={this.onLoadButtonItemClicked}
					childId={"loadButton" + slot}
					disabled={!data}
					premiumBenefitsText={showPremiumBenefits ? "Only on Premium" : null}
					notSelectable={!this.state.loadContainerVisible || !data || showPremiumBenefits && deviceInfo.storeType === STORE_TYPE_EPIC}
				/>
			);
		}

		return buttonsArray;
	}

	render() {
		const {
			inControlMenu,
			loadContainerVisible,
			saveContainerVisible
		} = this.state

		if (!this.props.isWebViewVisible && this.isRunningOnNativeClientV2()) {
			const oskData = this.state.oskData;
			const oskEnabled = oskData && oskData.vk_enabled && !!oskData.vk_enabled.find(entry => entry > 0);
			return (
				<div>
					<InGameHeader
						allowOSK={oskEnabled}
						onMenuClicked={() => {
							console.log('onMenuClicked');
						}}
						onStartClicked={() => {
							console.log('onStartClicked');
						}}
						onOSKClicked={() => {
							this.toggleOSKVisibilityOnNativeClientV2(!this.props.isOskVisible);
						}}
						active
						isChallenge={ false }
						scores={ {} }
						userData={ {} }
						isTournament={ false }
						rank={ 1 }
						isBetterRank={ false }
						giantSlayerRole={ {} }
						// giantSlayerChallenge={ this.props.giantSlayerChallenge }
						// giantUserData={ this.props.giantUserData }
						// challengeObject={ this.props.challengeObject }
					/>
					{this.props.isOskVisible && <InGameOSK
						inputSetupRaw={oskData}
						onOSKKeyUp={(code) => {
							this.handleOSKKeyEvent(code, 0);
						}}
						onOSKKeyDown={(code) => {
							this.handleOSKKeyEvent(code, 1);
						}}
					/>}
				</div>
			);
		}

		if (!this.props.isWebViewVisible) return null;

		const saveEnabled = this.props.currentGameDetails
			&& this.props.currentGameDetails.tags?.includes("savestates")
			&& featureInfo.isSupported(FEATURES.SAVE_STATES)
			&& !this.props.challengeId;

		if (this.props.showHowToPlay) {
			return (<HowToPlay />);
		}

		if (inControlMenu) {
			return (
				<section className="control-menu-component">
					<ControlMenu isXboxPlayer={this.props.isXboxPlayer || deviceInfo.isPlayStationPlatform()} handleConfirm={() => {
						this.setState({inControlMenu: false}, () => {
							inputManager.setCurrentChildById('editControlsButton');
							inputManager.setBackFunction(() => {
								this.onQuitClicked()
							});
						})}
					}/>
				</section>
			)
		}

		inputManager.setBackSound(true);

		return (
			<section className="in-game-menu-component">
				<Button
					className="primary"
					onClick={this.onContinueClicked}
					childId={"continueButton"}
					onFocus={this.onButtonFocus}
				>
					Continue
				</Button>
				{
					featureInfo.isSupported(FEATURES.CONTROL_OPTIONS) && <Button
						className="primary"
						onClick={() => {
							this.setState({inControlMenu: true})
						}}
						childId={"editControlsButton"}
						onFocus={this.onButtonFocus}
					>
						Settings
					</Button>
				}

				{window.config.REACT_APP_ENV !== 'live' &&
				<>
				<Button
					className="primary"
					onClick={this.onRetryClicked}
					childId={"retryButton"}
					onFocus={this.onButtonFocus}
					notSelectable={!this.props.enableRetryButton}
				>
					Retry
				</Button>
					<div className={`save-buttons-container ${loadContainerVisible ? 'focused' : ''}`}>
						<Button
							className="primary common-save-button"
							childId={"loadButton"}
							onFocus={this.onLoadButtonFocus}
							onClick={this.onLoadButtonClicked}
							withEventObject
							notSelectable={!saveEnabled}
						>
							Load
						</Button>
						{loadContainerVisible?this.renderLoadButtons():""}
					</div>
				</>}
			
				<div className={`save-buttons-container ${saveContainerVisible ? 'focused' : ''}`}>
					<Button
						className="primary common-save-button"
						childId={"saveButton"}
						onFocus={this.onSaveButtonFocus}
						onClick={this.onSaveButtonClicked}
						withEventObject
						notSelectable={!saveEnabled}
					>
						Save
					</Button>
					{saveContainerVisible?this.renderSaveButtons():""}
				</div>
				<Button
					className="primary"
					onClick={this.onHowToPlayClicked}
					childId={"howToPlayButton"}
					onFocus={this.onButtonFocus}
				>
					Controls
				</Button>
				<Button
					className="primary"
					onClick={this.onQuitClicked}
					childId={"quitButton"}
					onFocus={this.onButtonFocus}
				>
					Quit Game
				</Button>
			</section>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const isPopupVisible = !getPopupVisibility(state);
	const currentGameDetails = getCurrentGameDetails(state);
	const isSaving = getIsSaving(state);
	const isSavingSuccessful = getIsSavingSuccessful(state);
	const challengeId = getChallengeId(state);
	const currentGamesTags = get(currentGameDetails, 'tags');
	const hasLeaderBoard = currentGamesTags && Array.isArray(currentGamesTags) ? includes(currentGamesTags, 'leaderboard') : false;
	const isWebViewVisible = getWebviewState(state).visible;
	const lastSessionObject = getLastSessionObject(state);
	const isInterimScoreEnabled = getIsInterimScoreEnabled(state);
	const sessionObject = getRunningSessionObject(state);
	const isMultiplayerChallenge = sessionObject ? sessionObject.challenge_instance_id : false;
	const showHowToPlay = state.gameDetails ? state.gameDetails.showHowToPlay : false;
	const isSubscribed = getUserIsSubscribed(state);
	const gameId = currentGameDetails ? currentGameDetails._id : null;

	return {
		isPopupVisible,
		isXboxPlayer: isXboxUser(state),
		isOskVisible: state.gameDetails.isOskVisible,
		currentGameDetails,
		hasLeaderBoard,
		challengeId,
		isSaving,
		isWebViewVisible,
		isInterimScoreEnabled,
		lastSessionObject,
		isSavingSuccessful,
		gameId,
		sessionId: getRunningSessionId(state),
		region: getCurrentRegionCode(state),
		enableRetryButton: !isMultiplayerChallenge,
		showHowToPlay,
		isSubscribed,
		webrtc: ownProps.webrtc,
	};
};

export default connect(mapStateToProps)(InGameComponent);
