import React, { Component } from 'react';
import inputManager from '../../../assets/lib/inputmanager';
import './drop-down.component.less';
import {DropDownSelectionButton} from './drop-down-selection-button';
import {Button} from '../button/common-button.component';

const TYPE_VIEW_OPEN = 'open';
const TYPE_VIEW_CLOSE = 'close';

const navLayer = 3;

class DropDown extends Component {
	constructor(props) {
		super(props);
		const defaultItem = this.props.defaultItem
		const itemElement = typeof defaultItem === 'string' ? {
			name: defaultItem,
			value: defaultItem,
		} : defaultItem

		this.state = {
			viewState : TYPE_VIEW_CLOSE,
			selectedItem : itemElement,
		};

		this.baseLayer = inputManager.layerIndex || 0;

		this.onSelectionButtonClicked=this.onSelectionButtonClicked.bind(this);
		this.onMenuButtonClicked=this.onMenuButtonClicked.bind(this);
		this.closeMenu=this.closeMenu.bind(this);
	}

	componentWillUnmount() {
		this.closeMenu();
	}

	componentDidUpdate() {
		if(this.props.defaultItem && this.state.selectedItem !== this.props.defaultItem) {
			this.setState({
				selectedItem : this.props.defaultItem,
			});
		}
	}
	

	onSelectionButtonClicked() {
		if (this.props.ignoreClick) return;

		if(this.state.viewState===TYPE_VIEW_OPEN) {
			this.closeMenu();
			return;
		}

		this.setState(
			{
				viewState : TYPE_VIEW_OPEN,
			},
			() => {
				inputManager.setLayer(navLayer);
				inputManager.setBackFunction(()=>{this.closeMenu()},navLayer);
			});
	}

	onMenuButtonClicked(item,index) {
		const selectedItem = this.state.selectedItem && this.state.selectedItem.value
		if(selectedItem!==item.value) {
			this.setState({
				selectedItem : item,
			});
			if(this.props.onChange)this.props.onChange(item,index);
		}

		this.closeMenu();
	}

	closeMenu() {
		if(this.state.viewState===TYPE_VIEW_CLOSE)return;

		this.setState({
			viewState : TYPE_VIEW_CLOSE
		});

		if(inputManager.layerIndex === navLayer) {
			inputManager.setLayer(this.baseLayer);
		}

		inputManager.setBackFunction(null,navLayer);
	}

	renderMenu(menuItems) {
		const reactElements = [];

		menuItems.forEach((item,index)=>{
			const itemElement = typeof item === 'string' ? {
				name: item,
				value: item,
			} : item

			reactElements.push(
				<Button
					className={`menu-item ${this.state.selectedItem && this.state.selectedItem===itemElement.value ? 'primary' : 'secondary'}`}
					onClick={()=>this.onMenuButtonClicked(itemElement,index)}
					childId={"drop-down-"+itemElement.name}
					layer={navLayer}
					key={`drop-down-button-${itemElement.name}`}
				>
					{itemElement.name}
				</Button>
			);
		});

		return(
			<div className="drop-down-menu">
				{reactElements}
			</div>
		);
	}

	render() {
		const {menuItems, disabled} = this.props;
		const selectedItem = this.state.selectedItem && typeof this.state.selectedItem !== 'string' ? this.state.selectedItem.name : this.state.selectedItem 

		return (
			<div className="drop-down-component">
				<DropDownSelectionButton
					throttleMs={this.props.throttleMs}
					noArrowIcon={this.props.noArrowIcon}
					disabled={disabled}
					className={`${this.state.viewState===TYPE_VIEW_OPEN ? 'primary' : 'secondary'}`}
					childId = {this.props.childId}
					onClick={this.onSelectionButtonClicked}
					layer={this.props.layer}
				>
					{selectedItem ? selectedItem : 'Select'}
				</DropDownSelectionButton>
				{this.state.viewState===TYPE_VIEW_OPEN && menuItems && menuItems.length>0 ? this.renderMenu(menuItems) : null}
			</div>
		);
	}
}

export default DropDown;
