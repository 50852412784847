import React, { useEffect } from 'react';
import './history.component.less';
import { connect } from 'react-redux';
import { getDailyChallengeActivity } from '../daily-challenges.actions';
import CountdownShortTime from '../../common/countdown-short-time/countdown-short-time.component';
import { getNthText } from '../../../assets/lib/utils';

const DailyChallengesHistoryComponent = ({ activity, currentTimeEnd, dispatch }) => {
    useEffect(() => {
        dispatch(getDailyChallengeActivity());
    }, []);

    function renderCountdown() {
        if (!currentTimeEnd) return null;

        return (
            <CountdownShortTime endTimeStamp={currentTimeEnd} isDailyChallenge={true} />
        );
    }

    function renderLastWeek() {
        const rank = activity?.lastEventHistory?.stats?.rank;
        if (!Number(rank)) return null;

        return (
            <div className='daily-challenges-history__last-week'>
                <div className='daily-challenges-history__last-week-text'>
                    Last Weeks Position
                </div>
                <div className='daily-challenges-history__last-week-position'>
                    {rank}
                    {getNthText(rank)}
                </div>
            </div>
        );
    }

    return (
        <div className='daily-challenges-history'>
            {renderCountdown()}
            {renderLastWeek()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        currentTimeEnd: state.dailyChallenges.currentTimeEnd,
        activity: state.dailyChallenges.dailyChallengeActivity,
        configuration: state.dailyChallenges.configuration
    };
};

const DailyChallengesHistory = connect(mapStateToProps)(DailyChallengesHistoryComponent);
export default DailyChallengesHistory;
